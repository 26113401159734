import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { change } from 'redux-form';
import { useSession } from '../sessions';

import SearchLayout from './SearchLayout';

import './RipLayout.scss';

const RipLayout = ({ dispatch, ...props }) => {
    const session = useSession();
    const searchRef = useRef(null);

    const resetInput = useCallback(() => {
        searchRef.current.getWrappedInstance().resetSearch();
        dispatch(change('rip-list-modes', 'mode', 'user'));
    }, [dispatch]);

    return (
        <SearchLayout
            {...props}
            searchRef={searchRef}
            logo={
                <button className="logo" onClick={resetInput}>
                    <img src={get('settings.logo', session)} alt={get('entity.name', session)} />
                </button>
            }
        />
    );
};

RipLayout.propTypes = {
    searchName: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(RipLayout);
