import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { investmentReduction } from 'common/choices';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const InvestmentType = ({ type, name }) => (
    <Fragment>
        <PercentageFloatField
            name={`${name}.rate`}
            title={`${investmentReduction[type]} - Réduction (%)`}
        />
        <IntegerField
            name={`${name}.singleCeil`}
            title={`${investmentReduction[type]} - Plafond de réduction (célibataire) (€)`}
        />
        <IntegerField
            name={`${name}.coupleCeil`}
            title={`${investmentReduction[type]} - Plafond de réduction (couple) (€)`}
        />
    </Fragment>
);

InvestmentType.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default InvestmentType;
