import PropTypes from 'prop-types';
import React from 'react';
import { InputField, IntegerField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import OwnerField from '../field/Owner';

const OtherIncome = ({ name, ripFromRecommendation }) => (
    <HorizontalLayout>
        <InputField name={`${name}.description`} title="Autre ? Précisez" required type="text" />
        <IntegerField name={`${name}.income`} title="Montant" required />
        <InputField name={`${name}.recurring`} title="Récurrent" type="checkbox" />
        <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" ripFromRecommendation={ripFromRecommendation} required />
    </HorizontalLayout>
);

OtherIncome.propTypes = {
    name: PropTypes.string.isRequired,
    ripFromRecommendation: PropTypes.shape({}),
};

export default OtherIncome;
