import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import FormGroup from 'components/form/Group';
import { HorizontalLayout } from 'components/form/layout';
import { IntegerField, TextField, YesNoField } from 'components/form/field';
import Title from 'components/Title';

const ClientInner = ({ currentValue, createdAt }) => {
    // Une disponibilité minimun obligatoire à partir du 18/07/24
    const dateLimit = new Date('2024-07-18T00:00:00.000Z');
    const createdDateRip = new Date(createdAt);
    if (createdDateRip >= dateLimit) {
        return (
            <div className="rip-civil-state">
                <Fragment>
                    <FormGroup>
                        <Title><b>Montant</b> des disponibilités</Title>
                        <HorizontalLayout>
                            <IntegerField title="Minimum" name="financialMinimumSupply" required />
                            <IntegerField title="Maximum" name="financialMaximumSupply" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <FormGroup>
                        <Title><b>Capacité d&apos;épargne mensuelle</b></Title>
                        <HorizontalLayout className="condensed">
                            <IntegerField title="Minimum" name="projectMinimumSavings" required />
                            <IntegerField title="Maximum" name="projectMaximumSavings" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <TextField name="financialSource" title={<span><b>Origine</b> des disponibilités</span>} />
                </Fragment>
            </div>
        );
    }

    return (

        <div className="rip-civil-state">
            <YesNoField name="financialCapacity" title="Avez-vous des disponibilités financières ?" required />
            {currentValue ? (
                <Fragment>
                    <FormGroup>
                        <Title><b>Montant</b> des disponibilités</Title>
                        <HorizontalLayout>
                            <IntegerField title="Minimum" name="financialMinimumSupply" required />
                            <IntegerField title="Maximum" name="financialMaximumSupply" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <FormGroup>
                        <Title><b>Capacité d&apos;épargne mensuelle</b></Title>
                        <HorizontalLayout className="condensed">
                            <IntegerField title="Minimum" name="projectMinimumSavings" required />
                            <IntegerField title="Maximum" name="projectMaximumSavings" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <TextField name="financialSource" title={<span><b>Origine</b> des disponibilités</span>} />
                </Fragment>
            ) : null}
        </div>
    );
};

ClientInner.propTypes = {
    currentValue: PropTypes.bool,
    createdAt: PropTypes.string,
};

ClientInner.defaultProps = {
    currentValue: true,
};

const Client = formValues({
    currentValue: 'financialCapacity',
    createdAt: 'createdAt',
})(ClientInner);

export default Client;
