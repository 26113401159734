import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Icon from 'common/components/Icon';
import Error from 'components/Error';
import './FileInput.scss';

class FileInputInner extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.inputRef = React.createRef();
        this.state = {
            inputFileName: null,
        };
    }

    onChange({ target: { files, value } }) {
        const { input: { onChange } } = this.props;

        try {
            const inputFileName = value.split('\\').pop();
            this.setState({ inputFileName });
        } catch (error) {
            this.setState({ inputFileName: null });
            console.error(error);
        }

        return onChange(files.length ? files[0] : null);
    }

    onDelete() {
        const { input: { onChange } } = this.props;

        this.setState({ inputFileName: null });
        this.inputRef.current.value = null;

        return onChange(null);
    }

    render() {
        const { inputFileName } = this.state;

        return (
            <Fragment>
                <input
                    type="file"
                    onChange={this.onChange}
                    name={this.props.input.name}
                    id={this.props.input.name}
                    className="input-file-uploader"
                    ref={this.inputRef}
                />
                <label htmlFor={this.props.input.name}>
                    <Icon icon="upload" />
                    <span>Choisissez un fichier</span>
                </label>
                <div className="input-file-name">
                    {inputFileName && (
                        <div>
                            <Icon icon="check-circle" />
                            <span>{inputFileName}</span>
                            {this.props.deletable && (
                                <button onClick={this.onDelete}><Icon icon="times" /></button>
                            )}
                        </div>
                    )}
                </div>
                <Error {...this.props.meta} />
            </Fragment>
        );
    }
}

FileInputInner.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    deletable: PropTypes.bool,
};

const validate = ({ allowEmpty = false }) => (value, allValues, props) => {
    if (!value && !allowEmpty && props.anyTouched) { return 'Veuillez choisir un fichier'; }

    return undefined;
};

const FileInput = (props) => (
    <Field
        {...props.shouldValidate && { validate: validate(props) }}
        component={FileInputInner}
        {...props}
    />
);

FileInput.propTypes = {
    shouldValidate: PropTypes.bool,
};

FileInput.defaultProps = {
    shouldValidate: true,
};

export default FileInput;
