import { get, isString, getOr } from 'lodash/fp';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import withFormName from './withFormName';

const withFieldError = compose(
    withFormName,
    connect((state, { form, name }) => {
        const error = get(name, getFormSyncErrors(form)(state));

        return {
            error: isString(error) ? error : getOr(null, '_error', error),
        };
    }),
);

export default withFieldError;
