import validate, {
    checkPasswordStrongEnough,
    checkEqualFields,
    checkIsPhoneNumber,
    branchValidate,
    checkRequiredField,
} from 'common/formValidation/lib';

const formValidate = validate(() => [
    checkEqualFields(
        'passwordConfirmation', 'password',
        'La confirmation de mot de passe ne correspond pas au mot de passe',
    ),
    checkPasswordStrongEnough('password'),
    checkIsPhoneNumber('phone'),
    branchValidate((errors, { differentBuyer }) => differentBuyer, [
        checkRequiredField('otherBuyer.firstName'),
        checkRequiredField('otherBuyer.lastName'),
        checkRequiredField('otherBuyer.email'),
        checkRequiredField('otherBuyer.address'),
    ]),
]);

export default formValidate;
