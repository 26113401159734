const formatAmount = (value, showCents = false) => {
    const parsedValue = parseFloat(value);
    if (Number.isNaN(parsedValue)) {
        return 'NC';
    }

    const amount = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: showCents ? 2 : 0,
        maximumFractionDigits: showCents ? 2 : 0,
    }).format(parsedValue);

    return '-0 €' === amount ? '0 €' : amount;
};

export default formatAmount;
