import React from 'react';
import { reduxForm } from 'redux-form';
import { lifeInsuranceSimulatorProfiles } from 'common/choices';
import { GridLayout } from 'components/form/layout';
import FormGroup from 'components/form/Group';
import { IntegerField, PercentageField } from 'components/form/field';
import GridRadioChoices from 'components/form/GridRadioChoices';
import Title from 'components/Title';
import SimulationResult from './LifeInsuranceResult';

const LifeInsurance = () => (
    <form className="simulation-form">
        <h4 className="simulator-title">
            <b>Simulateur de placement en assurance-vie</b>
        </h4>
        <FormGroup>
            <Title><b>Paramètres</b></Title>
            <GridLayout className="triple">
                <IntegerField
                    name="initialFunding"
                    title="Capital initial"
                    min={0}
                />
                <PercentageField
                    name="entryFees"
                    title="Frais d'entrée pour le montant initial (%)"
                    min={0}
                    max={5}
                />
                <IntegerField
                    name="duration"
                    title="Durée (en années)"
                    min={5}
                />
                <IntegerField
                    name="monthlyFunding"
                    title="Épargne mensuelle"
                    min={0}
                />
                <PercentageField
                    name="monthlyFees"
                    title="Frais d'entrée pour les montants mensuels (%)"
                    min={0}
                    max={5}
                />
            </GridLayout>
            <div className="form-group-grid">
                <h6>Profil d&apos;investisseur</h6>
                <GridRadioChoices
                    choices={lifeInsuranceSimulatorProfiles}
                    name="investorProfile"
                    className="double"
                />
            </div>
        </FormGroup>
        <SimulationResult />
    </form>
);

export default reduxForm({
    form: 'assurance-vie',
    initialValues: {
        initialFunding: 0,
        monthlyFunding: 50,
        investorProfile: 'E',
        duration: 5,
        entryFees: '0.05',
        monthlyFees: '0.05',
    },
    destroyOnUnmount: false,
})(LifeInsurance);
