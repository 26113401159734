import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { syncRipAndDownload } from 'actions';
import Icon from '../../common/components/Icon';
import './DownloadRipSelection.scss';

const DownloadRipSelection = ({ id, universignTxState, onFullClicked, onClientClicked, onSignedClicked, navigate }) => (
    <div className="rip-download-selection">
        <h5>Quel format souhaitez vous télécharger ?</h5>
        <div className="rip-download-selection-buttons">
            <div>
                <button onClick={() => onClientClicked(id, navigate)}>
                    <Icon icon="file-o" className="fa-5x" />
                    <span>RIP client</span>
                </button>
            </div>
            <div>
                <button onClick={() => onFullClicked(id, navigate)}>
                    <Icon icon="files-o" className="fa-5x" />
                    <span>RIP complet</span>
                </button>
            </div>
            {'completed' === universignTxState ? (
                <div>
                    <button onClick={() => onSignedClicked(id, navigate)}>
                        <Icon icon="files-o" className="fa-5x" />
                        <Icon icon="check" className="fa signed-rip" />
                        <span>RIP signé avec universign</span>
                    </button>
                </div>
            ) : null}
        </div>
    </div>
);

DownloadRipSelection.propTypes = {
    id: PropTypes.string.isRequired,
    universignTxState: PropTypes.string,
    onFullClicked: PropTypes.func.isRequired,
    onClientClicked: PropTypes.func.isRequired,
    onSignedClicked: PropTypes.func.isRequired,
    navigate: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    onSignedClicked: (id, navigate) => dispatch(syncRipAndDownload(id, navigate, 'signed')),
    onFullClicked: (id, navigate) => dispatch(syncRipAndDownload(id, navigate, 'full')),
    onClientClicked: (id, navigate) => dispatch(syncRipAndDownload(id, navigate)),
});

export default connect(null, mapDispatchToProps)(DownloadRipSelection);
