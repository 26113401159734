import React from 'react';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import { GridLayout } from 'components/form/layout';
import { IntegerField } from 'components/form/field';

const OtherLandRentalResults = () => (
    <FormGroup>
        <Title><b>Autres résultats fonciers</b></Title>
        <GridLayout className="condensed">
            <IntegerField
                name="data.landRentalResult"
                title="Résultat foncier imposable"
            />
            <IntegerField
                name="data.chargeableDeficit"
                title="Dont déficit imputable sur le revenu global"
            />
        </GridLayout>
    </FormGroup>
);

export default OtherLandRentalResults;
