import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'common/components/Icon';

const UntypedBlock = ({ children, deleteAction }) => (
    <div className="form-block-typed-collection-item untyped">
        <div className="header">
            <button
                type="button"
                onClick={deleteAction}
                className="add-btn"
            >
                <Icon icon="times-circle" />
            </button>
        </div>
        <div className="inner">
            {children}
        </div>
    </div>
);

UntypedBlock.propTypes = {
    children: PropTypes.node.isRequired,
    deleteAction: PropTypes.func.isRequired,
};

export default UntypedBlock;
