import { connect } from 'react-redux';
import { flowRight, identity, get } from 'lodash/fp';
import { branch, lifecycle, renderNothing, withProps } from 'recompose';
import { loadData } from 'actions';
import withRouter from './withRouter';

const dataProvider = ({ id, type, prop = type, wait = false, processData = identity }) => flowRight([
    withRouter,
    connect(null, {
        loadData,
    }),
    lifecycle({
        componentDidMount() {
            this.props.loadData(type, id(this.props));
        },
    }),
    connect((state, props) => ({
        [prop]: get([type, id(props)], state.data),
    })),
    wait ? branch(
        (props) => !props[prop],
        renderNothing,
        withProps((props) => processData({ [prop]: props[prop] })),
    ) : identity,
]);

export default dataProvider;
