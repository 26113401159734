import PropTypes from 'prop-types';
import React from 'react';
import { withPropsOnChange, compose } from 'recompose';
import { reduxForm } from 'redux-form';
import computeSummary from 'common/model/summary';
import FormGroup from 'components/form/Group';
import { IntegerField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import dataProvider from 'components/dataProvider';
import Title from 'components/Title';
import LoanResult from './LoanResult';
import { getMaxAmountLoanable } from './computeLoan';
import LoanDropdowns from './LoanDropdowns';
import withRouter from '../../../../../withRouter';

const Loan = ({
    rip,
    summary,
    change,
}) => {
    const maxAmountLoanable = getMaxAmountLoanable(summary);

    return (
        <form className="simulation-form">
            <h4 className="simulator-title">
                <b>Simulateur de crédit immobilier</b>
            </h4>
            <LoanDropdowns
                maxAmountLoanable={maxAmountLoanable}
                change={change}
            />
            <hr />
            <Title><b>Paramètres</b></Title>
            <FormGroup>
                <GridLayout className="double condensed">
                    <IntegerField
                        name="loanAmount"
                        title="Montant"
                        min={0}
                    />
                    <IntegerField
                        name="loanDuration"
                        title="Durée en années"
                        min={0}
                    />
                    <IntegerField
                        name="monthlyPayment"
                        title="Mensualité (comprend l'assurance)"
                        min={0}
                    />
                    <IntegerField
                        name="monthlyLoan"
                        title="Loyer mensuel"
                        min={0}
                    />
                </GridLayout>
            </FormGroup>
            <hr />
            <LoanResult
                maxAmountLoanable={maxAmountLoanable}
                summary={summary}
                rip={rip}
            />
        </form>
    );
};

Loan.propTypes = {
    rip: PropTypes.shape({}).isRequired,
    summary: PropTypes.shape({}).isRequired,
    change: PropTypes.func.isRequired,
};

export default compose(
    withRouter,
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
    }),
    withPropsOnChange(['rip'], ({ rip }) => ({ summary: computeSummary(rip) })),
    reduxForm({
        form: 'credit-immo',
        initialValues: {
            loanAmount: 0,
            loanDuration: 0,
            monthlyPayment: 0,
            monthlyLoan: 0,
        },
        destroyOnUnmount: false,
    }),
)(Loan);
