import { flowRight } from 'lodash/fp';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { branch, renderNothing, withPropsOnChange } from 'recompose';
import computeSummary from 'common/model/summary';
import { formatAmount, formatFloat, formatPercentage } from 'common/utils/format';
import FormButtons from 'components/FormButtons';
import Group from 'components/form/Group';
import { WizardLayout } from 'components/layouts';
import Title from 'components/Title';
import dataProvider from 'components/dataProvider';
import withBeneficiaryRip from '../../withBeneficiaryRip';
import wizard from './wizardData';
import SimulationHeader from './Header';
import applyEvents from '../../../../common/utils/applyEvents';

import './Overview.css';

const OverviewInner = ({ navigate, needFunding, summary, params: { id, simulationId } }) => {
    const handleSubmit = (event) => {
        // prevent the browser to reload the document
        event.preventDefault();
        // redirect to the next step
        navigate(`/rip/${id}/simulations/${simulationId}/bien`);
    };

    return (
        <WizardLayout className="simulation-form simulation-overview" wizard={wizard(needFunding)} hasFooter>
            <form onSubmit={handleSubmit} id="main-form">
                <div>
                    <div className="simulation-form-inner">
                        <div className="container">
                            <SimulationHeader />
                            <Group>
                                <Title><b>Résumé</b> des informations du RIP</Title>
                                <table className="table table-striped overview">
                                    <tbody>
                                        <tr>
                                            <td>Nombre de parts</td>
                                            <td>{formatFloat(summary.parts)}</td>
                                        </tr>
                                        <tr>
                                            <td>Revenus annuels nets</td>
                                            <td>{formatAmount(summary.rawIncomes)}</td>
                                        </tr>
                                        <tr>
                                            <td>Déductions</td>
                                            <td>{formatAmount(summary.deductions)}</td>
                                        </tr>
                                        <tr>
                                            <td>Réductions</td>
                                            <td>{formatAmount(summary.reductions)}</td>
                                        </tr>
                                        <tr>
                                            <td>Crédits d&apos;impôts</td>
                                            <td>{formatAmount(summary.credits)}</td>
                                        </tr>
                                        <tr>
                                            <td>TMI</td>
                                            <td>{formatPercentage(summary.tmi)}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Impôt net sur le revenu</b></td>
                                            <td><b>{formatAmount(summary.incomeTax)}</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Reste à vivre mensuel</b></td>
                                            <td><b>{formatAmount(summary.remainingIncomes)}</b></td>
                                        </tr>
                                        <tr>
                                            <td><b>Disponibilité mensuelle</b></td>
                                            <td><b>{formatAmount(summary.availableIncomes)}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link className="rip-edit-link" to={`/rip/${id}/etat-civil`}>
                                    <p>Editer le rip</p>
                                </Link>
                            </Group>
                        </div>
                    </div>
                    <FormButtons
                        back={`/rip/${id}/simulations/`}
                        list={`/rip/${id}/simulations/`}
                    />
                </div>
            </form>
        </WizardLayout>
    );
};

OverviewInner.defaultProps = {
    needFunding: false,
};

OverviewInner.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string,
    }).isRequired,
    summary: PropTypes.shape({
        credits: PropTypes.number.isRequired,
        deductions: PropTypes.number.isRequired,
        incomes: PropTypes.number.isRequired,
        incomeTax: PropTypes.number.isRequired,
        parts: PropTypes.number.isRequired,
        reductions: PropTypes.number.isRequired,
        tmi: PropTypes.number.isRequired,
        rawIncomes: PropTypes.number,
        remainingIncomes: PropTypes.number,
        availableIncomes: PropTypes.number,
    }).isRequired,
    navigate: PropTypes.shape({}).isRequired,
    needFunding: PropTypes.bool,
};

const Overview = flowRight([
    dataProvider({
        id: ({ params: { simulationId } }) => simulationId,
        type: 'simulation',
    }),
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
    }),
    branch(
        ({ rip, simulation }) => !rip || !simulation,
        renderNothing,
    ),
    withBeneficiaryRip,
    withPropsOnChange(
        ['rip'],
        ({ rip }) => ({
            summary: computeSummary(applyEvents(rip, moment().year())),
        }),
    ),
    withPropsOnChange(
        ['simulation'],
        ({ simulation }) => ({
            needFunding: !!simulation.needFunding,
        }),
    ),
])(OverviewInner);

export default Overview;
