import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { VerticalLayout } from '../form/layout';
import SummaryBlock from './overlays/summary/Summary';
import Form from './Form';
import dataForm from '../dataForm';
import withRouter from '../withRouter';

const SummaryInner = ({ back, handleSubmit, initialValues: { id } }) => (
    <Form back={back} handleSubmit={handleSubmit} id={id}>
        <VerticalLayout className="container">
            <SummaryBlock id={id} overlay={false} />
        </VerticalLayout>
    </Form>
);

SummaryInner.propTypes = {
    back: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

const Summary = flowRight([
    withRouter,
    dataForm({
        form: 'rip-summary',
        id: ({ router: { params: { id } } }) => id,
        type: 'rip',
    }),
])(SummaryInner);

export default Summary;
