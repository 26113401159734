import React from 'react';
import PropTypes from 'prop-types';
import './BreadCrumbs.scss';

const BreadCrumbs = ({ parents, moveUp }) => (
    <div className="breadcrumbs">
        <ul className="breadcrumbs-list">
            {/* eslint-disable-next-line */}
            <li onClick={() => moveUp(0)}>SGAs</li>
            {/* eslint-disable-next-line */}
            {parents.map(({ id, name }) => <li key={id} onClick={() => moveUp(id)}>&nbsp;{name}</li>)}
        </ul>
    </div>
);

BreadCrumbs.propTypes = {
    parents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired),
    moveUp: PropTypes.func.isRequired,
};

export default BreadCrumbs;
