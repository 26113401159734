import { get, set } from 'lodash/fp';

const checkIntegerField = (condition, errorString) =>
    (field) =>
        (errors, values) => {
            const value = get(field, values);

            return ('number' === typeof value && !condition(value)
                ? set(field, errorString, errors)
                : errors);
        };

export default checkIntegerField;
