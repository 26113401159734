import { get } from 'lodash/fp';
import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { setModal } from 'actions/modal';
import Spinner from 'components/Spinner';
import handleError from '../../../utils/handleError';
import { POST } from '../../../utils/httpMethods';
import { SelectField, TextAreaField } from '../../form/field';
import { withSession } from '../../sessions';
import baseSimulationLoader from '../simulationLoader';
import SuccessModal from '../../SuccessModal';
import withRouter from '../../withRouter';

const mayUserSelectRecipient = (session) => get('settings.canSelectRecommendationRecipient', session)
&& get('entity.managerId', session)
&& get('entity.caseManagerId', session);

const RequestApproval = ({ formComponent: Form, session, ...props }) => (
    <Form {...props}>
        <TextAreaField name="recommendationComments" title="Message pour mon validateur" />
        {mayUserSelectRecipient(session) ? (
            <SelectField name="recommendationRecipient" title="Destinataire" required>
                <option />
                <option value="manager">Mon manager</option>
                <option value="caseManager">Mon chargé d&apos;affaires</option>
                <option value="both">Mon manager et mon chargé d&apos;affaires</option>
            </SelectField>
        ) : null}
    </Form>
);

RequestApproval.propTypes = {
    formComponent: PropTypes.func.isRequired,
    session: PropTypes.shape({}).isRequired,
};

export default ({
    // HOC to load the simulation (and router match)
    simulationLoader = baseSimulationLoader,
    // form component to use as a layout
    formComponent,
}) => compose(
    // first need the simulation
    simulationLoader,
    withRouter,
    // then init the redux form
    reduxForm({
        form: 'simulation-approval',
        onSubmit: (
            body,
            dispatch,
            { params: { simulationId } },
        ) => {
            dispatch(setModal(() => <Spinner>Envoi de l&apos;email en cours...</Spinner>, false));

            return POST(`/api/simulations/${simulationId}/sendToManager`, { body })
                .catch(handleError);
        },
        onSubmitSuccess: (body, dispatch) => dispatch(setModal(() => <SuccessModal />, false)),
        onSubmitFail: () => alert('Erreur lors de l\'envoi du mail'),
        type: 'simulation',
    }),
    withSession,
)(withProps({ formComponent })(RequestApproval));
