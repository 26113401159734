import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { branch } from 'recompose';
import dataForm from 'components/dataForm';
import FormButtons from 'components/FormButtons';
import Form from 'components/simulation/FormStep';
import contractLoader from 'components/contract/contractLoader';
import ConnectedNextButton from 'components/FormButtons/buttons/ConnectedNextButton';
import getWizardStep from 'utils/getWizardStep';
import withDisabledForm from '../../../form/withDisabledForm';
import processValues from '../processValues';
import getWizard from '../wizardData';
import getUploader from '../getUploader';
import Documents from './Documents';
import formValidate from './formValidate';
import onSubmitRedirect from '../onSubmitRedirect';
import withRouter from '../../../withRouter';

const DocumentsUploadInner = ({
    handleSubmit,
    location,
    ...props
}) => {
    const { params: { id, contractId, contractType } } = props;
    const uploader = getUploader(contractId);

    const wizard = getWizard(contractType);
    const baseUrl = wizard.prefixTemplate(props.params);
    const prevStep = getWizardStep({ wizard, url: location.pathname, next: false });

    const prevUrl = `${baseUrl}/${prevStep}`;

    return (
        <Form handleSubmit={handleSubmit} wizard={wizard}>
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <Documents uploader={uploader} />
                </div>
            </div>
            <FormButtons
                back={prevUrl}
                list={`/rip/${id}/contracts/`}
                nextButtonComponent={ConnectedNextButton}
            />
        </Form>
    );
};

DocumentsUploadInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
        contractType: PropTypes.string.isRequired,
    }).isRequired,
};

const DocumentsUpload = flowRight([
    withRouter,
    contractLoader,
    branch(({ contract: { state } }) => 'ongoing' !== state, withDisabledForm),
    dataForm({
        autoSave: false,
        processValues,
        form: 'contract-documents-upload',
        id: ({ params: { contractId } }) => contractId,
        onSubmitSuccess: onSubmitRedirect,
        type: 'contract',
        validate: formValidate,
    }),
])(DocumentsUploadInner);

export default DocumentsUpload;
