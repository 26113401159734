// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulator-card {
    width: 150px;
    height: 200px;
    padding: 10px;
    margin: 10px;
    background: rgb(36,143,223);
    background: linear-gradient(to bottom right, rgb(36,143,223) 0%, rgb(40,233,234) 100%);
    box-shadow: 9px 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    text-align: center;
}

.simulator-image {
    width: 100%;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
}

.simulator-title {
    margin-top: 5px;
    margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/overlays/Simulators/Card.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,YAAY;IACZ,2BAA2B;IAC3B,sFAAsF;IACtF,6CAA6C;IAC7C,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".simulator-card {\n    width: 150px;\n    height: 200px;\n    padding: 10px;\n    margin: 10px;\n    background: rgb(36,143,223);\n    background: linear-gradient(to bottom right, rgb(36,143,223) 0%, rgb(40,233,234) 100%);\n    box-shadow: 9px 8px 16px 0 rgba(0, 0, 0, 0.2);\n    border-radius: 5px;\n    color: white;\n    cursor: pointer;\n    text-align: center;\n}\n\n.simulator-image {\n    width: 100%;\n    font-size: 100px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.simulator-title {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
