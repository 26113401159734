import React from 'react';
import PropTypes from 'prop-types';
import fullName from 'common/utils/fullName';
import SendSignInviteAgainBtn from 'components/rip/commitment/Signatures/Menu/SignatureStatus/SendSignInviteAgainButton';

const SignatureStatus = ({ rip, client, partner, partnerShouldSign, advisor }) => {
    const signatureStatus = {
        client: 'Non signé',
        partner: 'Non signé',
        advisor: 'Non signé',
    };

    const canSendSignInvitAgain = {
        clientUrl: null,
        partnerUrl: null,
        advisorUrl: null,
    };

    if ('universign' === client.mode) {
        if ('signed' === client.status) {
            signatureStatus.client = 'Signé électroniquement';
        } else if ('ready' === client.status) {
            signatureStatus.client = 'En attente de signature';
            canSendSignInvitAgain.clientUrl = client.resignUrl;
        } else if ('canceled' === client.status) {
            signatureStatus.client = 'Refus de signer';
        }
    }

    if ('universign' === partner.mode) {
        if ('signed' === partner.status) {
            signatureStatus.partner = 'Signé électroniquement';
        } else if ('ready' === partner.status) {
            signatureStatus.partner = 'En attente de signature';
            canSendSignInvitAgain.partnerUrl = partner.resignUrl;
        } else if ('waiting' === partner.status) {
            signatureStatus.partner = 'En attente de signature du conjoint';
        } else if ('canceled' === partner.status) {
            signatureStatus.partner = 'Refus de signer';
        }
    }

    if ('universign' === advisor.mode) {
        if ('signed' === advisor.status) {
            signatureStatus.advisor = 'Signé électroniquement';
        } else if ('ready' === advisor.status) {
            signatureStatus.advisor = 'En attente de signature';
            canSendSignInvitAgain.advisorUrl = advisor.resignUrl;
        } else if ('waiting' === advisor.status) {
            signatureStatus.advisor = 'En attente de signature du précédent signataire';
        } else if ('canceled' === advisor.status) {
            signatureStatus.advisor = 'Refus de signer';
        }
    }

    return (
        <div className="tx-status">
            <h2>État des signatures</h2>
            <div className="signature-status">
                <p>Client ({fullName(rip)}) : {signatureStatus.client}</p>
                <SendSignInviteAgainBtn
                    ripId={rip.id}
                    type="client"
                    url={canSendSignInvitAgain.clientUrl}
                />
            </div>
            {partnerShouldSign && (
                <div className="signature-status">
                    <p>Conjoint ({fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName })}) : {signatureStatus.partner}</p>
                    <SendSignInviteAgainBtn
                        ripId={rip.id}
                        type="partner"
                        url={canSendSignInvitAgain.partnerUrl}
                    />
                </div>
            )}
            <div className="signature-status">
                <p>Conseiller ({fullName(rip.user)}) : {signatureStatus.advisor}</p>
                <SendSignInviteAgainBtn
                    ripId={rip.id}
                    type="advisor"
                    url={canSendSignInvitAgain.advisorUrl}
                />
            </div>
        </div>
    );
};

SignatureStatus.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        user: PropTypes.string,
    }).isRequired,
    client: PropTypes.shape({
        mode: PropTypes.string,
        status: PropTypes.string,
        resignUrl: PropTypes.string,
    }).isRequired,
    partner: PropTypes.shape({
        mode: PropTypes.string,
        status: PropTypes.string,
        resignUrl: PropTypes.string,
    }).isRequired,
    advisor: PropTypes.shape({
        mode: PropTypes.string,
        status: PropTypes.string,
        resignUrl: PropTypes.string,
    }).isRequired,
    partnerShouldSign: PropTypes.bool,
};

export default SignatureStatus;
