import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSession } from 'components/sessions';
import Overview from 'components/contract/per/Overview';
import ClientInformation from 'components/contract/per/ClientInformation';
import DocumentsUpload from 'components/contract/per/DocumentsUpload';
import Signatures from 'components/contract/per/Signatures';
import Validation from 'components/contract/per/Validation';
import Verify from 'components/contract/per/Verify';
import Documents from 'components/contract/per/Documents';
import ValidationDocuments from 'components/contract/per/Validation/Documents';
import InternalDocuments from 'components/contract/per/InternalDocuments';
import CardifCssWarning from 'components/contract/per/CardifCssWarning';

const Per = () => {
    const session = useSession();

    return (
        <Routes>
            <Route exact path="/verifications/:contractType" element={<Verify />} />
            <Route exact path="/conditions/:contractType" element={<Documents />} />
            <Route exact path="/overview/:contractType" element={<Overview />} />
            <Route exact path="/informations/:contractType" element={<ClientInformation />} />
            <Route exact path="/documents/:contractType" element={<DocumentsUpload />} />
            <Route exact path="/internalDocs/:contractType" element={<InternalDocuments />} />
            <Route exact path="/signatures/:contractType" element={<Signatures />} />
            <Route exact path="/cardifCssWarning/:contractType" element={<CardifCssWarning />} />
            {session.permissions.mayValidateContracts() ? [
                <Route key="0" exact path="/ca-validation" element={<Validation />} />,
                <Route key="1" exact path="/ca-documents" element={<ValidationDocuments />} />,
            ] : null}
        </Routes>
    );
};

export default Per;
