import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { withContext } from 'recompose';
import { TextAreaField, YesNoField } from 'components/form/field';
import { formValues } from 'redux-form';
import Divider from 'components/Divider';
import RipForm from '../Form';
import Sci from './SciList';
import MainResidence from './MainResidence';
import Property from './PropertyList';
import dataForm from '../dataForm';
import RipChargeList from './ChargeList';

const WithHaveCharges = formValues('haveCharges')(({ haveCharges, children }) => children(haveCharges));

WithHaveCharges.propTypes = {
    children: PropTypes.func.isRequired,
};

const FormInner = ({ ...props }) => (
    <RipForm {...props}>
        <Sci />
        <MainResidence />
        <Property />
        <YesNoField name="haveCharges" title="Avez-vous d'autres crédits ?" required />
        <WithHaveCharges>
            {(haveCharges) => (haveCharges ? <RipChargeList /> : null)}
        </WithHaveCharges>
        <Divider />
        <TextAreaField title="Commentaires" name="estateComment" />
    </RipForm>
);

const Form = flowRight([
    dataForm({
        form: 'rip-estate',
        id: ({ id }) => id,
        type: 'rip',
        renamedFields: {
            mainResidenceEndLoan: 'mainResidenceDurationLoan',
        },
    }),
    withContext(
        {
            change: PropTypes.func.isRequired,
        },
        ({ change }) => ({ change }),
    ),
])(FormInner);

export default Form;
