import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'common/components/Icon';

const LogDetails = ({ details }) => {
    const [open, toggle] = useState(false);

    const toggleDetails = useCallback(() => toggle(!open), [open]);

    return (
        <td className={classNames('details', { open })}>
            <div
                onClick={toggleDetails}
                onKeyDown={toggleDetails}
                role="presentation"
            >
                <Icon
                    icon={`chevron-circle-${open ? 'up' : 'down'}`}
                />
                <pre onClick={toggleDetails} onKeyDown={toggleDetails} role="presentation">
                    <code>{JSON.stringify(details, null, 2)}</code>
                </pre>
            </div>
        </td>
    );
};

LogDetails.propTypes = {
    details: PropTypes.shape({}).isRequired,
};

export default LogDetails;
