import { isFunction, some } from 'lodash/fp';

const mergeChanges = (...changes) => Object.assign({}, ...changes);

const applyChange = (item, change) => {
    const date = new Date().toISOString();

    if (item) {
        return {
            ...item,
            ...change,
            updatedAt: date,
        };
    }

    return {
        ...change,
        createdAt: date,
        updatedAt: date,
    };
};

const applyCriteria = (db, criteria) => {
    if (null === criteria) {
        return db;
    }

    if (isFunction(criteria)) {
        return db.filter(criteria);
    }

    return db.where(criteria);
};

class Collection {
    constructor(api, storage) {
        this.api = api;
        this.storage = storage;
    }

    async sync() {
        await this.syncChanges();
        await this.syncItems();
    }

    async syncChanges() {
        const changes = await this.storage.changes.toArray();

        await Promise.all(changes.map(async ({ id, ...change }) => {
            await this.api.update(id, change);
            await this.storage.changes.delete(id);
        }));
    }

    async syncItems() {
        const lastSync = await this.storage.getLastSync();

        const items = await this.api.find(lastSync ? { since: lastSync } : null);

        await this.storage.items.bulkPut(items);
        await this.storage.setLastSync(new Date());
    }

    async trySync() {
        try {
            await this.sync();
        } catch (e) {
            if (e.status && e.status < 500) {
                throw e;
            }
        }
    }

    async find(criteria = null) {
        await this.trySync();

        const items = await applyCriteria(this.storage.items, criteria).toArray();
        const changes = await this.storage.changes.toArray();

        return items.map((item) => ({
            ...item,
            hasChanges: some({ id: item.id }, changes),
        }));
    }

    async get(id) {
        await this.trySync();

        const data = await this.storage.items.get(id);

        if (data.minified) {
            return this.api.get(id);
        }

        return data;
    }

    async update(id, change) {
        const savedChange = await this.storage.changes.get(id);
        const mergedChange = savedChange ? mergeChanges(savedChange, change) : change;

        try {
            const data = await this.api.update(id, mergedChange);

            await this.storage.items.put(data);

            if (savedChange) {
                await this.storage.changes.delete(id);
            }

            return data;
        } catch (e) {
            await this.storage.changes.put({
                ...mergedChange,
                id,
            });

            const item = await this.storage.items.get(id);

            const data = {
                ...applyChange(item, change),
                id,
            };

            await this.storage.items.put(data);

            return data;
        }
    }
}

export default Collection;
