import { set } from 'lodash/fp';

const listsReducer = (state = {}, { type, ...payload }) => {
    switch (type) {
        case 'LISTS_SEARCH':
            return set([payload.list, 'search'], payload.search, state);
        case 'LISTS_SORT':
            return set([payload.list, 'sort'], { property: payload.property, order: payload.order }, state);
        case 'LISTS_MODE':
            return set([payload.list, 'mode'], payload.mode, state);
        default:
            return state;
    }
};

export default listsReducer;
