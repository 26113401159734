import PropTypes from 'prop-types';
import React from 'react';
import { Grid, DateItem, TextItem, AmountItem, Item, PercentageItem } from 'common/components/summary';
import { initialFundingReasonTypes } from 'common/choices';
import Title from 'components/Title';
import Group from 'components/form/Group';

const Settings = ({
    simulation: {
        data: {
            entryFees,
            initialFunding,
            subscriptionDate,
            initialFundingReason,
        },
    },
}) => (
    <Group>
        <Title><b>Caractéristiques et paramètres</b></Title>
        <Grid size={2}>
            <DateItem value={subscriptionDate} title="Date de souscription" />
            <TextItem value="viagère" title="Durée" />
            <AmountItem value={initialFunding} title="Montant initial" />
            <PercentageItem value={entryFees} title="Frais d'entrée (%)" />
            <Item
                value={initialFundingReasonTypes[initialFundingReason]}
                title="Origine des fonds"
            />
        </Grid>
    </Group>
);

Settings.propTypes = {
    simulation: PropTypes.shape({
        data: PropTypes.shape({
            entryFees: PropTypes.number,
            initialFunding: PropTypes.number,
            subscriptionDate: PropTypes.string,
            initialFundingReason: PropTypes.string,
        }),
    }).isRequired,
};

export default Settings;
