import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import Title from '../Title';
import FormGroup from './Group';
import withScroll from './withScroll';
import { GridLayout } from './layout';
import CheckboxButton from './CheckboxButton';

const CheckboxQuestionInner = ({ choices, name, title, ...props }) => (
    <FormGroup>
        <Title>{title}</Title>
        <GridLayout {...props}>
            {Object.entries(choices).map(([key, label]) => (
                <Field
                    title={label}
                    component={CheckboxButton}
                    name={`${name}.${key}`}
                    key={key}
                />
            ))}
        </GridLayout>
    </FormGroup>
);

CheckboxQuestionInner.propTypes = {
    choices: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
};

const CheckboxQuestion = withScroll(CheckboxQuestionInner);

export default CheckboxQuestion;
