import React, { useState, useEffect } from 'react';
import { GET } from 'utils/httpMethods';
import withSpinner from '../../withSpinner';
import SyncLog from './SyncLog';

const SyncLogsInner = () => {
    const [logs, setLogs] = useState();

    useEffect(() => {
        (async () => {
            const response = await GET('/api/syncLogs');
            const data = await response.json();

            setLogs(data);
        })();
    }, []);

    return (
        <div className="relative-container">
            <div className="container payouts">
                {
                    logs
                        ? Object
                            .entries(logs)
                            .map(([date, sameDateLogs]) => (
                                <SyncLog key={date} date={date} logs={sameDateLogs} />
                            ))
                        : null
                }
            </div>
        </div>
    );
};

export default withSpinner(SyncLogsInner);
