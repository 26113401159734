import { get, set } from 'lodash/fp';
import computeInvestorProfile from 'common/model/rip/investorProfile';
import validate from 'common/formValidation/lib';

const checkInvestorProfile = (
    errors,
    { beneficiary },
    { rip, session },
) => {
    const investorProfile = computeInvestorProfile(get(beneficiary, rip.investorProfiles));

    if (!investorProfile || 'declined' === investorProfile) {
        return set('product', 'Questionnaire de risque non renseigné', errors);
    }

    if ('IDSOFT' === session.instanceSettings.label.toUpperCase() && !session.permissions.mayCreateSimulation(rip, 'lifeInsurance', beneficiary)) {
        return set('product', 'Recommendation non validé pour ce bénéficiaire', errors);
    }

    return errors;
};

const formValidate = validate([checkInvestorProfile]);

export default formValidate;
