import PropTypes from 'prop-types';
import { flowRight } from 'lodash/fp';
import React from 'react';
import { withProps } from 'recompose';
import { Field, reset, formValues } from 'redux-form';
import Icon from '../../../../common/components/Icon';
import dataCreationForm from '../../../dataCreationForm';
import withRouter from '../../../withRouter';

const FormInner = ({ message, handleSubmit }) => (
    <form className="form-add-comment" onSubmit={handleSubmit}>
        <Field
            name="text"
            component="textarea"
            required
            onKeyUp={(e) => {
                if ((13 === e.keyCode)
                    && e.ctrlKey
                    && (message.length > 0)
                ) {
                    handleSubmit(e);
                    e.preventDefault();
                }
            }}
            placeholder="Saisir une note"
        />
        {message.length > 0 ? (
            <button><Icon icon="paper-plane" /></button>
        ) : null}
    </form>
);

FormInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    message: PropTypes.string,
};

FormInner.defaultProps = {
    message: '',
};

const Form = flowRight([
    withRouter,
    withProps(({ params: { id } }) => ({
        initialValues: {
            ripId: id,
        },
    })),
    dataCreationForm({
        form: 'comment-creation',
        onSubmitSuccess: (result, dispatch) => dispatch(reset('comment-creation')),
        type: 'comment',
    }),
    formValues({
        message: 'text',
    }),
])(FormInner);

export default Form;
