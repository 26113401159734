// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-layout-horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .container-horizontal {
        width: 100%;
        display: flex;
        flex-direction: column;

        .container-input {
            width: 100%;
            display: flex;
            flex-direction: row;

            .form-group-horizontal {
                margin: 1rem 5rem 2rem 3rem; 
                width: 51rem;
            }
        }
    } 
}
`, "",{"version":3,"sources":["webpack://./src/components/form/layout/Horizontal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;;IAEnB;QACI,WAAW;QACX,aAAa;QACb,sBAAsB;;QAEtB;YACI,WAAW;YACX,aAAa;YACb,mBAAmB;;YAEnB;gBACI,2BAA2B;gBAC3B,YAAY;YAChB;QACJ;IACJ;AACJ","sourcesContent":[".form-layout-horizontal {\n    display: flex;\n    flex-wrap: wrap;\n    margin-bottom: 2rem;\n\n    .container-horizontal {\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n\n        .container-input {\n            width: 100%;\n            display: flex;\n            flex-direction: row;\n\n            .form-group-horizontal {\n                margin: 1rem 5rem 2rem 3rem; \n                width: 51rem;\n            }\n        }\n    } \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
