import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { reduxForm, formValues } from 'redux-form';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import FormGroup from 'components/form/Group';
import Title from 'components/Title';
import TypedCollection from 'components/form/TypedCollection';
import types from './LifePlansTypes';
import Error from '../../Error';

const LifePlansList = ({ familySituation, isErrors, submitSucceeded }) => {
    const lifePlansError = isErrors && isErrors.lifePlans ? isErrors.lifePlans : null;
    const partnerLifePlansError = isErrors && isErrors.partner && isErrors.partner.lifePlans ? isErrors.partner.lifePlans : null;

    return (
        <Fragment>
            <FormGroup>
                <Title>Quels sont vos <b>objectifs&nbsp;?*</b></Title>
                <TypedCollection textOnly name="lifePlans" types={types} />
                {submitSucceeded && lifePlansError && <Error errortext={lifePlansError} forceerror />}
            </FormGroup>
            {'cohabitation' === familySituation ? (
                <FormGroup>
                    <Title>Quels sont les <b>objectifs de votre concubin&nbsp;?*</b></Title>
                    <TypedCollection textOnly name="partner.lifePlans" types={types} />
                    {submitSucceeded && partnerLifePlansError && <Error errortext={partnerLifePlansError} forceerror />}
                </FormGroup>
            ) : null}
        </Fragment>
    );
};

LifePlansList.propTypes = {
    familySituation: PropTypes.string,
    isErrors: PropTypes.shape({
        lifePlans: PropTypes.string,
        partner: PropTypes.string,
    }),
    submitSucceeded: PropTypes.bool,
};

LifePlansList.defaultProps = {
    familySituation: null,
    submitSucceeded: false,
};

const mapStateToProps = (state) => ({
    isErrors: state.form['rip-profil'].syncErrors,
    submitSucceeded: state.form['rip-profil'].submitSucceeded,
});

export default flowRight([
    formValues('familySituation'),
    connect(mapStateToProps),
    reduxForm({
        form: 'rip-profil',
    }),
])(LifePlansList);
