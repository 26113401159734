import PropTypes from 'prop-types';
import React from 'react';
import { educationEstablishments } from 'common/choices';
import { ChoiceField, IntegerField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { education as computeAmountEducation } from 'common/model/summary/uncappedReductions';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const EducationReduction = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <ChoiceField name={`${name}.establishment`} title="Type" choices={educationEstablishments} required />
        <IntegerField name={`${name}.number`} title="Nombre d&apos;enfants (à charge)" required />
        <IntegerField name={`${name}.numberAlternateCustody`} title="Nombre d&apos;enfants (garde alternée)" required />
        <Recurrent name={name} />
        <DisabledTaxField
            name={name}
            computeAmount={computeAmountEducation}
            type="reduction"
            path="computedAmount"
        />
    </HorizontalLayout>
);

EducationReduction.propTypes = {
    name: PropTypes.string.isRequired,
};

export default EducationReduction;
