const getUploader = (contractId) => (docType) => ({
    options: {
        core: {
            multiple: false,
            debug: true,
        },
        camera: {
            ios: true,
        },
        deleteFile: {
            enabled: true,
            endpoint: `/api/contracts/${contractId}/upload/${docType}`,
        },
        request: {
            endpoint: `/api/contracts/${contractId}/upload/${docType}`,
        },
        session: {
            endpoint: `/api/contracts/${contractId}/upload/${docType}`,
        },
        retry: {
            enableAuto: true,
        },
        validation: {
            acceptFiles: 'image/jpeg,image/png,application/pdf',
            allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
            itemLimit: 1,
            sizeLimit: 5242880,
        },
    },
});

export default getUploader;
