import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './Divider.css';

const Divider = ({ className, ...props }) => (
    <div className={classNames('divider', className)} {...props} />
);

Divider.propTypes = {
    className: PropTypes.string,
};

Divider.defaultProps = {
    className: null,
};

export default Divider;
