import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Parts from './Parts';
import FiscalParticularCases from './FiscalParticularCases';
import Slices from './Slices';
import MicroBIC from './MicroBIC';
import MicroBNC from './MicroBNC';
import MicroBA from './MicroBA';
import TenPercentsAbatement from './TenPercentsAbatment';
import Decote from './Decote';
import ExtraReduction from './ExtraReduction';
import RentalPropertyWeight from './RentalPropertyWeight';

const IncomeTax = ({ settings, name }) => (
    <Fragment>
        <Parts name={`${name}.parts`} />
        <hr />
        <FiscalParticularCases name={`${name}.fiscalParticularCasesValues`} />
        <hr />
        <Slices settings={settings} name={name} />
        <hr />
        <MicroBIC name={`${name}.microBICAbatement`} />
        <MicroBNC name={`${name}.microBNCAbatement`} />
        <MicroBA name={`${name}.microBAAbatement`} />
        <hr />
        <TenPercentsAbatement name={`${name}.10percentAbatmentBounds`} />
        <hr />
        <Decote name={`${name}.decote`} />
        <hr />
        <ExtraReduction name={`${name}.extraReduction`} />
        <hr />
        <RentalPropertyWeight name={`${name}.rentalPropertyIncomesWeight`} />
    </Fragment>
);

IncomeTax.propTypes = {
    settings: PropTypes.shape({}).isRequired,
    name: PropTypes.string.isRequired,
};

export default IncomeTax;
