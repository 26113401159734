// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulation-form input[type=text],
.simulation-form input[type=date],
.simulation-form input[type=number],
.simulation-form .form-control {
    width: 100%;
}

.simulation-form .divider {
    width: 60%;
}
`, "",{"version":3,"sources":["webpack://./src/components/simulation/FormStep.css"],"names":[],"mappings":"AAAA;;;;IAII,WAAW;AACf;;AAEA;IACI,UAAU;AACd","sourcesContent":[".simulation-form input[type=text],\n.simulation-form input[type=date],\n.simulation-form input[type=number],\n.simulation-form .form-control {\n    width: 100%;\n}\n\n.simulation-form .divider {\n    width: 60%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
