import { flowRight } from 'lodash/fp';
import { connect } from 'react-redux';
import { branch, lifecycle, renderNothing } from 'recompose';
import handleError from 'utils/handleError';
import { setPdfEditions } from 'actions';
import { withDatabase } from '../../database';

const loadPdfEditions = (id) => (dispatch, { signatures }) => {
    dispatch(setPdfEditions());

    signatures.get(id)
        .then(({ pdfEditions }) => {
            if (pdfEditions) {
                dispatch(setPdfEditions(pdfEditions));
            }
        })
        .catch(handleError);
};

const pdfEditionsProvider = ({ id }) => flowRight([
    connect(null, { loadPdfEditions }),
    withDatabase,
    lifecycle({
        componentDidMount() {
            this.props.loadPdfEditions(id(this.props), this.props.database);
        },
    }),
    connect((state) => ({
        pdfViewer: state.pdfViewer,
        pdfEditions: state.pdfEditions,
    })),
    branch((props) => !props.pdfEditions, renderNothing),
]);

export default pdfEditionsProvider;
