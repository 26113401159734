import PropTypes from 'prop-types';
import React from 'react';
import { IntegerField, TextField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';

const Wealth = ({ name }) => (
    <HorizontalLayout>
        <IntegerField name={`${name}.amount`} title="Montant" />
        <TextField name={`${name}.specify`} title="Préciser" />
    </HorizontalLayout>
);

Wealth.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Wealth;
