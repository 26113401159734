import { acquisitionMethods } from '../choices';

const getSuffix = (acquisitionMethod) => {
    switch (acquisitionMethod) {
        case 'purchase':
            return 'l\'achat';
        default:
            return `la ${acquisitionMethods[acquisitionMethod].toLowerCase()}`;
    }
};

const getPrefix = (jointPossession) =>
    `Montant ${jointPossession ? 'total ' : ''}de `;

const acquisitionAmountTitle = (acquisitionMethod, jointPossession) =>
    `${getPrefix(jointPossession)} ${getSuffix(acquisitionMethod)}`;

export default acquisitionAmountTitle;
