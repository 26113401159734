import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import Icon from 'common/components/Icon';
import withRouter from './withRouter';

import './SuccessModal.scss';

const SuccessModal = ({ close, session }) => {
    let userTopinvest = false;
    if (undefined !== session) {
        const { initialValues: { user: { entity } } } = session;
        userTopinvest = 'Topinvest' === entity.name ? true : null;
    }

    return userTopinvest ? (
        <div className="modal-container">
            <Icon icon="times" className="close-action" onClick={close} />
            <p>Votre message a bien été envoyé !</p>
        </div>
    ) : (
        <div className="modal-container">
            <p>Votre message a bien été envoyé !</p>
            <button
                onClick={close}
                className="lastButtonRipForm sendButton"
                type="button"
            >
                Retour vers la liste des rips
            </button>
        </div>
    );
};

SuccessModal.propTypes = {
    close: PropTypes.func.isRequired,
    session: PropTypes.shape({
        initialValues: PropTypes.shape({
            user: PropTypes.shape({
                entity: PropTypes.shape({
                    name: PropTypes.string,
                }),
            }),
        }),
    }),
};

export default compose(
    withRouter,
    connect(null, (dispatch, { navigate, session }) => ({
        close: () => {
            let userTopinvest = false;
            if (undefined !== session) {
                const { initialValues: { user: { entity } } } = session;
                userTopinvest = 'Topinvest' === entity.name;
            }

            return userTopinvest ? (
                dispatch(setModal(null))
            ) : (
                dispatch(setModal(null)),
                navigate('/')
            );
        },
    })),
)(SuccessModal);
