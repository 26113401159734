import React from 'react';
import PropTypes from 'prop-types';
import FormButtons from 'components/FormButtons';
import requestApprovalFactory from '../../approval/requestApprovalFactory';
import Form from './Form';
import SimulationHeader from './Header';

export const ApprovalForm = ({
    handleSubmit,
    simulation: { needFunding },
    children,
    params: { id, simulationId },
}) => (
    <Form handleSubmit={handleSubmit} needFunding={needFunding}>
        <div className="simulation-form-inner">
            <div className="container">
                <SimulationHeader />
                {children}
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/simulation`}
            list={`/rip/${id}/simulations/`}
            nextButtonContent="Envoyer"
        />
    </Form>
);

ApprovalForm.propTypes = {
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    simulation: PropTypes.shape({
        needFunding: PropTypes.bool,
    }).isRequired,
    params: PropTypes.shape({
        id: PropTypes.string,
        simulationId: PropTypes.string,
    }),
};

export default requestApprovalFactory({ formComponent: ApprovalForm });
