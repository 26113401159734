import React from 'react';
import PropTypes from 'prop-types';
import { InfiniteScroll } from '../../list';

const List = ({ items, isLoading, listComponent }) => (
    <InfiniteScroll
        component={listComponent}
        items={items}
        isLoading={isLoading}
    />
);

List.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    isLoading: PropTypes.bool.isRequired,
    listComponent: PropTypes.func.isRequired,
};

export default List;
