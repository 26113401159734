import { flatten, flow, map, some, get, fromPairs } from 'lodash/fp';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, withProps } from 'recompose';
import { Fields } from 'redux-form';
import AsyncSelect from 'react-select/lib/Async';
import Error from 'components/Error';
import withScroll from 'components/form/withScroll';
import codeInsee from 'common/utils/codeInsee.json';
import tokenize from 'utils/tokenize';

export const mappedOptions = flow(
    Object.entries,
    map(([postalCode, cities]) => map(({ insee, name }) => {
        const key = `${postalCode}.${name}`;

        return [key, {
            key,
            postalCode,
            name,
            insee,
            label: `${name} (${postalCode})`,
            tokens: [...tokenize(postalCode), ...tokenize(name)],
        }];
    }, cities)),
    flatten,
    fromPairs,
)(codeInsee);

export const options = Object.values(mappedOptions);
export const noOptionsMessage = () => 'Aucun résultat';

export const customStyles = {
    singleValue: (provided) => ({
        ...provided,
        color: '#248fdf',
    }),
    control: (provided, { hasValue }) => (hasValue ? ({
        ...provided,
        borderColor: '#248fdf',
    }) : provided),
};

class CityField extends Component {
    constructor(props) {
        super(props);

        this.cache = {};

        this.loadOptions = this.loadOptions.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async onChange(value) {
        const {
            city: { input: { onChange: onChangeCity } },
            postalCode: { input: { onChange: onChangePostalCode } },
        } = this.props;

        const { name, postalCode } = value || { name: null, postalCode: null };

        onChangeCity(name);
        onChangePostalCode(postalCode);
    }

    get inputValue() {
        const {
            city: { input: { value: city } },
            postalCode: { input: { value: postalCode } },
        } = this.props;

        return city && postalCode ? get(`${postalCode}.${city}`, mappedOptions) : null;
    }

    async loadOptions(inputValue) {
        let filteredOptions = get(inputValue, this.cache);

        if (!filteredOptions) {
            filteredOptions = tokenize(inputValue).reduce(
                (results, word) => results.filter(({ tokens }) => some((token) => token.startsWith(word), tokens)),
                get(inputValue.slice(0, inputValue.length - 1), this.cache) || options,
            );

            this.cache[inputValue] = filteredOptions;
        }

        return filteredOptions.slice(0, 100);
    }

    render() {
        const {
            formGroup: Group,
            city: { meta: cityMeta },
            required,
        } = this.props;

        return (
            <Group
                component="label"
                htmlFor="city"
                required={required}
                title="Sélectionnez votre ville & code postal"
            >
                <AsyncSelect
                    className="react-select"
                    placeholder="Commune ou code postal"
                    noOptionsMessage={noOptionsMessage}
                    name="city"
                    isSearchable
                    isClearable
                    loadOptions={this.loadOptions}
                    getOptionValue={({ key }) => key}
                    getOptionLabel={({ label }) => label}
                    onChange={this.onChange}
                    value={this.inputValue}
                    styles={customStyles}
                />
                <Error {...cityMeta} />
            </Group>
        );
    }
}

CityField.propTypes = {
    city: PropTypes.shape({
        meta: PropTypes.shape({}),
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    postalCode: PropTypes.shape({
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    formGroup: PropTypes.func.isRequired,
    required: PropTypes.bool,
};

CityField.defaultProps = {
    required: false,
};

export default compose(
    withProps({
        component: CityField,
        names: ['city', 'postalCode'],
    }),
    getContext({ formGroup: PropTypes.func.isRequired }),
    withScroll,
)(Fields);
