import React from 'react';
import PropTypes from 'prop-types';
import { map, replace, get } from 'lodash/fp';
import classNames from 'classnames';
import moment from 'moment';
import zipcelx from 'zipcelx';
import Icon from 'common/components/Icon';
import { ripStates } from 'common/choices';
import { useOnline } from '../withOnline';
import { saveAndExportRips } from '../../utils/saveAndExportRips';

import './ExportButton.scss';

const formatPhone = replace(/ /g, '');

const header = [
    {
        value: 'Nom',
        type: 'string',
    },
    {
        value: 'Prénom',
        type: 'string',
    },
    {
        value: 'Téléphone portable',
        type: 'string',
    },
    {
        value: 'Téléphone',
        type: 'string',
    },
    {
        value: 'Adresse email',
        type: 'string',
    },
    {
        value: 'Date de création',
        type: 'string',
    },
    {
        value: 'Status',
        type: 'string',
    },
];

const getRow = ({ firstName, lastName, phone, mobile, personalEmail, createdAt, state }) => [
    {
        value: lastName,
        type: 'string',
    },
    {
        value: firstName,
        type: 'string',
    },
    {
        value: formatPhone(mobile),
        type: 'string',
    },
    {
        value: formatPhone(phone),
        type: 'string',
    },
    {
        value: personalEmail,
        type: 'string',
    },
    {
        value: moment(createdAt).format('DD/MM/YYYY'),
        type: 'string',
    },
    {
        value: get(state, ripStates),
        type: 'string',
    },
];

const onClick = (items, userId, userStatus) => () => {
    const ripsAExporter = saveAndExportRips(userId, items, userStatus, 'archives');

    const config = {
        filename: `export-archives-${moment().format('DDMMYYYY_HHmm')}`,
        sheet: {
            data: [header, ...map(getRow, ripsAExporter)],
        },
    };

    zipcelx(config);
};

const ExportButtonArchived = ({ items, userId, userStatus }) => {
    const onLine = useOnline();

    return (
        <button
            onClick={onClick(items, userId, userStatus)}
            title="Export Rips archivés"
            className={classNames('list-export-button', {
                disabled: !onLine,
            })}
            disabled={!onLine}
        >
            <Icon icon="download" />
        </button>
    );
};

ExportButtonArchived.propTypes = {
    userId: PropTypes.string,
    userStatus: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        civility: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        address: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        phone: PropTypes.string,
        personalEmail: PropTypes.string,
        birthDate: PropTypes.string,
    })).isRequired,
};

export default ExportButtonArchived;
