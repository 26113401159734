import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import { isFunction } from 'lodash/fp';
import Icon from 'common/components/Icon';

import './TypedBlock.scss';

const TypedBlock = ({
    children,
    title,
    deleteAction,
    name,
}) => (
    <div className="form-block-typed-collection-item">
        <div className="header">
            {deleteAction && (
                <button
                    type="button"
                    onClick={deleteAction}
                    className="add-btn"
                >
                    <Icon icon="times-circle" />
                </button>
            )}
            <div className="inner">
                {isFunction(title) ? createElement(title, { name }) : <p>{title}</p>}
            </div>
        </div>
        <div className="inner">
            {children}
        </div>
    </div>
);

TypedBlock.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]).isRequired,
    name: PropTypes.string,
    children: PropTypes.node.isRequired,
    deleteAction: PropTypes.func,
};

TypedBlock.defaultProps = {
    deleteAction: null,
};

export default TypedBlock;
