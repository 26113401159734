import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash/fp';
import { connect } from 'react-redux';
import { mapProps } from 'recompose';
import { FieldArray, fieldArrayPropTypes } from 'redux-form';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { processCollections, saveData } from 'actions';
import Error from '../Error';
import UntypedBlock from './UntypedBlock';

import './Collection.css';

const confirm =
    'Attention, la suppression de toutes les personnes à charge aura pour effet d\'effacer les évènements "départs du foyer fiscal", voulez-vous continuer ?';

const deleteEventsAction = (index, fds, dependents, familyEvents, change, name) => {
    const dependentDeleted = dependents.find((object, i) => name === `dependents[${i}]`);

    if (1 === dependents.length && familyEvents) {
        if (window.confirm(confirm)) {
            fds.remove(index);
            processCollections(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                change,
                null,
                familyEvents,
                dependents,
                null,
            );
        }
    } else {
        fds.remove(index);
        processCollections(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            change,
            null,
            familyEvents,
            dependents,
            dependentDeleted,
        );
    }
};

const Collection = ({
    itemComponent: Item,
    componentProps,
    fields,
    meta,
    initialValues,
    addLabel,
    deleteAction = (index, fds) => fds.remove(index),
    inline,
    dependents,
    familyEvents,
    change,
    deleteChildren,
}) => (
    <div className={classNames({ 'collection-inline': inline })}>
        {fields.map((name, index) => (
            <UntypedBlock deleteAction={deleteChildren ? () => deleteAction(index, fields) : () => deleteEventsAction(index, fields, dependents, familyEvents, change, name)} key={name}>
                <Item name={name} index={index} fields={fields} {...componentProps} />
            </UntypedBlock>
        ))}
        <div className="form-typed-collection-buttons">
            <button
                type="button"
                onClick={() => fields.push({ ...initialValues, id: uuidv4() })}
                className="textOnly untyped"
            >
                <p>{addLabel}</p>
            </button>
        </div>
        <Error {...meta} />
    </div>
);

Collection.propTypes = {
    ...fieldArrayPropTypes,
    itemComponent: PropTypes.func.isRequired,
    componentProps: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
    addLabel: PropTypes.string,
    inline: PropTypes.bool,
};

Collection.defaultProps = {
    initialValues: {},
    addLabel: 'Ajouter',
    inline: false,
};

export default flowRight([
    mapProps(({ component, ...props }) => ({
        ...props,
        itemComponent: component,
        component: Collection,
    })),
    connect(null, { saveData }),
])(FieldArray);
