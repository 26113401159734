import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { compose, mapProps, branch, renderNothing } from 'recompose';
import React from 'react';
import { Legend, Pie, PieChart } from 'recharts';
import { formatPercentage } from '../../utils/format';
import getSettings from '../../utils/settings';

const formatLabel = ({ value }) => formatPercentage(value);

const ProductChart = ({ data, colors, withCodes, UCCodesOnly, ...props }) => {
    const baseProducts = getSettings('simulation.lifeInsurance.baseProducts');

    return (
        <PieChart {...props}>
            <Pie
                data={data.map(({ baseProduct, proportion }, index) => {
                    const { label } = get(baseProduct, baseProducts);

                    return {
                        name: label,
                        value: proportion,
                        fill: colors[index],
                    };
                })}
                dataKey="value"
                nameKey="name"
                label={formatLabel}
                isAnimationActive={false}
                labelLine
                legendType="circle"
            />
            <Legend layout="vertical" verticalAlign="left" width={300} />
        </PieChart>
    );
};

ProductChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    withCodes: PropTypes.bool,
    UCCodesOnly: PropTypes.bool,
};

ProductChart.defaultProps = {
    withCodes: false,
    UCCodesOnly: false,
};

export default compose(
    mapProps(({ profile, contractType, ...props }) => ({
        data: getSettings(['simulation', 'lifeInsurance', 'types', contractType, 'profiles', profile, 'repartition']),
        ...props,
    })),
    branch(({ data }) => !data, renderNothing),
)(ProductChart);
