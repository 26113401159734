import { formValues } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import getOwnerName from '../../../common/utils/getOwnerName';
import { formatAmount } from '../../../common/utils/format';

const SalaryOptionInner = ({
    salary: { beneficiary, company, income, result, pension, description },
    firstName,
    partnerFirstName,
    familySituation,
    type,
    ...props
}) => {
    // rebuild a partial RIP (to get owner names)
    const rip = { familySituation, firstName, partnerFirstName };

    switch (type) {
        case 'BNC':
        case 'BIC':
        case 'BA':
            return (
                <option {...props}>
                    {`${type}: ${formatAmount(result)}${beneficiary ? ` (${getOwnerName(rip, beneficiary)})` : ''}`}
                </option>
            );
        case 'pension':
            return (
                <option {...props}>
                    {`${type}: ${formatAmount(pension)}${beneficiary ? ` (${getOwnerName(rip, beneficiary)})` : ''}`}
                </option>
            );
        case 'other':
            return (
                <option {...props}>
                    {`${description}: ${formatAmount(income)}${
                        beneficiary ? ` (${getOwnerName(rip, beneficiary)})` : ''
                    }`}
                </option>
            );
        case 'exemptedIncome':
            return (
                <option {...props}>
                    {`Revenu annuel exonéré: ${formatAmount(income)}${
                        beneficiary ? ` (${getOwnerName(rip, beneficiary)})` : ''
                    }`}
                </option>
            );
        default:
            return (
                <option {...props}>
                    {`${company ? `${company}: ` : ''}${formatAmount(income)}${
                        beneficiary ? ` (${getOwnerName(rip, beneficiary)})` : ''
                    }`}
                </option>
            );
    }
};

SalaryOptionInner.propTypes = {
    salary: PropTypes.shape({
        beneficiary: PropTypes.string,
        company: PropTypes.string,
        income: PropTypes.number,
        result: PropTypes.number,
        pension: PropTypes.number,
        description: PropTypes.string,
    }).isRequired,
    firstName: PropTypes.string,
    partnerFirstName: PropTypes.string,
    familySituation: PropTypes.string,
    type: PropTypes.string,
};

SalaryOptionInner.defaultProps = {
    firstName: '',
    partnerFirstName: '',
    familySituation: '',
};

const SalaryOption = formValues({
    firstName: 'firstName',
    partnerFirstName: 'partnerFirstName',
    familySituation: 'familySituation',
})(SalaryOptionInner);

export default SalaryOption;
