import React from 'react';
import FormGroup from 'components/form/Group';
import RadioQuestion from '../../../../InvestorProfile/RadioQuestion';
import './modality.scss';

const Modalite = () => (
    <FormGroup className="per-modalite" name="data.outputModality">

        <div>
            <RadioQuestion
                choices={{
                    capital: 'Sortie en capital',
                    rente: 'Sortie en rente',
                }}
                name="data.outputModality"
                title="Quelle est votre préférence de modalité de sortie (option qui pourra être modifée lors de la sortie) ?*"
            />

        </div>

    </FormGroup>
);

export default Modalite;
