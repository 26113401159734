import React from 'react';
import { SearchLayout } from '../layouts';
import ModeForm from './ModeForm';
import List from './List';
import { useSession } from '../sessions';

const PendingValidations = () => {
    const session = useSession();

    return (
        <SearchLayout
            className="pendingValidation-list"
            searchName="pendingValidation"
        >
            <div className="container">
                <p className="text-muted">
                    Ce tableau de bord vous permet de consulter les éléments en attentes de validation.
                </p>
            </div>
            <ModeForm />
            <List session={session} />
        </SearchLayout>
    );
};

export default PendingValidations;
