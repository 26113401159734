const isValidator = (state = false, { type, ...payload }) => {
    switch (type) {
        case 'SET_IS_VALIDATOR':
            return payload.isValidator;
        default:
            return state;
    }
};

export default isValidator;
