const usersRipStats = (state = {}, { type, ...payload }) => {
    switch (type) {
        case 'SET_USER_RIP_STATS':
            return payload.usersRipStats;
        default:
            return state;
    }
};

export default usersRipStats;
