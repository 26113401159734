import { get, mapValues, pickBy, flow } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formValues, reduxForm } from 'redux-form';
import { compose, mapProps, withPropsOnChange } from 'recompose';
import { setListMode } from 'actions';
import GridRadioChoices from 'components/form/GridRadioChoices';
import modeProvider from 'components/list/modeProvider';
import { withSession } from '../../../sessions';
import LeftComponent from './LeftComponent';
import modes from './modes';

import './ModeForm.scss';

const ModeForm = ({ choices, mode, setMode }) => {
    const [modeSelectionne, setModeSelectionne] = useState(mode);

    useEffect(() => {
        setModeSelectionne(mode);
    }, [mode]);

    useEffect(() => {
        if (setMode) {
            setMode(modeSelectionne);
        }
    }, [modeSelectionne]);

    return (
        <div className="container rip-mode-form">
            <GridRadioChoices
                choices={choices}
                name="mode"
                className="dynamic"
                leftComponent={LeftComponent}
                onChange={() => setMode(modeSelectionne)}
            />
        </div>
    );
};

ModeForm.propTypes = {
    choices: PropTypes.shape({}).isRequired,
    setMode: PropTypes.func,
    mode: PropTypes.string,
};

export default compose(
    modeProvider('initialMode', 'rip', 'user'),
    mapProps(({ initialMode: mode, setMode }) => ({ initialValues: { mode }, setMode })),
    reduxForm({
        form: 'rip-list-modes',
        onChange: async ({ mode }, dispatch) => dispatch(setListMode('rip', mode)),
    }),
    withSession,
    withPropsOnChange(
        // once session is context provided
        // we can start using this one to know if we've to update available modes
        ['session'],
        ({ session }) => ({
            choices: flow(
                pickBy((mode) => mode.isAvailable(session)),
                mapValues(get('label')),
            )(modes),
        }),
    ),
    formValues('mode'),
)(ModeForm);
