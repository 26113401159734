import React, { Component, Fragment } from 'react';
import { isEmpty, debounce } from 'lodash/fp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, formValueSelector, FormSection } from 'redux-form';
import { compose } from 'recompose';
import Icon from 'common/components/Icon';
import { GET } from 'utils/httpMethods';
import EmailTemplateForm from './EmailTemplateForm';
import Button from './Button';
import Dropdown from './Dropdown';
import withFormName from './form/withFormName';

class SingleEmailTemplateManager extends Component {
    constructor(props) {
        super(props);

        this.state = { template: null };

        // JS trick to keep this in memory to use it in bounded scope
        const manager = this;

        // we will save the last preview on async rendering for UX purposes
        this.lastPreview = '';
        // and we'll use integers as lock/index to ensure there's no wrong order in the rendering
        this.renderLocks = { lastRendered: 0, current: 0 };
        // we also debounce the asynchronous rendering to reduce the load on the network/server
        this.asyncPreviewRendering = debounce({ wait: 500, maxWait: 1000 }, this.asyncPreviewRendering.bind(this));
        // and lastly we prepare simple MDE options
        this.simpleMdeOptions = {
            previewRender(...args) {
                return manager.previewRender(this, ...args);
            },
        };
    }

    componentDidMount() {
        this.loadTemplate();
    }

    async loadTemplate() {
        const { templateName } = this.props;

        const response = await GET(`/api/users/emailTemplates/${templateName}`);
        const template = await response.json();

        this.setState({ template });
    }

    async asyncPreviewRendering(plainText, preview) {
        const { previewRender } = this.props;
        const lockId = this.renderLocks.current++;

        // call the rendering method
        const html = await previewRender(plainText);

        if (lockId >= this.renderLocks.lastRendered) {
            // update the lock id
            this.renderLocks.lastRendered = lockId;
            // update the preview
            this.lastPreview = html;
            // eslint-disable-next-line no-param-reassign
            preview.innerHTML = html;
        }
    }

    previewRender(options, plainText, preview) {
        const { previewRender } = this.props;

        if (previewRender) {
            // call the asynchronous rendering
            this.asyncPreviewRendering(plainText, preview);

            // return the last preview for now
            return this.lastPreview;
        }

        // let simpleMDE render the markdown
        return options.parent.markdown(plainText);
    }

    render() {
        const { template } = this.state;
        const { edited, update } = this.props;

        return (
            <FormSection name="temporaryEmailTemplate">
                <Dropdown
                    title={(
                        <Fragment>
                            <Icon
                                icon={`${edited ? 'check' : 'times'}-circle`}
                                className={classNames('fa-fw', { warning: !edited, success: edited })}
                            />
                            &nbsp;Notification prochain rendez-vous
                        </Fragment>
                    )}
                    className="email-template-manager"
                >
                    {
                        edited ? (
                            <Fragment>
                                <EmailTemplateForm
                                    variables={template.variables}
                                    simpleMdeOptions={this.simpleMdeOptions}
                                />
                                <div className="remove">
                                    <Button
                                        // we're going to remove the current template
                                        onClick={() => update(null)}
                                    >
                                        <Icon icon="trash" /> Annulation de la personnalisation du mail
                                    </Button>
                                </div>
                            </Fragment>
                        ) : (
                            <div className="setup">
                                <Button
                                    // we're going to add the new template
                                    onClick={() => update({
                                        subject: template.subject,
                                        body: template.body,
                                    })}
                                >
                                    <Icon icon="pencil" /> Personnaliser l&apos;email
                                </Button>
                            </div>
                        )
                    }
                </Dropdown>
            </FormSection>
        );
    }
}

SingleEmailTemplateManager.propTypes = {
    templateName: PropTypes.string.isRequired,
    edited: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    previewRender: PropTypes.func,
};

SingleEmailTemplateManager.defaultProps = {
    previewRender: null,
};

export default compose(
    withFormName,
    connect(
        (state, { form }) => ({
            edited: !isEmpty(formValueSelector(form)(state, 'temporaryEmailTemplate')),
        }),
        (dispatch, { form }) => ({
            update: (value) => dispatch(change(form, 'temporaryEmailTemplate', value)),
        }),
    ),
)(SingleEmailTemplateManager);
