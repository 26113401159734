import React from 'react';
import PropTypes from 'prop-types';
import './pdfComponent.scss';

const handlePdfClick = (route) => {
    fetch(route, { method: 'GET', mode: 'cors', cache: 'default' })
        .then((response) => response.blob())
        .then((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
};

const PdfComponent = ({ route }) => (
    <div
        onClick={() => handlePdfClick(route)}
        onKeyPress={() => handlePdfClick(route)}
        className="document-link"
        rel="noreferrer"
        role="link"
        tabIndex="0"
    >
        Consulter
    </div>
);

PdfComponent.propTypes = {
    route: PropTypes.string.isRequired,
};

export default PdfComponent;
