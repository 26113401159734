import { clamp } from 'lodash/fp';
import { parseInteger } from '../model/utils';

// reductionType correspond soit à "credit d'impots" soit à "reduction d'impots"
const calculationWithEndDate = (currentYear, reductionType, rate, bound) => {
    const endYear = !reductionType.recurrentEvent ? parseInteger(reductionType.endYear) : null;

    // condition et calcul pour : other
    if ('other' === reductionType.type) {
        if (endYear) {
            return currentYear <= endYear ? parseInteger(reductionType.amount) : 0;
        }

        return parseInteger(reductionType.amount);
    }

    // condition et calcul pour : homeWork
    if ('homeWork' === reductionType.type) {
        if (endYear) {
            return currentYear <= endYear ? parseFloat(reductionType.rate) * parseInteger(reductionType.amount) : 0;
        }

        return parseFloat(reductionType.rate) * parseInteger(reductionType.amount);
    }

    // condition et calcul pour : childCare, employeeAtHome
    if (endYear) {
        return currentYear <= endYear ? rate * clamp(0, bound)(parseInteger(reductionType.amount)) : 0;
    }

    return rate * clamp(0, bound)(parseInteger(reductionType.amount));
};

export default calculationWithEndDate;
