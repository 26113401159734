import { get } from 'lodash/fp';
import validate, {
    checkRequiredField, checkIntegerField, validateCollection,
    checkRequiredIntegerField,
    checkRequiredOwnerField,
    branchValidate,
} from '../lib';
import getSettings from '../../utils/settings';

const checkMicroEntrMaxIncome = (maxIncome) => checkIntegerField(
    (income) => income <= maxIncome,
    'Les revenus saisis ne permettent pas de bénéficier du statut de micro-entrepreneur',
);

const getMicroEntrValidators = (name, element) => {
    const parametreDePlafondMicroBIC = getSettings('summary.incomeTax.microBICPlafond');

    return [
        ...'trade' === get('activity', element) ?
            [checkMicroEntrMaxIncome(parametreDePlafondMicroBIC.commerce)(`${name}.result`)] :
            [],
        ...'services' === get('activity', element) || 'BNC' === get('type', element) ?
            [checkMicroEntrMaxIncome(parametreDePlafondMicroBIC.services)(`${name}.result`)] :
            [],
    ];
};

const validateFormCif = (name, element, values) => {
    const basicIncomeValidators = [
        checkRequiredOwnerField(`${name}.beneficiary`, values),
        checkRequiredIntegerField(`${name}.result`),
        checkRequiredIntegerField(`${name}.regime`),
    ];

    switch (get('type', element)) {
        case 'salary':
            return [
                checkRequiredOwnerField(`${name}.beneficiary`, values),
                checkRequiredIntegerField(`${name}.income`),
                ...(get('realExpenses', element) ? [
                    checkRequiredIntegerField(`${name}.realExpensesValue`),
                ] : []),
            ];
        case 'BNC':
        case 'BA':
            return [
                ...basicIncomeValidators,
                ...('micro' === get('regime', element) ?
                    getMicroEntrValidators(name, element) : []),
            ];
        case 'BIC':
            return [
                checkRequiredField(`${name}.activity`),
                ...basicIncomeValidators,
                ...('micro' === get('regime', element) ?
                    getMicroEntrValidators(name, element) : []),
            ];
        case 'landRentalIncome':
        case 'furnishedRentalIncome':
            return [
                checkRequiredIntegerField(`${name}.income`),
                ...('micro' !== get('regime', element) ?
                    basicIncomeValidators : [
                        checkRequiredOwnerField(`${name}.beneficiary`, values),
                        checkRequiredIntegerField(`${name}.regime`),
                    ]),
            ];
        case 'dividends':
        case 'exemptedIncome':
            return [
                checkRequiredIntegerField(`${name}.income`),
                checkRequiredOwnerField(`${name}.beneficiary`, values),
            ];
        case 'pension':
            return [
                checkRequiredOwnerField(`${name}.beneficiary`, values),
                checkRequiredIntegerField(`${name}.pension`),
            ];
        case 'other':
            return [
                checkRequiredIntegerField(`${name}.income`),
                checkRequiredOwnerField(`${name}.beneficiary`, values),
                checkRequiredField(`${name}.description`),
            ];
        case 'childrenIncome':
            return [
                checkRequiredField(`${name}.child`),
                checkRequiredIntegerField(`${name}.income`),
            ];
        default:
            return [];
    }
};

export default validate([
    branchValidate(
        (_, { isApproved }) => 'no' !== isApproved,
        [
            validateCollection('incomes', validateFormCif),
            checkRequiredField('fiscalPart'),
            checkRequiredField('reductionAmount'),
            checkRequiredField('fundingAmount'),
            checkRequiredField('costAmount'),
            checkRequiredField('investmentPercentage'),
        ],
    ),
]);
