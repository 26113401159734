import React, { useState, Fragment, useCallback } from 'react';
import { get } from 'lodash/fp';
import { compose } from 'recompose';
import { Field, formValues } from 'redux-form';
import PropTypes from 'prop-types';
import dataForm from 'components/dataForm';
import contractLoader from 'components/contract/contractLoader';
import PdfReader from 'components/contract/lifeInsurance/Documents/PdfReader';
import Checkbox from 'components/form/Checkbox';
import getWizardStep from 'utils/getWizardStep';
import ConnectedNextButton from 'components/FormButtons/buttons/ConnectedNextButton';
import processValues from '../processValues';
import FormButtons from '../../../FormButtons';
import Form from '../../../simulation/FormStep';
import getWizard from '../wizardData';
import onSubmitRedirect from '../onSubmitRedirect';
import getLifeInsuranceData from '../../../../common/utils/getLifeInsuranceData';
import withRouter from '../../../withRouter';

const internalDocs = {
    target: {
        insuranceAdvize: {
            checkbox: 'Je reconnais avoir pris connaissance du Document Conseil en assurance',
        },
    },
};

const InternalDocuments = ({ handleSubmit, readDocuments = {}, ...props }) => {
    const { params: { id, contractId, contractType } } = props;

    const wizard = getWizard(contractType);
    const baseUrl = wizard.prefixTemplate(props.params);
    const prevStep = getWizardStep({ wizard, url: props.location.pathname, next: false });

    const prevUrl = `${baseUrl}/${prevStep}`;

    const [isRead, setIsRead] = useState(false);

    const onRead = useCallback(() => setIsRead(true));

    const hasReadAll = Object.entries(readDocuments).reduce((allRead, [, hasRead]) => allRead && hasRead, true);

    return (
        <Form
            handleSubmit={handleSubmit}
            wizard={wizard}
        >
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    {Object.entries(internalDocs[getLifeInsuranceData(contractType, 'target')]).map(([docType, { checkbox }]) => (
                        <Fragment key={docType}>
                            <PdfReader file={`/api/contracts/${contractId}/documents/${docType}`} onRead={onRead} />
                            <Field
                                title={<span>{checkbox}</span>}
                                component={Checkbox}
                                name={`data.hasRead.${docType}`}
                                disabled={!isRead}
                            />
                        </Fragment>
                    ))}
                </div>
            </div>
            <FormButtons
                back={prevUrl}
                list={`/rip/${id}/contracts/`}
                nextButtonComponent={ConnectedNextButton}
                disabled={!hasReadAll}
            />
        </Form>
    );
};

InternalDocuments.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    readDocuments: PropTypes.shape({}),
    params: PropTypes.shape({
        contractType: PropTypes.string.isRequired,
        id: PropTypes.string,
        contractId: PropTypes.string,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default compose(
    withRouter,
    contractLoader,
    dataForm({
        autoSave: false,
        processValues,
        form: 'contract-internal-docs',
        id: ({ params: { contractId } }) => contractId,
        onSubmitSuccess: onSubmitRedirect,
        type: 'contract',
        validate: ({ data = {} }, props) => {
            const { contractType } = props.params;
            for (const doc of Object.keys(internalDocs[getLifeInsuranceData(contractType, 'target')])) {
                const value = get(['hasRead', doc], data);
                if (!value) {
                    return {
                        data: {
                            hasRead: {
                                [doc]: 'Vous devez accepter le document pour continuer',
                            },
                        },
                    };
                }
            }

            return {};
        },
    }),
    formValues({ readDocuments: 'data.hasRead' }),
)(InternalDocuments);
