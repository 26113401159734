import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createElement } from 'react';

import './Grid.css';

const Grid = ({ children, component, double, title, ...props }) => createElement(
    component,
    { className: classNames('form-group-grid', { double }), ...props },
    title ? createElement('h6', {}, title) : null,
    children,
);

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.string,
    double: PropTypes.bool,
    title: PropTypes.node,
};

Grid.defaultProps = {
    component: 'div',
    double: false,
};

export default Grid;
