import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { familySituations } from 'common/choices';
import { AmountItem, ChoiceItem, FloatItem, Grid } from 'common/components/summary';
import Title from 'components/Title';

const RipSummary = ({
    rip,
    summary,
}) => (
    <Fragment>
        <Title className="margin-top"><b>Récapitulatif</b> de votre situation</Title>
        <Grid size={3}>
            <AmountItem title="Revenus avant abattement" value={summary.rawIncomes} />
            <AmountItem title="Revenus après abattement" value={summary.incomes} />
            <AmountItem title="Réductions" value={summary.reductions} />
            <AmountItem title="Déductions" value={summary.deductions} />
            <ChoiceItem choices={familySituations} title="Situation familiale" value={rip.familySituation} />
            <FloatItem title="Nombre de parts" value={summary.parts} />
        </Grid>
    </Fragment>
);

RipSummary.propTypes = {
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
    }).isRequired,
    summary: PropTypes.shape({
        rawIncomes: PropTypes.number,
        incomes: PropTypes.number,
        reductions: PropTypes.number,
        deductions: PropTypes.number,
        parts: PropTypes.number,
    }).isRequired,
};

export default RipSummary;
