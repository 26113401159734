import validate from 'common/formValidation/lib';
import { validators as feesValidators } from '../../common/charges/FeesForm';
import { validators as propertyTaxValidators } from '../../common/charges/PropertyTaxForm';

const formValidate = validate([
    ...feesValidators,
    ...propertyTaxValidators,
]);

export default formValidate;
