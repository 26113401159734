import React from 'react';
import { formValues } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { InputField } from 'components/form/field';
import Collection from 'components/form/Collection';
import { VerticalLayout } from 'components/form/layout';
import dataForm from 'components/dataForm';
import RadioQuestion from 'components/InvestorProfile/RadioQuestion';
import Icon from 'common/components/Icon';
import validate, {
    checkRequiredField,
    checkIsPhoneNumber,
    checkIsEmail,
    validateCollection,
} from 'common/formValidation/lib';
import Sponsored from './Sponsored';

const choices = {
    yes: 'Oui, je souhaite parrainer mes proches',
    no: 'Non, je ne souhaite pas parrainer mes proches',
    later: "Oui, mais je n'ai personne en tête pour l'instant",
};

const Form = ({ wantToSponsor, handleSubmit }) => (
    <form onSubmit={handleSubmit}>
        <RadioQuestion
            choices={choices}
            name="wantToSponsor"
            title={<b>Faites bénéficier vos proches d&apos;un bilan Fiscal et Patrimonial !</b>}
            className="triple"
        />
        {'yes' === wantToSponsor && (
            <VerticalLayout>
                <Collection name="sponsorship" component={Sponsored} addLabel="Ajouter une personne" />
                <InputField
                    name="contactCGP"
                    title="Souhaitez vous envoyer un mail au CGP immédiatement ?"
                    type="checkbox"
                />
            </VerticalLayout>
        )}
        <hr />
        <button className="lastButtonRipForm sendButton" type="submit">
            Valider&nbsp;
            <Icon icon="angle-right" />
        </button>
    </form>
);

Form.propTypes = {
    wantToSponsor: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
};

Form.defaultProps = {
    wantToSponsor: null,
};

export default compose(
    dataForm({
        form: 'rip-sponsoring',
        id: ({ id }) => id,
        type: 'rip',
        autoSave: false,
        validate: validate([
            validateCollection('sponsorship', (name) => [
                checkRequiredField(`${name}.firstName`),
                checkRequiredField(`${name}.lastName`),
                checkRequiredField(`${name}.mail`),
                checkIsEmail(`${name}.mail`),
                checkIsPhoneNumber(`${name}.phone`),
            ]),
        ]),
    }),
    formValues({ wantToSponsor: 'wantToSponsor' }),
)(Form);
