import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Body, Head, Table } from '../list';
import { useSession } from '../sessions';
import ListItem from './ListItem';

const ListTable = ({ items, wrapped }) => {
    const session = useSession();

    return (
        <Table wrapped={wrapped}>
            <Head>
                {session.isAdmin ? (
                    <Fragment>
                        <th className="text-center">Nom du réseau</th>
                        <th className="text-center">Nom du conseiller</th>
                        <th className="text-center">Date d&apos;émission</th>
                        <th className="text-center">Montant TTC</th>
                        <th className="text-center">Lien de téléchargement</th>
                    </Fragment>
                ) : (
                    <Fragment>
                        <th className="text-center">Numéros de facture</th>
                        <th className="text-center">Date d&apos;émission</th>
                        <th className="text-center">Montant</th>
                        <th className="text-center">Statut</th>
                        <th className="text-center">Lien de téléchargement</th>
                    </Fragment>
                )}
            </Head>
            <Body>
                {items.map((item) => (
                    <ListItem key={item.id} item={item} />
                ))}
            </Body>
        </Table>
    );
};

ListTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        map: PropTypes.func,
    })),
    wrapped: PropTypes.bool,
};

ListTable.defaultProps = {
    wrapped: false,
};

export default ListTable;
