import React, { Fragment, useCallback, useState } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import Title from 'components/Title';
import Icon from 'common/components/Icon';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import FileInput from 'react-fine-uploader/file-input';
import { DELETE, POST } from 'utils/httpMethods';
import RipVars from './RipVars';
import FormFields from '../../FormFields';

import './EditForm.scss';

const uploader = (settingKey, addFile) => {
    const fineUploader = new FineUploaderTraditional({
        options: {
            request: {
                endpoint: `/api/settings/file/${settingKey}?signable=true`,
                method: 'PUT',
                inputName: 'annex',
            },
        },
    });

    fineUploader.on('complete', () => {
        addFile(fineUploader.methods.getUploads()[0]);
    });

    return fineUploader;
};

const FormInner = ({ initialValues, ...props }) => {
    const { global: { documents: { documents = [] } } } = initialValues;
    const [files, setFiles] = useState(documents);

    const addFile = useCallback(({ originalName: file, uuid: id }) => {
        setFiles([...files, { id, file, signable: false }]);
    }, [files, setFiles]);

    const toggleSignable = useCallback((fileToToggle) => async (e) => {
        e.preventDefault();
        const res = await POST(`/api/settings/file/toggleSignable/global.documents/${fileToToggle}`);
        const jsonRes = await res.json();
        setFiles(jsonRes.documents);
    }, [files, setFiles]);

    return (
        <Fragment>
            <h1 className="text-center">Documents pdf éditables</h1>
            <FormFields {...props} back="/admin/settings" last>
                <Tabs id="documents-tabs">
                    <Tab eventKey="documents" title="Documents">
                        <Title><b>Document pdf éditables disponibles</b></Title>
                        <div className="settings-upload-file">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Fichier</th>
                                        <th>Signable ?</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.map(({ file, signable, id }) => (
                                        <tr key={file}>
                                            <td>
                                                <Icon icon="file-pdf-o" />&nbsp;&nbsp;
                                                {file}
                                            </td>
                                            <td>{signable ? <Icon icon="check" className="text-success" /> : <Icon icon="times" className="text-danger" />}</td>
                                            <td>
                                                <button className="signable-btn" onClick={toggleSignable(id)}>
                                                    {signable ? 'Rendre non signable' : 'Rendre signable'}
                                                </button>
                                                <button onClick={async (e) => {
                                                    e.preventDefault();
                                                    const res = await DELETE(`/api/settings/file/signable/global.documents/${id}`);
                                                    const jsonRes = await res.json();
                                                    setFiles(jsonRes);
                                                }}
                                                >
                                                    <Icon icon="trash-o" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <FileInput
                                accept="application/pdf"
                                uploader={uploader('global.documents', addFile)}
                                text={{ selectFile: '+ Ajouter un document' }}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="variables" title="Variables">
                        <RipVars />
                    </Tab>
                </Tabs>
            </FormFields>
        </Fragment>
    );
};

FormInner.propTypes = {
    initialValues: PropTypes.shape({
        global: PropTypes.shape({
            documents: PropTypes.shape({
                documents: PropTypes.shape({}),
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

const Form = reduxForm({
    form: 'settings-simulation-edit',
    enableReinitialize: true,
})(FormInner);

export default Form;
