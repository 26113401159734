const choices = {
    single: 'Célibataire',
    married: 'Marié(e)',
    partner: 'Pacsé(e)',
    cohabitation: 'Concubinage',
    divorced: 'Divorcé(e)',
    widower: 'Veuf(ve)',
};

export default choices;
