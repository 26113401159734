import * as React from 'react';

const FreeAccomodationSVG = (props) => (
    <svg
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 29.6 29.8"
        style={{
            enableBackground: 'new 0 0 29.6 29.8',
        }}
        xmlSpace="preserve"
        {...props}
        className="freeAccomodationSVG"
    >
        <style>{'.st0{fill:#464646}'}</style>
        <g id="Calque_2">
            <g id="Calque_2-2">
                <path
                    className="st0"
                    d="M28.2 4.9h-3c.2-.5.3-1 .3-1.5 0-1.9-1.5-3.5-3.4-3.5-1 0-1.9.4-2.6 1.1C18.1-.3 16-.3 14.6 1c-1 1-1.3 2.5-.7 3.8h-3c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4h7.2v6.1h-3.3V9.2H12v5c0 1.3 1.1 2.4 2.4 2.4h10c1.3 0 2.4-1.1 2.4-2.4v-5H24v4.6h-3.1V7.7h7.2c.8 0 1.4-.6 1.4-1.4.1-.8-.5-1.4-1.3-1.4zm-6-2.1c.4 0 .7.3.7.7s-.3.7-.7.7-.7-.3-.7-.7.3-.7.7-.7zm-5 0c.4 0 .7.3.7.7s-.3.7-.7.7-.7-.3-.7-.7.3-.7.7-.7zM20.7 23.5H18V23c-.1-.8-.6-1.6-1.2-2.1L10.4 16c-.3-.2-.7-.3-1.1-.3H3.9c-2.1 0-3.9 1.7-3.9 3.9v.7c0 2.1 1.7 3.9 3.9 3.9.4 0 .8.1 1.1.4l5.8 4.6c.6.5 1.3.7 2 .7h.1l7.9-.1c1.7 0 3.1-1.4 3.1-3.1-.1-1.8-1.5-3.2-3.2-3.2zm0 3.4-7.9.1c-.1 0-.2 0-.2-.1l-5.8-4.6c-.8-.6-1.8-1-2.9-1-.6 0-1.1-.5-1.1-1.1v-.7c0-.6.5-1.1 1.1-1.1h5.2l6 4.6c.2.1.2.4.1.6-.1.1-.2.1-.3.2-.1 0-.2 0-.3-.1l-1.7-1.1c-.7-.4-1.5-.1-1.9.5-.4.6-.2 1.4.4 1.8l1.5 1c.5.4 1.2.6 1.9.6h.4c.4-.1.8-.2 1.1-.4h4.3c.2 0 .3.1.3.3s-.1.5-.2.5z"
                />
            </g>
        </g>
    </svg>
);

export default FreeAccomodationSVG;
