import PropTypes from 'prop-types';
import React from 'react';
import SelectField from './Select';

const ChoiceField = ({ choices, placeholder, ...props }) => (
    <SelectField {...props}>
        {null !== placeholder ? <option>{placeholder}</option> : null}
        {Object.entries(choices).map(([key, value]) => (
            <option key={key} value={key}>{value}</option>
        ))}
    </SelectField>
);

ChoiceField.propTypes = {
    choices: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    placeholder: PropTypes.string,
};

ChoiceField.defaultProps = {
    placeholder: '',
};

export default ChoiceField;
