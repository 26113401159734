import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { deferralTypes, handlingsOfLeapInterests, handlingsOfBrokerageFees } from 'common/choices';
import { GridLayout } from 'components/form/layout';
import { ChoiceField, DisabledField, EditableField, IntegerField } from 'components/form/field';
import FieldInfo from 'components/form/FieldInfo';

const DetailFormInner = ({
    fundingType,
    leapInterestsAmount,
    fundedMonths,
}) => (
    <Fragment>
        <GridLayout className="condensed">
            <ChoiceField
                name="data.handlingOfFraisCourtage"
                choices={handlingsOfBrokerageFees}
                title="Prise en charge des frais de courtage"
                required
            />
            <IntegerField name="data.fraisCourtage" title="Frais de courtage" required />
        </GridLayout>
        <GridLayout className="condensed">
            <ChoiceField
                name="data.handlingOfBankApplicationFees"
                choices={handlingsOfBrokerageFees}
                title="Prise en charge des frais de dossier bancaire"
                required
            />
            <IntegerField name="data.bankApplicationFees" title="Frais de dossier bancaire" required />
        </GridLayout>
        <GridLayout className="condensed">
            <ChoiceField
                name="handlingOfBrokerageFees"
                choices={handlingsOfBrokerageFees}
                title="Prise en charges des frais d'ingénierie"
                required
            />
            <IntegerField
                name="brokerageFees"
                title={(
                    <div>
                        Frais d&apos;ing&eacute;nierie
                        <FieldInfo id="brokerageFeesInfo">
                            liés au cabinet
                        </FieldInfo>
                    </div>
                )}
                required
            />
        </GridLayout>
        {'depreciable' === fundingType ? (
            <GridLayout className="condensed">
                <ChoiceField
                    choices={deferralTypes}
                    name="deferralType"
                    title="Type du différé (à la livraison)"
                    required
                />
                <IntegerField name="deferralDuration" title="Durée du différé (mois)" min={0} />
            </GridLayout>
        ) : null}
        <GridLayout className="condensed">
            <ChoiceField
                name="handlingOfLeapInterests"
                choices={handlingsOfLeapInterests}
                title="Prise en charge des intérêts intercalaires"
                required
            />
            <DisabledField
                name="fundedMonths"
                title="Nombre de mois à financer"
                value={fundedMonths || ''}
            />
            <EditableField
                name="leapInterestsAmount"
                editName="editableLeapInterestsAmount"
                title="Montant des intérêts intercalaires"
                defaultValue={leapInterestsAmount}
            />
        </GridLayout>
    </Fragment>
);

DetailFormInner.propTypes = {
    fundedMonths: PropTypes.number,
    leapInterestsAmount: PropTypes.number,
    fundingType: PropTypes.string,
};

DetailFormInner.defaultProps = {
    fundedMonths: null,
    leapInterestsAmount: null,
    fundingType: null,
};

const DetailForm = formValues({
    fundingType: 'fundingType',
})(DetailFormInner);

export default DetailForm;
