/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connectDisabledForm } from './withDisabledForm';

import './HorizontalRadioButton.scss';

const id = ({ name, value }) => `${name}-${value}`;

const HorizontalRadioButton = ({ icon, path, title, disabled, scale, isBoxFailed = false, setRenderRecipientShoyo = null, setTargetPartnerBoxShoyo = null, isPartner = false, ...props }) => (
    <Fragment>
        <label
            className={classNames('form-radio-button', {
                selected: props.onlyClient || props.checked,
                small: !title || !(icon || path),
                disabled,
            })}
            htmlFor={id(props)}
        >
            <input id={id(props)} type="radio" {...props} disabled={disabled} />
            {icon || path ? <FontAwesomeIcon icon={icon} path={path} className={scale && 'scale'} /> : null}
            {title ? <p>{title}</p> : null}
        </label>
        {isBoxFailed &&
            <div >
                <a onClick={() => { setRenderRecipientShoyo(false); setTargetPartnerBoxShoyo(isPartner); }}>
                    <div style={{ color: 'red', whiteSpace: 'pre-line', fontSize: '14px' }}>
                        La création de coffre-fort n’a pas pu aboutir <br />Cliquez pour plus
                        d&apos;information
                    </div>
                </a>

            </div>
        }
    </Fragment>
);

HorizontalRadioButton.propTypes = {
    checked: PropTypes.bool,
    icon: PropTypes.string,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    onlyClient: PropTypes.bool,
    scale: PropTypes.bool,
    isPartnerBoxShoyoCreated: PropTypes.bool,
    isSameEmail: PropTypes.bool,
    isBoxFailed: PropTypes.bool,
    setRenderRecipientShoyo: PropTypes.func,
    setTargetPartnerBoxShoyo: PropTypes.func,
    isPartner: PropTypes.bool,
};

HorizontalRadioButton.defaultProps = {
    checked: false,
    icon: null,
    path: null,
    title: '',
};

export default connectDisabledForm(HorizontalRadioButton);
