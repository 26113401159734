import React from 'react';
import { WizardLayout } from 'components/layouts';
import wizard from './wizardData';

import './Disclaimer.css';
import PdfReader from '../contract/lifeInsurance/Documents/PdfReader';

const Notices = () => (
    <WizardLayout wizard={wizard}>
        <div className="container disclaimer">
            <h1>Conditions Générales de ventes</h1>
            <PdfReader file="/api/documents/pdf/cgv" />
        </div>
    </WizardLayout>
);

export default Notices;
