import scpiSettingsValidators from './scpiSettingsValidation';
import lifeInsuranceSettingsValidators from './lifeInsuranceSettingsValidation';
import pinelSettingsValidators from './pinelSettingsValidation';
import perSettingsValidators from './perSettingsValidation';

const validators = (settings) => [
    ...scpiSettingsValidators(settings),
    ...lifeInsuranceSettingsValidators(settings),
    ...pinelSettingsValidators(settings),
    ...perSettingsValidators(settings),
];

export default validators;
