const userMenuVisibility = (state = false, { type }) => {
    switch (type) {
        case 'TOGGLE_USER_MENU':
            return !state;
        default:
            return state;
    }
};

export default userMenuVisibility;
