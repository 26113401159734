const questionnaireAMFEstComplete = (client) => undefined !== client && (undefined === client.decline || !client.decline);

const affichageCheckboxCertificationRenseignements = (hasAVSubscription, ripEnCours, partenaire) => {
    if (hasAVSubscription && ripEnCours.investorProfiles) {
        if (partenaire) {
            return questionnaireAMFEstComplete(ripEnCours.investorProfiles.partner);
        }

        return questionnaireAMFEstComplete(ripEnCours.investorProfiles.client);
    }

    return false;
};

export default affichageCheckboxCertificationRenseignements;
