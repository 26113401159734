import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValues } from 'redux-form';
import { compose } from 'recompose';
import { Layout } from 'components/layouts';
import { FileInputField } from 'components/form/field';
import FormFields from 'components/admin/FormFields';
import useFetch from 'components/useFetch';
import Icon from 'common/components/Icon';

import './Upload.scss';
import withRouter from '../withRouter';

const NextButton = ({ onClick }) => (
    <div className="formNextAction">
        <button onClick={onClick}>
            Continuer
            <Icon icon="angle-right" />
        </button>
    </div>
);

NextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const UploadDocument = ({ params: { id: ripId, docId }, completedDocument, ...props }) => {
    const [response, loading] = useFetch({ url: `/api/documents/${docId}/fileName` });

    return (
        <Layout hasFooter>
            <FormFields back={`/rip/${ripId}/documents`} {...props}>
                <h1 className="text-center">
                    {!loading && response.fileName}
                </h1>
                <p className="text-center">
                    Veuillez charger la version complète du document
                </p>
                <div className="editable-pdf-form">
                    <FileInputField name="completedDocument" shouldValidate={false} deletable />
                </div>
            </FormFields>
        </Layout>
    );
};

UploadDocument.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        docId: PropTypes.string.isRequired,
    }).isRequired,
    completedDocument: PropTypes.shape({}),
};

export default compose(
    withRouter,
    reduxForm({
        form: 'upload-final-pdf',
        onSubmit: (values, dispatch, props) => {
            const { params: { id: ripId, docId }, navigate } = props;
            navigate(`/rip/${ripId}/documents/${docId}/check`, { state: { file: values.completedDocument } });
        },
        validate: (values) => {
            if (!values.completedDocument) {
                return { completedDocument: 'Veuillez charger un fichier' };
            }

            return {};
        },
    }),
    formValues('completedDocument'),
)(UploadDocument);
