import React, { Fragment, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import { validateSimulationSettings } from 'common/formValidation/settings';
import { simulationsTypes } from 'common/choices/settings/settingsGroups';
import FormFields from '../../FormFields';
import ScpiSettings from './scpi/ScpiSettings';
import PlafondLoyersPinel from './pinel/PlafondLoyersPinel';
import LifeInsuranceSettings from './lifeInsurance/LifeInsuranceSettings';
import CreateNewScpiType from './scpi/CreateNewScpiType';
import CreateNewLifeInsuranceType from './lifeInsurance/CreateNewLifeInsuranceType';
import CreateNewLifeInsuranceBaseProducts from './lifeInsurance/CreateNewLifeInsuranceBaseProducts';
import LegalDisclaimers from './legalDisclaimer/LegalDisclaimers';
import PerSettings from './PER/PerSettings';
import CreateNewSupportPER from './PER/CreateNewSupportPER';
import CreateNewContractPER from './PER/CreateNewContractPER';
import ModeForm from './ModeForm';

const renderSettings = (type, settings) => {
    switch (type) {
        case 'scpi':
        case 'dismemberedScpi':
            return <ScpiSettings type={type} settings={settings} />;
        case 'lifeInsurance':
            return <LifeInsuranceSettings settings={settings} />;
        default:
            return null;
    }
};

const FormInner = ({ initialValues: { simulation: settings }, ...props }) => {
    const [activeForm, setActiveForm] = useState(null);
    const [simulationMode, setSimulationMode] = useState('scpi');

    useEffect(() => {
        setActiveForm(null);
    }, [simulationMode]);

    return (
        <Fragment>
            <h1 className="text-center">
                Paramètres de Simulation
            </h1>
            <ModeForm setMode={setSimulationMode} />
            {'scpi' === simulationMode &&
                <div className="container create-btns">
                    <CreateNewScpiType
                        hiddenAll={activeForm && activeForm !== 'scpi'}
                        hideForm={() => setActiveForm(null)}
                        showForm={() => setActiveForm('scpi')}
                        type="scpi"
                        form="simulation-scpi-create"
                    />
                    <CreateNewScpiType
                        hiddenAll={activeForm && activeForm !== 'dismemberedScpi'}
                        hideForm={() => setActiveForm(null)}
                        showForm={() => setActiveForm('dismemberedScpi')}
                        type="dismemberedScpi"
                        form="simulation-dismemberedScpi-create"
                    />
                </div>}
            {'av' === simulationMode &&
                <div className="container create-btns">
                    <CreateNewLifeInsuranceBaseProducts
                        hiddenAll={activeForm && activeForm !== 'baseProduct'}
                        hideForm={() => setActiveForm(null)}
                        showForm={() => setActiveForm('baseProduct')}
                    />
                    <CreateNewLifeInsuranceType
                        baseProducts={settings.lifeInsurance.baseProducts}
                        hiddenAll={activeForm && activeForm !== 'av'}
                        hideForm={() => setActiveForm(null)}
                        showForm={() => setActiveForm('av')}
                    />
                </div>}
            {'per' === simulationMode &&
                <div className="container create-btns">
                    <CreateNewSupportPER
                        hiddenAll={activeForm && activeForm !== 'support'}
                        hideForm={() => setActiveForm(null)}
                        showForm={() => setActiveForm('support')}
                        form="simulation-per-supports-create"
                    />
                    <CreateNewContractPER
                        baseProducts={settings.per.baseProducts}
                        hiddenAll={activeForm && activeForm !== 'per'}
                        hideForm={() => setActiveForm(null)}
                        showForm={() => setActiveForm('per')}
                    />
                </div>}
            <FormFields {...props} back="/admin/settings">
                <Tabs id="simulation-tabs">
                    {
                        Object
                            .entries(settings)
                            .map(([type, subSettings]) => (
                                <Tab eventKey={type} title={simulationsTypes[type]} key={type}>
                                    {renderSettings(type, subSettings)}
                                </Tab>
                            ))
                    }
                    <Tab eventKey="legalDisclaimer" title="Mentions légales">
                        <LegalDisclaimers settings={settings} />
                    </Tab>
                    <Tab eventKey="rentCeiling" title="Pinel">
                        <PlafondLoyersPinel settings={settings} />
                    </Tab>
                    <Tab eventKey="per" title="PER">
                        <PerSettings settings={settings} />
                    </Tab>
                </Tabs>
            </FormFields>
        </Fragment>
    );
};

FormInner.propTypes = {
    initialValues: PropTypes.shape({
        simulation: PropTypes.shape({
            lifeInsurance: PropTypes.shape({
                baseProducts: PropTypes.shape({}),
            }),
            per: PropTypes.shape({
                baseProducts: PropTypes.shape({}),
            }),
        }).isRequired,
    }).isRequired,
};

const Form = reduxForm({
    form: 'settings-simulation-edit',
    enableReinitialize: true,
    validate: validateSimulationSettings,
})(FormInner);

export default Form;
