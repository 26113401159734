import classNames from 'classnames';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { Field, fieldPropTypes } from 'redux-form';
import DropDown from 'components/Dropdown';
import Icon from 'common/components/Icon';

const FieldDropDown = ({ input, meta: { error }, title, ...props }) => (
    <DropDown
        title={(
            <Fragment>
                <Icon
                    icon={`${error ? 'times' : 'check'}-circle`}
                    className={classNames('fa-fw', { success: !error, danger: !!error })}
                />
                &nbsp;{title}
            </Fragment>
        )}
        {...props}
    />
);

FieldDropDown.propTypes = {
    ...fieldPropTypes,
    title: PropTypes.node.isRequired,
};

export default withProps({
    component: FieldDropDown,
})(Field);
