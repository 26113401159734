import { get } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSession } from '../../sessions';

const Logo = () => {
    const session = useSession();

    return session.settings &&
        (
            'Topinvest' === session.entity.name ?
                (
                    <img src={session.settings.logo} alt={get('entity.name', session)} />
                ) : (
                    <Link title="Retourner à la liste" to={session.signatureOnly ? '/signatures' : '/'}>
                        <img src={session.settings.logo} alt={get('entity.name', session)} />
                    </Link>
                )
        );
};

export default Logo;
