import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { saveData, setPdfEditionContent } from 'actions';
import { Layout } from 'components/layouts';
import FormButtons from 'components/FormButtons';
import ToolBox from './ToolBox';
import PdfCanvas from './PdfCanvas';
import pdfEditionsProvider from './pdfEditionsProvider';
import mapPrefixToProps from '../mapPrefixToProps';
import withRouter from '../../withRouter';

import './Edition.scss';

const Edition = ({ params: { id }, handleSubmit, prefix }) => (
    <Layout hasFooter>
        <form className="edition-pdf-form" onSubmit={handleSubmit} id="main-form">
            <div className="edition-page">
                <ToolBox />
                <PdfCanvas />
            </div>
            <FormButtons back={`${prefix}/signatures/${id}/documents`} list={`${prefix}/signatures`} />
        </form>
    </Layout>
);

Edition.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    prefix: PropTypes.string.isRequired,
};

export default flowRight([
    withRouter,
    mapPrefixToProps,
    pdfEditionsProvider({
        id: ({ params: { id } }) => id,
    }),
    reduxForm({
        form: 'edition-pdf-form',
        onChange: (values, dispatch, props) => {
            const id = props.pdfEditions.activeId;
            dispatch(setPdfEditionContent(id, values[`${id}-content`]));
        },
        onSubmit: (
            values,
            dispatch,
            { params: { id }, pdfEditions, pdfViewer: { pages } },
        ) => dispatch(saveData('signature', id, { pdfEditions: { pages, ...pdfEditions } })),
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id },
            prefix,
        }) => navigate(`${prefix}/signatures/${id}/signature`),
    }),
])(Edition);
