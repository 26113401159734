import { flowRight, get } from 'lodash/fp';
import { withProps } from 'recompose';
import computeInvestorProfile from 'common/model/rip/investorProfile';
import baseSimulationLoader from '../../simulationLoader';

const simulationLoader = flowRight([
    baseSimulationLoader,
    withProps(({ simulation: { beneficiary }, rip: { investorProfiles } }) => ({
        investorProfile: computeInvestorProfile(get(beneficiary, investorProfiles)),
    })),
]);

export default simulationLoader;
