import React from 'react';
import GrandParent from './GrandParent';
import Group from '../../form/Group';
import TypedCollectionDropDowns from '../../form/TypedCollectionDropDowns';
import TypedBlockCollectionItem from '../../form/TypedBlockCollectionItem';

const parentsMaternelsTitre = 'Parents maternels';
const parentsPaternelsTitre = 'Parents paternels';

const types = {
    maternalGrandParents: {
        component: GrandParent,
        title: parentsMaternelsTitre,
        itemTitle: () => parentsMaternelsTitre,
        unique: true,
    },
    paternalGrandParents: {
        component: GrandParent,
        title: parentsPaternelsTitre,
        itemTitle: () => parentsPaternelsTitre,
        unique: true,
    },
};

const GrandParents = () => (
    <Group title="Parents">
        <TypedCollectionDropDowns
            name="grandParents"
            types={types}
            textOnly
            itemComponent={TypedBlockCollectionItem}
        />
    </Group>
);

export default GrandParents;
