import { flowRight } from 'lodash/fp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import TransferOwnership from 'components/TransferOwnership';
import Icon from '../../../common/components/Icon';
import { Item as BaseItem, Initials, connectActiveItem, Action } from '../../list';
import { getUsersRipStats, setModal } from '../../../actions';
import fullName from '../../../common/utils/fullName';
import RipList from './RipList';

import './Item.css';

const ItemInner = ({ user, userActive, toggle, isActive, getStats, usersRipStats, transferOwnership }) => {
    const toggleAndGetStats = () => {
        getStats(user.id);
        toggle();
    };

    return (
        <Fragment>
            <BaseItem
                className={classNames('team-list-item', {
                    expanded: isActive,
                    'active-item': isActive,
                    'inactive-item-with-rips': 'isInactiveWithRips' === userActive,
                    'inactive-item': 'isInactiveWithoutRips' === userActive,
                    'active-items': 'activeItems' === userActive,
                })}
                key={`${user.id}.user`}
                onClick={toggleAndGetStats}
            >
                <Initials firstName={user.firstName} lastName={user.lastName} />
                <td>
                    <div className="username">{fullName(user)}</div>
                    {
                        (isActive && usersRipStats) ? (
                            <Fragment>
                                <div className="rip-stats">
                                    <div className="rip-stat-title">Rips signés&nbsp;:</div>
                                    <div>
                                        <span className="stat-value">{usersRipStats.nbRipWeek}</span>
                                        <span className="stat-legend">Cette semaine</span>
                                    </div>
                                    <div>
                                        <span className="stat-value">{usersRipStats.nbRipMonth}</span>
                                        <span className="stat-legend">Ce mois ci</span>
                                    </div>
                                    <div>
                                        <span className="stat-value">{usersRipStats.nbRipYear}</span>
                                        <span className="stat-legend">Cette année</span>
                                    </div>
                                </div>
                                <div>
                                    <Action
                                        className="transfer-rips"
                                        onClick={() => transferOwnership(user.id)}
                                        icon="arrows-h"
                                        text="Réaffecter les Rips"
                                    />
                                </div>
                            </Fragment>
                        ) : null
                    }
                </td>
                <td className="team-list-icon">
                    <span>
                        <Icon icon={`chevron-${isActive ? 'down' : 'left'}`} />
                    </span>
                </td>
                <td className="team-list-actions" />
            </BaseItem>
            {isActive ? (
                <tr key={`${user.id}.rips`}>
                    <td colSpan={4} className="team-list-rips">
                        <RipList userId={user.id} listeDeMesEquipes items={[{}]} />
                    </td>
                </tr>
            ) : null}
        </Fragment>
    );
};

export const userShape = {
    id: PropTypes.string,
    firstName: PropTypes.string,
};

ItemInner.propTypes = {
    user: PropTypes.shape(userShape).isRequired,
    toggle: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    usersRipStats: PropTypes.shape({
        nbRipWeek: PropTypes.number,
        nbRipMonth: PropTypes.number,
        nbRipYear: PropTypes.number,
    }),
    getStats: PropTypes.func.isRequired,
    transferOwnership: PropTypes.func.isRequired,
    userActive: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    getStats: (userId) => dispatch(getUsersRipStats(userId)),
    transferOwnership: (selectedUserId) =>
        dispatch(setModal(() => <TransferOwnership userId={selectedUserId} forManager />)),
});

const mapStateToProps = (state) => ({
    usersRipStats: state.usersRipStats,
});

const Item = flowRight([
    connectActiveItem(
        ({ user: { id } }) => id,
        (item, { activeItem }) => ({
            isActive: item === activeItem,
        }),
    ),
    withProps(({ isActive, hide, show }) => ({
        toggle: isActive ? hide : show,
    })),
    connect(mapStateToProps, mapDispatchToProps),
])(ItemInner);

export default Item;
