// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-create-link {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: block;
    background-color: rgb(40, 228, 234);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.2);
}

.list-create-link i {
    color: white;
    text-align: center;
    font-size: 26px;
    padding: 13px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/list/CreateLink.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,cAAc;IACd,mCAAmC;IACnC,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,+CAA+C;AACnD;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".list-create-link {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    display: block;\n    background-color: rgb(40, 228, 234);\n    width: 50px;\n    height: 50px;\n    border-radius: 25px;\n    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.2);\n}\n\n.list-create-link i {\n    color: white;\n    text-align: center;\n    font-size: 26px;\n    padding: 13px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
