import classNames from 'classnames';
import { flowRight, omit, pick } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { getContext, withProps } from 'recompose';
import { Field, fieldPropTypes } from 'redux-form';
import Error from '../../Error';
import NoLayout from '../group/NoLayout';

import './TextArea.css';

/**
 * Props passed to the textarea instead of the group
 */
const textareaProps = ['placeholder', 'disabled'];

const TextAreaInner = ({ formGroup: Group, input: { name, value, ...input }, meta, ...props }) => (
    <Group component="label" htmlFor={name} {...omit(textareaProps, props)}>
        <textarea
            id={name}
            className={classNames('form-control', 'form-field-text-area', { filled: value })}
            name={name}
            value={value}
            {...pick(textareaProps, props)}
            {...input}
            required={props.required}
        />
        <Error {...meta} />
    </Group>
);

TextAreaInner.propTypes = {
    ...fieldPropTypes,
    formGroup: PropTypes.func,
};

TextAreaInner.defaultProps = {
    formGroup: NoLayout,
};

const TextArea = flowRight([
    withProps({
        component: TextAreaInner,
    }),
    getContext({
        formGroup: PropTypes.func,
    }),
])(Field);

export default TextArea;
