import PropTypes from 'prop-types';
import React from 'react';
import { DateField, TextField, PercentageField } from '../../../form/field';
import { HorizontalLayout } from '../../../form/layout';

const Beneficiary = ({ name }) => (
    <HorizontalLayout>
        <TextField name={`${name}.lastName`} title="Nom" required />
        <TextField name={`${name}.firstName`} title="Prénom" required />
        <DateField name={`${name}.birthDate`} title="Date de naissance" required />
        <TextField name={`${name}.birthPlace`} title="Lieu de naissance" required />
        <TextField name={`${name}.address`} title="Adresse" required />
        <PercentageField name={`${name}.proportion`} title="Quote-part" required />
    </HorizontalLayout>
);

Beneficiary.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Beneficiary;
