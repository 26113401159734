/* eslint-disable array-callback-return */
import { get } from 'lodash/fp';

const isNullorUndefined = (value) => {
    if (undefined !== value || null !== value) {
        return value;
    }

    return null;
};

const multipleChoiceQuestion = (investorProfile, choices) => {
    const totalPoints = [];
    let result;
    Object.entries(choices).map((e) => {
        const questionKey = e[0];
        if (true === get(`${questionKey}`, investorProfile)) {
            totalPoints.push(e[1].point[0]);
            const initialValue = 0;
            result = { point: [totalPoints.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue)] };
        }
    });
    if (result && result.point[0] > 0 && isNullorUndefined(result.point[0])) {
        return result;
    }
    result = { point: [0] };

    return result;
};

export default multipleChoiceQuestion;
