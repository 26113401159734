import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import { POST } from 'utils/httpMethods';
import SelectRecipients from './SelectRecipients';
import './EmailPreviewed.scss';

class EmailPreviewed extends Component {
    constructor(props) {
        super(props);

        this.loadTemplate = this.loadTemplate.bind(this);

        this.state = {
            htmlTemplate: null,
        };
    }

    componentDidMount() {
        this.loadTemplate();
    }

    async loadTemplate() {
        const { id, template } = this.props;
        const response = await POST(`/api/rips/${id}/emailPreview/customerEmail`, {
            body: {
                temporaryEmailTemplate: JSON.stringify({
                    subject: '',
                    body: template.body,
                }),
            },
            credentials: 'include',
        });

        const htmlTemplate = await response.json();

        this.setState({ htmlTemplate: htmlTemplate.body });
    }

    render() {
        const { selectRecipients } = this.props;

        return (
            <Fragment>
                <div className="email-previewer">
                    <h3>
                        Prévisualisation du mail
                    </h3>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="email" dangerouslySetInnerHTML={{ __html: this.state.htmlTemplate }} />
                    <button className="sendButton" onClick={selectRecipients}>Selectionner les destinataires</button>
                </div>
            </Fragment>

        );
    }
}

EmailPreviewed.propTypes = {
    selectRecipients: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    template: PropTypes.shape({
        body: PropTypes.shape({}),
    }).isRequired,
};

export default connect(
    null,
    (dispatch, { id, customAttachment, template }) => ({
        selectRecipients: () => dispatch(setModal(() => (
            <SelectRecipients
                id={id}
                customAttachment={customAttachment}
                temporaryEmailTemplate={template}
            />
        ))),
    }),
)(EmailPreviewed);
