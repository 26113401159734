const isRequiredGrandChildrenAndPartnerField = (dateCreatedRip) => {
    // date de la mep
    // const dateLimit = new Date('2023-04-13T00:00:00.000Z');

    // date pour le test
    const dateLimit = new Date('2023-04-07T00:00:00.000Z');
    const createdDateRip = new Date(dateCreatedRip);

    return createdDateRip >= dateLimit;
};

export default isRequiredGrandChildrenAndPartnerField;
