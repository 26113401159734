import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField } from 'components/form/field';

const HomeWork = ({ name }) => (
    <Fragment>
        <Title>Travaux à domicile</Title>
        <GridLayout className="double condensed full-width">
            <PercentageFloatField
                name={`${name}.rate1`}
                title="Taux de crédit 1 (%)"
            />
            <PercentageFloatField
                name={`${name}.rate2`}
                title="Taux de crédit 2 (%)"
            />
        </GridLayout>
    </Fragment>
);

HomeWork.propTypes = {
    name: PropTypes.string.isRequired,
};

export default HomeWork;
