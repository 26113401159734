import React from 'react';
import PropTypes from 'prop-types';

const Li = ({ icon, children, className, ...props }) => (
    <li {...props}><i className={`fa-li fa fa-${icon}${className ? ` ${className}` : ''}`} />{children}</li>
);

Li.defaultProps = {
    className: '',
};

Li.propTypes = {
    icon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Li;
