import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { scroller } from 'react-scroll';
import { PercentageField } from '../../components/form/field';
import Checkbox from '../form/Checkbox';
import CheckboxQuestion from '../form/CheckboxQuestion';
import Form from './Form';
import formProvider from './formProvider';
import FormButtons from '../FormButtons';
import RadioQuestion from './RadioQuestion';

import {
    financialKnowledge,
    legalKnowledge,
    investmentKnowledge,
    investmentPossession,
    possibilityOfSouscription,
    yesNoConductFinancialTransactionsWithoutAdvisor,
    yesNoConductFinancialTransactionsWithAdvisor,
    yesNoDelegatingPortfolio,
    doneInvestmentTypes,
    investmentInFinancialSupport,
    yesNoTradingLossKnowledge,
    downwardPrediction,
    yesNoSufferTradingLosses,
    doneReactionOnLoss,
    supportLoss,
    investmentStress,
    availableSavings,
    investmentTimeHorizon,
    percentageInvestment,
    investmentDescription,
    favoriteEvolution,
    lifeInsuranceSouscription,
    lifeInsuranceDown,
    yesNoLifeInsuranceLosses,
    lifeInsuranceCauses,
    diferenceFondEurosAndUnity,
    drivenManagement,
    drivenFree,
    discretionaryManagement,
    investmentPeriod,
    objectBeneficiaryClause,
    clauseOrModels,
    // investissement durable
    yesNoDurableInvestment,
    advisorTakesEnviromentalCriteria,
    choicesEnvironmentalCriteria,
    advisorTakeSocialCriteria,
    choicesSocialCriteria,
    advisorTakeGovernanceCriteria,
    choicesGovernanceCriteria,
    lowerPerformanceForESGInvestments,
    isPoliticallyExposedOptions,
} from '../../common/choices/investorProfile';

const scrollTo = (field) => () => scroller.scrollTo(`scroll.${field}`, {
    smooth: true,
    containerId: 'main',
    offset: -110,
});

const SurveyInner = ({
    decline,
    getFieldName,
    handleSubmit,
    investorProfile: { path: back, label },
    lifeInsuranceLosses,
    sufferTradingLosses,
    durableInvestment,
    enviromentalCriteria,
    socialCriteria,
    governanceCriteria,
}) => (
    <Form handleSubmit={handleSubmit}>
        <div className="container">
            <Field
                title="Je ne souhaite pas répondre à ce questionnaire de connaissances règlementaire. Mon conseiller ne pourra donc réaliser aucune préconisation."
                component={Checkbox}
                name={getFieldName('decline')}
            />
            {!decline ? (
                <div>
                    <p className="bold">
                        Connaissance et expérience
                    </p>
                    <RadioQuestion
                        choices={financialKnowledge}
                        name={getFieldName('financialKnowledge')}
                        title="Comment considérez-vous votre connaissance financière&nbsp;?"
                        className="triple"
                        onChange={scrollTo(getFieldName('legalKnowledge'))}
                    />
                    <RadioQuestion
                        choices={legalKnowledge}
                        name={getFieldName('legalKnowledge')}
                        title="Comment considérez-vous votre connaissance juridique&nbsp;?"
                        className="triple"
                        onChange={scrollTo(getFieldName('investmentKnowledge'))}
                    />
                    <CheckboxQuestion
                        choices={investmentKnowledge}
                        name={getFieldName('investmentKnowledge')}
                        title="Quels sont les supports de placement que vous connaissez&nbsp;?"
                        onChange={scrollTo(getFieldName('investmentPossession'))}
                    />
                    <CheckboxQuestion
                        choices={investmentPossession}
                        name={getFieldName('investmentPossession')}
                        title="Quels sont les supports de placement que vous possédez&nbsp;?"
                        onChange={scrollTo(getFieldName('possibilityOfSouscription'))}
                    />
                    <RadioQuestion
                        choices={possibilityOfSouscription}
                        name={getFieldName('possibilityOfSouscription')}
                        title="De ces 3 placements, lequel pourriez-vous souscrire&nbsp;?"
                        className="single"
                        onChange={scrollTo(getFieldName('yesNoConductFinancialTransactionsWithoutAdvisor'))}
                    />
                    <RadioQuestion
                        choices={yesNoConductFinancialTransactionsWithoutAdvisor}
                        name={getFieldName('yesNoConductFinancialTransactionsWithoutAdvisor')}
                        title={
                            <span>Avez-vous déjà réalisé des opérations financières <b>sans</b> conseiller financier&nbsp;?</span>}
                        helpText="Ex. compte en ligne, bourse en ligne …"
                        onChange={scrollTo(getFieldName('yesNoConductFinancialTransactionsWithAdvisor'))}
                    />
                    <RadioQuestion
                        choices={yesNoConductFinancialTransactionsWithAdvisor}
                        name={getFieldName('yesNoConductFinancialTransactionsWithAdvisor')}
                        title={<span>Avez-vous déjà réalisé des opérations financières <b>avec</b> un conseiller financier&nbsp;?</span>}
                        helpText="Ex. avec votre banquier ou un CGP …"
                        onChange={scrollTo(getFieldName('yesNoDelegatingPortfolio'))}
                    />
                    <RadioQuestion
                        choices={yesNoDelegatingPortfolio}
                        name={getFieldName('yesNoDelegatingPortfolio')}
                        title="En cas de réalisation d'investissement financiers, déléguez-vous la gestion de tout ou partie de votre portefeuille de valeurs mobilières&nbsp;?"
                        onChange={scrollTo(getFieldName('doneInvestmentTypes'))}
                    />
                    <CheckboxQuestion
                        choices={doneInvestmentTypes}
                        name={getFieldName('doneInvestmentTypes')}
                        title="Sur quels types de placements avez-vous déjà investi&nbsp;?"
                        className="single"
                        onChange={scrollTo(getFieldName('investmentInFinancialSupport'))}
                    />
                    <CheckboxQuestion
                        choices={investmentInFinancialSupport}
                        name={getFieldName('investmentInFinancialSupport')}
                        title="Avez-vous déjà investi dans les supports financiers suivants&nbsp;?"
                        onChange={scrollTo(getFieldName('yesNoTradingLossKnowledge'))}
                    />
                    <RadioQuestion
                        choices={yesNoTradingLossKnowledge}
                        name={getFieldName('yesNoTradingLossKnowledge')}
                        title="Les placements réalisés sur les actions, obligations ou fonds commun de placement, peuvent-ils subir une perte en capital&nbsp;? *"
                        className="triple"
                        onChange={scrollTo(getFieldName('downwardPrediction'))}
                    />

                    <p className="text-muted margin-bottom-form text-muted wording">
                        * Les placements réalisés sur les actions, obligations ou fonds commun de placement, peuvent subir une perte en capital.
                    </p>
                    <RadioQuestion
                        choices={downwardPrediction}
                        name={getFieldName('downwardPrediction')}
                        title="Les marchés financiers peuvent monter ou baisser, si dans les 5 ans votre placement baissait, quelle serait votre réaction&nbsp;?"
                        className="single"
                        onChange={scrollTo(getFieldName('sufferTradingLosses'))}
                    />
                    <RadioQuestion
                        choices={yesNoSufferTradingLosses}
                        name={getFieldName('sufferTradingLosses')}
                        title="Avez-vous déjà subi des pertes en investissant sur des produits financiers hors assurance vie&nbsp;?"
                        onChange={scrollTo(getFieldName('yes' === sufferTradingLosses
                            ? 'doneReactionOnLoss'
                            : 'investmentStress'))}
                    />
                    {'yes' === sufferTradingLosses ? (
                        <RadioQuestion
                            choices={doneReactionOnLoss}
                            name={getFieldName('doneReactionOnLoss')}
                            title="Quelle a été votre réaction en cas de perte d'argent&nbsp;?"
                            onChange={scrollTo(getFieldName('supportLoss'))}
                        />
                    ) : null}
                    {'yes' === sufferTradingLosses ? (
                        <RadioQuestion
                            choices={supportLoss}
                            name={getFieldName('supportLoss')}
                            title="Sur quels supports avez-vous subi des pertes&nbsp;?"
                            onChange={scrollTo(getFieldName('investmentStress'))}
                        />
                    ) : null}
                    <RadioQuestion
                        choices={investmentStress}
                        name={getFieldName('investmentStress')}
                        title="Êtes-vous stressé par vos investissements financiers&nbsp;?"
                        className="single"
                        onChange={scrollTo(getFieldName('availableSavings'))}
                    />
                    <p className="bold">
                        Souhait d&apos;investissement
                    </p>
                    <RadioQuestion
                        choices={availableSavings}
                        name={getFieldName('availableSavings')}
                        title="Pensez vous avoir besoin de la somme investie pendant la durée de placement envisagée&nbsp;?"
                        className="triple"
                        onChange={scrollTo(getFieldName('investmentTimeHorizon'))}
                    />
                    <RadioQuestion
                        choices={investmentTimeHorizon}
                        name={getFieldName('investmentTimeHorizon')}
                        title="Quel est votre horizon de placement&nbsp;?"
                        onChange={scrollTo(getFieldName('percentageInvestment'))}
                    />
                    <RadioQuestion
                        choices={percentageInvestment}
                        name={getFieldName('percentageInvestment')}
                        title="Quel est le pourcentage de votre épargne disponible que vous souhaitez attribuer à ce placement&nbsp;?"
                        onChange={scrollTo(getFieldName('investmentDescription'))}
                    />
                    <RadioQuestion
                        choices={investmentDescription}
                        name={getFieldName('investmentDescription')}
                        title="Quelle phrase correspond au mieux à votre souhait d'investissement&nbsp;?"
                        className="single"
                        onChange={scrollTo(getFieldName('favoriteEvolution'))}
                    />
                    <RadioQuestion
                        choices={favoriteEvolution}
                        name={getFieldName('favoriteEvolution')}
                        title="Quelle évolution préférez-vous pour votre capital&nbsp;?"
                        className="single"
                        onChange={scrollTo(getFieldName('yesNoDurableInvestment'))}
                    />
                    <RadioQuestion
                        choices={yesNoDurableInvestment}
                        name={getFieldName('yesNoDurableInvestment')}
                        title="Etes-vous susceptible d’être intéressé par l’investissement durable&nbsp;?"
                        onChange={scrollTo(getFieldName('yes' === yesNoDurableInvestment
                            ? 'advisorTakesEnviromentalCriteria'
                            : 'yesNoDurableInvestment'))}
                    />

                    {'yes' === durableInvestment ? (
                        <div>
                            <PercentageField
                                title={<span> Dans quelle proportion souhaitez-vous prendre en compte des critères de placement durable, sociaux et environnementaux pour votre investissement (%): </span>}
                                name={getFieldName('investmentRatio')}
                                min={0}
                                max={100}
                                isRequired
                            />

                            <RadioQuestion
                                choices={advisorTakesEnviromentalCriteria}
                                name={getFieldName('advisorTakesEnviromentalCriteria')}
                                title="Souhaitez-vous que votre conseiller prenne en compte des critères Environnementaux&nbsp;?"
                                onChange={scrollTo(getFieldName('yes' === advisorTakesEnviromentalCriteria
                                    ? 'choicesEnvironmentalCriteria'
                                    : 'advisorTakeSocialCriteria'))}
                            />
                            { 'yes' === enviromentalCriteria ? (
                                <CheckboxQuestion
                                    choices={choicesEnvironmentalCriteria}
                                    name={getFieldName('choicesEnvironmentalCriteria')}
                                    title="Lesquels&nbsp;?"
                                />

                            ) : null }
                            <RadioQuestion
                                choices={advisorTakeSocialCriteria}
                                name={getFieldName('advisorTakeSocialCriteria')}
                                title="Souhaitez-vous que votre conseiller prenne en compte des critères Sociaux&nbsp;?"
                                onChange={scrollTo(getFieldName('yes' === advisorTakeSocialCriteria
                                    ? 'choicesSocialCriteria'
                                    : 'advisorTakeGovernanceCriteria'))}

                            />
                            { 'yes' === socialCriteria ? (
                                <CheckboxQuestion
                                    choices={choicesSocialCriteria}
                                    name={getFieldName('choicesSocialCriteria')}
                                    title="Lesquels&nbsp;?"
                                    className="single"
                                />
                            ) : null }

                            <RadioQuestion
                                choices={advisorTakeGovernanceCriteria}
                                name={getFieldName('advisorTakeGovernanceCriteria')}
                                title="Souhaitez-vous que votre conseiller prenne en compte des critères de Gouvernance&nbsp;?"
                                onChange={scrollTo(getFieldName('yes' === advisorTakeGovernanceCriteria
                                    ? 'choicesGovernanceCriteria'
                                    : 'lowerPerformanceForESGInvestments'))}
                            />

                            {'yes' === governanceCriteria ? (
                                <CheckboxQuestion
                                    choices={choicesGovernanceCriteria}
                                    name={getFieldName('choicesGovernanceCriteria')}
                                    title="Lesquels&nbsp;?"
                                />
                            ) : null }

                            <RadioQuestion
                                choices={lowerPerformanceForESGInvestments}
                                name={getFieldName('lowerPerformanceForESGInvestments')}
                                title="Êtes-vous prêt à accepter des performances moindres en cas d’investissements répondant à des critères d’ESG &nbsp;?"
                            />
                        </div>
                    ) : null }

                    <p className="bold">
                        L&apos;assurance vie
                    </p>
                    <RadioQuestion
                        choices={lifeInsuranceSouscription}
                        name={getFieldName('lifeInsuranceSouscription')}
                        title="Avez-vous déjà souscrit un contrat d'assurance-vie&nbsp;?"
                        onChange={scrollTo(getFieldName('lifeInsuranceDown'))}
                    />
                    <RadioQuestion
                        choices={lifeInsuranceDown}
                        name={getFieldName('lifeInsuranceDown')}
                        title="Dans l'hypothèse où le capital de contrat d'assurance vie est à la baisse&nbsp;?"
                        onChange={scrollTo(getFieldName('lifeInsuranceLosses'))}
                    />
                    <RadioQuestion
                        choices={yesNoLifeInsuranceLosses}
                        name={getFieldName('lifeInsuranceLosses')}
                        title="Avez-vous subi des pertes sur votre contrat d'assurance vie&nbsp;?"
                        onChange={scrollTo(getFieldName('yes' === lifeInsuranceLosses
                            ? 'lifeInsuranceCauses'
                            : 'diferenceFondEurosAndUnity'))}
                    />
                    {'yes' === lifeInsuranceLosses ? (
                        <RadioQuestion
                            choices={lifeInsuranceCauses}
                            name={getFieldName('lifeInsuranceCauses')}
                            title="Si oui, pour quelle raison&nbsp;?"
                            onChange={scrollTo(getFieldName('diferenceFondEurosAndUnity'))}
                        />
                    ) : null}
                    <RadioQuestion
                        choices={diferenceFondEurosAndUnity}
                        name={getFieldName('diferenceFondEurosAndUnity')}
                        title="Connaissez vous la différence entre les fonds en euros et les fonds en unités de compte dans un contrat d’assurance vie&nbsp;?"
                        onChange={scrollTo(getFieldName('drivenManagement'))}
                    />
                    <RadioQuestion
                        choices={drivenManagement}
                        name={getFieldName('drivenManagement')}
                        title="Connaissez vous les modalités d'une gestion pilotée&nbsp;?"
                        onChange={scrollTo(getFieldName('drivenFree'))}
                    />
                    <RadioQuestion
                        choices={drivenFree}
                        name={getFieldName('drivenFree')}
                        title="Connaissez vous les modalités d'une gestion libre&nbsp;?"
                        onChange={scrollTo(getFieldName('discretionaryManagement'))}
                    />
                    <RadioQuestion
                        choices={discretionaryManagement}
                        name={getFieldName('discretionaryManagement')}
                        title="Connaissez vous les modalités d'une gestion sous mandat&nbsp;?"
                        onChange={scrollTo(getFieldName('investmentPeriod'))}
                    />
                    <RadioQuestion
                        choices={investmentPeriod}
                        name={getFieldName('investmentPeriod')}
                        title="Sur quelle durée souhaitez vous réaliser ce type de placement&nbsp;?"
                        className="triple"
                        onChange={scrollTo(getFieldName('objectBeneficiaryClause'))}
                    />
                    <RadioQuestion
                        choices={objectBeneficiaryClause}
                        name={getFieldName('objectBeneficiaryClause')}
                        title="Savez vous quel est l'objet de la clause bénéficiaire&nbsp;?"
                        onChange={scrollTo(getFieldName('clauseOrModels'))}
                    />
                    <RadioQuestion
                        choices={clauseOrModels}
                        name={getFieldName('clauseOrModels')}
                        title="Souhaitez vous rédiger la clause bénéficiaire ou utiliser les modèles proposés&nbsp;?"
                        onChange={scrollTo(getFieldName('clauseOrModels'))}
                    />

                    <RadioQuestion
                        choices={isPoliticallyExposedOptions}
                        name={getFieldName('isPoliticallyExposedOptions')}
                        title="Etes vous considéré comme une Personne Politiquement Exposée (une personne qui a occupé ou occupe une fonction publique importante au sein d’un gouvernement, d’une organisation nationale ou internationale ou encore d’une administration importante. Les PPE, du fait de leur statut et de leur influence, sont davantage exposées à des activités financières sensibles et leur implication peut engendrer un risque plus élevé en matière de blanchiment de capitaux, financement de terrorisme ou corruption)&nbsp;?"
                    />

                    <p className="bold">
                        En l&apos;absence de réponse à ce questionnaire, mon conseiller ne pourra pas me préconiser un investissement en adéquation à ma situation
                    </p>
                </div>
            ) : null}
        </div>
        <FormButtons
            list={null}
            back={back}
            nextButtonContent={decline ? label : 'Continuer'}
        />
    </Form>
);

SurveyInner.propTypes = {
    decline: PropTypes.bool,
    getFieldName: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    investorProfile: PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    lifeInsuranceLosses: PropTypes.string,
    sufferTradingLosses: PropTypes.string,
    durableInvestment: PropTypes.string,
    enviromentalCriteria: PropTypes.string,
    socialCriteria: PropTypes.string,
    governanceCriteria: PropTypes.string,
};

SurveyInner.defaultProps = {
    decline: false,
    lifeInsuranceLosses: 'no',
    sufferTradingLosses: 'no',
    durableInvestment: 'no',
    enviromentalCriteria: 'no',
    socialCriteria: 'no',
    governanceCriteria: 'no',
};

const Survey = formProvider(SurveyInner);

export default Survey;
