import { find } from 'lodash/fp';
import hasPartner from '../utils/hasPartner';

export const parseInteger = (value) => {
    const parsedValue = parseInt(value, 10);

    if (Number.isNaN(parsedValue)) {
        return 0;
    }

    return parsedValue;
};

export const sumOrAdd = (collection, type, element) => {
    const current = collection[type];

    return {
        ...collection,
        [type]: current
            ? {
                reportedAmount: current.reportedAmount + element.reportedAmount,
                computedAmount: current.computedAmount + element.computedAmount,
            }
            : element,
    };
};

export const getSciShareFromAmount = (value, rip, owner) => {
    if (!owner) {
        return value;
    }

    const match = owner.match(/^sci-(.*)/);
    let share = 100;
    if (match) {
        // il faut recuperer la quote part du propriétaire.
        const sci = find({ id: match[1] }, rip.sci);
        // TODO: Gérer les concubinages.
        // Pas fait car bug actuellement sur le summary.
        share = parseInt(sci.yourShare || 0, 10);

        if (hasPartner(rip.familySituation)) {
            share += parseInt(sci.partnerShare || 0, 10);
        }
    }

    return (share / 100) * value;
};
