import React from 'react';
import { WizardLayout } from 'components/layouts';
import useFetch from 'components/useFetch';
import wizard from './wizardData';

const Cookies = () => {
    const [legalDisclaimer, loading] = useFetch({ url: '/api/settings/legals' });

    return (
        <WizardLayout wizard={wizard}>
            {!loading ? (
                // eslint-disable-next-line react/no-danger
                <div className="container disclaimer" dangerouslySetInnerHTML={{ __html: legalDisclaimer.cookies }} />
            ) : null}
        </WizardLayout>
    );
};

export default Cookies;
