import PropTypes from 'prop-types';
import React from 'react';
import { SubmissionError } from 'redux-form';
import { PATCH } from 'utils/httpMethods';
import { Layout } from 'components/layouts';
import { setModal } from 'actions';
import EditForm from './EditForm';
import entityProvider from '../entityProvider';
import MiddleOfficeError from '../../MiddleOfficeError';

const handleSubmit = async ({ id, ...body }) => {
    try {
        const response = await PATCH(`/api/entities/${id}`, { body });

        return response.json();
    } catch (error) {
        const data = await error.response.json();
        throw new SubmissionError({ _error: data.message });
    }
};

const Edit = ({ entity, settings, navigate }) => (
    <Layout hasFooter>
        {entity ? (
            <EditForm
                onSubmit={handleSubmit}
                onSubmitFail={({ _error }, dispatch) => _error && dispatch(setModal(() => <MiddleOfficeError error={_error} />))}
                onSubmitSuccess={() => navigate('/admin/entity')}
                initialValues={entity}
                settings={settings}
            />
        ) : null}
    </Layout>
);

Edit.propTypes = {
    entity: PropTypes.shape({}),
    navigate: PropTypes.shape({}).isRequired,
    settings: PropTypes.shape({}).isRequired,
};

Edit.defaultProps = {
    entity: null,
};

export default entityProvider(({ params: { id } }) => id)(Edit);
