import { get, set } from 'lodash/fp';
import checkRequiredIntegerField from './checkRequiredIntegerField';
import computeSupportRepartitionProgramme from '../../utils/computeSupportRepartitionProgramme';

export const checkSupportRepartitionProgramme = (field) => (errors, values) => {
    const support = get(field, values);

    if (support && support.length) {
        const sumSupport = computeSupportRepartitionProgramme(support);

        let newErrors = errors;

        if (100 !== sumSupport) {
            newErrors = set(field, { _error: 'La repartition du versement programmé ne fait pas 100%' }, errors);
        }

        newErrors = checkRequiredIntegerField(newErrors, values);

        return newErrors;
    }

    return errors;
};

export default checkSupportRepartitionProgramme;
