import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import OwnerField from '../field/Owner';
import ChildrenField from '../field/Children';

import { IntegerField, TextField, YesNoField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';

const SalaryInner = (props) => {
    const { name, realExpenses, familySituation, dualRip, ripFromRecommendation } = props;
    const displayField = ('cohabitation' === familySituation && dualRip) || (ripFromRecommendation && 'cohabitation' === ripFromRecommendation.familySituation && ripFromRecommendation.dualRip);

    return (
        <div>
            <HorizontalLayout>
                {displayField ? (
                    <OwnerField
                        name={`${name}.beneficiary`}
                        title="Foyer fiscal de rattachement"
                        onlyCohabitation
                        required
                        single
                        ripFromRecommendation={ripFromRecommendation}
                    />
                ) : null}
                <ChildrenField name={`${name}.child`} title="Enfant concerné" child={ripFromRecommendation && ripFromRecommendation.children} required />
                <TextField name={`${name}.description`} title="Description" />
                <IntegerField name={`${name}.income`} title="Salaire annuel net" required />
                <YesNoField name={`${name}.realExpenses`} title="Êtes-vous aux frais réels ?" />
                {realExpenses && (
                    <IntegerField name={`${name}.realExpensesValue`} title="Frais réels annuels" required />
                )}
            </HorizontalLayout>
        </div>
    );
};

SalaryInner.defaultProps = {
    realExpenses: false,
};

SalaryInner.propTypes = {
    name: PropTypes.string.isRequired,
    realExpenses: PropTypes.bool,
    familySituation: PropTypes.string.isRequired,
    dualRip: PropTypes.bool.isRequired,
    ripFromRecommendation: PropTypes.shape({
        familySituation: PropTypes.string,
        dualRip: PropTypes.bool,
        children: PropTypes.shape({}),
    }),
};

const Salary = formValues(({ name }) => ({
    realExpenses: `${name}.realExpenses`,
    familySituation: 'familySituation',
    dualRip: 'dualRip',
}))(SalaryInner);

export default Salary;
