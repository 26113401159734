import { isFunction } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import { compose } from 'recompose';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Icon from 'common/components/Icon';
import { ControlledDropdown as Dropdown } from 'components/Dropdown';
import withFormName from './withFormName';

import './TypedBlock.scss';

const TypedBlockDropdown = ({ children, title, deleteAction, toggle, openDropdown, name, id = name }) => (
    <Dropdown
        title={isFunction(title) ? createElement(title, { name }) : title}
        toggle={() => toggle(id)}
        open={openDropdown === id}
    >
        <div className="form-block-typed-collection-item dropdown">
            <div className="header right">
                {deleteAction && (
                    <button
                        type="button"
                        onClick={() => {
                            deleteAction();
                            toggle(id);
                        }}
                        className="add-btn"
                    >
                        <Icon icon="times-circle" />
                    </button>
                )}
                <div className="inner">
                    <p>Supprimer</p>
                </div>
            </div>
            <div className="inner">{children}</div>
        </div>
    </Dropdown>
);

TypedBlockDropdown.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    children: PropTypes.node.isRequired,
    deleteAction: PropTypes.func,
    toggle: PropTypes.func,
    openDropdown: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
};

TypedBlockDropdown.defaultProps = {
    deleteAction: null,
};

export default compose(
    withFormName,
    connect((state, { form, name }) => ({ id: formValueSelector(form)(state, name).id })),
)(TypedBlockDropdown);
