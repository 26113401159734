import { flowRight, isEmpty, set, get } from 'lodash/fp';
import moment from 'moment';
import PropTypes from 'prop-types';
import { branch, renderNothing } from 'recompose';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValues, change } from 'redux-form';
import validate, { checkIsEmail, checkRequiredField, branchValidate } from 'common/formValidation/lib';
import { DateTimeField, TextField, YesNoField, ChoiceField, SearchableSelectField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import Icon from 'common/components/Icon';
import Title from 'components/Title';
import { userStatus as userStatuses } from 'common/choices';
import fullName from 'common/utils/fullName';
import { useFlag } from '@unleash/proxy-client-react';
import CheckboxButton from 'components/form/CheckboxButton';
import FormFields from '../../FormFields';
import SubscriptionInfos from './SubscriptionInfos';
import { getSubscriptionData } from '../../../profile/Subscription';
import SSOUser from './SSOUser';
import ERIPUser from './ERIPUser';
import './EditForm.scss';
import { POST } from '../../../../utils/httpMethods';
import withInstanceSettings from '../../../sessions/withInstanceSettings';
import { withSession } from '../../../sessions';
import sortEntitiesChildren from '../../../../common/utils/sortEntitiesChildren';

const checkTrialField = (errors, { payingUser, trialEndDate }, { initialValues }) => {
    if (!payingUser) {
        // we don't need to check this field
        return errors;
    }

    const endDate = moment(trialEndDate);

    if (endDate.isSame(initialValues.trialEndDate)) {
        // the date didn't change, we won't check for errors
        return errors;
    }

    if (endDate < moment()) {
        return set('trialEndDate', 'La date doit être postérieure à la date du jour', errors);
    }

    if (endDate > moment().add(2, 'month')) {
        return set('trialEndDate', 'La date doit être antérieure à J+2M', errors);
    }

    return errors;
};

const getIsCheckedCddManager = (entityId, cddManager) => {
    if (cddManager) {
        const find = cddManager.find(u => u === entityId);
        if (find) {
            return true;
        }
    }

    return false;
};

const formValidate = validate([
    branchValidate((errors, { middlePartnerId }, { instanceSettings: { hasFlux } }) => !hasFlux && !middlePartnerId, [
        checkRequiredField('firstName'),
        checkRequiredField('lastName'),
        checkRequiredField('email'),
        checkIsEmail('email'),
    ]),
    branchValidate((errors, { middlePartnerId }, { instanceSettings: { hasFlux } }) => hasFlux && middlePartnerId, [
        // check middlePartnerId format
        (errors, values) => {
            const value = get('middlePartnerId', values);
            const errorString = 'Veuillez renseigner un ID valide (ex: 120e50a2-1900-e711-810a-3863bb34cba5)';

            return (value.match(/[a-z|0-9]{8}-[a-z|0-9]{4}-[a-z|0-9]{4}-[a-z|0-9]{4}-[a-z|0-9]{12}/)) ?
                errors :
                set('middlePartnerId', errorString, errors);
        },
    ]),
    checkRequiredField('entityId'),
    checkRequiredField('isAdmin'),
    branchValidate((errors, _, { instanceSettings: { hasStripeSubscription } }) => hasStripeSubscription, [
        checkRequiredField('payingUser'),
    ]),
    checkRequiredField('status'),
    branchValidate((errors, { status }) => 'cgp' === status, [
        checkRequiredField('isEliteCGP'),
        branchValidate((errors, { isEliteCGP }) => isEliteCGP, [
            checkRequiredField('dedicatedCaseManagerId'),
        ]),
    ]),
    checkRequiredField('needApprovalOnSimulations'),
    checkRequiredField('needApprovalOnCIFDocument'),
    checkTrialField,
]);

const confirm = 'Souhaitez-vous vraiment annuler l\'abonnement de cet utilisateur ?';

const cancelSubscription = async (id, syncSubscriptionInfos) => {
    if (!window.confirm(confirm)) {
        return;
    }

    try {
        await POST(`/api/users/${id}/cancelSubscription`);
        await syncSubscriptionInfos(id);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error happens');
    }
};

const getEntityAndSubEntities = (entities, entityName) => {
    const entityOfTheUser = entities.filter((entity) => entity.name === entityName);
    if (!isEmpty(entityOfTheUser)) {
        return [entityOfTheUser, sortEntitiesChildren(entities, entityOfTheUser[0].id)].flat();
    }

    return [];
};

const FormInner = ({
    form,
    initialValues: { id: userId },
    entities,
    users,
    status,
    isEliteCGP,
    subscriptionInfos,
    syncSubscriptionInfos,
    cddManager,
    syncCddManagerInfos,
    syncCddManagerInfosUpdate,
    payingUser,
    entityId,
    middlePartnerId,
    instanceSettings: { label, hasStripeSubscription, hasFlux },
    session,
    dispatch,
    isAdminSelected,
    isAdminReseauSelected,
    ...props
}) => {
    let managedNetworks = [];
    let sousReseaux = [];
    let parentEntityName = '';

    if (session.isAdminReseau) {
        // Récupérer les réseaux et sous-réseaux de l'utilisateur
        managedNetworks = getEntityAndSubEntities(entities, session.entity.name);
    }

    const network = session.isAdmin ? entities : managedNetworks;

    if ('cdd' === status) {
        const currentEntity = entities.find(u => u.id === entityId);

        parentEntityName = currentEntity.name;
        // Récupérer les sous-réseaux de l'utilisateur
        sousReseaux = getEntityAndSubEntities(entities, currentEntity.name).slice(1); // Ignorer l'entité principale
    }

    let subscriptionData;

    if (hasStripeSubscription && subscriptionInfos) {
        subscriptionData = getSubscriptionData(subscriptionInfos, label);
    }
    const enabled = useFlag('erip-1223-CIF');

    const buttonDisabled = (fieldName) => {
        if ('isAdmin' === fieldName) {
            return isAdminReseauSelected;
        }
        if ('isAdminReseau' === fieldName) {
            return isAdminSelected;
        }

        return false;
    };

    return (
        <FormFields {...props} back="/admin/user">
            {hasFlux && (
                <Fragment>
                    <Title>
                        <b>Middle</b>
                    </Title>
                    <GridLayout className="single condensed full-width gray-box">
                        <TextField title="ID Middle" name="middlePartnerId" />
                        {middlePartnerId && <p>Les informations seront mises à jour lors de la sauvegarde.</p>}
                    </GridLayout>
                </Fragment>
            )}
            <Title><b>Utilisateur</b></Title>
            <GridLayout className="double condensed full-width gray-box">
                {hasFlux && middlePartnerId ? <SSOUser /> : <ERIPUser />}
                <SearchableSelectField
                    name="entityId"
                    title="Réseau"
                    required
                    options={network.map(({ id, name }) => ({ value: id, label: name }))}
                    onChange={(e) => {
                        syncCddManagerInfos(userId, e.value);
                    }}
                />
                <button type="button" className="sendButton password-reset" onClick={props.resetPassword}>
                    Réinitialiser le mot de passe
                </button>
            </GridLayout>
            <hr />
            <Title><b>Rôles et Permissions</b></Title>
            <GridLayout className="triple condensed full-width gray-box">
                {session.isAdmin &&
                <Fragment>
                    <YesNoField
                        title="Administrateur plateforme"
                        name="isAdmin"
                        required
                        disabled={buttonDisabled('isAdmin')}
                    />
                    <YesNoField
                        title="Administrateur réseau"
                        name="isAdminReseau"
                        required
                        disabled={buttonDisabled('isAdminReseau')}
                    />
                </Fragment>
                }

                {hasStripeSubscription && <YesNoField
                    title="Utilisateur payant"
                    name="payingUser"
                    required
                />}
                <ChoiceField
                    name="status"
                    title="Statut"
                    choices={userStatuses}
                />
                { 'cdd' === status && (
                    <div >
                        <div className="parent-reseau"><label htmlFor="parentReseauName">{parentEntityName}</label></div>
                        <div className="rips-list">
                            {sousReseaux &&
                        sousReseaux.map((sousReseau) => (
                            <Field
                                title={sousReseau.name}
                                component={CheckboxButton}
                                name={`#${sousReseau.id}`}
                                key={sousReseau.id}
                                {...{
                                    input: {
                                        name: `input#${sousReseau.id}#${entityId}`,
                                        value: getIsCheckedCddManager(sousReseau.id, cddManager),
                                        disable: false,
                                    },
                                }}
                                onClick={(e) => {
                                    // Mise à jour immuable du cddManager
                                    const isChecked = e.currentTarget.checked;
                                    const newCddManager = isChecked
                                        ? [...cddManager, sousReseau.id]
                                        : cddManager.filter(id => id !== sousReseau.id);

                                    // Mise à jour dans le state
                                    syncCddManagerInfosUpdate(newCddManager);

                                    // Mise à jour du formulaire redux-form
                                    dispatch(change('user-edit', `input#${sousReseau.id}#${entityId}`, isChecked));
                                }}
                            />
                        ))}
                        </div>
                    </div>
                )}
                {'cgp' === status && (
                    <Fragment>
                        <YesNoField
                            title="Conseiller Élite ?"
                            name="isEliteCGP"
                            required
                        />
                        {isEliteCGP && (
                            <SearchableSelectField
                                name="dedicatedCaseManagerId"
                                title="Chargé d'affaire dédié"
                                required
                                options={users.filter(({ status: userStatus }) => 'caseManager' === userStatus)
                                    .map(({ id, ...user }) => ({ value: id, label: fullName(user) }))}
                            />
                        )}

                    </Fragment>
                )}
                {hasStripeSubscription && payingUser && (
                    <DateTimeField
                        name="trialEndDate"
                        title="Fin de période d'essai"
                        required
                    />
                )}
            </GridLayout>
            <hr />
            {'cgp' === status && (
                <Fragment>
                    <Title><b>Validation</b></Title>
                    <GridLayout className="double condensed full-width gray-box">
                        <YesNoField
                            title="Doit-être validé sur simulations ?"
                            name="needApprovalOnSimulations"
                            required
                        />
                        {enabled && (
                            <YesNoField
                                title="Doit-être validé sur document de conseil CIF ?"
                                name="needApprovalOnCIFDocument"
                                required
                            />
                        )}
                    </GridLayout>
                </Fragment>
            )}
            <hr />
            {hasStripeSubscription && subscriptionInfos && (
                <Fragment>
                    <Title><b>Abonnement</b></Title>
                    <GridLayout className="full-width gray-box bottom">
                        <div className="subscription-infos double">
                            <SubscriptionInfos {...subscriptionData} />
                        </div>
                        {['ongoingSubscription', 'past_due', 'failedFirstPayment'].includes(subscriptionData.type) && (
                            <div className="double text-center">
                                <button
                                    type="button"
                                    className="sendButton cancel"
                                    onClick={() => cancelSubscription(userId, syncSubscriptionInfos)}
                                >
                                    <Icon icon="trash" />
                                    Annuler l&apos;abonnement
                                </button>
                            </div>
                        )}
                    </GridLayout>
                </Fragment>
            )}
        </FormFields>
    );
};

FormInner.propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    syncSubscriptionInfos: PropTypes.func.isRequired,
    syncCddManagerInfos: PropTypes.func.isRequired,
    syncCddManagerInfosUpdate: PropTypes.func.isRequired,
    resetPassword: PropTypes.func,
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    })),
    status: PropTypes.string.isRequired,
    isEliteCGP: PropTypes.bool,
    payingUser: PropTypes.bool,
    subscriptionInfos: PropTypes.shape({}),
    cddManager: PropTypes.shape({
        filter: PropTypes.func,
    }),
    middlePartnerId: PropTypes.string,
    instanceSettings: PropTypes.shape({
        label: PropTypes.string,
        hasStripeSubscription: PropTypes.bool,
        hasFlux: PropTypes.bool,
    }),
    session: PropTypes.shape({
        id: PropTypes.string,
        isAdminReseau: PropTypes.bool,
        isAdmin: PropTypes.bool,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    entityId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    isAdminReseauSelected: PropTypes.bool,
    isAdminSelected: PropTypes.bool,
};

const Form = flowRight([
    withSession,
    withInstanceSettings,
    reduxForm({
        form: 'user-edit',
        enableReinitialize: true,
        validate: formValidate,
    }),
    connect((state) => ({
        entities: Object.values(state.data.entity),
        users: Object.values(state.data.user),
    })),
    branch(({ entities, users }) => isEmpty(entities) || isEmpty(users), renderNothing),
    formValues({
        status: 'status',
        isEliteCGP: 'isEliteCGP',
        payingUser: 'payingUser',
        entityId: 'entityId',
        middlePartnerId: 'middlePartnerId',
        isAdminSelected: 'isAdmin',
        isAdminReseauSelected: 'isAdminReseau',
    }),
])(FormInner);

export default Form;
