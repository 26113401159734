import React from 'react';
import { useDatabase } from 'components/database';
import handleError from 'utils/handleError';
import { POST } from 'utils/httpMethods';
import useRouter from '../../../useRouter';
import RipRecommendationForm from './Form';

const handleSubmit = (body, dispatch, { id, database }) => database.rips.syncChanges()
    .then(() => POST(`/api/rips/${id}/sendToManager`, { body }))
    .catch(handleError);

const Recommendation = () => {
    const {
        params: { id },
        navigate,
    } = useRouter();

    const database = useDatabase();

    return (
        <RipRecommendationForm
            id={id}
            overlay="recommendation"
            onSubmit={handleSubmit}
            history={navigate}
            database={database}
        />
    );
};

export default Recommendation;
