import React from 'react';
import { reduxForm } from 'redux-form';
import FormGroup from 'components/form/Group';
import { IntegerField, PercentageField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import ADIResults from './ADIResults';

const ADICalculator = () => (
    <form className="simulation-form">
        <h4 className="simulator-title">
            <b>Calculette ADI</b>
        </h4>
        <Title><b>Paramètres</b></Title>
        <FormGroup>
            <GridLayout className="condensed">
                <div className="double">
                    <IntegerField
                        name="amount"
                        title="Montant emprunté"
                        min={0}
                    />
                </div>
                <PercentageField
                    name="quotity1"
                    title="Quotité assuré 1"
                />
                <PercentageField
                    name="quotity2"
                    title="Quotité assuré 2"
                />
                <PercentageField
                    name="rate1"
                    title="Taux assuré 1"
                />
                <PercentageField
                    name="rate2"
                    title="Taux assuré 2"
                />
            </GridLayout>
        </FormGroup>
        <ADIResults />
    </form>
);

export default reduxForm({
    form: 'adi-calculator',
    destroyOnUnmount: false,
})(ADICalculator);
