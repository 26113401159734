import { getOr } from 'lodash/fp';

const choices = {
    callAdvisorToAct: { point: [2] },
    sellEverything: { point: [1], rejectedProfiles: ['D'] },
    waitsWithoutCallingAdvisor: { point: [3] },
};

const question = (point, investorProfile) => ('yes' === investorProfile.sufferTradingLosses ? getOr(null, point, choices) : {});

export default question;
