import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import Header from './Header';
import Tree from './Tree';

class Variables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPath: '',
        };

        this.formRef = React.createRef();

        this.onLeafClick = this.onLeafClick.bind(this);
    }

    onLeafClick(path) {
        this.setState({ selectedPath: path });
    }

    render() {
        const { tree } = this.props;

        return (
            <Fragment>
                <Header selectedPath={this.state.selectedPath} />
                <div className="body">
                    <div className="helper">
                        Cet arbre représente les variables du RIP que vous pourrez intégrer dans le document CIF.
                        <br />
                        Cliquez sur un élément de l&apos;arbre pour obtenir le texte à intégrer dans le document.
                    </div>
                    <div className="body-container">
                        {tree ? (
                            <Tree tree={tree} onLeafClick={this.onLeafClick} />
                        ) : (
                            <Spinner>chargement en cours...</Spinner>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

Variables.propTypes = {
    tree: PropTypes.shape({}),
};

export default Variables;
