import PropTypes from 'prop-types';
import React from 'react';
import { getContext } from 'recompose';
import RadioGroup from '../../form/RadioGroup';
import Product from './Product';

import './ProductChoice.scss';

const ProductChoiceInner = ({ formGroup: Group, types, ...props }) => (
    <Group {...props}>
        <RadioGroup className="contract-creation-product" {...props}>
            {Object.entries(types).map(([key, type]) => (
                <Product product={type} value={key} key={key} classValue={key} />
            ))}
        </RadioGroup>
    </Group>
);

ProductChoiceInner.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    types: PropTypes.shape({}).isRequired,
    formGroup: PropTypes.func.isRequired,
};

const ProductChoice = getContext({
    formGroup: PropTypes.func.isRequired,
})(ProductChoiceInner);

export default ProductChoice;
