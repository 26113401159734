import React from 'react';
import { faBuildingUser, faHandHoldingDollar, faGraduationCap, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import FieldInfo from '../../form/FieldInfo';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import RipInvestmentReduction from './InvestmentReduction';
import RipSubscriptionReduction from './SubscriptionReduction';
import RipDonationReduction from './DonationReduction';
import RipEducationReduction from './EducationReduction';
import RipOtherReduction from './OtherReduction';

const types = {
    investment: {
        component: RipInvestmentReduction,
        icon: faMoneyBill1,
        title: 'Placements',
        defaultValues: {
            recurrentEvent: true,
        },
    },
    donation: {
        component: RipDonationReduction,
        icon: faHandHoldingDollar,
        title: 'Dons',
        defaultValues: {
            recurrentEvent: true,
        },
    },
    subscription: {
        component: RipSubscriptionReduction,
        icon: faBuildingUser,
        title: 'Souscriptions PME/PMI',
        defaultValues: {
            recurrentEvent: true,
        },
    },
    education: {
        component: RipEducationReduction,
        icon: faGraduationCap,
        title: 'Scolarité',
        defaultValues: {
            recurrentEvent: true,
        },
    },
    other: {
        component: RipOtherReduction,
        icon: faEllipsis,
        title: 'Autres',
        defaultValues: {
            recurrentEvent: true,
            capped: true,
        },
    },
};

const TaxReductionsList = () => (
    <FormGroup>
        <Title>
            Avez-vous <b>des réductions&nbsp;?</b>
            <FieldInfo id="incomesInfo">
                Les investissements locatifs ouvrant droit à réduction d&apos;impôt doivent être renseignés dans
                l&apos;onglet Patrimoine
            </FieldInfo>
        </Title>
        <TypedCollection name="reductions" types={types} />
    </FormGroup>
);

export default TaxReductionsList;
