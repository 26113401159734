import PropTypes from 'prop-types';
import React from 'react';
import { IntegerField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';

const Pension = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Titulaire concerné" required />
        <IntegerField name={`${name}.rent`} title="Montant mensuel" required />
    </HorizontalLayout>
);

Pension.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Pension;
