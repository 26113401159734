import { mapValues, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { GET, POST } from 'utils/httpMethods';

import ConnectedNextButton from 'components/FormButtons/buttons/ConnectedNextButton';
import FormButtons from '../../../FormButtons';
import useSettings from '../../../sessions/useSettings';
import Form from '../../../simulation/FormStep';
import contractLoader from '../../contractLoader';
import onSubmitRedirect from '../onSubmitRedirect';
import DocumentsReader from './DocumentsReader';
import withRouter from '../../../withRouter';
import getWizard from '../wizardData';

const containsTarget = (variable, string) => variable.includes(string);

const isDateGreaterThan30Days = (date) => {
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const currentDate = new Date();
    const targetDate = new Date(date);
    const differenceInMilliseconds = currentDate - targetDate;

    return differenceInMilliseconds > thirtyDaysInMilliseconds;
};

const filterDocumentsObject = (documents) => {
    const documentsArray = Object.entries(documents);
    const documentsFiltered = documentsArray.filter(([key]) => key !== 'documentEntreeEnRelation');

    return documentsFiltered.reduce((acc, [key, value]) => {
        acc[key] = value;

        return acc;
    }, {});
};

const DocumentsInner = ({
    handleSubmit,
    contract: {
        simulation,
    },
    documents,
    contractSelected,
    ...props
}) => {
    const { contractType, subscriptionDate } = simulation.data;
    const { state } = contractSelected;

    const isTarget = containsTarget(contractType.toLowerCase(), 'target');
    const isHubOrSignedWithDate = 'completedDigital' === state || ('completed' === state && isDateGreaterThan30Days(subscriptionDate));

    let documentObj = {};

    if (isTarget && isHubOrSignedWithDate && documents && documents.documentEntreeEnRelation !== undefined) {
        documentObj = filterDocumentsObject(documents);
    } else {
        documentObj = documents;
    }

    const { params: { id } } = props;
    const { name: contractName } = useSettings(['simulation', 'lifeInsurance', 'types', contractType]);
    const wizard = getWizard(contractType);

    return (
        <Form handleSubmit={handleSubmit} wizard={wizard}>
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <p className="text-help">
                        Bienvenue dans votre module de souscription avec signature électronique du
                        contrat <b>{contractName}</b> pour votre client.
                    </p>
                    <p className="text-help">
                        Préalablement à la souscription du contrat et dans le cadre de la réglementation, vous devez
                        faire prendre connaissance et valider avec votre client l&apos;ensemble des documents suivants :
                    </p>
                    <ul className="margin-bottom-40 text-help">
                        {Object.entries(documentObj).map(([key, { filename }]) => (
                            <li key={key}>
                                {filename}
                            </li>
                        ))}
                    </ul>
                    <p>
                        Vous pouvez préconiser à votre client de l&apos;imprimer ou de les sauvegarder sur un support
                        durable.
                    </p>
                    <DocumentsReader documents={documentObj} />
                </div>
            </div>
            <FormButtons
                back={`/rip/${id}/contracts/`}
                list={`/rip/${id}/contracts/`}
                nextButtonComponent={ConnectedNextButton}
            />
        </Form>
    );
};

DocumentsInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    documents: PropTypes.shape({
        documentEntreeEnRelation: PropTypes.shape({}),
    }).isRequired,
    history: PropTypes.shape({}),
    contract: PropTypes.shape({
        simulation: PropTypes.shape({
            data: PropTypes.shape({
                contractType: PropTypes.string,
                subscriptionDate: PropTypes.string,
            }),
        }),
    }).isRequired,
    contractSelected: PropTypes.shape({
        state: PropTypes.string,
    }).isRequired,
};

const Documents = compose(
    withRouter,
    contractLoader,
    withState('documents', 'setDocuments', {}),
    withState('contractSelected', 'setContract', {}),
    lifecycle({
        componentDidMount() {
            const { setDocuments, setContract, params: { contractId } } = this.props;

            GET(`/api/contracts/${contractId}/timestamp`)
                .then((response) => response.json())
                .then(setDocuments);
            GET(`/api/contracts/${contractId}`)
                .then((response) => response.json())
                .then(setContract);
        },
    }),
    withPropsOnChange(['documents', 'contractSelected'], ({ documents, contractSelected }) => ({
        initialValues: {
            documents: mapValues(({ processed }) => processed, documents),
            contractSelected,
        },
    })),
    reduxForm({
        form: 'contract-documents',
        enableReinitialize: true,
        validate: ({ documents = {} }) => {
            for (const [doc, value] of Object.entries(documents)) {
                if (!value) {
                    return {
                        documents: {
                            [doc]: 'Vous devez lire et accepter le document pour continuer',
                        },
                    };
                }
            }

            return {};
        },
        onSubmit: onSubmitRedirect,
        onChange: (
            { documents = {} },
            dispatch,
            { documents: originalDocuments },
            { documents: previousDocuments },
        ) => {
            Object.entries(documents).forEach(([doc, value]) => {
                const { [doc]: { url, processed } } = originalDocuments;

                if (!processed && value && value !== get(doc, previousDocuments)) {
                    POST(url);
                }
            });
        },
    }),
)(DocumentsInner);

export default Documents;
