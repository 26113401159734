import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CardElement, IbanElement } from '@stripe/react-stripe-js';
import { formValues } from 'redux-form';
import Title from 'components/Title';
import { InputField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';

const commonProps = { style: { base: { fontSize: '22px' } } };

const PaymentType = ({ paymentType }) => {
    switch (paymentType) {
        case 'card':
            return (
                <Fragment>
                    <Title>Renseignez vos coordonnées bancaires</Title>
                    <CardElement options={commonProps} />
                </Fragment>
            );

        case 'iban':
            return (
                <Fragment>
                    <Title>Renseignez votre IBAN</Title>
                    <div className="form-row inline">
                        <GridLayout>
                            <InputField
                                name="name"
                                title="Nom"
                                type="text"
                                placeholder="Jean Dupont"
                            />
                            <InputField
                                name="email"
                                title="Adresse email"
                                type="text"
                                placeholder="jean@dupont.fr"
                            />
                        </GridLayout>
                    </div>
                    <label className="iban" htmlFor="iban">
                        <span>IBAN</span>
                        <IbanElement
                            options={{
                                supportedCountries: ['SEPA'],
                                style: commonProps.style,
                                placeholderCountry: 'FR',
                            }}
                            id="iban"
                        />
                    </label>
                </Fragment>
            );

        default:
            return null;
    }
};

PaymentType.propTypes = {
    paymentType: PropTypes.string,
};

PaymentType.defaultProps = {
    paymentType: null,
};

export default formValues('paymentType')(PaymentType);
