import diffMonths from './diffMonths';

const computeCollateralSavings = (
    fundingType,
    propertyActDate,
    propertyDeliveryDate,
    fundingDuration,
    collateralRate,
    collateralMonthlyContribution,
    collateralInitialContribution,
) => {
    if ('in-fine' !== fundingType) {
        return [];
    }

    const startYear = propertyActDate.getFullYear();
    const startMonth = propertyActDate.getMonth();
    let capitalizedAmount = collateralInitialContribution;

    const annuities = [];

    const duration = fundingDuration + diffMonths(propertyActDate, propertyDeliveryDate);

    for (let i = 0; i < duration; i++) {
        const year = startYear + Math.floor((startMonth + i) / 12);
        const month = (startMonth + i) % 12;

        capitalizedAmount += (capitalizedAmount * (collateralRate / 12)) + collateralMonthlyContribution;

        annuities.push({
            year,
            month,
            capitalizedAmount,
            saving: collateralMonthlyContribution,
        });
    }

    return annuities;
};

export default computeCollateralSavings;
