import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { withPropsOnChange } from 'recompose';
import { formValues } from 'redux-form';
import filterForOwner from 'common/model/rip/filterForOwner';
import RadioQuestion from '../../../InvestorProfile/RadioQuestion';
import SummaryContent from './SummaryContent';
import dataForm from '../../dataForm';
import withRouter from '../../../withRouter';

const CohabitationSummary = ({ rip, change }) => {
    useEffect(() => {
        change('target', 'client');
    }, []);

    return (
        <Fragment>
            <RadioQuestion
                choices={{
                    client: rip.originalRip.firstName,
                    partner: rip.originalRip.partnerFirstName,
                }}
                name="target"
                title={<span><b>Concubin</b></span>}
                required
            />
            <SummaryContent rip={rip} />
        </Fragment>
    );
};

CohabitationSummary.propTypes = {
    rip: PropTypes.shape({
        originalRip: PropTypes.shape({
            firstName: PropTypes.string,
            partnerFirstName: PropTypes.string,
        }),
    }).isRequired,
    change: PropTypes.func,
};

export default flowRight([
    withRouter,
    dataForm({
        form: 'rip-summary',
        id: ({ params: { id } }) => id,
        type: 'rip',
    }),
    formValues('target'),
    withPropsOnChange(['rip', 'target'], ({ rip, target }) => ({ rip: filterForOwner(rip, target) })),
])(CohabitationSummary);
