const menuVisibility = (state = false, { type }) => {
    switch (type) {
        case 'HIDE_MENU':
            return false;
        case 'SHOW_MENU':
            return true;
        case 'TOGGLE_MENU':
            return !state;
        default:
            return state;
    }
};

export default menuVisibility;
