import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { downloadPdf } from 'actions';
import Icon from 'common/components/Icon';
import DownloadButton from 'components/DownloadButton';
import FormGroup from 'components/form/Group';
import FormButtons from 'components/FormButtons';
import dataForm from 'components/dataForm';
import { TextField } from 'components/form/field';
import { POST } from 'utils/httpMethods';
import handleError from 'utils/handleError';
import { withSession } from '../../../sessions';
import simulationLoader from '../../simulationLoader';
import Header from './Header';
import Form from './Form';
import { SummaryInner } from './Summary';

const DetailsInner = ({
    handleSubmit,
    params: { id, simulationId },
    simulation,
    isApproved,
    download,
}) => (
    <Form handleSubmit={handleSubmit}>
        <div className="simulation-form-inner" id="simulationFormInner">
            <div className="container">
                <Header />
                <TextField
                    name="name"
                    title="Nom de la simulation"
                    placeholder="saisissez le nom de la simulation"
                    required
                />
                <SummaryInner {...simulation} />
                {isApproved ? (
                    <FormGroup>
                        <DownloadButton onClick={() => download(`/api/simulations/${simulationId}/pdf`)}>
                            <Icon icon="file-pdf-o" /> Télécharger
                        </DownloadButton>
                    </FormGroup>
                ) : null}
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/products`}
            list={`/rip/${id}/simulations/`}
        />
    </Form>
);

DetailsInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    simulation: PropTypes.shape({}).isRequired,
    isApproved: PropTypes.bool.isRequired,
    download: PropTypes.func.isRequired,
};

const Details = flowRight([
    simulationLoader,
    withSession,
    withProps(({ simulation, session, rip }) => ({
        isApproved: session.permissions.mayDownloadSimulationPDF(simulation, rip),
    })),
    dataForm({
        form: 'simulation-details',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
            isApproved,
        }) => POST(`/api/simulations/${simulationId}/complete`)
            .then(navigate(isApproved
                ? `/rip/${id}/simulations/`
                : `/rip/${id}/simulations/${simulationId}/approval/request`))
            .catch(handleError),
        type: 'simulation',
    }),
    connect(null, { download: downloadPdf }),
])(DetailsInner);

export default Details;
