import { flowRight } from 'lodash/fp';
import { branch, renderNothing } from 'recompose';
import dataProvider from '../dataProvider';

const signatureLoader = flowRight([
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'signature',
        wait: true,
    }),
    branch(
        ({ signature }) => !signature,
        renderNothing,
    ),
]);

export default signatureLoader;
