import PropTypes from 'prop-types';
import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import RoundBar from '../../RoundBar';

const LifeInsuranceResultBarChart = ({
    bars,
    barData,
    formatter,
    labelFormatter,
    legendPosition,
    ...props
}) => (
    <BarChart className="bar-chart" data={barData} barSize={15} {...props}>
        <XAxis dataKey="year" minTickGap={15} />
        <YAxis unit="€" padding={{ bottom: 20 }} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={formatter} labelFormatter={labelFormatter} />
        {bars.map(({ dataKey, name, fill }) => (
            <Bar
                key={dataKey}
                dataKey={dataKey}
                name={name}
                stackId="a"
                fill={fill}
                legendType="circle"
                isAnimationActive={false}
                shape={<RoundBar />}
            />
        ))}
        <Legend
            layout="vertical"
            verticalAlign="bottom"
            wrapperStyle={legendPosition}
        />
    </BarChart>
);

LifeInsuranceResultBarChart.propTypes = {
    bars: PropTypes.arrayOf(PropTypes.shape({
        dataKey: PropTypes.string,
        name: PropTypes.string,
        fill: PropTypes.string,
    })).isRequired,
    barData: PropTypes.arrayOf(PropTypes.shape({
        year: PropTypes.number.isRequired,
        interests: PropTypes.number.isRequired,
        fundings: PropTypes.number.isRequired,
    })).isRequired,
    formatter: PropTypes.func,
    labelFormatter: PropTypes.func,
    legendPosition: PropTypes.shape({}),
};

LifeInsuranceResultBarChart.defaultProps = {
    legendPosition: null,
};

export default LifeInsuranceResultBarChart;
