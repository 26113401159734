import { filter } from 'lodash/fp';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import DocumentReader from './DocumentReader';

import './DocumentsReader.scss';

class DocumentsReader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps({ documents, fields }) {
        const keys = Object.keys(documents);
        const activeDocuments = filter((key) => fields[key], keys);

        return {
            checked: activeDocuments,
            activeDocuments: activeDocuments.length < keys.length ? [
                ...activeDocuments,
                keys[activeDocuments.length],
            ] : activeDocuments,
        };
    }

    render() {
        const { documents } = this.props;
        const { activeDocuments, checked } = this.state;

        return (
            <div className="documents-reader">
                {activeDocuments.map((key) => {
                    const { url, filename } = documents[key];

                    return (
                        <div key={key}>
                            <DocumentReader
                                name={`documents.${key}`}
                                isChecked={checked.includes(key)}
                                filename={filename}
                                file={url}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

DocumentsReader.propTypes = {
    documents: PropTypes.shape({}).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    fields: PropTypes.shape({}).isRequired,
};

export default formValues({ fields: 'documents' })(DocumentsReader);
