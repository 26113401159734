import { getOr, set, get } from 'lodash/fp';
import { checkRequiredIntegerField } from '../lib';

const errorString = 'Vous êtes limité à 2 chiffres après la virgule';
const errorStringNull = 'Vous devez renseigner ce champ.';

const limiterADeuxChiffresApresVirgule = (value) => {
    const arrayValue = value.toString().split('.');

    return arrayValue.length > 1 && arrayValue[1].length > 2;
};

const checkFormatIntegerField = (field) => (errors, values) => {
    const value = get(field, values);

    if (null === value) {
        return set(field, errorStringNull, errors);
    }

    if (limiterADeuxChiffresApresVirgule(value)) {
        return set(field, errorString, errors);
    }

    return errors;
};

export const plafondsLoyerValidation = (prefix = '') => [
    checkRequiredIntegerField(`${prefix}zone.A`),
    checkRequiredIntegerField(`${prefix}zone.A bis`),
    checkRequiredIntegerField(`${prefix}zone.B1`),
    checkRequiredIntegerField(`${prefix}zone.B2`),
    checkRequiredIntegerField(`${prefix}zone.C`),
];

export const nombreChiffreApresVirguleValidation = (prefix = '') => [
    checkFormatIntegerField(`${prefix}zone.A`),
    checkFormatIntegerField(`${prefix}zone.A bis`),
    checkFormatIntegerField(`${prefix}zone.B1`),
    checkFormatIntegerField(`${prefix}zone.B2`),
    checkFormatIntegerField(`${prefix}zone.C`),
];

export const typeValidators = (pinel) => plafondsLoyerValidation(`simulation.pinel.${pinel}.`);
export const chiffreValidators = (pinel) => nombreChiffreApresVirguleValidation(`simulation.pinel.${pinel}.`);

const validators = (settings) => {
    const pinels = Object.keys(getOr({}, 'simulation.pinel', settings));

    return [
        ...pinels.reduce(
            (acc, pinel) => [...acc, ...chiffreValidators(pinel)],
            [],
        ),
        ...pinels.reduce(
            (acc, pinel) => [...acc, ...typeValidators(pinel)],
            [],
        ),
    ];
};

export default validators;
