const choices = {
    various: {
        title: 'Divers',
        icon: 'cog',
        forAdminReseau: true,
        description: 'Paramétrage des calculettes, de taux et de rendements.',
    },
    simulation: {
        title: 'Simulation',
        icon: 'bar-chart',
        forAdminReseau: false,
        description: 'Paramétrage de données relatives aux simulateurs.',
    },
    summary: {
        title: 'Récapitulatif',
        icon: 'area-chart',
        forAdminReseau: false,
        description: 'Paramétrage de données relatives au récapitulatif (impôt, endettement...).',
    },
    documents: {
        title: 'Documents',
        icon: 'book',
        forAdminReseau: false,
        description: 'Documents pdf éditable.',
    },
};

export const simulationsTypes = {
    scpi: 'SCPI',
    dismemberedScpi: 'SCPI nue propriétée',
    lifeInsurance: 'Assurance-Vie',
};

export default choices;
