const computeInstalmentAmount = (type, amount, monthlyRate, duration) => {
    switch (type) {
        case 'depreciable': {
            if (0 === monthlyRate) {
                return amount / duration;
            }

            return (amount * monthlyRate * ((1 + monthlyRate) ** duration)) / (((1 + monthlyRate) ** duration) - 1);
        }
        case 'in-fine':
            return amount * monthlyRate;
        default:
            return 0;
    }
};

export default computeInstalmentAmount;
