import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PercentageFloatField, SelectField } from 'components/form/field';
import Collection from 'components/form/GridLayoutCollection';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';

const distributionTypes = [
    'Bureaux',
    'Activités et entrepôts',
    'Commerces',
    'Diversification',
    'Mixte bureaux / commerces',
    'Mixte bureaux / activités',
    'Locaux d\'activité',
    'Crèche',
    'Éducation',
    'Santé',
    'Sénior',
    'Santé / commerces',
    'Pépinière d\'entreprise',
    'Résidence étudiante',
];

const DistributionType = ({ name }) => (
    <Fragment>
        <SelectField name={`${name}.name`} title="Distribution">
            <option />
            {distributionTypes.map((value) => <option key={value} value={value}>{value}</option>)}
        </SelectField>
        <PercentageFloatField name={`${name}.value`} title="Valeur (%)" />
    </Fragment>
);

DistributionType.propTypes = {
    name: PropTypes.string.isRequired,
};

const ScpiDistribution = ({ baseName }) => (
    <div className="profile">
        <hr />
        <Title><b>Distribution</b></Title>
        <GridLayout className="single condensed full-width">
            <Collection
                name={baseName}
                component={DistributionType}
                addLabel="Ajouter une distribution"
                inline
            />
        </GridLayout>
        <hr />
    </div>
);

ScpiDistribution.propTypes = {
    baseName: PropTypes.string.isRequired,
};

export default ScpiDistribution;
