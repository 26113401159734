import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { childSituations } from 'common/choices';
import { ChoiceField, DateField, DisabledField, EditableField, InputField, SelectField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import GenerationalAnalysis from './GenerationalAnalysis';
import { OwnerField } from '../field';

const generateDefaultLeaveDate = (birthDate, situation, disabled = null) => {
    if (disabled || !birthDate) {
        return null;
    }

    // Ancien calcul:
    //  const leaveDate = moment(birthDate).add(25, 'years').toDate();
    const leaveDate = 'dependant' === situation ? moment(birthDate).add(25, 'years').endOf('year').add(1, 'day')
        .toDate() : moment(birthDate).add(25, 'years').toDate();

    const leaveDateFormat = moment(leaveDate).format('YYYY-MM-DD');

    return leaveDateFormat;
};

const ChildInner = ({ name, birthDate, familySituation, fromAnotherUnion, singleParent, deadChild, situation, disabled }) => (
    <div>
        <HorizontalLayout>
            <OwnerField name={`${name}.fiscalHome`} title="Foyer fiscal de rattachement" onlyCohabitation required />
            <InputField name={`${name}.firstNames`} title="Prénoms" required type="text" />
            <DateField name={`${name}.birthDate`} title="Date de naissance" required />
            {!deadChild && <DisabledField
                name={`${name}.age`}
                value={birthDate ? `${moment().diff(birthDate, 'years', false)} ans` : ''}
                title="Âge"
            />}
            <InputField name={`${name}.deadChild`} title="Enfant décédé" type="checkbox" />
            {deadChild ? (
                <DateField name={`${name}.childDeathDate`} title="Date du décès" />
            ) : (
                <Fragment>
                    <ChoiceField name={`${name}.situation`} title="Situation" choices={childSituations} required />
                    <InputField name={`${name}.disabled`} title="Handicapé" type="checkbox" />
                    <EditableField
                        type="date"
                        name={`${name}.leaveDate`}
                        editName={`${name}.editableLeaveDate`}
                        title="Date de départ du foyer fiscal"
                        defaultValue={generateDefaultLeaveDate(birthDate, situation, disabled)}
                    />
                    <InputField name={`${name}.fromAnotherUnion`} title="Enfant d'une autre union" type="checkbox" />
                    {['single', 'widower', 'divorced'].includes(familySituation) && !singleParent ? (
                        <InputField
                            name={`${name}.raisedMore5Years`}
                            title="Élevé seul pendant plus de 5 ans"
                            type="checkbox"
                        />
                    ) : null}
                    <GenerationalAnalysis name={name} />
                </Fragment>
            )}
        </HorizontalLayout>
        {fromAnotherUnion ? (
            <HorizontalLayout>
                <OwnerField name={`${name}.parent`} title="Parent" single required />
                <SelectField name={`${name}.union`} title="Union">
                    <option />
                    <option value={1}>1ère union</option>
                    <option value={2}>2ème union</option>
                    <option value={3}>3ème union</option>
                </SelectField>
                <DateField name={`${name}.divorce`} title="Date de divorce/séparation" />
            </HorizontalLayout>
        ) : null}
    </div>
);

ChildInner.propTypes = {
    familySituation: PropTypes.string,
    birthDate: PropTypes.string,
    name: PropTypes.string.isRequired,
    fromAnotherUnion: PropTypes.bool,
    deadChild: PropTypes.bool,
    singleParent: PropTypes.bool,
    disabled: PropTypes.bool,
    situation: PropTypes.string,
};

ChildInner.defaultProps = {
    fromAnotherUnion: false,
    deadChild: false,
    disabled: false,
};

const Child = formValues(({ name }) => ({
    birthDate: `${name}.birthDate`,
    familySituation: 'familySituation',
    fromAnotherUnion: `${name}.fromAnotherUnion`,
    singleParent: 'singleParent',
    deadChild: `${name}.deadChild`,
    disabled: `${name}.disabled`,
    situation: `${name}.situation`,
}))(ChildInner);

export default Child;
