import { find } from 'lodash/fp';
import addPinelToRIP from './addPinelToRIP';

const getAfterwardRip = ({
    rip,
    summary,
    simulation: { propertyDeliveryDate, beneficiary },
    simulationResult: { operationCash },
}) => {
    // calculate the year on which we'll show a revised rip
    const year = (new Date(propertyDeliveryDate)).getFullYear() + 1;
    // get its data from the oprationCash array
    const data = find((row) => row.year === year, operationCash);

    // well some wronged data could do bad so if we didn't get the year we're looking for
    // we get back on the original rip

    return { rip: data ? addPinelToRIP(rip, summary, data, beneficiary) : rip, year, beneficiary };
};

export default getAfterwardRip;
