import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
// import { computeAmountEmployeeAtHome } from 'common/utils/computeAmount/credit';
import { employeeAtHome as computeAmountEmployeeAtHome } from 'common/model/summary/credits';
import { IntegerField, TextField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const EmployeeAtHome = ({ name, ...props }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <IntegerField name={`${name}.amount`} title="Montant annuel des dépenses" required />
        <TextField name={`${name}.description`} title="Précisez" />
        <Recurrent name={name} />
        <DisabledTaxField type="credit" name={name} computeAmount={computeAmountEmployeeAtHome(props)} />
    </HorizontalLayout>
);

EmployeeAtHome.propTypes = {
    name: PropTypes.string.isRequired,
};

export default formValues(({
    familySituation: 'familySituation',
    birthDate: 'birthDate',
    partnerBirthDate: 'partnerBirthDate',
    children: 'children',
}))(EmployeeAtHome);
