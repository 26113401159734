import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { IntegerField, SelectField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import computeAmountDonation from 'common/utils/computeAmount/donation';
import formatPercentage from 'common/utils/format/percentage';
import useSettings from 'components/sessions/useSettings';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const DonationReduction = ({ name, percentage }) => {
    const {
        rate1: { rate: rate1, max: rate1Max },
        rate2: { rate: rate2 },
        ceil,
    } = useSettings('summary.reductions.donation');

    const displayRate1 = formatPercentage(rate1);
    const displayRate2 = formatPercentage(rate2);
    const displayCeil = formatPercentage(ceil);

    return (
        <HorizontalLayout>
            <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
            <SelectField name={`${name}.percentage`} title="Type des dons" required>
                <option />
                <option value={rate1}>{displayRate1}</option>
                <option value={rate2}>{displayRate2}</option>
            </SelectField>
            <IntegerField name={`${name}.amount`} title="Montant annuel" required />
            <Recurrent name={name} />
            <DisabledTaxField
                name={name}
                computeAmount={computeAmountDonation}
                type="reduction"
                info={rate1 === parseFloat(percentage)
                    ? `Les dons à des associations venant en aide aux personnes en difficulté donnent lieu à une
                    réduction d’impôt égale à ${displayRate1} des versements retenus dans la limite de
                    ${rate1Max.toString()} € soit une réduction d’impôt maximale de
                    ${(rate1 * rate1Max).toFixed()} €.\n
                    Si vous avez versé un montant supérieur à la limite, la fraction qui dépasse cette limite sera
                    automatiquement ajoutée au montant des dons versés à des organismes d’intérêt général ouvrant
                    droit à la réduction d’impôt de ${displayRate2} dans la limite de ${displayCeil} du revenu
                    imposable.`
                    : null
                }
            />
        </HorizontalLayout>
    );
};

DonationReduction.propTypes = {
    name: PropTypes.string.isRequired,
    percentage: PropTypes.string,
};

export default formValues(({ name }) => ({ percentage: `${name}.percentage` }))(DonationReduction);
