const labelOuPlansDuContrat = (contrat, objectif) => {
    switch (objectif) {
        case 'plans':
            return contrat.plans;
        case 'label':
            return contrat.label;

        default:
            return null;
    }
};

const formatageDesPlansDeTarificationStripe = (stripeTarification, objectif) => {
    const newObj = {};

    stripeTarification.contrats.map((contrat) => Object.defineProperty(newObj, contrat.type, {

        value: labelOuPlansDuContrat(contrat, objectif),
        writable: true,
        enumerable: true,
        configurable: true,
    }));

    return newObj;
};

export default formatageDesPlansDeTarificationStripe;
