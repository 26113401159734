import { flowRight, fromPairs, get, set } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { withPropsOnChange } from 'recompose';

import recommendations, { categories } from 'common/choices/recommendations';
import Group from 'components/form/Group';
import TypedCollection from 'components/form/TypedCollection';
import TypedBlockCollectionItem from 'components/form/TypedBlockCollectionItem';
import { formValues } from 'redux-form';
import FinancialFields from './FinancialFields';
import RealEstateFields from './RealEstateFields';
import FinancialCIFFields from './FinancialCIFFields';

const RecommendationLists = ({ name, instancedTypes, isCif = false, rip, target }) => {
    const targetStatusEnvelope = 'client' === target ? rip.recommendationsCIF && rip.recommendationsCIF.LODEOMCIF && rip.recommendationsCIF.LODEOMCIF.statusEnvelope : rip.partner && rip.partner.recommendationsCIF && rip.partner.recommendationsCIF.LODEOMCIF && rip.partner.recommendationsCIF.LODEOMCIF.statusEnvelope;

    const recommendationIsValid = 'client' === target ? rip.recommendationsCIF && rip.recommendationsCIF.LODEOMCIF && rip.recommendationsCIF.LODEOMCIF.isApproved : rip.partner && rip.partner.recommendationsCIF && rip.partner.recommendationsCIF.LODEOMCIF && rip.partner.recommendationsCIF.LODEOMCIF.isApproved;

    const isEnveloppeProgress = ['Envelope_Signature_EN_CREATION', 'Envelope_Signature_COMPOSITION'].includes(targetStatusEnvelope) && recommendationIsValid;

    return (
        instancedTypes.map(([category, types]) => (
            <Group title={categories[category]} key={category}>
                <TypedCollection
                    name={name}
                    types={types}
                    textOnly
                    itemComponent={TypedBlockCollectionItem}
                    isCif={isCif}
                    rip={rip}
                    target={target}
                    isEnveloppeProgress={isEnveloppeProgress}
                />
            </Group>
        )));
};
RecommendationLists.propTypes = {
    name: PropTypes.string.isRequired,
    instancedTypes: PropTypes.shape({}),
};

export default flowRight([
    formValues('target', 'choicePreco'),
    withPropsOnChange(['recommendations', 'choicePreco', 'target'], ({ recommendations: ripRecommmendations, choicePreco, rip, target }) => {
        const groupedTypes = Object.entries({
            lifeInsurance: FinancialFields,
            LODEOM: FinancialFields,
            FIP: FinancialFields,
            SCPI: FinancialFields,
            dismemberedSCPI: FinancialFields,
            PERP: FinancialFields,
            Madelin: FinancialFields,
            PER: FinancialFields,
            diversification: FinancialFields,
            otherFinancial: FinancialFields,
            Malraux: RealEstateFields,
            LMNP: RealEstateFields,
            Bouvard: RealEstateFields,
            Pinel: RealEstateFields,
            optimizedPinel: RealEstateFields,
            PinelDenormandie: RealEstateFields,
            landDeficit: RealEstateFields,
            otherRealEstate: RealEstateFields,
            LODEOMCIF: FinancialCIFFields,
        }).reduce((types, [key, component]) => {
            const typeDefinition = recommendations[key];

            return set(
                [typeDefinition.category, key],
                { ...typeDefinition, unique: true, component },
                types,
            );
        }, {});

        if (null === ripRecommmendations || !ripRecommmendations.PERP) {
            delete groupedTypes.financial.PERP;
        }
        if (null === ripRecommmendations || !ripRecommmendations.Madelin) {
            delete groupedTypes.financial.Madelin;
        }

        if ('cif' === choicePreco) {
            delete groupedTypes.realEstate;
            delete groupedTypes.financial;
        } else {
            delete groupedTypes.financialCif;
        }

        return ({
            instancedTypes: Object
                .entries(groupedTypes)
                .map(([category, types]) => [
                    category,
                    fromPairs(Object.entries(types).map(([key, data]) => [key, {
                        ...data,
                        defaultValues: {
                            ...data.defaultValues,
                            ...get([key, 'data'], ripRecommmendations),

                        },
                        rip,
                        target,
                    }])),
                ]),
        });
    }),
])(RecommendationLists);
