import classNames from 'classnames';
import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import fullScreenManager from 'utils/fullScreenManager';
import Spinner from 'components/Spinner';
import MenuButton from '../../../menu/Button';

import './PdfReader.scss';

class PdfReader extends PureComponent {
    constructor(props) {
        super(props);

        this.pdfContainer = createRef();

        this.state = {
            numPages: 0,
            currentPage: 0,
            isFullScreen: fullScreenManager && fullScreenManager.isActive,
        };

        this.onLoadSuccess = this.onLoadSuccess.bind(this);
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrev = this.gotoPrev.bind(this);

        this.fullScreenListner = null;
    }

    componentDidMount() {
        if (fullScreenManager) {
            this.fullScreenListner = fullScreenManager.listen(() => {
                this.setState({ isFullScreen: fullScreenManager.isActive });
            });
        }
    }

    componentDidUpdate() {
        const { onRead } = this.props;
        const { numPages, currentPage } = this.state;

        if (numPages > 0 && currentPage + 1 >= numPages && onRead) {
            onRead();
        }
    }

    componentWillUnmount() {
        if (fullScreenManager) {
            this.fullScreenListner();
        }
    }

    onLoadSuccess({ numPages }) {
        this.setState({ numPages });
    }

    toggleFullScreen() {
        if (!fullScreenManager) {
            // there's no manager available
            return;
        }

        if (fullScreenManager.isActive) {
            // full screen already on
            // exit it
            fullScreenManager.exit();
        } else {
            // request a full screen on our container
            fullScreenManager.request(this.pdfContainer.current);
        }
    }

    gotoNext() {
        this.setState(({ currentPage, numPages }) => {
            const nextPage = currentPage + 1;

            return nextPage < numPages ? { currentPage: nextPage } : {};
        });
    }

    gotoPrev() {
        this.setState(({ currentPage }) => {
            const nextPage = currentPage - 1;

            return 0 <= nextPage ? { currentPage: nextPage } : {};
        });
    }

    render() {
        const { file } = this.props;
        const { currentPage, numPages, isFullScreen } = this.state;

        return (
            <div
                className={classNames('pdf-reader', { fullscreen: isFullScreen })}
                ref={this.pdfContainer}
            >
                <div className="pdf-container">
                    <Document
                        file={file}
                        onLoadSuccess={this.onLoadSuccess}
                        loading={<Spinner>récupération du PDF</Spinner>}
                    >
                        <div className="page">
                            <Page pageIndex={currentPage} />
                        </div>
                    </Document>
                </div>
                {numPages > 0 && (
                    <span className="pager-view">
                        {currentPage + 1}&nbsp;/&nbsp;{numPages}
                    </span>
                )}
                <ul className="pager">
                    <li className={classNames({ disable: currentPage - 1 < 0 })}>
                        <MenuButton onClick={this.gotoPrev}>
                            <span><i className="fa fa-arrow-left" /></span>
                        </MenuButton>
                    </li>
                    {fullScreenManager && !isFullScreen && (
                        <li>
                            <MenuButton onClick={this.toggleFullScreen}>
                                <span><i className="fa fa-arrows-alt" /></span>
                            </MenuButton>
                        </li>
                    )}
                    <li className={classNames({ disable: currentPage + 1 >= numPages })}>
                        <MenuButton onClick={this.gotoNext}>
                            <span><i className="fa fa-arrow-right" /></span>
                        </MenuButton>
                    </li>
                    <li>
                        <a className="download-link" href={file} title="Télécharger le document PDF" download>
                            <MenuButton>
                                <i className="fa fa-download" />
                            </MenuButton>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

PdfReader.propTypes = {
    file: PropTypes.string.isRequired,
    onRead: PropTypes.func,
};

PdfReader.defaultProps = {
    onRead: null,
};

export default PdfReader;
