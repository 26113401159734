import FineUploaderTraditional from 'fine-uploader-wrappers';
import { filter, flow, map, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import FileInput from 'react-fine-uploader/file-input';
import { connect } from 'react-redux';
import { reduxForm, formValues } from 'redux-form';
import { compose, withState, withPropsOnChange } from 'recompose';
import validate, { checkRequiredField, branchValidate } from 'common/formValidation/lib';
import fullName from 'common/utils/fullName';
import { GridLayout } from 'components/form/layout';
import { TextField, YesNoField, SearchableSelectField, ChoiceField, DisabledField } from 'components/form/field';
import InheritanceField from 'components/form/field/InheritanceField';
import Button from 'components/Button';
import Title from 'components/Title';
import Icon from 'common/components/Icon';
import EmailTemplateManager from 'components/EmailTemplateManager';
import { DELETE } from 'utils/httpMethods';
import { useFlag } from '@unleash/proxy-client-react';

import useRouter from '../../../useRouter';
import FormFields from '../../FormFields';
import UserListOfEntity from './UserListOfEntity';
import withInstanceSettings from '../../../sessions/withInstanceSettings';
import formatageDesPlansDeTarificationStripe from '../../../../../../common/src/utils/formatTarificationStripe';
import sortEntitiesChildren from '../../../../../../common/src/utils/sortEntitiesChildren';

import './EditForm.scss';
import { withSession } from '../../../sessions';

const formValidate = validate([
    checkRequiredField('name'),
    checkRequiredField('caseManagerId'),
    checkRequiredField('managerId'),
    checkRequiredField('validateSimulationBy', false, true),
    branchValidate((errors, { validateSimulationBy }) => 'both' === validateSimulationBy, [
        checkRequiredField('canSelectRecommendationRecipient', false, true),
    ]),
    checkRequiredField('canCreateSimulation', false, true),
    checkRequiredField('overrideEmailTemplates'),
    checkRequiredField('contractType'),
]);

const logoUploader = (id, setLogo) => {
    const uploader = new FineUploaderTraditional({
        options: {
            request: {
                endpoint: `/api/entities/${id}/logo`,
                method: 'PUT',
                inputName: 'logo',
            },
        },
    });

    uploader.on('complete', () => {
        const logo = uploader.methods.getUploads()[0];
        const logoUrl = URL.createObjectURL(logo.file);
        setLogo(logoUrl);
    });

    return uploader;
};

const attachmentUploader = (id) => new FineUploaderTraditional({
    options: {
        request: {
            endpoint: `/api/entities/${id}/attachment`,
            method: 'PUT',
            inputName: 'attachment',
        },
    },
});

const FormInner = ({
    entities,
    users,
    settings,
    logo,
    setLogo,
    validateSimulationBy,
    name,
    directChildren,
    middleEntityId,
    instanceSettings: { hasStripeSubscription, hasFlux, stripeTarification },
    session,
    ...props
}) => {
    const enabled = useFlag('erip-1223-CIF');

    const { params: { id } } = useRouter();
    const { instanceTheseis } = session;

    const noChildren = true;

    let isParent = false;

    let childrenEntity;
    if (session.isAdmin) {
        // Si admin plateform alors je vois tout les enfants de tout les réseaux
        childrenEntity = sortEntitiesChildren(entities, id, noChildren);
    } else if (session.isAdminReseau) {
        // Check si le réseau actuel est le réseau parent
        if (name === session.entity.name) {
            isParent = true;
        }
        // Si admin réseau je ne vois que les enfants de mon réseau
        const entityOfTheUser = entities.filter((entity) => entity.name === session.entity.name);
        const childrenEntities = sortEntitiesChildren(entities, entityOfTheUser[0].id);
        childrenEntity = [...entityOfTheUser, ...childrenEntities];
    }

    return (
        <FormFields {...props} back="/admin/entity">
            {hasFlux && (
                <Fragment>
                    <Title><b>Middle</b></Title>
                    <GridLayout className="single condensed full-width gray-box">
                        <TextField
                            title="ID Middle"
                            name="middleEntityId"
                        />
                        {middleEntityId && (
                            <p>Les informations seront mises à jour lors de la sauvegarde.</p>
                        )}
                    </GridLayout>
                </Fragment>
            )}
            <hr />
            <Title><b>Entit&eacute;</b></Title>
            <GridLayout className="double condensed full-width gray-box">
                {middleEntityId ? (
                    <DisabledField title="Nom" name="name" value={name} />
                ) : (
                    <TextField
                        title="Nom"
                        required
                        name="name"
                    />
                )}
                <InheritanceField
                    title="Nom d'affichage"
                    name="displayName"
                    defaultValue={session.isAdminReseau ? name : settings.displayName}
                    type="text"
                    required
                />
                {!isParent ? (
                    <SearchableSelectField
                        name="parentId"
                        title="Réseau parent"
                        options={
                            flow(
                                filter(({ id: entityId }) => entityId !== id),
                                map(({ id: entityId, name: entityName }) => ({ value: entityId, label: entityName })),
                            )(childrenEntity)
                        }
                    />
                ) : (
                    <ChoiceField
                        name="displayName"
                        title="Réseau parent"
                        placeholder={name}
                        choices={name}
                        disabled
                    />
                )}
            </GridLayout>
            <hr />
            <Title><b>Validations</b></Title>
            <GridLayout className="double condensed full-width gray-box">
                <SearchableSelectField
                    name="caseManagerId"
                    title="Chargé d'affaires"
                    required
                    options={
                        filter(['status', 'caseManager'], users)
                            .map(({ id: userId, firstName, lastName }) =>
                                ({ value: userId, label: fullName({ firstName, lastName }) }))
                    }
                />
                <SearchableSelectField
                    name="managerId"
                    title="Manager"
                    required
                    options={
                        users
                            .map(({ id: userId, firstName, lastName }) =>
                                ({ value: userId, label: fullName({ firstName, lastName }) }))
                    }
                />
                <InheritanceField
                    name="validateSimulationBy"
                    title="Validation des simulations par le"
                    defaultValue={settings.validateSimulationBy}
                    type="select"
                >
                    <option />
                    <option value="manager">Manager</option>
                    <option value="caseManager">Chargé d&apos;affaire</option>
                    <option value="both">Les deux</option>
                </InheritanceField>
                {enabled && (
                    <InheritanceField
                        name="validateCIFDocumentBy"
                        title="Validation du document de conseil CIF par le"
                        defaultValue={settings.validateCIFDocumentBy}
                        type="select"
                        disabled
                    >
                        <option />
                        <option value="manager">Manager</option>
                        <option value="caseManager">Chargé d&apos;affaire</option>
                        <option value="both">Les deux</option>
                    </InheritanceField>
                )}
            </GridLayout>
            <hr />
            <Title><b>Mention légales</b></Title>
            <GridLayout className="single condensed full-width gray-box">
                <InheritanceField
                    title="Mentions légales"
                    name="legalNotice"
                    defaultValue={settings.legalNotice}
                    type="textArea"
                />
                <InheritanceField
                    title="Adresse légale"
                    name="legalAddress"
                    defaultValue={settings.legalAddress}
                    type="text"
                />
            </GridLayout>
            <hr />
            <Title><b>Configuration</b></Title>
            <GridLayout className="double condensed full-width gray-box">
                {'both' === validateSimulationBy && (
                    <InheritanceField
                        title="Peut-choisir le destinataire lors des demandes de préconisations"
                        name="canSelectRecommendationRecipient"
                        defaultValue={settings.canSelectRecommendationRecipient}
                        type="boolean"
                    />
                )}
                {hasStripeSubscription && session.isAdmin ? <ChoiceField
                    name="contractType"
                    title="Type de contrat"
                    choices={formatageDesPlansDeTarificationStripe(stripeTarification, 'label')}
                /> : <ChoiceField
                    name="contractType"
                    title="Type de contrat"
                    choices={formatageDesPlansDeTarificationStripe(stripeTarification, 'label')}
                    disabled
                />
                }
                <InheritanceField
                    title="Les conseillers peuvent-ils créer eux-mêmes des simulations (après validation) ?"
                    name="canCreateSimulation"
                    defaultValue={settings.canCreateSimulation}
                    type="boolean"
                />
                {!instanceTheseis && (
                    <YesNoField
                        title="Forcer l'utilisation des templates email du réseau"
                        name="overrideEmailTemplates"
                    />
                )}
                <InheritanceField
                    title="Empêcher la désactivation des abonnés par le Middle"
                    name="blockMiddleOfficeUserDesactivation"
                    defaultValue={settings.blockMiddleOfficeUserDesactivation}
                    type="boolean"
                />
                <InheritanceField
                    title="Utiliser le numéro ORIAS du manager du réseau"
                    name="allowManagerOrias"
                    defaultValue={settings.allowManagerOrias}
                    type="boolean"
                />
            </GridLayout>
            {
                !instanceTheseis && (
                    <div>
                        <hr />
                        <Title><b>Emails</b></Title>
                        <EmailTemplateManager url="/api/entities/emailTemplates" />
                    </div>

                )
            }
            <hr />
            <Title><b>Personnalisation</b></Title>
            <div className="entity-edit-form-actions">
                <div className="image-logo-uploader">
                    <FileInput
                        accept="image/*"
                        title=" "
                        name="logo"
                        uploader={logoUploader(id, setLogo)}
                    >
                        <img src={logo || settings.logo} alt="Logo du réseau" />
                        <Button className="main">
                            <Icon icon="pencil" />&nbsp;
                            Personnaliser le logo du cabinet
                        </Button>
                    </FileInput>
                    {
                        logo && (
                            <Button
                                onClick={() => {
                                    setLogo(null);

                                    return DELETE(`/api/entities/${id}/logo`);
                                }}
                            >
                                <Icon icon="refresh" />&nbsp;
                                Logo par défaut
                            </Button>
                        )
                    }
                </div>
                <FileInput accept="application/pdf" title=" " uploader={attachmentUploader(id)}>
                    <Button className="">Choisir une pièce jointe pour les e-mails</Button>
                </FileInput>
            </div>
            {!isEmpty(directChildren) && (
                <Fragment>
                    <hr />
                    <Title><b>Utilisateurs de {name}</b></Title>
                    <GridLayout className="condensed full-width gray-box user-list-container bottom">
                        <div className="user-list-container double">
                            <UserListOfEntity items={directChildren} />
                        </div>
                    </GridLayout>
                </Fragment>
            )}
        </FormFields>
    );
};

FormInner.propTypes = {
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    })).isRequired,
    settings: PropTypes.shape({
        displayName: PropTypes.string,
        validateSimulationBy: PropTypes.string,
        legalNotice: PropTypes.string,
        legalAddress: PropTypes.string,
        canSelectRecommendationRecipient: PropTypes.bool,
        canCreateSimulation: PropTypes.bool,
        blockMiddleOfficeUserDesactivation: PropTypes.bool,
        allowManagerOrias: PropTypes.bool,
        logo: PropTypes.string,
        validateCIFDocumentBy: PropTypes.string,
    }).isRequired,
    logo: PropTypes.string,
    setLogo: PropTypes.func.isRequired,
    validateSimulationBy: PropTypes.string,
    name: PropTypes.string,
    directChildren: PropTypes.arrayOf(PropTypes.shape({})),
    middleEntityId: PropTypes.string,
    instanceSettings: PropTypes.shape({
        hasStripeSubscription: PropTypes.bool,
        hasFlux: PropTypes.bool,
        stripeTarification: PropTypes.shape({}),
    }),
    session: PropTypes.shape({
        instanceTheseis: PropTypes.bool,
        isAdminReseau: PropTypes.bool,
        isAdmin: PropTypes.bool,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        instanceSettings: PropTypes.shape({
            label: PropTypes.string,
        }),
    }),
};

const Form = compose(
    withInstanceSettings,
    reduxForm({
        form: 'entity-edit',
        enableReinitialize: true,
        validate: formValidate,
    }),
    connect((state) => ({
        entities: Object.values(state.data.entity),
        users: Object.values(state.data.user),
    })),
    withSession,
    withState('logo', 'setLogo', ({ initialValues: { logo } }) => logo),
    formValues('validateSimulationBy', 'name', 'middleEntityId', 'name'),
    withPropsOnChange(
        'users',
        ({ users, initialValues: { id } }) => ({
            directChildren: users.filter(({ entityId }) => entityId === id),
        }),
    ),
)(FormInner);

export default Form;
