import { set, min } from 'lodash/fp';
import moment from 'moment';
import validate, { checkRequiredIntegerField, checkRequiredEditableField } from 'common/formValidation/lib';
import { validators as propertyValidators } from '../../common/property/PropertyForm';
import { validators as programValidators } from '../../common/property/ProgramForm';
import { GET } from '../../../../../utils/httpMethods';

let rentCeils = {};

const checkPropertyMonthlyRate = (
    errors,
    { propertyMonthlyRent, programZone, propertyWeightedSurface },
) => {
    GET('/api/settings/')
        .then((response) => response.json())
        .then((data) => {
            rentCeils = data.simulation.pinel.plafonds.zone;
        });
    const ceil = min([(0.7 + (propertyWeightedSurface ? 19 / propertyWeightedSurface : 0)).toFixed(2), 1.2])
        * rentCeils[programZone]
        * propertyWeightedSurface;

    if (ceil < propertyMonthlyRent) {
        return set(
            'propertyMonthlyRent',
            'Attention votre loyer dépasse le plafond au m² de la zone sélectionnée.',
            errors,
        );
    }

    return errors;
};

const checkDateActeInferieur2024 = (errors, { propertyActDate }) => {
    const dateActe = moment(propertyActDate);
    const dateLimite = moment('2027-12-31T23:59:59');

    if (dateActe > dateLimite) {
        return set(
            'propertyActDate',
            'La date d\'acte ne peut pas dépasser le 31/12/2027',
            errors,
        );
    }

    return errors;
};

// cette fonction teste le montant des travaux au moment du clic sur le bouton continué
const checkMontantTravauxParRapportAInvestissement = (errors, { montantTravaux }) => {
    const investmentWithTVA = localStorage.getItem('investmentWithTVA');

    if (montantTravaux < (investmentWithTVA * 0.25)) {
        return set(
            'montantTravaux',
            // Le bouton continuer est bloqué par cette espace vide qui est renvoyé
            ' ',
            errors,
        );
    }

    return errors;
};

const formValidate = validate([
    ...propertyValidators,
    ...programValidators,
    checkDateActeInferieur2024,
    checkMontantTravauxParRapportAInvestissement,
    checkRequiredIntegerField('programDuration'),
    checkRequiredIntegerField('propertyValue'),
    checkRequiredIntegerField('montantTravaux'),
    checkRequiredIntegerField('parkingLotValue'),
    checkRequiredIntegerField('propertyMonthlyRent'),
    checkPropertyMonthlyRate,
    checkRequiredEditableField('parkingEditableNotaryFees', 'parkingNotaryFees'),
    checkRequiredEditableField('parkingEditableOtherAcquisitionCost', 'parkingOtherAcquisitionCost'),
    checkRequiredEditableField('editableNotaryFees', 'notaryFees'),
    checkRequiredEditableField('editableOtherAcquisitionCost', 'otherAcquisitionCost'),
    checkRequiredEditableField('data.taxBenefitBasis.edited', 'data.taxBenefitBasis.value'),
]);

export default formValidate;
