import PropTypes from 'prop-types';
import React from 'react';
import { CreateLink } from 'components/list';
import { Layout } from 'components/layouts';
import dataProvider from 'components/dataProvider';
import ContractTable from './ContractTable';

import './List.css';

const ContractListInner = ({ rip }) => (
    <Layout className="contract-list">
        {rip ? (
            <div>
                <ContractTable rip={rip} />
                <CreateLink to={`/rip/${rip.id}/contracts/new`} />
            </div>
        ) : null}
    </Layout>
);

ContractListInner.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
    }),
};

ContractListInner.defaultProps = {
    rip: null,
};

const ContractList = dataProvider({
    id: ({ params: { id } }) => id,
    type: 'rip',
    wait: true,
})(ContractListInner);

export default ContractList;
