import { GET } from '../utils/httpMethods';

const setIsValidator = (isValidator) => ({
    type: 'SET_IS_VALIDATOR',
    isValidator,
});

export const getIsValidator = (id, items = 'rips') => async (dispatch) => {
    const response = await GET(`/api/${items}/${id}/isValidator`);

    dispatch(setIsValidator((await response.json()).isValidator));
};
