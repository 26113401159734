const count = (value) => {
    const parsedValue = parseInt(value, 10);
    if (Number.isNaN(parsedValue)) {
        return 'NC';
    }

    return parsedValue.toLocaleString('fr-FR', { style: 'decimal' });
};

export default count;
