import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { DateField, IntegerField, TextField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import OtherLoan from './OtherLoan';

const OtherChargeInner = ({ otherOption, name }) => {
    switch (otherOption) {
        case 'loan':
            return <OtherLoan name={name} />;
        case 'other':
            return (
                <HorizontalLayout>
                    <TextField name={`${name}.subtype`} title="Type" required />
                    <IntegerField name={`${name}.monthlyPayment`} title="Montant mensuel" required />
                    <DateField name={`${name}.start`} title="Date de début" />
                    <DateField name={`${name}.end`} title="Date de fin" />
                </HorizontalLayout>
            );
        default:
            return null;
    }
};

OtherChargeInner.propTypes = {
    otherOption: PropTypes.string,
    name: PropTypes.string.isRequired,
};

const OtherCharge = formValues(({ name }) => ({
    otherOption: `${name}.otherOption`,
}))(OtherChargeInner);

export default OtherCharge;
