import { createContext } from 'react';

const Context = createContext(null);

const {
    Consumer: FooterElementConsumer,
    Provider: FooterElementProvider,
} = Context;

export { FooterElementConsumer, FooterElementProvider };

export default Context;
