import { isFinite } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { propertyTaxExemptionPeriods } from 'common/choices';
import { GridLayout } from 'components/form/layout';
import { ChoiceField, PercentageField } from 'components/form/field';
import {
    checkRequiredField,
    checkChoice,
    checkRequiredEditableField,
    checkRequiredIntegerField,
    branchValidate,
} from 'common/formValidation/lib';
import EditableField from '../../../EditableField';

export const validators = [
    checkRequiredIntegerField('propertyTaxExemptionPeriod'),
    checkChoice('propertyTaxExemptionPeriod', propertyTaxExemptionPeriods),
    branchValidate((errors, { propertyTaxExemptionPeriod }) => propertyTaxExemptionPeriod > 0, [checkRequiredField('propertyTaxExemptionRate')]),
    checkRequiredEditableField('editablePropertyTaxEstimated', 'propertyTaxEstimated'),
];

const InnerPropertyTaxForm = ({ className, simulation, ...values }) => {
    const { editablePropertyTaxEstimated } = values;
    const { product, propertyMonthlyRent, parkingLotRent, parkingLotWithSeparateLease } = simulation;
    const editPropertyTaxExemptionRate = 0 < values.propertyTaxExemptionPeriod;

    let propertyTax =
        parseInt(propertyMonthlyRent, 10) + (parkingLotWithSeparateLease ? parseInt(parkingLotRent, 10) : 0);

    if ('lmnp' === product) {
        propertyTax *= 1.1;
    }

    return (
        <div className={className}>
            <GridLayout className="condensed">
                <div>
                    <ChoiceField
                        title="Durée d'exonération de la taxe foncière"
                        name="propertyTaxExemptionPeriod"
                        parse={(value) => parseInt(value, 10)}
                        normalize={(value) => (isFinite(value) ? value : null)}
                        choices={propertyTaxExemptionPeriods}
                        required
                    />
                    <EditableField
                        editable={editablePropertyTaxEstimated}
                        name="propertyTaxEstimated"
                        editableName="editablePropertyTaxEstimated"
                        title="Taxe foncière estimée"
                        defaultValue={propertyTax}
                    />
                </div>
                {(editPropertyTaxExemptionRate) ?
                    <PercentageField
                        name="propertyTaxExemptionRate"
                        title="Taux d'exonération de la taxe foncière"
                        min={0}
                        max={100}
                        required
                    /> : null
                }
            </GridLayout>
        </div>
    );
};

InnerPropertyTaxForm.propTypes = {
    className: PropTypes.string,
    simulation: PropTypes.shape({
        product: PropTypes.string,
        propertyMonthlyRent: PropTypes.string,
        parkingLotRent: PropTypes.number,
        parkingLotWithSeparateLease: PropTypes.bool,
    }).isRequired,
};

InnerPropertyTaxForm.defaultProps = {
    className: null,
};

const PropertyTaxForm = formValues({
    editablePropertyTaxEstimated: 'editablePropertyTaxEstimated',
    propertyTaxEstimated: 'propertyTaxEstimated',
    propertyTaxExemptionPeriod: 'propertyTaxExemptionPeriod',
    propertyTaxExemptionRate: 'propertyTaxExemptionRate',
})(InnerPropertyTaxForm);

export default PropertyTaxForm;
