import { compose, withProps } from 'recompose';
import { listProvider } from '../../list';
import List from './List';

const createList = (settings, listComponent) => compose(
    listProvider({
        ...settings,
        sorts: {
            updatedAt: { initialOrder: 'desc', default: true },
            createdAt: { initialOrder: 'desc' },
        },
        name: 'pendingValidation',
    }),
    withProps({ listComponent }),
)(List);

export default createList;
