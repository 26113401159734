import { getOr } from 'lodash/fp';
import { checkSupportRepartition, checkRequiredField, checkRequiredIntegerField } from '../lib';
import { checkSupportRepartitionProgramme } from '../lib/checkSupportRepartitionProgramme';

export const buildLifeInsuranceBaseProductsValidation = (prefix = '') => [
    checkRequiredField(`${prefix}label`),
    checkRequiredField(`${prefix}lowRate`),
    checkRequiredField(`${prefix}rate`),
    checkRequiredField(`${prefix}highRate`),
    checkRequiredField(`${prefix}isEuro`),
    checkRequiredField(`${prefix}codeIsin`),
];

export const buildLifeInsuranceTypesValidation = (prefix = '') => [
    checkRequiredField(`${prefix}name`),
    checkRequiredIntegerField(`${prefix}accountAvailability`),
    checkRequiredIntegerField(`${prefix}fees.min`),
    checkRequiredIntegerField(`${prefix}fees.max`),
    checkRequiredIntegerField(`${prefix}miniInitialFunding`),
    checkRequiredIntegerField(`${prefix}miniScheduledPaymentAmount`),
    ...['P', 'E', 'D'].map((profile) => checkSupportRepartition(`${prefix}profiles.${profile}.repartition`)),
    ...['P', 'E', 'D'].map((profile) => checkSupportRepartitionProgramme(`${prefix}profiles.${profile}.repartition`)),
];

export const baseProductValidators = (product) => buildLifeInsuranceBaseProductsValidation(`simulation.lifeInsurance.baseProducts.${product}.`);
export const typeValidators = (type) => buildLifeInsuranceTypesValidation(`simulation.lifeInsurance.types.${type}.`);

const validators = (settings) => {
    const baseProducts = Object.keys(getOr({}, 'simulation.lifeInsurance.baseProducts', settings));
    const types = Object.keys(getOr({}, 'simulation.lifeInsurance.types', settings));

    return [
        ...baseProducts.reduce((acc, product) => [
            ...acc,
            ...baseProductValidators(product),
        ], []),
        ...types.reduce((acc, type) => [
            ...acc,
            ...typeValidators(type),
        ], []),
    ];
};

export default validators;
