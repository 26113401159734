import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import withSpinner from 'components/withSpinner';
import withTutorial from 'components/withTutorial';
import { Action, Actions, Body, Head, Header, Table, Date, Item, listProvider } from 'components/list';
import ErrorMessage from 'components/ErrorMessage';
import mapPrefixToProps from '../mapPrefixToProps';
import withRouter from '../../withRouter';

const NoSignatureMessage = () => (
    <ErrorMessage>
        <div>Pour créer votre première signature, cliquez sur le (+) en bas à droite de cette page.</div>
    </ErrorMessage>
);

const SimulationTable = ({ items, prefix }) => (
    <Table>
        <Head>
            <th />
            <Header sortProperty="name" text="Nom de la signature" />
            <Header sortProperty="createdAt" text="Date de création la signature" />
            <th />
        </Head>
        <Body>
            {items.map(({ id, createdAt, name, state }) => (
                <Item key={id}>
                    <td />
                    <td>{name}</td>
                    <Date date={createdAt} />
                    <Actions>
                        <Action
                            to={`${prefix}/signatures/${id}/documents`}
                            icon="pencil"
                            text="modifier"
                        />
                        {'completedDigital' === state ? (
                            <Action
                                onClick={() => {
                                    document.location.href = `/api/signatures/${id}/download`;
                                }}
                                icon="file-pdf-o"
                                text="télécharger"
                            />
                        ) : null}
                    </Actions>
                </Item>
            ))}
        </Body>
    </Table>
);

SimulationTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    prefix: PropTypes.string.isRequired,
};

export default flowRight([
    withRouter,
    mapPrefixToProps,
    listProvider({
        sorts: {
            createdAt: { initialOrder: 'desc', default: true },
            name: { initialOrder: 'asc' },
        },
        name: 'signature',
        type: 'signature',
        filter: ({ params: { ripId } }) => (ripId
            ? ({ ripId: signatureRipId }) => ripId === signatureRipId
            : null
        ),
    }),
    withSpinner,
    withTutorial(NoSignatureMessage),
])(SimulationTable);
