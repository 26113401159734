const choices = {
    gift: 'Héritage/Donation',
    assetSale: 'Vente d\'actifs immobiliers',
    businessCapital: 'Capitaux activité pro',
    propertyTransfer: 'Cession de bien',
    accumulatedSavings: 'Epargne déjà constituée',
    gamblingWin: 'Gains aux jeux',
    compensation: 'Indemnisation/Dommages intérêts',
};

export default choices;
