const supportingDocuments = [
    {
        title: 'Identité et situation familiale',
        fields: {
            missIdentificationDocument: 'Carte nationale d\'identité recto-verso ou passeport en cours de validité de chaque investisseur. À certifier conforme par le client (le permis de conduire n\'est pas considéré comme une pièce recevable au regard de la loi).',
            missDivorceJudgment: 'Jugement de divorce ou acte de séparation de corps et de biens pour les personnes en instance de divorce, convention de divorce définitive et liquidation de la communauté.',
            missResidenceCard: 'Carte de séjour ou de résident de chaque investisseur.',
            missFamilyBook: 'Livret de famille complet pages enfants incluses pour chaque investisseur s\'il y a lieu.',
            missMarriageContract: 'Le contrat de mariage.',
            missPacsContract: 'Attestation de PACS.',
            missUtilityBill: 'Facture d’électricité/gaz/eau, téléphone fixe, câble, échéancier',
            missElectricityAttestation: 'Attestation du titulaire du contrat d’électricité (si différent du souscripteur) + copie de sa pièce d’identité',
            missGovernmentAttestation: 'Attestation d’une mairie, ambassade ou consulat',
            missTaxNotice: 'Avis d’imposition sur le revenu, taxe d’habitation',
            missHouseInsurance: 'Attestation d’assurance habitation',
            missRentNotice: 'Quittance de loyer établie par une agence ou un syndic',
            missResidencyAttestation: 'Attestation de l’hébergeant si résidence chez un tiers + copie de la pièce d’identité du tiers + justificatif de domicile de l’hébergeant',
        },
    },
    {
        title: 'Revenus',
        subsections: [
            {
                title: 'Salarié',
                fields: {
                    missPaySlips: '3 derniers bulletins de salaire de chaque investisseur, si CDD contrat de travail.',
                },
            },
            {
                title: 'Profession libérales',
                fields: {
                    missDeclaration2035: 'Déclarations 2035 des 3 dernières années.',
                    missSCMAssessment: 'Bilan de la SCM (Société Civile de Moyens) ou de la SCP (Société Civile de Professionnels).',
                },
            },
            {
                title: 'Commerçant, artisans et dirigeants de sociétés',
                fields: {
                    missIncomeStatement: 'Bilans et compte de résultats, liasse fiscale des 3 dernières années.',
                },
            },
            {
                title: 'Autres revenus',
                fields: {
                    missProofOfOtherIncome: 'Justificatifs des revenus autres (pensions, rente, retraite...).',
                },
            },
        ],
    },
    {
        title: 'Fiscalité',
        fields: {
            missLatestTaxAssessment: '2 derniers avis d\'imposition complets (4 pages) ; si intermittent du spectacle ou si revenus annuels irréguliers, les 3 derniers avis d\'impôts.',
            missLastLandIncomeTax: 'Dernière déclaration des revenus fonciers 2044 et/ou 2071 / 2072 si SCI, contrat de locations si nécessaire.',
            missLastIncomeTax: 'Dernière déclaration des revenus 2042, 2042 C.',
            missLastTaxNoticeForSolidarityTax: 'Dernier avis d\'imposition à l\'impôt de Solidarité sur la Fortune.',
        },
    },
    {
        title: 'Charges et justificatifs bancaires',
        fields: {
            missLastBankStatements: '3 derniers mois de tous vos relevés de compte bancaire personnels, professionnels et de chaque SCI (copie des originaux).',
            missRIB: 'Relevé d\'identité bancaire.',
            missLoanAmortizationTables: 'Tableaux d\'amortissement des prêts en cours y compris ceux qui se terminent.',
            missBusinesseLoanAmortizationTables: 'Tableaux d\'amortissement des prêts professionnels en cours pour les professions libérales y compris ceux qui se terminent.',
            missSCIAmortizationTables: 'Tableaux d\'amortissement de chaque SCI, y compris ceux qui se terminent.',
            missRevolvingLoansStatusReport: 'Relevé de situation des prêts revolving.',
            missLastRentReceipt: 'Dernière quittance de loyer ou bail locatif.',
            missFunctionalAccommodationCertificate: 'Attestation de logement de fonction ou d\'occupation à titre gratuit.',
            missProofOfAdress: 'Justificatif de domicile, facture EDF, France Telecom de moins de 3 mois.',
            missLoanOffer: 'Offre de prêt',
        },
    },
    {
        title: 'Patrimoine immobilier et mobilier',
        fields: {
            missLatestPropertyTaxes: 'Dernières taxes foncières de vos propriétés y compris de chaque SCI ou attestations notariales ou titres de propriété.',
            missLatestInvestmentStatements: 'Derniers relevés de placements de moins de 3 mois, les fonds d\'investissement actuels, date de souscription et montant investi.',
            missSolidarityTaxOnWealth: 'Dernière déclaration à l\'impôt de Solidarité sur la Fortune.',
            missPersonalContributionProof: 'Justificatifs de l\'apport personnel, origine des fonds.',
            missNotarialCertification: 'Attestation notariée avec prix et/ou acte notarié, partie normalisée',
        },
    },
    {
        title: 'Acquisition par le biais d\'une SCI',
        fields: {
            missSCIStatutes: 'Statuts de la SCI et extraits K-Bis, si création récente et absence de déclaration.',
        },
    },
    {
        title: 'justificatifs pour signature électronique',
        fields: {
            missPhoneBill: 'Justificatif de téléphone mobile (facture).',
        },
    },
];

export default supportingDocuments;
