import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import dataForm from 'components/dataForm';
import FormButtons from 'components/FormButtons';
import Title from 'components/Title';
import Form from '../Form';
import simulationLoader from '../simulationLoader';
import Header from '../Header';
import ProfileField from './ProfileField';
import ProfileDescription from './ProfileDescription';

const InvestorProfileInner = ({
    handleSubmit,
    params: { id },
    investorProfile,
    rip: { allowChangingInvestorProfile, partnerAllowChangingInvestorProfile },
    simulation: { beneficiary },
}) => {
    const limit = (allowChangingInvestorProfile && 'client' === beneficiary) || (partnerAllowChangingInvestorProfile && 'partner' === beneficiary)
        ? 'D'
        : investorProfile;

    return (
        <Form handleSubmit={handleSubmit}>
            <div className="simulation-form-inner">
                <div className="container">
                    <Header />
                    <Title>
                        <b>Votre profil d&apos;investisseur</b>
                    </Title>
                    <Fragment>
                        <ProfileField name="data.profile" limit={limit} />
                        <ProfileDescription originalInvestorProfile={investorProfile} />
                    </Fragment>
                </div>
            </div>
            <FormButtons
                back={`/rip/${id}/simulations/`}
                list={`/rip/${id}/simulations/`}
            />
        </Form>
    );
};

InvestorProfileInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    investorProfile: PropTypes.string.isRequired,
    rip: PropTypes.shape({
        allowChangingInvestorProfile: PropTypes.bool,
        partnerAllowChangingInvestorProfile: PropTypes.bool,
    }).isRequired,
    simulation: PropTypes.shape({
        beneficiary: PropTypes.string,
    }).isRequired,
};

const InvestorProfile = flowRight([
    simulationLoader,
    dataForm({
        form: 'simulation-profile',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => navigate(`/rip/${id}/simulations/${simulationId}/product`),
        type: 'simulation',
    }),
])(InvestorProfileInner);

export default InvestorProfile;
