// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
    content: ' ';
    height: 2px;
    margin: 20px 0 20px 0;
    background: rgb(36, 143, 223);
    background: linear-gradient(to right, rgb(36, 143, 223) 0%, rgb(40, 233, 234) 100%);
}
`, "",{"version":3,"sources":["webpack://./src/components/Divider.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,6BAA6B;IAC7B,mFAAmF;AACvF","sourcesContent":[".divider {\n    content: ' ';\n    height: 2px;\n    margin: 20px 0 20px 0;\n    background: rgb(36, 143, 223);\n    background: linear-gradient(to right, rgb(36, 143, 223) 0%, rgb(40, 233, 234) 100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
