import { sumBy } from 'lodash/fp';

const sumCapital = sumBy(({ capital = 0 }) => capital);
const sumMonthlyPayment = sumBy(({ monthlyPayment = 0 }) => monthlyPayment);

const computeSavings = ({
    investments = [],
}) => ({
    savings: sumCapital(investments),
    savingMonthlyPayments: sumMonthlyPayment(investments),
});

export default computeSavings;
