import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import FileInput from 'react-fine-uploader/file-input';
import Icon from 'common/components/Icon';
import { DELETE } from 'utils/httpMethods';
import './UploadSettingFiles.scss';

const uploader = (settingKey, addFile, uploaderOptions) => {
    const fineUploader = new FineUploaderTraditional({
        options: {
            request: {
                endpoint: `/api/settings/file/${settingKey}`,
                method: 'PUT',
                inputName: 'annex',
                ...uploaderOptions,
            },
        },
    });

    fineUploader.on('complete', () => {
        addFile(fineUploader.methods.getUploads()[0]);
    });

    return fineUploader;
};

const SettingFileUploader = ({
    btnText,
    documents,
    multiple,
    settingKey,
    uploaderOptions = {},
    onChange,
    deleteEndPoint = (filename) => `/api/settings/file/${settingKey}/${filename}`,
}) => {
    const addFile = useCallback((newFile) => {
        onChange([...documents, newFile.name]);
    }, [documents]);

    return (
        <div className="settings-upload-file">
            <ul>
                {!['', undefined, null].includes(documents) ? documents.map((file) => (
                    <li key={file}>
                        <div>
                            <Icon icon="file-pdf-o" />
                            {file}
                        </div>
                        <button
                            type="button"
                            onClick={async (e) => {
                                e.preventDefault();
                                const newFiles = documents.filter((f) => f !== file);
                                onChange(newFiles);
                                await DELETE(deleteEndPoint(file));
                            }}
                        >
                            <Icon icon="trash-o" />
                        </button>
                    </li>
                ))
                    :
                    null

                }
            </ul>
            {(multiple || (!multiple && 0 === documents.length)) ? (
                <FileInput
                    accept="application/pdf"
                    uploader={uploader(settingKey, addFile, uploaderOptions)}
                    text={{ selectFile: btnText }}
                />
            ) : null}
        </div>
    );
};

SettingFileUploader.propTypes = {
    btnText: PropTypes.string,
    documents: PropTypes.string,
    multiple: PropTypes.bool,
    settingKey: PropTypes.string.isRequired,
    uploaderOptions: PropTypes.shape({}),
    deleteEndPoint: PropTypes.func,
    onChange: PropTypes.func,
};

SettingFileUploader.defaultProps = {
    btnText: '+ Ajouter une annexe',
    multiple: true,
};

export default SettingFileUploader;
