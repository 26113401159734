// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-action {
    height: 100%;
    display: inline-flex;
    margin: 0 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #464646;
    text-decoration: none;
    border: 0;
    outline: 0;
    background-color: inherit;
}

.list-action:hover,
.list-action:focus {
    color: #464646;
    text-decoration: none;
}

.list-action.right {
    float: right;
    color: #27cae6;
}

.list-action.disabled {
    cursor: not-allowed;
    opacity: .65;
}

.list-action.deactivated {
    opacity: .65;
}`, "",{"version":3,"sources":["webpack://./src/components/list/Action.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,yBAAyB;AAC7B;;AAEA;;IAEI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".list-action {\n    height: 100%;\n    display: inline-flex;\n    margin: 0 5px;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    color: #464646;\n    text-decoration: none;\n    border: 0;\n    outline: 0;\n    background-color: inherit;\n}\n\n.list-action:hover,\n.list-action:focus {\n    color: #464646;\n    text-decoration: none;\n}\n\n.list-action.right {\n    float: right;\n    color: #27cae6;\n}\n\n.list-action.disabled {\n    cursor: not-allowed;\n    opacity: .65;\n}\n\n.list-action.deactivated {\n    opacity: .65;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
