import { getOr } from 'lodash/fp';

const choices = {
    callAdvisorToAct: { values: ['E', 'D'] },
    sellEverything: { values: ['P'], rejectedProfiles: ['D'] },
};

const question = (value, { investorProfile = null }) => ('yes' === investorProfile ? getOr(null, value, choices) : {});

export default question;
