import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TextItem } from 'common/components/summary';

const types = {
    gff: 'GFF',
    fineArtSelection: 'Fine Art Selection',
    foresight: 'Prévoyance',
};

const Diversifications = ({ recommendation }) => (
    <Grid size={2}>
        {Object
            .entries(types)
            .filter(([type]) => recommendation[type])
            .map(([type, label]) => (
                <TextItem
                    key={type}
                    title={label}
                    value={recommendation[`${type}Comment`]}
                />
            ))}
    </Grid>
);

Diversifications.propTypes = {
    recommendation: PropTypes.shape({}).isRequired,
};

export default Diversifications;
