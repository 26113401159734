import PropTypes from 'prop-types';
import React from 'react';
import dataProvider from '../dataProvider';
import RipLayout from './RipLayout';
import RipSignatureState from './signatureState/Form';

const SignatureState = ({ params: { id }, rip, isElectronicSign = false }) => (

    <RipLayout >
        <div style={{ padding: '10px 40px' }}>
            <RipSignatureState
                back={`/rip/${id}/commitment`}
                id={id}
                rip={rip}
                last={false}
                nextRedirectTo
                isElectronicSign={isElectronicSign}
            />
        </div>
    </RipLayout>
);

SignatureState.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    rip: PropTypes.shape({
        id: PropTypes.string,
    }),
    isElectronicSign: PropTypes.bool,
};

export default dataProvider({
    id: ({ params: { id } }) => id,
    type: 'rip',
})(SignatureState);
