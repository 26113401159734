import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PdfReader from 'components/contract/lifeInsurance/Documents/PdfReader';
import BaseForm from '../form/Form';
import FormFields from './FormFields';
import Footer from './Footer';
import Menu from './Menu';
import { Comments, Recommendation, Simulators, Summary, Reattribute, Sponsoring } from './overlays';

class FormInner extends Component {
    filterOverlay() {
        const { back, nextButtonContent, overlay, last, hideBackButton, nextRedirectTo, enveloppeESigneCreated } = this.props;
        const footer = (
            <Footer
                back={back}
                nextButtonContent={nextButtonContent}
                showNav={!overlay || 'comments' === overlay}
                last={last}
                hideBackButton={hideBackButton}
                nextRedirectTo={nextRedirectTo}
                enveloppeESigneCreated={enveloppeESigneCreated}
            />
        );

        switch (overlay) {
            case 'reattribute':
                return (
                    <Fragment>
                        <Reattribute />
                        {footer}
                    </Fragment>
                );
            case 'ifb-sells-guide':
                return (
                    <Fragment>
                        <div className="container">
                            {/* <PdfViewer pdfPath="/IFB-sells-guide.pdf" /> */}
                            <PdfReader file="/api/documents/pdf/ifbSellsGuide" />
                        </div>
                        {footer}
                    </Fragment>
                );
            case 'recommendation':
                return (
                    <Fragment>
                        <Recommendation />
                        {footer}
                    </Fragment>
                );
            case 'summary': {
                const { id } = this.props;

                return (
                    <Fragment>
                        <Summary id={id} />
                        {footer}
                    </Fragment>
                );
            }
            case 'simulators':
                return (
                    <Fragment>
                        <Simulators />
                        {footer}
                    </Fragment>
                );
            case 'sponsoring': {
                const { id } = this.props;

                return (
                    <Fragment>
                        <Sponsoring id={id} />
                        {footer}
                    </Fragment>
                );
            }
            default: {
                const { children, handleSubmit } = this.props;
                const showComments = 'comments' === overlay;

                return (
                    <Fragment>
                        <FormFields handleSubmit={handleSubmit} fluid={showComments} footer={footer}>
                            {children}
                        </FormFields>
                        {showComments ? <Comments /> : null}
                    </Fragment>
                );
            }
        }
    }

    render() {
        const { hideMenu } = this.props;

        return (
            <Fragment>
                <BaseForm>
                    {this.filterOverlay()}
                </BaseForm>
                {hideMenu ? null : <Menu />}
            </Fragment>
        );
    }
}

FormInner.propTypes = {
    back: PropTypes.string,
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideMenu: PropTypes.bool,
    id: PropTypes.string.isRequired,
    nextButtonContent: PropTypes.string,
    last: PropTypes.bool,
    overlay: PropTypes.string,
    hideBackButton: PropTypes.bool,
    nextRedirectTo: PropTypes.string,
    enveloppeESigneCreated: PropTypes.bool,
};

FormInner.defaultProps = {
    back: '/',
    hideMenu: false,
    overlay: null,
    nextButtonContent: undefined,
    last: false,
};

const mapStateToProps = (state) => ({
    overlay: state.overlay,
});

const Form = connect(mapStateToProps)(FormInner);

export default Form;
