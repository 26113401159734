import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { get, sum } from 'lodash/fp';
import Dropdown from '../../../Dropdown';
import Title from '../../../Title';
import { AmountItem, CountItem, DateItem, OwnerItem } from '../../../../common/components/summary';
import safeSum from '../../../../utils/safeSum';

const types = {
    secondHome: 'Résidence secondaire',
    rentalProperty: 'Locatifs',
    other: 'Autres',
};

const Properties = ({ rip }) => {
    const properties = [];
    let totalMensualites = [];

    if ('owner' === rip.mainResidence) {
        if (rip.mainResidenceMortgages && get('mainResidenceMortgages', rip).length > 0) {
            rip.mainResidenceMortgages.forEach((credit) => credit.dueDateAmount && totalMensualites.push(parseInt(credit.dueDateAmount, 10)));
        }

        properties.push({
            title: 'Résidence principale',
            owner: rip.mainResidenceTenant,
            acquisitionValue: rip.mainResidenceAcquisitionPrice,
            currentValue: parseInt(rip.mainResidenceEstimatedValue, 10),
            acquisitionYear: rip.mainResidenceAcquisitionYear,
            loanEnd: 'owed' === rip.mainResidenceMortgage ? rip.mainResidenceEndLoan : rip.mainResidenceLastDueDate,
        });
    }

    if (rip.property && get('property', rip).length > 0) {
        rip.property.forEach((propriete) => {
            if (propriete.mortgages && get('mortgages', propriete).length > 0) {
                propriete.mortgages.forEach((credit) => credit.dueDateAmount && totalMensualites.push(parseInt(credit.dueDateAmount, 10)));
            }
        });

        properties.push(...rip.property.map((property) => ({
            title: types[property.type],
            owner: property.owner,
            acquisitionValue: property.acquisitionPrice,
            currentValue: parseInt(property.estimatedValue, 10),
            acquisitionYear: property.acquisitionYear,
            loanEnd: 'owed' === property.mortgage ? property.endLoan : property.lastDueDate,
        })));
    }

    totalMensualites = parseInt(sum(totalMensualites), 10);

    if (!properties.length) {
        return null;
    }

    return (
        <Fragment>
            <Title>Votre <b>patrimoine</b></Title>
            {properties.map(({ title, owner, amount, acquisitionValue, currentValue, acquisitionYear, loanEnd }) => (
                <Dropdown key={title} title={title}>
                    <OwnerItem title="Détenteur" rip={rip} value={owner} />
                    {amount ? <AmountItem title="Montant mensuel" value={amount} /> : null}
                    {acquisitionValue ? <AmountItem title="Valeur d'achat" value={acquisitionValue} /> : null}
                    {currentValue ? <AmountItem title="Valeur actuelle" value={currentValue} /> : null}
                    {acquisitionYear ? <CountItem title="Année d'achat" value={acquisitionYear} /> : null}
                    {loanEnd ? <DateItem title="Date de fin du crédit" value={loanEnd} /> : null}
                </Dropdown>
            ))}
            <dl>
                <AmountItem title="Valeur totale" value={safeSum('currentValue', properties)} important />
                {totalMensualites ? (
                    <AmountItem title="Versement mensuel total" value={totalMensualites} important />
                ) : null}
            </dl>
        </Fragment>
    );
};

Properties.propTypes = {
    rip: PropTypes.shape({
        mainResidence: PropTypes.string,
        mainResidenceMortgages: PropTypes.arrayOf(PropTypes.shape({
            forEach: PropTypes.func,
        })),
        property: PropTypes.arrayOf(PropTypes.shape({
            forEach: PropTypes.func,
            map: PropTypes.func,
        })),
        mainResidenceTenant: PropTypes.string,
        mainResidenceAcquisitionPrice: PropTypes.number,
        mainResidenceEstimatedValue: PropTypes.string,
        mainResidenceAcquisitionYear: PropTypes.number,
        mainResidenceMortgage: PropTypes.shape({}),
        mainResidenceEndLoan: PropTypes.number,
        mainResidenceLastDueDate: PropTypes.number,
    }).isRequired,
};

export default Properties;
