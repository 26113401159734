import { get, sum, flow, flowRight, map } from 'lodash/fp';
import { withProps } from 'recompose';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import baseDataForm from 'components/dataForm';
import multiStepValidation from 'components/multiStepValidation';
import { compose } from 'common/formValidation/lib';
import { formValidate as fundingValidate } from '../common/funding';
import propertyValidate from './Property/formValidate';
import chargesValidate from './Charges/formValidate';
import detailsValidate from './Details/formValidate';
import withRouter from '../../../withRouter';

const pinelValidation = compose({
    'simulation-property': propertyValidate,
    'simulation-funding': fundingValidate,
    'simulation-charges': chargesValidate,
    'simulation-details': detailsValidate,
});

const dataForm = ({ form, ...options }) => flowRight([
    withRouter,
    // setup multi step validation
    multiStepValidation(pinelValidation),
    // override the validate props
    connect(null, { change }),
    withProps(({ validate, change: updateField }) => ({
        // inform the form name
        form,
        // override the validate method
        validate: (values, props) => {
            // validate the form
            const errors = validate(values, props);

            // count the errors
            const errorCount = flow(
                // get  values
                Object.values,
                // count the number of errors for each form
                map((step) => Object.keys(step).length),
                // and get the sum
                sum,
            )(errors);

            // update the form
            updateField(form, 'errorCount', errorCount);

            // return only the errors for the current step
            return get(form, errors);
        },
    })),
    // initialize the original data form
    baseDataForm(options),
]);

export default dataForm;
