import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'components/layouts';
import getQueryVariable from '../../utils/getQueryVariable';
import { useSession } from '../sessions';
import InformationsInstance from '../../../src/common/components/summary/InformationsInstance';

const Informations = ({ location: { search } }) => {
    const session = useSession();
    const displayName = getQueryVariable(search, 'displayName');
    const legalAddress = getQueryVariable(search, 'legalAddress');
    const { label, emailSupport, informationsFacture } = session.instanceSettings;

    return (
        <Layout>
            <InformationsInstance nom={displayName} adresse={legalAddress || get('settings.legalAddress', session)} label={label} emailSupport={emailSupport} informationsFacture={informationsFacture} />
        </Layout>
    );
};

Informations.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
};

export default Informations;
