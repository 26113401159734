import { get, set } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Layout } from 'components/layouts';
import Title from 'components/Title';
import EmailTemplateManager from 'components/EmailTemplateManager';
import { concatAddress, splitAddress } from 'utils/shiftAddresses';
import { setModal } from '../../actions';
import { GridLayout } from '../form/layout';
import { DisabledField, InputField, TextAreaField, UserPasswordField, YesNoField } from '../form/field';
import dataForm from '../dataForm';
import { withSession } from '../sessions';
import formValidate from './formValidate';
import Subscription from './Subscription';
import Spinner from '../Spinner';

import './Profile.scss';
import AddressField from '../form/field/AddressField';

const Profile = ({ handleSubmit, session }) => {
    const { instanceTheseis } = session;

    return (
        <Layout className="user">
            <form className="container disclaimer profile" role="presentation" onSubmit={handleSubmit}>
                <h1>Mes informations</h1>
                <div className="article">
                    <Title>
                        <b>Informations personnelles</b>
                    </Title>
                    <GridLayout className="double condensed full-width gray-box">
                        {session.isMiddleUser ? (
                            <Fragment>
                                <DisabledField name="firstName" title="Prénom" value={session.firstName} />
                                <DisabledField name="lastName" title="Nom" value={session.lastName} />
                                <DisabledField name="phone" title="Téléphone" value={session.phone} />
                                <DisabledField name="email" title="Adresse email" value={session.email} />
                                <DisabledField name="oriasNumber" title="Numéro Orias" value={session.oriasNumber} />
                                <DisabledField name="companyName" title="Raison sociale" value={session.companyName} />
                                <DisabledField name="caseManager" title="Chargé d'affaire" value={session.caseManager ? `${session.caseManager.firstName} ${session.caseManager.lastName}` : 'NC'} />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <InputField name="firstName" title="Prénom" type="text" />
                                <InputField name="lastName" title="Nom" type="text" />
                                <UserPasswordField />
                                <InputField name="phone" title="Téléphone" type="text" />
                                <DisabledField name="email" title="Adresse email" value={session.email} />
                                <InputField name="oriasNumber" title="Numéro Orias" type="text" />
                                <InputField name="companyName" title="Raison sociale" type="text" />
                                <DisabledField name="caseManager" title="Chargé d'affaire" value={session.caseManager ? `${session.caseManager.firstName} ${session.caseManager.lastName}` : 'NC'} />
                            </Fragment>
                        )}
                        <AddressField
                            title="Adresse"
                            disabled={session.isMiddleUser}
                        />
                        <TextAreaField
                            double
                            name="greeting"
                            title="Signature de mail"
                            type="text"
                        />
                        <YesNoField title="Recevoir un email pour les évènements importants" name="importantEventsAlert" />
                    </GridLayout>
                    {!instanceTheseis && false === get('entity.overrideEmailTemplates', session) && (
                        <Fragment>
                            <hr />
                            <Title>
                                <b>Emails</b>
                            </Title>
                            <EmailTemplateManager url="/api/users/emailTemplates" />
                        </Fragment>
                    )}
                </div>
                <hr />
                {session.instanceSettings.hasStripeSubscription && <Subscription />}
                <button className="sendButton" type="submit">
                    Enregistrer
                </button>
            </form>
        </Layout>
    );
};

Profile.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    session: PropTypes.shape({
        instanceTheseis: PropTypes.bool,
        isMiddleUser: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        oriasNumber: PropTypes.string,
        companyName: PropTypes.string,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        caseManager: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
        instanceSettings: PropTypes.shape({
            hasStripeSubscription: PropTypes.bool,
            label: PropTypes.string,
        }),
    }).isRequired,
};

export default compose(
    withSession,
    dataForm({
        autoSave: false,
        form: 'profile',
        type: 'user',
        validate: formValidate,
        prop: 'user',
        id: ({ session }) => `${session.id}/personalDetails`,
        // Process values from form to redux store
        processValues: (values) => set('address', concatAddress(values), values),
        // Process data from redux store to form
        processData: ({ user }) => ({
            initialValues: set('address', splitAddress(user), user),
        }),
        onSubmitSuccess: (result, dispatch) => {
            // fake loading to keep the modal available at least 1 sec
            setTimeout(() => {
                dispatch(setModal(null));
            }, 1000);
        },
    }),
    connect(
        null,
        (dispatch, { handleSubmit }) => ({
            handleSubmit: (event) => {
                dispatch(setModal(() => <Spinner>Sauvegarde en cours...</Spinner>, false));
                handleSubmit(event);
            },
        }),
    ),
)(Profile);
