import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';
import Icon from 'common/components/Icon';
import { formatAmount } from 'common/utils/format';
import { ControlledDropdown as Dropdown } from 'components/Dropdown';
import { lots } from './computeLoan';

import './Presets.scss';

const MenuList = ({ children, ...props }) => (
    <components.MenuList {...props}>
        <div className="lot head">
            <span className="icon" />
            <span>Montant</span>
            <span>Durée</span>
            <span>Montant avec frais</span>
            <span>Mensualité</span>
            <span>Loyer mensuel</span>
        </div>
        {children}
    </components.MenuList>
);

MenuList.propTypes = {
    children: PropTypes.node,
};

const Option = (props) => {
    const { amount, duration, amountWithFees, monthlyPayment, rent } = props.data;
    const isAvailable = amount <= props.selectProps.maxAmountLoanable;

    return (
        <components.Option {...props}>
            <div className="lot">
                <Icon
                    icon={isAvailable ? 'check' : 'times'}
                    className={isAvailable ? 'green' : 'red'}
                />
                <span>{formatAmount(amount)}</span>
                <span>{duration} ans</span>
                <span>{formatAmount(amountWithFees)}</span>
                <span>{formatAmount(monthlyPayment)}</span>
                <span>{formatAmount(rent)}</span>
            </div>
        </components.Option>
    );
};

Option.propTypes = {
    data: PropTypes.shape({
        amount: PropTypes.number,
        duration: PropTypes.number,
        amountWithFees: PropTypes.number,
        monthlyPayment: PropTypes.number,
        rent: PropTypes.number,
    }).isRequired,
    selectProps: PropTypes.shape({
        maxAmountLoanable: PropTypes.number,
    }).isRequired,
};

const Control = () => null;

const id = 'presets';

const Presets = ({
    maxAmountLoanable,
    toggle,
    openDropdown,
    change,
}) => (
    <Dropdown
        title="J'utilise des lots et durées prédéfinis"
        toggle={() => toggle(id)}
        open={openDropdown === id}
    >
        <div className="rip-loan-simulator-presets">
            <Select
                menuIsOpen
                options={lots()}
                components={{ Option, MenuList, Control }}
                getOptionLabel={({ amount, duration }) => `${formatAmount(amount)} (${duration} ans)`}
                getOptionValue={({ amount, duration }) => `${amount}.${duration}`}
                maxAmountLoanable={maxAmountLoanable}
                onChange={({ amount, duration, monthlyPayment, rent }) => {
                    change('loanAmount', amount);
                    change('loanDuration', duration);
                    change('monthlyPayment', monthlyPayment);
                    change('monthlyLoan', rent);
                    toggle(id);
                }}
                value={null}
                styles={{
                    menu: (style) => ({
                        ...style,
                        position: 'relative',
                    }),
                }}
            />
        </div>
    </Dropdown>
);

Presets.propTypes = {
    change: PropTypes.func.isRequired,
    maxAmountLoanable: PropTypes.number.isRequired,
    toggle: PropTypes.func.isRequired,
    openDropdown: PropTypes.string,
};

export default Presets;
