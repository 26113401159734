import { get, isFunction, has, mapValues } from 'lodash/fp';
import validate from './validate';

const switchValidate = (field, validators) => {
    const validates = mapValues(validate, validators);
    const getter = isFunction(field) ? field : get(field);

    return (errors, values, props) => {
        const value = getter(values, props, errors);

        if (has(value, validates)) {
            return validates[value](values, props, errors);
        }

        if (validates.default) {
            return validates.default(values, props, errors);
        }

        return errors;
    };
};

export default switchValidate;
