import React from 'react';
import { SearchLayout } from 'components/layouts';
import EntityTable from './EntityTable';

const EntityList = () => (
    <SearchLayout className="user-list" searchName="entity" searchPlaceholder="Rechercher un réseau">
        <EntityTable />
    </SearchLayout>
);

export default EntityList;
