import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const InteractiveItem = ({ to, name }) => (
    <NavLink to={to} className="wizard-item">
        <div className="wizard-item-point" />
        <div className="wizard-item-label">
            <span>{name}</span>
        </div>
    </NavLink>
);

InteractiveItem.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default InteractiveItem;
