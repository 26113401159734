import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import { withProps } from 'recompose';
import { Field } from 'redux-form';
import FieldInfo from 'components/form/FieldInfo';
import Error from '../Error';

const RadioGroup = (props) => {
    const {
        children,
        groupComponent,
        input: { value, ...input },
        meta,
        info,
        renderInfo,
        ...rest
    } = props;

    // first get option elements
    const options = React.Children.map(children, (child) => React.cloneElement(child, {
        ...input,
        checked: value === child.props.value,
    }));

    // get the popover element
    const popover = info && renderInfo(props);

    // get the error element
    const error = <Error {...meta} />;

    return createElement(groupComponent, rest, options, popover, error);
};

export const radioInfoPropTypes = {
    info: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    renderInfo: PropTypes.func,
};

RadioGroup.propTypes = {
    groupComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    children: PropTypes.node.isRequired,
    input: PropTypes.shape({
        value: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        error: PropTypes.string,
        submitFailed: PropTypes.bool,
    }).isRequired,
    ...radioInfoPropTypes,
};

RadioGroup.defaultProps = {
    groupComponent: 'div',
    // renderInfo is not a component but a render method
    // eslint-disable-next-line react/prop-types, react/default-props-match-prop-types
    renderInfo: ({ input, info }) => <FieldInfo id={`${input.name}-info`}>{info}</FieldInfo>,
};

export default withProps({ component: RadioGroup })(Field);
