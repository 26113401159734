/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { POST } from 'utils/httpMethods';
import { connect } from 'react-redux';
import { flowRight } from 'lodash/fp';

import Footer from 'components/rip/Footer';
import Icon from 'common/components/Icon';
import { shouldPartnerSign } from 'common/utils/hasPartner';
import isElectLatestActionSign from 'common/utils/signature/defineLastestActionSign';
import SelectSignatures from './SelectSignatures/SelectSignatures';
import SignatureStatus from './SignatureStatus/SignatureStatus';

import { useSession } from '../../../../sessions';
import { saveData } from '../../../../../actions';

import './Menu.scss';

const checkIdentityFromUniversign = (tx, rip, user) => {
    if (tx.firstName.trim().toLowerCase() === ('client' === user ? rip.firstName : rip.partnerFirstName).trim().toLowerCase() && tx.lastName.trim().toLowerCase() === ('client' === user ? rip.lastName : rip.partnerLastName).trim().toLowerCase()) {
        return true;
    }

    return false;
};

const processSignerInfo = (rip, tx, signatureTimeAndType, signerIdentity, setSignatureMode, setResignUrl, save) => {
    if (signerIdentity && 'canceled' === tx.status) {
        const type = 'client' === signerIdentity ? 'client' : 'partner';
        // eslint-disable-next-line no-param-reassign
        signatureTimeAndType = { ...signatureTimeAndType, [type]: { type: 'refusal elect' } };
        save('rip', rip.id, { clientAndPartnerSignatureDate: signatureTimeAndType });
    }

    if (signerIdentity) {
        setSignatureMode('universign');
        setResignUrl(tx.url);
    }
};

const LaunchSign = (changeComponent, clientSigner, partnerSigner, goToAppointment, rip, hasOngoingSignatureProcess) => () => (
    <div className="sign-btn-wrapper">
        {goToAppointment ? (
            <Link to={`/rip/${rip.id}/appointment`}>
                <button className="sign-btn">
                    Continuer&nbsp;&nbsp;&nbsp;
                    <Icon icon="angle-right" />
                </button>
            </Link>
        ) : (
            <button className="sign-btn" onClick={changeComponent('manual-sign', { clientSigner, partnerSigner, renew: hasOngoingSignatureProcess })}>
                Lancer les signatures&nbsp;&nbsp;&nbsp;
                <Icon icon="angle-right" />
            </button>
        )}
    </div>
);

const Menu = ({ rip, changeComponent, back, clientSignatureMode, setClientSignatureMode, partnerSignatureMode, setPartnerSignatureMode, saveData: save }) => {
    const [hasOngoingSignatureProcess, setHasOngoingSignatureProcess] = useState(true);
    const [canSelectSignatures, setCanSelectSignatures] = useState(!hasOngoingSignatureProcess);

    const session = useSession();
    const { instanceTheseis } = session;

    const [clientResignUrl, setClientResignUrl] = useState(null);
    const [partnerResignUrl, setPartnerResignUrl] = useState(null);

    const [enLigne, setEnLigne] = useState(false);

    const goToAppointment = !canSelectSignatures;

    const partnerShouldSign = shouldPartnerSign(rip);

    const signatureTimeAndType = rip.clientAndPartnerSignatureDate;

    useEffect(() => {
        if (false === navigator.onLine) {
            return setEnLigne(false);
        }

        return setEnLigne(true);
    }, [navigator.onLine]);

    const signStatusState = true;
    useEffect(() => {
        POST(`/api/sign-status/rip/${rip.id}/${signStatusState}`)
            .then((res) => res.json())
            .then((transactionStatus) => {
                setHasOngoingSignatureProcess(transactionStatus.hasOngoingSignatureProcess);
                for (const tx of transactionStatus.signerInfos) {
                    const isClientSignature = isElectLatestActionSign(tx, signatureTimeAndType, 'client');
                    const isPartnerSignature = isElectLatestActionSign(tx, signatureTimeAndType, 'partner');

                    if (isClientSignature) {
                        processSignerInfo(
                            rip,
                            tx,
                            signatureTimeAndType,
                            checkIdentityFromUniversign(tx, rip, 'client') ? 'client' : null,
                            setClientSignatureMode,
                            setClientResignUrl,
                            save,
                        );
                    }

                    if (isPartnerSignature && partnerShouldSign) {
                        processSignerInfo(
                            rip,
                            tx,
                            signatureTimeAndType,
                            checkIdentityFromUniversign(tx, rip, 'partner') ? 'partner' : null,
                            setPartnerSignatureMode,
                            setPartnerResignUrl,
                            save,
                        );
                    }
                }
            });
    }, []);

    const footerProps = hasOngoingSignatureProcess
        ? { nextRedirectTo: `/rip/${rip.id}/appointment` }
        : {};

    return (
        <Fragment>
            <div className="signature-menu">
                <SignatureStatus
                    rip={rip}
                    resignUrl={{
                        client: clientResignUrl,
                        partner: partnerResignUrl,
                    }}
                    partnerShouldSign={partnerShouldSign}
                    enLigne={enLigne}
                />
                {canSelectSignatures && [
                    ...hasOngoingSignatureProcess ? [(
                        <div className="relaunch">
                            <button className="cancel" onClick={() => setCanSelectSignatures(false)}>Annuler la relance</button>
                        </div>
                    )] : [],
                    <SelectSignatures
                        rip={rip}
                        clientState={[clientSignatureMode, setClientSignatureMode]}
                        partnerShouldSign={partnerShouldSign}
                        partnerState={[partnerSignatureMode, setPartnerSignatureMode]}
                        enLigne={enLigne}
                    />,
                ]}
                {!canSelectSignatures && (
                    <div className="relaunch">
                        <button onClick={() => setCanSelectSignatures(true)}>Relancer le process de signature</button>
                    </div>
                )}
            </div>
            <Footer
                back={back}
                nextButtonComponent={LaunchSign(
                    changeComponent,
                    clientSignatureMode,
                    partnerSignatureMode,
                    goToAppointment,
                    rip,
                    hasOngoingSignatureProcess,
                )}
                isHiddenButtonForTheseis={instanceTheseis && !canSelectSignatures}
                {...footerProps}
            />
        </Fragment>
    );
};

Menu.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        clientAndPartnerSignatureDate: PropTypes.shape({}),
    }).isRequired,
    changeComponent: PropTypes.func.isRequired,
    back: PropTypes.string.isRequired,
    session: PropTypes.shape({
        entity: PropTypes.shape({
            name: PropTypes.string,
        }).isRequired,
    }).isRequired,
    clientSignatureMode: PropTypes.func.isRequired,
    setClientSignatureMode: PropTypes.func.isRequired,
    partnerSignatureMode: PropTypes.func.isRequired,
    setPartnerSignatureMode: PropTypes.func.isRequired,
    saveData: PropTypes.func.isRequired,
};

export default flowRight([
    connect(null, { saveData }),
])(Menu);
