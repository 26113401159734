import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../common/components/Icon';

import './CreateLink.css';

const CreateLink = ({ icon = 'plus', ...props }) => (
    <Link className="list-create-link" {...props}>
        <Icon icon={icon} />
    </Link>
);

CreateLink.propTypes = {
    icon: PropTypes.string,
};

export default CreateLink;
