import compose from '../lib/compose';
import financesValidation from './financesValidation';
import commitmentValidation from './commitmentValidation';
import estateValidation from './estateValidation';
import profileValidation from './profileValidation';
import taxesValidation from './taxesValidation';
import vitalRecordValidation from './vitalRecordValidation';

export default compose({
    'rip-edit': vitalRecordValidation,
    'rip-taxes': taxesValidation,
    'rip-estate': estateValidation,
    'rip-finances': financesValidation,
    'rip-profil': profileValidation,
    'rip-commitment': commitmentValidation,
});
