import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { formValues } from 'redux-form';

import FormButtons from 'components/FormButtons';
import dataForm from 'components/dataForm';
import scrollToFirstError from 'components/form/scrollToFirstError';
import handleError from 'utils/handleError';
import { withSession } from '../../../../sessions';
import Form from '../Form';
import Header from '../Header';
import simulationLoader from '../simulationLoader';
import Settings from './Settings';
import ScheduledPayments from './ScheduledPayments';
import { errorValidate } from './formValidate';
import { POST } from '../../../../../utils/httpMethods';
import Modality from './modality';

const ProductInner = ({
    handleSubmit,
    params: { id, simulationId },
    data,
}) => {
    const { programmedPayments, scheduledPayments, entryFees, subscriptionChoice } = data;
    const { fees } = scheduledPayments;

    const [isScheduleLineaire, setIsScheduleLineaire] = useState(false);
    const [productReference, setProductReference] = useState('611 PER');

    if ('mma' === subscriptionChoice) {
        // eslint-disable-next-line no-param-reassign
        data.productReference = productReference;
    }

    useEffect(() => {
        if (+fees < 0.03) {
            setIsScheduleLineaire(true);
            setProductReference('611 PER');
        } else {
            setIsScheduleLineaire(false);
            setProductReference('612 PER');
        }
        if (false === programmedPayments) {
            setProductReference('611 PER');
        }
    }, [entryFees, fees, programmedPayments]);

    return (
        <Form handleSubmit={handleSubmit}>
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <Header />
                    <Settings subscriptionChoice={subscriptionChoice} />
                    {programmedPayments && <ScheduledPayments subscriptionChoice={subscriptionChoice} isScheduleLineaire={isScheduleLineaire} />}
                    <Modality />
                </div>
            </div>
            <FormButtons
                back={`/rip/${id}/simulations/${simulationId}/profile`}
                list={`/rip/${id}/simulations/`}
            />
        </Form>
    );
};

ProductInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        programmedPayments: PropTypes.bool,
        entryFees: PropTypes.string,
        scheduledPayments: PropTypes.shape({
            fees: PropTypes.string,
        }),
        subscriptionChoice: PropTypes.string,
        productReference: PropTypes.string,
    }),
};

const Product = compose(
    simulationLoader,
    withSession,
    dataForm({
        form: 'simulation-product',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => POST(`/api/simulations/${simulationId}/complete`)
            .then(() => navigate(`/rip/${id}/simulations/`))
            .catch(handleError),
        type: 'simulation',
        validate: errorValidate,
        onSubmitFail: scrollToFirstError({ containerId: 'simulationFormInner' }),
    }),
    formValues({ data: 'data' }),
)(ProductInner);

export default Product;
