import PropTypes from 'prop-types';
import React from 'react';

import { PATCH } from 'utils/httpMethods';
import { Layout } from 'components/layouts';
import { v4 as uuidv4 } from 'uuid';
import Form from './Form';
import useRouter from '../../../useRouter';

const handleSubmit = async ({ id, ...body }) => {
    const res = await PATCH(`/api/entities/${id}`, { body });

    return res.json();
};

const Create = () => {
    const { navigate, params: { parentId } } = useRouter();

    return (
        <Layout hasFooter>
            <Form
                onSubmit={handleSubmit}
                onSubmitSuccess={({ id }) => navigate(`/admin/entity/${id}`)}
                initialValues={{
                    id: uuidv4(),
                    parentId: parentId || null,
                }}
            />
        </Layout>
    );
};
Create.propTypes = {
    navigate: PropTypes.shape({}),
    params: PropTypes.shape({
        parentId: PropTypes.string,
    }),
};

export default Create;
