import React, { Fragment } from 'react';
import { TextField, EmailField, TextAreaField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import Title from '../Title';
import './OtherBuyer.scss';

const OtherBuyer = () => (
    <Fragment>
        <Title>Coordonnées de facturation</Title>
        <GridLayout className="double condensed full-width gray-box">
            <TextField
                title="Prénom"
                name="buyer.buyerFirstName"
                required
            />
            <TextField
                title="Nom"
                name="buyer.buyerLastName"
                required
            />
            <EmailField
                title="Email"
                placeholder="nicolas.dupond@yahoo.fr"
                required
                name="buyer.buyerEmail"
            />
            <TextAreaField
                double
                name="buyer.buyerAddress"
                title="Adresse"
                type="text"
            />
        </GridLayout>
    </Fragment>
);

export default OtherBuyer;
