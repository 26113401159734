import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';

const WizardItem = ({ active, icon, label }) => (
    <div className={classNames('step', { active })}>
        <Icon icon={icon} />
        <span>{label}</span>
        <div className="point">
            <div className="point" />
        </div>
    </div>
);

WizardItem.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    active: PropTypes.bool.isRequired,
};

export default WizardItem;
