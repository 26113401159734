import { flow, filter, has, map, sum } from 'lodash/fp';
import getSettings from '../../../utils/settings';

const computeInvestment = flow([
    filter(({ type, parts }) => has(type, getSettings('simulation.scpi')) && parts),
    map(({ type, parts }) => parts * getSettings(['simulation', 'scpi', type, 'value'])),
    sum,
]);

export default computeInvestment;
