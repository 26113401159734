import PropTypes from 'prop-types';
import React from 'react';
import FormGroup from 'components/form/Group';
import PhoneBill from './PhoneBill';
import AddressProof from './AddressProof';
import Rib from './Rib';
import IdCard from './IdCard';
import FundsOrigin from './FundsOrigin';

const Documents = ({ uploader }) => (
    <FormGroup title="Pièces justificatives">
        <PhoneBill uploader={uploader} />
        <AddressProof uploader={uploader} />
        <IdCard uploader={uploader} />
        <Rib uploader={uploader} />
        <FundsOrigin uploader={uploader} />
    </FormGroup>
);

Documents.propTypes = {
    uploader: PropTypes.func.isRequired,
};

export default Documents;
