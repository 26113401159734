import { get } from 'lodash/fp';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Dropdown from '../../../Dropdown';
import Title from '../../../Title';
import { AmountItem, DateItem } from '../../../../common/components/summary';
import safeSum from '../../../../utils/safeSum';

const types = {
    carLoan: {
        title: 'Voiture',
        monthlyAmount: (charge) => ('loan' === charge.carOption ? charge.monthlyPayment : charge.rent),
        endDate: get('end'),
    },
    consumerLoan: {
        title: 'Crédit conso',
        monthlyAmount: get('monthlyPayment'),
        endDate: get('end'),
    },
    pension: {
        title: 'Pension versée',
        monthlyAmount: get('rent'),
    },
    other: {
        title: 'Autres',
        subtitle: get('subtype'),
        monthlyAmount: get('monthlyPayment'),
        endDate: get('end'),
    },
};

const Charges = ({ rip }) => {
    if (!rip.charges || !rip.charges.length) {
        return null;
    }

    const charges = rip.charges.map((charge) => ({
        title: (types[charge.type].subtitle)
            ? `${types[charge.type].title} - ${types[charge.type].subtitle(charge) || 'Crédit'}`
            : types[charge.type].title,
        monthlyAmount: parseInt(types[charge.type].monthlyAmount(charge), 10),
        endDate: types[charge.type].endDate ? types[charge.type].endDate(charge) : null,
    }));

    let investissementsSCPIAvecCredit = [];

    if (rip.investments) {
        investissementsSCPIAvecCredit = _.get(rip, 'investments', []).filter((investissement) => 'SCPI' === investissement.type && _.get(investissement, 'mortgages', []).length > 0);

        investissementsSCPIAvecCredit.forEach((investissement) => {
            investissement.mortgages.forEach((credit) => charges.push({
                endDate: credit.endLoan,
                title: `SCPI - ${credit.mortgageType}`,
                monthlyAmount: credit.dueDateAmount,
            }));
        });
    }

    const monthlyAmountTotal = safeSum('monthlyAmount', charges);

    return (
        <Fragment>
            <Title>Vos <b>charges</b></Title>
            {charges.map((charge) => (
                <Dropdown key={charge.title} title={charge.title}>
                    <AmountItem title="Montant mensuel" value={charge.monthlyAmount} />
                    {charge.endDate ? <DateItem title="Date de fin" value={charge.endDate} /> : null}
                </Dropdown>
            ))}
            <dl>
                <AmountItem title="Montant total des versements mensuels" value={monthlyAmountTotal} important />
            </dl>
        </Fragment>
    );
};

Charges.propTypes = {
    rip: PropTypes.shape({
        charges: PropTypes.arrayOf(PropTypes.shape({
            length: PropTypes.number,
            map: PropTypes.func,
        })),
        investments: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default Charges;
