import { isArray, concat } from 'lodash/fp';

const mergeArrays = (objValue, srcValue) => {
    if (isArray(objValue)) {
        return concat(objValue, srcValue);
    }

    return undefined;
};

export default mergeArrays;
