import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from '../../form/layout';
import { DateField } from '../../form/field';

const DivorceEvent = ({ name }) => (
    <HorizontalLayout>
        <DateField name={`${name}.date`} title="Date du jugement estimée" required />
    </HorizontalLayout>
);

DivorceEvent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default DivorceEvent;
