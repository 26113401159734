import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { syncRipAndDownload, setModal } from 'actions';
import Icon from 'common/components/Icon';
import DownloadButton from '../DownloadButton';
import Group from '../form/Group';
import Title from '../Title';
import DownloadRipSelection from './DownloadRipSelection';
import withRouter from '../withRouter';

const DownloadInner = ({ params: { id }, download, downloadRipSelection, rip, isInstanceTheseis, navigate }) => (
    <Group>
        <Title><b>Résumé du RIP</b></Title>
        <DownloadButton onClick={isInstanceTheseis ? () => downloadRipSelection(rip.id, rip.universignTransactionState, navigate) : () => download(id, navigate)}>
            <Icon icon="file-pdf-o" /> Télécharger
        </DownloadButton>
    </Group>
);

DownloadInner.propTypes = {
    download: PropTypes.func.isRequired,
    downloadRipSelection: PropTypes.func.isRequired,
    isInstanceTheseis: PropTypes.bool.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    rip: PropTypes.shape({
        id: PropTypes.string.isRequired,
        universignTransactionState: PropTypes.string.isRequired,
    }).isRequired,
    navigate: PropTypes.func,

};

const Download = compose(
    withRouter,
    connect(null, { download: syncRipAndDownload }),
    connect(null, (dispatch, { rip, navigate }) => ({
        downloadRipSelection: () => dispatch(setModal(() => (
            <DownloadRipSelection
                universignTxState={rip.universignTransactionState}
                id={rip.id}
                navigate={navigate}
            />
        ))),
    })),
)(DownloadInner);

export default Download;
