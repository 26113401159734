import PropTypes from 'prop-types';
import React from 'react';
import { getContext } from 'recompose';

const DisabledInner = ({ formGroup: Group, name, value, ...props }) => (
    <Group component="label" htmlFor={name} {...props}>
        <input id={name} name={name} value={value} type="text" disabled />
    </Group>
);

DisabledInner.propTypes = {
    formGroup: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

const Disabled = getContext({
    formGroup: PropTypes.func.isRequired,
})(DisabledInner);

export default Disabled;
