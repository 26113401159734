import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import FormGroup from 'components/form/Group';
import { HorizontalLayout } from 'components/form/layout';
import { IntegerField, TextField, YesNoField } from 'components/form/field';
import Title from 'components/Title';

const PartnerInner = ({ hasFinancialCapacity, createdAt }) => {
    // Une disponibilité minimun obligatoire à partir du 18/07/24
    const dateLimit = new Date('2024-07-18T00:00:00.000Z');
    const createdDateRip = new Date(createdAt);
    if (createdDateRip >= dateLimit) {
        return (
            <div className="rip-civil-state">
                <Fragment>
                    <FormGroup>
                        <Title><b>Montant</b> des disponibilités</Title>
                        <HorizontalLayout>
                            <IntegerField title="Minimum" name="partner.financialSupply.minimum" required />
                            <IntegerField title="Maximum" name="partner.financialSupply.maximum" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <FormGroup>
                        <Title><b>Capacité d&apos;épargne mensuelle</b></Title>
                        <HorizontalLayout>
                            <IntegerField title="Minimum" name="partner.projectSavings.minimum" required />
                            <IntegerField title="Maximum" name="partner.projectSavings.maximum" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <TextField
                        name="partner.financialSource"
                        title={<span><b>Origine</b> des disponibilités</span>}
                    />
                </Fragment>
            </div>
        );
    }

    return (
        <div className="rip-civil-state">
            <YesNoField
                name="partner.hasFinancialCapacity"
                title="Votre conjoint a-t-il des disponibilités financières ?"
                required
            />
            {hasFinancialCapacity ? (
                <Fragment>
                    <FormGroup>
                        <Title><b>Montant</b> des disponibilités</Title>
                        <HorizontalLayout>
                            <IntegerField title="Minimum" name="partner.financialSupply.minimum" required />
                            <IntegerField title="Maximum" name="partner.financialSupply.maximum" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <FormGroup>
                        <Title><b>Capacité d&apos;épargne mensuelle</b></Title>
                        <HorizontalLayout>
                            <IntegerField title="Minimum" name="partner.projectSavings.minimum" required />
                            <IntegerField title="Maximum" name="partner.projectSavings.maximum" required />
                        </HorizontalLayout>
                    </FormGroup>
                    <TextField
                        name="partner.financialSource"
                        title={<span><b>Origine</b> des disponibilités</span>}
                    />
                </Fragment>
            ) : null}
        </div>
    );
};

PartnerInner.propTypes = {
    hasFinancialCapacity: PropTypes.bool,
    createdAt: PropTypes.string,
};

PartnerInner.defaultProps = {
    hasFinancialCapacity: true,
};

const Partner = formValues({
    hasFinancialCapacity: 'partner.hasFinancialCapacity',
    createdAt: 'createdAt',
})(PartnerInner);

export default Partner;
