import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './Form.css';

const Form = ({ component, className, children }) => React.createElement(
    component,
    { className: classNames('rip-form', className) },
    children,
);

Form.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Form.defaultProps = {
    className: null,
    component: 'div',
};

export default Form;
