import React from 'react';
import Layout from '../../layouts/Layout';
import SyncLogs from './SyncLogs';

const SyncLogsList = () => (
    <Layout>
        <h1 className="text-center">Logs de synchro Middle Office</h1>
        <SyncLogs />
    </Layout>
);

export default SyncLogsList;
