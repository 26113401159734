import classNames from 'classnames';
import { getOr, without, uniq, flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { branch, withProps } from 'recompose';
import { change, registerField, unregisterField } from 'redux-form';
import FileInput from 'react-fine-uploader/file-input';
import FileName from 'react-fine-uploader/filename';
import Thumbnail from 'react-fine-uploader/thumbnail';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import Icon from 'common/components/Icon';

import './File.scss';
import { connectDisabledForm } from '../withDisabledForm';
import withFormName from '../withFormName';

const errors = {
    rejected: 'Le format de fichier est invalide.',
    'upload failed': 'Le téléchargement de la pièce a échoué, veuillez réessayer.',
};

class FileField extends PureComponent {
    constructor(props) {
        super(props);

        // original state
        this.state = {
            fineUploader: null,
            submittedFiles: [],
            status: null,
            error: null,
        };

        this.renderFile = this.renderFile.bind(this);
    }

    componentDidMount() {
        this.props.onStatusChange(null, null, []);
        // the uploader is set with the original properties
        // it won't update on properties changes
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ fineUploader: this.initializeUploader(this.props.uploader) });
    }

    get isEmpty() {
        return 0 === this.state.submittedFiles.length;
    }

    get emptyInputElement() {
        const { fineUploader } = this.state;
        const { disabled, name, uploadText } = this.props;

        return (
            <FileInput name={name} uploader={fineUploader} disabled={disabled}>
                <Icon icon="upload" /> {uploadText}
            </FileInput>
        );
    }

    initializeUploader(options) {
        const uploader = new FineUploaderTraditional(options);

        uploader.on('statusChange', (id, oldStatus, newStatus) => {
            let { submittedFiles } = this.state;
            const { onStatusChange } = this.props;

            if ('submitted' === newStatus) {
                // add the new file ID
                submittedFiles = [...submittedFiles, id];
            } else if (['canceled', 'deleted'].includes(newStatus)) {
                // remove the ID for the gone file
                submittedFiles = without([id], submittedFiles);
            } else if ('upload successful' === newStatus) {
                // on session load this state will be emitted
                submittedFiles = uniq([...submittedFiles, id]);
            }

            this.setState({
                status: newStatus,
                error: getOr('', newStatus, errors),
                submittedFiles,
            });

            if (onStatusChange) {
                // we do have a callback
                onStatusChange(newStatus, id, submittedFiles);
            }
        });

        return uploader;
    }

    renderFile(id) {
        const { fineUploader, status, error } = this.state;
        const { disabled, infoUploaded } = this.props;

        return (
            <Fragment key={id}>
                <div className="file-item">
                    {/* Thumbnail (preview) */}
                    <div className="thumbnail">
                        <Thumbnail
                            id={id}
                            uploader={fineUploader}
                            notAvailablePlaceholder={<Icon icon="file-pdf-o" />}
                        />
                        {!disabled ? (
                            <button
                                className="delete-button"
                                onClick={(e) => {
                                    // prevent default event
                                    e.preventDefault();
                                    // delete the file
                                    fineUploader.methods.deleteFile(id);
                                }}
                            >
                                <Icon icon="times" />
                            </button>
                        ) : null}
                    </div>
                    {/* State & Filename */}
                    {'upload successful' === this.state.status ? (
                        <div className="filename success">
                            <Icon icon="check" />
                        </div>
                    ) : null}
                    {('upload successful' !== status && !error) ? (
                        <div className="filename">Téléchargement...</div>
                    ) : null}
                    <div className="filename">
                        <FileName id={id} uploader={fineUploader} />
                    </div>
                </div>
                {'upload successful' === status && infoUploaded ? (
                    <div className="file-info">
                        <div className="alert alert-info">
                            {infoUploaded}
                        </div>
                    </div>
                ) : null}
            </Fragment>
        );
    }

    render() {
        const { className } = this.props;
        const { submittedFiles, fineUploader } = this.state;
        const { isEmpty } = this;

        return fineUploader && (
            <div className={classNames('file-box', className, { empty: isEmpty })}>
                {/* Input(s) */}
                {isEmpty ? this.emptyInputElement : submittedFiles.map(this.renderFile)}
                {/* Errors */}
                {this.state.error ? (
                    <div className="alert alert-danger">
                        {this.state.error}
                    </div>
                ) : null}
            </div>
        );
    }
}

FileField.propTypes = {
    className: PropTypes.string,
    uploader: PropTypes.shape({}).isRequired,
    name: PropTypes.string.isRequired,
    uploadText: PropTypes.string.isRequired,
    infoUploaded: PropTypes.string,
    onStatusChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
};

FileField.defaultProps = {
    className: null,
    infoUploaded: null,
    onStatusChange: null,
};

const withRedux = flowRight([
    withFormName,
    connect(null, {
        updateForm: change,
        register: registerField,
        unregister: unregisterField,
    }),
    withProps(({ form, reduxField, updateForm, onStatusChange = null }) => ({
        onStatusChange: (status, id, submittedFiles) => {
            // update the value on the redux form
            updateForm(form, reduxField, submittedFiles);

            // we keep the original behavior
            // we don't want to break anything
            if (onStatusChange) {
                onStatusChange(status, id, submittedFiles);
            }
        },
    })),
]);

export default flowRight([
    connectDisabledForm,
    branch(({ reduxField }) => reduxField, withRedux),
])(FileField);
