import PropTypes from 'prop-types';
import React from 'react';

const Actions = ({ children }) => (
    <td className="list-actions">
        <div className="container">
            {children}
        </div>
    </td>
);

Actions.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Actions;
