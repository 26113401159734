import { get } from 'lodash/fp';
import { useMemo } from 'react';
import useSession from './useSession';

const useSettings = (path = null) => {
    const { appSettings } = useSession();

    return path ? get(path, appSettings) : appSettings;
};

export const useSettingsMemo = (factory, inputs = []) => {
    const settings = useSettings();

    return useMemo(() => factory(settings), [settings, ...inputs]);
};

export default useSettings;
