import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { formValues } from 'redux-form';
import { incomeRegimes, profitActivities } from 'common/choices';
import { setModal } from 'actions/modal';
import Icon from 'common/components/Icon';
import { ChoiceField, IntegerField, YesNoField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import { OwnerField } from '../field';
import WitholdingIncomeTaxPaymentOptionTable from './WitholdingIncomeTaxPaymentOptionTable';
import './Profit.scss';
import FieldInfo from '../../form/FieldInfo';

const Profit = ({ name, type, regime, witholdingIncomeTaxPaymentOption, onInfoClicked, ripFromRecommendation }) => {
    const renderInfo = useCallback(() => (
        <button type="button" className="form-field-info-button witholding" onClick={onInfoClicked}>
            <Icon icon="info" />
        </button>
    ), [onInfoClicked]);

    return (
        <HorizontalLayout>
            <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" ripFromRecommendation={ripFromRecommendation} required />
            {'BIC' === type && (
                <div className="content-field-info">
                    <ChoiceField
                        name={`${name}.activity`}
                        title={(
                            <Fragment>
                                Activité*
                                <FieldInfo id="profitActivities" className="FieldInfo">
                                    <div>
                                        <p>
                                            En cas de régime micro l’abattement est de 71% dans le cadre des activités de commerce / fourniture de logement et de 50% dans le cadre des prestations de services.
                                        </p>
                                    </div>
                                </FieldInfo>
                            </Fragment>
                        )}
                        choices={profitActivities}
                    />
                </div>

            )}
            <IntegerField name={`${name}.result`} title="Résultat annuel" required />
            <ChoiceField name={`${name}.regime`} title="Régime" choices={incomeRegimes} required />
            <YesNoField name={`${name}.managementCenter`} title="Faites-vous partie d'un centre agréé ?" />
            {'micro' === regime && (
                <Fragment>
                    <YesNoField
                        name={`${name}.witholdingIncomeTaxPaymentOption`}
                        title="Option pour le versement libératoire de l'impôt sur le revenu ?"
                        double
                        info
                        renderInfo={renderInfo}
                    />
                    {witholdingIncomeTaxPaymentOption && (
                        <div className="alert alert-warning text-center income-alert">
                            Le revenu saisi ne sera pas pris en compte pour le calcul de l&apos;impôt sur le revenu.
                        </div>
                    )}
                </Fragment>
            )}
        </HorizontalLayout>
    );
};

Profit.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    regime: PropTypes.string,
    witholdingIncomeTaxPaymentOption: PropTypes.bool,
    onInfoClicked: PropTypes.func.isRequired,
    ripFromRecommendation: PropTypes.shape({}),
};

export default compose(
    connect(
        null,
        (dispatch) => ({
            onInfoClicked: () => dispatch(setModal(() => <WitholdingIncomeTaxPaymentOptionTable />)),
        }),
    ),
    formValues(({ name }) => ({
        regime: `${name}.regime`,
        witholdingIncomeTaxPaymentOption: `${name}.witholdingIncomeTaxPaymentOption`,
    })),
)(Profit);
