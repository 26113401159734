import { has } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { initialFundingReasonTypes } from 'common/choices';
import FormGroup from 'components/form/Group';
import Title from 'components/Title';
import { GridLayout } from 'components/form/layout';
import { ChoiceField, DateField, IntegerField, PercentageField, SelectField, YesNoField, EditableField } from 'components/form/field';
import { formValues } from 'redux-form';
import useSettings, { useSettingsMemo } from 'components/sessions/useSettings';
import sortedAlphabetically from 'utils/sortedAlphabetically';
import { useSession } from '../../../../sessions';
import ConnectedProductChart from './ConnectedProductChart';
import FieldInfo from '../../../../form/FieldInfo';

const getContractsTypes = (perTypes, permissions, products, subscriptionChoice) => Object
    .entries(perTypes)
    .reduce((types, [type, { deleted, name, suffix, onlyCA, subscription }]) => {
        if (subscription === subscriptionChoice && !deleted && has([type], products) && (!onlyCA || permissions.isCaseManager())) {
            return { ...types, [type]: suffix ? `${name} ${suffix}` : name };
        }

        return types;
    }, {});

const Settings = ({ investorProfile, contractType, data, subscriptionChoice }) => {
    const infoPer = (
        <div>
            <p>La plus élevée des 2 limites suivantes est retenue :</p>
            <ul>
                <li>
                    <span style={{ textDecoration: 'underline' }}>10 % des revenus d&apos;activité professionnelle de N-1</span> nets de frais, revenus retenus dans la limite de 8 PASS de N-1,
                </li>
                <li>
                    <span style={{ textDecoration: 'underline' }}>10 % du PASS de N-1 </span>
                </li>
            </ul>
            <p><span style={{ fontWeight: 'bold' }}> Possibilité d’utiliser les plafonds non utilisés des 3 années antérieures. </span></p>
        </div>

    );

    const { programmedPayments } = data;
    useEffect(() => {
        Reflect.deleteProperty(data, 'initialFunding');
        Reflect.deleteProperty(data, 'editableInitialFunding');
        if (false === programmedPayments) {
            // eslint-disable-next-line no-param-reassign
            data.initialFunding = 1500;
        }
    }, [programmedPayments]);

    const { permissions } = useSession();

    const products = useSettings(['simulation', 'per', 'contracts']);

    const profilOuLaRepartitionDuProduitNEstPasVide = (product) => Object.entries(product).filter((x) => undefined !== x[1].repartition && null !== x[1].repartition && 0 !== x[1].repartition.length);

    let filtrageProduits = Object.entries(products).filter((product) => {
        const profileActif = profilOuLaRepartitionDuProduitNEstPasVide(product[1].profiles).flat();

        return profileActif.find((profil) => profil === investorProfile);
    });

    filtrageProduits = Object.fromEntries(filtrageProduits);

    const contractsTypes = useSettingsMemo(({ simulation: { per } }) => getContractsTypes(
        per.contracts,
        permissions,
        filtrageProduits,
        subscriptionChoice,
    ), [investorProfile, permissions]);

    return (
        <FormGroup>
            <Title><b>Caractéristiques et paramètres</b></Title>
            <GridLayout className="condensed">
                <div className="double">
                    <ChoiceField
                        name="data.contractType"
                        title="Type de contrat"
                        choices={sortedAlphabetically(contractsTypes)}
                        required
                    />
                    {/* Kept for later re-use */}
                    {false && contractType && !!contractType.match(/Immo$/i) && (
                        <div className="alert alert-warning">
                            Attention, cette allocation est soumise à dérogation
                        </div>
                    )}
                </div>
                <div className="double">
                    <ConnectedProductChart
                        className="in-box"
                        profile={investorProfile}
                    />
                </div>
                <IntegerField
                    name="data.ceilingsAvailable"
                    title={(
                        <Fragment>
                            Plafond disponible*
                            <FieldInfo>
                                {infoPer}
                            </FieldInfo>
                        </Fragment>
                    )}
                />
                <IntegerField
                    name="data.amountOfPaymentsAlreadyDone"
                    title="Montant des versements déjà effectués dans le cadre d&apos;un support retraite cette année"
                    required
                />
                <DateField
                    name="data.subscriptionDate"
                    title="Date de souscription"
                    required
                />
                <IntegerField
                    name="data.retirement"
                    title="Année de départ à la retraite"
                    required
                />
                <YesNoField
                    title="Souhaitez-vous mettre en place des versements programmés ?"
                    name="data.programmedPayments"
                    required
                />
                <SelectField
                    name="data.initialFundingReason"
                    title="Origine du versement"
                    required
                >
                    <option />
                    {Object.entries(initialFundingReasonTypes).map(([name, title]) => (
                        <option key={name} value={name}>{title}</option>
                    ))}
                </SelectField>
                {false === programmedPayments ? (<EditableField
                    type="number"
                    title="Montant initial*"
                    name="data.initialFunding"
                    editName="data.editableInitialFunding"
                    defaultValue={1500}
                    required
                />) : (
                    <IntegerField
                        name="data.initialFunding"
                        title="Montant initial"
                        required={programmedPayments}
                    />
                )}
                <PercentageField
                    name="data.entryFees"
                    title="Frais d'entrée pour le montant initial (%)"
                    required
                />
            </GridLayout>
        </FormGroup>
    );
};

Settings.propTypes = {
    investorProfile: PropTypes.string.isRequired,
    contractType: PropTypes.string.isRequired,
    subscriptionChoice: PropTypes.string,
    data: PropTypes.shape({
        programmedPayments: PropTypes.bool,
        initialFunding: PropTypes.number,
    }),
};

export default formValues({ investorProfile: 'data.profile', contractType: 'data.contractType', data: 'data' })(Settings);
