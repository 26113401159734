import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'common/components/Icon';

const MenuItem = ({ to, title, description, icon }) => (
    <Link to={to}>
        <div className="admin-link">
            <div className="menu-icon">
                <Icon icon={icon} />
            </div>
            {title}
            <p>{description}</p>
        </div>
    </Link>
);

MenuItem.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default MenuItem;
