import React from 'react';
import Icon from 'common/components/Icon';

import './SendButton.css';

const SendButton = () => (
    <button className="sendButton" type="submit">
        Envoyer&nbsp;
        <Icon icon="angle-right" />
    </button>
);

export default SendButton;
