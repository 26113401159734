import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Grid, AmountItem, TextItem, CountItem, PercentageItem } from 'common/components/summary';
import Title from 'components/Title';

const Operation = ({
    simulationSummary: {
        instalmentCosts: { investmentWithTVA, investment },
        operationResults: {
            tvaGain,
        },
        operationCash,
    },
    simulation: {
        needFunding,
        fundingRate,
        data: {
            program: { type } = {},
            funding: { tvaFunder },
        } = {},
    },
}) => {
    const isTvaPromoter = 'promoter' === tvaFunder;

    return (
        <Fragment>
            <Title className="margin-top"><b>Opération</b></Title>
            <Grid size={2}>
                <AmountItem
                    title="Montant de l'investissement (TTC) + frais d'acquisition"
                    value={isTvaPromoter ? investment : investmentWithTVA}
                />
                {needFunding ? (
                    <PercentageItem
                        title="Taux du financement"
                        value={fundingRate}
                    />
                ) : (
                    <TextItem
                        title="Taux du financement"
                        value="Paiement cash"
                    />
                )}
                {'censi-bouvard' !== type ? (
                    <Fragment>
                        <AmountItem title="Montant de la TVA récupérée (en amortissement)" value={tvaGain} />
                        <CountItem
                            title="Nombre d'année de revenus non fiscalisés"
                            value={operationCash.filter(({ fiscalResult }) => !fiscalResult).length}
                        />
                    </Fragment>
                ) : null}
            </Grid>
        </Fragment>
    );
};

Operation.propTypes = {
    simulation: PropTypes.shape({
        needFunding: PropTypes.bool,
        fundingRate: PropTypes.number,
        data: PropTypes.shape({
            program: PropTypes.shape({}),
        }),
    }).isRequired,
    simulationSummary: PropTypes.shape({
        instalmentCosts: PropTypes.shape({
            investmentWithTVA: PropTypes.number,
            investment: PropTypes.number,
        }),
        operationResults: PropTypes.shape({
            tvaGain: PropTypes.number,
        }),
        operationCash: PropTypes.number,

    }).isRequired,
};

export default Operation;
