import { flow } from 'lodash/fp';
import moment from 'moment';
import normalize from '../rip/normalize';
import filterForOwner from '../rip/filterForOwner';
import availableIncomes from './availableIncomes';
import cappedReductions from './cappedReductions';
import charges from './charges';
import credits from './credits';
import debtRatio from './debtRatio';
import deductions from './deductions';
import furnishedRentalResults from './furnishedRentalResults';
import incomes from './incomes';
import incomesN1 from './incomesN1';
import netIncomes from './netIncomes';
import referenceIncomes from './referenceIncomes';
import incomeTax from './incomeTax';
import landRentalResults from './landRentalResults';
import remainingIncomes from './remainingIncomes';
import parts from './parts';
import rawIncomes from './rawIncomes';
import reductions from './reductions';
import uncappedReductions from './uncappedReductions';
import familyDetails from './familyDetails';
import socialCharges from './socialCharges';
import landRentalRegime from './landRentalRegime';
import assets from './assets';
import outstandingCapital from './outstandingCapital';
import savings from './savings';
import sci from './sci';
import applyEvents from '../../utils/applyEvents';
import getSettings from '../../utils/settings';
import { setCurrentYear } from '../../utils/currentYear';
import financialInvestments from './financialInvestments';
import property from './properties';

const computeElements = [
    charges,
    credits,
    rawIncomes,
    availableIncomes,
    landRentalResults,
    furnishedRentalResults,
    deductions,
    incomes,
    netIncomes,
    referenceIncomes,
    remainingIncomes,
    parts,
    cappedReductions,
    uncappedReductions,
    reductions,
    incomeTax,
    debtRatio,
    familyDetails,
    socialCharges,
    landRentalRegime,
    assets,
    outstandingCapital,
    savings,
    sci,
    financialInvestments,
    property,
    incomesN1,
];

export const applyCurrentYearEvents = (rip) => applyEvents(rip, moment().year());

const filterRip = (owner) => (rip) => (
    'cohabitation' === rip.familySituation
        ? filterForOwner(rip, owner || 'client')
        : rip
);

const computeSummary = (originalRip, owner = null, isCurrentYear = null) => {
    const rip = flow([
        ...isCurrentYear ? [applyCurrentYearEvents] : [],
        // we normalize
        normalize,
        // we build the rip for the right client
        filterRip(owner),
    ])(originalRip);

    if (null !== isCurrentYear) {
        setCurrentYear(moment().year());
    }

    // we pass down dynamic settings to every compute functions
    const settings = getSettings();

    return computeElements.reduce((summary, computeElement) => ({
        ...summary,
        ...computeElement(rip, summary, settings),
    }), {});
};

export default computeSummary;
