import moment from 'moment';
import createSimpleValidator from './createSimpleValidator';

const errorString = 'Le client doit être majeur (> 18 ans).';
const checkIsMajor = createSimpleValidator(
    (value) => moment(value).add(18, 'years') < moment(),
    errorString,
);

export default checkIsMajor;
