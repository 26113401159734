export const concatAddress = ({ address }) => {
    const {
        street = '',
        complement = '',
        zipCode = '',
        city = '',
    } = address || {};

    return `${street}${complement && `\n${complement}`}\n${zipCode}\n${city}`;
};

export const splitAddress = ({ address = '' }) => {
    const arrAddress = address ? address.split('\n') : [];

    let street = null;
    let complement = null;
    let zipCode = null;
    let city = null;

    if (4 === arrAddress.length) {
        [street, complement, zipCode, city] = arrAddress;
    } else if (4 < arrAddress.length) {
        let rest = [];
        [street, complement, zipCode, ...rest] = arrAddress;
        city = rest.join(' ');
    } else {
        [street, zipCode, city] = arrAddress;
    }

    return {
        street,
        ...complement && { complement },
        zipCode,
        city,
    };
};
