import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';

const Other = ({ name }) => (
    <HorizontalLayout>
        <TextField name={`${name}.specify`} title="Préciser" />
    </HorizontalLayout>
);

Other.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Other;
