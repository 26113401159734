import { isArray, isEmpty } from 'lodash/fp';
import hasPartner from './hasPartner';

const getOwners = (rip, options = {}, pensionComponent, goingRetiredComponent) => {
    const {
        firstName,
        familySituation,
        children,
        sci,
    } = rip;

    const {
        onlyCohabitation = false,
        onlyCouple = false, // married, partner, cohabitation
        showSci = false,
        single = false,
        child = false,
    } = options;

    const partnerFirstName = isEmpty(rip.partnerFirstName) ? 'Conjoint(e) / NC' : rip.partnerFirstName;

    if (onlyCouple && !['cohabitation', 'partner', 'married'].includes(familySituation)) {
        return [];
    }

    if (onlyCohabitation && 'cohabitation' !== familySituation) {
        // by returning an empty array
        // it'll cancel the whole field
        // because we only want it for cohabitation
        return [];
    }

    const owners = [
        { value: 'client', text: firstName },
    ];

    if (hasPartner(familySituation) && partnerFirstName) {
        owners.push({ value: 'partner', text: partnerFirstName });

        if (!single && !goingRetiredComponent && (!pensionComponent || ['married', 'partner'].includes(familySituation))) {
            owners.push({ value: 'clientAndPartner', text: `${firstName} et ${partnerFirstName}` });
        }
    }

    if (showSci && !isEmpty(sci)) {
        sci.forEach(({ id, sciName }) => {
            owners.push({ value: `sci-${id}`, text: `SCI ${sciName}` });
        });
    }

    if (child && isArray(children)) {
        children.forEach(({ firstNames }, index) => {
            owners.push({ value: `child_${index}`, text: firstNames });
        });
    }

    return owners;
};

export default getOwners;
