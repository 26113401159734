import { flowRight } from 'lodash/fp';
import { withProps } from 'recompose';
import SignaturesCommon from '../Signatures';
import signatureLoader from './signatureLoader';
import mapPrefixToProps from './mapPrefixToProps';
import withRouter from '../withRouter';

const Signatures = flowRight([
    withRouter,
    signatureLoader,
    mapPrefixToProps,
    withProps(({ params: { id }, signature, prefix }) => ({
        type: 'signatures',
        id,
        data: signature,
        back: `${prefix}/signatures/${id}/edition`,
        list: `${prefix}/signatures`,
        next: '/',
    })),
])(SignaturesCommon);

export default Signatures;
