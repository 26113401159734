import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { otherChargeOptions } from 'common/choices';
import { ChoiceField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';
import OtherCharge from './OtherCharge';

const Other = ({ name }) => (
    <Fragment>
        <HorizontalLayout>
            <OwnerField name={`${name}.fiscalHome`} title="Titulaire concerné" required showSci />
            <ChoiceField
                name={`${name}.otherOption`}
                title="Type&nbsp;?"
                choices={otherChargeOptions}
                required
            />
        </HorizontalLayout>
        <OtherCharge name={name} />
    </Fragment>
);

Other.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Other;
