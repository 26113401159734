import React, { useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import { camelCase } from 'lodash/fp';
import { reduxForm, Form, SubmissionError, change } from 'redux-form';

import GridRadioButton from 'components/form/GridRadioButton';
import GridRadioGroup from 'components/form/GridRadioGroup';
import { POST } from 'utils/httpMethods';
import Title from 'components/Title';
import { GridLayout } from 'components/form/layout';
import { IntegerField, PercentageFloatField, TextField } from 'components/form/field';
import validate from 'common/formValidation/lib';
import { buildPerContractsValidation } from 'common/formValidation/settings/perSettingsValidation';
import SupportRepartition from '../lifeInsurance/SupportRepartition/SupportRepartition';

const CreateNewContractPER = ({ error, baseProducts, handleSubmit, hideForm, hiddenAll, showForm, dispatch }) => {
    const [hidden, setHidden] = useState(true);
    const [selectedRadio, setSelectedRadio] = useState(null);

    const showNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(false);
        showForm();
    }, [setHidden]);
    const hideNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(true);
        hideForm();
    }, [setHidden]);

    const toggleRadio = (value) => {
        if (selectedRadio === value) {
            dispatch(change('simulation-per-create', 'subscription', null));
            setSelectedRadio(null);
        } else {
            dispatch(change('simulation-per-create', 'subscription', value));
            setSelectedRadio(value);
        }
    };

    return (
        <div className={classNames('new-simu', { hidden: hiddenAll })}>
            {hidden ? (
                <button onClick={showNewForm}>Nouveau contrat PER</button>
            ) : (
                <button onClick={hideNewForm} className="cancel">Annuler</button>
            )}
            <div className={classNames('new-simulation-form', { hidden })}>
                <h1 className="text-center">Paramètres du nouveau contrat PER</h1>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <GridLayout className="double condensed full-width">
                        <TextField
                            name="name"
                            title="Nom"
                        />
                        <PercentageFloatField
                            name="fees.min"
                            title="Frais d'entrée min"
                        />
                        <PercentageFloatField
                            name="fees.max"
                            title="Frais d'entrée max"
                        />
                        <IntegerField
                            name="miniInitialFunding"
                            title="Minimum versement initial"
                        />
                        <IntegerField
                            name="miniScheduledPaymentAmount"
                            title="Minimum versement programmé"
                        />
                        <TextField
                            name="codeApporteur"
                            title="Code apporteur"
                        />
                        {['P', 'E', 'D'].map((profile) => (
                            <Fragment key={profile}>
                                <SupportRepartition
                                    key={`collection-${profile}`}
                                    profile={profile}
                                    baseProducts={baseProducts}
                                />
                            </Fragment>
                        ))}
                    </GridLayout>
                    <Title><b>Souscription</b></Title>
                    <GridRadioGroup name="subscription" className="PER-subscription">
                        <GridRadioButton label="CARDIF" value="cardif" checked={'cardif' === selectedRadio} onClick={() => toggleRadio('cardif')} />
                        <GridRadioButton label="MMA" value="mma" checked={'mma' === selectedRadio} onClick={() => toggleRadio('mma')} />
                    </GridRadioGroup>
                    <div className="submit PER-Button">
                        <button type="submit">Créer</button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

CreateNewContractPER.propTypes = {
    baseProducts: PropTypes.shape({}),
    handleSubmit: PropTypes.func,
    hiddenAll: PropTypes.bool,
    showForm: PropTypes.func,
    dispatch: PropTypes.func,
    hideForm: PropTypes.func,
    error: PropTypes.string,
};

export default reduxForm({
    form: 'simulation-per-create',
    onSubmit: async (values) => {
        const id = `simulation.per.contracts.${camelCase(values.name)}`;

        try {
            await POST('/api/settings/per', { body: { id, value: values } });
        } catch (error) {
            const data = await error.response.json();

            throw new SubmissionError({ _error: 'La Création à échoué !', ...data });
        }
    },
    onSubmitSuccess: () => {
        document.location.href = '/admin/settings/simulation';
    },
    validate: validate(buildPerContractsValidation()),
})(CreateNewContractPER);
