import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSession } from 'components/sessions';
import Overview from 'components/contract/lifeInsurance/Overview';
import ClientInformation from 'components/contract/lifeInsurance/ClientInformation';
import DocumentsUpload from 'components/contract/lifeInsurance/DocumentsUpload';
import Signatures from 'components/contract/lifeInsurance/Signatures';
import Validation from 'components/contract/lifeInsurance/Validation';
import Verify from 'components/contract/lifeInsurance/Verify';
import Documents from 'components/contract/lifeInsurance/Documents';
import ValidationDocuments from 'components/contract/lifeInsurance/Validation/Documents';
import InternalDocuments from 'components/contract/lifeInsurance/InternalDocuments';
import PrimonialHubWarning from 'components/contract/lifeInsurance/PrimonialHubWarning';

const LifeInsurance = () => {
    const session = useSession();

    return (
        <Routes>
            <Route path="/verifications/:contractType" element={<Verify />} />
            <Route path="/conditions/:contractType" element={<Documents />} />
            <Route path="/overview/:contractType" element={<Overview />} />
            <Route path="/informations/:contractType" element={<ClientInformation />} />
            <Route path="/documents/:contractType" element={<DocumentsUpload />} />
            <Route path="/internalDocs/:contractType" element={<InternalDocuments />} />
            <Route path="/signatures/:contractType" element={<Signatures />} />
            <Route path="/primonialHubWarning/:contractType" element={<PrimonialHubWarning />} />
            {session.permissions.mayValidateContracts() ? [
                <Route key="0" path="/ca-validation" element={<Validation />} />,
                <Route key="1" path="/ca-documents" element={<ValidationDocuments />} />,
            ] : null}
        </Routes>
    );
};

export default LifeInsurance;
