import React from 'react';
import Icon from 'common/components/fa/Icon';

const isInvalidBrowser = navigator.userAgent.match('CriOS') || navigator.userAgent.match('FxiOS');

const BrowserWarning = isInvalidBrowser ? () => (
    <div className="container">
        <div className="alert alert-danger version-warning">
            <Icon icon="warning" className="fa-fw" /> Seul Safari sur iOS dipose du mode hors ligne
        </div>
    </div>
) : () => null;

export default BrowserWarning;
