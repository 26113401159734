import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure } from 'recompose';
import withSpinner from '../withSpinner';
import withTutorial from '../withTutorial';
import { Body, Head, Header, Table, listProvider, withActiveItem } from '../list';
import fullName from '../../common/utils/fullName';
import ErrorMessage from '../ErrorMessage';
import ListItem from './ListItem';

import './SimulationTable.scss';

const NoSimuMessage = () => (
    <ErrorMessage>
        <div>
            Pour créer votre première simulation associée à ce e-RIP, cliquez sur le (+) en bas à droite de cette page.
        </div>
    </ErrorMessage>
);

const SimulationTable = ({ rip, items, session }) => (
    <Table>
        <Head>
            <th />
            <Header sortProperty="name" text={`Simulations de ${fullName(rip)}`} />
            <Header sortProperty="product" text="Type" />
            <Header sortProperty="createdAt" text="Date de création" />
            <Header sortProperty="updatedAt" text="Dernière modification" />
            {session.needApprovalOnSimulations ? (
                <Header sortProperty="approval" text="Validation" />
            ) : null}
            <th />
        </Head>
        <Body>
            {items.map((item) => (
                <ListItem
                    key={item.id}
                    rip={rip}
                    item={item}
                />
            ))}
        </Body>
    </Table>
);

SimulationTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rip: PropTypes.shape({}).isRequired,
    session: PropTypes.shape({
        needApprovalOnSimulations: PropTypes.bool,
    }).isRequired,
};

export default compose(
    listProvider({
        sorts: {
            createdAt: { initialOrder: 'desc', default: true },
            name: { initialOrder: 'asc' },
        },
        name: 'simulation',
        type: 'simulation',
        filter: ({ session, rip }) => (item) => session.permissions.mayReadSimulation(item, rip),
        criteria: ({ rip: { id } }) => ({ ripId: id }),
    }),
    withSpinner,
    withTutorial(NoSimuMessage),
    withActiveItem,
    pure,
)(SimulationTable);
