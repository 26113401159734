import { flowRight, get } from 'lodash/fp';
import { withPropsOnChange } from 'recompose';
import { formValues } from 'redux-form';

const withTargetRecommendations = flowRight([
    formValues('target', 'choicePreco', 'reductionAmount', 'fundingAmount', 'costAmount', 'investmentPercentage', 'incomes', 'fiscalPart'),
    withPropsOnChange(['rip', 'target', 'choicePreco', 'reductionAmount', 'fundingAmount', 'costAmount', 'investmentPercentage', 'incomes', 'fiscalPart'], ({ rip, target, choicePreco, reductionAmount, fundingAmount, costAmount, investmentPercentage, incomes, fiscalPart }) => {
        const name = 'cif' !== choicePreco ? 'partner.recommendations' : 'partner.recommendationsCIF';

        return {
            recommendations: get('partner' === target ? 'partner.recommendationsCIF' : 'recommendationsCIF', rip),
            name,
            choicePreco,
            enveloppe: { reductionAmount, fundingAmount, costAmount, investmentPercentage },
            incomes,
            fiscalPart,
        };
    }),
]);

export default withTargetRecommendations;
