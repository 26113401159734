import React from 'react';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';

import getTypes, { groupTypes } from 'common/components/rip/finances/investmentTypes';
import Group from 'components/form/Group';
import TypedBlockCollectionItem from 'components/form/TypedBlockCollectionItem';
import TypedCollection from 'components/form/TypedCollection';
import InvestmentsCommon from './InvestmentsCommon';
import ProfessionalInvestments from './ProfessionalInvestments';
import Error from '../../Error';

// get types
const types = getTypes(InvestmentsCommon, ProfessionalInvestments);
// and group them
const groups = groupTypes(types);

const labels = {
    Disponibilité: 'Avez-vous des disponibilités ?',
    'Moyen/long terme': 'Avez-vous des placements moyen/long terme ?',
    Rentabilité: 'Avez vous des placements exposés exclusivement sur les marchés financiers ? ',
    Professionnel: 'Avez-vous des investissements professionnels ?',
    Retraite: 'Avez-vous des placements retraite ?',
    Autres: 'Avez-vous d\'autres types de placements ?',
};

const InvestmentsList = ({ isErrors }) => Object.entries(groups).map(([group, innerTypes]) => (
    <Group title={labels[group]} key={group}>
        <TypedCollection
            name="investments"
            types={innerTypes}
            textOnly
            itemComponent={TypedBlockCollectionItem}
        />
        {'Avez-vous des disponibilités ?' === labels[group] &&
            <Error forceerror={isErrors} errortext="Vous devez renseigner au moins une disponibilité" />}

    </Group>
));
const invest = flowRight([
    connect((state) => ({ isErrors: state.form['rip-finances'].syncError })),
])(InvestmentsList);
export default invest;
