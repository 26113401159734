import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { sum } from 'lodash/fp';
import Dropdown from '../../../Dropdown';
import Title from '../../../Title';
import { AmountItem, OwnerItem } from '../../../../common/components/summary';
import getTypes from '../../../../common/components/rip/finances/investmentTypes';

const types = getTypes();

const Investments = ({ rip }) => {
    if (!rip.investments || !rip.investments.length) {
        return null;
    }

    const investissementAvecCapital = rip.investments.filter((x) => x.capital);
    const investissementAvecValeur = rip.investments.filter((x) => x.value);
    const versementsMensuels = rip.investments.filter((x) => x.monthlyPayment);

    const investissementsTotal =
        sum(investissementAvecCapital.map((investissement) => investissement.capital)) +
        sum(investissementAvecValeur.map((investissement) => investissement.value));

    const totalDesVersementsMensuels = sum(versementsMensuels.map((x) => x.monthlyPayment));

    return (
        <Fragment>
            <Title>Votre <b>epargne</b></Title>
            {rip.investments.map((investment) => (
                <Dropdown key={types[investment.type].title} title={types[investment.type].title}>
                    <OwnerItem title="Détenteur" rip={rip} value={investment.subscriber} />
                    <AmountItem title="Montant capitalisé" value={investment.capital || investment.value} />
                    {investment.monthlyPayment ? (
                        <AmountItem title="Versements mensuels" value={investment.monthlyPayment} />
                    ) : null}
                </Dropdown>
            ))}
            <dl>
                <AmountItem title="Montant total capitalisé" value={investissementsTotal} important />
                <AmountItem title="Versement mensuel total" value={totalDesVersementsMensuels} important />
            </dl>
        </Fragment>
    );
};

Investments.propTypes = {
    rip: PropTypes.shape({
        investments: PropTypes.arrayOf(PropTypes.shape({
            length: PropTypes.number,
            filter: PropTypes.func,
            map: PropTypes.func,
        })),
    }).isRequired,
};

export default Investments;
