import React from 'react';
import Icon from 'common/components/Icon';
import { ActiveItemContext } from './withActiveItem';

const HideActiveItemButton = () => (
    <ActiveItemContext.Consumer>
        {({ activeItem, setActiveItem }) => activeItem && (
            <button
                className="list-create-link team-list-close-btn"
                onClick={() => setActiveItem(null)}
            >
                <Icon icon="times" />
            </button>
        )}
    </ActiveItemContext.Consumer>
);

export default HideActiveItemButton;
