// Résultat BIC du locatif meublé

import { filter, flow, has, map, sum } from 'lodash/fp';
import { computeFurnishedIncome } from './incomes';

const computeFurnishedRentalResult = {
    furnishedRentalIncome: computeFurnishedIncome(0.5),
};

const computeFurnishedRentalResults = ({ incomes }) => flow(
    filter(({ type }) => has(type, computeFurnishedRentalResult)),
    map(({ type, ...details }) => computeFurnishedRentalResult[type](details)),
    sum,
    (furnishedRentalResults) => ({ furnishedRentalResults }),
)(incomes);

export default computeFurnishedRentalResults;
