import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formatAmount } from '../../../utils/format/index';
import reduceToTotals from '../../../model/simulation/common/reduceToTotals';

const Boldify = ({ content }) => <b>{content}</b>;

Boldify.propTypes = {
    content: PropTypes.node,
};

const TableLine = ({
    className,
    year,
    capital,
    data: {
        payments,
        fees,
        withdrawals,
        netWithdrawals,
        interests,
        socialCharges,
        taxes,
    },
    Formatter = ({ content }) => content,
}) =>
    (
        <tr className={className}>
            <td><Formatter content={year} /></td>
            <td><Formatter content={formatAmount(payments)} /></td>
            <td><Formatter content={formatAmount(fees)} /></td>
            <td><Formatter content={formatAmount(payments - fees)} /></td>
            <td><Formatter content={formatAmount(withdrawals)} /></td>
            <td><Formatter content={formatAmount(netWithdrawals)} /></td>
            <td><Formatter content={formatAmount(interests)} /></td>
            <td><Formatter content={formatAmount(taxes)} /></td>
            <td><Formatter content={formatAmount(socialCharges)} /></td>
            <td><Formatter content={formatAmount(capital)} /></td>
        </tr>
    );

TableLine.propTypes = {
    className: PropTypes.string,
    year: PropTypes.string.isRequired,
    capital: PropTypes.number.isRequired,
    data: PropTypes.shape({
        payments: PropTypes.number,
        fees: PropTypes.number,
        withdrawals: PropTypes.number,
        netWithdrawals: PropTypes.number,
        interests: PropTypes.number,
        socialCharges: PropTypes.number,
        taxes: PropTypes.number,
    }),
    Formatter: PropTypes.func,
};

// which totals we're looking for
const src = {
    socialCharges: 0,
    fees: 0,
    payments: 0,
    withdrawals: 0,
    netWithdrawals: 0,
    interests: 0,
    taxes: 0,
};

const computeSums = reduceToTotals(src);

const LifeInsuranceResultTable = ({ simulation, className, taxMode }) => {
    const overview = computeSums(simulation);
    const lastSnapshot = simulation[simulation.length - 1];

    return (
        <Fragment>
            <table className={classNames('table', className)}>
                <thead>
                    <tr>
                        <th rowSpan={2}>Année</th>
                        <th colSpan={3}>Versements</th>
                        <th colSpan={2}>Rachats</th>
                        <th rowSpan={2}>Plus-values</th>
                        <th rowSpan={2}>Impôts ({taxMode})</th>
                        <th rowSpan={2}>PS</th>
                        <th rowSpan={2}>Épargne acquise (fin de période)</th>
                    </tr>
                    <tr>
                        <th>Brut</th>
                        <th>Frais</th>
                        <th>Net *</th>
                        <th>Brut</th>
                        <th>Net **</th>
                    </tr>
                </thead>
                <tbody>
                    {simulation.map(({
                        year,
                        capital,
                        ...data
                    }) => (
                        <TableLine
                            key={year.toString()}
                            year={year.toString()}
                            capital={capital}
                            data={data}
                        />
                    ))}
                    <TableLine
                        year="Total"
                        capital={lastSnapshot.capital}
                        data={overview}
                        Formatter={Boldify}
                        className="table-summary"
                    />
                </tbody>
            </table>
            <p><i>* Net de frais d&apos;entr&eacute;e</i>&nbsp;<i>** Net de fiscalité</i></p>
        </Fragment>
    );
};

LifeInsuranceResultTable.propTypes = {
    className: PropTypes.string,
    simulation: PropTypes.arrayOf(PropTypes.shape({
        year: PropTypes.number.isRequired,
        interests: PropTypes.number.isRequired,
        capital: PropTypes.number,
    })).isRequired,
    taxMode: PropTypes.string.isRequired,
};

LifeInsuranceResultTable.defaultProps = {
    className: null,
};

export default LifeInsuranceResultTable;
