import PropTypes from 'prop-types';
import React from 'react';
import { formatPercentage } from 'common/utils/format';
import { GridLayout } from 'components/form/layout';
import { IntegerField, DisabledField, SelectField } from 'components/form/field';

const SCPI = ({
    name,
    scpi: {
        beforeDividendsDuration,
        fees,
        minimumParts,
        simulationRate,
    },
}) => {
    const getFieldName = (fieldName) => `${name}.${fieldName}`;

    return (
        <GridLayout className="triple condensed">
            <IntegerField
                name={getFieldName('parts')}
                title="Nombre de parts"
                required
                min={minimumParts}
            />
            <DisabledField
                name={getFieldName('rentalYield')}
                title="Rendement locatif"
                value={formatPercentage(simulationRate)}
            />
            <DisabledField
                name={getFieldName('fees')}
                title="Commission de souscription"
                value={formatPercentage(fees)}
            />
            <DisabledField
                name={getFieldName('beforeDividendsDuration')}
                title="Délai d'entrée en jouissance des parts"
                value={`${beforeDividendsDuration}  mois`}
            />
            <DisabledField
                name={getFieldName('simulationRate')}
                title="Taux d'indexation des loyers"
                value={formatPercentage(0)}
            />
            <SelectField
                name={getFieldName('revaluation')}
                title="Revalorisation annuelle de la valeur de la part"
            >
                <option value="0.005">0.5%</option>
                <option value="0">0%</option>
            </SelectField>
        </GridLayout>
    );
};

SCPI.propTypes = {
    name: PropTypes.string.isRequired,
    scpi: PropTypes.shape({
        beforeDividendsDuration: PropTypes.number,
        fees: PropTypes.number,
        minimumParts: PropTypes.number,
        simulationRate: PropTypes.number,
    }).isRequired,
};

export default SCPI;
