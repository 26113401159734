import PropTypes from 'prop-types';
import React from 'react';
import { IntegerField, TextField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';

const OtherDeduction = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <TextField name={`${name}.description`} title="Précisez" required />
        <IntegerField name={`${name}.amount`} title="Montant annuel" required />
        <Recurrent name={name} />
    </HorizontalLayout>
);

OtherDeduction.propTypes = {
    name: PropTypes.string.isRequired,
};

export default OtherDeduction;
