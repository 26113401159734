import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'components/layouts';
import { VerticalLayout } from '../form/layout';
import { TextField } from '../form/field';
import FormButtons from '../FormButtons';
import dataCreationForm from '../dataCreationForm';
import mapPrefixToProps from './mapPrefixToProps';
import withRouter from '../withRouter';

const CreationInner = ({ handleSubmit, prefix }) => (
    <Layout className="signature-form creation-form" hasFooter>
        <form onSubmit={handleSubmit} id="main-form">
            <div>
                <div className="signature-form-inner">
                    <div className="container">
                        <VerticalLayout>
                            <TextField name="name" title="Nom de la signature" />
                        </VerticalLayout>
                    </div>
                </div>
                <FormButtons
                    back={`${prefix}/signatures/`}
                    list={`${prefix}/signatures/`}
                />
            </div>
        </form>
    </Layout>
);

CreationInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    prefix: PropTypes.string.isRequired,
};

const Creation = flowRight([
    withRouter,
    mapPrefixToProps,
    dataCreationForm({
        form: 'signature-creation',
        onSubmitSuccess: ({ id }, dispatch, { navigate, prefix }) => navigate(`${prefix}/signatures/${id}/documents`),
        type: 'signature',
        onSubmitData: (values, { params: { ripId } }) => ({
            ...values,
            ...(ripId ? { ripId } : null),
        }),
    }),
])(CreationInner);

export default Creation;
