import { flow, groupBy, get, mapValues, fromPairs } from 'lodash/fp';
import { withProps } from 'recompose';

const getTypes = (innerComponent, professionalComponent) => ({
    // Disponibilité
    currentAccount: {
        component: withProps({ type: 'currentAccount', showSci: true })(innerComponent),
        title: 'Compte Courant',
        group: 'Disponibilité',
    },
    LDD: {
        component: withProps({ type: 'LDD' })(innerComponent),
        title: 'LDDS',
        group: 'Disponibilité',
    },
    savingsAccount: {
        component: withProps({
            type: 'savingsAccount',
        })(innerComponent),
        title: 'Livret A',
        group: 'Disponibilité',
    },
    PEL: {
        component: innerComponent,
        title: 'PEL',
        group: 'Disponibilité',
    },
    CEL: {
        component: innerComponent,
        title: 'CEL',
        group: 'Disponibilité',
    },
    CSL: {
        component: innerComponent,
        title: 'Compte sur livret',
        group: 'Disponibilité',
    },
    // Moyen/long terme
    SCPI: {
        component: withProps({ showSci: true, type: 'SCPI' })(innerComponent),
        title: 'SCPI',
        group: 'Moyen/long terme',
    },
    funding: {
        component: withProps({ type: 'funding', isRisky: true, showSci: true })(innerComponent),
        title: 'Contrat de capitalisation',
        group: 'Moyen/long terme',
    },
    lifeInsurance: {
        component: withProps({ showBeneficiary: true, type: 'lifeInsurance', isRisky: true })(innerComponent),
        title: 'Assurance vie',
        group: 'Moyen/long terme',
    },
    PEP: {
        component: innerComponent,
        title: 'PEP',
        group: 'Moyen/long terme',
    },
    // Rentabilité
    PEA: {
        component: withProps({ type: 'PEA', isRisky: true })(innerComponent),
        title: 'PEA',
        group: 'Rentabilité',
    },
    PEAPME: {
        component: withProps({ type: 'PEAPME', isRisky: true })(innerComponent),
        title: 'PEA-PME',
        group: 'Rentabilité',
    },
    securitiesAccount: {
        component: withProps({ showSci: true })(innerComponent),
        title: 'Compte-titres',
        group: 'Rentabilité',
    },
    // Professionnel
    socialShares: {
        component: professionalComponent,
        title: 'Parts sociales',
        group: 'Professionnel',
    },
    shares: {
        component: professionalComponent,
        title: 'Actions',
        group: 'Professionnel',
    },
    // Retraite
    PER: {
        component: withProps({ showBeneficiary: true })(innerComponent),
        title: 'PER',
        group: 'Retraite',
    },
    PERPMadelin: {
        component: withProps({ showBeneficiary: true })(innerComponent),
        title: 'PERP/Madelin',
        group: 'Retraite',
    },
    PERCO: {
        component: innerComponent,
        title: 'PERCO',
        group: 'Retraite',
    },
    PEE: {
        component: withProps({ type: 'PEE', isRisky: true })(innerComponent),
        title: 'PEE',
        group: 'Retraite',
    },
    // Autres
    other: {
        component: withProps({ showBeneficiary: true, type: 'other', isRisky: true, showSci: true })(innerComponent),
        title: 'Autre',
        group: 'Autres',
    },
});

// and group them
export const groupTypes = flow([Object.entries, groupBy(get('1.group')), mapValues(fromPairs)]);

export default getTypes;
