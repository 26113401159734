import React from 'react';
import Icon from '../../../../common/components/Icon';

import './CloseLink.css';

const CloseLink = ({ ...props }) => (
    <div className="simulators-close-link" {...props}>
        <Icon icon="window-restore" />
    </div>
);

export default CloseLink;
