import PropTypes from 'prop-types';
import React from 'react';
import Input from './Input';

const Salary = ({ salaryMonthCount, ...props }) => {
    const parse = (value) => {
        const parsed = parseInt(value, 10);

        return Number.isNaN(parsed) ? null : parsed * salaryMonthCount;
    };

    const format = (value) => {
        if (!value) {
            return '';
        }

        return Math.floor(value / salaryMonthCount);
    };

    return <Input parse={parse} format={format} type="number" {...props} />;
};

Salary.propTypes = {
    salaryMonthCount: PropTypes.number,
};

Salary.defaultProps = {
    salaryMonthCount: 12,
};

export default Salary;
