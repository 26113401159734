// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulators-close-link {
    position: absolute;
    bottom: 60px;
    right: 30px;
    display: block;
    background-color: rgb(40, 228, 234);
    width: 44px;
    height: 44px;
    border-radius: 25px;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

.simulators-close-link i {
    color: white;
    font-size: 26px;
    padding-left: 7px;
    padding-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/overlays/Simulators/CloseLink.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,cAAc;IACd,mCAAmC;IACnC,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,+CAA+C;IAC/C,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".simulators-close-link {\n    position: absolute;\n    bottom: 60px;\n    right: 30px;\n    display: block;\n    background-color: rgb(40, 228, 234);\n    width: 44px;\n    height: 44px;\n    border-radius: 25px;\n    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.2);\n    z-index: 1001;\n}\n\n.simulators-close-link i {\n    color: white;\n    font-size: 26px;\n    padding-left: 7px;\n    padding-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
