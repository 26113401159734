import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { lifeInsuranceEventTypes, periodicities } from 'common/choices';
import { formatAmount, formatDate, formatPercentage } from 'common/utils/format';
import Group from 'components/form/Group';
import Dropdown from 'components/Dropdown';

const Events = ({
    simulation: {
        data: { events },
    },
}) => (events && events.length ? (
    <Group>
        <Dropdown title="Événements à prévoir">
            <table className="table lifeInsurance-hypothesis-table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th>Périodicité</th>
                        <th>Montant</th>
                        <th>Frais d&apos;entrée (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map(({
                        type,
                        startDate,
                        endDate,
                        date,
                        periodicity,
                        amount,
                        fees,
                    }) => (
                        <tr key={events.id}>
                            <td>{lifeInsuranceEventTypes[type]}</td>
                            {type.startsWith('recurrent') ? (
                                <Fragment>
                                    <td>{formatDate(startDate)}</td>
                                    <td>{endDate ? formatDate(endDate) : '-'}</td>
                                    <td>{periodicities[periodicity]}</td>
                                </Fragment>
                            ) : (
                                <td colSpan={3}>{formatDate(date)}</td>
                            )}
                            <td>{formatAmount(amount)}</td>
                            <td>{fees ? formatPercentage(fees) : null}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Dropdown>
    </Group>
) : null);

Events.propTypes = {
    simulation: PropTypes.shape({
        data: PropTypes.shape({
            events: PropTypes.shape({
                length: PropTypes.number,
                map: PropTypes.func,
                id: PropTypes.string,
            }),
        }),
    }).isRequired,
};

export default Events;
