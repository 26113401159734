// Patrimoine du foyer

import { flow, map, sum } from 'lodash/fp';

const sumAssets = (assets, assetKey) => (
    assets && assets.length
        ? flow([
            map((data) => (data[assetKey] ? parseInt(data[assetKey], 10) : 0)),
            sum,
        ])(assets)
        : 0
);

const computeAssets = ({ mainResidenceEstimatedValue, investments, property }) => {
    const propertiesValues = sumAssets(property, 'estimatedValue');

    const investmentsValues = sumAssets(investments, 'capital');

    return { assets: propertiesValues + investmentsValues + (mainResidenceEstimatedValue ? parseInt(mainResidenceEstimatedValue, 10) : 0) };
};

export default computeAssets;
