import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'common/components/Icon';

import './Title.scss';

const Title = ({ children, className }) => (
    <h5 className={classNames('title', className)}>
        <Icon icon="arrow-right" /> {children}
    </h5>
);

Title.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Title.defaultProps = {
    className: null,
};

export default Title;
