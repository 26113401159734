import { withProps } from 'recompose';
import { WizardLayout } from 'components/layouts';
import wizard from './wizardData';

const RipLayout = withProps({
    wizard,
    hasFooter: true,
    noScroll: true,
})(WizardLayout);

export default RipLayout;
