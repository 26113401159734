const wizard = {
    prefixTemplate: ({ id, simulationId }) => `/rip/${id}/simulations/${simulationId}`,
    items: [
        {
            name: 'Client',
            target: 'profile',
        },
        {
            name: 'Produit',
            target: 'product',
        },
        {
            name: 'Simulation',
            target: 'simulation',
        },
    ],
};

export default wizard;
