import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';

import { shouldPartnerSign } from '../../../../common/src/utils/hasPartner';

import './SignaturesState.scss';

const SignaturesState = ({ rip }) => {
    const { clientAndPartnerSignatureDate } = rip;

    const [numberOfSignatureCompleted, setNumberOfSignatureCompleted] = useState(0);
    const [numberOfSignature, setNumberOfSignature] = useState(1);

    const allSignatureCompleted = numberOfSignature === numberOfSignatureCompleted;

    //  ** fonction pour afficher les différents status
    const renderSignatureState = (signature, contact) => {
        if (signature) {
            if (signature[contact]) {
                if ('refusal elect' === signature[contact].type) {
                    return `Refus de signature électronique du contact ${'client' === contact ? 'principal' : 'secondaire'}`;
                }
                if ('universign' === signature[contact].type) {
                    return `En attente de signature électronique du contact ${'client' === contact ? 'principal' : 'secondaire'}`;
                }
                if (['électroniquement', 'manuellement'].includes(signature[contact].type)) {
                    const { date } = signature[contact];

                    return `Signé ${signature[contact].type} le ${date ? moment(date).format('DD/MM/YYYY') : ''}`;
                }
            }
        }

        return 'Non signé';
    };

    const isPartner = shouldPartnerSign(rip);

    //  ** useEffect pour setter le nbre de signature
    useEffect(() => {
        if (isPartner) {
            setNumberOfSignature(2);
        }
        if ((clientAndPartnerSignatureDate && clientAndPartnerSignatureDate.client && (['électroniquement', 'manuellement'].includes(clientAndPartnerSignatureDate.client.type)))) {
            setNumberOfSignatureCompleted((prevCount) => prevCount + 1);
        }
        if (isPartner && (clientAndPartnerSignatureDate && clientAndPartnerSignatureDate.partner && (['électroniquement', 'manuellement'].includes(clientAndPartnerSignatureDate.partner.type)))) {
            setNumberOfSignatureCompleted((prevCount) => prevCount + 1);
        }
    }, []);

    //  ** pour faire un seul rendu si c'est le même message
    let sameState = false;
    if (renderSignatureState(clientAndPartnerSignatureDate, 'client') === renderSignatureState(clientAndPartnerSignatureDate, 'partner') || 'Refus de signature électronique du contact principal' === renderSignatureState(clientAndPartnerSignatureDate, 'client')) {
        sameState = true;
    }

    return (
        <OverlayTrigger
            trigger={['hover', 'click']}
            overlay={
                <Popover className="form-field-info-popover" placement="right">
                    {sameState ? (
                        <div>
                            {renderSignatureState(clientAndPartnerSignatureDate, 'client')}
                        </div>
                    ) : (
                        <div>
                            {renderSignatureState(clientAndPartnerSignatureDate, 'client')}
                            <br />
                            {isPartner && renderSignatureState(clientAndPartnerSignatureDate, 'partner')}
                        </div>)}
                </Popover>}
            rootClose
        >
            <td className={allSignatureCompleted ? 'signatureStateOver' : 'signatureStateOnGoing'}>
                {numberOfSignatureCompleted} / {numberOfSignature}
            </td>
        </OverlayTrigger>
    );
};

SignaturesState.propTypes = {
    rip: PropTypes.shape(PropTypes.shape({
        signature: PropTypes.string.isRequired,
        partnerSignature: PropTypes.string.isRequired,
        clientAndPartnerSignatureDate: PropTypes.shape(PropTypes.shape({
            client: PropTypes.shape({
                date: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            }).isRequired,
            partner: PropTypes.shape({
                date: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired),
    }).isRequired).isRequired,
};

export default SignaturesState;
