// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check.simple-checkbox label {
    display: flex;
}

.form-check.simple-checkbox label input {
    margin-right: 10px;
    margin-top: 6px;
}

.form-check.simple-checkbox label p {
    text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Checkbox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".form-check.simple-checkbox label {\n    display: flex;\n}\n\n.form-check.simple-checkbox label input {\n    margin-right: 10px;\n    margin-top: 6px;\n}\n\n.form-check.simple-checkbox label p {\n    text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
