import { isEqual } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PDF from 'react-pdf-js';
import { connect } from 'react-redux';
import { unsetPdfViewer, setPdfViewerFrame, setPdfViewerPages, setPdfViewerPage } from 'actions';
import getPosition from 'utils/getPosition';
import MenuButton from '../../menu/Button';

import './PdfViewer.scss';

const isFullScreen = () => (document.webkitIsFullScreen || document.mozFullScreen
        || document.msFullscreenEnabled || document.fullScreen);

class PdfViewer extends Component {
    constructor(props) {
        super(props);
        this.pdfContainer = React.createRef();
        this.pdfjsCanvasRef = React.createRef();
        this.intervalId = null;

        this.gotoPrev = this.gotoPrev.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
    }

    componentDidMount() {
        this.intervalId = window.setInterval(() => this.updatePdfViewer(), 1000);
    }

    componentDidUpdate() {
        this.updatePdfViewer();
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalId);
        this.props.unsetViewer();
    }

    updatePdfViewer() {
        const { setFrame, viewer: { frame: oldFrame } } = this.props;
        const { x, y } = getPosition(this.pdfjsCanvasRef.current);
        const proportion = this.pdfjsCanvasRef ? (this.pdfjsCanvasRef.current.scrollWidth * 0.03514598035) / 21 : null;

        const newFrame = {
            proportion, // proportion pt <-> px
            width: this.pdfjsCanvasRef.current.scrollWidth,
            height: this.pdfjsCanvasRef.current.scrollHeight,
            x,
            y,
        };

        if (!isEqual(oldFrame, newFrame)) {
            setFrame(newFrame);
        }
    }

    gotoPrev() {
        const { isOnFirstPage, currentPage, setPage } = this.props;

        if (!isOnFirstPage) {
            setPage(currentPage - 1);
        }
    }

    gotoNext() {
        const { isOnLastPage, currentPage, setPage } = this.props;

        if (!isOnLastPage) {
            setPage(currentPage + 1);
        }
    }

    toggleFullScreen() {
        if (isFullScreen()) {
            if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            }
            if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        } else {
            const rFS = (this.pdfContainer.current.mozRequestFullScreen
                || this.pdfContainer.current.webkitRequestFullscreen
                || this.pdfContainer.current.requestFullscreen);
            rFS.call(this.pdfContainer.current);
        }
    }

    renderPagination() {
        const { currentPage, lastPage, isOnFirstPage, isOnLastPage } = this.props;

        if (!lastPage) {
            return null;
        }

        return (
            <ul className="pager">
                <li className={isOnFirstPage ? 'previous disabled' : 'previous'}>
                    <MenuButton onClick={this.gotoPrev}>
                        <span><i className="fa fa-arrow-left" /></span>
                    </MenuButton>
                </li>
                <li>&nbsp;{currentPage}/{lastPage}&nbsp;</li>
                <li className={isOnLastPage ? 'next disabled' : 'next'}>
                    <MenuButton onClick={this.gotoNext}>
                        <span><i className="fa fa-arrow-right" /></span>
                    </MenuButton>
                </li>
                <li className="pdf-viewer-expand-btn">
                    <MenuButton onClick={this.toggleFullScreen}>
                        <span><i className="fa fa-arrows-alt" /></span>
                    </MenuButton>
                </li>
            </ul>
        );
    }

    renderPdf() {
        const { currentPage, pdfPath, setPages } = this.props;

        return (
            <PDF
                file={pdfPath}
                page={currentPage}
                onDocumentComplete={setPages}
                fillWidth
            />
        );
    }

    render() {
        const { children, listeners } = this.props;

        return (
            <div className="pdf-container" ref={this.pdfContainer}>
                <div className="pdf-document-container">
                    <div ref={this.pdfjsCanvasRef} className="pdfjs-canvas">
                        {children}
                        {listeners ? (
                            <div {...listeners}>
                                {this.renderPdf()}
                            </div>
                        ) : this.renderPdf()}
                    </div>
                </div>
                <div className="pdf-pagination-container">
                    {this.renderPagination()}
                </div>
            </div>
        );
    }
}

PdfViewer.propTypes = {
    // user properties
    pdfPath: PropTypes.string.isRequired,
    children: PropTypes.node,
    listeners: PropTypes.shape({}),
    // properties from mapStateToProps
    viewer: PropTypes.shape({
        frame: PropTypes.shape({}),
    }).isRequired,
    currentPage: PropTypes.number,
    lastPage: PropTypes.number,
    isOnFirstPage: PropTypes.bool.isRequired,
    isOnLastPage: PropTypes.bool.isRequired,
    // properties from mapDispatchToProps
    unsetViewer: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setPages: PropTypes.func.isRequired,
    setFrame: PropTypes.func.isRequired,
};

const mapStateToProps = ({ pdfViewer: viewer }) => ({
    viewer,
    currentPage: viewer.page,
    lastPage: viewer.pages,
    isOnFirstPage: 1 === viewer.page,
    isOnLastPage: viewer.page === viewer.pages,
});

const mapDispatchToProps = {
    unsetViewer: unsetPdfViewer,
    setPage: setPdfViewerPage,
    setPages: setPdfViewerPages,
    setFrame: setPdfViewerFrame,
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
