import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Icon from 'common/components/Icon';
import { toggleUserMenu } from 'actions';
import MenuButton from 'components/menu/Button';
import withRouter from '../../withRouter';
import withOnline from '../../withOnline';
import { GET } from '../../../utils/httpMethods';
import UserMenu from './UserMenu';
import Logo from './Logo';

import './Header.scss';

class Header extends Component {
    constructor(props) {
        super(props);
        this.menuRef = createRef();
        this.unlistenHistory = null;
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        const { activerAmplitude } = await GET('/api/amplitude-activation').then((response) => response.json());

        if (activerAmplitude && 'true' === activerAmplitude) {
            let urlATracer = '/';

            if (window.location.pathname.includes('/rip/')) {
                const array = window.location.pathname.split('/');
                const arraySimplifiee = array.slice(3);
                urlATracer = `/${arraySimplifiee.join('/')}`;
            } else {
                urlATracer = window.location.pathname;
            }

            fetch('/api/trace', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ urlATracer }),
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    get menu() {
        return this.menuRef.current;
    }

    handleClickOutside(event) {
        if (this.props.showUserMenu) {
            const { menu } = this;

            if (menu && !menu.contains(event.target)) {
                this.props.toggleActiveUserMenu();
            }
        }
    }

    render() {
        const { showUserMenu, toggleActiveUserMenu, children, onLine, logo } = this.props;

        return (
            <header>
                <nav className="navbar navbar-default">
                    {logo}
                    <div className="body">
                        {children}
                    </div>
                    <div className="user-menu">
                        <MenuButton onClick={toggleActiveUserMenu}>
                            <Icon icon={showUserMenu ? 'user' : 'user-regular'} />
                            {!onLine && (
                                <span className="offline-badge" title="Mode déconnecté">
                                    <Icon icon="wifi" />
                                </span>
                            )}
                        </MenuButton>
                        {showUserMenu ? <UserMenu menuRef={this.menuRef} toggleActiveUserMenu={toggleActiveUserMenu} /> : null}
                    </div>
                </nav>
            </header>
        );
    }
}

Header.propTypes = {
    children: PropTypes.node,
    showUserMenu: PropTypes.bool.isRequired,
    toggleActiveUserMenu: PropTypes.func.isRequired,
    onLine: PropTypes.bool.isRequired,
    logo: PropTypes.element,
};

Header.defaultProps = {
    children: null,
    logo: <Logo />,
};

const mapStateToProps = (state) => ({
    showUserMenu: state.userMenuVisibility,
});

const mapDispatchToProps = {
    toggleActiveUserMenu: toggleUserMenu,
};

export default flowRight([
    withRouter,
    withOnline,
    connect(mapStateToProps, mapDispatchToProps),
])(Header);
