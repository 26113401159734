// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rip-see-more {
    outline: none;
    text-align: center;
    color: rgb(36, 146, 223);
    width: 44px;
    height: 44px;
    border: 1px solid rgb(36, 146, 223);
    border-radius: 22px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 0;
}

.rip-see-more input {
    display: none;
}

.rip-see-more > label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.rip-see-more > label > i {
    font-size: 20px;
}

.rip-see-more.primary {
    background: rgba(36, 143, 223, 1);
    background: linear-gradient(135deg, rgba(36, 143, 223, 1) 37%, rgba(37, 175, 227, 1) 59%, rgba(40, 234, 234, 1) 100%);
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/SeeMore.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;IACX,YAAY;IACZ,mCAAmC;IACnC,mBAAmB;IACnB,oCAAoC;IACpC,2CAA2C;IAC3C,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,qHAAqH;IACrH,YAAY;AAChB","sourcesContent":[".rip-see-more {\n    outline: none;\n    text-align: center;\n    color: rgb(36, 146, 223);\n    width: 44px;\n    height: 44px;\n    border: 1px solid rgb(36, 146, 223);\n    border-radius: 22px;\n    background-color: rgb(255, 255, 255);\n    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);\n    padding: 0;\n}\n\n.rip-see-more input {\n    display: none;\n}\n\n.rip-see-more > label {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    cursor: pointer;\n}\n\n.rip-see-more > label > i {\n    font-size: 20px;\n}\n\n.rip-see-more.primary {\n    background: rgba(36, 143, 223, 1);\n    background: linear-gradient(135deg, rgba(36, 143, 223, 1) 37%, rgba(37, 175, 227, 1) 59%, rgba(40, 234, 234, 1) 100%);\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
