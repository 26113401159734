import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import settingsValidators from 'common/formValidation/settings';
import Dropdown from 'components/Dropdown';
import groups from 'common/choices/settings/summary';
import FormFields from '../../FormFields';
import IncomeTax from './IncomeTax';
import Deductions from './Deductions';
import Reductions from './Reductions';
import Credits from './Credits';

const renderSettings = (type, subSettings) => {
    switch (type) {
        case 'incomeTax':
            return <IncomeTax settings={subSettings} name="summary.incomeTax" />;
        case 'deductions':
            return <Deductions name="summary.deductions" />;
        case 'reductions':
            return <Reductions name="summary.reductions" />;
        case 'credits':
            return <Credits name="summary.credits" />;
        default:
            return null;
    }
};

const FormInner = ({ initialValues: { summary: settings }, ...props }) => (
    <FormFields {...props} back="/admin/settings">
        <h1 className="text-center">
            Paramètres Récapitulatif
        </h1>
        {
            Object
                .entries(settings)
                .map(([type, subSettings]) => (
                    <Dropdown key={type} title={groups[type]}>
                        {renderSettings(type, subSettings)}
                    </Dropdown>
                ))
        }
    </FormFields>
);

FormInner.propTypes = {
    initialValues: PropTypes.shape({
        summary: PropTypes.shape({}),
    }).isRequired,
};

const Form = reduxForm({
    form: 'settings-summary-edit',
    enableReinitialize: true,
    validate: settingsValidators,
})(FormInner);

export default Form;
