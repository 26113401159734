import { flowRight } from 'lodash';
import { withProps } from 'recompose';
import withRouter from '../../withRouter';
import UniversignSuccess from './UniversignSuccess';

export default flowRight([
    withRouter,
    withProps({
        type: 'rip',
        getStatusUrl: ({ params: { docId, ripId } }) => `/api/sign-status/document/${docId}/${ripId}`,
    }),

])(UniversignSuccess);
