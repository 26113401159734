import React, { createContext, Component, useContext } from 'react';
import { setDisplayName, wrapDisplayName } from 'recompose';

const Context = createContext(false);

export const withOnlineProvider = (WrappedComponent) => {
    class OnlineProvider extends Component {
        constructor(props) {
            super(props);

            this.state = { onLine: navigator.onLine };

            this.onOnLine = this.onOnLine.bind(this);
            this.onOffLine = this.onOffLine.bind(this);
        }

        componentDidMount() {
            window.addEventListener('online', this.onOnLine);
            window.addEventListener('offline', this.onOffLine);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.onOnLine);
            window.removeEventListener('offline', this.onOffLine);
        }

        onOnLine() {
            this.setState({ onLine: true });
        }

        onOffLine() {
            this.setState({ onLine: false });
        }

        render() {
            const { onLine } = this.state;

            return (
                <Context.Provider value={onLine}>
                    <WrappedComponent {...this.props} />
                </Context.Provider>
            );
        }
    }

    return setDisplayName(wrapDisplayName(WrappedComponent, 'withOnlineProvider'))(OnlineProvider);
};

export const useOnline = () => useContext(Context);

const withOnline = (WrappedComponent) => {
    const OnlineConsumer = (props) => {
        const onLine = useOnline();

        return <WrappedComponent {...props} onLine={onLine} />;
    };

    return setDisplayName(wrapDisplayName(WrappedComponent, 'withOnline'))(OnlineConsumer);
};

export default withOnline;
