import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { branch, compose, renderNothing } from 'recompose';
import Collection from '../../form/Collection';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import GrandChild from './GrandChild';

const GrandChildren = ({ initialValues }) => (
    <FormGroup>
        <Title>Combien <b>avez-vous de petits enfants&nbsp;?</b></Title>
        <Collection
            name="grandChildren"
            component={GrandChild}
            addLabel="Ajouter un petit enfant"
            componentProps={{ initialValues }}
            deleteChildren
        />
    </FormGroup>
);

GrandChildren.propTypes = {
    initialValues: PropTypes.shape({
    }),
};

export default compose(
    formValues(({ children: 'children' })),
    branch(({ children }) => isEmpty(children), renderNothing),
)(GrandChildren);
