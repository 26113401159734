import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { pensionTypes } from 'common/choices';
import OwnerField from '../field/Owner';
import { SalaryField, IntegerField, ChoiceField, TextField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';

const PensionInner = ({ name, pensionPeriodicity, ripFromRecommendation }) => {
    let pensionField;

    if ('monthly' === pensionPeriodicity) {
        pensionField = <SalaryField name={`${name}.pension`} title="Pension mensuelle nette" required />;
    }
    if ('annual' === pensionPeriodicity) {
        pensionField = <IntegerField name={`${name}.pension`} title="Pension annuelle nette" required />;
    }

    return (
        <HorizontalLayout>
            <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" ripFromRecommendation={ripFromRecommendation} pensionComponent required />
            <ChoiceField name={`${name}.pensionPeriodicity`} choices={pensionTypes} title="Pension" />
            {pensionField}
            <TextField name={`${name}.pensionType`} title="Type" />
        </HorizontalLayout>
    );
};

PensionInner.propTypes = {
    name: PropTypes.string.isRequired,
    pensionPeriodicity: PropTypes.string,
    ripFromRecommendation: PropTypes.shape({}),
};

PensionInner.defaultProps = {
    pensionPeriodicity: 'monthly',
};

const Pension = formValues(({ name }) => ({
    pensionPeriodicity: `${name}.pensionPeriodicity`,
}))(PensionInner);

export default Pension;
