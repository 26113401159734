import React from 'react';
import PropTypes from 'prop-types';

import './Tree.scss';
import Node from './Node';

const Tree = ({ tree, onLeafClick }) => (
    <div className="tree-view">
        <Node node={tree} onLeafClick={onLeafClick} />
    </div>
);

Tree.propTypes = {
    tree: PropTypes.shape({}),
    onLeafClick: PropTypes.func.isRequired,
};

export default Tree;
