import PropTypes from 'prop-types';
import React from 'react';
import './logs.scss';

const Logs = ({
    historyLogs,
    displayLogs,
}) => (
    displayLogs ?
        historyLogs.map((item) => (
            <tr className="logs">
                <td colSpan={10}>
                    <div className="logs-container">
                        <div> {item.change}</div>
                    </div>
                    <div className="logs-container logs-par">
                        <div>{item.par}</div>
                    </div>
                </td>
            </tr>
        ))
        : <tr className="logs"><td colSpan={10}><div className="logs-container"> Pas d&apos;historique en cours</div></td></tr>
);

Logs.propTypes = {
    historyLogs: PropTypes.string.isRequired,
    displayLogs: PropTypes.bool.isRequired,

};

export default Logs;
