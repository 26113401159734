import { v4 as uuidv4 } from 'uuid';

const incomes = [
    {
        id: uuidv4(),
        type: 'salary',
        incomeType: 'monthly',
        realExpenses: false,
    },
];

export default incomes;
