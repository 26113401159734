import moment from 'moment';
import createSimpleValidator from './createSimpleValidator';

const errorString = 'Vous devez entrer une date valide.';
const checkIsDate = createSimpleValidator(
    (value) => {
        let anneeEnCours = new Date();
        anneeEnCours = moment(anneeEnCours).year();

        const anneeRenseignee = moment(value).year();

        const anneeMinimale = anneeEnCours - 120;

        const anneeMaximale = anneeEnCours + 120;

        return moment(value).isValid() && anneeMinimale < anneeRenseignee && anneeMaximale > anneeRenseignee;
    },
    errorString,
);

export default checkIsDate;
