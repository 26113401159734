import { pick, omit } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, getContext } from 'recompose';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import HorizontalRadioButton from '../HorizontalRadioButton';
import RadioGroup, { radioInfoPropTypes } from '../RadioGroup';
import withScroll from '../withScroll';

const parse = (value) => {
    switch (value) {
        case 'yes':
            return true;
        case 'no':
            return false;
        default:
            return undefined;
    }
};

const format = (value) => {
    switch (value) {
        case true:
            return 'yes';
        case false:
            return 'no';
        default:
            return '';
    }
};

const pickInfoProps = pick(Object.keys(radioInfoPropTypes));
const omitInfoProps = omit(Object.keys(radioInfoPropTypes));

const YesNo = ({ formGroup: Group, name, required, disabled, ...props }) => {
    const infoProps = pickInfoProps(props);
    const rest = omitInfoProps(props);

    return (
        <Group name={name} required={required} {...rest}>
            <RadioGroup
                name={name}
                required={required}
                parse={parse}
                format={format}
                {...infoProps}
            >
                <HorizontalRadioButton title="Oui" icon={faCircleCheck} value="yes" disabled={disabled} />
                <HorizontalRadioButton title="Non" icon={faCircleXmark} value="no" />
            </RadioGroup>
        </Group>
    );
};

YesNo.propTypes = {
    formGroup: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    ...radioInfoPropTypes,
};

YesNo.defaultProps = {
    required: false,
};

export default compose(
    withScroll,
    getContext({ formGroup: PropTypes.func.isRequired }),
)(YesNo);
