import PropTypes from 'prop-types';
import React from 'react';
import { stubTrue } from 'lodash/fp';
import { fieldArrayPropTypes } from 'redux-form';
import Error from '../Error';
import TypedCollectionButtons from './TypedCollectionButtons';
import TypedCollectionItem from './TypedCollectionItem';

const TypedCollectionInner = ({
    fields,
    meta,
    types,
    textOnly,
    itemComponent: Item,
    maxElementCount,
    itemProps,
    shouldDisplay,
    isDependentsExist,
    isCif = false,
    rip,
    target,
    isEnveloppeProgress = false,
    divorceDisabled,
    marriageDisabled,
    partnershipDisabled,
}) => ((
    <div>
        {fields.map((name, index) => shouldDisplay(name, index) && (
            <Item
                key={name}
                name={name}
                index={index}
                fields={fields}
                types={types}
                textOnly={textOnly}
                meta={meta}
                {...itemProps}
            />
        ))}
        <TypedCollectionButtons
            fields={fields}
            types={types}
            textOnly={textOnly}
            maxElementCount={maxElementCount}
            isDependentsExist={isDependentsExist}
            isCif={isCif}
            rip={rip}
            target={target}
            isEnveloppeProgress={isEnveloppeProgress && isCif}
            divorceDisabled={divorceDisabled}
            marriageDisabled={marriageDisabled}
            partnershipDisabled={partnershipDisabled}
        />
        <Error {...meta} />
    </div>
));

TypedCollectionInner.propTypes = {
    ...fieldArrayPropTypes,
    textOnly: PropTypes.bool,
    types: PropTypes.objectOf(PropTypes.shape()).isRequired,
    itemComponent: PropTypes.func,
    itemProps: PropTypes.shape({}),
    shouldDisplay: PropTypes.func,
};

TypedCollectionInner.defaultProps = {
    textOnly: false,
    itemComponent: TypedCollectionItem,
    itemProps: null,
    shouldDisplay: stubTrue,
};

export default TypedCollectionInner;
