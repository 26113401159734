// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sigCanvas {
    background-color: #ccc;
}

.signature {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}
.signature > .form-check.simple-checkbox label p {
    max-width: 477px;
}

.signature > p {
    max-width: 500px;
}

.trash-signature {
    float: right;
    border: 1px solid rgb(70, 70, 70);
    border-radius: 2px;
    opacity: 0.8;
    background-color: white;
    font-size: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/commitment/Signatures/Manual/ManualSignature.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".sigCanvas {\n    background-color: #ccc;\n}\n\n.signature {\n    margin-left: 20px;\n    margin-right: 20px;\n    margin-bottom: 20px;\n}\n.signature > .form-check.simple-checkbox label p {\n    max-width: 477px;\n}\n\n.signature > p {\n    max-width: 500px;\n}\n\n.trash-signature {\n    float: right;\n    border: 1px solid rgb(70, 70, 70);\n    border-radius: 2px;\n    opacity: 0.8;\n    background-color: white;\n    font-size: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
