import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Field, formValues } from 'redux-form';
import { downloadPdf } from 'actions';
import DropDown from 'components/form/FieldDropDown';
import { FileField } from 'components/form/field';
import Checkbox from 'components/form/Checkbox';
import './PhoneBill.scss';

const PhoneBillInner = ({ uploader, professionalPhone, download }) => (
    <DropDown title="Facture de téléphone mobile de moins de 3 mois" name="virtual.phoneBill">
        <Field
            title={'L\'adresse présente sur ma facture de téléphone mobile '
            + 'ne correspond pas à mon adresse actuelle'}
            component={Checkbox}
            name="data.phoneBillAddressDiffers"
        />
        <Field
            title={'Je n\'ai pas de facture à disposition, il s\'agit d\'un téléphone '
            + 'mobile professionnel'}
            component={Checkbox}
            name="data.professionalPhone"
        />
        <hr />
        <FileField
            name="phoneBill"
            uploader={uploader('phoneBill')}
            uploadText="Télécharger votre facture de téléphone mobile"
            reduxField="files.phoneBill"
        />
        {professionalPhone && (
            <div className="employer-certificate">
                <FileField
                    name="employerCertificate"
                    uploader={uploader('employerCertificate')}
                    uploadText="Télécharger l'attestation de l'employeur"
                    reduxField="files.employerCertificate"
                />
                <button
                    type="button"
                    onClick={() => download('/static/modele-attestation-employeur-facture-telephone.pdf', {
                        forcedFileName: 'attestation-employeur-facture-telephone.pdf',
                    })}
                >
                    Télécharger un modèle d&apos;attestation
                </button>
            </div>
        )}
    </DropDown>
);

PhoneBillInner.propTypes = {
    uploader: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    professionalPhone: PropTypes.bool,
};

const PhoneBill = compose(
    formValues({
        professionalPhone: 'data.professionalPhone',
    }),
    connect(null, { download: downloadPdf }),
)(PhoneBillInner);

export default PhoneBill;
