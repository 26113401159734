import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isInvalid } from 'redux-form';
import withFormName from 'components/form/withFormName';
import NextButton from './NextButton';

const ConnectedNextButton = compose(
    withFormName,
    connect((state, { form }) => ({ disabled: isInvalid(form)(state) })),
)(NextButton);

export default ConnectedNextButton;
