import React from 'react';
import PropTypes from 'prop-types';
import { map, replace } from 'lodash/fp';
import moment from 'moment';
import classNames from 'classnames';
import zipcelx from 'zipcelx';
import Icon from 'common/components/Icon';
import formatNullValue from '../../common/utils/format/nullValue';
import recupereChoixContact from '../../utils/recupereChoixOrigineContact';
import { useOnline } from '../withOnline';
import { saveAndExportRips } from '../../utils/saveAndExportRips';

import './ExportButton.scss';

const formatPhone = replace(/ /g, '');

const header = [
    {
        value: 'Civilité',
        type: 'string',
    },
    {
        value: 'Nom',
        type: 'string',
    },
    {
        value: 'Prénom',
        type: 'string',
    },
    {
        value: 'Adresse',
        type: 'string',
    },
    {
        value: 'Complément adresse',
        type: 'string',
    },
    {
        value: 'Code postal',
        type: 'string',
    },
    {
        value: 'Ville',
        type: 'string',
    },
    {
        value: 'Pays',
        type: 'string',
    },
    {
        value: 'Téléphone portable',
        type: 'string',
    },
    {
        value: 'Téléphone',
        type: 'string',
    },
    {
        value: 'Adresse email',
        type: 'string',
    },
    {
        value: 'Date de naissance',
        type: 'string',
    },
    {
        value: 'Date de création',
        type: 'string',
    },
    {
        value: 'Date de dernière modification',
        type: 'string',
    },
    {
        value: 'Date de dernière signature',
        type: 'string',
    },
    {
        value: 'Origine de contact',
        type: 'string',
    },
];

const getRow = ({
    civility,
    firstName,
    lastName,
    addressNumber,
    addressType,
    address,
    addressComplement,
    postalCode,
    city,
    country,
    phone,
    mobile,
    personalEmail,
    birthDate,
    createdAt,
    updatedAt,
    signatureDate,
    contactOrigin,
}) => [
    {
        value: civility,
        type: 'string',
    },
    {
        value: lastName,
        type: 'string',
    },
    {
        value: firstName,
        type: 'string',
    },
    {
        value: `${formatNullValue(addressNumber)} ${formatNullValue(addressType)} ${formatNullValue(address)}`,
        type: 'string',
    },
    {
        value: addressComplement,
        type: 'string',
    },
    {
        value: postalCode,
        type: 'number',
    },
    {
        value: city,
        type: 'string',
    },
    {
        value: country,
        type: 'string',
    },
    {
        value: formatPhone(mobile),
        type: 'string',
    },
    {
        value: formatPhone(phone),
        type: 'string',
    },
    {
        value: personalEmail,
        type: 'string',
    },
    {
        value: birthDate ? moment(birthDate).format('DD/MM/YYYY') : '',
        type: 'string',
    },
    {
        value: moment(createdAt).format('DD/MM/YYYY'),
        type: 'string',
    },
    {
        value: moment(updatedAt).format('DD/MM/YYYY'),
        type: 'string',
    },
    {
        value: signatureDate ? moment(signatureDate).format('DD/MM/YYYY') : '',
        type: 'string',
    },
    {
        value: recupereChoixContact[contactOrigin],
        type: 'string',
    },
];

const onClick = (items, userId, userStatus) => () => {
    const ripsAExporter = saveAndExportRips(userId, items, userStatus, 'user');
    const config = {
        filename: `export-clients-${moment().format('DDMMYYYY_HHmm')}`,
        sheet: {
            data: [header, ...map(getRow, ripsAExporter)],
        },
    };

    zipcelx(config);
};

const ExportCGPClientsButton = ({ items, userId, userStatus }) => {
    const onLine = useOnline();

    return (
        <button
            onClick={onClick(items, userId, userStatus)}
            title="Export clients"
            className={classNames('list-export-button second', {
                disabled: !onLine,
            })}
            disabled={!onLine}
        >
            <Icon icon="download" />
        </button>
    );
};

ExportCGPClientsButton.propTypes = {
    userId: PropTypes.string,
    userStatus: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        civility: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        addressNumber: PropTypes.string,
        addressType: PropTypes.string,
        address: PropTypes.string,
        addressComplement: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        personalEmail: PropTypes.string,
        birthDate: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        signatureDate: PropTypes.string,
        contactOrigin: PropTypes.string,
    })).isRequired,
};

export default ExportCGPClientsButton;
