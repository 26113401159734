import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import FormButtons from 'components/FormButtons';
import dataForm from 'components/dataForm';
import scrollToFirstError from 'components/form/scrollToFirstError';
import { withSession } from '../../../../sessions';
import Form from '../Form';
import Header from '../Header';
import simulationLoader from '../simulationLoader';
import Settings from './Settings';
import ScheduledPayments from './ScheduledPayments';
import PlannedEvents from './PlannedEvents';
import { errorValidate } from './formValidate';

const ProductInner = ({
    handleSubmit,
    params: { id, simulationId },
}) => (
    <Form handleSubmit={handleSubmit}>
        <div className="simulation-form-inner" id="simulationFormInner">
            <div className="container">
                <Header />
                <Settings />
                <ScheduledPayments />
                <PlannedEvents />
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/profile`}
            list={`/rip/${id}/simulations/`}
        />
    </Form>
);

ProductInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
};

const Product = compose(
    simulationLoader,
    withSession,
    dataForm({
        form: 'simulation-product',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => navigate(`/rip/${id}/simulations/${simulationId}/simulation`),
        onSubmitFail: scrollToFirstError({ containerId: 'simulationFormInner' }),
        type: 'simulation',
        validate: errorValidate,
    }),
)(ProductInner);

export default Product;
