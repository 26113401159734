/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import FormGroup from '../../../form/Group';
import RadioQuestion from '../../../InvestorProfile/RadioQuestion';
import { useSession } from '../../../sessions';

const renderChoixPreco = (estCif, estSimulations) => {
    const choix = {
        simulation: 'Simulation',
        cif: 'Document de conseil CIF',
    };

    if (estCif && !estSimulations) {
        const { cif } = choix;

        return { cif };
    }

    if (estSimulations && !estCif) {
        const { simulation } = choix;

        return { simulation };
    }

    if (estSimulations && estCif) {
        return choix;
    }

    return null;
};

export const PrecoChoices = ({ required }) => {
    const session = useSession();
    const choix = renderChoixPreco(session.needApprovalOnCIFDocument, session.needApprovalOnSimulations);

    return (
        <FormGroup className="choice-preco" name="data.outputModality">
            <div>
                <RadioQuestion
                    choices={choix}
                    name="choicePreco"
                    title="Choix de la préconisation*"
                    className="double"
                    required={required}
                />
            </div>
        </FormGroup>
    );
};

PrecoChoices.propTypes = {
    required: PropTypes.bool,
    session: PropTypes.shape({
        needApprovalOnSimulations: PropTypes.bool,
        needApprovalOnCIFDocument: PropTypes.bool,
    }).isRequired,
};

export default PrecoChoices;
