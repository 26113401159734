import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { formatFullDate } from 'common/utils/format';
import Payout from './Payout';
import { listProvider } from '../../list';
import withTutorial from '../../withTutorial';
import withSpinner from '../../withSpinner';
import { NoInvoiceMessage } from '../../invoices/Invoices';
import './Payouts.scss';

const PayoutsInner = ({ items }) => (
    <div className="relative-container">
        <div className="container payouts">
            {items.map(({ id, amount, arrival_date: arrivalDate }) => (
                <Payout
                    title={`Versement du ${formatFullDate(arrivalDate * 1000)} - ${amount / 100} EUR`}
                    dateVirement={arrivalDate}
                    key={id}
                    payoutId={id}
                />
            ))}
        </div>
    </div>
);

PayoutsInner.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default compose(
    listProvider({
        name: 'payout',
        type: 'payout',
    }),
    withSpinner,
    withTutorial(NoInvoiceMessage),
)(PayoutsInner);
