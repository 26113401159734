import PropTypes from 'prop-types';
import React from 'react';
import { withContext } from 'recompose';
import VerticalGroup from '../group/Vertical';

const VerticalInner = ({ children, noDiv, ...props }) => (noDiv ? children : (
    <div {...props}>
        {children}
    </div>
));

VerticalInner.propTypes = {
    children: PropTypes.node.isRequired,
    noDiv: PropTypes.bool,
};

VerticalInner.defaultProps = {
    noDiv: false,
};

const Vertical = withContext({
    formGroup: PropTypes.func.isRequired,
}, () => ({
    formGroup: VerticalGroup,
}))(VerticalInner);

export default Vertical;
