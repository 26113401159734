import React from 'react';
import { mapProps } from 'recompose';
import Layout from './Layout';
import { SearchField } from './sections';

const SearchLayout = mapProps(({ searchName, searchRef, searchPlaceholder, setMotRecherche, ...props }) => ({
    ...props,
    header: <SearchField searchName={searchName} placeholder={searchPlaceholder} ref={searchRef} setMotRecherche={setMotRecherche} />,
}))(Layout);

export default SearchLayout;
