import React from 'react';
import { flowRight } from 'lodash/fp';
import { formValues } from 'redux-form';

import { InputField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import Title from '../../Title';

const EnveloppeResponse = () => (
    <form>
        <Title>
            <b>Enveloppe validée</b>
        </Title>
        <HorizontalLayout>
            <div className="container-horizontal">
                <div className="container-input">
                    <InputField
                        name="reductionAmount"
                        title="Montant de la réduction"
                        type="number"
                        required
                    />
                    <InputField
                        name="fundingAmount"
                        title="Montant de l'apport"
                        type="number"
                        required
                    />
                </div>
                <div className="container-input">
                    <InputField
                        name="costAmount"
                        title="Montant des frais"
                        type="number"
                        required
                    />
                    <InputField
                        name="investmentPercentage"
                        title="Pourcentage de l'investissement"
                        type="number"
                        required
                    />
                </div>
            </div>
        </HorizontalLayout>
    </form>
);

export default flowRight([
    formValues({
        reductionAmount: 'reductionAmount',
        fundingAmount: 'fundingAmount',
        costAmount: 'costAmount',
        investmentPercentage: 'investmentPercentage',
    }),
])(EnveloppeResponse);
