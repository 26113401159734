import { get } from 'lodash/fp';
import computeInvestment from 'common/model/simulation/scpi/computeInvestment';
import responseApprovalFactory from '../../approval/responseApprovalFactory';
import { ApprovalForm } from './RequestApproval';

export default responseApprovalFactory({
    formComponent: ApprovalForm,
    recommendationCheck: ({ rip, simulation }) => {
        if (!get('user.needsValidation', rip)) {
            return false;
        }

        const recommendation = get([
            ...('partner' === simulation.beneficiary ? ['partner'] : []),
            'recommendations',
            'SCPI',
        ], rip);

        const investment = computeInvestment(simulation.data.products);
        const amount = get('data.amount', recommendation);

        return !get('isApproved', recommendation)
            || !amount
            || amount < investment
            || get('data.needFunding', simulation) !== get('data.needLoan', recommendation);
    },
});
