import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';
import { compose, withState, branch, withProps, renderComponent } from 'recompose';
import { formatDateTime } from 'common/utils/format';
import fullName from 'common/utils/fullName';
import { TextAreaField } from 'components/form/field';
import dataForm from 'components/dataForm';
import { withSession } from 'components/sessions';

const Editable = ({ handleSubmit, changeEditable }) => (
    <div className="comment comment-edition">
        <form className="form-comment-update" onSubmit={handleSubmit}>
            <TextAreaField
                title="Commentaire"
                name="text"
            />
            <button type="submit" className="confirm">
                <Icon icon="paper-plane" />
            </button>
            <button
                className="cancel"
                onClick={() => changeEditable(false)}
            >
                <Icon icon="ban" />
            </button>
        </form>
    </div>
);

Editable.propTypes = {
    handleSubmit: PropTypes.func,
    changeEditable: PropTypes.func,
};

const Visualize = ({ comment, changeEditable, session }) => {
    const otherUser = comment.userId && comment.userId !== session.id;

    const content = (
        <Fragment>
            <div className="comment-header">
                <span className="name">{fullName(comment.user)}</span>
                <span className="date">{formatDateTime(comment.createdAt)}</span>
                {!otherUser ? (
                    <span className="comment-actions">
                        <Icon icon="edit" />
                    </span>
                ) : null}
            </div>
            <p className="comment-body">{comment.text}</p>
        </Fragment>
    );

    if (!otherUser) {
        return (
            <button
                className="comment"
                onClick={() => changeEditable(true)}
            >
                {content}
            </button>
        );
    }

    return <div className="comment">{content}</div>;
};

Visualize.propTypes = {
    comment: PropTypes.shape({
        userId: PropTypes.string,
        user: PropTypes.string,
        createdAt: PropTypes.string,
        text: PropTypes.string,
    }),
    changeEditable: PropTypes.func,
    session: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

const Comment = compose(
    withState('isEditable', 'changeEditable', false),
    branch(
        ({ isEditable }) => isEditable,
        compose(
            withProps(({ comment }) => ({
                form: `comment-form-${comment.id}`,
                initialValues: comment,
            })),
            dataForm({
                id: ({ comment: { id } }) => id,
                type: 'comment',
                autoSave: false,
                onSubmitSuccess: (result, dispatch, { changeEditable }) => changeEditable(false),
            }),
            renderComponent(Editable),
        ),
    ),
    withSession,
)(Visualize);

export default Comment;
