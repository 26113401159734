// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-table-header {
    border-bottom: 0;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    vertical-align: top;
    line-height: 30px;
    height: 30px;
}

.list-table-header i.asc-desc {
    padding: 10px 0 0 10px;
    color: #248fdf;
    position: relative;
    width: 2em;
    height: 2em;
    line-height: 0.5em;
}

.list-table-header .available-sort i {
    color: #efefef;
}
`, "",{"version":3,"sources":["webpack://./src/components/list/TableHeader.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".list-table-header {\n    border-bottom: 0;\n    font-weight: bold;\n    font-size: 15px;\n    cursor: pointer;\n    vertical-align: top;\n    line-height: 30px;\n    height: 30px;\n}\n\n.list-table-header i.asc-desc {\n    padding: 10px 0 0 10px;\n    color: #248fdf;\n    position: relative;\n    width: 2em;\n    height: 2em;\n    line-height: 0.5em;\n}\n\n.list-table-header .available-sort i {\n    color: #efefef;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
