import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { InputField, IntegerField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import Diversification from './Diversification';

const FinancialFieldsInner = ({ currentValue, name }) => {
    switch (currentValue) {
        case 'lifeInsurance':
            return (
                <GridLayout className="triple condensed full-width">
                    <InputField name={`${name}.description`} title="Type" type="text" required />
                    <IntegerField name={`${name}.initialPayment`} title="Versement initial" required />
                    <IntegerField name={`${name}.monthlyPayment`} title="Versement mensuel" required />
                </GridLayout>
            );
        case 'SCPI':
        case 'dismemberedSCPI':
            return (
                <GridLayout className="triple condensed full-width">
                    <InputField name={`${name}.description`} title="Type" type="text" required />
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                    {'dismemberedSCPI' === currentValue && (
                        <IntegerField name={`${name}.duration`} title="Durée du démembrement" required />
                    )}
                </GridLayout>
            );
        case 'FIP':
        case 'LODEOM':
            return (
                <GridLayout className="condensed full-width">
                    <InputField name={`${name}.description`} title="Type" type="text" required />
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                    {'LODEOM' === currentValue && (
                        <Fragment>
                            <InputField name={`${name}.fatherLastName`} title="Nom du père" type="text" />
                            <InputField name={`${name}.fatherFirstName`} title="Prénom du père" type="text" />
                            <InputField
                                name={`${name}.motherBirthLastName`}
                                title="Nom de naissance de la mère"
                                type="text"
                            />
                            <InputField name={`${name}.motherFirstName`} title="Prénom de la mère" type="text" />
                        </Fragment>
                    )}
                </GridLayout>
            );
        case 'Madelin':
        case 'PERP':
        case 'PER':
            return (
                <GridLayout className="triple condensed full-width">
                    <InputField name={`${name}.description`} title="Type" type="text" required />
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                    <IntegerField name={`${name}.annualPayment`} title="Versement annuel" required />
                </GridLayout>
            );
        case 'diversification':
            return <Diversification name={name} />;
        case 'otherFinancial':
            return (
                <GridLayout className="condensed full-width">
                    <InputField name={`${name}.description`} title="Précisez" type="text" required />
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                </GridLayout>
            );
        default:
            return null;
    }
};

FinancialFieldsInner.propTypes = {
    currentValue: PropTypes.string,
    name: PropTypes.string.isRequired,
};

FinancialFieldsInner.defaultProps = {
    currentValue: '',
};

const FinancialFields = formValues(({ name }) => ({
    currentValue: `${name}.type`,
}))(FinancialFieldsInner);

export default FinancialFields;
