import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'common/components/Icon';
import { connectDisabledForm } from './withDisabledForm';

const getId = ({ name, value }) => `${name}-${value}`;

const CheckIcon = ({ checked }) => (checked ? <Icon icon="check" /> : null);

CheckIcon.propTypes = {
    checked: PropTypes.bool.isRequired,
};

const GridRadioButton = ({ checked, label, leftComponent: Left, disabled, required, setIsInputError, ...props }) => {
    const id = getId(props);
    if (required && checked) { setIsInputError(false); }

    return (
        <div className="checkbox checkbox-button">
            <label
                htmlFor={id}
                className={classNames('form-radio-button-vertical', { selected: checked, disabled })}
            >
                <input
                    id={id}
                    type="radio"
                    {...props}
                    checked={checked}
                    disabled={disabled}
                    required={required}
                    onInvalid={() => (setIsInputError(true))
                    }
                />

                <p>{label}</p>
                <Left {...props} checked={checked} />
            </label>
        </div>
    );
};

GridRadioButton.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    leftComponent: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    setIsInputError: PropTypes.func,
};

GridRadioButton.defaultProps = {
    checked: false,
    leftComponent: CheckIcon,
    required: false,
};

export default connectDisabledForm(GridRadioButton);
