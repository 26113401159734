// Impôt avis social (CSG + CRDS + RSA)

import { filter, flow, get, map, sum } from 'lodash/fp';
import compute from '../computeResultSocialCharges';

const computeSocialCharges = (rip, { landRentalResults, furnishedRentalResults }) => {
    const dividends = flow([
        get('incomes'),
        filter(({ type }) => 'dividends' === type),
        map(({ income = 0 }) => income),
        sum,
    ])(rip);

    const socialCharges = compute(landRentalResults) + compute(furnishedRentalResults) + compute(dividends);

    return { socialCharges };
};

export default computeSocialCharges;
