import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Icon from '../../common/components/Icon';

import './FieldInfo.css';

const FieldInfo = ({ children, id }) => (
    <OverlayTrigger
        trigger={['hover', 'click']}
        overlay={<Popover className="form-field-info-popover" placement="right" id={id}>{children}</Popover>}
    >
        <button type="button" className="form-field-info-button"><Icon icon="info" /></button>
    </OverlayTrigger>
);

FieldInfo.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
};

export default FieldInfo;
