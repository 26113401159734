import downloadPdf from './downloadPdf';

const syncRipAndDownload = async (id, database, type = false, givenUrl = null, options = {}) => {
    try {
        await database.rips.syncChanges();

        let url;
        if (!givenUrl) {
            url = `/api/rips/${id}/pdf`;
            if ('full' === type) {
                url = `/api/rips/${id}/pdf/full`;
            } else if ('signed' === type) {
                url = `/api/rips/${id}/sign/document`;
            }
        } else {
            url = givenUrl;
        }

        return downloadPdf(url, options);
    } catch (error) {
        alert('Erreur lors du téléchargement du RIP PDF. Vérifiez votre connexion internet.');

        throw error;
    }
};

export default syncRipAndDownload;
