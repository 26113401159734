// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rip-form {
    display: flex;
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
}

.rip-form-inner {
    padding-top: 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.form-control {
    border-radius: 0.1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Form.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;IACjC,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".rip-form {\n    display: flex;\n    overflow-y: hidden;\n    height: 100%;\n    max-height: 100%;\n}\n\n.rip-form-inner {\n    padding-top: 20px;\n    overflow-y: auto;\n    -webkit-overflow-scrolling: touch;\n    width: 100%;\n}\n\n.form-control {\n    border-radius: 0.1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
