import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Survey from '../../../InvestorProfile/Survey';
import Result from '../../../InvestorProfile/Result';

const InvestorProfile = () => (
    <Routes>
        <Route path="/:target/" element={<Survey />} />
        <Route path="/:target/result" element={<Result />} />
    </Routes>
);

export default InvestorProfile;
