import PropTypes from 'prop-types';
import React from 'react';
import { InputField, SelectField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';

const Dependent = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Foyer fiscal de rattachement" onlyCohabitation required single />
        <InputField name={`${name}.type`} title="Type" required type="text" />
        <SelectField name={`${name}.parts`} title="Part correspondante" required>
            <option />
            <option value={0.25}>0,25</option>
            <option value={0.5}>0,5</option>
            <option value={0.75}>0,75</option>
            <option value={1}>1</option>
        </SelectField>
    </HorizontalLayout>
);

Dependent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Dependent;
