import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { mapProps } from 'recompose';
import { formValues } from 'redux-form';
import { TextField, DateField, InputField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import hasPartner from '../../../common/utils/hasPartner';
import SeeMore, { withSeeMore } from '../SeeMore';

const TransmissionInner = ({ name, partner, seeMore }) => (
    <div>
        <HorizontalLayout>
            <TextField title="Specifier" name={`${name}.specify`} />
            <SeeMore name={name} />
        </HorizontalLayout>
        {seeMore ? (
            <div>
                <HorizontalLayout>
                    <InputField
                        name={`${name}.sickLeave`}
                        title="Solutions pour votre famille&nbsp;:&nbsp;Arrêt de Travail&nbsp;?"
                        type="text"
                        double
                    />
                    <InputField name={`${name}.invalidity`} title="Invalidité&nbsp;?" type="text" />
                </HorizontalLayout>
                <HorizontalLayout>
                    {partner ? (
                        <InputField
                            name={`${name}.partnerDeceased`}
                            title="En cas de décès, le partenaire pourra-t-il faire face aux contraintes financières&nbsp;?"
                            type="checkbox"
                            double
                        />
                    ) : null}
                    <InputField
                        name={`${name}.allDeceased`}
                        title={`En cas de décès${partner ? ' des deux' : ''}, que se passe-t-il pour les ayants-droits ?`}
                        type="text"
                        double
                    />
                </HorizontalLayout>
                <HorizontalLayout>
                    <InputField
                        name={`${name}.inheritance`}
                        title={`Quel capital recevra ${partner ? 'le conjoint ou les ayants-droits' : 'les ayants-droits'} ?`}
                        type="text"
                        double
                    />
                </HorizontalLayout>
                <HorizontalLayout>
                    <InputField name={`${name}.summary`} title="Synthèse des objectifs : Montant" type="number" />
                    <DateField name={`${name}.summaryDeadline`} title="Horizon" />
                </HorizontalLayout>
            </div>
        ) : null}
    </div>
);

TransmissionInner.propTypes = {
    name: PropTypes.string.isRequired,
    partner: PropTypes.bool,
    seeMore: PropTypes.bool,
};

TransmissionInner.defaultProps = {
    partner: false,
    seeMore: false,
};

const Transmission = flowRight([
    withSeeMore,
    formValues({
        familySituation: 'familySituation',
    }),
    mapProps(({ name, familySituation, seeMore }) => ({
        name,
        seeMore,
        partner: hasPartner(familySituation),
    })),
])(TransmissionInner);

export default Transmission;
