import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';

import { faUser } from '@fortawesome/free-regular-svg-icons/faUser';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons/faTrashCan';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf';
import { faFileWord } from '@fortawesome/free-regular-svg-icons/faFileWord';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';

const IconFontAwesome = ({ icon, path, color, statut, ...props }) => {
    if (icon) {
        if ('box' === icon && statut !== null) {
            return (
                <div>
                    <div className={`initials-badge ${statut || 'incomplete'}`}>
                        <FontAwesomeIcon icon={['failed'].includes(statut) ? faXmark : faCheck} />
                    </div>
                    <div style={{ height: 22, color: 'black' }}>
                        <FontAwesomeIcon icon={faBox} {...props} />
                    </div>
                </div>);
        }
        if ('box' === icon && !statut) {
            return (<div style={{ height: 22 }}><FontAwesomeIcon icon={faBox} color={color} {...props} /></div>);
        }
        if ('userBlanc' === icon) {
            return (<div style={{ height: 22 }}><FontAwesomeIcon icon={faUser} color={color} {...props} /></div>);
        }
        if ('trashCan' === icon) {
            return (<div style={{ height: 22 }}><FontAwesomeIcon icon={faTrashCan} color={color} {...props} /></div>);
        }
        if ('file-pdf' === icon) {
            return (<div style={{ height: 22 }}><FontAwesomeIcon icon={faFilePdf} color={color} {...props} /></div>);
        }
        if ('file-word' === icon) {
            return (<div style={{ height: 22 }}><FontAwesomeIcon icon={faFileWord} color={color} {...props} /></div>);
        }
        if ('eSign' === icon) {
            return (<div style={{ height: 22 }}><FontAwesomeIcon icon={faFileSignature} color={color} {...props} /></div>);
        }
        if ('share' === icon) {
            return (<div style={{ height: 22 }}><FontAwesomeIcon icon={faShare} color={color} {...props} /></div>);
        }

        return (<div style={{ height: 22 }}><FontAwesomeIcon icon={icon} color={color} {...props} /></div>);
    }

    return <ReactSVG src={path} svgClassName="icon" {...props} />;
};

IconFontAwesome.propTypes = {
    icon: PropTypes.func,
    path: PropTypes.string,
    color: PropTypes.string,
    statut: PropTypes.string,
};

IconFontAwesome.defaultProps = {
    icon: null,
    path: null,
    color: null,
    statut: null,
};

export default IconFontAwesome;
