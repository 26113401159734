import { round } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList } from 'recharts';
import computeSummary from '../../model/summary';
import applyEvents from '../../utils/applyEvents';
import RoundBar from '../RoundBar';

const TaxEstimationTooltip = (props) => {
    const { active, debtRatioData } = props;

    if (active) {
        const { payload, label } = props;
        let debtRatio = debtRatioData[label];
        if (Number.isNaN(debtRatio)) {
            debtRatio = 0;
        }

        return (
            <div className="custom-tooltip">
                <p className="recharts-tooltip-label">{label}</p>
                <p style={{ color: payload[0].color }}>{`${payload[0].name} : ${payload[0].value}`}</p>
                <p style={{ color: payload[1].color }}>{`${payload[1].name} : ${payload[1].value}`}</p>
                <p>Taux d&apos;endettement&nbsp;: {debtRatio} %</p>
            </div>
        );
    }

    return null;
};

TaxEstimationTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
    })),
    label: PropTypes.number,
    debtRatioData: PropTypes.shape({}),
};

const TaxEstimationBarChart = ({ bars, rip, label: Label, legendPosition, ...props }) => {
    const debtRatioData = {};
    const barData = [];
    const currentYear = (new Date()).getFullYear();

    for (let yearOffset = 1; yearOffset < 7; yearOffset++) {
        const year = currentYear + yearOffset;
        const yearlyRip = computeSummary(applyEvents(rip, year));

        barData.push({
            year,
            taxes: yearlyRip.incomeTax.toFixed(0),
            incomes: yearlyRip.rawIncomes.toFixed(0),
            socialCharges: yearlyRip.socialCharges.toFixed(0),
        });
        debtRatioData[year] = round(yearlyRip.debtRatio * 10000) / 100;
    }

    return (
        <BarChart className="bar-chart" data={barData} barSize={15} reverseStackOrder {...props}>
            <XAxis dataKey="year" minTickGap={15} />
            <YAxis unit=" €" padding={{ bottom: 20 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<TaxEstimationTooltip debtRatioData={debtRatioData} />} />
            {bars.map(({ dataKey, name, fill }) => (
                <Bar
                    key={dataKey}
                    dataKey={dataKey}
                    name={name}
                    stackId="a"
                    fill={fill}
                    legendType="circle"
                    isAnimationActive={false}
                    shape={<RoundBar />}
                >
                    {Label && (
                        <LabelList
                            dataKey={dataKey}
                            content={(
                                <Label
                                    fill={fill}
                                    dataKey={dataKey}
                                    barData={barData}
                                />
                            )}
                        />
                    )}
                </Bar>
            ))}
            <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={legendPosition} />
        </BarChart>
    );
};

TaxEstimationBarChart.propTypes = {
    bars: PropTypes.arrayOf(PropTypes.shape({
        dataKey: PropTypes.string,
        name: PropTypes.string,
        fill: PropTypes.string,
    })).isRequired,
    label: PropTypes.func,
    legendPosition: PropTypes.shape({}),
    rip: PropTypes.shape({}).isRequired,
};

export default TaxEstimationBarChart;
