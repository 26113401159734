import { get, isEmpty, set, isObject, isFunction } from 'lodash/fp';

const errorString = 'Vous devez séléctionner une valeur proposée.';

const formatChoices = (choices) => (isObject(choices) ? Object.keys(choices) : choices);

const getChoicesFactory = (choices) => {
    if (isFunction(choices)) {
        return (...args) => formatChoices(choices(...args));
    }

    const finalChoices = formatChoices(choices);

    return () => finalChoices;
};

const checkChoice = (field, choices) => {
    const getChoices = getChoicesFactory(choices);

    return (errors, values, props) => {
        const value = get(field, values);
        const finalChoices = getChoices(values, props);

        return (isEmpty(value) || finalChoices.includes(value))
            ? errors
            : set(field, errorString, errors);
    };
};

export default checkChoice;
