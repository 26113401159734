import React from 'react';
import PropTypes from 'prop-types';
import Group from 'components/form/Group';
import TypedBlockCollectionItem from 'components/form/TypedBlockCollectionItem';
import TypedCollection from 'components/form/TypedCollection';
import Mortgage from './Mortgage';
import ClosedMortgage from './ClosedMortgage';

const mortgageTypes = {
    closed: {
        component: ClosedMortgage,
        title: 'Crédit soldé',
        unique: true,
    },
    owed: {
        component: Mortgage,
        title: 'Crédit en cours',
    },
};

const Mortgages = ({ name, owner }) => (
    <Group
        title="Avez-vous des crédits ?"
        className="in-grid"
        info={/^sci-/i.test(owner) ?
            'Pour les prêts rattachés à une SCI, saisir les informations sur la totalité du prêt contracté par la SCI.' :
            null}
    >
        <TypedCollection
            name={name}
            types={mortgageTypes}
            textOnly
            itemComponent={TypedBlockCollectionItem}
        />
    </Group>
);

Mortgages.propTypes = {
    name: PropTypes.string.isRequired,
    owner: PropTypes.string,
};

export default Mortgages;
