import { get, pick, omit, flowRight } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field, fieldPropTypes } from 'redux-form';
import { getContext, withProps } from 'recompose';
import Error from '../../Error';
import withScroll from '../withScroll';

const reactSelectProps = [
    'isSearchable',
    'isClearable',
    'getOptionValue',
    'getOptionLabel',
];

const SearchableSelectInner = ({
    formGroup: Group,
    input: { name, value, ...input },
    meta,
    required,
    options,
    ...props
}) => (
    <Group
        component="label"
        htmlFor={name}
        required={required}
        {...omit(reactSelectProps, props)}
    >
        <Select
            className="react-select"
            name={name}
            defaultValue={options.find(({ value: optionValue }) => optionValue === value)}
            options={options}
            {...input}
            {...pick(reactSelectProps, props)}
        />
        <Error {...meta} />
    </Group>
);
SearchableSelectInner.propTypes = {
    ...fieldPropTypes,
    formGroup: PropTypes.func.isRequired,
    required: PropTypes.bool,
};

SearchableSelectInner.defaultProps = {
    required: false,
};

const SearchableSelect = flowRight([
    withProps({
        component: SearchableSelectInner,
        parse: get('value'),
    }),
    getContext({ formGroup: PropTypes.func.isRequired }),
    withScroll,
])(Field);

export default SearchableSelect;
