import { get } from 'lodash/fp';
import isRequiredField from '../../utils/isRequiredField';

import validate, {
    branchValidate,
    checkRequiredField,
    checkRequiredIntegerField,
} from '../lib';

export default validate([
    checkRequiredField('financialCapacity'),
    branchValidate((errors, { createdAt }, { requiredFieldActivationDateReducer }) => isRequiredField(createdAt, requiredFieldActivationDateReducer, 'lifePlans'), [
        checkRequiredField('lifePlans'),
    ]),
    branchValidate((errors, { createdAt }, { requiredFieldActivationDateReducer }) => isRequiredField(createdAt, requiredFieldActivationDateReducer, 'investorProfiles'), [
        checkRequiredField('investorProfiles'),
    ]),
    branchValidate((errors, { familySituation, dualRip, createdAt }, { requiredFieldActivationDateReducer }) => isRequiredField(createdAt, requiredFieldActivationDateReducer, 'partner.lifePlans') && ('cohabitation' === familySituation && dualRip), [
        checkRequiredField('partner.lifePlans'),
    ]),
    branchValidate((errors, { financialCapacity }) => financialCapacity, [
        checkRequiredIntegerField('financialMinimumSupply'),
        checkRequiredIntegerField('financialMaximumSupply'),
        checkRequiredIntegerField('projectMinimumSavings'),
        checkRequiredIntegerField('projectMaximumSavings'),
    ]),
    branchValidate((errors, { familySituation }) => 'cohabitation' === familySituation, [
        checkRequiredField('partner.hasFinancialCapacity'),
        branchValidate((errors, values) => get('partner.hasFinancialCapacity', values), [
            checkRequiredIntegerField('partner.financialSupply.minimum'),
            checkRequiredIntegerField('partner.financialSupply.maximum'),
            checkRequiredIntegerField('partner.projectSavings.minimum'),
            checkRequiredIntegerField('partner.projectSavings.maximum'),
        ]),
    ]),
]);
