import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { filter, reduce } from 'lodash/fp';
import { formValueSelector, formValues } from 'redux-form';
import { withProps, compose } from 'recompose';
import { Link } from 'react-router-dom';
import Group from '../../form/Group';
import TypedBlockCollectionItem from '../../form/TypedBlockCollectionItem';
import PropertyCommon from './PropertyCommon';
import TypedCollectionDropDowns from '../../form/TypedCollectionDropDowns';

const defaultValues = { fiscalFramework: 'other' };

const formSelector = formValueSelector('rip-estate');

const Title = connect((state, { name }) => ({
    city: formSelector(state, `${name}.city`),
}))(({ title, city }) => (city ? `${title} - ${city}` : title));

const types = {
    secondHome: {
        component: withProps({ type: 'secondHome' })(PropertyCommon),
        title: 'Résidence secondaire',
        itemTitle: withProps({ title: 'Résidence secondaire' })(Title),
        defaultValues,
    },
    rentalProperty: {
        component: withProps({ rental: true })(PropertyCommon),
        title: 'Locatifs',
        itemTitle: withProps({ title: 'Locatifs' })(Title),
        defaultValues: {
            ...defaultValues,
            includeInReductions: true,
            prorogatedReduction: false,
        },
    },
    other: {
        component: withProps({ other: true })(PropertyCommon),
        title: 'Autres',
        itemTitle: withProps({ title: 'Autres' })(Title),
        defaultValues,
    },
};

const PropertyList = ({ id, areRentalIncomesDifferent, areFurnishedRentalDifferent }) => (
    <Fragment>
        <Group title="Possédez-vous ?">
            <TypedCollectionDropDowns
                name="property"
                types={types}
                textOnly
                itemComponent={TypedBlockCollectionItem}
            />
            {
                areRentalIncomesDifferent || areFurnishedRentalDifferent ? (
                    <div className="alert alert-warning income-alert">
                        Les revenus totaux de vos locatifs ne
                        correspondent pas à ceux de l&apos;onglet
                        <Link to={`/rip/${id}/impots`}><b> Impôts</b></Link>.
                    </div>
                ) : null
            }
        </Group>
    </Fragment>
);

PropertyList.propTypes = {
    id: PropTypes.string.isRequired,
    areRentalIncomesDifferent: PropTypes.bool.isRequired,
    areFurnishedRentalDifferent: PropTypes.bool.isRequired,
};

const checkIncomesDifference = (state, typeChecked, furnished = false) => {
    const taxIncomes = compose(
        reduce((initialValue, { income }) => initialValue + income, 0),
        filter(({ type }) => typeChecked === type),
    )(formValueSelector('rip-estate')(state, 'incomes'));

    const estateIncomes = compose(
        reduce((initialValue, { rentalIncome }) => initialValue + (rentalIncome * 12), 0),
        filter(({ type, fiscalFramework }) =>
            'rentalProperty' === type && (
                furnished ? ['lmnp', 'lmnpBouvard'].includes(fiscalFramework) :
                    !['lmnp', 'lmnpBouvard'].includes(fiscalFramework)
            )),
    )(formValueSelector('rip-estate')(state, 'property'));

    return taxIncomes > 0 && taxIncomes !== estateIncomes;
};

export default compose(
    connect((state) => ({
        areRentalIncomesDifferent: checkIncomesDifference(state, 'landRentalIncome'),
        areFurnishedRentalDifferent: checkIncomesDifference(state, 'furnishedRentalIncome', true),
    })),
    formValues('id'),
)(PropertyList);
