const getPerData = (type, returnOne, returnTwo) => {
    if (type.toLowerCase().includes('cardif')) {
        return returnOne;
    }
    if (type.toLowerCase().includes('mma')) {
        return returnTwo;
    }

    return false;
};

export default getPerData;
