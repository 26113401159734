import React from 'react';
import PropTypes from 'prop-types';
import fullName from 'common/utils/fullName';
import SelectSignature from './SelectSignature';

const SelectSignatures = ({ rip, clientState, partnerState, advisorState, partnerShouldSign }) => {
    const [clientSignatureMode, setClientSignatureMode] = clientState;
    const [partnerSignatureMode, setPartnerSignatureMode] = partnerState;
    const [advisorSignatureMode, setAdvisorSignatureMode] = advisorState;

    return (
        <div className="sign-configuration">
            <SelectSignature
                title={`Client (${fullName(rip)})`}
                selectedSignature={clientSignatureMode}
                onClick={setClientSignatureMode}
            />
            {partnerShouldSign && (
                <SelectSignature
                    title={`Conjoint (${fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName })})`}
                    selectedSignature={partnerSignatureMode}
                    onClick={setPartnerSignatureMode}
                />
            )}
            <SelectSignature
                title={`Conseiller (${fullName(rip.user)})`}
                selectedSignature={advisorSignatureMode}
                onClick={setAdvisorSignatureMode}
            />
        </div>
    );
};

SelectSignatures.propTypes = {
    rip: PropTypes.shape({
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        user: PropTypes.string,
    }).isRequired,
    clientState: PropTypes.shape({}),
    partnerState: PropTypes.shape({}),
    advisorState: PropTypes.shape({}),
    partnerShouldSign: PropTypes.bool,
};

export default SelectSignatures;
