import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import RipMatrimonialRegime from './MatrimonialRegime';
import InfosUnion from './InfosUnion';
import RipCivilStatus from './CivilStatus';
import { DateField, YesNoField } from '../../form/field';

const LastLivingDonation = () => (
    <YesNoField name="lastLivingDonation" title="Donation dernier vivant" />
);

const FamilyInfoInner = ({ currentValue }) => {
    switch (currentValue) {
        case 'divorced':
            return <DateField name="divorcedDate" title="Date du jugement" />;
        case 'widower':
            return <DateField name="deathDate" title="Date du décès" />;
        case 'partner':
            return (
                <Fragment>
                    <RipCivilStatus partner />
                    <InfosUnion partner />
                    <RipMatrimonialRegime />
                    <LastLivingDonation />
                </Fragment>
            );
        case 'married':
            return (
                <Fragment>
                    <RipCivilStatus partner />
                    <InfosUnion />
                    <RipMatrimonialRegime />
                    <LastLivingDonation />
                </Fragment>
            );
        case 'cohabitation':
            return (
                <Fragment>
                    <RipCivilStatus partner cohabitation />
                    <YesNoField
                        title={
                            <span>
                                Construire un rip pour le concubin&nbsp;?
                            </span>
                        }
                        name="dualRip"
                    />
                </Fragment>
            );
        default:
            return null;
    }
};

FamilyInfoInner.propTypes = {
    currentValue: PropTypes.string,
};

FamilyInfoInner.defaultProps = {
    currentValue: null,
};

const FamilyInfo = formValues({
    currentValue: 'familySituation',
})(FamilyInfoInner);

export default FamilyInfo;
