import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { reduxForm, formValues, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { validateVariousSettings } from 'common/formValidation/settings';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, MarkdownField, TextField } from 'components/form/field';
import Dropdown from 'components/Dropdown';
import groups from 'common/choices/settings/calculator';
import FormFields from '../../FormFields';
import LifeInsuranceSettings from './LifeInsuranceSettings';
import CreditSettings from './CreditSettings';
import UploadSettingFiles from '../UploadSettingFiles';
import LegalDisclaimerDropdown from '../LegalDisclaimerDropdown';
import { PATCH, POST } from '../../../../utils/httpMethods';
import Date from '../../../form/field/Date';

const renderCalculatorSettings = (type, settings) => {
    switch (type) {
        case 'lifeInsurance':
            return <LifeInsuranceSettings settings={settings} />;
        case 'credit':
            return <CreditSettings settings={settings} />;
        default:
            return null;
    }
};

const FormInner = ({ initialValues: { calculator: calculatorSettings, global }, dateRepereEmailExportRip, dateRepereEmailExportUtilisateurs, ...props }) => {
    const ancienneValeurMessageAStocker = {
        titre: _.get(global, 'communication.titre'),
        contenu: _.get(global, 'communication.texte'),
        dateDebutPublication: _.get(global, 'communication.dateDebutPublication'),
        dateFinPublication: _.get(global, 'communication.dateFinPublication'),
    };

    return (
        <FormFields {...props} back="/admin/settings">
            <h1 className="text-center">
                Paramètres Divers
            </h1>
            <Title><b>Taux</b></Title>
            <GridLayout className="double condensed full-width">
                <PercentageFloatField name="global.socialChargesRate" title="Taux de charges sociales" />
                <PercentageFloatField name="global.pfu" title="PFU" />
            </GridLayout>
            <hr />
            <Title><b>Rendements</b></Title>
            <GridLayout className="double condensed full-width">
                <PercentageFloatField name="finance.livretARate" title="Taux de rendement du Livret A" />
                <PercentageFloatField name="finance.LDDRate" title="Taux de rendement du LDDS" />
            </GridLayout>
            <hr />
            <Title><b>CGV</b></Title>
            <Field name="global.erip.documents" component={(field) => <UploadSettingFiles btnText="Uploader les CGV" multiple={false} documents={field.input.value} settingKey="global.erip" onChange={field.input.onChange} />} />
            <hr />
            <Title><b>Guide de vente IFB</b></Title>
            <Field name="global.ifbSellsGuide.documents" component={(field) => <UploadSettingFiles btnText="Uploader le guide de vente" multiple={false} documents={field.input.value} settingKey="global.ifbSellsGuide" onChange={field.input.onChange} />} />
            <hr />
            <Title><b>Calculettes</b></Title>
            {
                Object
                    .entries(calculatorSettings)
                    .map(([type, subSettings]) => (
                        <Dropdown title={groups[type]} key={type}>
                            {renderCalculatorSettings(type, subSettings)}
                        </Dropdown>
                    ))
            }
            <hr />
            <Title><b>Légal</b></Title>
            <LegalDisclaimerDropdown
                title="Mentions légales (Rip client)"
                name="global.legalDisclaimer.clientPdf"
                variables={{
                    mentionsLegalesReseau: 'Mentions légales du réseau',
                    adresseLegaleReseau: 'Adresse du réseau',
                    nomReseau: 'Nom d\'affichage du réseau',
                }}
            />
            <LegalDisclaimerDropdown
                title="Mentions légales (outil)"
                name="global.legalDisclaimer.notices"
            />
            <LegalDisclaimerDropdown
                title="Confidentialité"
                name="global.legalDisclaimer.confidentiality"
            />
            <LegalDisclaimerDropdown
                title="Gestion des cookies"
                name="global.legalDisclaimer.cookies"
            />
            <LegalDisclaimerDropdown
                title="Consentement"
                name="global.legalDisclaimer.consentement"
                variables={{
                    nomInstance: 'nom d\'affichage de l\'instance',
                    nomSGA: 'nom d\'affichage du SGA',
                    nomSGAInstance: 'nom d\'affichage du SGA et/ou instance',
                    aNomSGAInstance: 'à nom d\'affichage du SGA et/ou instance',
                    mailInstance: 'adresse mail de l\'instance',
                    adresseInstance: 'adresse de l\'instance',
                }}
            />
            <hr />
            <Title><b>Automatisation</b></Title>
            <Dropdown title="Emails d'alerte">
                <div className="mails-alerte__container">
                    <button
                        className="bouton"
                        onClick={
                            () => POST('/api/admin/automatisation/emails-alerte')
                        }
                    >
                        Renvoyer les emails
                    </button>
                </div>
            </Dropdown>
            <Dropdown title="Emails d'export des RIPs">
                <div className="mails-alerte__container">
                    <Date name="dateRepereEmailExportRip" title="Date repère" classNameInput="mails-alerte__container-date" />
                    <button
                        className="bouton"
                        onClick={
                            () => POST(`/api/admin/automatisation-emails-export-rip/${dateRepereEmailExportRip || ''}`)
                        }
                    >
                        Renvoyer les emails
                    </button>
                </div>
            </Dropdown>
            <Dropdown title="Emails d'export des utilisateurs">
                <div className="mails-alerte__container">
                    <Date name="dateRepereEmailExportUtilisateurs" title="Date repère" classNameInput="mails-alerte__container-date" />
                    <button
                        className="bouton"
                        onClick={
                            () => POST(`/api/admin/automatisation-emails-export-utilisateurs/${dateRepereEmailExportUtilisateurs || ''}`)
                        }
                    >
                        Renvoyer les emails
                    </button>
                </div>
            </Dropdown>
            <hr />
            <Title><b>Communication</b></Title>
            <Dropdown title="Message d'information de la page d'accueil">
                <div className="edit-form__communication__container">
                    <TextField classNameInput="edit-form__communication__container-titre" name="global.communication.titre" title="Titre" />
                    <MarkdownField name="global.communication.texte" title="Contenu" />
                    <div className="edit-form__communication__container__dates">
                        <Date
                            name="global.communication.dateDebutPublication"
                            className="edit-form__communication__container__dates-debut"
                            title="Date de début de publication"
                            classNameInput="mails-alerte__container-date"
                        />
                        <Date
                            name="global.communication.dateFinPublication"
                            title="Date de fin de publication"
                            classNameInput="mails-alerte__container-date"
                        />
                    </div>
                    <div className="edit-form__communication__action">
                        <button
                            className="bouton"
                            onClick={async () => {
                                await POST('/api/communication/message', { body: ancienneValeurMessageAStocker });
                                await PATCH('/api/settings', {
                                    global: {
                                        communication: {
                                            titre: _.get(global, 'communication.titre'),
                                            texte: _.get(global, 'communication.texte'),
                                            dateDebutPublication: _.get(global, 'communication.dateDebutPublication'),
                                            dateFinPublication: _.get(global, 'communication.dateFinPublication'),
                                        },
                                    },
                                });

                                await PATCH('/api/users/reinitialise-aLuPopUp');
                            }}
                        >
                            Publier
                        </button>
                    </div>
                </div>
            </Dropdown>
        </FormFields>
    );
};

FormInner.propTypes = {
    initialValues: PropTypes.shape({
        calculator: PropTypes.shape({}).isRequired,
        global: PropTypes.shape({}),
    }).isRequired,
    dateRepereEmailExportRip: PropTypes.instanceOf(Date),
    dateRepereEmailExportUtilisateurs: PropTypes.instanceOf(Date),
};

const Form = compose(
    reduxForm({
        form: 'settings-various-edit',
        enableReinitialize: true,
        validate: validateVariousSettings,
    }),
    formValues('dateRepereEmailExportRip', 'dateRepereEmailExportUtilisateurs'),
)(FormInner);

export default Form;
