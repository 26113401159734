import Router from 'components/routes/routers/simulation/per/PerSimulationRouter';
import Creation, { initialValues, formValidate } from './Creation';

const per = {
    // human readable title for this kind of product
    label: 'PER',
    // it may have additional fields (the RIP is given as property)
    formComponent: Creation,
    // it may have to validate data (must be a validator signature)
    formValidate: (errors, values, props) => formValidate(values, props, errors),
    // it may have initial values
    initialValues,
    // and an entry point for the form
    router: Router,
    entryPoint: 'profile',
    // inform we don't need validation on this kind of recommendation
    noRecommendation: true,
};

export default per;
