// Revenu annuel net après abattement mais avant déductions (application des abattements sur les revenus déclarés)

import { clamp, filter, flow, get, has, map, sum, find } from 'lodash/fp';
import landRentalResultsCeil from '../data/landRentalResults';

const computeProfitIncome = (path) => ({
    regime,
    result = 0,
    witholdingIncomeTaxPaymentOption,
}, settings) => {
    const { min, rate } = get(path, settings);

    if ('real' === regime) {
        // Désactivé pour l'instant mais peut réapparaitre un jour
        // voir ticket https://dev.azure.com/theseis/erip/_boards/board/t/erip%20Team/Stories/?workitem=1497
        // return managementCenter ? result : result * 1.25;
        return result;
    }

    if (witholdingIncomeTaxPaymentOption && 'micro' === regime) {
        return 0;
    }

    return Math.max(0, result - Math.max(rate * result, min));
};

const computeRentalIncome = (microRatePath) => ({ income = 0, regime, result = 0 }, settings) => {
    const microRate = get(microRatePath, settings);

    if ('real' === regime) {
        return result;
    }

    return microRate * income;
};

const computeFurnishedIncome = (microRate) => ({ income = 0, regime, result = 0 }) => {
    if ('real' === regime) {
        return Math.max(0, result);
    }

    return microRate * income;
};

const computeSalaryIncome = ({ income = 0, realExpenses, realExpensesValue = 0 }, settings) => {
    const { min, max } = get('summary.incomeTax.10percentAbatmentBounds.employed', settings);

    if (realExpenses) {
        return income - realExpensesValue;
    }
    const abatement = clamp(min, max)(0.1 * income);

    return Math.max(income - abatement, 0);
};

const computeIncome = {
    salary: computeSalaryIncome,
    BNC: computeProfitIncome('summary.incomeTax.microBNCAbatement'),
    BIC: ({ activity, ...details }, settings) => {
        const type = 'trade' === activity ? 'trade' : 'services';

        return computeProfitIncome(`summary.incomeTax.microBICAbatement.${type}`)(details, settings);
    },
    BA: computeProfitIncome('summary.incomeTax.microBAAbatement'),
    furnishedRentalIncome: computeFurnishedIncome(0.5),
    dividends: () => 0,
    other: ({ income = 0 }) => income,
    exemptedIncome: () => 0,
    childrenIncome: computeSalaryIncome,
};

const computeRentalResults = {
    landRentalIncome: computeRentalIncome('summary.incomeTax.rentalPropertyIncomesWeight'),
    furnishedRentalIncome: computeFurnishedIncome(0.5),
};

const computePensions = ({ client, partner }, settings) => {
    const { min, max } = get('summary.incomeTax.10percentAbatmentBounds.retired', settings);

    const [clientAbatement, partnerAbatement] = [client, partner].map((pension) =>
        Math.min(pension, Math.max(pension * 0.1, min)));

    return (client + partner) - clamp(0, max)(clientAbatement + partnerAbatement);
};

const computeIncomes = (rip, _, settings) => {
    const isCouple = ['married', 'partner'].includes(rip.familySituation);
    const pensions = {
        client: 0,
        partner: 0,
    };
    let landRentalIncome = 0;

    const filterIncomes = ({ type, ...income }) => {
        switch (type) {
            case 'pension': {
                const { beneficiary, pension = 0 } = income;

                if (['client', 'partner'].includes(beneficiary) && isCouple) {
                    pensions[beneficiary] += pension;
                } else {
                    pensions.client += pension;
                }

                return false;
            }
            case 'landRentalIncome':
                landRentalIncome += computeRentalResults.landRentalIncome(income, settings);

                return false;
            // Remove children not anymore in the fiscal household
            case 'childrenIncome': {
                const childId = income.child;
                const child = find(({ id }) => childId === id, rip.children);

                if (!child) {
                    return false;
                }

                return 'outside_household' !== child.situation && !child.deadChild;
            }
            default:
                return has(type, computeIncome);
        }
    };

    let incomesN1 = flow([
        get('incomesN1'),
        filter(filterIncomes),
        map(({ type, ...details }) => computeIncome[type](details, settings)),
        sum,
    ])(rip) + computePensions(pensions, settings);

    incomesN1 += Math.max(landRentalIncome, landRentalResultsCeil);

    const rentalResultsN1 = flow([
        get('incomesN1'),
        filter(({ type }) => has(type, computeRentalResults)),
        map(({ type, ...details }) => computeRentalResults[type](details, settings)),
        sum,
    ])(rip);

    return { incomesN1, rentalResultsN1, professionnalIncomes: incomesN1 - rentalResultsN1 };
};

export { computeRentalIncome, computeFurnishedIncome };

export default computeIncomes;
