import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Balance from './Balance';
import CommonSummary from './CommonSummary';
import Operation from './Operation';
import RipSummary from './RipSummary';
import TaxHousehold from './TaxHousehold';

const SummaryInner = ({
    rip,
    simulation,
    simulationSummary,
    summary,
    afterwardSummary,
}) => (
    <Fragment>
        <CommonSummary simulation={simulation} simulationSummary={simulationSummary} />
        <RipSummary rip={rip} summary={summary} />
        <TaxHousehold summary={summary} afterwardSummary={afterwardSummary} />
        <Balance simulationSummary={simulationSummary} />
        <Operation simulation={simulation} simulationSummary={simulationSummary} />
    </Fragment>
);

SummaryInner.propTypes = {
    rip: PropTypes.shape({}).isRequired,
    summary: PropTypes.shape({}).isRequired,
    afterwardSummary: PropTypes.shape({}).isRequired,
    simulation: PropTypes.shape({}).isRequired,
    simulationSummary: PropTypes.shape({}).isRequired,
};

export default SummaryInner;
