import { flatten, get, getOr, set } from 'lodash/fp';
import validate, { checkRequiredField } from 'common/formValidation/lib';
import getSettings from 'common/utils/settings';

const checkFeesValidity = (field) => (errors, values) => {
    const fees = parseFloat(get(field, values));

    return (fees && (fees < 0 || fees > 0.05))
        ? set(field, 'Les frais d\'entrée doivent être compris en 0 et 5%.', errors)
        : errors;
};

const formValidate = validate((values) => {
    const products = get('data.products', values);

    return [
        checkRequiredField('data.product', true),
        checkRequiredField('data.subscriptionDate'),
        checkRequiredField('data.needFunding'),
        ...(get('data.needFunding', values) ? [
            checkRequiredField('data.funding.amount'),
            checkRequiredField('data.funding.duration'),
            checkRequiredField('data.funding.type'),
            checkRequiredField('data.funding.rate'),
        ] : []),
        ...('in-fine' === get('data.funding.type', values) ? [
            checkRequiredField('data.funding.investorProfile'),
            checkFeesValidity('data.funding.entryFees'),
            checkFeesValidity('data.funding.monthlyFunding.fees'),
        ] : []),
        (errors) => {
            const duration = get('data.duration', values);

            if (!duration || duration < 10) {
                return set('data.duration', 'Une durée minimum de 10 ans est requise', errors);
            }

            if (duration > 60) {
                return set('data.duration', 'La durée maximum est de 60 ans', errors);
            }

            return errors;
        },
        checkRequiredField('data.products', true),
        ...(products ? flatten(products.map(({ type }, index) => [
            (errors) => {
                const parts = get(`data.products[${index}].parts`, values);

                if (!parts || parts < getSettings(['simulation', 'scpi', type, 'minimumParts'])) {
                    return set(
                        `data.products[${index}].parts`,
                        `Un minimum de ${getSettings(['simulation', 'scpi', type, 'minimumParts'])} parts est requis`,
                        errors,
                    );
                }

                return errors;
            },
            checkRequiredField(`data.products[${index}].revaluation`),
        ])) : []),
        (errors) => {
            const chargeableDeficit = getOr(0, 'data.chargeableDeficit', values);
            const landRentalResult = getOr(0, 'data.landRentalResult', values);
            if (landRentalResult >= 0 && chargeableDeficit < 0) {
                return set('data.chargeableDeficit', 'Le déficit imputable doit être nul en cas de bénéfice foncier', errors);
            }

            if (landRentalResult < 0 && (
                chargeableDeficit < -10700 ||
                chargeableDeficit < landRentalResult ||
                chargeableDeficit > 0)) {
                return set('data.chargeableDeficit', 'Le déficit imputable doit être négatif, supérieur au résultat foncier et supérieur à -10 700€', errors);
            }

            return errors;
        },
    ];
});

export default formValidate;
