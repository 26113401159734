import diferenceFondEurosAndUnity from './diferenceFondEurosAndUnity';
import clauseOrModels from './clauseOrModels';
import discretionaryManagement from './discretionaryManagement';
import drivenFree from './drivenFree';
import drivenManagement from './drivenManagement';
import investmentPeriod from './investmentPeriod';
import lifeInsuranceCauses from './lifeInsuranceCauses';
import lifeInsuranceDown from './lifeInsuranceDown';
import lifeInsuranceLosses from './lifeInsuranceLosses';
import lifeInsuranceSouscription from './lifeInsuranceSouscription';
import objectBeneficiaryClause from './objectBeneficiaryClause';

const lifeInsurance = {
    clauseOrModels,
    diferenceFondEurosAndUnity,
    discretionaryManagement,
    drivenFree,
    drivenManagement,
    investmentPeriod,
    lifeInsuranceCauses,
    lifeInsuranceDown,
    lifeInsuranceLosses,
    lifeInsuranceSouscription,
    objectBeneficiaryClause,
};

export default lifeInsurance;
