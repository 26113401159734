/* eslint-disable react/prop-types */
import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import Group from 'components/form/Group';
import Title from 'components/Title';
import Dropdown from 'components/Dropdown';
import recommendations from 'common/choices/recommendations';
import withTargetRecommendations from '../withTargetRecommendations';
import LODEOMCIF from './LODEOMCIF';

const summaries = {
    LODEOMCIF,
};

const Recommendations = ({ recommendationCif }) => (
    <Group>
        <Title><b>Recommandations soumises</b></Title>
        {recommendationCif.map(({ type, ...recommendation }) => (
            <Dropdown key={type} title={recommendations[type].title} active>
                {createElement(summaries[type], { recommendation })}
            </Dropdown>
        ))}
    </Group>
);

Recommendations.propTypes = {
    recommendationCif: PropTypes.shape({}).isRequired,
};

export default withTargetRecommendations(Recommendations);
