import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TextItem, AmountItem } from 'common/components/summary';

const Madelin = ({
    recommendation: {
        description,
        amount,
        annualPayment,
    },
}) => (
    <Grid size={3}>
        <TextItem title="Type" value={description} />
        <AmountItem title="Montant approximatif" value={amount} />
        <AmountItem title="Versement annuel" value={annualPayment} />
    </Grid>
);

Madelin.propTypes = {
    recommendation: PropTypes.shape({
        description: PropTypes.string,
        amount: PropTypes.number,
        annualPayment: PropTypes.number,
    }).isRequired,
};

export default Madelin;
