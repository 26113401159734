import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tabs, Tab, PageHeader, Alert } from 'react-bootstrap';
import { GET } from 'utils/httpMethods';
import Layout from '../layouts/Layout';
import DocumentUploader from './DocumentUploader';
import Documentation from './Documentation';

import './index.scss';
import Variables from './Variables';
import withRouter from '../withRouter';

class Cif extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tree: null,
            error: false,
        };

        this.formRef = React.createRef();

        this.loadCifTree = this.loadCifTree.bind(this);
    }

    componentDidMount() {
        this.loadCifTree();
    }

    async loadCifTree() {
        const {
            params: { id: ripId },
        } = this.props;
        const response = await GET(`/api/documents/tree?ripId=${ripId}`);

        const { tree, error } = await response.json();

        if (error) {
            this.setState({ error: true });
        } else {
            this.setState({ tree });
            this.setState({ error: false });
        }
    }

    render() {
        const {
            params: { id: ripId },
        } = this.props;

        return (
            <Layout className="cif">
                <div className="container">
                    <PageHeader>Génération livret CIF</PageHeader>
                    <DocumentUploader ripId={ripId} />
                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="Variables">
                            {this.state.error ? (
                                <Alert bsStyle="danger">
                                    Une erreur s&apos;est produite. Veuillez vérifier la validité de votre&nbsp;
                                    <Link to={`/rip/${ripId}/etat-civil`}>Rip</Link>
                                </Alert>
                            ) : (
                                <Variables tree={this.state.tree} />
                            )}
                        </Tab>
                        <Tab eventKey={2} title="Documentation" className="documentation-tab">
                            <Documentation />
                        </Tab>
                    </Tabs>
                </div>
            </Layout>
        );
    }
}

Cif.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
};

export default withRouter(Cif);
