import PropTypes from 'prop-types';
import React from 'react';
import { compose, getContext, withProps } from 'recompose';
import { Field, fieldPropTypes } from 'redux-form';
import { connectDisabledForm } from '../withDisabledForm';
import withScroll from '../withScroll';
import Error from '../../Error';

const SelectInner = ({
    children,
    formGroup: Group,
    input: { name, ...input },
    value,
    meta,
    required,
    disabled,
    ...props
}) => (
    <Group component="label" htmlFor={name} {...props} required={required}>
        <select id={name} className="form-control" name={name} {...input} disabled={disabled}>
            {children}
        </select>
        <Error {...meta} />
    </Group>
);

SelectInner.propTypes = {
    ...fieldPropTypes,
    children: PropTypes.node.isRequired,
    formGroup: PropTypes.func.isRequired,
    title: PropTypes.node.isRequired,
    disabled: PropTypes.bool.isRequired,
};

SelectInner.default = {
    required: false,
};

const Select = compose(
    withProps({ component: SelectInner }),
    getContext({ formGroup: PropTypes.func.isRequired }),
    withScroll,
)(Field);

export default connectDisabledForm(Select);
