import { flowRight } from 'lodash/fp';
import { withProps } from 'recompose';
import withRouter from '../withRouter';

const mapPrefixToProps = flowRight([
    withRouter,
    withProps(({ params: { ripId } }) => ({ prefix: (ripId ? `/rip/${ripId}` : '') })),
]);

export default mapPrefixToProps;
