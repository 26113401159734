import { pick } from 'lodash/fp';

const initialState = {
    type: null,
    data: null,
};

const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'PDF_ERROR_UNSET':
            return initialState;
        case 'PDF_ERROR_SET': {
            return pick(['type', 'data'], payload.error);
        }
        default:
            return state;
    }
};

export default reducer;
