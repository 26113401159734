import React, { Fragment } from 'react';
import { faUniversity, faHouseUser } from '@fortawesome/free-solid-svg-icons';

import FormGroup from '../../form/Group';
import Title from '../../Title';
import { delayedScrollToNext } from '../../../utils/scrollToNext';
import { mainResidences } from '../../../common/choices';
import RadioGroup from '../../form/RadioGroup';
import RadioIcon from '../../form/RadioIcon';
import MainResidenceForm from './MainResidenceForm';

const icons = {
    owner: { path: 'owner' },
    tenant: { icon: faHouseUser },
    companyAccomodation: { icon: faUniversity },
    freeAccomodation: { path: 'freeAccomodation' },
};

const MainResidence = () => (
    <Fragment>
        <FormGroup role="presentation" onClick={delayedScrollToNext(100)} onKeyDown={delayedScrollToNext(100)}>
            <Title>Pour votre <b>résidence principale êtes-vous&nbsp;? *</b></Title>
            <RadioGroup name="mainResidence">
                {Object.entries(mainResidences).map(([key, title]) => (
                    <RadioIcon
                        title={title}
                        value={key}
                        key={key}
                        {...icons[key]}
                    />
                ))}
            </RadioGroup>
        </FormGroup>
        <MainResidenceForm />
    </Fragment>
);

export default MainResidence;
