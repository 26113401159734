import { has } from 'lodash/fp';
import React from 'react';
import { investmentTimeHorizon as choices } from '../../../choices/investorProfile';

const profiles = (investementTimeHorizon) => {
    const horizon = has(investementTimeHorizon, choices)
        ? choices[investementTimeHorizon].toLowerCase()
        : null;

    return {
        P: (
            <span>
                Compte tenu de l&apos;ensemble des éléments que vous nous avez communiqué, vous êtes
                un investisseur <b>prudent</b> avec un horizon d&apos;investissement de {horizon}.
            </span>
        ),
        E: (
            <span>
                Compte tenu de l&apos;ensemble des éléments que vous nous avez communiqué, vous êtes
                un investisseur <b>équilibré</b> avec un horizon d&apos;investissement de {horizon}.
            </span>
        ),
        D: (
            <span>
                Compte tenu de l&apos;ensemble des éléments que vous nous avez communiqué, vous êtes
                un investisseur <b>dynamique</b> avec un horizon d&apos;investissement de {horizon}.
            </span>
        ),
        declined: (
            <span>
                Vous n&apos;avez pas souhaité répondre au questionnaire de connaissances règlementaire.
                Ainsi, nous sommes dans le regret de vous informer que la réglementation nous interdit de vous formuler
                toute préconisation.
            </span>
        ),
    };
};

export default profiles;
