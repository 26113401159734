const simulationSummary = (state = {}, { type, ...payload }) => {
    switch (type) {
        case 'SET_SIMULATION_SUMMARY':
            return payload.simulationSummary;
        default:
            return state;
    }
};

export default simulationSummary;
