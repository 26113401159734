import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { fieldPropTypes } from 'redux-form';
import Icon from 'common/components/Icon';
import { connectDisabledForm } from './withDisabledForm';

import './CheckboxButton.scss';

const CheckboxButton = ({ className, input: { value, ...input }, title, meta, disabled, ...props }) => (
    <div className="checkbox checkbox-button">
        <label
            htmlFor={input.name}
            className={classNames('form-radio-button-vertical', { selected: value, disabled })}
        >
            <input
                className={`${className}${value ? ' filled' : ''}`}
                id={input.name}
                {...input}
                {...props}
                checked={value}
                disabled={disabled}
                type="checkbox"
            />
            <p>{title}</p>
            {value ? (
                <Icon icon="check" />
            ) : null}
        </label>
    </div>
);

CheckboxButton.propTypes = {
    ...fieldPropTypes,
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
};

CheckboxButton.defaultProps = {
    className: '',
};

export default connectDisabledForm(CheckboxButton);
