import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import { compose, getContext, withProps } from 'recompose';
import { fieldPropTypes, Field } from 'redux-form';
import NoLayout from '../group/NoLayout';
import Error from '../../Error';

import './MarkdownField.scss';

class MarkdownField extends Component {
    constructor(props) {
        super(props);

        this.simplemde = null;
        this.textarea = createRef();
    }

    componentDidMount() {
        this.simplemde = new SimpleMDE({
            element: this.textarea.current,
            spellChecker: false,
            status: false,
            forceSync: true,
            autofocus: true,
            backgroundColor: false,
            ...this.props.options,
        });
    }

    render() {
        const { formGroup: Group, input: { name, value }, meta, ...props } = this.props;
        const onChangeSimpleMde = (valeur) => {
            this.props.input.onChange(valeur);
        };

        return (
            <Group component="label" htmlFor={name} {...props}>
                <SimpleMDE
                    id={name}
                    name={name}
                    title={name}
                    value={value}
                    ref={this.textarea}
                    onChange={onChangeSimpleMde}
                />
                <Error {...meta} />
            </Group>
        );
    }
}

MarkdownField.propTypes = {
    ...fieldPropTypes,
    formGroup: PropTypes.func,
    options: PropTypes.shape({}),
};

MarkdownField.defaultProps = {
    formGroup: NoLayout,
    options: null,
};

export default compose(
    withProps({ component: MarkdownField }),
    getContext({ formGroup: PropTypes.func }),
)(Field);
