import { get, slice } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Title from 'components/Title';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import BalancePie from 'common/components/simulation/BalancePie';
import { Grid, AmountItem } from 'common/components/summary';
import RoundBar from 'common/components/RoundBar';

const bars = [
    { dataKey: 'capital', name: 'capital', fill: '#a2ebac' },
    { dataKey: 'taxfree', name: 'défiscalisé', fill: '#27cae6' },
    { dataKey: 'saving', name: 'épargne', fill: '#248fdf' },
];

const CommonSummary = ({
    simulation: { simulationDuration, data },
    simulationSummary: {
        operationCash,
        balance: {
            rent,
            saving,
            taxGains,
        },
        totals: {
            taxEconomy,
            taxReduction,
            csgEconomy,
        },
        operationResults: {
            transferValue,
        },
    },
}) => {
    const barData = slice(1, 1 + simulationDuration, operationCash).map((year) => ({
        year: year.year,
        capital: year.capital,
        taxfree: year.taxEconomy + year.taxReduction + year.csgEconomy,
        saving: -year.yearlySaving,
    }));

    return (
        <Fragment>
            <Title><b>Récapitulatif</b> de votre LMNP</Title>
            <Grid size={3}>
                <AmountItem title="Patrimoine constitué" value={transferValue} />
                {'censi-bouvard' === get('program.type', data) && (
                    <AmountItem title="Montant défiscalisé" value={taxEconomy + taxReduction + csgEconomy} />
                )}
                <AmountItem title="Revenu mensuel prévisionnel" value={rent} />
            </Grid>
            <div className="charts-wrapper">
                <ResponsiveContainer aspect={1} width="40%" minWidth={350}>
                    <BalancePie
                        className="pie-chart"
                        balance={{ rent, saving, taxGains, colors: ['#a2ebac', '#27cae6', '#248fdf'] }}
                    />
                </ResponsiveContainer>
                <ResponsiveContainer aspect={1.5} width="50%" minWidth={400}>
                    <BarChart
                        className="bar-chart"
                        data={barData}
                        barSize={15}
                        reverseStackOrder
                        margin={{ top: 25, left: 25, right: 15 }}
                    >
                        <XAxis dataKey="year" minTickGap={15} />
                        <YAxis unit=" €" padding={{ bottom: 20 }} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        {bars.map(({ dataKey, name, fill }) => (
                            <Bar
                                key={dataKey}
                                dataKey={dataKey}
                                name={name}
                                stackId="a"
                                fill={fill}
                                legendType="circle"
                                isAnimationActive={false}
                                shape={<RoundBar />}
                            />
                        ))}
                        <Legend layout="vertical" verticalAlign="bottom" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Fragment>
    );
};

CommonSummary.propTypes = {
    simulation: PropTypes.shape({
        simulationDuration: PropTypes.number,
        data: PropTypes.shape({}),
    }).isRequired,
    simulationSummary: PropTypes.shape({
        operationCash: PropTypes.number,
        balance: PropTypes.shape({
            rent: PropTypes.number,
            saving: PropTypes.number,
            taxGains: PropTypes.number,
        }),
        totals: PropTypes.shape({
            taxEconomy: PropTypes.number,
            taxReduction: PropTypes.number,
            csgEconomy: PropTypes.number,
        }),
        operationResults: PropTypes.shape({
            transferValue: PropTypes.number,
        }),
    }).isRequired,
};

export default CommonSummary;
