import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from '../../form/layout';
import { PercentageField } from '../../form/field';
import SelectSalaryField from '../field/SelectSalary';
import Recurrent from '../Recurrent';

const SalaryIncreaseEvent = ({ name }) => (
    <HorizontalLayout>
        <SelectSalaryField name={`${name}.income`} title="Revenu concerné" required />
        <PercentageField name={`${name}.percentage`} title="Pourcentage d'évolution annuelle" required />
        <Recurrent name={name} />
    </HorizontalLayout>
);

SalaryIncreaseEvent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default SalaryIncreaseEvent;
