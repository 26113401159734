import { omit } from 'lodash/fp';

const getFiscalParticularCases = (familySituation, cases) => {
    if ('widower' === familySituation) {
        return cases;
    }

    if (['single', 'divorced'].includes(familySituation)) {
        return omit(['widowerWithMultiplePersonInCharge'], cases);
    }

    return omit([
        'singleParent',
        'singleHavingRaisedChildMoreThan5Years',
        'widowerWithMultiplePersonInCharge',
    ], cases);
};

export default getFiscalParticularCases;
