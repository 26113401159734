import { flowRight } from 'lodash/fp';
import { branch, renderNothing } from 'recompose';
import dataProvider from '../dataProvider';
import withRouter from '../withRouter';

const simulationLoader = flowRight([
    withRouter,
    dataProvider({
        id: ({ params: { simulationId } }) => simulationId,
        type: 'simulation',
    }),
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
    }),
    branch(
        ({ rip, simulation }) => !rip || !simulation,
        renderNothing,
    ),
]);

export default simulationLoader;
