import PropTypes from 'prop-types';
import React from 'react';
import withRouter from '../withRouter';
import RipFinanceForm from './finances/Form';
import RipLayout from './RipLayout';

const Finances = ({ navigate, params: { id } }) => (
    <RipLayout>
        <RipFinanceForm
            back={`/rip/${id}/patrimoine`}
            id={id}
            onSubmitSuccess={() => navigate(`/rip/${id}/profil-client`)}
        />
    </RipLayout>
);

Finances.propTypes = {
    navigate: PropTypes.func,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(Finances);
