import PropTypes from 'prop-types';
import React from 'react';
import { scrollToNext } from 'utils/scrollToNext';
import { VerticalLayout } from 'components/form/layout';

const FormFieldsWhitoutFooter = ({ children, handleSubmit }) => (
    <form
        role="presentation"
        onSubmit={handleSubmit}
        onKeyDown={scrollToNext}
        id="main-form"
    >
        <div>
            <VerticalLayout className="container">
                {children}
            </VerticalLayout>
        </div>
    </form>
);

FormFieldsWhitoutFooter.propTypes = {
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default FormFieldsWhitoutFooter;
