// Résultat foncier

import { filter, flow, get, map, sum } from 'lodash/fp';
import landRentalResultsCeil from '../data/landRentalResults';

const computeRentalResult = ({ income = 0, regime, result = 0 }, settings) => {
    const microRate = get('summary.incomeTax.rentalPropertyIncomesWeight', settings);

    if ('real' === regime) {
        return result;
    }

    return microRate * income;
};

const computeLandRentalResults = (rip, _, settings) => {
    const { incomes } = rip;

    return flow(
        filter(['type', 'landRentalIncome']),
        map(({ type, ...details }) => computeRentalResult(details, settings)),
        sum,
        (landRentalResults) => ({
            landRentalResults: Math.max(landRentalResults, landRentalResultsCeil),
        }),
    )(incomes);
};

export default computeLandRentalResults;
