import PropTypes from 'prop-types';
import React from 'react';
import { homeWork as computeAmountHomeWork } from 'common/model/summary/credits';
import { IntegerField, TextField, SelectField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const HomeWork = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <IntegerField name={`${name}.amount`} title="Montant éligible dans l&apos;année" required />
        <SelectField name={`${name}.rate`} title="Taux du crédit d'impôt" required>
            <option value={0.15}>15%</option>
            <option value={0.3}>30%</option>
        </SelectField>
        <TextField name={`${name}.description`} title="Précisez" />
        <Recurrent name={name} />
        <DisabledTaxField type="credit" name={name} computeAmount={computeAmountHomeWork} />
    </HorizontalLayout>
);

HomeWork.propTypes = {
    name: PropTypes.string.isRequired,
};

export default HomeWork;
