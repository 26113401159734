import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { AmountItem, CountItem, DateItem } from 'common/components/summary';
import computeInvestment from 'common/model/simulation/scpi/computeInvestment';
import Title from 'components/Title';

const Settings = ({
    data: {
        subscriptionDate,
        duration,
        products,
    },
}) => (
    <Fragment>
        <Title><b>Caractéristiques et paramètres</b></Title>
        <div className="summary-body col3">
            <dl>
                <DateItem title="Date de souscription" value={subscriptionDate} />
                <CountItem title="Durée en année" value={duration} />
                <AmountItem title="Montant de l'investissement" value={computeInvestment(products)} />
            </dl>
        </div>
    </Fragment>
);

Settings.propTypes = {
    data: PropTypes.shape({
        duration: PropTypes.number.isRequired,
        subscriptionDate: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            parts: PropTypes.number.isRequired,
            revaluation: PropTypes.string,
        })).isRequired,
    }).isRequired,
};

export default Settings;
