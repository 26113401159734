import classNames from 'classnames';
import { chunk, filter } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';

const noNullElement = filter((child) => null !== child);

const Grid = ({ children, size }) => (
    <div className={classNames('summary-body', `col${size}`)}>
        {chunk(size, noNullElement(Children.toArray(children))).map((rowChildren, rowIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <dl key={rowIndex.toString()}>
                {rowChildren.map((child, childIndex) => cloneElement(child, { key: childIndex.id }))}
            </dl>
        ))}
    </div>
);

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOf([1, 2, 3]),
};

Grid.defaultProps = {
    size: 3,
};

export default Grid;
