// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signatures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/commitment/Signatures/Manual/ManualSignatures.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;AACjC","sourcesContent":[".signatures {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
