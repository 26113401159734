import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field, formValues } from 'redux-form';
import CheckboxButton from 'components/form/CheckboxButton';
import { GridLayout } from 'components/form/layout';

const ItemsToDisplayInner = ({ className, needFunding, product }) => (
    <div className={className}>
        <GridLayout>
            <Field
                title="Bilan de l&apos;opération"
                component={CheckboxButton}
                name="showOperationReview"
            />
            <Field
                title="Hypothèses de sortie en capital"
                component={CheckboxButton}
                name="showCapitalOutflowAssumptions"
            />
            <Field
                title="Hypothèses de sortie en rente"
                component={CheckboxButton}
                name="showAnnuityRetirementAssumptions"
            />
            <Field
                title="Tableaux de synthèse du foyer fiscal"
                component={CheckboxButton}
                name="showHouseholdTaxSummary"
            />
            {needFunding ? (
                <Field
                    title="Tableau d&apos;amortissement"
                    component={CheckboxButton}
                    name="showDepreciationTable"
                />
            ) : null}
            {('pinel' === product || 'pinelDenormandie' === product) ? (
                <Fragment>
                    <Field
                        title="Hypothèse avec frais d'acquisition"
                        component={CheckboxButton}
                        name="data.showAssumptionsWithFees"
                    />
                    <Field
                        title="Hypothèses avec réindexation"
                        component={CheckboxButton}
                        name="showAssumptionsWithReindexation"
                    />
                </Fragment>
            ) : null}
            <Field
                title="Colonne épargne mensuelle"
                component={CheckboxButton}
                name="showMonthlySavingsColumn"
            />
            <Field
                title="Colonne épargne lissée"
                component={CheckboxButton}
                name="showSmoothSavingsColumn"
            />
            <Field
                title="Colonne trésorerie"
                component={CheckboxButton}
                name="data.showCashFlowColumn"
            />
            <Field
                title="Colonne épargne ou rente annuelle réelle"
                component={CheckboxButton}
                name="data.showYearlySavingsColumn"
            />
        </GridLayout>
    </div>
);

ItemsToDisplayInner.propTypes = {
    className: PropTypes.string,
    needFunding: PropTypes.bool,
    product: PropTypes.string.isRequired,
};

ItemsToDisplayInner.defaultProps = {
    className: null,
    needFunding: false,
};

const ItemsToDisplay = formValues({
    needFunding: 'needFunding',
    product: 'product',
})(ItemsToDisplayInner);

export default ItemsToDisplay;
