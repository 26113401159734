const choices = {
    cash: 'Liquidités',
    obligation: 'Obligations/fonds obligations',
    action: 'Actions/fonds d’action ',
    OPCVM: 'OPCVM',
    SCPI: 'SCPI/OPCI',
    estate: 'Immobilier direct',
};

export default choices;
