import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import FreeAccomodationSVG from '../../images/pictos/logement-gratuit';
import OwnerSVG from '../../images/pictos/proprietaire';

import './RadioIcon.css';

const id = ({ name, value }) => `${name}-${value}`;

const defineClassName = (path) => {
    if (!path) {
        return null;
    }
    if ('freeAccomodation' === path) {
        return 'titleForfreeAccomodationSVG';
    }

    return 'titleForOwnerSVG';
};

const RadioIcon = ({ icon, path, title, ...props }) => (
    <label className={`form-radio-icon${props.checked ? ' selected' : ''}`} htmlFor={id(props)}>
        <input id={id(props)} type="radio" {...props} />
        <FontAwesomeIcon icon={icon} path={path} />
        {'owner' === path ? <OwnerSVG /> : null}
        {'freeAccomodation' === path ? <FreeAccomodationSVG /> : null}
        <p className={defineClassName(path)} >{title}</p>
    </label>
);
RadioIcon.propTypes = {
    checked: PropTypes.bool,
    icon: PropTypes.string,
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
};

RadioIcon.defaultProps = {
    checked: false,
    icon: null,
    path: null,
};

export default RadioIcon;
