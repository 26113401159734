import React from 'react';
import './WitholdingIncomeTaxPaymentOptionTable.scss';

const tooltipString = 'Cette option est ouverte uniquement au micro-entrepreneur dont le revenu '
+ 'fiscal de référence de l\'année N-2 est inférieur ou égal à un seuil calculé en fonction du quotient familial. '
+ 'Au-delà du seuil, l\'option pour le versement libératoire n\'est pas possible.';

const WitholdingIncomeTaxPaymentOptionTable = () => (
    <div className="witholding-income-tax-payment-table">
        <table>
            <caption>
                Revenu fiscal de référence de l&apos;année N-2 pour l&apos;option au versement libératoire
            </caption>
            <thead>
                <tr>
                    <th />
                    <th>Revenu fiscal de référence de 2015 pour une application au 1e Janvier 2017</th>
                    <th>Revenu fiscal de référence de 2014 pour une application au 1e Janvier 2016</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><strong>Personnes seule</strong><br />(1 part de quotient familial)</td>
                    <td>26 791 €</td>
                    <td>26 764 €</td>
                </tr>
                <tr>
                    <td><strong>Couple</strong><br />(2 part de quotient familial)</td>
                    <td>53 582 €</td>
                    <td>53 528 €</td>
                </tr>
                <tr>
                    <td><strong>Couple avec 1 enfant</strong><br />(2 parts + 1 demi-part)</td>
                    <td>66 977 €</td>
                    <td>66 910 €</td>
                </tr>
                <tr>
                    <td><strong>Couple avec 2 enfants</strong><br />(2 parts + 2 demi-parts)</td>
                    <td>80 373 €</td>
                    <td>80 292 €</td>
                </tr>
            </tbody>
        </table>
        <div className="alert alert-info alert-witholding">
            {tooltipString}
        </div>
    </div>
);

export default WitholdingIncomeTaxPaymentOptionTable;
