import { get, isEmpty, set } from 'lodash/fp';

const checkEqualFields = (field1, field2, errorString) =>
    (errors, values) => (
        (get(field1, values) !== get(field2, values))
            && (!isEmpty(get(field1, values) || !isEmpty(get(field2, values)))
                ? set(field1, errorString, errors)
                : errors));

export default checkEqualFields;
