// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer h3 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
}

.disclaimer h4 {
    color: rgb(36, 146, 223);
    font-size: 22px;
}

.disclaimer p {
    text-align: justify;
}

.disclaimer ol li {
    margin-bottom: 20px;
    text-align: justify;
}

.disclaimer button p {
    text-align: center
}
`, "",{"version":3,"sources":["webpack://./src/components/legalDisclaimer/Disclaimer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI;AACJ","sourcesContent":[".disclaimer h3 {\n    text-align: center;\n    font-size: 30px;\n    margin-bottom: 30px;\n}\n\n.disclaimer h4 {\n    color: rgb(36, 146, 223);\n    font-size: 22px;\n}\n\n.disclaimer p {\n    text-align: justify;\n}\n\n.disclaimer ol li {\n    margin-bottom: 20px;\n    text-align: justify;\n}\n\n.disclaimer button p {\n    text-align: center\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
