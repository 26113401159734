// Calcul de l'impot pour les faibles revenus.
// Pour l'instant on part des revenus nets après abattements et déduction pour
// calculer la réduction des 20%. Normalement on devrait utiliser le RFR. Cependant
// La plupart des cas sont traités en utilisant le revenu net imposable.
import getSettings from '../../utils/settings';

const computeLowIncomesTax = ({
    rip: { familySituation },
    referenceIncomes,
    incomeTax,
    halfPartsCount,
    quarterPartsCount,
}) => {
    const {
        summary: {
            incomeTax: {
                decote: {
                    rate: decoteRate,
                    incomeTaxCeils,
                    reductionCeils,
                },
                extraReduction: {
                    rate: extraReductionRate,
                    incomeCeils,
                    extraPartIncrease,
                    degressiveReductionIncomeCeils,
                    degressiveDivisors,
                },
            },
        },
    } = getSettings();
    let incomeTaxResult = incomeTax;

    // Définition des plafonds en fonction de la situation familiale.
    const situation = ['married', 'partner'].includes(familySituation) ? 'couple' : 'single';
    const decoteCeil = reductionCeils[situation];
    const decoteIncomeTaxCeil = incomeTaxCeils[situation];
    const degressiveDivisor = degressiveDivisors[situation];

    // Calcul de la majoration du plafond pour la réduction de 20%,
    // en fonction des demi et quarts de parts supplémentaires.
    const extraReductionCeil = (halfPartsCount * extraPartIncrease.half)
        + (quarterPartsCount * extraPartIncrease.quarter);
    const reductionIncomeCeil = incomeCeils[situation] + extraReductionCeil;
    const degressiveReductionIncomeCeil = degressiveReductionIncomeCeils[situation] + extraReductionCeil;

    // Application de la décote.
    if (incomeTax < decoteIncomeTaxCeil) {
        incomeTaxResult -= decoteCeil - (incomeTax * decoteRate);
    }

    // Application de la réduction de 20% pour les foyers modestes.
    if (referenceIncomes < degressiveReductionIncomeCeil) {
        if (referenceIncomes < reductionIncomeCeil) {
            incomeTaxResult *= (1 - extraReductionRate);
        } else {
            incomeTaxResult *= 1 - (
                (
                    (degressiveReductionIncomeCeil - referenceIncomes)
                    / degressiveDivisor
                )
                * extraReductionRate
            );
        }
    }

    return Math.max(0, incomeTaxResult);
};

export default computeLowIncomesTax;
