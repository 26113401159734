import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, isArray, isObject } from 'lodash';
import Leaf from './Leaf';

const randomColor = '#222222';

function Node({ node, margin = 0, path = '', onLeafClick, nodeIsArray = false, arrayPath = '' }) {
    return (
        <div style={{ marginLeft: `${margin}px` }}>
            {map(node, (value, key) => {
                if (isArray(value)) {
                    if (isEmpty(value)) {
                        return null;
                    }

                    const array = value.map((subNode, index) => (
                        <div style={{ marginLeft: `${margin}px` }}>
                            <span style={{ color: randomColor }}>&#123;</span>
                            <Node
                                /* eslint-disable-next-line */
                                key={`node-${path}${path && '.'}${key}${index}`}
                                node={subNode}
                                margin={margin}
                                path={`${path}${path && '.'}${key}`}
                                onLeafClick={onLeafClick}
                                nodeIsArray
                            />
                            <span style={{ color: randomColor }}>&#125;,</span>
                        </div>
                    ));

                    return (
                        <div key={`array-${path}${arrayPath}${key}`}>
                            <strong>{key} :</strong>&nbsp;[
                            {array}]
                        </div>
                    );
                } else if (isObject(value)) {
                    return (
                        <div key={`object-${key}`}>
                            <strong>{key} :</strong>&nbsp;
                            <span style={{ color: randomColor }}>&#123;</span>
                            <Node
                                node={value}
                                margin={margin + 10}
                                path={`${path}${path && !nodeIsArray ? '.' : ''}${nodeIsArray ? '' : key}`}
                                arrayPath={`${arrayPath}${arrayPath && '.'}${key}`}
                                onLeafClick={onLeafClick}
                                nodeIsArray={nodeIsArray}
                            />
                            <span style={{ color: randomColor }}>&#125;,</span>
                        </div>
                    );
                }

                return (
                    <Leaf
                        key={`leaf-${arrayPath}${key}`}
                        label={key}
                        value={value}
                        path={path}
                        arrayPath={arrayPath}
                        onLeafClick={onLeafClick}
                        nodeIsArray={nodeIsArray}
                    />
                );
            })}
        </div>
    );
}

Node.propTypes = {
    node: PropTypes.shape({}).isRequired,
    margin: PropTypes.number,
    path: PropTypes.string,
    onLeafClick: PropTypes.func.isRequired,
    nodeIsArray: PropTypes.bool,
    arrayPath: PropTypes.string,
};

Node.defaultValues = {
    margin: 0,
    path: '',
    nodeIsArray: false,
    arrayPath: '',
};

export default Node;
