import PropTypes from 'prop-types';
import React from 'react';
import { Field, formValues } from 'redux-form';
import { branch, compose } from 'recompose';
import fullName from 'common/utils/fullName';
import dataForm from 'components/dataForm';
import FormButtons from 'components/FormButtons';
import Form from 'components/simulation/FormStep';
import contractLoader from 'components/contract/contractLoader';
import FormGroup from 'components/form/Group';
import Checkbox from 'components/form/Checkbox';
import GridRadioGroup from 'components/form/GridRadioGroup';
import GridRadioButton from 'components/form/GridRadioButton';
import withDisabledForm from 'components/form/withDisabledForm';
import ConnectedNextButton from 'components/FormButtons/buttons/ConnectedNextButton';
import Error from 'components/Error';
import getWizardStep from 'utils/getWizardStep';
import getWizard from '../wizardData';
import processValues from '../processValues';
import onSubmitRedirect from '../onSubmitRedirect';
import formValidate from './formValidate';
import getPerData from '../../../../common/utils/getPerData';

const ClientInformationInner = ({
    handleSubmit,
    change,
    params: { id, contractType },
    rip,
    fundsOrigin,
    location,
    params,
    choixBeneficiaire,
    ...meta
}) => {
    const { contract: { simulation: { beneficiary } } } = meta;
    const wizard = getWizard(contractType);
    const baseUrl = wizard.prefixTemplate(params);
    const prevStep = getWizardStep({ wizard, url: location.pathname, next: false });

    const prevUrl = `${baseUrl}/${prevStep}`;

    const companyName = getPerData(contractType, 'CARDIF', 'MMA');

    const name = 'partner' === beneficiary ? {
        firstName: rip.partnerFirstName,
        lastName: rip.partnerLastName,
    } : rip;

    const handleCourtierChange = (event, newValue) => {
        if (newValue) {
            change('data.mandataire', false);
        }
    };

    const handleMandataireChange = (event, newValue) => {
        if (newValue) {
            change('data.courtier', false);
        }
    };

    return (
        <Form handleSubmit={handleSubmit} wizard={wizard}>
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <Error {...meta} />
                    <FormGroup title="Part du nouvel investissement dans votre patrimoine global ?">
                        <GridRadioGroup name="data.investmentRatioInGlobalEstate">
                            <GridRadioButton
                                label="plus de 20%"
                                value="moreThan20"
                            />
                            <GridRadioButton
                                label="moins de 20%"
                                value="lessThan20"
                            />
                        </GridRadioGroup>
                    </FormGroup>
                    <FormGroup required title="Vous avez sélectionné ce contrat pour la raison suivante :">
                        <GridRadioGroup name="data.reasonForTheChoiceOfContract">
                            <GridRadioButton
                                label="L'accessibilité du contrat"
                                value="contractAccessibility"
                            />
                            <GridRadioButton
                                label="Le type de profil"
                                value="typeOfProfile"
                            />
                        </GridRadioGroup>
                    </FormGroup>
                    <Field
                        title={`J'autorise THESEIS et ${companyName} à m'adresser toute information, notification `
                            + 'ou communication concernant mes contrats (notamment conditions particulières, conditions '
                            + 'contractuelles, avis d\'opéré, relevés d\'informations annuels) au sein de mon espace personnel '
                            + 'sur internet et/ou par courriel par le biais de mon adresse mail.'}
                        component={Checkbox}
                        name="data.allowTheseisMailContact"
                        required
                    />
                    <Field
                        title={`Je soussigné(e) ${fullName(name)} atteste que la notice du contrat`
                            + ' relatif à la souscription que je m\'apprête à faire m\'a bien été remise préalablement à toute '
                            + 'souscription, dans le cadre du règlement PRIPPS applicable à partir 01/01/2018, et dans un ' +
                            'temps utile avant la conclusion de mon contrat, me permettant de prendre ma décision\n' +
                            'd\'investissement en connaissance de cause.'}
                        component={Checkbox}
                        name="data.gotKeyInformationDocument"
                        required
                    />
                    <Field
                        title={'Je certifie également avoir reçu et pris connaissance des caractéristiques principales de '
                            + 'chaque support qui m\'a été préconisé dans la simulation qui m\'a été remise par mon conseiller,'
                            + ' dans l\'un et/ou l\'autre des documents suivants : Document d\'Informations Clés pour '
                            + 'l\'Investisseur (DICI), Conditions contractuelles, note détaillée, annexe complémentaire de présentation'}
                        component={Checkbox}
                        name="data.gotInvestmentNotices"
                        required
                    />
                    <FormGroup title="Choix de la clause bénéficiaire">
                        <GridRadioGroup name="data.choixBeneficiaire">
                            <GridRadioButton
                                label="générale"
                                value="generale"
                            />
                            <GridRadioButton
                                label="libre"
                                value="libre"
                            />
                        </GridRadioGroup>

                        {'libre' === choixBeneficiaire && <p style={{ marginTop: '10px' }}> À compléter sur le site du partenaire.</p>}
                    </FormGroup>
                    <FormGroup title="Statut du mandataire">
                        <Field
                            title="Courtier"
                            component={Checkbox}
                            name="data.courtier"
                            onChange={handleCourtierChange}
                        /> <Field
                            title="Mandataire"
                            component={Checkbox}
                            name="data.mandataire"
                            onChange={handleMandataireChange}
                        />
                        <Error {...meta} />
                    </FormGroup>
                </div>
            </div>
            <FormButtons
                back={prevUrl}
                list={`/rip/${id}/contracts/`}
                nextButtonComponent={ConnectedNextButton}
            />
        </Form>
    );
};

ClientInformationInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
        contractType: PropTypes.string.isRequired,
    }).isRequired,
    fundsOrigin: PropTypes.shape({}),
    rip: PropTypes.shape({
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    choixBeneficiaire: PropTypes.string,
};

const ClientInformation = compose(
    contractLoader,
    branch(({ contract: { state } }) => 'ongoing' !== state, withDisabledForm),
    dataForm({
        autoSave: false,
        processValues,
        form: 'contract-client-information',
        id: ({ params: { contractId } }) => contractId,
        onSubmitSuccess: onSubmitRedirect,
        type: 'contract',
        validate: formValidate,
    }),
    formValues({ fundsOrigin: 'data.fundsOrigin', choixBeneficiaire: 'data.choixBeneficiaire' }),
)(ClientInformationInner);

export default ClientInformation;
