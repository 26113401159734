// Réductions non plafonnées : i.e. non soumises au plafonnement globale des niches fiscales
// cf https://www.service-public.fr/particuliers/vosdroits/F31179

import { clamp, concat, filter, flow, forEach, groupBy, has, map, mapValues, sumBy, get } from 'lodash/fp';
import { parseInteger, sumOrAdd } from '../utils';
import { computeOther, availableForCapExclusion } from './cappedReductions';

// Ne prend pas en compte le cas des gardes alternées
// Pour un enfant en garde alternée, le montant est divisé par 2
export const education = ({ id, establishment, number, numberAlternateCustody }, settings) => {
    const schoolReduction = get(`summary.reductions.education.${establishment}`, settings);

    const amount = establishment
        ? schoolReduction * (parseInteger(number) + (0.5 * parseInteger(numberAlternateCustody)))
        : 0;

    return {
        id,
        reportedAmount: amount,
        computedAmount: amount,
    };
};

export const computeDonations = (donations, { incomes }, settings) => {
    // rate1 represents donations dudctibles by 75%
    // rate2 represents donations dudctibles by 66%
    // ceil represents the max percentage of incomes the reduction can equals to
    const {
        rate1: { rate: rate1, max },
        rate2: { rate: rate2 },
        ceil,
    } = get('summary.reductions.donation', settings);

    let amountRate1 = donations[rate1.toString()] || 0;
    let amountRate2 = donations[rate2.toString()] || 0;

    if (max < amountRate1) {
        amountRate2 += amountRate1 - max;
        amountRate1 = max;
    }
    const amount = (rate2 * amountRate2) + (rate1 * amountRate1);

    return {
        reportedDonation: amountRate2 + amountRate1,
        computedDonation: clamp(0, ceil * incomes)(amount),
    };
};

const other = (reduction) => {
    if (reduction.capped) {
        return null;
    }

    if ('investment' !== reduction.type || 'lodeom' === reduction.investment) {
        const { id } = reduction;
        const amount = computeOther(reduction);

        return {
            id,
            reportedAmount: amount,
            computedAmount: amount,
        };
    }

    return null;
};

const getUncappedReduction = {
    education,
    other,
    rentalProperty: other,
    investment: other,
};

const splitReductions = groupBy(({ type }) => ('donation' === type ? 'donations' : 'others'));
const filterProperties = ({ fiscalFramework, includeInReductions }) => (
    // get all properties with fiscalFramework available for cap exclusion
    // but without ceil
    availableForCapExclusion.includes(fiscalFramework) && !includeInReductions
);
const mapProperties = ({ detaxAmount }) => ({ type: 'rentalProperty', amount: detaxAmount });

const computeUncappedReductions = (rip, summary, settings) => {
    // first split the reductions into two list (donations & others)
    const { donations, others } = splitReductions(concat(
        rip.reductions,
        flow([
            filter(filterProperties),
            map(mapProperties),
        ])(rip.property),
    ));

    // then we may calculates both
    let uncappedReductions = 0;
    let uncappedReductionsDetails = {};

    if (donations) {
        // we have donations so compute those
        const donation = flow([
            groupBy('percentage'),
            mapValues(sumBy('amount')),
            (groupedDonations) => computeDonations(groupedDonations, summary, settings),
        ])(donations);

        uncappedReductions += donation.computedDonation;
        uncappedReductionsDetails.donation = {
            reportedAmount: donation.reportedDonation,
            computedAmount: donation.computedDonation,
        };
    }

    if (others) {
        flow([
            filter(({ type }) => has(type, getUncappedReduction)),
            forEach((reduction) => {
                const uncappedReduction = getUncappedReduction[reduction.type](reduction, settings);

                if (null === uncappedReduction) {
                    return;
                }

                const { computedAmount } = uncappedReduction;

                uncappedReductionsDetails = sumOrAdd(uncappedReductionsDetails, reduction.type, uncappedReduction);
                uncappedReductions += computedAmount;
            }),
        ])(others);
    }

    return { uncappedReductions, uncappedReductionsDetails };
};

export default computeUncappedReductions;
