import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { branch, renderNothing } from 'recompose';
import { formValues } from 'redux-form';
import { ResponsiveContainer } from 'recharts';
import Title from 'components/Title';
import Dropdown from 'components/Dropdown';
import computeSimulation from 'common/model/simulation/lifeInsurance/miniSimulation';
import { AmountItem } from 'common/components/summary';
import LifeInsuranceResultBarChart from 'common/components/rip/simulators/LifeInsuranceResultBarChart';

const bars = [
    { dataKey: 'fundings', name: 'Versements', fill: '#27cae6' },
    { dataKey: 'interests', name: 'Intérêts', fill: '#248fdf' },
];

const labelFormatter = (label) => `Année: ${label}`;

const formatter = (value) => `${value} €`;

const LifeInsuranceResultsInner = ({ investorProfile, duration: months, ...params }) => {
    const duration = Math.floor(months / 12);
    const simulate = computeSimulation({ ...params, duration });
    const lifeInsuranceSimulation = simulate(investorProfile);
    const lifeInsuranceFinal = lifeInsuranceSimulation[duration];

    return (
        <Dropdown title="Simulation d'assurance vie">
            <Title><b>Chiffres-clés</b></Title>
            <div className="summary-body col3">
                <dl>
                    <AmountItem title="Capital final" value={lifeInsuranceFinal.capital} />
                    <AmountItem title="Versements cumulés" value={lifeInsuranceFinal.fundings} />
                    <AmountItem title="Intérêts cumulés" value={lifeInsuranceFinal.interests} />
                </dl>
            </div>
            <Title>Votre <b>épargne</b> au fil des ans</Title>
            <ResponsiveContainer aspect={1.5} maxHeight={300}>
                <LifeInsuranceResultBarChart
                    bars={bars}
                    barData={lifeInsuranceSimulation}
                    labelFormatter={labelFormatter}
                    formatter={formatter}
                />
            </ResponsiveContainer>
        </Dropdown>
    );
};

LifeInsuranceResultsInner.propTypes = {
    duration: PropTypes.number.isRequired,
    investorProfile: PropTypes.string.isRequired,
};

const LifeInsuranceResults = flowRight([
    formValues({
        duration: 'data.funding.duration',
        initialFunding: 'data.funding.initialFunding',
        entryFees: 'data.funding.entryFees',
        monthlyFunding: 'data.funding.monthlyFunding.amount',
        monthlyFees: 'data.funding.monthlyFunding.fees',
        investorProfile: 'data.funding.investorProfile',
    }),
    branch(({ investorProfile, duration }) => !duration || !investorProfile, renderNothing),
])(LifeInsuranceResultsInner);

export default LifeInsuranceResults;
