import { filter, flow, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import validate, { checkRequiredField } from 'common/formValidation/lib';
import { GridLayout } from 'components/form/layout';
import { TextField, SearchableSelectField } from 'components/form/field';
import Title from 'components/Title';
import FormFields from '../../FormFields';
import { withSession } from '../../../sessions';
import sortEntitiesChildren from '../../../../common/utils/sortEntitiesChildren';

const FormInner = ({
    entities,
    initialValues: { id },
    session,
    ...props
}) => {
    let entitiesToMap;

    let requiredInput = false;
    if (session.isAdmin) {
        entitiesToMap = entities;
    } else if (session.isAdminReseau) {
        requiredInput = true;
        // réseau de l'utilisateur
        const entityOfTheUser = entities.filter((entity) => entity.name === session.entity.name);
        // Récupération des réseaux enfants
        const childrenEntities = sortEntitiesChildren(entities, entityOfTheUser[0].id);
        entitiesToMap = [...entityOfTheUser, ...childrenEntities];
    }

    return (
        <FormFields {...props} back="/admin/entity">
            <Title><b>Nouvelle Entit&eacute;</b></Title>
            <GridLayout className="double condensed full-width gray-box">
                <TextField
                    title="Nom"
                    required
                    name="name"
                />
                { requiredInput ? (
                    <SearchableSelectField
                        name="parentId"
                        title="Réseau parent"
                        required={requiredInput}
                        options={[
                            ...flow(
                                filter(({ id: entityId }) => entityId),
                                map(({ id: entityId, name }) => ({ value: entityId, label: name })),
                            )(entitiesToMap),
                        ]}
                    />
                ) : (

                    <SearchableSelectField
                        name="parentId"
                        title="Réseau parent"
                        options={[
                            { value: null, label: '(PAS DE PARENT)' },
                            ...flow(
                                filter(({ id: entityId }) => entityId !== id),
                                map(({ id: entityId, name }) => ({ value: entityId, label: name })),
                            )(entitiesToMap),
                        ]}
                    />
                )}
            </GridLayout>
        </FormFields>
    );
};

FormInner.propTypes = {
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    initialValues: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
    })).isRequired,
    session: PropTypes.shape({
        isAdmin: PropTypes.bool,
        isAdminReseau: PropTypes.bool,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
};

const Form = compose(
    withSession,
    reduxForm({
        form: 'entity-create',
        validate: (props, values) => {
            const { isAdmin } = values.session;
            const validationAdmin = validate([
                checkRequiredField('name'),
            ])(props, values);
            const validationAdminReseau = validate([
                checkRequiredField('name'),
                checkRequiredField('parentId'),
            ])(props, values);

            return isAdmin ? validationAdmin : validationAdminReseau;
        },
    }),
    connect((state) => ({ entities: Object.values(state.data.entity) })),
)(FormInner);

export default Form;
