const getPosition = (el) => {
    let xPosition = 0;
    let yPosition = 0;

    let element = el;

    while (element) {
        if ('BODY' === element.tagName) {
            // deal with browser quirks with body/window/document and page scroll
            const xScrollPos = element.scrollLeft || document.documentElement.scrollLeft;
            const yScrollPos = element.scrollTop || document.documentElement.scrollTop;

            xPosition += ((element.offsetLeft - xScrollPos) + element.clientLeft);
            yPosition += ((element.offsetTop - yScrollPos) + element.clientTop);
        } else {
            xPosition += ((element.offsetLeft - element.scrollLeft) + element.clientLeft);
            yPosition += ((element.offsetTop - element.scrollTop) + element.clientTop);
        }

        element = element.offsetParent;
    }

    return {
        x: xPosition,
        y: yPosition,
    };
};

export default getPosition;
