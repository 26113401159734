import moment from 'moment';

const formatDate = (value) => {
    if (!value) {
        return 'NC';
    }

    const date = value instanceof moment ? value : moment(value);

    if (date.year() === moment().year()) {
        return date.format('DD MMM.');
    }

    return date.format('DD/MM/YYYY');
};

export default formatDate;
