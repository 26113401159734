import React, { Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { AmountItem } from 'common/components/summary';
import Title from 'components/Title';

const getMonthlyCostFor10000 = (rate) => (rate * 10000) / 12;

const getMonthlyCost = (rate, quotity, amount) => (rate * amount * quotity) / 12;

const ADIResults = ({
    amount,
    quotity1,
    quotity2,
    rate1,
    rate2,
}) => {
    const monthlyCost1 = getMonthlyCost(rate1, quotity1, amount);
    const monthlyCost2 = getMonthlyCost(rate2, quotity2, amount);
    const totalCost = monthlyCost1 + monthlyCost2;

    return (
        <Fragment>
            <Title><b>Résultats</b></Title>
            <div className="summary-body col3">
                <dl>
                    <AmountItem
                        title="Coût /10000€ assuré 1"
                        value={getMonthlyCostFor10000(rate1)}
                    />
                    <AmountItem
                        title="Coût /10000€ assuré 2"
                        value={getMonthlyCostFor10000(rate2)}
                    />
                </dl>
            </div>
            <div className="summary-body col3">
                <dl>
                    <AmountItem
                        title="Cotisation mensuelle assuré 1"
                        value={monthlyCost1}
                    />
                    <AmountItem
                        title="Cotisation mensuelle assuré 2"
                        value={monthlyCost2}
                    />
                    <AmountItem
                        title="Cotisation mensuelle totale"
                        value={totalCost}
                    />
                </dl>
            </div>
        </Fragment>
    );
};

ADIResults.propTypes = {
    amount: PropTypes.number,
    quotity1: PropTypes.number,
    quotity2: PropTypes.number,
    rate1: PropTypes.number,
    rate2: PropTypes.number,
};

export default compose(
    formValues({
        quotity1: 'quotity1',
        quotity2: 'quotity2',
        rate1: 'rate1',
        rate2: 'rate2',
        amount: 'amount',
    }),
    mapProps(({
        quotity1,
        quotity2,
        rate1,
        rate2,
        amount,
    }) => ({
        quotity1: parseFloat(quotity1 || '0'),
        quotity2: parseFloat(quotity2 || '0'),
        rate1: parseFloat(rate1 || '0'),
        rate2: parseFloat(rate2 || '0'),
        amount: amount || 0,
    })),
)(ADIResults);
