import React from 'react';
import classNames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import { withState } from 'recompose';
import { flowRight } from 'lodash/fp';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { POST } from '../../utils/httpMethods';
import PublicLayout from '../PublicLayout';
import withInstanceSettings from '../sessions/withInstanceSettings';

const PasswordReset = ({
    handleSubmit,
    loginError,
    submitSucceeded,
    instanceSettings: { emailError },
}) => {
    const invalidEmail = 'email' === loginError;
    const isDisconnected = 'connection' === loginError;

    if (submitSucceeded) {
        return (
            <PublicLayout>
                <h3>Un mail a été envoyé à votre adresse.</h3>
                <p>
                    Suivez les instructions contenues dans le mail pour réinitialiser votre mot de passe.
                </p>
            </PublicLayout>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <PublicLayout
                className={classNames({ 'has-error': invalidEmail })}
                floatingContent={(
                    <Link to="/">
                        <span>Revenir au login</span>
                    </Link>
                )}
                buttonText="Envoyer"
                buttonProps={{ type: 'submit' }}
            >
                <label htmlFor="email">
                    <h5>Saisissez votre Email pour recevoir un lien de réinitialisation</h5>
                    <Field
                        component="input"
                        type="text"
                        name="email"
                        placeholder="Saisissez votre Email"
                    />
                    {invalidEmail ? (
                        <p className="error">
                            Email incorrect merci de vérifier vos informations de connexion.
                        </p>
                    ) : null}
                    {isDisconnected ? (
                        <p className="error">
                            Vous ne pouvez pas vous connecter si vous n&apos;êtes pas connecté à internet
                        </p>
                    ) : null}
                </label>
                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={(
                        <Popover className="form-field-info-popover" id="forgot-password-popover">
                            <p>Contactez par email &agrave;&nbsp;:</p>
                            <p className="text-center"><b>{emailError}</b></p>
                        </Popover>
                    )}
                >
                    <p className="with-pointer">Un problème avec votre adresse ?</p>
                </OverlayTrigger>
            </PublicLayout>
        </form>
    );
};

PasswordReset.propTypes = {
    loginError: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitSucceeded: PropTypes.bool,
    instanceSettings: PropTypes.shape({
        emailError: PropTypes.string,
    }),
};

PasswordReset.defaultProps = {
    loginError: null,
    submitSucceeded: null,
    instanceSettings: {},
};

export default flowRight([
    withState('loginError', 'setLoginError', null),
    withInstanceSettings,
    reduxForm({
        form: 'passwordReset',
        onSubmit: (values) => POST('/api/reset', {
            body: values,
        }),
        onSubmitFail: (errors, dispatch, response, { setLoginError }) => {
            if (errors) {
                // failure on validation
                return;
            }

            if (401 === response.status) {
                setLoginError('email');
            } else if (undefined === response.status) {
                setLoginError('connection');
            }
        },
    }),
])(PasswordReset);
