import { flowRight, get, upperFirst } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment, createElement } from 'react';
import { branch } from 'recompose';
import { formValues } from 'redux-form';
import { Layout } from 'components/layouts';
import dataForm from 'components/dataForm';
import { FileField, TextField, EditableField } from 'components/form/field';
import HorizontalRadioButton from 'components/form/HorizontalRadioButton';
import RadioGroup from 'components/form/RadioGroup';
import { GridLayout, VerticalLayout } from 'components/form/layout';
import Title from 'components/form/Title';
import FormGroup from 'components/form/Group';
import hasPartner from 'common/utils/hasPartner';
import dataProvider from '../dataProvider';
import FormButtons from '../FormButtons';
import formValidate from './formValidate';
import mapPrefixToProps from './mapPrefixToProps';

import './Documents.scss';

const Documents = ({ handleSubmit, params: { id }, prefix, rip, signatory }) => {
    const uploader = {
        options: {
            core: {
                multiple: false,
                debug: true,
            },
            camera: {
                ios: true,
            },
            deleteFile: {
                enabled: true,
                endpoint: `/api/signatures/${id}/upload`,
            },
            request: {
                endpoint: `/api/signatures/${id}/upload`,
            },
            session: {
                endpoint: `/api/signatures/${id}/upload`,
            },
            retry: {
                enableAuto: true,
            },
            validation: {
                acceptFiles: 'image/jpeg,image/png,application/pdf',
                allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
                itemLimit: 1,
                sizeLimit: 5242880,
            },
        },
    };

    const withPartner = rip && hasPartner(rip.familySituation);

    let signatoryFields = [
        {
            name: 'lastName',
            title: 'Nom',
        }, {
            name: 'firstName',
            title: 'Prénom',
        }, {
            name: 'personalEmail',
            title: 'Email',
        }, {
            name: 'mobile',
            title: 'Téléphone mobile',
        },
    ];

    if (rip) {
        const getPersonalField = (field) => get('partner' === signatory
            ? `partner${upperFirst(field)}`
            : field);

        signatoryFields = signatoryFields.map(({ name, ...values }) => ({
            ...values,
            name,
            editName: `editable${upperFirst(name)}`,
            defaultValue: getPersonalField(name)(rip),
            type: 'text',
        }));
    }

    return (
        <Layout hasFooter>
            <Fragment>
                <form onSubmit={handleSubmit} id="main-form">
                    <div className="container">
                        <VerticalLayout>
                            <TextField name="name" title="Nom de la signature" />
                            {withPartner ? (
                                <Fragment>
                                    <Title title="Qui doit signer le document ?" />
                                    <RadioGroup name="data.signatory" className="select-signatory">
                                        <HorizontalRadioButton key="client" title={rip.firstName} value="client" />
                                        <HorizontalRadioButton
                                            key="partner"
                                            title={rip.partnerFirstName}
                                            value="partner"
                                        />
                                    </RadioGroup>
                                </Fragment>
                            ) : null}
                        </VerticalLayout>
                        <FormGroup>
                            <Title title="Informations du signataire" />
                            <GridLayout>
                                {signatoryFields.map(({ name, title, editName, defaultValue, type }) => (
                                    createElement((rip ? EditableField : TextField), {
                                        name: `data.${name}`,
                                        title,
                                        editName: editName ? `data.${editName}` : null,
                                        defaultValue,
                                        type,
                                    })
                                ))}
                            </GridLayout>
                        </FormGroup>
                        <VerticalLayout>
                            <Title title="Télécharger vos fichiers" />
                            <FileField
                                icon="file-pdf-o"
                                name="freeClauseDocument"
                                uploader={uploader}
                                uploadText="Cliquer pour téléverser votre pdf"
                            />
                        </VerticalLayout>
                    </div>
                    <FormButtons back={`${prefix}/signatures`} list={`${prefix}/signatures`} />
                </form>
            </Fragment>
        </Layout>
    );
};

Documents.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    prefix: PropTypes.string.isRequired,
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
        firstName: PropTypes.string,
        partnerFirstName: PropTypes.string,
    }),
    signatory: PropTypes.string.isRequired,
};

export default flowRight([
    mapPrefixToProps,
    branch(
        ({ params: { ripId } }) => !!ripId,
        dataProvider({
            id: ({ params: { ripId } }) => ripId,
            type: 'rip',
            wait: true,
        }),
    ),
    dataForm({
        form: 'signature-property',
        id: ({ params: { id } }) => id,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id },
            prefix,
        }) => navigate(`${prefix}/signatures/${id}/edition`),
        type: 'signature',
        validate: formValidate,
    }),
    formValues({
        signatory: 'data.signatory',
    }),
])(Documents);
