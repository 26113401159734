const choices = {
    legalCommunity: 'Communauté légale',
    universalCommunity: 'Communauté universelle',
    separationAsToProperty: 'Séparation de biens',
    acquiredProperty: 'Participation aux acquêts',
    joint: 'Indivision',
    separationOfEstate: 'Séparation de patrimoine',
};

export default choices;
