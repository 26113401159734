import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { GridLayout } from 'components/form/layout';
import Dropdown from 'components/Dropdown';
import { PercentageFloatField, TextField, IntegerField } from 'components/form/field';
import sortSettings from 'common/utils/sortSettings';
import { DELETE } from 'utils/httpMethods';
import SupportRepartition from '../lifeInsurance/SupportRepartition/SupportRepartition';
import FormGroup from '../../../../form/Group';
import RadioGroup from '../../../../form/RadioGroup';
import HorizontalRadioButton from '../../../../form/HorizontalRadioButton';

const PerDropdown = ({ deleted, baseProducts, name, type }) => {
    const deleteSetting = useCallback(async (e) => {
        e.preventDefault();
        if (window.confirm('Supprimer le contrat ?')) {
            await DELETE(`/api/settings/simulation.per.contracts.${type}`);
            window.location.reload(true);
        }
    }, []);

    return (
        <Dropdown title={`${deleted ? '(supprimé) ' : ''}${name}`} key={type}>
            <GridLayout className="double condensed full-width">
                <TextField
                    name={`simulation.per.contracts.${type}.name`}
                    title="Nom"
                />
                <PercentageFloatField
                    name={`simulation.per.contracts.${type}.fees.min`}
                    title="Frais d'entrée min"
                />
                <PercentageFloatField
                    name={`simulation.per.contracts.${type}.fees.max`}
                    title="Frais d'entrée max"
                />
                <IntegerField
                    name={`simulation.per.contracts.${type}.miniInitialFunding`}
                    title="Minimum versement initial"
                />
                <IntegerField
                    name={`simulation.per.contracts.${type}.miniScheduledPaymentAmount`}
                    title="Minimum versement programmé"
                />
                <TextField
                    name={`simulation.per.contracts.${type}.codeApporteur`}
                    title="Code apporteur"
                />
            </GridLayout>
            {['P', 'E', 'D'].map((profile) => (
                <GridLayout className="condensed full-width">
                    <SupportRepartition
                        baseProducts={baseProducts}
                        baseName={`simulation.per.contracts.${type}.`}
                        profile={profile}
                    />
                </GridLayout>
            ))}
            <FormGroup title="Souscription">
                <RadioGroup name={`simulation.per.contracts.${type}.subscription`} className="subscription-value">
                    <HorizontalRadioButton title="CARDIF" value="cardif" />
                    <HorizontalRadioButton title="MMA" value="mma" />
                </RadioGroup>
            </FormGroup>
            <hr />
            <button className="theseis-btn" onClick={deleteSetting}>Supprimer le contrat</button>
        </Dropdown>
    );
};

PerDropdown.propTypes = {
    baseProducts: PropTypes.shape({}).isRequired,
    deleted: PropTypes.bool,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

const PerContract = ({ baseProducts, types }) => sortSettings(types)
    .map(([type, contract]) => (
        <PerDropdown
            baseProducts={baseProducts}
            type={type}
            key={type}
            {...contract}
        />
    ));

PerContract.propTypes = {
    baseProducts: PropTypes.shape({}).isRequired,
    types: PropTypes.shape({}).isRequired,
};

export default PerContract;
