import PropTypes from 'prop-types';
import React from 'react';
import FormButtons from '../FormButtons';
import NextButton from '../FormButtons/buttons/NextButton';
import withActiveListUrl from './withActiveListUrl';

const FooterInner = ({
    back,
    last,
    list,
    nextRedirectTo,
    nextButtonContent,
    nextButtonComponent,
    showNav,
    showPrev,
    hideBackButton,
    shoyo,
    usersIds,
    session,
    isHiddenButtonForTheseis,
    enveloppeESigneCreated,
}) => (
    <FormButtons
        back={back}
        last={last}
        list={list}
        nextRedirectTo={nextRedirectTo}
        nextButtonContent={nextButtonContent}
        nextButtonComponent={nextButtonComponent}
        showNav={showNav}
        showPrev={showPrev}
        hideBackButton={hideBackButton}
        shoyo={shoyo}
        usersIds={usersIds}
        session={session}
        isHiddenButton={isHiddenButtonForTheseis}
        enveloppeESigneCreated={enveloppeESigneCreated}
    />
);

FooterInner.propTypes = {
    back: PropTypes.string,
    last: PropTypes.bool,
    list: PropTypes.string.isRequired,
    nextRedirectTo: PropTypes.string,
    nextButtonContent: PropTypes.string,
    nextButtonComponent: PropTypes.func,
    showNav: PropTypes.bool,
    showPrev: PropTypes.bool,
    hideBackButton: PropTypes.bool,
    shoyo: PropTypes.bool,
    usersIds: PropTypes.shape({}),
    session: PropTypes.shape({}),
    isHiddenButtonForTheseis: PropTypes.bool,
    enveloppeESigneCreated: PropTypes.bool,
};

FooterInner.defaultProps = {
    last: false,
    showNav: true,
    showPrev: true,
    nextButtonComponent: NextButton,
    isHiddenButtonForTheseis: false,
};

const Footer = withActiveListUrl('list')(FooterInner);

export default Footer;
