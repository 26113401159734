const sortSettings = (settings = {}) => Object.entries(settings)
    .sort(([product1], [product2]) => {
        if (product1.toLowerCase() > product2.toLowerCase()) {
            return 1;
        }
        if (product1.toLowerCase() === product2.toLowerCase()) {
            return 0;
        }

        return -1;
    });

export default sortSettings;
