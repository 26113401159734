import { getOr, sumBy } from 'lodash/fp';
import addMonths from '../../../../utils/addMonths';
import diffMonths from './diffMonths';
import computeInstalmentAmount from './instalmentAmount';
import { computeCollateralMonthlyContribution } from './collateralContribution';
import computeCollateralSavings from './collateralSavings';
import computeLeapInterestsAmount from './leapInterestsAmount';

const computeFunding = ({
    product,
    collateralInitialContribution,
    collateralRate,
    deferralType,
    deferralDuration,
    fundingADI,
    fundingDuration,
    fundingPersonalContribution,
    fundingRate,
    fundingType,
    handlingOfLeapInterests,
    leapInterestsAmount: editedLeapInterestsAmount,
    editableLeapInterestsAmount,
    needFunding,
    propertyActDate,
    propertyDeliveryDate,
    data = {},
}, {
    fundingAmount,
    instalmentCosts: { investment, investmentWithTVA, tva },
}) => {
    const fundedMonths = propertyActDate && propertyDeliveryDate
        ? diffMonths(new Date(propertyActDate), new Date(propertyDeliveryDate))
        : 0;

    const computedLeapInterestsAmount = computeLeapInterestsAmount(fundingAmount, fundingRate, fundedMonths);

    const leapInterestsAmount = editableLeapInterestsAmount ? editedLeapInterestsAmount : computedLeapInterestsAmount;
    const instalments = [];

    let fundingEndYear = propertyActDate.getFullYear();

    let isTvaPromoter = false;
    if ('lmnp' === product) {
        const { funding } = data;
        isTvaPromoter = 'promoter' === funding.tvaFunder;
    }

    if (!needFunding) {
        return {
            funding: {
                fundingMode: 'none',
                instalmentAmount: 0,
                instalments,
                leapInterestsProvision: 0,
                leapInterestsAmount,
                monthlyAdi: 0,
                monthlyRate: 0,
                collateralMonthlyContribution: 0,
                collateralSavings: [],
                personalContribution: isTvaPromoter ? investment : investmentWithTVA,
                duration: 0,
                fundingEndYear,
            },
        };
    }

    let fundingEndMonth = 0;
    let personalContribution = fundingPersonalContribution || 0;
    if ('in-fine' === fundingType) {
        personalContribution += (collateralInitialContribution || 0);
    }

    // Calculs de constantes sur le prêt.
    const leapInterestsDuration = diffMonths(propertyActDate, propertyDeliveryDate);
    const monthlyRate = fundingRate / 12;
    let remaining = fundingAmount;

    // TVA incluse dans le prêt pour la lmnp
    const tvaFunder = getOr('loan', 'funding.tvaFunder', data);
    const repaymentTvaStart = ('lmnp' === product && 'loan' === tvaFunder)
        ? addMonths(propertyDeliveryDate, 12)
        : null;

    // Calcul du montant total du prêt en fonction du différé : nécessaire pour calculer l'ADI.
    let totalFundingAmount = fundingAmount;
    // On commence par déterminer le nombre de mois pendant lequel on ne paye pas les intérêts.
    let addInterestsToFundingAmountDuration = 0;

    // intérêts intercalaires financé dans l'emprunt : les intérêts s'ajoutent.
    if ('financed' === handlingOfLeapInterests) {
        totalFundingAmount += leapInterestsAmount;
    }
    // Différé total : les intérêts s'ajoutent.
    if ('depreciable' === fundingType && 'total' === deferralType) {
        addInterestsToFundingAmountDuration += deferralDuration;
    }

    totalFundingAmount *= (1 + monthlyRate) ** addInterestsToFundingAmountDuration;

    let monthlyAdi = totalFundingAmount * (fundingADI / 10000);

    // Constantes de remboursement
    let repaymentDuration = fundingDuration;
    let repaymentStart = propertyDeliveryDate;

    if ('depreciable' === fundingType && ['partial', 'total'].includes(deferralType)) {
        repaymentDuration -= deferralDuration;
        repaymentStart = addMonths(repaymentStart, deferralDuration);
    }

    // addEarlyRepayment permet d'effectuer remboursement anticipé partiel.
    // Date : date du remboursement
    // amount : montant du remboursement
    const addEarlyRepayment = (date, amount) => {
        remaining -= amount;
        monthlyAdi = remaining * (fundingADI / 10000);

        instalments.push({
            year: date.getFullYear(),
            month: date.getMonth(),
            amount,
            remaining,
            capital: 0,
            interests: 0,
            adi: 0,
        });
    };

    // addInstallments permet de rajouter un versement mensuel sur une période donnée.
    // start : date de début du versement.
    // duration : durée en mois du versement.
    // amount : montant à payer chaque mois.
    // noInterests : booléen qui indique si on paye des intérêts ou non.

    const addInstallments = (start, duration, amount, noInterests = false) => {
        const startYear = start.getFullYear();
        const startMonth = start.getMonth();

        for (let i = 0; i < duration; i++) {
            const year = startYear + Math.floor((startMonth + i) / 12);
            const month = (startMonth + i) % 12;

            const adi = monthlyAdi;
            const interests = remaining * monthlyRate;
            const capital = amount - interests - adi;

            remaining -= capital;

            instalments.push({
                year,
                month,
                amount,
                remaining,
                capital: Math.max(capital, 0),
                interests: noInterests ? 0 : interests,
                adi,
            });

            fundingEndYear = year;
            fundingEndMonth = month;
        }
    };

    // computeDeferral ajoute un différé en fonction de son type.
    // start : début du différé
    // duration : durée du différé
    const computeDeferral = (start, duration) => {
        // Différé : le différé prend en compte la période après livraison.
        if ('depreciable' === fundingType) {
            // Lors d'un différé partiel on paye les intérêts et les ADI mais pas le capital.
            if ('partial' === deferralType) {
                addInstallments(start, duration, (remaining * monthlyRate) + monthlyAdi);
            }

            // Lors d'un différé total on ne paye que les ADI.
            if ('total' === deferralType) {
                const noInterests = true;
                addInstallments(start, duration, monthlyAdi, noInterests);
            }
        }
    };

    // Lorsque les intérêts intercalaires sont inclus dans le financement
    // Ou lorsqu'on est en différé, on paye toujours de l'ADI.
    // Seuls les intérêts et le capital peuvent être différés.

    // Fonction permettant d'étaler les intérêts intercalaires sur la période spécifiée.
    // Si leapInterests = true, on ne paye pas d'intérêts (donc que de l'ADI).
    // Si leapInterests = false, on paye intérêts + ADI.
    const addLeapInterests = (start, duration, amount, leapInterests = false) => {
        const startYear = start.getFullYear();
        const startMonth = start.getMonth();

        for (let i = 0; i < duration; i++) {
            const year = startYear + Math.floor((startMonth + i) / 12);
            const month = (startMonth + i) % 12;

            remaining -= leapInterests ? -amount : 0;

            instalments.push({
                year,
                month,
                amount: leapInterests ? monthlyAdi : monthlyAdi + amount,
                remaining,
                capital: 0,
                interests: leapInterests ? 0 : amount,
                adi: monthlyAdi,
            });

            fundingEndYear = year;
            fundingEndMonth = month;
        }
    };
    // Intérêts intercalaires : période entre l'acte et la livraison.
    const monthlyLeapInterests = leapInterestsDuration ? leapInterestsAmount / leapInterestsDuration : 0;

    if ('budgeted' === handlingOfLeapInterests) {
        addLeapInterests(propertyActDate, leapInterestsDuration, monthlyLeapInterests);
    }

    // Si intérêts intercalaires inclus au financement alors on ne paye que les ADI.
    if ('financed' === handlingOfLeapInterests) {
        const leapInterests = true;
        addLeapInterests(propertyActDate, leapInterestsDuration, monthlyLeapInterests, leapInterests);
    }

    const adiProvision = sumBy('adi', instalments);

    let instalmentAmount;
    let instalmentAmountAfterTvaRepayment;
    if (repaymentTvaStart && deferralDuration > 12 && ['partial', 'total'].includes(deferralType)) {
        computeDeferral(propertyDeliveryDate, 12);

        addEarlyRepayment(repaymentTvaStart, tva);

        computeDeferral(repaymentTvaStart, deferralDuration - 12);

        instalmentAmount = computeInstalmentAmount(
            fundingType,
            remaining,
            monthlyRate,
            repaymentDuration,
        ) + monthlyAdi;

        addInstallments(repaymentStart, repaymentDuration, instalmentAmount);
    } else {
        computeDeferral(propertyDeliveryDate, deferralDuration);

        instalmentAmount = computeInstalmentAmount(
            fundingType,
            remaining,
            monthlyRate,
            repaymentDuration,
        ) + monthlyAdi;

        if (repaymentTvaStart && (repaymentDuration + deferralDuration) > 12) {
            addInstallments(repaymentStart, 12 - deferralDuration, instalmentAmount);

            addEarlyRepayment(repaymentTvaStart, tva);

            repaymentDuration += deferralDuration - 12;

            instalmentAmountAfterTvaRepayment = computeInstalmentAmount(
                fundingType,
                remaining,
                monthlyRate,
                repaymentDuration,
            ) + monthlyAdi;

            addInstallments(repaymentTvaStart, repaymentDuration, instalmentAmountAfterTvaRepayment);
        } else {
            addInstallments(repaymentStart, repaymentDuration, instalmentAmount);
        }
    }

    // Apport nanti mensuel
    const collateralMonthlyContribution = 'in-fine' === fundingType
        ? computeCollateralMonthlyContribution(
            remaining,
            collateralRate / 12,
            leapInterestsDuration + fundingDuration,
            collateralInitialContribution,
        ) : 0;

    // Placement in fine
    const collateralSavings = computeCollateralSavings(
        fundingType,
        propertyActDate,
        propertyDeliveryDate,
        fundingDuration,
        collateralRate,
        collateralMonthlyContribution,
        collateralInitialContribution,
    );

    let duration = fundingDuration + leapInterestsDuration;
    const monthsCountLastYear = addMonths(propertyDeliveryDate, fundingDuration).getMonth();
    duration += monthsCountLastYear ? 12 - monthsCountLastYear : 0;

    fundingEndYear += fundingEndMonth ? 1 : 0;

    return {
        funding: {
            fundingMode: fundingType,
            instalmentAmount,
            instalmentAmountAfterTvaRepayment,
            instalments,
            leapInterestsAmount,
            leapInterestsProvision: leapInterestsAmount,
            adiProvision,
            monthlyAdi,
            monthlyRate,
            collateralMonthlyContribution,
            collateralSavings,
            personalContribution,
            duration,
            amountToCapitalize: remaining,
            fundingEndYear,
        },
    };
};

export default computeFunding;
