import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { mapProps } from 'recompose';
import { formValues } from 'redux-form';
import { TextField, DateField, InputField, IntegerField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import hasPartner from '../../../common/utils/hasPartner';
import HorizontalRadioButton from '../../form/HorizontalRadioButton';
import RadioGroup from '../../form/RadioGroup';
import SeeMore, { withSeeMore } from '../SeeMore';

const RetirementInner = ({
    name,
    firstName,
    isTotalEnough,
    hasRetirementSupplementGoal,
    partner,
    partnerFirstName,
    seeMore,
}) => (
    <div>
        <HorizontalLayout>
            {!isTotalEnough ? <IntegerField name={`${name}.totalShortage`} title="Montant manquant" /> : null}
            <DateField name={`${name}.summaryDeadline`} title="Horizon" />
            <SeeMore name={name} />
        </HorizontalLayout>
        {seeMore ? (
            <div>
                <HorizontalLayout>
                    <TextField title="Specifier" name={`${name}.specify`} />
                    <InputField
                        name={`${name}.activityStartYear`}
                        title={`${firstName} : Année début d'activité`}
                        type="number"
                    />
                    <InputField
                        name={`${name}.legalYear`}
                        title={`${firstName} : Année légale (taux plein)`}
                        type="number"
                    />
                    <InputField
                        name={`${name}.activityEndYear`}
                        title={`${firstName} : Année arrêt activité souhaitée`}
                        type="number"
                    />
                    <InputField
                        name={`${name}.monthlyAmount`}
                        title={`${firstName} : Montant mensuel à percevoir`}
                        type="number"
                    />
                    <InputField
                        name={`${name}.reversion`}
                        title={`${firstName} : Reversion`}
                        type="number"
                    />
                </HorizontalLayout>
                <HorizontalLayout>
                    {partner ? (
                        <InputField
                            name={`${name}.partnerActivityStartYear`}
                            title={`${partnerFirstName || 'Partenaire'} : Année début d'activité`}
                            type="number"
                        />
                    ) : null}
                    {partner ? (
                        <InputField
                            name={`${name}.partnerLegalYear`}
                            title={`${partnerFirstName || 'Partenaire'} : Année légale (taux plein)`}
                            type="number"
                        />
                    ) : null}
                    {partner ? (
                        <InputField
                            name={`${name}.partnerActivityEndYear`}
                            title={`${partnerFirstName || 'Partenaire'} : Année arrêt activité souhaitée`}
                            type="number"
                        />
                    ) : null}
                    {partner ? (
                        <InputField
                            name={`${name}.partnerMonthlyAmount`}
                            title={`${partnerFirstName || 'Partenaire'} : Montant mensuel à percevoir`}
                            type="number"
                        />
                    ) : null}
                    {partner ? (
                        <InputField
                            name={`${name}.partnerReversion`}
                            title={`${partnerFirstName || 'Partenaire'} : Reversion`}
                            type="number"
                        />
                    ) : null}
                </HorizontalLayout>
                <HorizontalLayout>
                    <InputField name={`${name}.totalFamily`} title="Total famille" type="number" double />
                </HorizontalLayout>
                <HorizontalLayout>
                    <InputField
                        name={`${name}.isTotalEnough`}
                        title="Le total est-il suffisant&nbsp;?"
                        type="checkbox"
                    />
                    <InputField
                        name={`${name}.totalFunding`}
                        title="Comment le financez-vous&nbsp;?"
                        type="text"
                        double
                    />
                </HorizontalLayout>
                <HorizontalLayout>
                    <RadioGroup name={`${name}.goal`} className="form-group-horizontal double">
                        <HorizontalRadioButton title="Arrêter l'activité plus tôt" value="stop-sooner" />
                        <HorizontalRadioButton title="Créer un complément de retraite" value="retirement-supplement" />
                    </RadioGroup>
                    {hasRetirementSupplementGoal ? (
                        <InputField name={`${name}.retirementShortage`} title="Montant du complément" type="number" />
                    ) : null}
                </HorizontalLayout>
                <HorizontalLayout>
                    <InputField name={`${name}.summary`} title="Synthèse des objectifs : Montant" type="number" />
                </HorizontalLayout>
            </div>
        ) : null}
    </div>
);

RetirementInner.propTypes = {
    firstName: PropTypes.string.isRequired,
    hasRetirementSupplementGoal: PropTypes.bool,
    isTotalEnough: PropTypes.bool,
    name: PropTypes.string.isRequired,
    partner: PropTypes.bool,
    partnerFirstName: PropTypes.string,
    seeMore: PropTypes.bool,
};

RetirementInner.defaultProps = {
    hasRetirementSupplementGoal: false,
    partner: false,
    partnerFirstName: undefined,
    isTotalEnough: undefined,
    seeMore: false,
};

const Retirement = flowRight([
    withSeeMore,
    formValues(({ name }) => ({
        familySituation: 'familySituation',
        partnerFirstName: 'partnerFirstName',
        firstName: 'firstName',
        goal: `${name}.goal`,
        isTotalEnough: `${name}.isTotalEnough`,
    })),
    mapProps(({ familySituation, goal, ...props }) => ({
        ...props,
        partner: hasPartner(familySituation),
        hasRetirementSupplementGoal: 'retirement-supplement' === goal,
    })),
])(RetirementInner);

export default Retirement;
