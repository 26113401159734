const choices = {
    disabled: {
        title: 'Porteur de handicap',
        additionalPart: 0.5,
    },
    singleParent: {
        title: 'Parent isolé',
        additionalPart: 0.5,
    },
    singleHavingRaisedChildMoreThan5Years: {
        title: 'Parent célibataire/veuf/divorcé ayant supporté seul la charge d\'un enfant pendant 5 ans',
        additionalPart: 0.5,
    },
    widowerWithMultiplePersonInCharge: {
        title: 'Veuf avec une ou plusieurs personnes à charge',
    },
    warVeteran: {
        title: 'Ancien combattant',
        additionalPart: 0.5,
    },
};

export default choices;
