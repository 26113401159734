import validate, { checkRequiredField, branchValidate } from 'common/formValidation/lib';

const formValidate = validate([
    branchValidate(
        (errors, values, { rip: { familySituation, dualRip } }) => 'cohabitation' === familySituation && dualRip,
        [checkRequiredField('beneficiary')],
    ),
]);

export default formValidate;
