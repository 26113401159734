import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';

import { InputField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';

const FinancialCIFFieldsInner = ({ currentValue, name }) => {
    switch (currentValue) {
        case 'LODEOMCIF':
            return (
                <GridLayout className="condensed full-width">
                    <div className="double">

                        <InputField name={`${name}.partenaire`} title="Partenaire" type="text" required />

                    </div>
                    {'LODEOMCIF' === currentValue && (
                        <Fragment>
                            <InputField name={`${name}.fatherLastName`} title="Nom du père" type="text" required />
                            <InputField name={`${name}.fatherFirstName`} title="Prénom du père" type="text" required />
                            <InputField
                                name={`${name}.motherBirthLastName`}
                                title="Nom de naissance de la mère"
                                type="text"
                                required
                            />
                            <InputField name={`${name}.motherFirstName`} title="Prénom de la mère" type="text" required />
                        </Fragment>
                    )}

                </GridLayout>
            );
        default:
            return null;
    }
};

FinancialCIFFieldsInner.propTypes = {
    currentValue: PropTypes.string,
    name: PropTypes.string.isRequired,
};

FinancialCIFFieldsInner.defaultProps = {
    currentValue: '',
};

const FinancialFields = formValues(({ name }) => (({
    currentValue: `${name}.type`,
})))(FinancialCIFFieldsInner);

export default FinancialFields;
