import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { DisabledField, IntegerField } from '../form/field';
import { formatAmount } from '../../common/utils/format';
import EditButton from './EditButton';

const EditableField = ({ editable, name, editableName, title, defaultValue }) => (editable ?
    (<IntegerField
        name={name}
        title={<Fragment>{title}<Field name={editableName} title="Éditer" component={EditButton} /></Fragment>}
        min={0}
    />) :
    (<DisabledField
        title={<Fragment>{title}<Field name={editableName} title="Éditer" component={EditButton} /></Fragment>}
        name={name}
        value={formatAmount(defaultValue)}
    />)
);

EditableField.propTypes = {
    editable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    editableName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    defaultValue: PropTypes.number.isRequired,
};

EditableField.defaultProps = {
    editable: false,
};

export default EditableField;
