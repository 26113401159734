import { omit, get } from 'lodash/fp';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setLogoutFunction } from 'utils/handleError';
import PermissionManager from 'utils/PermissionManager';
import { setSettings } from 'common/utils/settings';
import handleErrorsMiddleware from '../../middlewares/handleErrors';
import handleVersion from '../../middlewares/handleVersion';
import { GET } from '../../utils/httpMethods';
import { Provider } from './context';

export default class SessionProvider extends Component {
    constructor(props) {
        super(props);

        // get the initial data from the local storage
        let initialData = localStorage.getItem('userData') || null;

        if (initialData) {
            initialData = JSON.parse(initialData);
        }

        // we may have nothing there, in such case, we fallback on null
        this.state = {
            session: initialData,
            permissionManager: initialData ? new PermissionManager(initialData) : null,
        };

        this.updateSession = this.updateSession.bind(this);
        this.bindStore = this.bindStore.bind(this);
        this.sync = this.sync.bind(this);
        this.syncInstanceSettings = this.syncInstanceSettings.bind(this);

        // temporary hack to keep fetch methods as globals
        setLogoutFunction(this.updateSession);

        // update settings
        this.updateSettings();

        // we need the store to synchronize (required by handleVersion)
        this.store = null;
    }

    updateSession(data) {
        // safe guard to never keep the update method and the permission manager in data
        const cleanedData = omit(['update', 'permissions'], data);

        if (null === data) {
            // there's no session anymore, remove it from the local storage
            localStorage.removeItem('userData');
        } else {
            // update the session data in the local storage
            localStorage.setItem('userData', JSON.stringify(cleanedData));
        }

        // and update the react context to trigger a re-render
        this.setState({
            session: cleanedData,
            permissionManager: new PermissionManager(cleanedData),
        });

        // update settings
        this.updateSettings();

        return cleanedData;
    }

    updateSettings() {
        // update settings save in the singleton
        setSettings(get('appSettings', this.state.session));
    }

    bindStore(store) {
        this.store = store;
    }

    sync() {
        return GET('/api/users/sync')
            .then(handleVersion(this.store))
            .then((res) => res.json())
            .then((data) => this.updateSession(data))
            .catch(handleErrorsMiddleware);
    }

    // Sync anonymous instance settings
    syncInstanceSettings() {
        return GET('/api/syncInstanceSettings')
            .then(handleVersion(this.store))
            .then((res) => res.json())
            .then((data) => this.updateSession(data))
            .catch(handleErrorsMiddleware);
    }

    render() {
        const { children } = this.props;
        const { session, permissionManager } = this.state;
        const { updateSession: update, bindStore, sync, syncInstanceSettings } = this;

        const contextValue = {
            ...session,
            update,
            bindStore,
            sync,
            syncInstanceSettings,
            permissions: permissionManager,
        };

        return (
            <Provider value={contextValue}>
                {children(contextValue, session ? session.id : 'none')}
            </Provider>
        );
    }
}

SessionProvider.propTypes = {
    children: PropTypes.func.isRequired,
};
