const choices = {
    '01': 'CHEF ETAT-GOUV MEMBRE GOUV-CE',
    '02': 'MEMBRE PARLEMENT NAT.OU EUROPEEN',
    '03': 'MEMBRE HAUTE JURIDICTION',
    '04': 'MEMBRE COUR DES COMPTES',
    '05': 'DIRECTEUR BANQUE CENTRALE',
    '06': 'AMBASSADEUR CHARGE AFF.CONSUL',
    '07': 'OFFICIER CHARGE COMMANDEMENT',
    '08': 'MEMBRE DIRECTION ENTREPRISE PUBL',
    '09': 'DIRIGEANT INSTITUT.INTERNAT',
};

export default choices;
