import { set, get, omit, countBy, min, map, flow } from 'lodash/fp';
import { alimonyTypes } from '../../choices';
import hasPartner from '../../utils/hasPartner';
import shouldDisplayAlternateCustodyChildren from '../../utils/alternateCustody';
import validate, {
    checkDateDependencies,
    checkIsDate,
    checkRequiredField,
    checkRequiredIntegerField,
    checkRequiredOwnerField,
    checkChoice,
    checkIntegerField,
    branchValidate,
    validateCollection,
} from '../lib';
import getSettings from '../../utils/settings';
import checkCspField from '../lib/checkCspField';

const checkMicroEntrMaxIncome = (maxIncome) => checkIntegerField(
    (income) => income <= maxIncome,
    'Les revenus saisis ne permettent pas de bénéficier du statut de micro-entrepreneur',
);

const getMicroEntrValidators = (name, element) => {
    const parametreDePlafondMicroBIC = getSettings('summary.incomeTax.microBICPlafond');

    return [
        ...'trade' === get('activity', element) ?
            [checkMicroEntrMaxIncome(parametreDePlafondMicroBIC.commerce)(`${name}.result`)] :
            [],
        ...'services' === get('activity', element) || 'BNC' === get('type', element) ?
            [checkMicroEntrMaxIncome(parametreDePlafondMicroBIC.services)(`${name}.result`)] :
            [],
    ];
};

const validateIncomes = (name, element, values) => {
    const basicIncomeValidators = [
        checkRequiredOwnerField(`${name}.beneficiary`, values),
        checkRequiredIntegerField(`${name}.result`),
        checkRequiredIntegerField(`${name}.regime`),
    ];

    switch (get('type', element)) {
        case 'salary':
            return [
                checkRequiredIntegerField(`${name}.income`),
                ...(get('realExpenses', element) ? [
                    checkRequiredIntegerField(`${name}.realExpensesValue`),
                ] : []),
            ];
        case 'BNC':
        case 'BA':
            return [
                ...basicIncomeValidators,
                ...('micro' === get('regime', element) ?
                    getMicroEntrValidators(name, element) : []),
            ];
        case 'BIC':
            return [
                checkRequiredField(`${name}.activity`),
                ...basicIncomeValidators,
                ...('micro' === get('regime', element) ?
                    getMicroEntrValidators(name, element) : []),
            ];
        case 'landRentalIncome':
        case 'furnishedRentalIncome':
            return [
                checkRequiredIntegerField(`${name}.income`),
                ...('micro' !== get('regime', element) ?
                    basicIncomeValidators : [
                        checkRequiredOwnerField(`${name}.beneficiary`, values),
                        checkRequiredIntegerField(`${name}.regime`),
                    ]),
            ];
        case 'dividends':
        case 'exemptedIncome':
            return [
                checkRequiredIntegerField(`${name}.income`),
                checkRequiredOwnerField(`${name}.beneficiary`, values),
            ];
        case 'pension':
            return [
                checkRequiredOwnerField(`${name}.beneficiary`, values),
                checkRequiredIntegerField(`${name}.pension`),
            ];
        case 'other':
            return [
                checkRequiredIntegerField(`${name}.income`),
                checkRequiredOwnerField(`${name}.beneficiary`, values),
                checkRequiredField(`${name}.description`),
            ];
        case 'childrenIncome':
            return [
                checkRequiredField(`${name}.child`),
            ];
        default:
            return [];
    }
};

const validateTaxEvents = (name, element) => {
    const basicTaxEventValidators = [
        checkRequiredField(`${name}.income`),
        checkRequiredField(`${name}.date`),
        checkIsDate(`${name}.date`),
        checkDateDependencies(
            `${name}.date`,
            'createdAt',
            'La date doit être postérieure à la date de création du RIP',
        ),
    ];

    switch (get('type', element)) {
        case 'bonus':
            return [
                checkRequiredField(`${name}.amount`),
                ...basicTaxEventValidators,
            ];
        case 'salaryIncrease':
            return basicTaxEventValidators;
        case 'salaryEvolution':
            return [
                checkRequiredField(`${name}.income`),
                checkRequiredField(`${name}.percentage`),
                checkRequiredField(`${name}.recurrentEvent`),
            ];
        case 'goingRetired':
            return [
                checkRequiredField(`${name}.endingIncomes`),
                branchValidate((errors, { familySituation }) => hasPartner(familySituation), [
                    checkRequiredField(`${name}.beneficiary`),
                ]),
            ];
        default:
            return [];
    }
};

const validateAgainstBirthDatesError = 'La date de jugement doit être postérieure à la date de naissance d\'un des enfants';

const validateAgainstBirthDates = (field, childrenField) => (errors, values) => {
    if (errors[field]) {
        return errors;
    }

    const date = get(field, values);
    const minBirthDate = flow([
        get(childrenField),
        map(get('birthDate')),
        min,
    ])(values);

    return (minBirthDate && date < minBirthDate)
        ? set(field, validateAgainstBirthDatesError, errors)
        : errors;
};

const validateDeductions = (name, element) => [
    branchValidate((errors, { familySituation }) => 'cohabitation' === familySituation, [
        checkRequiredOwnerField(`${name}.fiscalHome`),
    ]),
    ...(() => {
        switch (get('type', element)) {
            case 'alimony': {
                const adultChildren = get('adultChildren', element);
                const alimonyType = get('alimonyType', element);

                return [
                    checkRequiredField(`${name}.childrenCount`),
                    checkRequiredField(`${name}.alimonyType`),
                    checkChoice(
                        `${name}.alimonyType`,
                        adultChildren ? alimonyTypes : omit(['sameRoof'], alimonyTypes),
                    ),
                    checkRequiredField(`${name}.adultChildren`),
                    ...(!adultChildren || 'sameRoof' !== alimonyType
                        ? [checkRequiredField(`${name}.amount`)]
                        : []),
                    ...('withVerdict' === alimonyType ? [
                        checkRequiredField(`${name}.date`),
                        checkIsDate(`${name}.date`),
                        validateAgainstBirthDates(
                            `${name}.date`,
                            'children',
                        ),
                    ] : []),
                ];
            }
            case 'retirement':
                return [
                    checkRequiredField(`${name}.amount`),
                ];
            case 'other':
                return [
                    checkRequiredField(`${name}.amount`),
                    checkRequiredField(`${name}.description`),
                ];
            default:
                return [];
        }
    })(),
];

const validateReductions = (name, element) => [
    branchValidate((errors, { familySituation }) => 'cohabitation' === familySituation, [
        checkRequiredOwnerField(`${name}.fiscalHome`),
    ]),
    ...(() => {
        switch (get('type', element)) {
            case 'investment':
                return [
                    checkRequiredField(`${name}.investment`),
                    checkRequiredField(`${name}.amount`),
                ];
            case 'donation':
                return [
                    checkRequiredField(`${name}.amount`),
                    checkRequiredField(`${name}.percentage`),
                ];
            case 'subscription':
                return [
                    checkRequiredField(`${name}.amount`),
                ];
            case 'education':
                return [
                    checkRequiredField(`${name}.establishment`),
                    checkRequiredIntegerField(`${name}.number`),
                    checkRequiredIntegerField(`${name}.numberAlternateCustody`),
                ];
            case 'other':
                return [
                    checkRequiredField(`${name}.amount`),
                    checkRequiredField(`${name}.description`),
                    checkRequiredField(`${name}.capped`),
                ];
            default:
                return [];
        }
    })(),
];

const validateTaxCredits = (name, element, { children }) => [
    branchValidate((errors, { familySituation }) => 'cohabitation' === familySituation, [
        checkRequiredOwnerField(`${name}.fiscalHome`),
    ]),
    ...(() => {
        switch (get('type', element)) {
            case 'employeeAtHome':
            case 'homeWork':
            case 'other':
                return [checkRequiredField(`${name}.amount`)];
            case 'childCare': {
                const validators = [
                    checkRequiredField(`${name}.amount`),
                    checkRequiredIntegerField(`${name}.dependentChildren`),
                ];

                return shouldDisplayAlternateCustodyChildren(children)
                    ? [...validators, checkRequiredIntegerField(`${name}.alternateCustodyChildren`)]
                    : validators;
            }
            default:
                return [];
        }
    })(),
];

const checkStartingActivityAge = checkIntegerField(
    (age) => age >= 16,
    'L\'âge de début d\'activité ne peut être inférieur à 16 ans',
);

export default validate([
    branchValidate((errors, { employmentStatus }) => 'active' === employmentStatus || !employmentStatus, [
        checkRequiredField('job'),
    ]),
    branchValidate(
        (_, { createdAt }) => {
            // Cette date limite correspond à la date de mise en prod de cette fonctionnalité
            // Afin de ne pas affecter les rips antérieur à cette date
            const dateLimit = new Date('2023-05-25T00:00:00.000Z');
            const createdDateRip = new Date(createdAt);

            return (createdDateRip >= dateLimit);
        },
        [
            checkRequiredField('employmentStatus'),
            checkCspField('employmentStatus', 'socioProfessionalCategory'),
        ],
    ),
    checkStartingActivityAge('startingActivityAge'),
    branchValidate(
        (_, { familySituation }) => hasPartner(familySituation),
        [
            checkStartingActivityAge('partnerStartingActivityAge'),
            branchValidate((errors, { employmentStatus }) => 'active' === employmentStatus || !employmentStatus, [
                checkRequiredField('job'),
            ]),
            branchValidate(
                (_, { createdAt }) => {
                    // Cette date limite correspond à la date de mise en prod de cette fonctionnalité
                    // Afin de ne pas affecter les rips antérieur à cette date
                    const dateLimit = new Date('2023-05-25T00:00:00.000Z');
                    const createdDateRip = new Date(createdAt);

                    return (createdDateRip >= dateLimit);
                },
                [
                    checkRequiredField('partnerEmploymentStatus'),
                    checkRequiredField('partnerSocioProfessionalCategory'),
                ],
            ),
        ],
    ),
    validateCollection('incomes', validateIncomes),
    validateCollection('taxEvents', validateTaxEvents),
    validateCollection('deductions', validateDeductions),
    validateCollection('reductions', validateReductions),
    validateCollection('taxCredits', validateTaxCredits),
    (errors, values) => (2 < countBy(get('type'), get('taxCredits', values)).childCare
        ? set('taxCredits._error', 'Maximum 2 lignes pour la garde d\'enfant (une par concubin)', errors)
        : errors),
]);
