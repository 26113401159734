import multipleChoiceQuestion from '../../../../../utils/multipleChoiceQuestion';

const choices = {
    none: { point: [0] },
    obligation: { point: [2] },
    action: { point: [2] },
    OPCVM: { point: [1] },
    SCPI: { point: [3] },
    estate: { point: [1] },
};

const question = (point, investorProfile) => multipleChoiceQuestion(investorProfile.investmentKnowledge, choices);

export default question;
