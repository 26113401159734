import { get, isEmpty, isFinite, set, isBoolean, isDate } from 'lodash/fp';

const errorString = 'Vous devez renseigner ce champ.';

const checkRequiredField = (field, underField = false, allowNull = false) => (errors, values) => {
    const value = get(field, values);
    const errorField = underField ? `${field}._error` : field;

    if (allowNull && null === value) {
        return errors;
    }

    return (
        !isEmpty(value)
        || (isFinite(value) && value !== 0)
        || isBoolean(value)
        || isDate(value)
    )
        ? errors
        : set(errorField, errorString, errors);
};

export default checkRequiredField;
