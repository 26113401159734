import PropTypes from 'prop-types';
import React from 'react';
import { map } from 'lodash/fp';
import { AmountItem, FloatItem, PercentageItem } from '.';
import { formatAmount } from '../../utils/format';
// summary.reductions
const BodyRequiredPart = ({ summary }) => (
    <div>
        <dl>
            <AmountItem
                title="Revenus annuels nets"
                value={summary.rawIncomes}
                help={`soit ${formatAmount(summary.rawIncomes / 12)} par mois`}
            />
            <AmountItem title="Déductions" value={summary.deductions} />
            <AmountItem title="Crédit d'impôts" value={summary.credits} />
        </dl>
        <dl>
            <FloatItem title="Nombre de parts" value={summary.parts} />
            <AmountItem title="Réductions" value={summary.reductions} />
            <PercentageItem title="TMI" value={summary.tmi} />
        </dl>
        <dl className="important">
            <AmountItem title="Impôt net sur le revenu" value={summary.incomeTax} important />
            <AmountItem title="Impôt avis social" help="CSG + CRDS + RSA" value={summary.socialCharges} important />
            <AmountItem title="Total impôts" value={summary.incomeTax + summary.socialCharges} important />
        </dl>
        <dl className="is">
            {
                map(({ name, tax }) => (
                    <AmountItem title={`IS dû par la SCI ${name}`} value={tax} important />
                ))(summary.sci)
            }
        </dl>
    </div>
);

BodyRequiredPart.propTypes = {
    summary: PropTypes.shape({
        credits: PropTypes.number.isRequired,
        deductions: PropTypes.number.isRequired,
        parts: PropTypes.number.isRequired,
        rawIncomes: PropTypes.number.isRequired,
        reductions: PropTypes.number.isRequired,
        tmi: PropTypes.number.isRequired,
        sci: PropTypes.arrayOf(PropTypes.shape({})),
        incomeTax: PropTypes.number,
        socialCharges: PropTypes.number,
    }).isRequired,
};

export default BodyRequiredPart;
