import React, { Fragment } from 'react';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { IntegerField } from 'components/form/field';

const Parts = () => (
    <Fragment>
        <Title>Quotien familial</Title>
        <GridLayout className="double condensed full-width">
            <IntegerField
                name="summary.incomeTax.parts.defaultHalf"
                title="Montant max demie part par défaut (€)"
            />
            <IntegerField
                name="summary.incomeTax.parts.defaultQuarter"
                title="Montant max quart de part par défaut (€)"
            />
        </GridLayout>
    </Fragment>
);

export default Parts;
