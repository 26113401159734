const signature = (state = {}, action) => {
    switch (action.type) {
        case 'SET_SIGNATURE_STATE':
            return action.payload;
        default:
            return state;
    }
};

export default signature;
