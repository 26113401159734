import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const ExtraReduction = ({ name }) => (
    <Fragment>
        <Title>Réduction (sous conditions)</Title>
        <GridLayout className="double condensed full-width">
            <div className="double">
                <PercentageFloatField
                    name={`${name}.rate`}
                    title="Taux d'application (%)"
                />
            </div>
            <IntegerField
                name={`${name}.incomeCeils.single`}
                title="Plafond de revenu (célibataire) (€)"
            />
            <IntegerField
                name={`${name}.incomeCeils.couple`}
                title="Plafond de revenu (couple) (€)"
            />
            <IntegerField
                name={`${name}.extraPartIncrease.half`}
                title="Majoration par demie part (€)"
            />
            <IntegerField
                name={`${name}.extraPartIncrease.quarter`}
                title="Majoration par quart de part (€)"
            />
            <IntegerField
                name={`${name}.degressiveDivisors.single`}
                title="Diviseur pour réduction minorée (célibataire) (€)"
            />
            <IntegerField
                name={`${name}.degressiveDivisors.couple`}
                title="Diviseur pour réduction minorée (couple) (€)"
            />
            <IntegerField
                name={`${name}.degressiveReductionIncomeCeils.single`}
                title="Montant plafond pour réduction minorée (célibataire) (€)"
            />
            <IntegerField
                name={`${name}.degressiveReductionIncomeCeils.couple`}
                title="Montant plafond pour réduction minorée (couple) (€)"
            />
        </GridLayout>
    </Fragment>
);

ExtraReduction.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ExtraReduction;
