import { find, flow, get, getOr, isArray, isFunction, isPlainObject, orderBy, some, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, lifecycle, withContext, withState, mapProps } from 'recompose';
import { loadDataList, sortList } from '../../actions';
import tokenize from '../../utils/tokenize';

const listProvider = ({ criteria = null, sorts = {}, filter = null, name, type }) => {
    const hasSort = !isEmpty(sorts);
    const defaultSort = hasSort && flow([
        Object.entries,
        find(get('1.default')),
        ([property, { initialOrder: order }]) => ({ property, order }),
    ])(sorts);

    const getSort = getOr(defaultSort, ['lists', name, 'sort']);

    const toggleSort = (currentSort, property) => {
        let order = get([property, 'initialOrder'], sorts);

        if (currentSort.property === property) {
            order = 'asc' === currentSort.order ? 'desc' : 'asc';
        }

        return sortList(name, property, order);
    };

    return compose(
        withContext({
            list: PropTypes.shape({
                getSort: PropTypes.func.isRequired,
                name: PropTypes.string.isRequired,
                toggleSort: PropTypes.func.isRequired,
            }).isRequired,
        }, () => ({
            list: {
                getSort,
                name,
                toggleSort,
            },
        })),
        connect(null, { load: loadDataList }),
        withState('isLoading', 'setIsLoading', true),
        lifecycle({
            componentWillMount() {
                const { load, setIsLoading, ...props } = this.props;

                load(type, isFunction(criteria) ? criteria(props) : criteria)
                    .then(() => setIsLoading(false))
                    .catch(console.error);
            },
        }),
        connect((state) => ({
            sort: hasSort && getSort(state),
            search: getOr('', ['lists', name, 'search'], state),
            list: Object.values(state.data[type]),
        })),
        mapProps(({ sort, search, list: originalList, ...props }) => {
            let list = originalList;

            if (null !== filter) {
                const filterFunc = filter(props);
                if (null !== filterFunc) {
                    const listFiltered = list.filter((rip) => null !== rip);
                    list = listFiltered.filter(filterFunc);
                }
            }

            let hasActiveSearch = false;

            if ('' !== search) {
                tokenize(search).forEach((word) => {
                    const match = (value) => {
                        if (null === value) {
                            return false;
                        }

                        hasActiveSearch = true;

                        if (isArray(value) || isPlainObject(value)) {
                            return some(match, value);
                        }

                        return some((token) => token.startsWith(word), tokenize(value.toString()));
                    };

                    list = list.filter(match);
                });
            }

            const customSort = get([sort.property, 'sort'], sorts);
            let items = list;
            if (sort) {
                items = customSort ?
                    customSort(sort.order, list) :
                    orderBy([sort.property], [sort.order], list);
            }

            const itemsDesactive = items.filter(user => !user.isActive).map(user => user);
            const itemsActive = items.filter(user => user.isActive).map(user => user);

            return {
                hasActiveSearch,
                items,
                itemsDesactive,
                itemsActive,
                ...props,
            };
        }),
        withContext(
            { hasActiveSearch: PropTypes.bool.isRequired },
            ({ hasActiveSearch }) => ({ hasActiveSearch }),
        ),
    );
};

export default listProvider;
