import {
    isEmpty,
    flow,
    get,
    // mapValues,
    pick,
    identity,
    fromPairs,
    set,
    filter,
    map,
    find,
    startsWith,
} from 'lodash/fp';
import addPrefix from '../../utils/addPrefix';
import getItem from '../../utils/getItem';

/* Utility functions */

const replaceOwnerField = (item, owner, { field }) => set(field, owner, item);

// utility function to process/split collections
const processCollection = (options) => {
    const {
        // which function call to split an item if the owner is clientAndPartner
        splitMethod = identity,
        // which field is the owner field
        field = 'owner',
        // however we may want to filter only some specific types
        typesToFilter = null,
        // we have a hook to process the owner field value
        processOwnerValue = (target) => target || 'client',
        // associate hook
        associate = replaceOwnerField,
        scis = [],
    } = options;

    return (collection, owner) => {
        if (isEmpty(collection)) {
            // nothing to filter there
            return [];
        }

        // here is the new collection (empty at first)
        const newCollection = [];

        const addItem = (item, index) => newCollection.push(associate(item, owner, options, index));

        // now we loop on the collection
        collection.forEach((item, index) => {
            // look for the type and owner field
            const { type, [field]: target } = item;

            if (typesToFilter && !typesToFilter.includes(type)) {
                // it's a tye we don't have to handle
                // simply copy it and move on
                addItem(item, index);
            } else {
                const processedOwnerValue = processOwnerValue(target) || '';
                switch (processedOwnerValue) {
                    case owner:
                        // we just have to copy it
                        // it's the right owner for this one
                        addItem(item, index);
                        break;
                    case (processedOwnerValue.match(/sci-/) || {}).input: {
                        const sciId = processedOwnerValue.replace('sci-', '');
                        const { owner: sciOwner } = find(['id', sciId], scis);

                        if (
                            ('client' === owner && (!sciOwner || 'client' === sciOwner)) ||
                            owner === sciOwner
                        ) {
                            addItem(item, index);
                        }
                        if ('clientAndPartner' === sciOwner) {
                            addItem(splitMethod(item), index);
                        }

                        break;
                    }
                    case 'clientAndPartner':
                        // we must call the split method
                        addItem(splitMethod(item), index);
                        break;
                    default:
                        // By default it belongs to the owner
                        break;
                }
            }
        });

        return newCollection;
    };
};

// utility function to split some properties
const divide = (properties) => {
    const divideValues = flow([
        pick(properties),
        //* on retire la division des charges pour les concubins
        // mapValues((value) => value / 2),
    ]);

    return (values) => ({
        ...values,
        ...divideValues(values),
    });
};

/* Scis */
const filterScis = processCollection({
    processOwnerValue: (target) => target || 'client',
    field: 'owner',
});

/* Incomes */

const incomeMap = {
    salary: divide(['income', 'realExpensesValue']),
    BNC: divide(['result']),
    BIC: divide(['result']),
    BA: divide(['result']),
    landRentalIncome: divide(['income', 'result']),
    furnishedRentalIncome: divide(['income', 'result']),
    dividends: divide(['income']),
    other: divide(['income']),
    exemptedIncome: divide(['income']),
    childrenIncome: divide(['income', 'realExpensesValue']),
};

const filterIncomes = processCollection({
    splitMethod: (income) => incomeMap[income.type](income),
    field: 'beneficiary',
    associate: (item, owner, options, index) => {
        // first replace the owner field
        const newItem = replaceOwnerField(item, owner, options);
        // for legacy purposes we keep the original item index
        // it should be remove in future
        newItem.originalIndex = index;

        return newItem;
    },
});

/* Tax events */

const filterTaxEvents = (rip, newIncomes) => processCollection({
    field: 'income',
    processOwnerValue: (income) => get('beneficiary', getItem(income, get('incomes', rip))),
    associate: (item) => {
        // we need to be careful of the legacy code
        // so first look for integer index
        const index = parseInt(item.income, 10);

        if (index.toString() === item.income) {
            // we do are on a index, so we look for the one with this index
            const newIncomeIndex = newIncomes.findIndex(({ originalIndex }) => originalIndex === index);

            // and do an update on the item
            return set('income', newIncomeIndex, item);
        }

        // nothing to do if we're on UUID
        return item;
    },
    scis: rip.sci,
});

/* Main residence */
const mainResidenceCommon = [
    'mainResidence',
    'mainResidenceAcquisitionYear',
    'mainResidenceAcquisitionMethod',
    'mainResidenceAcquisitionPrice',
    'mainResidenceEstimatedValue',
    'mainResidenceRent',
];

const filterMainResidence = (rip, owner) => {
    // is the main residence owned or rented
    if (['owner', 'tenant'].includes(get('mainResidence', rip))) {
        // we get the tenant
        const mainResidenceTenant = get('mainResidenceTenant', rip);
        // we check is tenant is the wanted owner or if we don't have tenant and wanted owner is 'client'
        if (owner === mainResidenceTenant
            || (null === mainResidenceTenant
                && 'client' === owner)) {
            return pick([...mainResidenceCommon, 'mainResidencePossessionType'], rip);
        } else if (startsWith('sci-', mainResidenceTenant)) {
            const sciId = get('mainResidenceTenant', rip).replace('sci-', '');
            const sci = find(({ id }) => sciId === id, rip.sci);
            const sciOwner = get('owner', sci);

            if (
                ('client' === owner && (!sciOwner || 'client' === sciOwner)) ||
                owner === sciOwner
            ) {
                return pick([...mainResidenceCommon, 'mainResidencePossessionType'], rip);
            }
            if ('clientAndPartner' === sciOwner) {
                return {
                    ...pick([...mainResidenceCommon], rip),
                    mainResidencePossessionType: 'jointPossession',
                    partnerFirstName: get('client' === owner ? 'partnerFirstName' : 'firstName', rip),
                    partnerLastName: get('client' === owner ? 'partnerLastName' : 'lastName', rip),
                };
            }
        } else if ('clientAndPartner' === mainResidenceTenant) {
            return {
                ...pick([...mainResidenceCommon, 'mainResidenceTenant'], rip),
                mainResidencePossessionType: 'jointPossession',
                partnerFirstName: get('client' === owner ? 'partnerFirstName' : 'firstName', rip),
                partnerLastName: get('client' === owner ? 'partnerLastName' : 'lastName', rip),
            };
        } else {
            return {
                mainResidence: 'freeAccomodation',
            };
        }
    }

    return null;
};

const divideMainResidenceRent = flow([
    divide(['mainResidenceRent']),
    pick(['mainResidenceRent']),
]);

const divideMainResidenceMortgages = (mainResidenceTenant, scis) => processCollection({
    splitMethod: divide(['dueDateAmount', 'insuranceAmount']),
    processOwnerValue: () => mainResidenceTenant,
    scis,
});

const divideMainResidence = (rip, owner) => ({
    ...divideMainResidenceRent(rip),
    mainResidenceMortgages: divideMainResidenceMortgages(rip.mainResidenceTenant, rip.sci)(rip.mainResidenceMortgages, owner),
});

/* Properties */

const filterProperties = (scis) => processCollection({
    splitMethod: (property) => {
        if (isEmpty(property.mortgages)) {
            return property;
        }

        const splittedMortgages = property.mortgages.reduce((acc, mortgage) => {
            if ('closed' === mortgage.type) {
                return [...acc, mortgage];
            }

            return [...acc, divide('dueDateAmount')(mortgage)];
        }, []);

        return { ...property, mortgages: splittedMortgages };
    },
    field: 'owner',
    scis,
});

/* Investments */

const filterInvestments = (rip) => (
    processCollection({
        field: 'subscriber',
        scis: rip.sci,
    }));

/* Childs & dependents */

const filterChildren = (items, owner, scis) => flow([
    filter(({ fiscalHome, fromAnotherUnion }) => fiscalHome === owner || !fromAnotherUnion),
    map((item) => ({
        ...item,
        // if we are not on the fiscal home but the owner is the parent
        // we'll keep the child in the RIP
        // but exclude it from the household
        situation:
            item.fiscalHome !== 'clientAndPartner' && item.fiscalHome !== owner && !item.fromAnotherUnion
                ?
                'outside_household'
                :
                item.situation,
    })),
])(items, scis);

const filterGrandChildren = (scis) => processCollection({ field: 'ascendant', scis });

const filterDependents = (scis) => processCollection({ field: 'fiscalHome', scis });

/* Family events */

const filterFamilyEvents = (scis) => processCollection({ field: 'parent', typesToFilter: ['birth'], scis });

/* Deductions & reductions & Credits */

const filterDeductions = (scis) => processCollection({ field: 'fiscalHome', scis });
const filterReductions = filterDeductions;
const filterTaxCredits = filterDeductions;

/* Charges */

const filterCharges = (scis) => processCollection({
    field: 'fiscalHome',
    splitMethod: (charge) => divide(['pension' === charge.type ? 'rent' : 'monthlyPayment'])(charge),
    scis,
}, 'filtreCharge');

/* Fields to copy */

const fieldsToCopy = [
    'phone',
    'address',
    'postalCode',
    'city',
    // comments fields
    'clientComment',
    'taxesComment',
    'chargesComment',
    'estateComment',
    'financialComment',
    // signatures
    'advisorSignature',
    'signatureDate',
    // relations
    'user',
    'userId',
    'futureOwner',
    'futureOwnerDate',
    'hypotheticalRent',
    // meta fields
    'createdAt',
    'updatedAt',
    'expandedSummary',
];

/* Fields to rename */

const addPartnerPrefix = addPrefix('partner')(true);
const fieldsToRename = {
    ...fromPairs([
        'civility',
        'firstName',
        'lastName',
        'birthName',
        'mobile',
        'personalEmail',
        'professionalEmail',
        'birthCountry',
        'birthZipcode',
        'birthPlace',
        'birthDate',
        'fiscalParticularCases',
        'previousTaxes',
        'signature',
        'taxResidency',
        // tax common
        'job',
        'startingActivityAge',
        'seniority',
        'employmentStatus',
        'socioProfessionalCategory',
        'grandParents',
        'allowChangingInvestorProfile',
    ].map((field) => [field, addPartnerPrefix(field)])),
    lifePlans: 'partner.lifePlans',
    financialCapacity: 'partner.hasFinancialCapacity',
    financialMinimumSupply: 'partner.financialSupply.minimum',
    financialMaximumSupply: 'partner.financialSupply.maximum',
    projectMinimumSavings: 'partner.projectSavings.minimum',
    projectMaximumSavings: 'partner.projectSavings.maximum',
    financialSource: 'partner.financialSource',
};

/* filterForOwner */

const filterForOwner = (rip, owner = 'client') => {
    const incomes = filterIncomes(rip.incomes, owner);
    const mainResidence = filterMainResidence(rip, owner);
    const originalFamilySituation = rip.familySituation;

    return {
        familySituation: 'single',
        // there's some fields we simply copy
        ...pick(fieldsToCopy, rip),
        ...mainResidence,
        // then there's some fields to rename and copy
        ...('client' === owner
            ? pick(Object.keys(fieldsToRename), rip)
            : Object.entries(fieldsToRename).reduce(
                (fields, [newField, oldField]) => set(newField, get(oldField, rip), fields),
                {},
            )),
        // the most important in a cohabitation is to split in two the main residence
        ...divideMainResidence(rip, owner),
        // then process some collections
        incomes,
        property: filterProperties(rip.sci)(rip.property, owner),
        investments: filterInvestments(rip)(rip.investments, owner),
        children: filterChildren(rip.children, owner, rip.sci),
        grandChildren: filterGrandChildren(rip.sci)(rip.grandChildren, owner),
        dependents: filterDependents(rip.sci)(rip.dependents, owner),
        familyEvents: filterFamilyEvents(rip.sci)(rip.familyEvents, owner),
        taxEvents: filterTaxEvents(rip, incomes)(rip.taxEvents, owner),
        deductions: filterDeductions(rip.sci)(rip.deductions, owner),
        reductions: filterReductions(rip.sci)(rip.reductions, owner),
        taxCredits: filterTaxCredits(rip.sci)(rip.taxCredits, owner),
        charges: filterCharges(rip.sci)(rip.charges, owner),
        sci: filterScis(rip.sci, owner),
        // investor profile
        investorProfiles: { client: get(['investorProfiles', owner], rip) },
        // we keep the old rip in memory
        // it might be used if we process specific events
        // such as marriage
        originalRip: rip,
        originalFamilySituation,
    };
};

export default filterForOwner;
