// import { useContext } from 'react';
// import { __RouterContext as Context } from 'react-router-dom';

// // TODO use official implementations once react-router-dom is released with hooks
// const useRouter = () => useContext(Context);

// export default useRouter;

import { useLocation, useParams, useNavigate } from 'react-router-dom';

const useRouter = () => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    return {
        location,
        params,
        navigate,
    };
};

export default useRouter;
