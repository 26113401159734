import React from 'react';
import PropTypes from 'prop-types';
import { generationalAnalysisChoices } from 'common/choices';
import { SelectField } from 'components/form/field';

const GenerationalAnalysis = ({ name }) => (
    <SelectField name={`${name}.generationalAnalysis`} title="Analyse intergénérationnelle">
        <option />
        {Object.entries(generationalAnalysisChoices).map(([value, label]) => (
            <option key={value} value={value}>{label}</option>
        ))}
    </SelectField>
);

GenerationalAnalysis.propTypes = {
    name: PropTypes.string.isRequired,
};

export default GenerationalAnalysis;
