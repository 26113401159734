import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { POST } from 'utils/httpMethods';
import Spinner from 'components/Spinner';

const UniversignSuccess = (props) => {
    const { type, getStatusUrl } = props;
    const [signStatusState, setSignStatus] = useState(null);

    useEffect(() => {
        POST(getStatusUrl(props))
            .then((response) => response.json())
            .then((signStatus) => setSignStatus(signStatus));
    }, []);

    if (null === signStatusState) {
        return <Spinner />;
    }

    return (
        <div className="error-page">
            <div>
                {['canceled', 'failed', 'expired'].includes(signStatusState.status) ? (
                    <p className="text-center">La signature du {type} a échouée.</p>
                ) : null}
                {['completed', 'ready'].includes(signStatusState.status) ? (
                    <p className="text-center">Votre signature a bien été prise en compte.</p>
                ) : null}
            </div>
        </div>
    );
};

UniversignSuccess.propTypes = {
    type: PropTypes.string.isRequired,
    getStatusUrl: PropTypes.func.isRequired,
};

export default UniversignSuccess;
