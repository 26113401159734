const revenuTypes = {
    salary: 'Salaire',
    BNC: 'BNC',
    BIC: 'BIC',
    BA: 'BA',
    landRentalIncome: 'Locatifs foncier',
    furnishedRentalIncome: 'Locatifs meublé',
    dividends: 'Dividendes',
    pension: 'Pension',
    other: 'Autres',
    exemptedIncome: 'Revenus exonérés',
    childrenIncome: 'Salaire des enfants à charge',
};

export default revenuTypes;
