import React from 'react';
import { TextAreaField } from '../../form/field';

const Comment = () => (
    <TextAreaField
        title="Commentaires"
        name="approvalComments"
    />
);

export default Comment;
