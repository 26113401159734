import { flowRight, get } from 'lodash/fp';
import { connect } from 'react-redux';
import { branch, renderNothing, withProps } from 'recompose';
import dataProvider from '../dataProvider';
import computeInvestorProfile from '../../common/model/rip/investorProfile';

const profileProvider = flowRight([
    // get the rip
    dataProvider({
        id: ({ params: { id } }) => (id),
        type: 'rip',
        prop: 'rip',
        wait: true,
    }),
    // get the investor profile
    withProps(({ params: { target = 'client' }, rip }) => ({
        target,
        investorProfile: computeInvestorProfile(get(`investorProfiles.${target}`, rip)),
    })),
    // deny invalid profile
    branch(({ investorProfile }) => [null, 'declined'].includes(investorProfile), renderNothing),
    connect(({ investorProfile: next }) => ({ next })),
]);

export default profileProvider;
