import { max } from 'lodash/fp';
import getMonthlyRate from './getMonthlyRate';

const reverseFees = (amount, fees) => Math.ceil(amount / (1 - (parseFloat(fees) || 0)));

const applyFees = (amount, fees) => amount * (1 - (parseFloat(fees) || 0));

/* compute the capital we got at the end of the investment */
const computeCapital = ({
    netInitialFunding,
    netMonthlyFunding,
    yearRate,
    duration,
}) => {
    const months = 12 * duration;
    const monthlyRate = getMonthlyRate(yearRate);

    if (0 === yearRate) {
        return netInitialFunding + (duration * 12 * netMonthlyFunding);
    }
    const monthlyIncome = netMonthlyFunding / monthlyRate;

    return ((netInitialFunding + monthlyIncome) * ((1 + monthlyRate) ** months)) - monthlyIncome;
};

/* compute how much money we need to monthly fund to get the capital on the targeted investment at the end */
export const reverseMonthlyFunding = ({
    capital,
    initialFunding,
    yearRate,
    duration,
    entryFees = 0,
    monthlyFees = 0,
}) => {
    const months = 12 * duration;
    const monthlyRate = getMonthlyRate(yearRate);
    const netInitialFunding = applyFees(initialFunding, entryFees);
    const investmentRate = (1 + monthlyRate) ** months;

    const monthlyFunding = 0 === yearRate
        ? (capital - netInitialFunding) / (duration * 12)
        : (((capital / investmentRate) - netInitialFunding) * monthlyRate) / (1 - (1 / investmentRate));

    return Math.ceil(reverseFees(max([monthlyFunding, 0]), monthlyFees));
};

/* compute how much money we bring in the investment */
const computeFundings = ({
    netInitialFunding,
    netMonthlyFunding,
    duration,
}) => netInitialFunding + (duration * 12 * netMonthlyFunding);

const computeSavings = ({
    duration,
    entryFees = 0,
    initialFunding,
    monthlyFees = 0,
    monthlyFunding = 0,
    yearRate,
}) => {
    const evolution = [];

    for (let year = 0; year <= duration; year++) {
        const yearlyParams = {
            duration: year,
            netInitialFunding: applyFees(initialFunding, entryFees),
            netMonthlyFunding: applyFees(monthlyFunding, monthlyFees),
            yearRate,
        };
        const capital = Math.round(computeCapital(yearlyParams));
        const fundings = Math.round(computeFundings(yearlyParams));
        const roundedCapital = Math.round(capital);

        evolution.push({
            year,
            capital: roundedCapital,
            fundings,
            interests: roundedCapital - fundings,
        });
    }

    return evolution;
};

export default computeSavings;
