import { useFlag } from '@unleash/proxy-client-react';

const setDocumentCifActived = (activated) => ({
    type: 'SET_DOCUMENT_CIF',
    activated,
});

export const getDocumentCifActived = () => (dispatch) => {
    const enabled = useFlag('erip-1223-CIF');
    dispatch(setDocumentCifActived(enabled));
};
