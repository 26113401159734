import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { POST } from 'utils/httpMethods';
import './SendSignInviteAgainButton.scss';

const SendSignInviteAgainBtn = ({ ripId, url, type, investorProfiles, isRequiredField }) => {
    if (!url) {
        return null;
    }

    const [inviteSent, setInviteSent] = useState(false);

    const sendSignInvit = useCallback(() => {
        POST(`/api/rips/${ripId}/resendSignInvite`, {
            body: {
                signer: type,
                signUrl: url,
            },
        }).then(() => {
            setInviteSent(true);
        });
    }, []);

    if (inviteSent) {
        return <div>L&apos;invitation à signer a été envoyée</div>;
    }

    return (
        <div className="sign-again-btn-wrapper">
            {((investorProfiles && isRequiredField) || !isRequiredField) && (
                <button className="sign-btn" onClick={sendSignInvit}>
                    Relancer l&apos;invitation à signer
                </button>
            )}
        </div>
    );
};

SendSignInviteAgainBtn.propTypes = {
    isRequiredField: PropTypes.bool,
    investorProfiles: PropTypes.shape({}),
    ripId: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['client', 'partner', 'advisor']).isRequired,
    url: PropTypes.string.isRequired,
};

export default SendSignInviteAgainBtn;
