import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { get, map } from 'lodash/fp';
import { Field, fieldPropTypes } from 'redux-form';
import { compose, getContext, withProps } from 'recompose';
import Error from '../../Error';

const MultiSelectInner = ({
    formGroup: Group,
    input: { name, value, onChange },
    meta,
    required,
    options,
    ...props
}) => (
    <Group
        component="label"
        htmlFor={name}
        required={required}
        {...props}
    >
        <Select
            name={name}
            className="react-select"
            isMulti
            defaultValue={options.filter(({ value: optionValue }) => value.includes(optionValue))}
            options={options}
            onChange={(inputValue) => onChange(inputValue)}
            onBlur={(event) => event.preventDefault()}
        />
        <Error {...meta} />
    </Group>
);

MultiSelectInner.propTypes = {
    ...fieldPropTypes,
    formGroup: PropTypes.func.isRequired,
    required: PropTypes.bool,
};

MultiSelectInner.defaultProps = {
    required: false,
};

export default compose(
    withProps({
        component: MultiSelectInner,
        parse: map(get('value')),
    }),
    getContext({ formGroup: PropTypes.func.isRequired }),
)(Field);
