import { identity } from 'lodash/fp';
import { reduxForm } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import { saveData } from 'actions';

const dataCreationForm = ({ type, onSubmitData = identity, ...options }) => {
    const onSubmit = (values, dispatch, props) => dispatch(saveData(type, uuidv4(), onSubmitData(values, props)));

    return reduxForm({
        onSubmit,
        ...options,
    });
};

export default dataCreationForm;
