import { slice } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Title from 'components/Title';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import BalancePie from 'common/components/simulation/BalancePie';
import { Grid, AmountItem } from 'common/components/summary';
import RoundBar from 'common/components/RoundBar';

import './CommonSummary.css';
import modifieValeurDureeDispositif from '../../../../../../../../common/src/utils/modifieValeurDureeDispositif';

const bars = [
    { dataKey: 'capital', name: 'capital', fill: '#a2ebac' },
    { dataKey: 'taxfree', name: 'défiscalisé', fill: '#27cae6' },
    { dataKey: 'saving', name: 'épargne', fill: '#248fdf' },
];

const CommonSummary = ({
    simulation: { programDuration },
    simulationSummary: {
        operationCash,
        balance: {
            rent,
            saving,
            taxGains,
        },
        resaleOperation: {
            builtCapital,
        },
        totals: {
            taxEconomy,
            taxReduction,
            csgEconomy,
        },
    },
}) => {
    const dureeDispositif = modifieValeurDureeDispositif(programDuration);
    const barData = slice(1, 1 + dureeDispositif, operationCash).map((year) => ({
        year: year.year,
        capital: year.capital,
        taxfree: year.taxEconomy + year.taxReduction + year.csgEconomy,
        saving: -year.yearlySaving,
    }));

    return (
        <Fragment>
            <Title><b>Récapitulatif</b> de votre pinel</Title>
            <Grid size={3}>
                <AmountItem title="Capital créé" value={builtCapital} />
                <AmountItem title="Montant défiscalisé" value={taxEconomy + taxReduction + csgEconomy} />
                <AmountItem title="Revenu mensuel prévisionnel" value={rent} />
            </Grid>
            <div className="charts-wrapper">
                <ResponsiveContainer aspect={1} width="40%" minWidth={350}>
                    <BalancePie
                        className="pie-chart"
                        balance={{ rent, saving, taxGains, colors: ['#a2ebac', '#27cae6', '#248fdf'] }}
                    />
                </ResponsiveContainer>
                <ResponsiveContainer
                    aspect={1.5}
                    width="50%"
                    minWidth={400}
                >
                    <BarChart
                        className="bar-chart"
                        data={barData}
                        barSize={15}
                        reverseStackOrder
                        margin={{ top: 25, left: 25, right: 15 }}
                    >
                        <XAxis dataKey="year" minTickGap={15} />
                        <YAxis unit=" €" padding={{ bottom: 20 }} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        {bars.map(({ dataKey, name, fill }) => (
                            <Bar
                                key={dataKey}
                                dataKey={dataKey}
                                name={name}
                                stackId="a"
                                fill={fill}
                                legendType="circle"
                                isAnimationActive={false}
                                shape={<RoundBar />}
                            />
                        ))}
                        <Legend layout="vertical" verticalAlign="bottom" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Fragment>
    );
};

CommonSummary.propTypes = {
    simulation: PropTypes.shape({
        programDuration: PropTypes.number,
    }).isRequired,
    simulationSummary: PropTypes.shape({
        operationCash: PropTypes.arrayOf(PropTypes.shape({})),
        balance: PropTypes.shape({
            rent: PropTypes.number,
            saving: PropTypes.number,
            taxGains: PropTypes.number,
        }),
        resaleOperation: PropTypes.shape({
            builtCapital: PropTypes.number,
        }),
        totals: PropTypes.shape({
            taxEconomy: PropTypes.number,
            taxReduction: PropTypes.number,
            csgEconomy: PropTypes.number,
        }),
    }).isRequired,
};

export default CommonSummary;
