export const subscriptionTypesIdsoft = {
    price_1K3zkSIjE1LuOiTE83pbCF2v: { title: '1 jour', monthDuration: 0 },
    price_1K3zkSIjE1LuOiTE81pSAek9: { title: '1 semaine', monthDuration: 0 },
    price_1K3zkSIjE1LuOiTENAFHqy2l: { title: '1 mois', monthDuration: 1 },
    price_1K3zkSIjE1LuOiTEBhyPjsDX: { title: '3 mois', monthDuration: 3 },
    price_1K3zkSIjE1LuOiTEBNraj5GD: { title: '6 mois', monthDuration: 6 },
    price_1K3zkSIjE1LuOiTEo6kLK42J: { title: '1 an', monthDuration: 12 },
    price_1K3zkSIjE1LuOiTEuGZrQNRT: { title: '1 an', monthDuration: 12 },
    price_1NBCyFIjE1LuOiTEF3Lxqh9I: { title: '1 moois', monthDuration: 1 },
};

export const subscriptionTypesTheseis = {
    // * Anciens tarifs NE PAS SUPPRIMER pour avoir accès aux anciennes factures
    oneMonthPartner: { title: '1 mois', monthDuration: 1 },
    sixMonthsPartner: { title: '6 mois', monthDuration: 6 },
    oneYearPartner: { title: '1 an', monthDuration: 12 },
    oneMonthExterne: { title: '1 mois', monthDuration: 1 },
    sixMonthsExterne: { title: '6 mois', monthDuration: 6 },
    oneYearExterne: { title: '1 an', monthDuration: 12 },
    oneMonth: { title: '1 mois', monthDuration: 1 },
    sixMonths: { title: '6 mois', monthDuration: 6 },
    oneYear: { title: '1 an', monthDuration: 12 },

    // * Nouveaux tarifs mis en place à partir du 1er juin 2023
    // partner
    price_1NBDyiJPY6SQcWxaSQOQjvlM: { title: '1 mois', monthDuration: 1 },
    price_1NBDyXJPY6SQcWxamZUkMAQx: { title: '6 mois', monthDuration: 6 },
    price_1NBDyKJPY6SQcWxaTN5k8Q13: { title: '1 an', monthDuration: 12 },
    // Externe
    price_1NBDy8JPY6SQcWxaUCYeDmWA: { title: '1 mois', monthDuration: 1 },
    price_1NBDx8JPY6SQcWxaa5FvIaI2: { title: '6 mois', monthDuration: 6 },
    price_1NBDxxJPY6SQcWxaSGXZjnQt: { title: '1 an', monthDuration: 12 },
    // Exclusif
    price_1NBDzOJPY6SQcWxasgEpOm6H: { title: '1 mois', monthDuration: 1 },
    price_1NBDz9JPY6SQcWxaoILZ0ZAh: { title: '6 mois', monthDuration: 6 },
    price_1NBDywJPY6SQcWxav4rP1TWx: { title: '1 an', monthDuration: 12 },

    // ! Nouveaux tarifs avec les IDs en mode test
    // partner
    price_1NBDvGJPY6SQcWxaONtMbuPg: { title: '1 mois', monthDuration: 1 },
    price_1NBDuqJPY6SQcWxajBzXESyT: { title: '6 mois', monthDuration: 6 },
    price_1NBDuaJPY6SQcWxaVDp25e0d: { title: '1 an', monthDuration: 12 },
    // Externe
    price_1NBDuMJPY6SQcWxa2PkIRjFZ: { title: '1 mois', monthDuration: 1 },
    price_1NBDsjJPY6SQcWxaoyetYXtY: { title: '6 mois', monthDuration: 6 },
    price_1NBDu3JPY6SQcWxayt5UDfa7: { title: '1 an', monthDuration: 12 },
    // Exclusif
    price_1N3xDlJPY6SQcWxaRK38rU1v: { title: '1 mois', monthDuration: 1 },
    price_1NBDvjJPY6SQcWxadzEc3fjD: { title: '6 mois', monthDuration: 6 },
    price_1NBDvXJPY6SQcWxaanI7LsFX: { title: '1 an', monthDuration: 12 },
};

export const subscriptionTypesInovia = {
    INO_Premium_1Month: { title: '1 mois', monthDuration: 1 },
    INO_Premium_6Months: { title: '6 mois', monthDuration: 6 },
    INO_Premium_1Year: { title: '1 an', monthDuration: 12 },
    INO_Standard_1Month: { title: '1 mois', monthDuration: 1 },
    INO_Standard_6Months: { title: '6 mois', monthDuration: 6 },
    INO_Standard_1Year_v2: { title: '1 an', monthDuration: 12 },
};
