import { get, isEmpty, pick, set } from 'lodash/fp';
import validate, {
    checkRequiredField,
    branchValidate,
    switchValidate,
} from 'common/formValidation/lib';

const checkVirtualFields = (virtualField, relatedFields) => (errors) => (!isEmpty(pick(relatedFields, errors))
    ? set(virtualField, 'invalid', errors)
    : errors);

const formValidate = validate([
    branchValidate(
        (errors, { simulation: { data: { contractType } } }) => !['target', 'targetImmo'].includes(contractType),
        [
            // Phone bill
            checkRequiredField('files.phoneBill'),
            branchValidate((errors, v) => get('data.professionalPhone', v), [
                checkRequiredField('files.employerCertificate'),
            ]),
            checkVirtualFields('virtual.phoneBill', ['files.phoneBill', 'files.employerCertificate']),
            // ID Card
            checkRequiredField('data.idType'),
            checkRequiredField('data.idCardEndYear'),
            switchValidate('idType', {
                idCard: [
                    checkRequiredField('files.idCardFront'),
                    checkRequiredField('files.idCardBack'),
                ],
                passport: [checkRequiredField('files.passport')],
                default: [],
            }),
            checkVirtualFields('virtual.idCard', [
                'data.idType',
                'data.idCardEndYear',
                'files.idCardFront',
                'files.idCardBack',
                'files.passport',
            ]),
            // IBAN & Bic
            checkRequiredField('files.rib'),
            checkRequiredField('data.iban'),
            checkRequiredField('data.bic'),
            checkVirtualFields('virtual.rib', ['files.rib', 'data.iban', 'data.bic']),
            // address proof
            branchValidate(
                (errors, { data: { phoneBillAddressDiffers, professionalPhone } = {} }) =>
                    phoneBillAddressDiffers || professionalPhone,
                [
                    checkRequiredField('data.addressProof'),
                    checkRequiredField('files.addressProof'),
                    checkVirtualFields('virtual.addressProof', ['data.addressProof', 'files.addressProof']),
                ],
            ),
            // funds origin
            branchValidate(
                (errors, { simulation: { data } }) => data.initialFunding >= 150000,
                [
                    checkRequiredField('files.fundsOrigin'),
                    checkVirtualFields('virtual.fundsOrigin', ['files.fundsOrigin']),
                ],
            ),
        ],
        [],
    ),
]);

export default formValidate;
