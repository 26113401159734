import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './DownloadButton.css';

const DownloadButton = ({ className, children, ...props }) => (
    <button
        className={classNames('download-button', className)}
        type="button"
        {...props}
    >
        {children}
    </button>
);

DownloadButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

DownloadButton.defaultProps = {
    className: null,
};

export default DownloadButton;
