import React from 'react';
import PropTypes from 'prop-types';
import { faCircleUser as cgp, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCircleUser as client } from '@fortawesome/free-regular-svg-icons';
import Icon from 'common/components/Icon';
import { compose } from 'recompose';
import dataForm from 'components/rip/dataForm';
import { setModal } from 'actions';
import Spinner from 'components/Spinner';
import Group from 'components/form/Group';
import RadioGroup from 'components/form/RadioGroup';
import HorizontalRadioButton from 'components/form/HorizontalRadioButton';
import handleError from 'utils/handleError';
import { withDatabase } from '../../database';
import SuccessModal from '../../SuccessModal';

import './SelectRecipients.scss';

const SelectRecipients = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit} className="rip-recipients-selection">
        <h5>Choisissez qui recevra cet email</h5>
        <Group name="selectRecipients" required>
            <RadioGroup name="selectRecipients" required>
                <HorizontalRadioButton className="recipient" title="CGP" icon={cgp} value="cgp" />
                <HorizontalRadioButton className="recipient" title="Client" icon={client} value="client" />
                <HorizontalRadioButton className="recipient" title="Les deux" icon={faUsers} value="both" />
            </RadioGroup>
            <button type="submit" className="sendButton">
                Envoyer le mail
                <Icon icon="paper-plane" />
            </button>
        </Group>
    </form>
);

SelectRecipients.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default compose(
    withDatabase,
    dataForm({
        form: 'rip-appointment-select-recipients',
        type: 'rip',
        id: ({ id }) => id,
        onSubmit: async ({ selectRecipients }, dispatch, { id, customAttachment, temporaryEmailTemplate, database }) => {
            dispatch(setModal(() => <Spinner>Envoi de l&apos;email en cours...</Spinner>, false));

            const formData = new FormData();

            formData.append('customAttachment', customAttachment);
            formData.append('temporaryEmailTemplate', JSON.stringify(temporaryEmailTemplate));
            formData.append('selectRecipients', selectRecipients);

            await database.rips.syncChanges();
            await fetch(`/api/rips/${id}/sendToCustomer`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            }).catch(handleError);
        },
        onSubmitSuccess: (result, dispatch, session) => {
            dispatch(setModal(() => <SuccessModal session={session} />, false));
        },
        onSubmitFail: () =>
            alert('Erreur lors de l\'envoi. Vérifiez votre connexion internet et réessayez dans quelques instants.'),

    }),
)(SelectRecipients);
