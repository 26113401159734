import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from '../../form/layout';
import { DateField, IntegerField } from '../../form/field';
import SelectSalaryField from '../field/SelectSalary';

const SalaryIncreaseEvent = ({ name }) => (
    <HorizontalLayout>
        <SelectSalaryField name={`${name}.income`} title="Revenu concerné" required />
        <DateField name={`${name}.date`} title="Date prévue pour l'augmentation" required />
        <IntegerField name={`${name}.amount`} title="Montant de l'augmentation (part fixe)" />
        <IntegerField name={`${name}.netAmount`} title="Montant de l'augmentation (part variable)" />
    </HorizontalLayout>
);

SalaryIncreaseEvent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default SalaryIncreaseEvent;
