import { withProps } from 'recompose';
import RipList from './RipList';
import documentCIFList from './DocumentCifList';
import createList from './createList';

const filterOnUserValidation = () => ({ onUserValidation }) => onUserValidation;

const getSettings = (type, component, label, male) => ({

    user: {
        component: createList({
            type,
            filter: filterOnUserValidation,
        }, component),
        subLabel: `Mes ${label}`,
    },
    all: {
        component: createList({
            type,
        }, withProps({ allMode: true })(component)),
        subLabel: `${male ? 'Tous' : 'Toutes'} les ${label}`,
    },
});

const modesSimulationOnly = {
    simulations: {
        label: 'Simulations',
        ...(getSettings('ripsWithoutDocumentCifPendingValidations', documentCIFList, 'Simulations')),
    },
};

const modesCifOnly = {
    documentsCIF: {
        label: 'Documents de conseil CIF',
        ...(getSettings('documentsCifPendingValidations', documentCIFList, 'Documents de conseil CIF', true)),
    },
};

const modesCifAndSimulations = {
    simulations: {
        label: 'Simulations',
        ...(getSettings('ripsWithoutDocumentCifPendingValidations', RipList, 'Simulations')),
    },
    documentsCIF: {
        label: 'Documents de conseil CIF',
        ...(getSettings('documentsCifPendingValidations', documentCIFList, 'Documents de conseil CIF', true)),
    },
};

const modes = { modesSimulationOnly, modesCifAndSimulations, modesCifOnly };

export default modes;
