// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    font-size: 22px;
    text-align: center;
}

.error-message .fa-info-circle {
    font-size: 75px;
    margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorMessage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".error-message {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    flex-direction: column;\n    font-size: 22px;\n    text-align: center;\n}\n\n.error-message .fa-info-circle {\n    font-size: 75px;\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
