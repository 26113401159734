import moment from 'moment';
import formatDate from './date';

const formatDateTime = (value) => {
    const date = value instanceof moment ? value : moment(value);

    if (date.day() === moment().day()) {
        return date.format('HH:mm');
    }

    return formatDate(date);
};

export default formatDateTime;
