import PropTypes from 'prop-types';
import React from 'react';
import { TextField, DateField, InputField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import SeeMore, { withSeeMore } from '../SeeMore';

const CapitalInner = ({ name, seeMore }) => (
    <div>
        <HorizontalLayout>
            <TextField title="Specifier" name={`${name}.specify`} />
            <SeeMore name={name} />
        </HorizontalLayout>
        {seeMore ? (
            <div>
                <HorizontalLayout>
                    <InputField name={`${name}.goals`} title="Quels objectifs&nbsp;?" type="text" />
                    <InputField name={`${name}.goalsAmount`} title="Montant associé" type="number" />
                </HorizontalLayout>
                <HorizontalLayout>
                    <InputField
                        name={`${name}.incomeShareAside`}
                        title="Constitution du capital : pourcentage des revenus mis de côté"
                        type="number"
                        double
                    />
                </HorizontalLayout>
                <HorizontalLayout>
                    <DateField name={`${name}.deadline`} title="Délais des objectifs personnels&nbsp;?" />
                    <InputField name={`${name}.deadlineAmount`} title="Montants respectés" type="number" />
                </HorizontalLayout>
                <HorizontalLayout>
                    <InputField name={`${name}.summary`} title="Synthèse des objectifs : Montant" type="number" />
                    <DateField name={`${name}.summaryDeadline`} title="Horizon" />
                </HorizontalLayout>
            </div>
        ) : null}
    </div>
);

CapitalInner.propTypes = {
    name: PropTypes.string.isRequired,
    seeMore: PropTypes.bool,
};

CapitalInner.defaultProps = {
    seeMore: false,
};

const Capital = withSeeMore(CapitalInner);

export default Capital;
