import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import PerSupport from './PerSupport';
import PerContract from './PerContract';

const PerSettings = ({ settings }) => (
    <Tabs id="life-insurance-tabs">
        <Tab eventKey="perSupport" title="Supports">
            <PerSupport products={settings.per.baseProducts} types={settings.per.contracts} />
        </Tab>
        <Tab eventKey="perContract" title="Contrats">
            <PerContract baseProducts={settings.per.baseProducts} types={settings.per.contracts} />
        </Tab>
    </Tabs>
);
PerSettings.propTypes = {
    settings: PropTypes.shape({
        per: PropTypes.shape({
            baseProducts: PropTypes.string,
            contracts: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default PerSettings;
