import { flowRight, get } from 'lodash/fp';
import { withPropsOnChange } from 'recompose';
import { formValues } from 'redux-form';

const withTargetRecommendations = flowRight([
    formValues('target', 'choicePreco'),
    withPropsOnChange(['rip', 'target', 'choicePreco'], ({ rip, target, choicePreco }) => {
        let nametarget;
        if ('partner' === target) {
            if ('simulation' === choicePreco && ('cohabitation' !== rip.familySituation)) {
                nametarget = 'cif' !== choicePreco ? 'recommendations' : 'recommendationsCIF';
            } else {
                nametarget = 'cif' !== choicePreco ? 'partner.recommendations' : 'partner.recommendationsCIF';
            }
        } else {
            nametarget = 'cif' !== choicePreco ? 'recommendations' : 'recommendationsCIF';
        }

        return {
            recommendations: get(nametarget, rip),
            name: nametarget,
            choicePreco,
        };
    }),
]);

export default withTargetRecommendations;
