import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import logo from 'images/logo-universign.png';

const SelectSignature = ({
    title,
    selectedSignature,
    onClick: clickHandler,
}) => (
    <div className="select-signatures">
        {title} :
        <div>
            <Fragment>
                <button
                    tabIndex="-1"
                    className={classNames('signature-type', { selected: 'universign' === selectedSignature })}
                    onClick={() => clickHandler('universign')}
                >
                    <img src={logo} alt="Universign" />
                    Signature Électronique (Client non présent physiquement)
                </button>
                <button
                    tabIndex="-1"
                    className={classNames('signature-type', { selected: 'no' === selectedSignature })}
                    onClick={() => clickHandler('no')}
                >
                    Pas de signature
                </button>
            </Fragment>
        </div>
    </div>
);

SelectSignature.propTypes = {
    title: PropTypes.string.isRequired,
    selectedSignature: PropTypes.string,
    onClick: PropTypes.func,
};

export default SelectSignature;
