import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import CheckboxButton from '../../form/CheckboxButton';
import { TextField } from '../../form/field';
import { GridLayout, HorizontalLayout } from '../../form/layout';
import SeeMore, { withSeeMore } from '../SeeMore';

import './Taxation.css';

const TaxationInner = ({ name, seeMore }) => (
    <div>
        <HorizontalLayout>
            <TextField title="Specifier" name={`${name}.specify`} />
            <SeeMore name={name} />
        </HorizontalLayout>
        {seeMore ? (
            <GridLayout className="rib-finances-taxation-grid">
                <Field name={`${name}.incomes`} title="Revenus" component={CheckboxButton} />
                <Field name={`${name}.realEstate`} title="Revenus fonciers" component={CheckboxButton} />
                <Field name={`${name}.addedValue`} title="Plus-values" component={CheckboxButton} />
                <Field name={`${name}.fortune`} title="IFI" component={CheckboxButton} />
                <Field name={`${name}.socialContributions`} title="CSG/CRDS/PS" component={CheckboxButton} />
            </GridLayout>
        ) : null}
    </div>
);

TaxationInner.propTypes = {
    name: PropTypes.string.isRequired,
    seeMore: PropTypes.bool,
};

TaxationInner.defaultProps = {
    seeMore: false,
};

const Taxation = withSeeMore(TaxationInner);

export default Taxation;
