import { get } from 'lodash/fp';
import isRequiredGrandChildrenAndPartnerField from '../../utils/isRequiredGrandChildrenAndPartnerField';
import hasPartner, { isCouple } from '../../utils/hasPartner';

import validate, {
    checkDateDependencies,
    checkIsDate,
    checkIsEmail,
    checkIsZipCode,
    checkIsPhoneNumber,
    checkRequiredField,
    checkRequiredOwnerField,
    checkIsMobilePhoneNumber,
    checkIsHousePhoneNumber,
    checkIsMajor,
    branchValidate,
    validateCollection,
} from '../lib';

const validateChildren = (name) => [
    checkRequiredField(`${name}.firstNames`),
    checkRequiredField(`${name}.birthDate`),
    checkIsDate(`${name}.birthDate`),
    branchValidate(
        (errors, { familySituation }) => 'cohabitation' === familySituation,
        [checkRequiredOwnerField(`${name}.fiscalHome`)],
    ),
    branchValidate(
        (errors, values) => get(`${name}.deadChild`, values),
        [
            checkRequiredField(`${name}.childDeathDate`),
            checkIsDate(`${name}.childDeathDate`),
        ],
    ),
    branchValidate(
        (errors, values) => !get(`${name}.deadChild`, values),
        [checkRequiredField(`${name}.situation`)],
    ),
    branchValidate(
        (errors, values) =>
            true === get(`${name}.fromAnotherUnion`, values) &&
            ('married' === values.familySituation ||
                'partner' === values.familySituation ||
                'cohabitation' === values.familySituation),
        [checkRequiredField(`${name}.parent`)],
    ),
];

const validateGrandChildren = (name) => [
    branchValidate(
        (errors, { grandChildren }) => null !== grandChildren,
        [checkRequiredField(`${name}.childParent`)],
    ),
    branchValidate(
        (errors, { grandChildren }) => null !== grandChildren,
        [checkRequiredField(`${name}.firstName`)],
    ),
    branchValidate(
        // champ ascendant visible uniquement pour le statut concubinage
        (errors, { grandChildren, familySituation }) => null !== grandChildren && 'cohabitation' === familySituation,
        [checkRequiredField(`${name}.ascendant`)],
    ),
];

const validateDependents = (name) => [
    checkRequiredField(`${name}.type`),
    checkRequiredField(`${name}.parts`),
    branchValidate(
        (errors, { familySituation }) => 'cohabitation' === familySituation,
        [checkRequiredField(`${name}.fiscalHome`)],
    ),
];

const validateFamilyEvents = (name, element) => {
    switch (get('type', element)) {
        case 'birth':
            return [
                branchValidate(
                    (errors, { familySituation }) =>
                        'cohabitation' === familySituation,
                    [checkRequiredField(`${name}.parent`)],
                ),
                checkRequiredField(`${name}.presumedBirthDate`),
                checkIsDate(`${name}.presumedBirthDate`),
            ];
        case 'householdLeave':
            return [
                checkRequiredField(`${name}.subject`),
                checkRequiredField(`${name}.date`),
                checkIsDate(`${name}.date`),
            ];
        case 'marriage':
        case 'partnership':
        case 'divorce':
            return [
                checkRequiredField(`${name}.date`),
                checkIsDate(`${name}.date`),
            ];
        default:
            return [];
    }
};

const validateUnionDate = (field) =>
    checkDateDependencies(
        'unionDate',
        field,
        "La date de l'union ne peut être antérieure à la date de naissance d'un des conjoints",
    );

export default validate([
    checkRequiredField('civility'),
    checkRequiredField('firstName'),
    checkRequiredField('nationality'),
    checkRequiredField('lastName'),
    branchValidate(
        (errors, { civility, familySituation }) => 'Mme' === civility && 'single' !== familySituation && 'cohabitation' !== familySituation && 'divorced' !== familySituation,
        [checkRequiredField('birthName')],
    ),
    checkRequiredField('address'),
    branchValidate((errors, { country }) => 'FR' === country, [checkRequiredField('addressType')]),
    branchValidate((errors, { country }) => 'FR' === country, [checkRequiredField('postalCode'), checkIsZipCode('postalCode'),
    ]),
    branchValidate((errors, { country }) => 'FR' === country, [checkRequiredField('city')]),
    checkRequiredField('mobile'),
    checkIsPhoneNumber('mobile'),
    checkIsMobilePhoneNumber('mobile'),
    checkIsPhoneNumber('phone'),
    checkIsHousePhoneNumber('phone'),
    checkRequiredField('personalEmail'),
    checkIsEmail('personalEmail'),
    checkIsEmail('professionalEmail'),
    checkRequiredField('birthDate'),
    checkIsDate('birthDate'),
    checkIsMajor('birthDate'),
    checkRequiredField('taxResidency'),
    branchValidate(
        (errors, { birthCountry, birthZipcode }) =>
            'FR' === birthCountry && birthZipcode,
        [checkIsZipCode('birthZipcode')],
    ),
    checkRequiredField('usPerson'),
    checkRequiredField('familySituation'),
    branchValidate(
        (errors, { familySituation, dualRip }) =>
            hasPartner(familySituation) &&
            ('cohabitation' !== familySituation || dualRip),
        [
            checkRequiredField('partnerCivility'),
            checkRequiredField('partnerFirstName'),
            checkRequiredField('partnerLastName'),
            branchValidate(
                (errors, { partnerCivility }) => 'Mme' === partnerCivility,
                [checkRequiredField('partnerBirthName')],
            ),
            checkRequiredField('partnerMobile'),
            checkIsPhoneNumber('partnerMobile'),
            checkIsMobilePhoneNumber('partnerMobile'),
            checkRequiredField('partnerPersonalEmail'),
            checkRequiredField('partnerNationality'),
            checkIsEmail('partnerPersonalEmail'),
            checkIsEmail('partnerProfessionalEmail'),
            checkRequiredField('partnerBirthDate'),
            checkIsDate('partnerBirthDate'),
            checkIsMajor('partnerBirthDate'),
            checkRequiredField('partnerTaxResidency'),
            branchValidate(
                (errors, { partnerBirthCountry, partnerBirthZipcode }) =>
                    'FR' === partnerBirthCountry && partnerBirthZipcode,
                [checkIsZipCode('birthZipcode')],
            ),
            checkRequiredField('partnerUsPerson'),
        ],
    ),
    branchValidate(
        (_, { familySituation }) => isCouple(familySituation),
        [
            checkIsDate('unionDate'),
            validateUnionDate('birthDate'),
            validateUnionDate('partnerBirthDate'),
        ],
    ),
    validateCollection('children', validateChildren),
    branchValidate(
        (errors, { createdAt }) => isRequiredGrandChildrenAndPartnerField(createdAt),
        [validateCollection('grandChildren', validateGrandChildren)],
    ),
    branchValidate(
        // champ obligatoire à partir du 13/04/23
        (errors, { createdAt, familySituation }) => isRequiredGrandChildrenAndPartnerField(createdAt) && 'cohabitation' === familySituation,
        [
            checkRequiredField('partnerCivility'),
            checkRequiredField('partnerFirstName'),
            checkRequiredField('partnerLastName'),
            checkRequiredField('partnerNationality'),

        ],
    ),
    validateCollection('dependents', validateDependents),
    validateCollection('familyEvents', validateFamilyEvents),
]);
