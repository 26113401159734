import { flowRight } from 'lodash/fp';
import moment from 'moment';
import { withPropsOnChange } from 'recompose';
import getAfterwardRip from 'common/utils/getAfterwardRip';
import computeSummary from 'common/model/summary';
import simulationLoader from '../../../simulationLoader';
import withSimulationSummary from '../../../withSimulationSummary';
import applyEvents from '../../../../../common/utils/applyEvents';
import withRouter from '../../../../withRouter';

const withSummary = flowRight([
    withRouter,
    simulationLoader,
    withSimulationSummary,
    withPropsOnChange(
        ['rip', 'simulation', 'simulationSummary'],
        ({ rip, simulation, simulationSummary: simulationResult }) => {
            const summary = computeSummary(applyEvents(rip, moment().year()));
            const { rip: afterwardRip } = getAfterwardRip({ rip, summary, simulation, simulationResult });

            return {
                summary,
                afterwardRip,
                afterwardSummary: computeSummary(afterwardRip),
            };
        },
    ),
]);

export default withSummary;
