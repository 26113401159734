import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import dataForm from 'components/dataForm';
import FormButtons from 'components/FormButtons';
import Form from 'components/simulation/FormStep';
import contractLoader from 'components/contract/contractLoader';
import getWizardStep from 'utils/getWizardStep';
import ConnectedNextButton from 'components/FormButtons/buttons/ConnectedNextButton';
import processValues from '../processValues';
import Settings from './Settings';
import ScheduledPayments from './ScheduledPayments';
import Profile from './Profile';
import Contract from './Contract';
import getWizard from '../wizardData';
import onSubmitRedirect from '../onSubmitRedirect';

const OverviewInner = ({
    handleSubmit,
    rip: { id },
    contract: { simulation },
    location,
    ...props
}) => {
    const wizard = getWizard(simulation.data.contractType);
    const baseUrl = wizard.prefixTemplate(props.params);

    const prevStep = getWizardStep({ wizard, url: location.pathname, next: false });

    const prevUrl = `${baseUrl}/${prevStep}`;

    return (
        <Form
            handleSubmit={handleSubmit}
            wizard={wizard}
        >
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <Profile simulation={simulation} />
                    <Contract simulation={simulation} />
                    <Settings simulation={simulation} />
                    <ScheduledPayments simulation={simulation} />
                    {!simulation.locked && (
                        <Link className="rip-edit-link" to={`/rip/${id}/simulations/${simulation.id}/profile`}>
                            <p>Editer la simulation</p>
                        </Link>
                    )}
                </div>
            </div>
            <FormButtons
                back={prevUrl}
                list={`/rip/${id}/contracts/`}
                nextButtonComponent={ConnectedNextButton}
            />
        </Form>
    );
};

OverviewInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    rip: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    contract: PropTypes.shape({
        simulation: PropTypes.shape({
            id: PropTypes.string,
            locked: PropTypes.bool,
            data: PropTypes.shape({
                contractType: PropTypes.string,
            }),
        }),
    }).isRequired,
    params: PropTypes.shape({}),
};

const Overview = compose(
    contractLoader,
    dataForm({
        autoSave: false,
        processValues,
        form: 'contract-overview',
        id: ({ params: { contractId } }) => contractId,
        onSubmitSuccess: onSubmitRedirect,
        type: 'contract',
    }),
)(OverviewInner);

export default Overview;
