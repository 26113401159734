import React from 'react';
import { Layout } from 'components/layouts';
import RipApprovalForm from './approval/Form';
import withRouter from '../withRouter';

const Approval = () => (
    <Layout>
        <RipApprovalForm
            initialValues={{ target: 'client' }}
            nextButtonContent="Envoyer"
            hideMenu
        />
    </Layout>
);

export default withRouter(Approval);
