import React, { Fragment } from 'react';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { IntegerField } from 'components/form/field';

const FiscalParticularCases = () => (
    <Fragment>
        <Title>Cas fiscaux particuliers</Title>
        <GridLayout className="double condensed full-width">
            <IntegerField
                name="summary.incomeTax.fiscalParticularCasesValues.widower"
                title="Montant max personne veuve ayant 1 ou plusieurs enfants à charge (€)"
            />
            <IntegerField
                name="summary.incomeTax.fiscalParticularCasesValues.disabled"
                title="Montant max personne porteuse de handicap (€)"
            />
            <IntegerField
                name="summary.incomeTax.fiscalParticularCasesValues.singleParent"
                title="Montant max parent isolé (€)"
            />
            <IntegerField
                name="summary.incomeTax.fiscalParticularCasesValues.singleHavingRaisedChildMoreThan5Years"
                title="Montant max parent ayant élevé un enfant seul pendant plus de 5 ans (€)"
            />
            <IntegerField
                name="summary.incomeTax.fiscalParticularCasesValues.various"
                title="Montant max en cas d'invalidité ou qualité d'ancien combattant (€)"
            />
        </GridLayout>
    </Fragment>
);

export default FiscalParticularCases;
