const choices = {
    advices: 'Conseil en assurances',
    subscriptionForm: 'Bulletin de souscription',
    sepaMandatePunctual: 'Mandat de prélèvement SEPA (ponctuel)',
    sepaMandateIterative: 'Mandat de prélèvement SEPA (récurrent)',
    phoneBill: 'Facture de téléphone mobile',
    swornStatement: 'Attestation sur l\'honneur',
    employerCertificate: 'Attestation de l\'employeur',
    townhallCertificate: 'Attestation d’une mairie, ambassade ou consulat',
    rentReceipt: 'Quittance de loyer établie par une agence ou un syndic',
    houseBill: 'Facture d’électricité/gaz/eau, téléphone fixe, câble, échéancier',
    taxNotice: 'Avis d’imposition sur le revenu, taxe d’habitation',
    passport: 'Passeport',
    idCardFront: 'Carte d\'identité (verso)',
    idCardBack: 'Carte d\'identité (recto)',
    RIB: 'RIB',
    freeClauseDocument: 'Clause libre papier',
    noticeInfo: 'Notice d\'information',
    legalDocuments: '',
    cg: 'Conditions contractuelles',
    kidContract: 'Document d\'informations clés de votre contrat',
    documentEntreeEnRelation: 'Document d\'entrée en relation',
    rip: 'RIP',
    cgse: 'Conditions Générales d\'accès au service de Signature Electronique',
    timestampedDocuments: 'documents précontractuels',
    fundsOrigin: 'Justificatif d\'origine des fonds',
    electricityCertificate: 'Attestation du titulaire du contrat d’électricité (si différent du souscripteur) + copie de sa pièce d’identité',
    insuranceCertificate: 'Attestation d’assurance habitation',
    proofOfAccommodation: 'Attestation de l’hébergeant si résidence chez un tiers + copie de la pièce d’identité du tiers + justificatif de domicile de l’hébergeant',
};

export default choices;
