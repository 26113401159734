import React, { PureComponent, Component, createContext } from 'react';
import { setDisplayName, wrapDisplayName } from 'recompose';

export const ActiveItemContext = createContext(null);

const withActiveItem = (WrappedComponent) => {
    class ActiveItemConsumer extends PureComponent {
        constructor(props) {
            super(props);

            // initial state
            this.state = {
                activeItem: null,
                target: 'default',
            };

            this.setActiveItem = this.setActiveItem.bind(this);
        }

        setActiveItem(activeItem, target = 'default') {
            this.setState({ activeItem, target });
        }

        render() {
            const context = {
                ...this.state,
                setActiveItem: this.setActiveItem,
            };

            return (
                <ActiveItemContext.Provider value={context}>
                    <WrappedComponent {...this.props} />
                </ActiveItemContext.Provider>
            );
        }
    }

    return setDisplayName(wrapDisplayName(WrappedComponent, 'withActiveItem'))(ActiveItemConsumer);
};

export const connectActiveItem = (getItemFromProps, withProps) => (WrappedComponent) => {
    // eslint-disable-next-line react/no-multi-comp
    class ActiveItemConnector extends Component {
        constructor(props) {
            super(props);

            // get the item from props
            this.item = getItemFromProps(props);

            // we might have concurrent events triggering show()
            // however we don't want one to override the other
            // so we set a lock to only apply one change instead of two
            this.lock = false;

            this.show = this.show.bind(this);
            this.hide = this.hide.bind(this);
        }

        componentDidUpdate() {
            this.lock = false;
        }

        show(target) {
            // the lock must not be on
            if (!this.lock) {
                // set it on
                this.lock = true;
                // and trigger our changes
                this.context.setActiveItem(this.item, target);
            }
        }

        hide() {
            const { activeItem, setActiveItem } = this.context;

            if (activeItem === this.item && !this.lock) {
                // set it on
                this.lock = true;
                // set active item to null
                setActiveItem(null);
            }
        }

        render() {
            const { item, show, hide, props, context } = this;

            return (
                <WrappedComponent
                    {...props}
                    {...withProps(item, context)}
                    show={show}
                    hide={hide}
                />
            );
        }
    }

    ActiveItemConnector.contextType = ActiveItemContext;

    return setDisplayName(wrapDisplayName(WrappedComponent, 'withActiveItemConnector'))(ActiveItemConnector);
};

export default withActiveItem;
