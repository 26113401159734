// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-life-plan {
    display: flex;
    margin-left: 15px;
    align-items: center ;
    height: 35px;
}

.summary-life-plan i.fa {
    font-size: 25px;
}

.summary-life-plan div:first-child,
.summary-life-plan .icon {
    width: 25px;
    height: 25px;
}

.summary-life-plan > p {
    margin: 0 0 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/overlays/summary/Savings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".summary-life-plan {\n    display: flex;\n    margin-left: 15px;\n    align-items: center ;\n    height: 35px;\n}\n\n.summary-life-plan i.fa {\n    font-size: 25px;\n}\n\n.summary-life-plan div:first-child,\n.summary-life-plan .icon {\n    width: 25px;\n    height: 25px;\n}\n\n.summary-life-plan > p {\n    margin: 0 0 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
