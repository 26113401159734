import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'components/layouts';
import { flowRight } from 'lodash/fp';
import withRouter from '../withRouter';
import FormButtons from '../FormButtons';
import fullName from '../../common/utils/fullName';
import { withSession } from '../sessions';

const SubscriptionProcess = ({ navigate, session }) => {
    const headText = `Bonjour ${fullName(session)},`;

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate('/subscription');
    };

    return (
        <Layout>
            <form onSubmit={handleSubmit} id="main-form">
                <div className="container">
                    <p>{headText}</p>
                    <p>Votre accès à E-RIP est arrivé à expiration</p>
                    <p>Pour continuer à utiliser le RIP, merci de cliquer sur Continuer.</p>
                </div>
                <FormButtons back="/" list={null} />
            </form>
        </Layout>
    );
};

SubscriptionProcess.propTypes = {
    navigate: PropTypes.func,
    session: PropTypes.shape({}).isRequired,
};

export default flowRight([
    withRouter,
    withSession,
])(SubscriptionProcess);
