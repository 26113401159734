import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TextItem, AmountItem } from 'common/components/summary';

const LODEOM = ({
    recommendation: {
        typeAmount,
        partenaire,
        amountCif,
    },
}) => (
    <Grid size={2}>
        <TextItem title="Partenaire" value={partenaire} />
        <AmountItem title={'apport' === typeAmount ? `Montant de l'${typeAmount}` : `Montant de la ${typeAmount}`} value={amountCif} />
    </Grid>
);

LODEOM.propTypes = {
    recommendation: PropTypes.shape({
        typeAmount: PropTypes.string,
        partenaire: PropTypes.string,
        amountCif: PropTypes.number,
    }).isRequired,
};

export default LODEOM;
