import React from 'react';
import { compose } from 'recompose';
import { formValues } from 'redux-form';
import { InputField } from '../../form/field';

const FiscalPartInputInner = () => (
    <InputField
        name="fiscalPart"
        title="Nombre de parts du dernier avis d'imposition"
        type="text"
        required
    />
);

const FiscalPartInput = compose(formValues('fiscalPart'))(FiscalPartInputInner);

export default FiscalPartInput;
