import { flowRight } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { withStateHandlers } from 'recompose';
import Universign from 'components/Universign';
import FormButtons from '../../FormButtons';
import Form from '../../simulation/FormStep';
import contractLoader from '../contractLoader';
import getWizard from './wizardData';
import onSubmitRedirect from './onSubmitRedirect';
import getSettings from '../../../common/utils/settings';
import withRouter from '../../withRouter';

const Signature = ({ params: { id, contractId, contractType }, navigate, completed, onComplete }) => {
    const listUrl = `/rip/${id}/contracts/`;

    const subscription = getSettings(`simulation.lifeInsurance.types.${contractType}.subscription`);
    const isTarget = subscription;
    const signUrl = isTarget ? `/api/contracts/${contractId}/primonial/sign` : `/api/contracts/${contractId}/sign`;

    return (
        <Form
            handleSubmit={(e) => {
                // prevent the default behavior
                // we don't want to reload the page
                e.preventDefault();

                if (completed) {
                    // only move to the next page if indeed it's completed
                    navigate(listUrl);
                }
            }}
            wizard={getWizard(contractType)}
            noScroll
            className="sign-frame-layout"
        >
            <Universign
                docId={contractId}
                docSign="av"
                ripId={id}
                signUrl={signUrl}
                onComplete={onComplete}
            />
            <FormButtons
                list={listUrl}
                back={`/rip/${id}/contracts/${contractId}/documents/${contractType}`}
                showNav={completed}
            />
        </Form>
    );
};

Signature.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        contractId: PropTypes.string,
        contractType: PropTypes.string,
    }),
    navigate: PropTypes.shape({}),
    completed: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
};

export default flowRight([
    withRouter,
    contractLoader,
    withStateHandlers({ completed: false }, {
        onComplete: (state, props) => () => {
            const { params: { contractType } } = props;

            const subscription = getSettings(`simulation.lifeInsurance.types.${contractType}.subscription`);
            const isTarget = subscription;

            if (!isTarget) {
                return { completed: true };
            }

            return onSubmitRedirect(null, null, props);
        },
    }),
])(Signature);
