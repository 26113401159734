import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import MenuItems from './menu/Items';
import MenuButton from '../menu/Button';
import Icon from '../../common/components/Icon';
import { hideOverlay, toggleMenu } from '../../actions';

import './Menu.css';

const Dots = () => (
    <span className="rip-menu-dots">
        <Icon icon="circle" /> <Icon icon="circle" /> <Icon icon="circle" />
    </span>
);

const Menu = ({ activeMenu, activeOverlay, hideActiveOverlay, toggleActiveMenu }) => (
    <div className="rip-menu">
        {activeMenu ? <MenuItems /> : null}
        {activeOverlay ? (
            <MenuButton color="danger" onClick={hideActiveOverlay}>
                <Icon icon="times" />
            </MenuButton>
        ) : (
            <MenuButton color={activeMenu ? 'primary' : null} onClick={toggleActiveMenu}>
                <Dots />
            </MenuButton>
        )}
    </div>
);

Menu.propTypes = {
    activeMenu: PropTypes.bool.isRequired,
    activeOverlay: PropTypes.string,
    hideActiveOverlay: PropTypes.func.isRequired,
    toggleActiveMenu: PropTypes.func.isRequired,
};

Menu.defaultProps = {
    activeOverlay: null,
};

const mapStateToProps = (state) => ({
    activeMenu: state.menuVisibility,
    activeOverlay: state.overlay,
});

const mapDispatchToProps = {
    toggleActiveMenu: toggleMenu,
    hideActiveOverlay: hideOverlay,
};

export default flowRight([
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        componentWillUnmount() {
            this.props.hideActiveOverlay();
        },
    }),
])(Menu);
