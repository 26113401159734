import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideOverlay } from 'actions';
import RipSponsoringForm from './Form';

const Sponsoring = ({ hideActiveOverlay, id }) => (
    <div className="overlay-scrollable">
        <div className="container">
            <RipSponsoringForm
                id={id}
                onSubmitSuccess={hideActiveOverlay}
            />
        </div>
    </div>
);

Sponsoring.propTypes = {
    id: PropTypes.string.isRequired,
    hideActiveOverlay: PropTypes.func.isRequired,
};

export default connect(null, { hideActiveOverlay: hideOverlay })(Sponsoring);
