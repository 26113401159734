import { toPairs, isEmpty, flow, first, filter, get, find } from 'lodash/fp';

const validateSubmittion = ({ evt, handleSubmit, showErrors, ripId, simulationId, errors, push, items }) => {
    // prevent the default event
    evt.preventDefault();

    const invalidStep = flow([toPairs, filter(([, formErrors]) => !isEmpty(formErrors)), first, get(0)])(errors);

    if (invalidStep) {
        // get the invalid step url
        const target = get('target', find(({ form }) => form === invalidStep, items));
        // inform we want to show the errors on the next step
        showErrors(true);
        // then redirect to the step
        push(`/rip/${ripId}/simulations/${simulationId}/${target}`);
    } else {
        handleSubmit();
    }

    return false;
};

export default validateSubmittion;
