import React from 'react';
import { wrapDisplayName, setDisplayName } from 'recompose';
import useSession from './useSession';

const withSession = (WrappedComponent) => {
    const SessionConsumer = (props) => {
        const session = useSession();

        return <WrappedComponent {...props} session={session} />;
    };

    return setDisplayName(wrapDisplayName(WrappedComponent, 'withSession'))(SessionConsumer);
};

export default withSession;
