const defaultState = { path: '/', label: 'Retour à la liste des RIPs ' };

const investorProfile = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case 'INVESTOR_PROFILE_SET':
            return { state, ...payload.data };
        default:
            return state;
    }
};

export default investorProfile;
