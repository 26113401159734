import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { useStripe, useElements, CardElement, IbanElement } from '@stripe/react-stripe-js';
import { POST } from 'utils/httpMethods';
import { paymentTypes } from 'common/choices';
import Icon from 'common/components/Icon';
import Checkbox from 'components/form/Checkbox';
import Spinner from 'components/Spinner';
import RadioQuestion from '../InvestorProfile/RadioQuestion';
import { withSession } from '../sessions';
import PaymentType from './PaymentType';
import Buyer from './Buyer';
import formValidate from './formValidate';
import withInstanceSettings from '../sessions/withInstanceSettings';
import formatageDesPlansDeTarificationStripe from '../../../../common/src/utils/formatTarificationStripe';

import './CheckoutForm.scss';

class StripeError extends Error {
    // simply used to dissociate error from stripe from others
}

const getToken = async (paymentType, stripe, elements, values) => {
    switch (paymentType) {
        case 'card': {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            if (error) {
                throw new StripeError(error.message);
            }

            return paymentMethod.id;
        }

        case 'iban': {
            const { name, email } = values;
            const ibanElement = elements.getElement(IbanElement);

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'sepa_debit',
                sepa_debit: ibanElement,
                billing_details: { name, email },
            });

            if (error) {
                throw new StripeError(error.message);
            }

            return paymentMethod.id;
        }

        default:
            return null;
    }
};

const checkout = async ({ selectedPlan, paymentType, buyer, ...values }, dispatch, props, stripe, elements) => {
    const { session } = props;
    let token;

    try {
        // get the token
        token = await getToken(paymentType, stripe, elements, values);
    } catch (error) {
        if (error instanceof StripeError) {
            // turn it into an error for redux form
            throw new SubmissionError({ _error: error.message });
        }

        // let it go
        throw error;
    }

    // try to create the subscription using our backend
    const response = await POST('/api/payment/subscribe', {
        body: {
            paymentMethodId: token,
            selectedPlanId: selectedPlan,
            buyer,
            paymentType,
        },
    });

    const { error: backendError, clientSecret, subscriptionId } = await response.json();

    if (backendError) {
        // raise the error incoming from the backend
        throw new SubmissionError({ _error: backendError });
    }

    if (clientSecret) {
        const { error: stripeError } = await stripe.confirmCardPayment(clientSecret);

        if (stripeError) {
            // raise an error incoming from stripe
            throw new SubmissionError({ _error: stripeError.message });
        }
    }

    // we're going to wait for the subscription to be completed
    // eslint-disable-next-line no-constant-condition
    while (true) {
        // update the session
        // eslint-disable-next-line no-await-in-loop
        const newSession = await session.sync();
        const { subscription } = newSession;

        if (
            subscription
            && subscription.id === subscriptionId
            && ['active', 'trialing'].includes(subscription.status)
        ) {
            // it's done
            return newSession;
        }

        // wait a bit
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, 2000));
    }
};

const CheckoutForm = ({ submitSucceeded, submitting, handleSubmit, error, session, initialValues, instanceSettings: { stripeTarification } }) => {
    const stripe = useStripe();
    const elements = useElements();

    const onSubmit = (values, dispatch, props) => checkout(values, dispatch, props, stripe, elements);

    if (submitSucceeded) {
        return (
            <Fragment>
                <p>
                    <Icon icon="check" className="text-success" />
                    &nbsp;Transaction effectuée avec succès. Cliquez <Link to="/">ici</Link> pour revenir à
                    l&apos;accueil
                </p>
                <p>
                    Votre facture vous sera envoyée par email et accessible depuis l&apos;onglet&nbsp;
                    <Link to="/profile">Mes informations</Link> une fois le paiement validé
                </p>
            </Fragment>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="checkout">
            {submitting && (
                <div className="overlay-spinner">
                    <Spinner>Transaction en cours...</Spinner>
                </div>
            )}
            <RadioQuestion
                choices={formatageDesPlansDeTarificationStripe(stripeTarification, 'plans')[session.entity.contractType]}
                name="selectedPlan"
                title="Choisissez un type d'abonnement :"
                className="triple"
            />
            <RadioQuestion
                choices={paymentTypes}
                name="paymentType"
                title="Choisissez un moyen de paiement :"
                className="triple"
            />
            <PaymentType />
            <Buyer initialValues={initialValues} />
            <div className="cgv-agreement">
                <Field
                    title={`J’autorise ${session.instanceSettings.label} à envoyer des instructions à l’établissement bancaire ayant émis ma carte afin qu’elle accepte les paiements sur mon compte bancaire, conformément au contrat qui me lie à ${session.instanceSettings.label}.`}
                    component={Checkbox}
                    name="userAgreement"
                    required
                />
                <Field
                    title={`L’abonné(e) accepte que ses coordonnées bancaires soient conservées au-delà de la transaction afin d’éviter de les saisir à nouveau à chaque échéance de son abonnement. Il est informé qu’il peut à tout moment renoncer à cette autorisation en envoyant un mail à ${session.instanceSettings.emailSupport} ou par voie postale à ${session.instanceSettings.adressePostale}.`}
                    component={Checkbox}
                    name="paymentDataAgreement"
                    required
                />
                <Field
                    title="Je déclare avoir pris connaissance des conditions générales de ventes et les accepter sans réserves."
                    component={Checkbox}
                    name="cgvAgreement"
                    required
                />
                <div>
                    <a href="/legalDisclaimer/cgv" target="_blank">
                        Lire les CGV
                    </a>
                </div>
            </div>
            {error && <div className="alert alert-danger text-center">{error}</div>}
            <div className="text-right clearfix">
                <button type="submit" className="btn btn-info submit-subscription-btn">
                    Payer
                </button>
                <span className="clearfix" />
            </div>
        </form>
    );
};

CheckoutForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    session: PropTypes.shape({
        instanceSettings: PropTypes.shape({
            label: PropTypes.string,
            emailSupport: PropTypes.string,
            adressePostale: PropTypes.string,
        }),
        entity: PropTypes.shape({
            contractType: PropTypes.string,
        }),
    }).isRequired,
    initialValues: PropTypes.shape({}).isRequired,
    instanceSettings: PropTypes.shape({
        stripeTarification: PropTypes.string,
    }),
};

CheckoutForm.defaultProps = {
    error: null,
};

export default compose(
    withInstanceSettings,
    withSession,
    reduxForm({
        form: 'plansSubscription',
        validate: formValidate,
        onSubmit: checkout,
    }),
)(CheckoutForm);
