import { get, find, reduce, startsWith, map, filter, isEmpty } from 'lodash/fp';

const singlizeCollection = (collection, owner, scis = []) => reduce((acc, item) => {
    // cas si le client passe de marié ou pacsé à concubinage
    if ('clientAndPartner' === item[owner] && 'pension' === item.type) {
        return [...acc, { ...item, [owner]: '' }];
    }
    // we attribute shared items to client now
    if ('clientAndPartner' === item[owner]) {
        return [...acc, { ...item, [owner]: 'client' }];
    }
    // we forgot partner's items or partner's sci items
    if (
        'partner' === item[owner] ||
        (
            startsWith('sci-', item[owner]) &&
            'partner' === get(owner, find(({ id }) => item[owner].replace('sci-', '') === id, scis))
        )
    ) {
        return acc;
    }
    // we keep client's items, or items that belongs to client's scis, or to client's children

    return [...acc, item];
}, [], collection);

// eslint-disable-next-line import/prefer-default-export
export const processCollections = (
    incomes,
    investments,
    scis,
    charges,
    properties,
    taxEvents,
    mainResidenceTenant,
    change,
    changeSituationFamily,
    familyEvents,
    dependents,
    dependentDeleted,
) => {
    const collections = {
        incomes: singlizeCollection(incomes, 'beneficiary'),
        investments: singlizeCollection(investments, 'subscriber'),
        sci: singlizeCollection(scis, 'owner'),
        charges: singlizeCollection(charges, 'fiscalHome'),
        property: singlizeCollection(properties, 'owner', scis),
    };
    // we clear partner's tax events
    collections.taxEvents = filter(({ income }) => map('id', collections.incomes).includes(income), taxEvents);

    // we reset haveCharges
    if (isEmpty(collections.charges)) {
        change('haveCharges', false);
    }

    // we reset mainResidence if it belonged to partner or clientAndPartner
    if (['partner', 'clientAndPartner'].includes(mainResidenceTenant)) {
        change('mainResidence', null);
        change('mainResidenceTenant', null);
    }

    for (const [path, collection] of Object.entries(collections)) {
        change(path, collection);
    }

    if (changeSituationFamily) {
        return change('familySituation', 'cohabitation');
    }

    if (dependents && dependents.length > 1 && familyEvents) {
        const dependentId = `dependent_${dependentDeleted.id}`;

        const familyEventsFiltered = familyEvents.map((event) => {
            if (dependentId === event.subject) {
                // eslint-disable-next-line no-param-reassign
                delete event.subject;
            }

            return event;
        });

        return change('familyEvents', familyEventsFiltered);
    }

    if (dependents && 1 === dependents.length && familyEvents) {
        return change('familyEvents', null);
    }

    return change('familySituation', 'single');
};
