import React from 'react';
import PropTypes from 'prop-types';

import VitalRecordForm from './vitalRecord/Form';
import withActiveListUrl from './withActiveListUrl';
import RipLayout from './RipLayout';
import withRouter from '../withRouter';

const VitalRecordInner = ({ navigate, params: { id }, back }) => (
    <RipLayout>
        <VitalRecordForm back={back} id={id} onSubmitSuccess={() => navigate(`/rip/${id}/impots`)} />
    </RipLayout>
);

VitalRecordInner.propTypes = {
    navigate: PropTypes.func,
    back: PropTypes.string.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

const VitalRecord = withActiveListUrl('back')(VitalRecordInner);

export default withRouter(VitalRecord);
