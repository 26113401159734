/*
 * Shade 1
 * '#248EDF',
 * '#74B7EB',
 * '#4AA1E4',
 * '#097ED8',
 * '#05599A',
 *
 * Shade 2
 * '#3636E4',
 * '#8181EE',
 * '#5959E9',
 * '#1616DE',
 * '#0D0DA7',
 *
 * Shade 3
 * '#17E38C',
 * '#6DEDB6',
 * '#40E7A0',
 * '#00DD7E',
 * '#00A45E',
 */

const colors = [
    '#248fdf',
    '#27cae6',
    '#a2ebac',
    '#0D0DA7',
    '#00DD7E',
    '#097ED8',
    '#00A45E',
    '#8181EE',
];

export default colors;
