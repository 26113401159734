import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { possessionTypes, acquisitionMethods } from 'common/choices';
import acquisitionAmountTitle from 'common/utils/acquisitionAmountTitle';
import { ChoiceField, IntegerField } from 'components/form/field';

const OwnerInfoInner = ({ acquisitionMethod, mortgage, possessionType }) => (
    <Fragment>
        <ChoiceField
            name="mainResidencePossessionType"
            title="Mode de détention"
            choices={possessionTypes}
            required={'closed' !== mortgage}
        />
        <IntegerField
            name="mainResidenceAcquisitionYear"
            title="Année d&apos;acquisition"
            required={'closed' !== mortgage}
        />
        <ChoiceField
            name="mainResidenceAcquisitionMethod"
            title="Mode d'acquisition"
            choices={acquisitionMethods}
            required={'closed' !== mortgage}
        />
        {acquisitionMethod ? (
            <IntegerField
                name="mainResidenceAcquisitionPrice"
                title={acquisitionAmountTitle(acquisitionMethod)}
                required={'purchase' === acquisitionMethod && 'closed' !== mortgage}
            />
        ) : null}
        <IntegerField
            name="mainResidenceEstimatedValue"
            title={'jointPossession' === possessionType ? 'Valeur totale actuelle estimée de votre bien' : 'Valeur actuelle estimée de votre bien'}
            required={'closed' !== mortgage}
        />
    </Fragment>
);

OwnerInfoInner.propTypes = {
    acquisitionMethod: PropTypes.string,
    mortgage: PropTypes.string,
    possessionType: PropTypes.string,
};

OwnerInfoInner.defaultProps = {
    acquisitionMethod: '',
    mortgage: '',
    possessionType: '',
};

const OwnerInfo = formValues({
    mortgage: 'mainResidenceMortgage',
    acquisitionMethod: 'mainResidenceAcquisitionMethod',
    possessionType: 'mainResidencePossessionType',
})(OwnerInfoInner);

export default OwnerInfo;
