import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from 'components/form/layout';
import { DateField, PercentageField, IntegerField } from 'components/form/field';

const Event = ({ name, type }) => (
    <HorizontalLayout>
        <DateField title="Date" name={`${name}.date`} />
        <IntegerField title="Montant" name={`${name}.amount`} />
        { 'payment' === type ? (
            <PercentageField title="Frais d&apos;entrée (%)" name={`${name}.fees`} />
        ) : null}
    </HorizontalLayout>
);

Event.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
};

Event.defaultProps = {
    type: null,
};

export default Event;
