import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { lifecycle, withState, mapProps } from 'recompose';
import DropDown from 'components/form/FieldDropDown';
import { GridLayout } from 'components/form/layout';
import { EditableField, FileField } from 'components/form/field';
import getOcrRib from 'utils/getOcrRib';
import withRouter from '../../../../withRouter';

const RibInner = ({
    uploader,
    dataFromFile: { error, iban, bic },
    readRib,
}) => (
    <DropDown title="Votre RIB" name="virtual.rib">
        <FileField
            icon="euro"
            name="RIB"
            uploader={uploader('RIB')}
            uploadText="Votre RIB"
            infoUploaded={
                error
                    ? 'Nous n\'avons pas réussi à extraire les données, veuillez les rentrer à la main.'
                    : null
            }
            onStatusChange={(status) => 'upload successful' === status && readRib()}
            reduxField="files.rib"
        />
        <hr />
        <GridLayout className="double condensed wide-inputs no-margin-bottom">
            <EditableField
                type="text"
                name="data.iban"
                editName="data.editableIbanCode"
                title="IBAN"
                defaultValue={iban}
            />
            <EditableField
                type="text"
                name="data.bic"
                editName="data.editableBicCode"
                title="BIC"
                defaultValue={bic}
            />
        </GridLayout>
    </DropDown>
);

RibInner.propTypes = {
    uploader: PropTypes.func.isRequired,
    readRib: PropTypes.func.isRequired,
    dataFromFile: PropTypes.shape({
        error: PropTypes.bool,
        iban: PropTypes.string,
        bic: PropTypes.string,
    }),
};

const Rib = flowRight([
    withRouter,
    withState('dataFromFile', 'setDataFromFile', { iban: null, bic: null }),
    mapProps(({ dataFromFile, setDataFromFile, uploader, params: { contractId } }) => ({
        dataFromFile,
        uploader,
        readRib: () => getOcrRib(contractId)
            .then((response) => setDataFromFile({
                ...response,
                error: null === response,
            }))
            .catch(() => setDataFromFile({ error: null })),
    })),
    lifecycle({
        componentDidMount() {
            this.props.readRib();
        },
    }),
])(RibInner);

export default Rib;
