const computeFamilyDetails = (rip) => {
    const familyDetails = {
        dependantChildren: {
            total: 0,
            disabled: 0,
        },
        alternateCustody: {
            total: 0,
            disabled: 0,
        },
        dependents: rip.dependents.length,
    };

    rip.children.forEach((child) => {
        if ('dependant' === child.situation) {
            familyDetails.dependantChildren.total += 1;
            if (child.disabled) {
                familyDetails.dependantChildren.disabled += 1;
            }
        }
        if ('alternate_custody' === child.situation) {
            familyDetails.alternateCustody.total += 1;
            if (child.disabled) {
                familyDetails.alternateCustody.disabled += 1;
            }
        }
    });

    return { familyDetails };
};

export default computeFamilyDetails;
