import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { investmentReduction } from 'common/choices';
import { investments as computeAmountInvestment } from 'common/model/summary/cappedReductions';
import { ChoiceField, IntegerField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const InvestmentReduction = ({ name, familySituation }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <ChoiceField name={`${name}.investment`} title="Type" choices={investmentReduction} required />
        <IntegerField name={`${name}.amount`} title="Montant" required />
        <Recurrent name={name} />
        <DisabledTaxField
            name={name}
            computeAmount={computeAmountInvestment({ familySituation })}
            type="reduction"
            path="computedAmount"
        />
    </HorizontalLayout>
);

InvestmentReduction.propTypes = {
    name: PropTypes.string.isRequired,
    familySituation: PropTypes.string.isRequired,
};

export default formValues('familySituation')(InvestmentReduction);
