import PropTypes from 'prop-types';
import React from 'react';
import { ControlledDropdown as Dropdown } from 'components/Dropdown';
import FormGroup from 'components/form/Group';
import { IntegerField, PercentageField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import MonthlyPaymentsResults from './MonthlyPaymentsResults';

const id = 'monthlyPayment';

const MonthlyPayment = ({ toggle, openDropdown }) => (
    <Dropdown
        title="Je calcule mes mensualités"
        toggle={() => toggle(id)}
        open={openDropdown === id}
    >
        <Title><b>Paramètres</b></Title>
        <FormGroup>
            <GridLayout className="condensed">
                <IntegerField
                    name="differedAmount"
                    title="Montant emprunté"
                    min={0}
                />
                <PercentageField
                    name="rate"
                    title="Taux du prêt"
                />
                <IntegerField
                    name="differedDuration"
                    title="Durée de l'amortissement (en mois)"
                    min={0}
                />
                <IntegerField
                    name="differedFranchise"
                    title="Durée de la franchise SI TOTALE, AVANT livraison en mois, si PARTIEL ne rien saisir"
                    min={0}
                />
            </GridLayout>
            <MonthlyPaymentsResults />
        </FormGroup>
    </Dropdown>
);

MonthlyPayment.propTypes = {
    toggle: PropTypes.func.isRequired,
    openDropdown: PropTypes.string,
};

export default MonthlyPayment;
