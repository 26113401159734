import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';
import './MiddleOfficeError.scss';

const MiddleOfficeError = ({ error }) => (
    <Fragment>
        <div className="user-error text-center">
            <Icon icon="exclamation-triangle" />
            <h3>Une erreur est survenue lors de la connexion au middle office</h3>
            <p>{error}</p>
        </div>
    </Fragment>
);

MiddleOfficeError.propTypes = {
    error: PropTypes.string.isRequired,
};

export default MiddleOfficeError;
