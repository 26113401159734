import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import LifeInsuranceBaseProducts from './LifeInsuranceBaseProducts';
import LifeInsuranceTypes from './LifeInsuranceTypes';

const LifeInsuranceSettings = ({ settings }) => (
    <Tabs id="life-insurance-tabs">
        <Tab eventKey="lifeInsuranceSupport" title="Supports">
            <LifeInsuranceBaseProducts products={settings.baseProducts} types={settings.types} />
        </Tab>
        <Tab eventKey="lifeInsuranceContract" title="Contrats">
            <LifeInsuranceTypes baseProducts={settings.baseProducts} types={settings.types} />
        </Tab>
    </Tabs>
);

LifeInsuranceSettings.propTypes = {
    settings: PropTypes.shape({
        baseProducts: PropTypes.shape({}).isRequired,
        types: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default LifeInsuranceSettings;
