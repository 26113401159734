const float = (value) => {
    const parsedValue = parseFloat(value);
    if (Number.isNaN(parsedValue)) {
        return 'NC';
    }

    return parsedValue.toLocaleString('fr-FR', { style: 'decimal' });
};

export default float;
