import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { flowRight } from 'lodash/fp';
import { formValues } from 'redux-form';
import { withPropsOnChange } from 'recompose';
import withTargetRecommendations from '../../approval/withTargetRecommendations';
import RecommendationsLists from './RecommendationLists';
import { PrecoChoices } from './PrecoChoices';
import SelectContact from '../../field/SelectContact';
import { useSession } from '../../../sessions';

const renderCifComponent = (choicePreco, name, recommendations, rip, target, isCif, setIsCif) => {
    const session = useSession();
    setIsCif('cif' === choicePreco);
    const isSimulation = 'simulation' === choicePreco;

    return (
        <div>
            <PrecoChoices required />
            <SelectContact rip={rip} isCif={isCif} isSimulation={isSimulation} />
            {choicePreco &&
                <RecommendationsLists
                    name={name}
                    recommendations={recommendations}
                    isCif={isCif}
                    rip={rip}
                    target={target}
                    choicePreco={choicePreco}
                    needApprovalOnCIFDocument={session.needApprovalOnCIFDocument}
                    needApprovalOnSimulations={session.needApprovalOnSimulations}
                />}
        </div>
    );
};

const Recommendations = ({ rip, recommendations, name, choicePreco, target, enabled, isCif, setIsCif, setTarget }) => {
    setTarget(target);

    return (
        <Fragment>
            {enabled ?
                renderCifComponent(choicePreco, name, recommendations, rip, target, isCif, setIsCif)
                :
                <RecommendationsLists
                    name={name}
                    recommendations={recommendations}
                />}
        </Fragment>
    );
};

Recommendations.propTypes = {
    name: PropTypes.string.isRequired,
    setTarget: PropTypes.func,
    recommendations: PropTypes.shape({}),
    rip: PropTypes.shape({
        personalEmail: PropTypes.string.isRequired,
        partnerPersonalEmail: PropTypes.string.isRequired,
        idBoxShoyo: PropTypes.string.isRequired,
        partnerIdBoxShoyo: PropTypes.string.isRequired,
    }),
    choicePreco: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    isCif: PropTypes.bool.isRequired,
    setIsCif: PropTypes.func.isRequired,
};

Recommendations.defaultProps = {
    recommendations: {},
};

export default flowRight([
    withTargetRecommendations,
    formValues('target', 'choicePreco'),
    withPropsOnChange(['rip', 'choicePreco'], ({ rip, choicePreco }) => {
        if ('simulation' === choicePreco && ('cohabitation' !== rip.familySituation)) {
            return {
                target: 'client',
            };
        }

        return null;
    }),
])(Recommendations);
