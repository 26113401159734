import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { branch, mapProps, renderNothing } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formValues } from 'redux-form';
import Icon from '../../common/components/Icon';
import HomeWorkSVG from '../../images/pictos/travaux-a-domicile';
import DividendesSVG from '../../images/pictos/dividences';

import './TypedCollectionItem.css';

const TypedCollectionItemInner = ({ fields, index, name, type: { component: Component, icon, path, title, ...extraProps }, textOnly }) => (
    <div className="form-typed-collection-item">
        <button type="button" onClick={() => fields.remove(index)} className="form-typed-collection-item-add">
            <Icon icon="times-circle" />
        </button>
        <div className={`form-typed-collection-item-type${textOnly ? ' textOnly' : ''}`} title={title}>
            {!textOnly ? <FontAwesomeIcon icon={icon} path={path} /> : <p>{title}</p>}
            {'prime' === path ? <DividendesSVG /> : null}
            {'homeWork' === path ? <HomeWorkSVG /> : null}

        </div>
        <Component name={name} extra={extraProps} />
    </div>
);

TypedCollectionItemInner.propTypes = {
    fields: PropTypes.shape({
        remove: PropTypes.func.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    textOnly: PropTypes.bool.isRequired,
    type: PropTypes.shape({
        component: PropTypes.func.isRequired,
        icon: PropTypes.string,
        path: PropTypes.string,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

const TypedCollectionItem = flowRight([
    formValues(({ name }) => ({
        type: `${name}.type`,
    })),
    mapProps(({ type, types, ...props }) => ({
        type: types[type],
        ...props,
    })),
    branch(
        ({ type }) => !type,
        renderNothing,
    ),
])(TypedCollectionItemInner);

export default TypedCollectionItem;
