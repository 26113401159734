import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { IntegerField, FloatField, TextField, PercentageField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';
import LoanRange from '../LoanRange';

const OtherLoan = ({ name, type }) => (
    <HorizontalLayout>
        {!['other', 'carLoan'].includes(type) && (
            <OwnerField name={`${name}.fiscalHome`} title="Titulaire concerné" required />
        )}
        <TextField name={`${name}.name`} title="Nom" />
        <HorizontalLayout>
            <TextField name={`${name}.bankName`} title="Quelle est votre banque ?" />
            <IntegerField name={`${name}.initialAmount`} title="Montant initial" />
            <PercentageField name={`${name}.interestRate`} title="Taux d'intérêt" />
            <FloatField name={`${name}.monthlyPayment`} title="Mensualités" required />
            <IntegerField name={`${name}.remainingCapital`} title="Capital restant dû" />
            <LoanRange
                startName={`${name}.start`}
                startTitle="Début"
                endName={`${name}.end`}
                endTitle="Fin"
                durationName={`${name}.duration`}
                durationTitle="Durée en mois"
            />
        </HorizontalLayout>
    </HorizontalLayout>
);

OtherLoan.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default formValues(({ name }) => ({
    type: `${name}.type`,
}))(OtherLoan);
