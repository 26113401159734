import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { SelectField } from '../../form/field';

/* eslint react/no-array-index-key: 0 */

const HouseholdLeaveSubjectInner = ({ dependents, ...props }) => (
    <SelectField {...props}>
        <option />
        {(dependents && 0 !== dependents.length) ? (
            <optgroup label="Autres personnes à charge">
                {dependents.map(({ id, type }, index) => (
                    <option key={index} value={`dependent_${id}`}>{type}</option>
                ))}
            </optgroup>
        ) : null}
    </SelectField>
);

HouseholdLeaveSubjectInner.propTypes = {
    dependents: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
    })),
};

HouseholdLeaveSubjectInner.defaultProps = {
    dependents: [],
};

const HouseholdLeaveSubject = formValues({
    dependents: 'dependents',
})(HouseholdLeaveSubjectInner);

export default HouseholdLeaveSubject;
