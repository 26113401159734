import { clamp } from 'lodash/fp';

// La plupart des valeurs rentrées dans le formulaire LMNP sont HT.

const CensiBouvardUpperBound = 300000;
const clampCensiBouvard = clamp(0, CensiBouvardUpperBound);
const getTvaRate = (tva) => parseFloat(tva, 10);

const computeInstalmentCosts = ({
    parkingLotWithSeparateLease,
    propertyValue,
    notaryFees,
    otherAcquisitionCost,
    propertyMonthlyRent,
    parkingLotValue,
    editableNotaryFees,
    editableOtherAcquisitionCost,
    parkingLotRent,
    editablePropertyTaxEstimated,
    propertyTaxEstimated,
    needFunding,
    data: {
        program: {
            type,
        } = {},
        property: {
            landValue,
            furnitureValue,
            estateBrokerageFees,
        },
        fraisCourtage = 0,
        taxBenefitBasis: {
            value: taxBenefitBasisValue,
            edited: taxBenefitBasisEdited,
        } = {},
        funding: {
            tvaFunder,
        } = {},
        tvaRates: {
            furnitureTvaRate = 0.2,
            landTvaRate = 0.2,
            parkingTvaRate = 0.2,
            propertyTvaRate = 0.2,
        } = {},
    },
}) => {
    // Loyer total mensuel
    const monthlyRent = parseFloat(propertyMonthlyRent) + (parkingLotWithSeparateLease ? parkingLotRent : 0);
    // calculate yearly rent
    const yearlyRent = 12 * monthlyRent;
    // Valeur de l'immobilier soumise à amortissement (avant possible réduction Censi-Bouvard)
    const estateAmount = estateBrokerageFees + propertyValue + parkingLotValue;

    // Valeur de l'immobilier, soumise à indexation
    const amount = estateAmount + landValue;

    // then calculate the TVA
    // rawTVA displayed in TVA field
    const rawTVA = (furnitureValue * getTvaRate(furnitureTvaRate))
        + (landValue * getTvaRate(landTvaRate))
        + (estateBrokerageFees * 0.2)
        + (propertyValue * getTvaRate(propertyTvaRate))
        + (parkingLotValue * getTvaRate(parkingTvaRate));

    // estateBrokerageFees + propertyValue + parkingLotValue +
    // landValue + furnitureValue:
    const investmentWithoutFees = amount + furnitureValue;
    const investmentWithoutFeesWithTVA = rawTVA + investmentWithoutFees;

    // Calcul des frais :
    const defaultNotaryFees = investmentWithoutFeesWithTVA * 0.03;
    const defaultOtherAcquisitionCost = false === needFunding ? 0 : investmentWithoutFeesWithTVA * 0.02;

    // Frais de notaire et autres frais d'acquisition.
    const totalOtherAcquisitionCost = (editableNotaryFees ? notaryFees : defaultNotaryFees) +
        (editableOtherAcquisitionCost ? otherAcquisitionCost : defaultOtherAcquisitionCost);

    // calculate the investment
    const investment = investmentWithoutFees + totalOtherAcquisitionCost;
    const investmentWithTVA = investmentWithoutFeesWithTVA + totalOtherAcquisitionCost;

    const promoterTVA = (
        (propertyValue * getTvaRate(propertyTvaRate))
            + (parkingLotValue * getTvaRate(parkingTvaRate))
            + (landValue * getTvaRate(landTvaRate))
    );

    // refundable TVA:
    const tva = 'promoter' === tvaFunder ?
        rawTVA - promoterTVA :
        rawTVA;

    const rawDefaultTaxBenefitBasisValue = amount
        + totalOtherAcquisitionCost
        + fraisCourtage;

    const hypotheticBenefitBasis = !taxBenefitBasisEdited ?
        clampCensiBouvard(rawDefaultTaxBenefitBasisValue) :
        taxBenefitBasisValue;

    const taxBenefitBasis = 'censi-bouvard' === type ? hypotheticBenefitBasis : 0;

    // Valeur de l'immobilier amortissable. Le terrain ne s'amortit jamais.
    const estateDepreciationAmount = 'censi-bouvard' === type ?
        Math.max(0, (rawDefaultTaxBenefitBasisValue - CensiBouvardUpperBound)) :
        estateAmount;

    // Taxe foncière
    const propertyTax = editablePropertyTaxEstimated ?
        propertyTaxEstimated :
        1.1 * monthlyRent;

    return {
        instalmentCosts: {
            amount,
            estateDepreciationAmount,
            totalOtherAcquisitionCost,
            defaultNotaryFees,
            defaultOtherAcquisitionCost,
            investment,
            investmentWithoutFeesWithTVA,
            investmentWithTVA,
            tva,
            landTvaRate,
            rawTVA,
            monthlyRent,
            yearlyRent,
            taxBenefitBasis,
            propertyTax,
            promoterTVA,
        },
    };
};

export default computeInstalmentCosts;
