import { get } from 'lodash/fp';
import getSettings from '../../../utils/settings';

const getMedianRate = () => {
    const settings = getSettings();

    return {
        P: get('calculator.lifeInsurance.profiles.P', settings),
        E: get('calculator.lifeInsurance.profiles.E', settings),
        DE: get('calculator.lifeInsurance.profiles.DE', settings),
        D: get('calculator.lifeInsurance.profiles.D', settings),
        livretA: get('finance.livretARate', settings),
        inflation: get('calculator.lifeInsurance.inflation', settings),
    };
};

export default getMedianRate;
