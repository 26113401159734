import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { size } from 'lodash/fp';
import { acquisitionMethods, fiscalFrameworks, possessionTypes, detaxTypes } from 'common/choices';
import { formValues } from 'redux-form';
import acquisitionAmountTitle from 'common/utils/acquisitionAmountTitle';
import { formatFullDate } from 'common/utils/format';
import { availableEstateForReductions, availableForCapExclusion } from 'common/model/summary/cappedReductions';
import { availableEstateForDeductions } from 'common/model/summary/deductions';
import Mortgages from './Mortgages';
import { GridLayout } from '../../form/layout';
import { ChoiceField, DateField, InputField, IntegerField, YesNoField, DisabledField } from '../../form/field';
import OwnerField from '../field/Owner';

const PropertyCommon = ({
    type,
    name,
    other,
    mortgages,
    fiscalFramework,
    acquisitionMethod,
    possessionType,
    rental,
    detaxType,
    detaxEndDate,
    owner,
    prorogatedReduction,
}) => {
    const withMortages = size(mortgages) > 0;

    return (
        <Fragment>
            <GridLayout className="triple condensed full-width">
                {other ? <InputField title="Précisez" required type="text" name={`${name}.description`} /> : null}
                <OwnerField name={`${name}.owner`} title="Propriétaire(s)" required showSci />
                <InputField title="Ville" type="text" name={`${name}.city`} />
                <ChoiceField
                    name={`${name}.possessionType`}
                    title="Mode de détention"
                    choices={possessionTypes}
                    required={withMortages}
                />
                <IntegerField title="Année d'acquisition" name={`${name}.acquisitionYear`} required={withMortages} />
                <ChoiceField
                    name={`${name}.acquisitionMethod`}
                    title="Mode d'acquisition"
                    choices={acquisitionMethods}
                    required={withMortages}
                />
                {acquisitionMethod ? (
                    <IntegerField
                        name={`${name}.acquisitionPrice`}
                        title={acquisitionAmountTitle(acquisitionMethod, 'jointPossession' === possessionType)}
                        required={'purchase' === acquisitionMethod && withMortages}
                    />
                ) : null}
                <IntegerField name={`${name}.estimatedValue`} title="Valeur actuelle estimée de votre bien" required />
                {'secondHome' !== type && <IntegerField
                    title={
                        rental && 'jointPossession' === possessionType
                            ? 'Votre quote-part de revenus locatifs mensuels'
                            : 'Revenus locatifs mensuels'
                    }
                    required
                    name={`${name}.rentalIncome`}
                />}

                {rental && (
                    <Fragment>
                        <ChoiceField
                            name={`${name}.fiscalFramework`}
                            title="Cadre Fiscal"
                            choices={fiscalFrameworks}
                            placeholder={null}
                        />
                        {'other' === fiscalFramework && (
                            <ChoiceField
                                name={`${name}.detaxType`}
                                title="Type d'avantage fiscal"
                                choices={detaxTypes}
                                placeholder={null}
                            />
                        )}
                        {(availableEstateForDeductions.includes(fiscalFramework) ||
                            ('other' === fiscalFramework && 'deduction' === detaxType)) ? (
                                <Fragment>
                                    <IntegerField title="Montant de la déduction" name={`${name}.detaxAmount`} />
                                    <DateField title="Date de début d'application" name={`${name}.detaxStartDate`} />
                                    <DateField title="Date de fin d'application" name={`${name}.detaxEndDate`} />
                                </Fragment>
                            ) : null}
                        {(availableEstateForReductions.includes(fiscalFramework) ||
                            ('other' === fiscalFramework && 'reduction' === detaxType)) && (
                            <Fragment>
                                <IntegerField title="Montant de la réduction" name={`${name}.detaxAmount`} />
                                <DateField title="Date de début d'application" name={`${name}.detaxStartDate`} />
                                <DateField title="Date de fin d'application" name={`${name}.detaxEndDate`} />
                            </Fragment>
                        )}
                        {['ScellierIntermediaire', 'Pinel', 'Pinel+', 'Denormandie'].includes(fiscalFramework) && (
                            <Fragment>
                                <YesNoField
                                    title="Proroger la réduction d'impôt ?"
                                    name={`${name}.prorogatedReduction`}
                                    required
                                    double
                                    info="Prorogation de l'engagement de location après 9 ans"
                                />
                                {prorogatedReduction && (
                                    <Fragment>
                                        <IntegerField
                                            title="Montant de réduction prorogée"
                                            name={`${name}.prorogationDetaxAmount`}
                                            required
                                        />
                                        <DisabledField
                                            title="Date de début de réduction prorogée"
                                            name={`${name}.prorogationDetaxStartDate`}
                                            value={formatFullDate(detaxEndDate)}
                                        />
                                        <DateField
                                            title="Date de fin de réduction prorogée"
                                            name={`${name}.prorogationDetaxEndDate`}
                                            required
                                        />
                                    </Fragment>
                                )}
                            </Fragment>
                        )}

                        {availableForCapExclusion.includes(fiscalFramework) && (
                            <YesNoField
                                title="Soumis au plafonnement des 10 000€ ?"
                                name={`${name}.includeInReductions`}
                                required
                                double
                            />
                        )}
                    </Fragment>
                )}
            </GridLayout>
            <Mortgages name={`${name}.mortgages`} owner={owner} />
        </Fragment>
    );
};

PropertyCommon.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    other: PropTypes.bool,
    acquisitionMethod: PropTypes.string,
    fiscalFramework: PropTypes.string,
    mortgages: PropTypes.arrayOf(PropTypes.shape({})),
    possessionType: PropTypes.string,
    rental: PropTypes.bool,
    detaxType: PropTypes.string,
    detaxEndDate: PropTypes.string,
    owner: PropTypes.string,
    prorogatedReduction: PropTypes.bool,
};

PropertyCommon.defaultProps = {
    type: null,
    other: false,
    mortgages: [],
    acquisitionMethod: null,
    fiscalFramework: null,
    possessionType: null,
    detaxType: null,
    detaxEndDate: null,
    owner: null,
    prorogatedReduction: false,
};

export default formValues(({ name }) => ({
    acquisitionMethod: `${name}.acquisitionMethod`,
    fiscalFramework: `${name}.fiscalFramework`,
    mortgages: `${name}.mortgages`,
    possessionType: `${name}.possessionType`,
    detaxType: `${name}.detaxType`,
    detaxEndDate: `${name}.detaxEndDate`,
    owner: `${name}.owner`,
    prorogatedReduction: `${name}.prorogatedReduction`,
}))(PropertyCommon);
