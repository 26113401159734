import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { YesNoField, IntegerField } from '../form/field';
import { HorizontalLayout } from '../form/layout';

const RecurrentInner = ({ name, recurrentEvent }) => (
    <HorizontalLayout>
        <YesNoField name={`${name}.recurrentEvent`} title="Récurrent ?" required />
        {false === recurrentEvent ? <IntegerField name={`${name}.endYear`} title="Année de fin" required /> : null}
    </HorizontalLayout>
);

RecurrentInner.propTypes = {
    name: PropTypes.string.isRequired,
    recurrentEvent: PropTypes.bool.isRequired,
};

const Recurrent = formValues(({ name }) => ({
    recurrentEvent: `${name}.recurrentEvent`,
}))(RecurrentInner);

export default Recurrent;
