import PropTypes from 'prop-types';
import React from 'react';
import { withState } from 'recompose';
import Summary from '../Summary';
import hasSigned from '../../../utils/hasSigned';
import Signatures from './Signatures/Signatures';

const FormInner = ({ back, rip, hasReadSummary, update }) => (hasReadSummary ? (
    <Signatures back={back} rip={rip} />
) : (
    <Summary
        back={back}
        handleSubmit={(e) => {
            // prevent the event (deny the redirection)
            e.preventDefault();
            // then update to move on the next step (signatures)
            update(true);
        }}
        initialValues={rip}
    />
));

FormInner.propTypes = {
    back: PropTypes.string.isRequired,
    hasReadSummary: PropTypes.bool,
    rip: PropTypes.shape({}).isRequired,
    update: PropTypes.func.isRequired,
};

FormInner.defaultProps = {
    hasReadSummary: null,
};

const Form = withState('hasReadSummary', 'update', ({ rip }) => hasSigned(rip))(FormInner);

export default Form;
