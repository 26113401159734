import React from 'react';
import PropTypes from 'prop-types';
import FormButtons from 'components/FormButtons';
import requestApprovalFactory from '../../approval/requestApprovalFactory';
import Form from './Form';
import Header from './Header';

export const ApprovalForm = ({ handleSubmit, children, params: { id, simulationId } }) => (
    <Form handleSubmit={handleSubmit}>
        <div className="simulation-form-inner" id="simulationFormInner">
            <div className="container">
                <Header />
                {children}
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/simulation`}
            list={`/rip/${id}/simulations/`}
            nextButtonContent="Envoyer"
        />
    </Form>
);

ApprovalForm.propTypes = {
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string,
        simulationId: PropTypes.string,
    }),
};

export default requestApprovalFactory({ formComponent: ApprovalForm });
