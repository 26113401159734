import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import dataForm from 'components/dataForm';
import FormButtons from 'components/FormButtons';
import Title from 'components/Title';
import Form from 'components/simulation/FormStep';
import Checkbox from 'components/form/Checkbox';
import contractLoader from '../../contractLoader';
import processValues from '../processValues';
import formValidate from './formValidate';
import getPerData from '../../../../common/utils/getPerData';
import PdfComponent from '../../../editPdf/pdfComponent';

import './Verify.scss';

const prerequisites = {
    cardif: {
        isPhysicalPerson: 'Votre client est une personne physique',
        is18orMore: 'Votre client est majeur',
        isCapable: 'Il s\'agit d\'un majeur capable',
        isNotGuarded: 'Qu\'il n’est pas soumis à un régime de protection (tutelle, curatelle …)',
        isFrenchTaxResident: 'Qu\'il est résident fiscal français',
        agreeWithTheseisAllocation: 'Qu\'il accepte l’allocation préconisée par son conseiller lors du précédent entretien',
        allDocumentsAreEnclosed: 'Que l\'ensemble des justificatifs nécéssaires à la souscription sont présents au dossier',
    },
    mma: {
        isPhysicalPerson: 'Votre client est une personne physique',
        is18orMore: 'Votre client est majeur',
        isCapable: 'Il s\'agit d\'un majeur capable',
        isNotGuarded: 'Qu\'il n’est pas soumis à un régime de protection (tutelle, curatelle …)',
        isFrenchTaxResident: 'Qu\'il est résident fiscal français',
        agreeWithTheseisAllocation: 'Qu\'il accepte l’allocation préconisée par son conseiller lors du précédent entretien',
        allDocumentsAreEnclosed: 'Que l\'ensemble des justificatifs nécéssaires à la souscription sont présents au dossier',
    },
};

export const prerequisitesNames = (contractType = '') => Object.keys(prerequisites[getPerData(contractType, 'cardif', 'mma')]);

export const verifyPerPrerequisites = (verify, contractType) => verify && prerequisitesNames(getPerData(contractType, 'cardif', 'mma')).reduce((result, value) => result && verify[value], true);

const VerifyInner = ({
    handleSubmit,
    params: { id, contractType },
}) => {
    const iscardif = 'cardif' === getPerData(contractType, 'cardif', 'mma');

    return (
        <Form handleSubmit={handleSubmit}>
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <Title>
                        <b>
                            Afin de confirmer la possibilité de réaliser la souscription en signature électronique, nous
                            vous remercions de confirmer que :
                        </b>
                    </Title>
                    {Object.entries(prerequisites[getPerData(contractType, 'cardif', 'mma')]).map(([name, title]) => (
                        <Fragment>
                            {'allDocumentsAreEnclosed' === name ?
                                <div className="div-fields">
                                    <Field
                                        key={name}
                                        title={title}
                                        component={Checkbox}
                                        name={`data.verify.${name}`}
                                    />
                                    <PdfComponent route={`/api/documents/pdf/${iscardif ? 'checklist-per-cardif-2022' : 'checklist-per-mma-2024'}`} />
                                </div>
                                :
                                <Field
                                    key={name}
                                    title={title}
                                    component={Checkbox}
                                    name={`data.verify.${name}`}
                                />}
                        </Fragment>
                    ))}
                </div>
            </div>
            <FormButtons
                back={`/rip/${id}/contracts/`}
                list={`/rip/${id}/contracts/`}
            />
        </Form>
    );
};

VerifyInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
        contractType: PropTypes.string,
    }).isRequired,
};

const Verify = flowRight([
    contractLoader,
    dataForm({
        autoSave: false,
        processValues,
        form: 'contract-verify',
        id: ({ params: { contractId } }) => contractId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, contractId, contractType },
        }) => navigate(`/rip/${id}/contracts/${contractId}/conditions/${contractType}`),
        type: 'contract',
        validate: formValidate,
    }),
])(VerifyInner);

export default Verify;
