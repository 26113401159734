import PropTypes from 'prop-types';
import React from 'react';
import Title from 'components/Title';
import Group from 'components/form/Group';
import ProductChart from 'components/simulation/types/lifeInsurance/Product/ProductChart';
import useSettings from 'components/sessions/useSettings';

const Contract = ({
    simulation: {
        data: {
            profile,
            contractType,
        },
    },
}) => {
    const { name: contractName } = useSettings(['simulation', 'lifeInsurance', 'types', contractType]);

    return (
        <Group>
            <Title>
                <b>
                    La r&eacute;partition de votre contrat {contractName} en ad&eacute;quation avec votre profil
                    investisseur
                </b>
            </Title>
            <ProductChart profile={profile} contractType={contractType} withCodes />
        </Group>
    );
};

Contract.propTypes = {
    simulation: PropTypes.shape({
        data: PropTypes.shape({
            profile: PropTypes.shape({}),
            contractType: PropTypes.number,
        }),
    }).isRequired,
};

export default Contract;
