const groups = {
    lifeInsurance: 'Assurance-Vie',
    credit: 'Crédit/ADI',
};

export const lifeInsuranceProfiles = {
    P: 'Prudent',
    E: 'Équilibré',
    DE: 'Dynamique Équilibre Immo',
    D: 'Dynamique',
};

export default groups;
