import React, { Fragment } from 'react';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const MicroBIC = () => (
    <Fragment>
        <Title>Micro BIC</Title>
        <GridLayout className="double condensed full-width">
            <IntegerField
                name="summary.incomeTax.microBICAbatement.trade.min"
                title="Seuil (Vente de marchandises ou logement) (€)"
            />
            <PercentageFloatField
                name="summary.incomeTax.microBICAbatement.trade.rate"
                title="Abattement (Vente de marchandises ou logement) (%)"
            />
            <IntegerField
                name="summary.incomeTax.microBICAbatement.services.min"
                title="Seuil (Prestation de service ou location meublée) (€)"
            />
            <PercentageFloatField
                name="summary.incomeTax.microBICAbatement.services.rate"
                title="Abattement (Prestation de service ou location meublée) (%)"
            />
            <IntegerField
                name="summary.incomeTax.microBICPlafond.commerce"
                title="Plafond (Vente de marchandises ou logement) (€)"
            />
            <IntegerField
                name="summary.incomeTax.microBICPlafond.services"
                title="Plafond (Prestation de service ou location meublée) (€)"
            />
        </GridLayout>
    </Fragment>
);

export default MicroBIC;
