import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { childCare } from 'common/model/summary/credits';
import shouldDisplayAlternateCustodyChildren from 'common/utils/alternateCustody';
import { IntegerField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const ChildCare = ({ name, children }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <IntegerField name={`${name}.amount`} title="Montant annuel des dépenses" required />
        <IntegerField name={`${name}.dependentChildren`} title="Enfants concernés (à charge)" required />
        {shouldDisplayAlternateCustodyChildren(children) && (
            <IntegerField
                name={`${name}.alternateCustodyChildren`}
                title="Enfants concernés (garde alternée)"
                required
            />
        )}
        <Recurrent name={name} />
        <DisabledTaxField
            type="credit"
            name={name}
            computeAmount={childCare}
        />
    </HorizontalLayout>
);

ChildCare.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default formValues('children')(ChildCare);
