import { get, map, min } from 'lodash/fp';
import computeInvestorProfile from 'common/model/rip/investorProfile';
import getSettings from 'common/utils/settings';

const initialValues = ({ beneficiary, subscriptionChoice }, { rip: { investorProfiles } }) => {
    const perTypes = getSettings('simulation.per.contracts');

    const defaultFees = min(map(({ fees: { max } }) => max, perTypes));

    return {
        data: {
            profile: computeInvestorProfile(get(beneficiary, investorProfiles)),
            entryFees: defaultFees,
            scheduledPayments: { fees: defaultFees },
            subscriptionChoice,
        },
    };
};

export default initialValues;
