import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createElement } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../common/components/Icon';
import IconFontAwesome from '../Icone/IconFontAwesome';

import './Action.css';

const onClickDisabled = (event) => {
    event.preventDefault();
};

const Action = ({ disabled, href, color, statut, iconNew, icon, onClick, right, to, text, className: actionClass, nonVisible }) => {
    const className = classNames('list-action', actionClass, { disabled, right });
    let children = [];
    if (iconNew) {
        children = [
            createElement(IconFontAwesome, { icon, color, statut }),
            createElement('span', {}, text),
        ];
    } else {
        children = [
            createElement(Icon, { icon }),
            createElement('span', {}, text),
        ];
    }

    if (nonVisible) {
        return null;
    }

    if (to) {
        return createElement(Link, { className, onClick: disabled ? onClickDisabled : null, to }, ...children);
    }

    if (href) {
        return createElement('a', { className, href, onClick: disabled ? onClickDisabled : null }, ...children);
    }

    return createElement('button', { className, disabled, onClick, type: 'button' }, ...children);
};

Action.propTypes = {
    disabled: PropTypes.bool,
    href: PropTypes.string,
    color: PropTypes.string,
    statut: PropTypes.string,
    icon: PropTypes.string.isRequired,
    iconNew: PropTypes.bool,
    onClick: PropTypes.func,
    right: PropTypes.bool,
    to: PropTypes.string,
    text: PropTypes.string.isRequired,
    nonVisible: PropTypes.bool,
    className: PropTypes.string,
};

Action.defaultProps = {
    color: null,
    statut: null,
    iconNew: false,
    disabled: false,
    href: null,
    onClick: null,
    right: false,
    to: null,
    nonVisible: false,
};

export default Action;
