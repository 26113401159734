import React, { useState } from 'react';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { reduxForm } from 'redux-form';
import { useFlag } from '@unleash/proxy-client-react';

import { SelectField, TextAreaField } from 'components/form/field';
import dataProvider from 'components/dataProvider';
import { filterRecommendations } from 'common/utils/recommendations';
import { setModal } from 'actions';
import SuccessModalForPreco from '../../../SuccessModalForPreco';
import { withSession } from '../../../sessions';
import TargetField from '../../approval/TargetField';
import RipFormFields from '../../FormFields';
import SendButton from '../../../SendButton';
import Download from '../../Download';
import Recommendations from './Recommendations';
import Spinner from '../../../Spinner';
import Approved from './Approved';
import getPrecoChoiceParameters from '../../../../utils/getPrecoChoiceParameters';
import './Form.scss';

const maySelectRecipient = (session) => get('settings.canSelectRecommendationRecipient', session)
    && get('entity.managerId', session)
    && get('entity.caseManagerId', session);

const FormInner = ({ rip, session, ...props }) => {
    const { instanceTheseis } = session;
    const [isCif, setIsCif] = useState(false);
    const [target, setTarget] = useState('client');

    const enabled = useFlag('erip-1223-CIF');

    return (
        <RipFormFields {...props}>
            <div className="recommendation">
                <Download rip={rip} isInstanceTheseis={instanceTheseis} />
                {(rip.recommendations || rip.partner) && <Approved recommendations={'client' === target ? rip.recommendations : rip.partner && rip.partner.recommendations} />}
                {!enabled && <TargetField rip={rip} enabled={enabled} />}
                <Recommendations rip={rip} enabled={enabled} setIsCif={setIsCif} isCif={isCif} setTarget={setTarget} />
                <TextAreaField name={isCif ? 'recommendationCommentsCIF' : 'recommendationComments'} title="Message pour mon validateur" />
                {maySelectRecipient(session) ? (
                    <SelectField name="recommendationRecipient" title="Destinataire" required>
                        <option />
                        <option value="manager">Mon manager</option>
                        <option value="caseManager">Mon charg&eacute; d&apos;affaires</option>
                        <option value="both">Mon manager et mon charg&eacute; d&apos;affaires</option>
                    </SelectField>
                ) : null}
                <SendButton />
            </div>
        </RipFormFields>
    );
};

FormInner.propTypes = {
    rip: PropTypes.shape({
        recommendations: PropTypes.shape({}),
        partner: PropTypes.shape({
            recommendations: PropTypes.shape({}),
        }),
    }).isRequired,
    session: PropTypes.shape({
        instanceTheseis: PropTypes.bool,
        needApprovalOnCIFDocument: PropTypes.bool,
    }).isRequired,
};

const Form = compose(
    dataProvider({
        id: ({ id }) => id,
        type: 'rip',
        wait: true,
    }),
    withProps(({ rip }) => ({
        initialValues: ({
            target: 'client',
            recommendations: filterRecommendations(rip.recommendations),
            partner: {
                recommendations: filterRecommendations(get('partner.recommendations', rip)),
                recommendationsCIF: filterRecommendations(get('partner.recommendationsCIF', rip)),
            },
            recommendationComments: rip.recommendationComments,
            recommendationsCIF: filterRecommendations(rip.recommendationsCIF),
            recommendationCommentsCIF: rip.recommendationCommentsCIF,
            choicePreco: getPrecoChoiceParameters(),
        }),
    })),
    reduxForm({
        form: 'rip-recommendation',
        id: ({ id }) => id,
        type: 'rip',
        onSubmitSuccess: (result, dispatch, { values: { choicePreco } }) => {
            const isCif = 'cif' === choicePreco;

            return (dispatch(setModal(() => (<SuccessModalForPreco isCif={isCif} />), false)));
        },
        onSubmitFail: () =>
            alert('Erreur lors de l\'envoi. Vérifiez votre connexion internet et réessayez dans quelques instants.'),
    }),
    connect(
        null,
        (dispatch, { handleSubmit }) => ({
            handleSubmit: (event) => {
                event.preventDefault();
                dispatch(setModal(() => <Spinner>Envoi de l&apos;email en cours...</Spinner>, false));
                handleSubmit(event);
            },
        }),
    ),
    withSession,
)(FormInner);

export default Form;
