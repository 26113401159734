// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.username {
    float: left;
}

.rip-stats {
    float: left;
    margin-left: 20px;
    height: 100%;
}

.rip-stats > div {
    float: left;
    height: 100%;
    margin-top: -5px;
    text-align: center;
    width: 130px;
}

.transfer-rips {
    float: right;
    color: #464646;
}

.rip-stats > div.rip-stat-title {
    line-height: 50px;
}

.rip-stats > div > span {
    display: block;
}

.rip-stats .stat-value {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
}

.rip-stats .stat-legend {
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/TeamList/Item.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".username {\n    float: left;\n}\n\n.rip-stats {\n    float: left;\n    margin-left: 20px;\n    height: 100%;\n}\n\n.rip-stats > div {\n    float: left;\n    height: 100%;\n    margin-top: -5px;\n    text-align: center;\n    width: 130px;\n}\n\n.transfer-rips {\n    float: right;\n    color: #464646;\n}\n\n.rip-stats > div.rip-stat-title {\n    line-height: 50px;\n}\n\n.rip-stats > div > span {\n    display: block;\n}\n\n.rip-stats .stat-value {\n    font-size: 1.2em;\n    font-weight: bold;\n    text-align: center;\n}\n\n.rip-stats .stat-legend {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
