import React, { Fragment } from 'react';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import { VerticalLayout, GridLayout } from 'components/form/layout';
import { DateField, YesNoField, IntegerField } from 'components/form/field';
import SCPICollection from './SCPICollection';
import Investment from './Investment';

const Operation = () => (
    <Fragment>
        <FormGroup>
            <Title><b>L&apos;opération</b></Title>
            <GridLayout className="condensed">
                <DateField
                    name="data.subscriptionDate"
                    title="Date de souscription"
                    required
                />
                <IntegerField
                    name="data.duration"
                    title="Durée de l'investissement"
                    required
                />
            </GridLayout>
        </FormGroup>
        <SCPICollection required />
        <VerticalLayout>
            <Investment />
            <YesNoField
                name="data.needFunding"
                title="Avez-vous besoin d&apos;ajouter un financement ?"
                required
            />
        </VerticalLayout>
    </Fragment>
);

export default Operation;
