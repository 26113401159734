import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatFullDate } from 'common/utils/format';
import Icon from 'common/components/Icon';
import { Item } from '../list';
import './listItem.scss';
import { useSession } from '../sessions';

const classes = {
    paid: {
        color: 'green',
        text: 'Payée',
    },
    open: {
        color: 'orange',
        text: 'Consultée',
    },
    uncollectible: {
        color: 'red',
        text: 'En attente de paiement',
    },
    draft: {
        color: 'blue',
        text: 'Brouillon',
    },
    void: {
        color: 'gray',
        text: 'Vide',
    },

};

const ListItem = ({
    item: {
        id,
        number,
        created,
        amount_paid: amount,
        status,
        userData: {
            entity,
            fullName,
        },
    },
}) => {
    const session = useSession();

    return (
        <Item className="text-center">
            {session.isAdmin ? (
                <Fragment>
                    <td>{entity}</td>
                    <td>{fullName}</td>
                    <td className="date">{formatFullDate(created * 1000)}</td>
                    <td>{(amount / 100).toFixed(2)} EUR</td>
                    <td className="dl-link">
                        <a href={`/api/payment/invoices/${id}/pdf`} target="_blank" rel="noreferrer">
                            <Icon icon="file-pdf-o" />
                        </a>
                    </td>
                </Fragment>
            ) : (
                <Fragment>
                    <td>Facture {number}</td>
                    <td className="date">{formatFullDate(created * 1000)}</td>
                    <td>{(amount / 100).toFixed(2)} EUR</td>
                    <td className={status && classes[status].color}>{status && classes[status].text}</td>
                    <td className="dl-link">
                        <a href={`/api/payment/invoices/${id}/pdf`} target="_blank" rel="noreferrer">
                            <Icon icon="file-pdf-o" />
                        </a>
                    </td>
                </Fragment>

            )}
        </Item>
    );
};

ListItem.propTypes = {
    item: PropTypes.shape({
        number: PropTypes.string,
        amount_due: PropTypes.number,
        status: PropTypes.string,
        invoice_pdf: PropTypes.string,
        userData: PropTypes.shape({
            fullName: PropTypes.string,
            entity: PropTypes.string,
        }),
        id: PropTypes.string,
        created: PropTypes.number,
        amount_paid: PropTypes.number,
    }),
};

export default ListItem;
