import React from 'react';
import { GridLayout } from 'components/form/layout';
import { DateField, FloatField, TextField } from 'components/form/field';
import { checkIsDate, checkRequiredField, checkDateDependencies } from 'common/formValidation/lib';

export const validators = [
    checkRequiredField('propertyWeightedSurface'),
    checkRequiredField('propertyActDate'),
    checkIsDate('propertyActDate'),
    checkRequiredField('propertyDeliveryDate'),
    checkIsDate('propertyDeliveryDate'),
    checkDateDependencies(
        'propertyDeliveryDate',
        'propertyActDate',
        'La date de livraison doit être postérieure à la date de l\'acte',
    ),
];

const PropertyForm = (programType) => (
    <GridLayout className="condensed" >
        <FloatField
            title="Surface pondérée (m²)"
            name="propertyWeightedSurface"
            min={0}
            placeholder="saisissez la surface"
            required
        />
        <FloatField
            title="Surface habitable (m²)"
            name="propertyLivingSurface"
            min={0}
            placeholder="saisissez la surface"
        />
        <TextField name="propertyAddress" title="Adresse" placeholder="saisissez l'adresse" double />
        <TextField name="propertyPostalCode" title="Code postal" placeholder="saisissez le code postal" />
        <TextField name="propertyCity" title="Ville" placeholder="saisissez la ville" />
        <DateField name="propertyActDate" title="Date de l'acte / Début de la mise en épargne" programType={programType.programType} required />
        <DateField name="propertyDeliveryDate" title="Date de livraison" required />
    </GridLayout>
);
export default PropertyForm;
