import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import data from './data';
import lists from './lists';
import menuVisibility from './menuVisibility';
import overlay from './overlay';
import userMenuVisibility from './userMenuVisibility';
import isValidator from './isValidator';
import simulationSummary from './simulationSummary';
import usersRipStats from './usersRipStats';
import investorProfile from './investorProfile';
import simulator from './simulator';
import formValidation from './formValidation';
import pdfViewer from './pdfViewer';
import pdfEditions from './pdfEditions';
import pdfError from './pdfError';
import modal from './modal';
import outdated from './outdated';
import flagUnleash from './flagUnleash';
import requiredFieldActivationDateReducer from './requiredFieldsReducer';
import signature from './signature';

const reducer = combineReducers({
    data,
    form,
    lists,
    menuVisibility,
    overlay,
    userMenuVisibility,
    isValidator,
    simulationSummary,
    usersRipStats,
    investorProfile,
    simulator,
    formValidation,
    pdfViewer,
    pdfEditions,
    pdfError,
    modal,
    outdated,
    flagUnleash,
    requiredFieldActivationDateReducer,
    signature,
});

export default reducer;
