import React from 'react';
import { HorizontalLayout } from 'components/form/layout';
import FutureOwner from './FutureOwner';

const CompanyAccomodation = () => (
    <HorizontalLayout>
        <FutureOwner />
    </HorizontalLayout>
);

export default CompanyAccomodation;
