import PropTypes from 'prop-types';
import React from 'react';
import Title from '../Title';
import FieldInfo from './FieldInfo';

const FormTitle = ({ title, required, info }) => (
    <Title>
        <b>{title} {required ? '*' : ''}</b>
        {info ? <FieldInfo>{info}</FieldInfo> : null}
    </Title>
);

FormTitle.propTypes = {
    title: PropTypes.string.isRequired,
    info: PropTypes.string,
    required: PropTypes.bool,
};

FormTitle.defaultProps = {
    required: false,
    info: null,
};

export default FormTitle;
