import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, withProps } from 'recompose';
import { Fields } from 'redux-form';
import AsyncSelect from 'react-select/lib/Async';
import withScroll from 'components/form/withScroll';
import Icon from '../../../common/components/Icon';

import './SearchAddress.css';

export const noOptionsMessage = () => 'Aucun résultat';

export const customStyles = {
    singleValue: (provided) => ({
        ...provided,
        color: '#248fdf',
    }),
    control: (provided, { hasValue }) => (hasValue ? ({
        ...provided,
        borderColor: '#248fdf',
    }) : provided),
};

const transformText = (text) =>
    text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/-/g, ' ')
        .replace(/'/g, ' ')
        .toUpperCase()
        .replace(/Œ/g, 'OE')
        .replace(/œ/g, 'oe')
        .replace(/\bSAINTE\b/g, 'STE')
        .replace(/\bSAINT\b/g, 'ST');

const AddressField = ({
    formGroup: Group,
    city: { input: cityInput },
    postalCode: { input: postalCodeInput },
    label: { input: labelInput },
    addressNumber: { input: addressNumberInput },
    addressType: { input: addressTypeInput },
    address: { input: addressInput },
    addressComplement: { input: addressComplementInput },
}) => {
    const [cache, setCache] = useState({});

    const onChange = useCallback((value) => {
        if (!value) {
            labelInput.onChange('');
        } else {
            const { label, address, addressType, addressNumber, postalCode, city, addressComplement } = value;

            cityInput.onChange(transformText(city) || null);
            postalCodeInput.onChange(postalCode || '');
            addressNumberInput.onChange(addressNumber || '');
            addressTypeInput.onChange(addressType || '');
            addressInput.onChange(address || '');
            labelInput.onChange(label || '');
            addressComplementInput.onChange(addressComplement || '');
        }
    }, [cityInput, postalCodeInput, addressNumberInput, addressTypeInput, addressInput, labelInput, addressComplementInput]);

    const selectedValue = useMemo(() => {
        if (!labelInput.value) {
            return null;
        }

        return {
            label: labelInput.value,
        };
    }, [labelInput.value]);

    const splitAddress = (properties) => {
        if (properties.label.includes('Lieu Dit')) {
            return properties.street.replace('Lieu Dit ', '');
        }

        return properties.street && properties.street.split(' ').slice(1).join(' ');
    };

    const splitAddressType = (properties) => {
        if (properties.label.includes('Lieu Dit')) {
            return '';
        }

        return properties.street && properties.street.split(' ')[0];
    };

    const splitAddressComplement = (properties) => {
        if (properties.label.includes('Lieu Dit')) {
            return properties.street && properties.street;
        }

        return '';
    };

    const loadOptions = useCallback(async (value) => {
        if (cache[value]) {
            return cache[value];
        }
        if (value && value.length > 3) {
            try {
                const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(value)}`);
                const data = await response.json();

                const options = data.features.map(({ properties }) => ({
                    label: properties.label,
                    address: splitAddress(properties),
                    addressType: splitAddressType(properties),
                    addressNumber: properties.housenumber,
                    postalCode: properties.postcode,
                    city: properties.city,
                    addressComplement: splitAddressComplement(properties),
                }));

                setCache((prevCache) => ({ ...prevCache, [value]: options }));

                return options;
            } catch (error) {
                console.error('Erreur lors de la recherche d\'adresse:', error);

                return [];
            }
        }

        return [];
    }, [cache]);

    return (
        <div className="icon-group-container">
            <div className="group">
                <Group
                    component="label"
                    htmlFor="searchAddress"
                >
                    <div className="title-content">
                        <div className="icon">
                            <Icon icon="map" />
                        </div>
                        <div className="title-address">
                            Rechercher une adresse

                        </div>

                    </div>
                    <AsyncSelect
                        className="react-select"
                        placeholder="2 chemin de ..."
                        noOptionsMessage={noOptionsMessage}
                        name="searchAddress"
                        isSearchable
                        isClearable
                        loadOptions={loadOptions}
                        getOptionValue={({ label }) => label}
                        getOptionLabel={({ label }) => label}
                        onChange={onChange}
                        value={selectedValue}
                        styles={customStyles}
                    />
                </Group>
            </div>

        </div>

    );
};

AddressField.propTypes = {
    city: PropTypes.shape({
        meta: PropTypes.shape({}),
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    postalCode: PropTypes.shape({
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    label: PropTypes.shape({
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    addressNumber: PropTypes.shape({
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    addressType: PropTypes.shape({
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    address: PropTypes.shape({
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }).isRequired,
    addressComplement: PropTypes.shape({
        input: PropTypes.shape({
            onChange: PropTypes.func,
            value: PropTypes.string,
        }),
    }),
    formGroup: PropTypes.func.isRequired,
};

export default compose(
    withProps({
        component: AddressField,
        names: ['address', 'addressNumber', 'addressType', 'postalCode', 'city', 'label', 'addressComplement'],
    }),
    getContext({ formGroup: PropTypes.func.isRequired }),
    withScroll,
)(Fields);
