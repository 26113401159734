import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash/fp';
import { formValues } from 'redux-form';
import Title from 'components/Title';
import Collection from 'components/form/GridLayoutCollection';
import { lifeInsuranceProfiles } from 'common/choices/settings/calculator';
import computeSupportRepartition from 'common/utils/computeSupportRepartition';
import computeSupportRepartitionProgramme from 'common/utils/computeSupportRepartitionProgramme';
import SupportType from './SupportType';

const SupportRepartition = ({ baseProducts, profile, currentRepartition, baseName = '' }) => {
    const totalProportion = !isEmpty(currentRepartition) ? computeSupportRepartition(currentRepartition) : 0;
    const totalProportionProgramme = !isEmpty(currentRepartition) ? computeSupportRepartitionProgramme(currentRepartition) : 0;

    const isError = ((totalProportion !== 100 && totalProportion !== 0));
    const isErrorProgramme = (totalProportionProgramme !== 100 && totalProportionProgramme !== 0);

    return (
        <div className="profile double" key={profile}>
            <Title>
                <b>Répartition du profil {lifeInsuranceProfiles[profile]}&nbsp;&nbsp;&nbsp;</b>
                <div className="percentage">
                    <div className={classNames({ 'form-field-error': isError })}>
                        <span>Versement initial</span>
                        <span>{totalProportion}/100</span>
                    </div>
                    <div className={classNames({ 'form-field-error': isErrorProgramme })}>
                        <span>Versement programmé</span>
                        <span>{totalProportionProgramme}/100</span>
                    </div>
                </div>
            </Title>
            <Collection
                componentProps={{ baseProducts }}
                name={`${baseName}profiles.${profile}.repartition`}
                component={SupportType}
                addLabel="Ajouter un support"
            />
        </div>
    );
};

SupportRepartition.propTypes = {
    baseProducts: PropTypes.shape({}).isRequired,
    profile: PropTypes.string.isRequired,
    currentRepartition: PropTypes.arrayOf(PropTypes.shape({})),
    baseName: PropTypes.string,
};

export default formValues(({ baseName = '', profile }) => ({
    currentRepartition: `${baseName}profiles.${profile}.repartition`,
}))(SupportRepartition);
