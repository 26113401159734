import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { camelCase, omit } from 'lodash/fp';
import { reduxForm, Form, SubmissionError, formValues } from 'redux-form';
import { compose } from 'recompose';
import { Alert } from 'react-bootstrap';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, TextField, TextAreaField, IntegerField, YesNoField } from 'components/form/field';
import validate from 'common/formValidation/lib';
import { buildScpiValidation } from 'common/formValidation/settings/scpiSettingsValidation';
import { POST } from 'utils/httpMethods';
import { getOpposite } from './ScpiSettings';
import ScpiDistribution from './ScpiDistribution';
import './CreateNewScpiType.scss';

const DismemberementShare = () => (
    <Fragment>
        <hr />
        <Title>Démembrement</Title>
        {[5, 7, 10, 15, 20].map((years) => (
            <Fragment key={`dismemberement-share-${years}`}>
                <h5>{years} ans</h5>
                <GridLayout className="double condensed full-width">
                    <PercentageFloatField name={`share.${years}.usufruct`} title="Usufruit" />
                    <PercentageFloatField name={`share.${years}.bareOwnership`} title="Nue-propriétée" />
                </GridLayout>
            </Fragment>
        ))}
    </Fragment>
);

const CreateNewScpiType = ({
    type,
    handleSubmit,
    shouldCreateOpposite,
    error,
    hideForm,
    hiddenAll,
    showForm,
}) => {
    const [hidden, setHidden] = useState(true);

    const showNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(false);
        showForm();
    }, [setHidden]);

    const hideNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(true);
        hideForm();
    }, [setHidden]);

    return (
        <div className={classNames('new-simu', { hidden: hiddenAll })}>
            {hidden ? (
                <button onClick={showNewForm}>
                    Nouvelle simulation {'scpi' === type ? 'SCPI' : 'SCPI NP'}
                </button>
            ) : (
                <button onClick={hideNewForm} className="cancel">Annuler</button>
            )}
            {error && <Alert variant="danger">{error}</Alert>}
            {!hidden && (
                <div className={classNames('new-simulation-form', { hidden })}>
                    <h1 className="text-center">Paramètres de la nouvelle simulation SCPI</h1>
                    <Form onSubmit={handleSubmit}>
                        <GridLayout className="double condensed full-width">
                            <TextField
                                name="name"
                                title="Nom"
                            />
                            <IntegerField
                                name="value"
                                title="Prix de part (€)"
                            />
                            <IntegerField
                                name="minimumParts"
                                title="Nombre de part minimum"
                            />
                            <IntegerField
                                name="beforeDividendsDuration"
                                title="Délai de jouissance (mois)"
                            />
                            <PercentageFloatField
                                name="fees"
                                title="Commission de souscription (%)"
                            />
                            <PercentageFloatField
                                name="simulationRate"
                                title="Rendement locatif (%)"
                            />
                            <TextAreaField
                                className="double"
                                name="description"
                                title="Description"
                            />
                        </GridLayout>
                        <ScpiDistribution baseName="distribution" />
                        {'dismemberedScpi' === type && <DismemberementShare />}
                        {'scpi' === type && shouldCreateOpposite && (
                            <Fragment>
                                <GridLayout className="double condensed full-width">
                                    <YesNoField
                                        title={`Créer également en ${'scpi' === type ? 'nue propriétée' : 'SCPI'} ?`}
                                        name="shouldCreateOpposite"
                                        className="double"
                                    />
                                </GridLayout>
                                <DismemberementShare />
                                <GridLayout className="condensed full-width">
                                    <div />
                                    <div className="submit">
                                        <button type="submit">Créer</button>
                                    </div>
                                </GridLayout>
                            </Fragment>
                        )}
                        {('dismemberedScpi' === type || ('scpi' === type && !shouldCreateOpposite)) && (
                            <GridLayout className="double condensed full-width">
                                <YesNoField
                                    title={`Créer également en ${'scpi' === type ? 'nue propriétée' : 'SCPI'} ?`}
                                    name="shouldCreateOpposite"
                                />
                                <div className="submit">
                                    <button type="submit">Créer</button>
                                </div>
                            </GridLayout>
                        )}
                    </Form>
                </div>
            )}
        </div>
    );
};

CreateNewScpiType.propTypes = {
    type: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    shouldCreateOpposite: PropTypes.bool.isRequired,
    error: PropTypes.string,
    hiddenAll: PropTypes.bool,
    showForm: PropTypes.func,
    hideForm: PropTypes.func,
};

export default compose(
    reduxForm({
        initialValues: { shouldCreateOpposite: true },
        onSubmit: async ({ shouldCreateOpposite, ...values }, dispatch, props) => {
            const slugifiedName = camelCase(values.name);
            const id = `simulation.${props.type}.${slugifiedName}`;
            const body = [{
                id,
                value: 'scpi' === props.type
                    ? omit(['share'], values)
                    : values,
                type: props.type,
                product: slugifiedName,
            }];

            if (shouldCreateOpposite) {
                const oppositeType = getOpposite(props.type);
                const oppositeId = `simulation.${oppositeType}.${slugifiedName}`;

                body.push({
                    id: oppositeId,
                    value: 'scpi' === oppositeType
                        ? omit(['share'], values)
                        : values,
                    type: oppositeType,
                    product: slugifiedName,
                });
            }

            try {
                const response = await POST('/api/settings/scpi', { body });

                return response.json();
            } catch (error) {
                const data = await error.response.json();
                // trigger the onSubmitFail
                throw new SubmissionError({ _error: 'La Création à échoué !', ...data });
            }
        },
        onSubmitSuccess: () => {
            document.location.href = '/admin/settings/simulation';
        },
        validate: (values, props) => {
            const { shouldCreateOpposite } = values;
            const { type } = props;

            const typeToValidate = 'dismemberedScpi' === type || ('scpi' === type && shouldCreateOpposite) ? 'dismemberedScpi' : 'scpi';

            const errors = validate(buildScpiValidation(typeToValidate, '', values.distribution))(values, props);

            return errors;
        },
    }),
    formValues('shouldCreateOpposite'),
)(CreateNewScpiType);
