// Réductions plafonnées : i.e. soumis au plafonnement global des niches fiscales
// cf https://www.service-public.fr/particuliers/vosdroits/F31179

import { concat, clamp, filter, flow, forEach, has, map, pullAll, get } from 'lodash/fp';
import { parseInteger, sumOrAdd } from '../utils';
import calculationWithEndDate from '../../utils/calculationWithEndDate';
import getCurrentYear from '../../utils/currentYear';

export const subscription = (rip) => (reduction = {}, settings) => {
    const { rate, singleCeil, coupleCeil } = get('summary.reductions.subscription', settings);
    const { id } = reduction;
    const amount = parseInteger(reduction.amount);

    const bound = ['married', 'partner'].includes(rip.familySituation)
        ? coupleCeil
        : singleCeil;

    return { id, reportedAmount: amount, computedAmount: rate * clamp(0, bound)(amount) };
};

export const investments = (rip) => (reduction = {}, settings) => {
    const { id, investment, amount } = reduction;

    if (!investment || 'lodeom' === investment) {
        return null;
    }

    const { rate, singleCeil, coupleCeil } = get(['summary', 'reductions', 'investment', investment], settings);

    const amnt = ['fcpi', 'fip'].includes(investment) ? parseInteger(amount) : 0;
    const bound = ['married', 'partner'].includes(rip.familySituation)
        ? coupleCeil
        : singleCeil;

    return { id, reportedAmount: amnt, computedAmount: calculationWithEndDate(getCurrentYear(), reduction, rate, bound) };
};

export const computeOther = (reduction) => parseInteger(reduction.amount);

const other = (reduction = {}) => {
    if (!reduction.capped) {
        return null;
    }

    const { id } = reduction;
    const amount = computeOther(reduction);

    return {
        id,
        reportedAmount: amount,
        computedAmount: amount,
    };
};

const getCappedReduction = {
    investment: investments,
    subscription,
    other,
    otherEstate: other,
};

export const availableEstateForReductions = [
    'Pinel',
    'Pinel+',
    'Denormandie',
    'Duflot',
    'Scellier',
    'lmnpBouvard',
    'Girardin',
    'ScellierIntermediaire',
];

export const availableForCapExclusion = ['Scellier', 'lmnpBouvard', 'Girardin'];

const computeCappedReductions = (rip, _, settings) => {
    // fonction pour retourner le "(mortdetaxAmount || detaxAmount)" seulement les années de la simulation en cours
    const returnAmount = (mortdetaxAmount, detaxAmount, detaxStartDate, detaxEndDate, prorogation, prorogationDetaxEndDate) => {
        if (detaxStartDate && detaxEndDate) {
            const anneeDebutDetax = parseInteger(detaxStartDate.slice(0, 4));
            const anneeFinDetax = parseInteger(detaxEndDate.slice(0, 4));
            const anneeFinProrogationDetax = prorogation ? parseInteger(prorogationDetaxEndDate.slice(0, 4)) : null;

            return (getCurrentYear() >= anneeDebutDetax && getCurrentYear() <= anneeFinDetax) || (prorogation && getCurrentYear() <= anneeFinProrogationDetax) ? (mortdetaxAmount || detaxAmount) : 0;
        }

        return ((mortdetaxAmount || detaxAmount));
    };

    let cappedReductions = 0;
    let cappedReductionsDetails = {};

    flow([
        filter(({ type }) => has(type, getCappedReduction)),
        forEach((reduction) => {
            const computer = 'function' === typeof getCappedReduction[reduction.type]()
                ? getCappedReduction[reduction.type](rip)
                : getCappedReduction[reduction.type];

            const element = computer(reduction, settings);

            if (null === element) {
                return;
            }

            const { computedAmount } = element;

            cappedReductionsDetails = sumOrAdd(cappedReductionsDetails, reduction.type, element);
            cappedReductions += computedAmount;
        }),
    ])(concat(
        rip.reductions,
        // get reductions from estate
        flow([
            filter(({ type, fiscalFramework, detaxType, includeInReductions }) => {
                // remove duplicates between estates that are automatically included in reduction and those who aren't
                // to facilitate filter below
                const reductibleEstates = pullAll(availableForCapExclusion, availableEstateForReductions);

                return 'rentalProperty' === type
                    && ((reductibleEstates.includes(fiscalFramework)
                        || (availableForCapExclusion.includes(fiscalFramework) && includeInReductions))
                        || ('other' === fiscalFramework && 'reduction' === detaxType));
            }),
            map(({ detaxAmount, mortdetaxAmount, detaxStartDate, detaxEndDate, prorogatedReduction, prorogationDetaxEndDate }) => ({
                type: 'otherEstate',
                amount: returnAmount(mortdetaxAmount, detaxAmount, detaxStartDate, detaxEndDate, prorogatedReduction, prorogationDetaxEndDate),
                capped: true,
            })),
        ])(rip.property),
    ));

    return { cappedReductions, cappedReductionsDetails };
};

export default computeCappedReductions;
