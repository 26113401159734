import React, { createContext, useContext } from 'react';

const appDataContext = createContext();

const withAppData = (Component) => (props) => (
    <appDataContext.Consumer>
        {(appData) => <Component {...props} appData={appData} />}
    </appDataContext.Consumer>
);

export const useAppData = () => useContext(appDataContext);
export const { Provider } = appDataContext;
export default withAppData;
