// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rip-menu-items {
    display: block;
    margin-bottom: 15px;
}

.rip-menu-items .item {
    margin-bottom: 5px;
}

.rip-menu-items .item span {
    padding-right: 10px;
    font-size: 18px;
    font-weight: 300;
}

.rip-menu-items .disabled span {
    padding-right: 10px;
    font-size: 18px;
    font-weight: 300;
    box-shadow: none;
    opacity: .55;
    border: 0;
}

.form-field-info-popover {
    max-width: 300px;
    color: #248fdf;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/rip/menu/Items.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,2CAA2C;AAC/C","sourcesContent":[".rip-menu-items {\n    display: block;\n    margin-bottom: 15px;\n}\n\n.rip-menu-items .item {\n    margin-bottom: 5px;\n}\n\n.rip-menu-items .item span {\n    padding-right: 10px;\n    font-size: 18px;\n    font-weight: 300;\n}\n\n.rip-menu-items .disabled span {\n    padding-right: 10px;\n    font-size: 18px;\n    font-weight: 300;\n    box-shadow: none;\n    opacity: .55;\n    border: 0;\n}\n\n.form-field-info-popover {\n    max-width: 300px;\n    color: #248fdf;\n    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
