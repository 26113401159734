import { flowRight, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { lifecycle, withProps } from 'recompose';
import { connect } from 'react-redux';
import { formValues, reduxForm } from 'redux-form';
import { getIsValidator } from 'actions';
import Icon from 'common/components/Icon';
import { POST } from 'utils/httpMethods';
import { TextAreaField, YesNoField } from 'components/form/field';
import { setModal } from 'actions/modal';
import Spinner from 'components/Spinner';
import baseSimulationLoader from '../simulationLoader';
import SuccessModal from '../../SuccessModal';
import withRouter from '../../withRouter';

const ResponseApprovalFactory = ({
    formComponent: Form,
    isApproved, isValidator,
    isAboveRecommendation,
    ...props
}) => (
    <Form {...props}>
        {isValidator ? (
            <Fragment>
                {isAboveRecommendation ? (
                    <div className="simulation-header">
                        <div className="approval-danger">
                            <Icon icon="times" className="fa-fw" />
                            Cette simulation est au dessus des recommendations validées
                        </div>
                    </div>
                ) : null}
                <YesNoField title="Validez-vous cette simulation ?" name="isApproved" />

                {isApproved ? (
                    <div>
                        <p>Vous avez validé cette simulation !</p>
                        <p>Cliquez sur &quot;Envoyer&quot; pour notifier le conseiller</p>
                        <TextAreaField title="Commentaires" name="approvalComments" />
                    </div>
                ) : <TextAreaField title="Pourquoi refusez-vous ?" name="approvalComments" required />}
            </Fragment>
        ) : (
            <div className="alert alert-danger">
                <p>Vous n&apos;avez pas les permissions pour valider cette simulation</p>
            </div>
        )}
    </Form>
);

ResponseApprovalFactory.propTypes = {
    formComponent: PropTypes.func.isRequired,
    isValidator: PropTypes.bool.isRequired,
    isApproved: PropTypes.bool,
    isAboveRecommendation: PropTypes.bool.isRequired,
};

ResponseApprovalFactory.defaultProps = {
    isApproved: false,
};

const approvalValues = { yes: true, no: false };

const Form = ({
    // HOC to load the simulation (and router match)
    simulationLoader = baseSimulationLoader,
    // form component to use as a layout
    formComponent,
    // limit checker to warn if the we're above the recommendations
    recommendationCheck = null,
}) => flowRight([
    // first load the simulation
    simulationLoader,
    // prepare initial values for the redux form
    withProps(({ params: { approval }, ...props }) => ({
        initialValues: { isApproved: get(approval, approvalValues) },
        isAboveRecommendation: recommendationCheck
            ? recommendationCheck(props)
            : false,
    })),
    withRouter,
    // init the redux form
    reduxForm({
        form: 'simulation-approval',
        onSubmit: (
            body,
            dispatch,
            { params: { simulationId } },
        ) => {
            dispatch(setModal(() => <Spinner>Envoi de l&apos;email en cours...</Spinner>, false));

            return POST(`/api/simulations/${simulationId}/sendAdvisorNotification`, { body });
        },
        onSubmitSuccess: (body, dispatch) => dispatch(setModal(() => <SuccessModal />, false)),
        onSubmitFail: () => alert('Erreur lors de l\'envoi du mail'),
    }),
    // bind the getIsValidator to a dispatch
    connect(null, { load: getIsValidator }),
    // we will load this information on mount
    lifecycle({
        componentDidMount() {
            this.props.load(this.props.match.params.simulationId, 'simulations');
        },
    }),
    // get back the information
    connect((state) => ({ isValidator: state.isValidator })),
    // and get some values from the form
    formValues('isApproved'),
])(withProps({ formComponent })(ResponseApprovalFactory));

export default Form;
