const choices = {
    none: 'Aucun',
    Pinel: 'Pinel',
    'Pinel+': 'Pinel+',
    Denormandie: 'Dispositif Denormandie',
    Duflot: 'Duflot',
    Scellier: 'Scellier',
    ScellierIntermediaire: 'Scellier Intermédiaire',
    recenteredRobien: 'de Robien recentré',
    Robien: 'de Robien',
    Besson: 'Besson',
    Perrissol: 'Perrissol',
    lmnp: 'LMNP',
    lmnpBouvard: 'LMNP Bouvard',
    Girardin: 'Girardin immobilière',
    commonLaw: 'Droit commun',
    other: 'Autre',
};

export default choices;
