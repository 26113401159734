import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { carOptions } from 'common/choices';
import { ChoiceField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';
import RipCarLoan from './CarLoan';

const Car = ({ name }) => (
    <Fragment>
        <HorizontalLayout>
            <OwnerField name={`${name}.fiscalHome`} title="Titulaire concerné" required />
            <ChoiceField
                name={`${name}.carOption`}
                title="Type d'achat&nbsp;?"
                choices={carOptions}
                required
            />
        </HorizontalLayout>
        <RipCarLoan name={name} />
    </Fragment>
);

Car.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Car;
