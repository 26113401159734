import React from 'react';
import PropTypes from 'prop-types';
import './TutorialCard.scss';

const TutorialCard = ({ image, content, step }) => (
    <div className="tutorial-card">
        <div className="step">{step}</div>
        <div className="image">
            <img src={image} alt="vider-cache" />
        </div>
        <div className="content">{content}</div>
    </div>
);

TutorialCard.propTypes = {
    image: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default TutorialCard;
