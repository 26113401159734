import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const Subscription = ({ name }) => (
    <Fragment>
        <Title>Souscription PME/PMI</Title>
        <GridLayout className="triple condensed full-width">
            <PercentageFloatField
                name={`${name}.rate`}
                title="Pourcentage de réduction (%)"
            />
            <IntegerField
                name={`${name}.singleCeil`}
                title="Plafond de souscription (célibataire) (€)"
            />
            <IntegerField
                name={`${name}.coupleCeil`}
                title="Pourcentage de réduction (couple) (€)"
            />
        </GridLayout>
    </Fragment>
);

Subscription.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Subscription;
