export const searchList = (list, search) => ({
    type: 'LISTS_SEARCH',
    list,
    search,
});

export const sortList = (list, property, order) => ({
    type: 'LISTS_SORT',
    list,
    property,
    order,
});

export const setListMode = (list, mode) => ({
    type: 'LISTS_MODE',
    list,
    mode,
});
