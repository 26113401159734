const pvpChoices = {
    donationInheritance: 'Donation / Succession',
    estateSell: 'Vente immobilière',
    shopSell: 'Vente commerce',
    cash: 'Trésorerie courante',
    dividends: 'Dividendes',
    fixedAssetSell: 'Vente d\'actifs',
    transferOfProperty: 'Cession de bien',
    saleOfRealEstateAssets: 'Vente d\'actifs immobiliers',
    constitutedSavings: 'Epargne déjà constituée',
    professionalActivityCapital: 'Capitaux activité professionnelle',
    gameEarnings: 'Gain de jeux',
    indemnity: 'Indemnisation / Dommages et Intérêts',
};

const targetChoices = {
    epargneDejaConstituee: 'Épargne déjà constituée',
    realisationActif: 'Réalisation d\'actif',
    heritageDonation: 'Héritage donation',
    interetDividendeStockOption: 'Intérêts dividendes sur stock options',
    gainAuJeux: 'Gains aux jeux',
    indemnisation: 'Indémnisation',
    rachatDeContrat: 'Rachat de contrat',
    creditEtablissement: 'Crédit',
};

export default { pvp: pvpChoices, target: targetChoices };
