export const setPdfEditions = (pdfEditions = { editions: {} }) => ({
    type: 'SET_PDF_EDITIONS',
    pdfEditions,
});

export const setTextEdition = (index, { position, temporary = false }) => ({
    type: 'SET_PDF_TEXT_EDITION',
    index,
    position,
    temporary,
});

export const addTextEdition = (index, { position, temporary = false, page, createdAt }) => ({
    type: 'ADD_PDF_TEXT_EDITION',
    index,
    position,
    temporary,
    page,
    createdAt,
});

export const togglePdfEdition = (index) => ({
    type: 'TOGGLE_PDF_EDITION',
    index,
});

export const setPdfEditionContent = (index, content) => ({
    type: 'SET_PDF_EDITION_CONTENT',
    index,
    content,
});

export const deletePdfEdition = (index) => ({
    type: 'DELETE_PDF_EDITION',
    index,
});

export const deletePdfSignature = (signatory) => ({
    type: 'DELETE_PDF_SIGNATURE',
    signatory,
});

export const setPdfEditionTool = (tool) => ({
    type: 'SET_PDF_EDITION_TOOL',
    tool,
});

export const setPdfEditionSignatureTool = (signatory) => ({
    type: 'SET_PDF_EDITION_SIGNATURE_TOOL',
    signatory,
});

export const addSignature = (signatory, { position, page, createdAt }) => ({
    type: 'ADD_PDF_SIGNATURE',
    signatory,
    position,
    page,
    createdAt,
});
