const pvp = {
    ongoing: 'non menée à terme',
    completedPaper: 'menée à terme en impression papier',
    completedDigital: 'menée à terme en signature électronique',
};

const target = {
    ongoing: 'non menée à terme',
    completed: 'document signé',
    completedDigital: 'pris en charge sur le hub',
};

const cardif = {
    ongoing: 'non menée à terme',
    completed: 'document signé',
    completedDigital: 'pris en charge sur Cardif',
};

const mma = {
    ongoing: 'non menée à terme',
    completed: 'document signé',
    completedDigital: 'pris en charge sur MMA',
};

export default { pvp, target, cardif, mma };
