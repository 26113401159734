import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { mapProps } from 'recompose';
import { HorizontalLayout } from '../../form/layout';
import { DateField, IntegerField } from '../../form/field';

const UnionEvent = ({ name, withSalaryField }) => (
    <HorizontalLayout>
        <DateField name={`${name}.date`} title="Date prévue" required />
        {withSalaryField ? <IntegerField name={`${name}.salary`} title="Salaire annuel estimé du futur conjoint" /> : null}
    </HorizontalLayout>
);

UnionEvent.propTypes = {
    name: PropTypes.string.isRequired,
    withSalaryField: PropTypes.bool.isRequired,
};

export default flowRight([
    formValues({
        familySituation: 'familySituation',
        dualRip: 'dualRip',
    }),
    mapProps(({ familySituation, dualRip, ...props }) => ({
        withSalaryField: 'cohabitation' !== familySituation || !dualRip,
        ...props,
    })),
])(UnionEvent);
