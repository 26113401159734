import { merge } from 'lodash/fp';
import commonInitialValues from '../common/initialValues';

const initialValues = merge(
    commonInitialValues,
    {
        managementFees: 0.09,
        guaranteedIncome: 0.02,
        rentalFees: 0.0185,
        rentsIndexation: 0.03,
    },
);

export default initialValues;
