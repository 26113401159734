const modalReducer = (state = { content: null, closable: true }, { type, ...payload }) => {
    switch (type) {
        case 'MODAL.SET':
            return {
                ...state,
                content: payload.content,
                closable: payload.closable,
            };
        default:
            return state;
    }
};

export default modalReducer;
