import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { shouldPartnerSign } from 'common/utils/hasPartner';
import Menu from './Menu/Menu';
import ElectronicSignatures from './Electronic/ElectronicSignatures';

const Signatures = ({ back, rip, documentId, completedDocument }) => {
    const [component, setComponent] = useState('menu');
    const [componentProps, setComponentProps] = useState({});
    const changeComponent = useCallback((newComponent, props) => () => {
        setComponentProps(props);
        setComponent(newComponent);
    }, [setComponent, setComponentProps]);

    const partnerShouldSign = shouldPartnerSign(rip);

    switch (component) {
        case 'menu':
            return (
                <Menu
                    rip={rip}
                    back={back}
                    changeComponent={changeComponent}
                    documentId={documentId}
                    completedDocument={completedDocument}
                    partnerShouldSign={partnerShouldSign}
                />
            );
        case 'electronic-sign':
            return (
                <ElectronicSignatures
                    rip={rip}
                    documentId={documentId}
                    completedDocument={completedDocument}
                    partnerShouldSign={partnerShouldSign}
                    {...componentProps}
                />
            );
        default:
            return null;
    }
};

Signatures.propTypes = {
    back: PropTypes.string.isRequired,
    rip: PropTypes.shape({}).isRequired,
    completedDocument: PropTypes.shape({}).isRequired,
    documentId: PropTypes.string,
};

export default Signatures;
