import React from 'react';
import PropTypes from 'prop-types';
import { TextAreaField } from 'components/form/field';
import Divider from 'components/Divider';
import RipFormFamilySituation from './FormFamilySituation';
import Children from './Children';
import GrandChildren from './GrandChildren';
import Dependents from './Dependents';
import RipCivilStatus from './CivilStatus';
import GrandParents from './GrandParents';
import RipForm from '../Form';
import Events from './EventsList';
import dataForm from '../dataForm';
import { useSession } from '../../sessions';

const FormInner = (props) => {
    const session = useSession();

    const isUserTopinvest = 'Topinvest' === session.entity.name;

    return (
        <RipForm {...props} hideBackButton={isUserTopinvest}>
            <RipCivilStatus />
            <RipFormFamilySituation change={props.change} />
            <Children change={props.change} />
            <GrandChildren initialValues={props.initialValues} />
            <Dependents change={props.change} />
            <GrandParents />
            <Events />
            <Divider />
            <TextAreaField title="Commentaires" name="clientComment" />
        </RipForm>
    );
};

FormInner.propTypes = {
    change: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        birthCountry: PropTypes.string,
        partnerBirthCountry: PropTypes.string,
        taxResidency: PropTypes.string,
        partnerTaxResidency: PropTypes.string,
        nationality: PropTypes.string,
        partnerNationality: PropTypes.string,
    }),
};

const Form = dataForm({
    form: 'rip-edit',
    id: ({ id }) => id,
    type: 'rip',
})(FormInner);

export default Form;
