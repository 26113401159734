import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createElement, Fragment } from 'react';
import { connect } from 'react-redux';
import simulators from './simulators';
import Card from './Card';
import { showSimulator, hideSimulator } from '../../../../actions';
import CloseLink from './CloseLink';

import './Simulators.css';

const SimulatorsInner = ({ simulator, toggle }) => (
    <Fragment>
        <div className="overlay-scrollable">
            <div className="container">
                {simulator
                    ? (
                        <div className={`simulator simulator-${simulator}`}>
                            {createElement(simulators[simulator].component)}
                        </div>
                    ) : (
                        <div className="simulators">
                            {Object.entries(simulators).map(([key, { card: cardProps }]) => createElement(Card, {
                                ...cardProps,
                                key,
                                role: 'button',
                                onClick: () => toggle(key),
                            }))}
                        </div>
                    )}
            </div>
        </div>
        {simulator ? (
            <CloseLink role="button" onClick={toggle} />
        ) : null}
    </Fragment>
);

SimulatorsInner.propTypes = {
    simulator: PropTypes.string,
    toggle: PropTypes.func.isRequired,
};

SimulatorsInner.defaultProps = {
    simulator: null,
};

const mapStateToProps = ({ simulator }) => ({ simulator });

const mapDispatchToProps = (dispatch, { simulator: activeSimulator }) => ({
    toggle: activeSimulator
        ? () => dispatch(hideSimulator())
        : (simulator) => dispatch(showSimulator(simulator)),
});

const Simulators = flowRight([
    connect(mapStateToProps),
    connect(null, mapDispatchToProps),
])(SimulatorsInner);

export default Simulators;
