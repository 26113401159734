// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.title-content .icon {
    display: flex;
    align-items:center;
    margin-bottom: 10px;
    align-self: baseline;
}

.title-content .title-address {
    margin-bottom: 10px;
    font-size: 14px;
    height: 30px;
    font-weight: bold;
}

.css-151xaom-placeholder {
    color: #efefef !important;
}`, "",{"version":3,"sources":["webpack://./src/components/rip/vitalRecord/SearchAddress.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".title-content {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.title-content .icon {\n    display: flex;\n    align-items:center;\n    margin-bottom: 10px;\n    align-self: baseline;\n}\n\n.title-content .title-address {\n    margin-bottom: 10px;\n    font-size: 14px;\n    height: 30px;\n    font-weight: bold;\n}\n\n.css-151xaom-placeholder {\n    color: #efefef !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
