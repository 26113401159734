import { flowRight } from 'lodash/fp';
import { reduxForm } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import { withPropsOnChange } from 'recompose';
import fullName from 'common/utils/fullName';
import withSpinner from 'components/withSpinner';
import Icon from 'common/components/Icon';
import { listProvider } from 'components/list';
import { POST } from 'utils/httpMethods';
import { SearchableSelectField } from '../../../form/field';
import RipFormFields from '../../FormFields';
import withRouter from '../../../withRouter';

const ReattributeInner = ({ users, ...props }) => (
    <RipFormFields {...props}>
        <SearchableSelectField
            name="nextUserId"
            title="Sélectionnez le nom du nouveau CGP"
            options={users}
        />
        <button className="sendButton" type="submit">
            Réattribuer&nbsp;
            <Icon icon="angle-right" />
        </button>
    </RipFormFields>
);

ReattributeInner.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({})),
};

const Reattribute = flowRight([
    listProvider({
        sorts: {
            lastName: { initialOrder: 'asc', default: true },
        },
        name: 'user',
        type: 'user',
    }),
    withSpinner,
    withRouter,
    reduxForm({
        form: 'rip-reassign',
        onSubmit: (body, dispatch, { params: { id } }) => POST('/api/rips/changeOwnership', { body: { ...body, rips: [id] } }),
        onSubmitSuccess: (results, dispatch, {
            navigate,
        }) => navigate('/'),
    }),
    withPropsOnChange('items', ({ items }) => ({
        users: items.map(({ id, firstName, lastName }) => ({ value: id, label: fullName({ firstName, lastName }) })),
    })),
])(ReattributeInner);
export default Reattribute;
