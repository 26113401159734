import React from 'react';

import RadioQuestion from '../../InvestorProfile/RadioQuestion';

import './choiceResponse.scss';

const choices = {
    yes: 'Valider',
    no: 'Invalider',
};

const ChoiceResponse = () => (
    <div className="choice-response">
        <RadioQuestion
            choices={choices}
            name="isApproved"
            title={<b>Retour du validateur*</b>}
            className="double"
            required
            errortext="Vous devez choisir une r&eacute;ponse."
        />
    </div>
);

export default ChoiceResponse;
