// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary {
    margin-top: 50px;
}

.charts-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;
}

.recharts-surface {
    overflow: overlay !important;
}

.pie-chart, .bar-chart {
    margin-top: 40px;
}

.bar-chart .recharts-default-legend, .bar-chart .recharts-tooltip-item-list {
    display: flex;
    flex-direction: column-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/components/simulation/types/pinel/Summary/SummaryInner/CommonSummary.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,qBAAqB;AACzB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".summary {\n    margin-top: 50px;\n}\n\n.charts-wrapper {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    align-items: baseline;\n}\n\n.recharts-surface {\n    overflow: overlay !important;\n}\n\n.pie-chart, .bar-chart {\n    margin-top: 40px;\n}\n\n.bar-chart .recharts-default-legend, .bar-chart .recharts-tooltip-item-list {\n    display: flex;\n    flex-direction: column-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
