import classNames from 'classnames';
import { flowRight, get } from 'lodash/fp';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import {
    deletePdfEdition,
    togglePdfEdition,
    addSignature,
    addTextEdition,
    setTextEdition,
    setPdfEditionTool,
} from 'actions';
import PdfViewer from 'components/rip/overlays/PdfViewer';
import TextArea from './TextArea';
import withRouter from '../../withRouter';

class PdfCanvas extends Component {
    constructor(props) {
        super(props);
        this.state = { position: {} };
    }

    setPosition(e, temporary) {
        const { pdfViewer: { frame: { x, y, height, width } } } = this.props;

        const bottom = (1 - ((e.clientY - y) / height)) * 100;
        const right = (1 - ((e.clientX - x) / width)) * 100;

        const { top, left } = this.state.position;

        const position = {
            ...(100 - bottom < top ? { top: 100 - bottom, bottom: 100 - top } : { top, bottom }),
            ...(100 - right < left ? { left: 100 - right, right: 100 - left } : { left, right }),
        };

        if ('text' === this.props.pdfEditions.activeTool && this.props.pdfEditions.activeId) {
            this.props.setTextEdition(this.props.pdfEditions.activeId, { position, temporary });
        }
    }

    handleMouseDown(e) {
        const previousId = this.props.pdfEditions.activeId;
        if (previousId && 'none' === this.props.pdfEditions.activeTool) {
            if (!this.props.pdfEditions.editions[previousId].content) {
                this.props.deletePdfEdition(previousId);
            } else {
                this.props.togglePdfEdition(previousId);
            }
        }

        if ('text' === this.props.pdfEditions.activeTool) {
            const { pdfViewer: { frame: { x, y, height, width } } } = this.props;

            const top = ((e.clientY - y) / height) * 100;
            const left = ((e.clientX - x) / width) * 100;
            const id = uuidv4();

            this.setState({
                position: { top, left },
            });

            this.props.addTextEdition(
                id,
                {
                    position: { top, left },
                    temporary: true,
                    page: this.props.pdfViewer.page,
                    createdAt: moment(),
                },
            );
        }

        if ('signature' === this.props.pdfEditions.activeTool) {
            this.props.addSignature(
                this.props.pdfEditions.activeId,
                {
                    position: this.state.position,
                    page: this.props.pdfViewer.page,
                    createdAt: moment(),
                },
            );
        }
    }

    handleMouseMove(e) {
        if ('signature' === this.props.pdfEditions.activeTool) {
            const { pdfViewer: { frame: { x, y, height, width } } } = this.props;

            const bottom = (1 - (((e.clientY - y) / height))) * 100;
            const left = ((e.clientX - x) / width) * 100;
            const top = 100 - (bottom + 6.7340067); // La hauteur de la signature est 6.73% d'une page A4
            const right = 100 - (left + 20.9302326); // La largeur de la signature est 20.93% d'une page A4

            this.setState({
                position: { bottom, left, top, right },
            });
        }

        if (this.props.pdfEditions.activeId && get(`${this.props.pdfEditions.activeId}.temporary`, this.props.pdfEditions.editions)) {
            this.setPosition(e, true);
        }
    }

    handleMouseUp(e) {
        if (this.props.pdfEditions.activeId) {
            this.setPosition(e, false);
            this.props.setPdfEditionTool('none');
        }
    }

    render() {
        const {
            params: { id },
            pdfEditions: { editions, activeId, activeTool, signatures },
            pdfViewer: { frame: { proportion }, page: currentPage },
        } = this.props;

        const setStyle = ({ top, left, bottom, right }, fontSize) => ({
            position: 'absolute',
            ...(top ? { top: `${top}%` } : null),
            ...(left ? { left: `${left}%` } : null),
            ...(bottom ? { bottom: `${bottom}%` } : null),
            ...(right ? { right: `${right}%` } : null),
            ...(fontSize ? { fontSize: `${proportion * fontSize}px` } : null),
        });

        return (
            <div className="edition-pdf">
                <PdfViewer
                    pdfPath={`/api/signatures/${id}/documentContent`}
                    listeners={{
                        onMouseDown: (e) => this.handleMouseDown(e),
                        onMouseUp: (e) => this.handleMouseUp(e),
                        onMouseMove: (e) => this.handleMouseMove(e),
                    }}
                >
                    {Object.entries(editions).map(([index, {
                        position,
                        temporary,
                        content,
                        page,
                    }]) => (
                        page === currentPage ? (
                            <div
                                key={index}
                                className={classNames('edition-field', {
                                    temporary,
                                    current: activeId === index,
                                })}
                                style={setStyle(position, 12)}
                            >
                                {activeId === index && !temporary ? (
                                    <Field name={`${index}-content`} component={TextArea} />
                                ) : <div>{content}</div>}
                            </div>
                        ) : null
                    ))}
                    {Object.entries(signatures).map(([signatory, {
                        position,
                        page,
                    }]) => (
                        page === currentPage ? (
                            <div
                                key={signatory}
                                className={classNames('signature-field', signatory)}
                                style={setStyle(position, 12)}
                            />
                        ) : null
                    ))}
                    {'signature' === activeTool ? <div key="signature" className={classNames('signature-field', activeId)} style={setStyle(this.state.position, 12)} /> : null}
                </PdfViewer>
            </div>
        );
    }
}

PdfCanvas.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    pdfEditions: PropTypes.shape({
        signatures: PropTypes.shape({}),
        activeId: PropTypes.string,
        activeTool: PropTypes.string,
        editions: PropTypes.shape({
            type: PropTypes.string,
            position: PropTypes.shape({
                top: PropTypes.number,
                left: PropTypes.number,
                right: PropTypes.number,
                bottom: PropTypes.number,
            }),
            temporary: PropTypes.bool,
        }),
    }).isRequired,
    pdfViewer: PropTypes.shape({
        page: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
        proportion: PropTypes.number,
        frame: PropTypes.shape({
            proportion: PropTypes.number,
            x: PropTypes.number,
            y: PropTypes.number,
            height: PropTypes.number,
            width: PropTypes.number,
        }),
    }),
    deletePdfEdition: PropTypes.func.isRequired,
    addSignature: PropTypes.func.isRequired,
    addTextEdition: PropTypes.func.isRequired,
    setTextEdition: PropTypes.func.isRequired,
    togglePdfEdition: PropTypes.func.isRequired,
    setPdfEditionTool: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    pdfEditions: state.pdfEditions,
    pdfViewer: state.pdfViewer,
});

const mapDispatchToProps = {
    deletePdfEdition,
    addSignature,
    addTextEdition,
    setTextEdition,
    togglePdfEdition,
    setPdfEditionTool,
};

export default flowRight([
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
])(PdfCanvas);
