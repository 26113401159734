import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setFormValidationDisplay } from 'actions';
import handleError from 'utils/handleError';
import { POST } from 'utils/httpMethods';
import Divider from 'components/Divider';
import Title from 'components/Title';
import FormButtons from 'components/FormButtons';
import FormGroup from 'components/form/Group';
import { TextField } from 'components/form/field';
import simulationLoader from 'components/simulation/simulationLoader';
import Customisation from 'components/simulation/types/common/simulationDetails/CustomisationForm';
import ItemsToDisplay from 'components/simulation/types/common/simulationDetails/ItemsToDisplay';
import Form from '../Form';
import SimulationHeader from '../Header';
import dataForm from '../dataForm';
import wizardData from '../wizardData';
import validateSubmittion from '../../common/validateSubmittion';

const SimulationDetailsInner = ({
    handleSubmit,
    params: { id, simulationId },
    navigate,
    initialValues: { needFunding },
    showErrors,
    errors,
}) => (
    <Form
        handleSubmit={(evt) =>
            validateSubmittion({
                evt,
                handleSubmit,
                showErrors,
                errors,
                navigate,
                ripId: id,
                simulationId,
                items: wizardData(needFunding).items,
            })
        }
        needFunding={needFunding}
    >
        <div className="simulation-form-inner">
            <div className="container">
                <SimulationHeader />
                <TextField name="name" title="Nom de la simulation" placeholder="saisissez le nom de la simulation" required />
                <FormGroup>
                    <Title>
                        <b>Paramètres de la simulation</b>
                    </Title>
                    <Customisation />
                </FormGroup>
                <Divider />
                <FormGroup>
                    <Title>
                        <b>Eléments à afficher dans la simulation</b>
                    </Title>
                    <ItemsToDisplay />
                </FormGroup>
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/charges`}
            list={`/rip/${id}/simulations/`}
            nextButtonContent="Continuer"
        />
    </Form>
);

SimulationDetailsInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    initialValues: PropTypes.shape({
        needFunding: PropTypes.bool,
    }).isRequired,
    navigate: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    showErrors: PropTypes.func,
};

const Details = flowRight([
    simulationLoader,
    dataForm({
        form: 'simulation-details',
        id: ({
            params: { simulationId },
        }) => simulationId,
        onSubmitSuccess: (
            result,
            dispatch,
            {
                navigate,
                params: { id, simulationId },
            },
        ) =>
            POST(`/api/simulations/${simulationId}/complete`)
                .then(() => navigate(`/rip/${id}/simulations/${simulationId}/summary/form`))
                .catch(handleError),
        type: 'simulation',
    }),
    connect(
        ({ formValidation: { errors } }) => ({
            errors,
        }),
        { showErrors: setFormValidationDisplay },
    ),
])(SimulationDetailsInner);

export default Details;
