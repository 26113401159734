import { flowRight, flow, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { withProps } from 'recompose';
import { countries, fiscalParticularCases, nationalities } from 'common/choices';
import Icon from 'common/components/Icon';
import getFiscalParticularCases from 'common/utils/getFiscalParticularCases';
import sortedList from 'common/utils/sortedList';
import addPrefix from '../../../utils/addPrefix';
import { DateField, SelectField, TextField, PhoneField, MultiSelectField, YesNoField } from '../../form/field';
import FieldInfo from '../../form/FieldInfo';
import { GridLayout } from '../../form/layout';
import CityField from './CityField';
import BirthCityField from './BirthCityField';
import isRequiredGrandChildrenAndPartnerField from '../../../common/utils/isRequiredGrandChildrenAndPartnerField';
import SearchAdress from './SearchAddress';

const CivilStatus = ({
    birthCountry,
    civility,
    partner,
    required,
    partnerBirthCountry,
    partnerCivility,
    familySituation,
    country,
    createdAt,
}) => {
    const isRequiredField = isRequiredGrandChildrenAndPartnerField(createdAt);
    const getIdentifier = addPrefix('partner')(partner);

    const estUneFemmeMariePacseOuVeuve = (situation) => {
        if ('single' === situation || 'cohabitation' === situation || 'divorced' === situation) {
            return false;
        }

        return (!partner && 'Mme' === civility) || (partner && 'Mme' === partnerCivility);
    };

    const sortedNationalities = sortedList(nationalities);

    // Note: empty divs allow to fill a block in the grid
    return (
        <Fragment>
            <h5 className="title">
                <Icon icon="arrow-right" />
                {partner ? 'Informations personnelles de votre conjoint' : 'Vos informations personnelles'}
            </h5>
            <GridLayout className="triple condensed gray-box full-width">
                {(!partner && 'Mme' === civility) || (partner && 'Mme' === partnerCivility) ? (
                    <Fragment>
                        <div className="middle">
                            <SelectField name={getIdentifier('civility')} title="Civilité" required>
                                <option />
                                <option>M</option>
                                <option>Mme</option>
                            </SelectField>
                            <TextField
                                title={`Prénom${partner ? ' du conjoint' : ''}`}
                                placeholder="Saisir le prénom"
                                required={partner ? isRequiredField : required}
                                name={getIdentifier('firstName')}
                            />
                        </div>
                        <div className="middle">
                            <TextField
                                title={`Nom${partner ? ' du conjoint' : ''}`}
                                placeholder="Saisir le nom"
                                required={partner ? isRequiredField : required}
                                name={getIdentifier('lastName')}
                            />
                            <TextField
                                title={`Nom de naissance ${partner ? ' du conjoint' : ''}`}
                                placeholder="Saisir le nom"
                                name={getIdentifier('birthName')}
                                required={estUneFemmeMariePacseOuVeuve(familySituation) ? required : null}
                            />

                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <SelectField name={getIdentifier('civility')} title="Civilité" required>
                            <option />
                            <option>M</option>
                            <option>Mme</option>
                        </SelectField>
                        <TextField
                            title={`Prénom${partner ? ' du conjoint' : ''}`}
                            placeholder="Saisir le prénom"
                            required={partner ? isRequiredField : required}
                            name={getIdentifier('firstName')}
                        />
                        <TextField
                            title={`Nom${partner ? ' du conjoint' : ''}`}
                            placeholder="Saisir le nom"
                            required={partner ? isRequiredField : required}
                            name={getIdentifier('lastName')}
                        />
                    </Fragment>
                )}
                {!partner ? (
                    <Fragment>
                        <div className="double">
                            <SearchAdress />
                        </div>
                        <TextField title="Numéro" placeholder="10, 10 bis" name="addressNumber" />
                        <TextField title="Type de voie" placeholder="Rue, Chemin, Allée" name="addressType" required={'FR' === country && required} />
                        <TextField title="Libellé voie" placeholder="du Moulin" required={required} name="address" />
                        <TextField title="Complément d'adresse" placeholder="Batiment, Résidence, Appartement" name="addressComplement" />
                        {
                            'FR' === country ?
                                <Fragment>
                                    <SelectField
                                        title="Pays"
                                        name={getIdentifier('country')}
                                    >
                                        <option />
                                        {Object.entries(countries).map(([key, value]) => (
                                            <option key={key} value={key}>
                                                {value}
                                            </option>
                                        ))}
                                    </SelectField>
                                    <CityField required />
                                </Fragment> :
                                <Fragment>
                                    <SelectField
                                        title="Pays"
                                        name={getIdentifier('country')}
                                    >
                                        <option />
                                        {Object.entries(countries).map(([key, value]) => (
                                            <option key={key} value={key}>
                                                {value}
                                            </option>
                                        ))}
                                    </SelectField>
                                    <TextField title="Code Postal" name="postalCode" />
                                    <TextField title="Ville" name="city" />
                                </Fragment>
                        }
                    </Fragment>
                ) : null}
                <PhoneField
                    title={`Téléphone mobile${partner ? ' du conjoint' : ''}`}
                    placeholder="0612345678"
                    required={required || !partner}
                    name={getIdentifier('mobile')}
                />
                {!partner ? <PhoneField title="Téléphone fixe" placeholder="0123456789" name="phone" /> : null}
                <TextField
                    title={`Email${partner ? ' du conjoint' : ''}`}
                    placeholder="adresse@email.com"
                    required={required}
                    name={getIdentifier('personalEmail')}
                />
                <TextField
                    title={`Email professionnel${partner ? ' du conjoint' : ''}`}
                    placeholder="adresse@email.com"
                    name={getIdentifier('professionalEmail')}
                />
                <DateField
                    title={`Date de naissance${partner ? ' du conjoint' : ''}`}
                    required={required}
                    name={getIdentifier('birthDate')}
                />
                <SelectField
                    title={`Pays de naissance${partner ? ' du conjoint' : ''}`}
                    name={getIdentifier('birthCountry')}
                >
                    <option />
                    {Object.entries(countries).map(([key, value]) => (
                        <option key={key} value={key}>
                            {value}
                        </option>
                    ))}
                </SelectField>
                {(!partner && 'FR' === birthCountry) || (partner && 'FR' === partnerBirthCountry) ? (
                    <Fragment>
                        <div className="double">
                            <BirthCityField partner={partner} />
                        </div>
                    </Fragment>
                ) : null
                }
                <SelectField
                    title="Résidence fiscale"
                    name={getIdentifier('taxResidency')}
                >
                    <option />
                    {Object.entries(countries).map(([key, value]) => (
                        <option key={key} value={key}>
                            {value}
                        </option>
                    ))}
                </SelectField>
                <YesNoField
                    title={(
                        <Fragment>
                            US PERSON
                            <FieldInfo id="remainingIncomesPopover">
                                <div>
                                    <p>
                                        est considérée comme US PERSON un(e) citoyen(e) américain(e) ou un(e)
                                        résident(e) américain(e) ou une personne née aux Etats-Unis
                                    </p>
                                </div>
                            </FieldInfo>
                        </Fragment>
                    )}
                    name={getIdentifier('usPerson')}
                />
                <SelectField
                    title={`Nationalité${partner ? ' du conjoint' : ''}`}
                    name={getIdentifier('nationality')}
                    required={partner ? isRequiredField : required}
                >
                    <option />
                    {Object.entries(sortedNationalities).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </SelectField>
                <MultiSelectField
                    name={getIdentifier('fiscalParticularCases')}
                    title="Cas fiscaux particuliers"
                    double
                    options={flow([
                        Object.entries,
                        map(([partCase, settings]) => ({ value: partCase, label: settings.title })),
                    ])(getFiscalParticularCases(familySituation, fiscalParticularCases))}
                />
            </GridLayout>
        </Fragment>
    );
};

CivilStatus.propTypes = {
    partner: PropTypes.bool,
    required: PropTypes.bool,
    birthCountry: PropTypes.string,
    partnerBirthCountry: PropTypes.string,
    civility: PropTypes.string,
    partnerCivility: PropTypes.string,
    familySituation: PropTypes.string,
    country: PropTypes.string,
    createdAt: PropTypes.string,
};

CivilStatus.defaultProps = {
    partner: false,
};

export default flowRight([
    formValues({
        civility: 'civility',
        partnerCivility: 'partnerCivility',
        birthCountry: 'birthCountry',
        partnerBirthCountry: 'partnerBirthCountry',
        birthZipcode: 'birthZipcode',
        partnerBirthZipcode: 'partnerBirthZipcode',
        required: 'dualRip',
        familySituation: 'familySituation',
        country: 'country',
        createdAt: 'createdAt',
        seachAdress: 'seachAdress',
    }),
    withProps(({ required = false, cohabitation = false }) => ({
        required: cohabitation ? required : true,
    })),
])(CivilStatus);
