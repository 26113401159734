import React from 'react';
import PropTypes from 'prop-types';
import { withProps, compose } from 'recompose';
import { Form, formValues } from 'redux-form';
import { set } from 'lodash/fp';

import TypedBlockCollectionItem from '../../form/TypedBlockCollectionItem';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import Salary from '../taxes/Salary';
import Profit from '../taxes/Profit';
import LandRentalIncome from '../taxes/LandRentalIncome';
import FurnishedRentalIncome from '../taxes/FurnishedRentalIncome';
import Dividends from '../taxes/Dividends';
import Pension from '../taxes/Pension';
import OtherIncome from '../taxes/OtherIncome';
import TaxExemptedIncome from '../taxes/TaxExemptedIncome';
import ChildrenIncome from '../taxes/ChildrenIncome';
import agriculture from '../../../images/pictos/agriculture.svg';
import patrimoineLocatif from '../../../images/pictos/patrimoine-locatif.svg';
import locatifMeuble from '../../../images/pictos/locatif-meuble.svg';
import dividendes from '../../../images/pictos/dividendes.svg';
import circle from '../../../images/pictos/circle.svg';
import retraite from '../../../images/pictos/retraite.svg';
import FiscalPartInput from './FiscalPartInput';

const types = {
    salary: {
        component: Salary,
        icon: 'money',
        title: 'Salaire',
        defaultValues: {
            incomeType: 'monthly',
            realExpenses: false,
        },
    },
    BNC: {
        component: withProps({ type: 'BNC' })(Profit),
        icon: 'stethoscope',
        title: 'BNC',
        defaultValues: {
            managementCenter: true,
        },
    },
    BIC: {
        component: withProps({ type: 'BIC' })(Profit),
        icon: 'shopping-basket',
        title: 'BIC',
        defaultValues: {
            managementCenter: true,
        },
    },
    BA: {
        component: withProps({ type: 'BA' })(Profit),
        path: agriculture,
        title: 'BA',
        defaultValues: {
            managementCenter: true,
        },
    },
    landRentalIncome: {
        component: LandRentalIncome,
        path: patrimoineLocatif,
        title: 'Locatifs foncier',
    },
    furnishedRentalIncome: {
        component: FurnishedRentalIncome,
        path: locatifMeuble,
        title: 'Locatifs meublé',
    },
    dividends: {
        component: Dividends,
        path: dividendes,
        title: 'Dividendes',
    },
    pension: {
        component: Pension,
        path: retraite,
        title: 'Pension',
    },
    other: {
        component: OtherIncome,
        path: circle,
        title: 'Autres',
    },
    exemptedIncome: {
        component: TaxExemptedIncome,
        icon: 'handshake-o',
        title: 'Revenus exonérés',
    },
    childrenIncome: {
        component: ChildrenIncome,
        icon: 'handshake-o',
        title: 'Salaire des enfants à charge',
    },
};

const TaxIncomesListInner = ({ ripFromRecommendation }) => {
    const addRipDataRecoOnTypes = Object.keys(types).reduce((acc, key) => {
        const originalType = types[key];
        const modifiedType = {
            ...originalType,
            component: withProps({ ripFromRecommendation })(originalType.component),
        };
        acc[key] = modifiedType;

        return acc;
    }, {});

    return (
        <Form className="condensed">
            <Title>
                Revenus du dernier avis d&apos;imposition
            </Title>
            <TypedCollection
                name="incomes"
                // eslint-disable-next-line react/prop-types
                types={set('childrenIncome.shouldDisplay', ripFromRecommendation && ripFromRecommendation.children && ripFromRecommendation.children.length > 0, addRipDataRecoOnTypes)}
                textOnly
                itemComponent={TypedBlockCollectionItem}
            />
            <br />
            <FiscalPartInput />

        </Form>
    );
};

TaxIncomesListInner.propTypes = {
    ripFromRecommendation: PropTypes.shape({
        children: PropTypes.shape({}),
    }),
};

const TaxIncomesList = compose(formValues('cif'))(TaxIncomesListInner);

export default TaxIncomesList;
