import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import dataForm from 'components/dataForm';
import { DateField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';

const CallbackForm = ({ close, handleSubmit }) => (
    <form onSubmit={handleSubmit}>
        <GridLayout className="single condensed full-width">
            <DateField title="Date de rappel" name="callbackDate" />
        </GridLayout>
        <div className="submit-footer">
            <div>
                <button type="button" className="cancel-button" onClick={close}>
                    Annuler
                </button>
            </div>
            <div>
                <button type="submit" className="submit-button">
                    Continuer
                </button>
            </div>
        </div>
    </form>
);

CallbackForm.propTypes = {
    close: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default compose(
    connect(null, (dispatch) => ({ close: () => dispatch(setModal(null)) })),
    dataForm({
        form: 'rip-callback-form',
        autoSave: false,
        type: 'rip',
        id: ({ id }) => id,
        processValues: (values) => ({ ...values, state: 'toCallBack' }),
        onSubmitSuccess: (values, dispatch, { close }) => close(),
    }),
)(CallbackForm);
