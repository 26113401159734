import classNames from 'classnames';
import { has, omit } from 'lodash/fp';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormSection, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { GET } from 'utils/httpMethods';
import Icon from 'common/components/Icon';
import Button from './Button';
import Dropdown from './Dropdown';
import EmailTemplateForm from './EmailTemplateForm';
import withFormName from './form/withFormName';

import './EmailTemplateManager.scss';

class EmailTemplateManager extends Component {
    constructor(props) {
        super(props);

        this.state = { templates: null };
        this.renderTemplate = this.renderTemplate.bind(this);
    }

    componentDidMount() {
        this.loadTemplates();
    }

    async loadTemplates() {
        const response = await GET(this.props.url);
        const data = await response.json();

        this.setState({ templates: data });
    }

    renderTemplate([key, { name, variables, subject, body }]) {
        const { values, update } = this.props;
        const edited = has(key, values);

        return (
            <FormSection name={key} key={key}>
                <Dropdown
                    title={(
                        <Fragment>
                            <Icon
                                icon={`${edited ? 'check' : 'times'}-circle`}
                                className={classNames('fa-fw', { warning: !edited, success: edited })}
                            />
                            &nbsp;{name}
                        </Fragment>
                    )}
                    className="email-template-manager"
                >
                    {edited ? (
                        <Fragment>
                            <EmailTemplateForm variables={variables} />
                            <div className="remove">
                                <Button
                                    // we're going to remove the current template
                                    onClick={() => update(omit([key], values))}
                                >
                                    <Icon icon="trash" /> Supprimer le modèle d&apos;email
                                </Button>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="setup">
                            <Button
                                // we're going to add the new template
                                onClick={() => update({
                                    ...values,
                                    [key]: { subject, body },
                                })}
                            >
                                <Icon icon="pencil" /> Éditer le modèle d&apos;email
                            </Button>
                        </div>
                    )}
                </Dropdown>
            </FormSection>
        );
    }

    render() {
        const { templates } = this.state;

        if (null === templates) {
            return null;
        }

        return (
            <FormSection name="emailTemplates">
                {Object.entries(templates).map(this.renderTemplate)}
            </FormSection>
        );
    }
}

EmailTemplateManager.propTypes = {
    update: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    values: PropTypes.shape({}),
};

EmailTemplateManager.defaultProps = {
    values: null,
};

export default compose(
    withFormName,
    connect(
        (state, { form }) => ({
            values: formValueSelector(form)(state, 'emailTemplates'),
        }),
        (dispatch, { form }) => ({
            update: (value) => dispatch(change(form, 'emailTemplates', value)),
        }),
    ),
)(EmailTemplateManager);
