import PropTypes from 'prop-types';
import React from 'react';
import { InputField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';

const Sponsored = ({ name }) => (
    <HorizontalLayout>
        <InputField name={`${name}.firstName`} title="Prénom" required type="text" />
        <InputField name={`${name}.lastName`} title="Nom" required type="text" />
        <InputField name={`${name}.mail`} title="Mail" required type="mail" />
        <InputField name={`${name}.phone`} title="Téléphone" type="text" />
        <InputField name={`${name}.contactRightNow`} title="À contacter immédiatement" type="checkbox" />
    </HorizontalLayout>
);

Sponsored.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Sponsored;
