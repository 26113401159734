import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from 'components/form/layout';
import { DateField } from 'components/form/field';
import { OwnerField } from '../field';

const BirthEvent = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.parent`} title="Foyer fiscal de rattachement" onlyCohabitation required single />
        <DateField name={`${name}.presumedBirthDate`} title="Date présumée de naissance" required />
    </HorizontalLayout>
);

BirthEvent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default BirthEvent;
