import { get, set } from 'lodash/fp';
import validate, { checkRequiredField } from 'common/formValidation/lib';
import types from './types';

const checkCreatePermission = (rip, session) => (errors, { product, beneficiary }) => (
    product && !session.permissions.mayCreateSimulation(rip, product, beneficiary)
        ? set('product', 'Recommendation non approuvée pour ce bénéficiaire', errors)
        : errors
);

// form validation for the creation form
const formValidate = validate(({ product }, { rip, session }) => {
    const validators = [
        checkRequiredField('name'),
        checkRequiredField('product'),
        checkCreatePermission(rip, session),
    ];

    const productFormValidate = get([product, 'formValidate'], types);

    if (productFormValidate) {
        // we have an additional form validation for this product
        validators.push(productFormValidate);
    }

    return validators;
});

export default formValidate;
