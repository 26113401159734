import { get, flatten, set, identity } from 'lodash/fp';
import moment from 'moment';
import { formatPercentage } from 'common/utils/format';
import computeSummary from 'common/model/summary';
import validate, { checkRequiredField } from 'common/formValidation/lib';
import getSettings from 'common/utils/settings';

const periodicityFactors = {
    yearly: 12,
    quarterly: 3,
    monthly: 1,
};

const getMontlyPayments = (scheduledPayments) => {
    const amount = get('amount', scheduledPayments);
    const periodicity = get('periodicity', scheduledPayments);

    return amount && periodicity ? amount / periodicityFactors[periodicity] : null;
};

const checkScheduledPayments = (
    { minimum, error = `Un minimum de ${minimum}€ mensuel est requis`, required = false },
    scheduledPayments,
) => {
    const monthlyPayments = getMontlyPayments(scheduledPayments);

    if (null !== monthlyPayments) {
        return monthlyPayments < minimum ? ['data.scheduledPayments.amount', error] : null;
    }

    return required ? ['data.scheduledPayments.amount', error] : null;
};

const basicContractValidatorFactory = (name) => ({ data: { initialFunding = 0, scheduledPayments, duration, profile } }, settings) => {
    const { miniInitialFunding, miniScheduledPaymentAmount, profiles: { [profile]: { minimumDuration } } } = settings;

    if (initialFunding < miniInitialFunding) {
        return ['data.initialFunding', `${name} nécessite un minimum de ${miniInitialFunding}€ en montant initial`];
    }

    if (duration < minimumDuration) {
        return ['data.duration', `La durée saisie est inférieure à la durée de placement recommandée sur ce profil (${minimumDuration})`];
    }

    return checkScheduledPayments({ minimum: miniScheduledPaymentAmount }, scheduledPayments);
};

const checkDateValidity = (field) => (errors, values) => {
    const subscriptionDate = get('data.subscriptionDate', values);
    const date = get(field, values);

    if (!date || !subscriptionDate) {
        return errors;
    }

    const minDate = moment(subscriptionDate);
    let error = 'La date doit être postérieure ou égale à la date de souscription';

    const contractType = get('data.contractType', values);
    const accountAvailability = getSettings(['simulation', 'lifeInsurance', 'types', contractType, 'accountAvailability']);

    if (accountAvailability) {
        error = `La date doit être postérieure de ${accountAvailability} jours à la date de souscription`;
        minDate.add(accountAvailability, 'days');
    }

    return moment(date).isBefore(minDate) ? set(field, error, errors) : errors;
};

const checkSubscriptionDateValidity = (errors, values) => {
    const subscriptionDate = get('data.subscriptionDate', values);

    return subscriptionDate && moment(subscriptionDate).isBefore(moment())
        ? set('data.subscriptionDate', 'La date doit être ultérieure à la date du jour', errors)
        : errors;
};

const checkFeesValidity = (type, test) => {
    // first look for the limit fees for the given contract types
    const contractFees = getSettings(['simulation', 'lifeInsurance', 'types', type, 'fees']) || {};

    if (!contractFees) {
        // somehow we can't check it
        // so we will simply return no error
        return identity;
    }

    const { min, max } = contractFees;
    const error = `Les frais d'entrée doivent être compris entre ${formatPercentage(min)} et ${formatPercentage(max)}`;

    // return a validator builder
    return (field) => (errors, values) => {
        const fees = parseFloat(get(field, values));

        return test(fees, contractFees) ? set(field, error, errors) : errors;
    };
};

const checkMaxFeesValidity = (type) => checkFeesValidity(type, (fees, { max }) => fees < 0 || fees > max);
const checkMinFeesValidity = (type) => checkFeesValidity(type, (fees, { min }) => fees >= 0 && fees < min);

// On interdit, aux clients qui ont choisi un profil supérieur, d'avoir un investissment > 10% patrimoine global
const checkChangingProfileLowInvestment = (
    errors,
    { data: { initialFunding, profile } = {} },
    { rip, investorProfile },
) => {
    const { assets } = computeSummary(rip);

    if (profile < investorProfile && initialFunding > 0.1 * assets) {
        return set(
            'data.initialFunding',
            'Simulation impossible sur ce profil de risque car la souscription représente plus de 10% du patrimoine global du client',
            errors,
        );
    }

    return errors;
};

const checkInitialFunding = (error, values) => {
    const contractType = get('data.contractType', values);
    if (!['cardif', 'cardifImmo'].includes(contractType)) {
        return checkRequiredField('data.initialFunding');
    }

    return null;
};

/* eslint-disable-next-line */
export const errorValidate = validate((values, { session, rip }) => {
    const investorProfile = get('data.profile', values);
    const events = get('data.events', values);
    const maxFeesValidator = checkMaxFeesValidity(get('data.contractType', values), 'max');
    const minFeesValidator = checkMinFeesValidity(get('data.contractType', values), 'max');

    return [
        checkChangingProfileLowInvestment,
        // the required fields
        ...[
            'data.contractType',
            'data.subscriptionDate',
            'data.duration',
            'data.entryFees',
            'data.initialFundingReason',
        ].map((field) => checkRequiredField(field)),
        checkInitialFunding,
        checkSubscriptionDateValidity,
        // check entry fees
        maxFeesValidator('data.entryFees'),
        ...(session.permissions.isCaseManager(rip.user.entity) ? [] : [minFeesValidator('data.entryFees')]),
        // if we've an amount on scheduled payments
        // we have few more required fields
        ...(get('data.scheduledPayments.amount', values)
            ? [
                checkRequiredField('data.scheduledPayments.periodicity'),
                checkRequiredField('data.scheduledPayments.startDate'),
                checkRequiredField('data.scheduledPayments.firstPaymentDate'),
                checkDateValidity('data.scheduledPayments.firstPaymentDate'),
                maxFeesValidator('data.scheduledPayments.fees'),
                ...(session.permissions.isCaseManager(rip.user.entity)
                    ? []
                    : [minFeesValidator('data.scheduledPayments.fees')]),
            ]
            : []),
        // then the contract type
        (errors) => {
            const contractType = get('data.contractType', values);
            const type = getSettings(['simulation', 'lifeInsurance', 'types', contractType]);

            if (!type) {
                return errors;
            }

            if (!(investorProfile in type.profiles)) {
                return set(
                    'data.contractType',
                    "Ce contrat n'est pas disponible pour le profil d'investisseur choisi",
                    errors,
                );
            }

            const contractValidator = basicContractValidatorFactory(type.name);

            if (contractValidator) {
                const contractError = contractValidator(values, type);

                if (contractError) {
                    const [contractErrorField, contractErrorValue] = contractError;

                    return set(contractErrorField, contractErrorValue, errors);
                }
            }

            return errors;
        },
        // and validate the events (only if we've some)
        ...(events
            ? flatten(events.map(({ type }, index) => [
                checkRequiredField(`data.events[${index}].amount`),
                checkRequiredField(`data.events[${index}].date`),
                checkRequiredField(`data.events[${index}].startDate`),
                checkRequiredField(`data.events[${index}].periodicity`),
                checkDateValidity(`data.events[${index}].date`),
                ...('payment' === type || 'recurrentPayment' === type
                    ? [
                        checkRequiredField(`data.events[${index}].fees`),
                        maxFeesValidator(`data.events[${index}].fees`),
                        ...(session.permissions.isCaseManager(rip.user.entity)
                            ? []
                            : [minFeesValidator(`data.events[${index}].fees`)]),
                    ]
                    : []),
            ]))
            : []),
    ];
});
