// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.sendButton {
    display: block;
    border: 1px solid #248fdf;
    background-color: white;
    height: 48px;
    padding: 0 20px;
    border-radius: 24px;
    margin-right: auto;
    margin-left: auto;
    color: white;
    background: rgb(36,143,223);
    background: linear-gradient(to right, rgb(36,143,223) 0%, rgb(40,233,234) 100%);
}

button.sendButton i.fa {
    font-size: 24px;
    padding: 1px 0 0 15px;
    display: block;
    float: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/SendButton.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,2BAA2B;IAC3B,+EAA+E;AACnF;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,cAAc;IACd,YAAY;AAChB","sourcesContent":["button.sendButton {\n    display: block;\n    border: 1px solid #248fdf;\n    background-color: white;\n    height: 48px;\n    padding: 0 20px;\n    border-radius: 24px;\n    margin-right: auto;\n    margin-left: auto;\n    color: white;\n    background: rgb(36,143,223);\n    background: linear-gradient(to right, rgb(36,143,223) 0%, rgb(40,233,234) 100%);\n}\n\nbutton.sendButton i.fa {\n    font-size: 24px;\n    padding: 1px 0 0 15px;\n    display: block;\n    float: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
