const validators = {
    // validators
    caseManager: 'Chargé d\'affaire',
    manager: 'Manager',
    both: 'Les deux',
};

const formatSelect = (value, name) => {
    if (['validateSimulationBy', 'validateCIFDocumentBy'].includes(name)) {
        return validators[value];
    }

    return value;
};

export default formatSelect;
