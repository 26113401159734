import PropTypes from 'prop-types';
import React from 'react';
import RipLayout from './RipLayout';
import RipCommitmentForm from './commitment/Form';
import dataProvider from '../dataProvider';

const Commitment = ({ rip }) => (
    <RipLayout>
        {rip ? (
            <RipCommitmentForm
                back={`/rip/${rip.id}/profil-client`}
                rip={rip}
            />
        ) : null}
    </RipLayout>
);

Commitment.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
    }),
};

Commitment.defaultProps = {
    rip: null,
};

export default dataProvider({
    id: ({ params: { id } }) => id,
    type: 'rip',
})(Commitment);
