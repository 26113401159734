import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { DateField, YesNoField } from 'components/form/field';

const FutureOwnerInner = ({ currentValue }) => (
    <Fragment>
        <YesNoField name="futureOwner" title="Pensez-vous devenir propriétaire ?" required />
        {currentValue ? (
            <DateField name="futureOwnerDate" title="A quelle date ?" required />
        ) : null}
    </Fragment>
);

FutureOwnerInner.propTypes = {
    currentValue: PropTypes.bool,
};

FutureOwnerInner.defaultProps = {
    currentValue: undefined,
};

const FutureOwner = formValues({
    currentValue: 'futureOwner',
})(FutureOwnerInner);

export default FutureOwner;
