export const header = [
    {
        value: 'Numéro de facture',
        type: 'string',
    },
    {
        value: 'Montant TTC',
        type: 'string',
    },
    {
        value: 'Commission prélevée HT',
        type: 'string',
    },
    {
        value: 'Montant de la TVA',
        type: 'string',
    },
    {
        value: 'Net versé',
        type: 'string',
    },
];

export const getRow = ({
    number,
    amount_paid: amountPaid,
    fee,
    tax,
}) => [
    {
        value: number,
        type: 'string',
    },
    {
        value: amountPaid / 100,
        type: 'number',
    },
    {
        value: fee / 100,
        type: 'number',
    },
    {
        value: tax / 100,
        type: 'number',
    },
    {
        value: (amountPaid - fee) / 100,
        type: 'number',
    },
];

export const getTotalRow = (invoices) => [
    {
        value: '',
        type: 'string',
    },
    {
        value: '',
        type: 'string',
    },
    {
        value: '',
        type: 'string',
    },
    {
        value: 'Total',
        type: 'string',
    },
    {
        value: invoices.reduce((acc, { amount_paid: amountPaid, fee }) => acc + ((amountPaid - fee) / 100), 0),
        type: 'string',
    },
];
