import React from 'react';
import PropTypes from 'prop-types';
import PdfReader from 'components/contract/lifeInsurance/Documents/PdfReader';
import { Layout } from 'components/layouts';
import Icon from 'common/components/Icon';
import Footer from '../rip/Footer';

import './Check.scss';
import withRouter from '../withRouter';

const NextButton = ({ onClick }) => (
    <div className="formNextAction">
        <button onClick={onClick}>
            Lancer les signatures
            <Icon icon="angle-right" />
        </button>
    </div>
);

NextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const Check = ({ location, params: { id: ripId, docId }, navigate }) => (
    <Layout>
        <h1 className="text-center">
            {location.state.file.name}
        </h1>
        <p className="text-center">
            Veuillez vérifier les informations du document
        </p>
        <div className="check-wrapper">
            <PdfReader file={location.state.file} />
        </div>
        <Footer
            back={`/rip/${ripId}/documents/${docId}/upload`}
            nextButtonComponent={() => (
                <NextButton
                    onClick={() => navigate(
                        `/rip/${ripId}/documents/${docId}/sign`,
                        { state: {
                            file: location.state.file,
                            back: { pathname: `/rip/${ripId}/documents/${docId}/check`, state: { file: location.state.file } },
                        } },
                    )}
                />
            )}
        />
    </Layout>
);

Check.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        docId: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            file: PropTypes.shape({
                name: PropTypes.string,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    navigate: PropTypes.shape({}),
};

export default withRouter(Check);
