import { isObject } from 'lodash';
import { scroller } from 'react-scroll';

const getDeepFirstKey = (obj) => {
    const [key, value] = Object.entries(obj)[0];

    return isObject(value) ? `${key}.${getDeepFirstKey(value)}` : key;
};

const scrollToFirstError = (options = null) => (errors) => scroller.scrollTo(
    `scroll.${getDeepFirstKey(errors)}`,
    {
        smooth: true,
        duration: 500,
        delay: 0,
        offset: -120,
        ...options,
    },
);

export default scrollToFirstError;
