import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { flowRight } from 'lodash/fp';
import { faRing, faCakeCandles, faSuitcase, faBuildingColumns, faGavel } from '@fortawesome/free-solid-svg-icons';

import DivorceEvent from './DivorceEvent';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import UnionEvent from './UnionEvent';
import BirthEvent from './BirthEvent';
import HouseholdLeaveEvent from './HouseholdLeaveEvent';

const types = {
    birth: {
        component: BirthEvent,
        icon: faCakeCandles,
        title: 'Naissance',
    },
    householdLeave: {
        component: HouseholdLeaveEvent,
        icon: faSuitcase,
        title: 'Départ du foyer fiscal',
    },
    marriage: {
        component: UnionEvent,
        icon: faRing,
        title: 'Mariage',
        unique: true,
    },
    partnership: {
        component: UnionEvent,
        icon: faBuildingColumns,
        title: 'Pacs',
        unique: true,
    },
    divorce: {
        component: DivorceEvent,
        icon: faGavel,
        title: 'Divorce',
        unique: true,
    },
};

const EventsList = ({ dependents, familySituation }) => {
    const isDependentsExist = dependents && 0 < dependents.length;
    const divorceDisabled = familySituation && (['partner', 'divorced', 'single', 'cohabitation']).includes(familySituation);
    const marriageDisabled = familySituation && (['married']).includes(familySituation);
    const partnershipDisabled = familySituation && (['married', 'partner']).includes(familySituation);

    return (
        <FormGroup>
            <Title>Avez-vous un <b>évènement à prévoir&nbsp;?</b></Title>
            <TypedCollection
                name="familyEvents"
                types={types}
                isDependentsExist={isDependentsExist}
                divorceDisabled={divorceDisabled}
                marriageDisabled={marriageDisabled}
                partnershipDisabled={partnershipDisabled}
            />
        </FormGroup>
    );
};

EventsList.propTypes = {
    dependents: PropTypes.arrayOf(PropTypes.shape({})),
    familySituation: PropTypes.string,
};

export default flowRight([
    formValues({
        dependents: 'dependents',
        familySituation: 'familySituation',
    }),
])(EventsList);
