const isKnownTopinvestUrl = (url) => {
    switch (url) {
        case 'dev-topinvest.idsoft.local':
        case 'neith.idsoft.local':
        case 'recette-topinvest.idsoft.local':
        case 'venus.idsoft.local':
        case 'mercure.idsoft.local':
        case 'demo.crmtopinvest.fr':
        case 'www.topinvest.fr':
        case 'topinvest.fr':
        case 'crmtopinvest.fr':
        case 'monespaceidimmo.fr':
            return true;

        default:
            return false;
    }
};
export default isKnownTopinvestUrl;
