import React from 'react';
import { mapProps } from 'recompose';
import Layout from './Layout';
import Wizard from '../layout/Wizard';

const WizardLayout = mapProps(({ wizard, ...props }) => ({
    ...props,
    header: <Wizard {...wizard} />,
}))(Layout);

export default WizardLayout;
