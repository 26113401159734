import {
    countBy,
    flow,
    get,
    getOr,
    identity,
    isFunction,
    isObject,
    last,
    pick,
    sortBy,
    without,
} from 'lodash/fp';
import questions from './questions/index';

const computeOldInvestorProfile = (investorProfile) => {
    // Ancienne version questionnaire de risque
    let values = [];
    // list of profiles still available
    let availableProfiles = ['P', 'E', 'D'];

    for (const [name, choices] of Object.entries(questions)) {
        // get the user answer
        const userAnswer = get(name, investorProfile);

        const questionResult = isFunction(choices)
            ? choices(userAnswer, investorProfile)
            : getOr({ resolvedProfile: null }, userAnswer, choices);

        if (!isObject(questionResult)) {
            // we got a definitive profile
            return questionResult;
        }

        const {
            values: answerValues = [],
            resolvedProfile = undefined,
            rejectedProfiles = [],
        } = questionResult;

        if (undefined !== resolvedProfile) {
            // return it
            return resolvedProfile;
        }

        // update the results
        values = values.concat(answerValues);
        availableProfiles = without(rejectedProfiles, availableProfiles);
    }

    // return the profile
    return flow(
        countBy(identity),
        // keep only available profiles
        pick(availableProfiles),
        // get an array of couples ([profile, count])
        Object.entries,
        // sort them (ascending)
        sortBy(1),
        // get the last one (being the more present one)
        last,
        // and get the profile key
        getOr(null, 0),
    )(values);
};

export default computeOldInvestorProfile;
