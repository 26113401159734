import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Field } from 'redux-form';
import Checkbox from '../../../../form/Checkbox';
import Title from '../../../../Title';
import { FaIcon } from '../../../../../common/components/fa';

import './ManualSignature.css';

class Signature extends Component {
    componentDidMount() {
        this.sigCanvas.fromDataURL(this.props.initialValue);
    }

    render() {
        const { customer, fullName, hasPartner, title, save } = this.props;
        const trash = () => {
            this.sigCanvas.clear();
            save(null);
        };

        return (
            <div className="signature">
                <Title>
                    <b>{title || `Signature de ${fullName}`}</b>
                    <button
                        className="trash-signature"
                        type="button"
                        onClick={trash}
                    >
                        <FaIcon icon="trash" />
                    </button>
                </Title>
                {customer ? (
                    <p>Je certifie que les informations reprises dans le présent relevé patrimonial sont exactes.</p>
                ) : (
                    <Field
                        title={`J'atteste être en présence de ${hasPartner ? 'mes clients' : 'mon client'} et sur le territoire Français.`}
                        component={Checkbox}
                        name="advisorAgreement"
                        required
                    />
                )}
                <SignatureCanvas
                    ref={(ref) => { this.sigCanvas = ref; }}
                    penColor="black"
                    canvasProps={{ width: 500, height: 300, className: 'sigCanvas' }}
                    onEnd={() => save(this.sigCanvas.getCanvas().toDataURL())}
                />
            </div>
        );
    }
}

Signature.propTypes = {
    customer: PropTypes.string,
    fullName: PropTypes.string,
    hasPartner: PropTypes.bool,
    title: PropTypes.string,
    save: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
};

Signature.defaultProps = {
    customer: null,
    fullName: null,
    hasPartner: false,
    initialValue: null,
    title: null,
};

export default Signature;
