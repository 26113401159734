import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash/fp';

import RipLayout from '../RipLayout';
import Summary from '../Summary';
import dataProvider from '../../dataProvider';
import withRouter from '../../withRouter';

const SummaryAfter = ({ rip, params: { id }, navigate }) => (
    <RipLayout>
        {rip ? <Summary
            back={`/rip/${id}/impots`}
            initialValues={rip}
            onSubmitSuccess={() => navigate(`/rip/${id}/patrimoine`)}
        /> : null}
    </RipLayout>
);

SummaryAfter.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    rip: PropTypes.shape({}),
    navigate: PropTypes.shape({}),
};

const SummaryInner = flowRight([
    withRouter,
    dataProvider({
        id: ({ router: { params: { id } } }) => (id),
        type: 'rip',
    }),

])(SummaryAfter);

export default SummaryInner;
