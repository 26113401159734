import React, { useMemo, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ChoiceField } from 'components/form/field';
import { GridLayout } from '../form/layout';
import Title from '../Title';
import OtherBuyer from './OtherBuyer';

const Buyer = ({ initialValues: { potentialBuyers: buyers } }) => {
    const choices = useMemo(() => buyers.reduce((acc, { name, metadata }) => {
        const { buyerType, buyerId } = metadata;

        return {
            ...acc,
            [`${buyerType}.${buyerId}`]: name,
        };
    }, {}), [buyers]);

    const parse = useCallback((value) => {
        if (!value) {
            return undefined;
        }

        const [type, id] = value.split('.');
        const choice = buyers.find(({ metadata }) => metadata.buyerId === id && metadata.buyerType === type);

        return choice ? choice.metadata : undefined;
    }, [buyers]);

    const format = useCallback((value) => {
        if (!value) {
            return undefined;
        }

        const { buyerType, buyerId } = value;
        const key = `${buyerType}.${buyerId}`;

        return key in choices ? key : undefined;
    }, [choices]);

    if (!buyers.length) {
        return <OtherBuyer />;
    }

    return (
        <Fragment>
            <Title>Choisissez le payeur</Title>
            <GridLayout className="double condensed full-width gray-box">
                <ChoiceField
                    double
                    name="buyer"
                    choices={choices}
                    parse={parse}
                    format={format}
                    title="Payeur"
                />
            </GridLayout>
        </Fragment>
    );
};

Buyer.propTypes = {
    initialValues: PropTypes.shape({
        potentialBuyers: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default Buyer;
