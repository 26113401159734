import { keyBy, set } from 'lodash/fp';

const defaultState = {
    comment: {},
    entity: {},
    rip: {},
    simulation: {},
    user: {},
    ripPendingValidation: {},
    simulationPendingValidation: {},
    invoice: {},
};

const data = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case 'DATA_SET':
            return set([payload.data.type, payload.data.id], payload.data.data, state);
        case 'DATA_SET_LIST':
            return set([payload.data.type], keyBy('id', payload.data.data), state);
        default:
            return state;
    }
};

export default data;
