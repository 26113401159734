// Taux d'endettement
const weight = 0.90;
const computeDebtRatio = (_, { charges: allCharges, rawRentalIncomes, rawProfessionalIncomes }) => {
    const charges = allCharges; // on retire les charges locatives pour faire la condition plus bas
    const monthlyWeightedIncomes = (rawProfessionalIncomes + (weight * rawRentalIncomes)) / 12;
    const debtRatioNum = charges;
    const debtRatioDenom = monthlyWeightedIncomes;
    const debtRatio = debtRatioNum / debtRatioDenom;

    return {
        debtRatio,
        debtRatioNum,
        debtRatioDenom,
    };
};

export default computeDebtRatio;
