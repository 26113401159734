import { GET } from './httpMethods';

const logout = (session, database) => database.syncUpAll()
    // if there's an issue with the synchronization, we must then ask first
    .catch(() => new Promise((resolve, reject) => {
        if (window.confirm('Les données non sauvegardées seront perdues lors de la déconnexion. Voulez-vous continuer?')) {
            resolve();
        } else {
            reject();
        }
    }))
    // so far everything is fine, we may now log out
    .then(async () => {
        try {
            // notice the server of the logout
            await GET('/api/logout');
        } finally {
            // remove the cookie
            document.cookie = 'session=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            // empty the session
            session.update(null);
            // empty the database
            database.clearDb();
        }
    }, () => null);

export default logout;
