/* eslint-disable array-callback-return */
import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withPropsOnChange, lifecycle } from 'recompose';
import { invertedFullName } from 'common/utils/fullName';
import { setModal, loadDataList } from 'actions';
import withSpinner from 'components/withSpinner';
import { Action, Actions, Body, Head, Header, Table, Date, Item, listProvider } from 'components/list';
import { PATCH } from 'utils/httpMethods';
import Icon from 'common/components/fa/Icon';
import TransferOwnership from 'components/TransferOwnership';
import { withSession } from '../../../sessions';
import sortEntitiesChildren from '../../../../common/utils/sortEntitiesChildren';

const desactivateUser = async (id, session) => {
    if (!window.confirm('Souhaitez-vous vraiment désactiver cet utilisateur ?')) {
        return;
    }

    const { instanceTheseis } = session;

    await PATCH(`/api/users/${id}`, { body: { isInstanceTheseis: instanceTheseis, isActive: false } });
    document.location.href = '/admin/user';
};

const activateUser = async (id) => {
    if (!window.confirm('Souhaitez-vous vraiment ré-activer cet utilisateur ?')) {
        return;
    }

    await PATCH(`/api/users/${id}`, { body: { isActive: true } });
    document.location.href = '/admin/user';
};

const UserTableInner = ({ items, transferOwnership, session, entities }) => {
    const userNetwork = [];
    if (session.isAdminReseau) {
        // reseau actuel
        const entityOfTheUser = entities.filter((entity) => entity.name === session.entity.name);
        let managedNetworks = [];
        // je récupère mes réseaux et sous-réseaux
        if (!isEmpty(entityOfTheUser)) {
            const sortEntities = sortEntitiesChildren(entities, entityOfTheUser[0].id);
            managedNetworks = [entityOfTheUser, sortEntities].flat();
            items.map((users) => {
                managedNetworks.map((network) => {
                    if (network.id === users.entityId) {
                        userNetwork.push(users);
                    }
                });
            });
        }
    }

    const networkUsers = session.isAdmin ? items : userNetwork;

    return (
        <Table>
            <Head>
                <Header sortProperty="lastName" text="Nom" />
                <Header sortProperty="entity.name" text="Réseau" />
                <Header sortProperty="email" text="Email" />
                <Header sortProperty="createdAt" text="Date de création" />
                <Header sortProperty="updatedAt" text="Dernière modification" />
                <Header sortProperty="payingUser" text="Utilisateur payant" />
                <th />
            </Head>
            <Body>
                {networkUsers.map(({ id, email, createdAt, updatedAt, firstName, lastName, entity, payingUser, isActive, middlePartnerId }) => {
                    const isMiddleUser = !!middlePartnerId;

                    return (
                        <Item key={id} className={classNames({ inactive: !isActive })}>
                            <td>{invertedFullName({ firstName, lastName })}</td>
                            <td>{entity ? entity.name : null}</td>
                            <td>{email}</td>
                            <Date date={createdAt} />
                            <Date date={updatedAt} />
                            <td>{payingUser ? 'Oui' : 'Non'}</td>
                            {!isActive && (
                                <td>
                                    <Icon icon="times-rectangle" />
                                </td>
                            )}
                            <Actions>
                                <Action to={`/admin/user/${id}`} icon="pencil" text="Modifier" />
                                {session.isAdmin && <Action onClick={() => transferOwnership(id)} icon="arrows-h" text="Réaffecter les Rips" />}
                                {id !== session.id && (isActive ? (
                                    <Action onClick={() => desactivateUser(id, session)} icon="trash-o" text="Désactiver" right disabled={isMiddleUser} />
                                ) : (
                                    <Action onClick={() => activateUser(id)} icon="plug" text="Activer" right disabled={isMiddleUser} />
                                ))}
                            </Actions>
                        </Item>
                    );
                })}
            </Body>
        </Table>
    );
};
UserTableInner.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
    })).isRequired,
    transferOwnership: PropTypes.func.isRequired,
    session: PropTypes.shape({
        id: PropTypes.string,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        isAdminReseau: PropTypes.bool,
        isAdmin: PropTypes.bool,
    }).isRequired,
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
};

const UserTable = compose(
    withSession,
    listProvider({
        sorts: {
            updatedAt: { initialOrder: 'desc' },
            createdAt: { initialOrder: 'desc' },
            email: { initialOrder: 'asc' },
            lastName: { initialOrder: 'asc', default: true },
            'entity.name': { initialOrder: 'asc' },
        },
        name: 'user',
        type: 'user',
    }),
    withPropsOnChange(['items'], ({ items }) => items.sort((item) => {
        if (item.isActive) {
            return -1;
        }

        return 0;
    })),
    withSpinner,
    connect(null, { loadDataList }),
    lifecycle({
        componentDidMount() {
            this.props.loadDataList('entity');
        },
    }),
    connect(null, (dispatch) => ({
        transferOwnership: (selectedUserId) =>
            dispatch(setModal(() => <TransferOwnership userId={selectedUserId} />)),
    })),
    connect((state) => ({
        entities: Object.values(state.data.entity),
    })),
)(UserTableInner);

export default UserTable;
