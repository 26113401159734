import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { mapProps } from 'recompose';
import { reverseMonthlyFunding } from 'common/model/simulation/lifeInsurance/computeSavings';
import getMedianRate from 'common/model/simulation/lifeInsurance/getMedianRate';
import FormGroup from 'components/form/Group';
import { GridLayout } from 'components/form/layout';
import { EditableField, PercentageField, IntegerField } from 'components/form/field';
import GridRadioChoices from 'components/form/GridRadioChoices';
import Title from 'components/Title';
import { lifeInsuranceSimulatorProfiles } from 'common/choices';
import SimulationResults from './LifeInsuranceResults';

const LifeInsuranceInner = ({ monthlyFunding }) => (
    <Fragment>
        <FormGroup>
            <Title><b>Assurance vie</b></Title>
            <GridLayout className="double">
                <IntegerField
                    name="data.funding.initialFunding"
                    title="Versement initial"
                    required
                />
                <PercentageField
                    name="data.funding.entryFees"
                    title="Frais d'entrée pour le versement initial (%)"
                    required
                />
                <EditableField
                    name="data.funding.monthlyFunding.amount"
                    editName="data.funding.monthlyFunding.amountEdited"
                    title="Épargne mensuelle"
                    defaultValue={monthlyFunding}
                />
                <PercentageField
                    name="data.funding.monthlyFunding.fees"
                    title="Frais d'entrée pour les montants mensuels (%)"
                />
            </GridLayout>
            <div className="form-group-grid">
                <h6>Profil d&apos;investisseur</h6>
                <GridRadioChoices
                    choices={lifeInsuranceSimulatorProfiles}
                    name="data.funding.investorProfile"
                    className="double"
                />
            </div>
        </FormGroup>
        <SimulationResults />
    </Fragment>
);

LifeInsuranceInner.propTypes = {
    monthlyFunding: PropTypes.number.isRequired,
};

const LifeInsurance = flowRight([
    formValues({
        duration: 'data.funding.duration',
        initialFunding: 'data.funding.initialFunding',
        entryFees: 'data.funding.entryFees',
        monthlyFees: 'data.funding.monthlyFunding.fees',
        investorProfile: 'data.funding.investorProfile',
    }),
    mapProps(({
        capital,
        duration,
        entryFees,
        initialFunding,
        investorProfile,
        monthlyFees,
    }) => ({
        monthlyFunding: reverseMonthlyFunding({
            capital,
            initialFunding,
            // get the year rate from the investor profile
            yearRate: getMedianRate()[investorProfile],
            duration: Math.floor(duration / 12),
            entryFees,
            monthlyFees,
        }),
    })),
])(LifeInsuranceInner);

export default LifeInsurance;
