import PropTypes from 'prop-types';
import React from 'react';
import { GridLayout } from 'components/form/layout';
import { SelectField, TextField, YesNoField } from 'components/form/field';
import { checkRequiredField } from 'common/formValidation/lib';

export const validators = [
    checkRequiredField('taxRegime'),
    checkRequiredField('programZone'),
];

const ProgramForm = ({ className, programTypeField, withoutTaxRegime, avecZoneC, immeubleARenover, taxRegime }) => (
    <div className={className}>
        <GridLayout className="condensed" >
            {!withoutTaxRegime && (
                taxRegime
            )}
            {programTypeField}
            <TextField name="programPromoter" title="Promoteur" placeholder="saisissez le promoteur" />
            <TextField name="programName" title="Programme" placeholder="saisissez le programme" />
            <TextField name="programId" title="N° / Identifiant du lot" placeholder="saisissez le numéro" />
            <TextField name="programFloor" title="Étage du bien" placeholder="précisez l'étage" />
            <SelectField name="programZone" title="Zone" required>
                <option />
                <option>A</option>
                <option>A bis</option>
                <option>B1</option>
                <option>B2</option>
                {avecZoneC &&
                    <option>C</option>
                }
            </SelectField>
            <SelectField name="programType" title="Type de bien">
                <option />
                <option>Studio</option>
                <option>T1</option>
                <option>T1 bis</option>
                <option>T2</option>
                <option>T2 bis</option>
                <option>T3</option>
                <option>T3 bis</option>
                <option>T3 duplex</option>
                <option>T4</option>
                <option>T4 duplex</option>
                <option>T5</option>
                <option>T5 duplex</option>
                <option>Villa</option>
            </SelectField>
            {immeubleARenover && <YesNoField name="venteImmeubleARenover" title="Vente d'immeuble à rénover" />}
        </GridLayout>
    </div>
);

ProgramForm.propTypes = {
    className: PropTypes.string,
    programTypeField: PropTypes.node.isRequired,
    withoutTaxRegime: PropTypes.bool,
    avecZoneC: PropTypes.bool,
    immeubleARenover: PropTypes.bool,
    taxRegime: PropTypes.node.isRequired,
};

ProgramForm.defaultProps = {
    className: null,
    withoutTaxRegime: false,
};

export default ProgramForm;
