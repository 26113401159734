import classNames from 'classnames';
import { get, keys } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const FormWizardInner = ({ errorCount, to, name }) => (
    <NavLink to={to} className="wizard-item">
        <div
            className={classNames('wizard-item-point', errorCount ? 'invalid' : 'valid')}
            data-error-count={errorCount}
        />
        <div className="wizard-item-label">{name}</div>
    </NavLink>
);

FormWizardInner.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    errorCount: PropTypes.number.isRequired,
};

const FormItem = connect((state, { form }) => ({
    errorCount: keys(get(['formValidation', 'errors', form], state)).length,
}))(FormWizardInner);

export default FormItem;
