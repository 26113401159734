import PropTypes from 'prop-types';
import React from 'react';
import { Legend, Pie, PieChart } from 'recharts';
import { formatPercentage } from '../../utils/format';

const data = ({ rent, saving, taxGains, monthlyTva, colors }) => {
    let total = rent + taxGains + saving;
    const result = [
        { name: 'loyer', value: rent / total, fill: colors[0] },
        { name: 'gain fiscal', value: taxGains / total, fill: colors[1] },
        { name: 'épargne', value: saving / total, fill: colors[2] },
    ];

    if (monthlyTva) {
        total += monthlyTva;
        result.push({ name: 'tva', value: monthlyTva / total, fill: colors[3] });
    }

    return result;
};

const formatLabel = ({ value }) => formatPercentage(value, 0);

const BalancePie = ({ balance, ...props }) => (
    <PieChart {...props}>
        <Pie
            data={data(balance)}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            isAnimationActive={false}
            label={formatLabel}
            labelLine
            legendType="circle"
        />
        <Legend layout="vertical" verticalAlign="bottom" />
    </PieChart>
);

BalancePie.propTypes = {
    balance: PropTypes.shape({
        rent: PropTypes.number.isRequired,
        saving: PropTypes.number.isRequired,
        taxGains: PropTypes.number.isRequired,
    }).isRequired,
};

export default BalancePie;
