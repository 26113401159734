import PropTypes from 'prop-types';
import React from 'react';
import RadioQuestion from '../../../InvestorProfile/RadioQuestion';

const Creation = ({
    rip: {
        familySituation,
        firstName,
        partnerFirstName,
        dualRip,
    },
}) => {
    const isCohabitation = 'cohabitation' === familySituation && dualRip;

    return isCohabitation ? (
        <RadioQuestion
            choices={{
                client: firstName,
                partner: partnerFirstName,
            }}
            name="beneficiary"
            title="Bénéficiaire"
            required
        />
    ) : null;
};

Creation.propTypes = {
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
        firstName: PropTypes.string,
        partnerFirstName: PropTypes.string,
        dualRip: PropTypes.bool,
    }).isRequired,
};

export default Creation;
