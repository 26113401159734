// Crédits d'impots

import { clamp, filter, flow, forEach, has, map, sum, get } from 'lodash/fp';
import moment from 'moment';
import { parseInteger, sumOrAdd } from '../utils';
import calculationWithEndDate from '../../utils/calculationWithEndDate';
import getCurrentYear from '../../utils/currentYear';

// Pour un enfant en garde alternée, le montant max est divisé par 2
export const childCare = (credit, settings) => {
    const { id } = credit;
    const { rate, ceil } = get('summary.credits.childCare', settings);
    const creditBound = (parseInteger(credit.dependentChildren) + (parseInteger(credit.alternateCustodyChildren) / 2))
        * ceil;

    return {
        id,
        reportedAmount: credit.amount,
        computedAmount: calculationWithEndDate(getCurrentYear(), credit, rate, creditBound),
    };
};

// On ne dispose pas dans le formulaire de l'information sur la présence d'une
// personne handicapée dans le foyer fiscal qui porte le plafond à 20 000€
// D'autres cas ne sont pas envisagés:
// - cas où on emploie pour la première fois un salarié
// - en plus d'un plafond global, il existe des plafonds et des conditions pour
// certaines situations (petits bricolages, travaux informatiques, petits travaux
// de jardinage)
// https://www.service-public.fr/particuliers/vosdroits/F12

export const employeeAtHome = (rip) => (credit, settings) => {
    const { rate, ceil, maxCeil, childrenOrDependentPart } = get('summary.credits.employeeAtHome', settings);
    const { id } = credit;

    let bound = ceil;

    // Get dependent children
    const dependentChildren = filter((child) => 'outside_household' !== child.situation, rip.children);

    // Children bound increase
    bound += flow([
        map((child) => {
            const part = 'alternate_custody' === child.situation ? 0.5 : 1;

            return childrenOrDependentPart * part;
        }),
        sum,
    ])(dependentChildren);

    // Senior bound increase
    const birthDates = [rip.birthDate];

    if (['married', 'partner'].includes(rip.familySituation)) {
        birthDates.push(rip.partnerBirthDate);
    }

    birthDates.forEach((date) => {
        if (65 >= moment().diff(moment(date), 'years')) {
            bound += childrenOrDependentPart;
        }
    });

    bound = clamp(0, maxCeil)(bound);

    return {
        id,
        reportedAmount: credit.amount,
        computedAmount: calculationWithEndDate(getCurrentYear(), credit, rate, bound),
    };
};

export const homeWork = (credit) => ({
    id: credit.id,
    reportedAmount: credit.amount,
    // computedAmount: calculationWithEndDate(getCurrentYear(), credit),
    computedAmount: parseFloat(credit.rate) * parseInteger(credit.amount),
});

export const other = (credit) => {
    const endYear = !credit.recurrentEvent ? parseInteger(credit.endYear) : null;

    const computeWithEndDate = getCurrentYear() <= endYear ? parseInteger(credit.amount) : 0;

    return ({
        id: credit.id,
        reportedAmount: credit.amount,
        computedAmount: endYear ? parseInteger(computeWithEndDate) : credit.amount,
    });
};

// Le calcul du crédit d'impôt transition énergétique est complexe et nécessite
// des données sur les années précédentes. On simplifie en demandant le montant
// éligible pour l'année en cours qu'on multiplie par le taux (30%).

const getCredit = {
    childCare,
    employeeAtHome,
    homeWork,
    other,
};

const computeCredits = (rip, _, settings) => {
    let credits = 0;
    let creditsDetails = {};

    flow([
        filter(({ type }) => has(type, getCredit)),
        forEach((credit) => {
            const computer = 'employeeAtHome' === credit.type
                ? getCredit[credit.type](rip)
                : getCredit[credit.type];

            const element = computer(credit, settings);
            creditsDetails = sumOrAdd(creditsDetails, credit.type, element);
            credits += element.computedAmount;
        }),
    ])(rip.taxCredits);

    return { credits, creditsDetails };
};

export default computeCredits;
