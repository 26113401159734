import PropTypes from 'prop-types';
import React from 'react';
import { compose, nest } from 'recompose';
import FormButtons from 'components/FormButtons';
import { withSession } from '../../../../sessions';
import Form from '../Form';
import SimulationHeader from '../Header';
import SummaryInner from './SummaryInner';
import withSummary from './withSummary';

const SummaryForm = ({
    navigate,
    params: { id, simulationId },
    simulation,
    children,
    session,
    rip,
}) => {
    const isApproved = session.permissions.mayDownloadSimulationPDF(simulation, rip);
    const handleSubmit = (event) => {
        // prevent the browser to reload the document
        event.preventDefault();
        // redirect to the next step
        navigate(isApproved
            ? `/rip/${id}/simulations/`
            : `/rip/${id}/simulations/${simulationId}/approval/request`);
    };

    return (
        <Form handleSubmit={handleSubmit} needFunding={simulation.needFunding}>
            <div className="simulation-form-inner">
                <div className="container">
                    <SimulationHeader />
                    {children}
                </div>
            </div>
            <FormButtons
                back={`/rip/${id}/simulations/${simulationId}/simulation`}
                list={`/rip/${id}/simulations/`}
                nextButtonContent={isApproved ? 'Terminer' : 'Continuer'}
            />
        </Form>
    );
};

SummaryForm.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        simulationId: PropTypes.string,
    }),
    navigate: PropTypes.shape({}),
    simulation: PropTypes.shape({
        needFunding: PropTypes.bool,
    }).isRequired,
    children: PropTypes.node.isRequired,
    session: PropTypes.shape({
        permissions: PropTypes.shape({
            mayDownloadSimulationPDF: PropTypes.func,
        }),
    }).isRequired,
    rip: PropTypes.shape({}).isRequired,
};

export default compose(
    withSession,
    withSummary,
)(nest(SummaryForm, SummaryInner));
