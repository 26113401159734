import React from 'react';
import Icon from 'common/components/Icon';
import './ClosedMortgage.scss';

const ClosedMortgage = () => (
    <div className="closed-mortgage">
        <Icon icon="check-circle" />
        &nbsp;Vous avez renseigné un crédit sold&eacute;
    </div>
);

export default ClosedMortgage;
