import React, { Fragment } from 'react';
import Icon from 'common/components/Icon';
import PropTypes from 'prop-types';

import { DateField, TextField } from '../../form/field';
import { GridLayout } from '../../form/layout';

function InfosUnion({ partner }) {
    return (
        <Fragment>
            <h5 className="title">
                <Icon icon="arrow-right" />
                <b> Informations {partner ? 'pacs' : 'mariage'}</b>
            </h5>
            <GridLayout className="triple condensed gray-box full-width">
                <DateField
                    name="unionDate"
                    title={
                        <span>
                            A quelle date{' '}
                            <b>
                                vous êtes-vous
                                {partner ? ' pacsé(e)' : ' marié(e)'}&nbsp;?
                            </b>
                        </span>
                    }
                />
                <TextField
                    title={`Lieu de votre ${partner ? 'pacs' : 'mariage'}`}
                    placeholder="Bordeaux"
                    name="unionPlace"
                />
            </GridLayout>
        </Fragment>
    );
}

InfosUnion.propTypes = {
    partner: PropTypes.bool,
};

export default InfosUnion;
