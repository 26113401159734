import { filter, flow, get, has, map } from 'lodash/fp';

const getLandRentalRegime = {
    landRentalIncome: ({ regime }) => regime,
};

const computeLandRentalRegime = flow([
    get('incomes'),
    filter(({ type }) => has(type, getLandRentalRegime)),
    map(({ type, ...details }) => getLandRentalRegime[type](details)),
    (regimes) => (regimes.includes('micro') ? 'micro' : 'real'),
    (landRentalRegime) => ({ landRentalRegime }),
]);

export default computeLandRentalRegime;
