// vérification d'une value
const checkIfValueIsNull = (value) => {
    if (value && '' !== value) {
        return value;
    }

    return null;
};

// récupération du reseau parent
const getAncestor = (entityId, entitiesList) => {
    const myEntity = entitiesList.filter((entity) => entity.id === entityId);

    if (myEntity[0].parentId) {
        const myEntityParent = entitiesList.filter((entity) => entity.id === myEntity[0].parentId);

        return myEntityParent;
    }

    return null;
};

// ici on regarde les paramètres du reseau parent
// si le  value du setting passé en paramètre est nulle et s'il y a un reseau grand-parent (gd-prt par rapport au reseau initiale) on va voir chez le grand-parent
const ifSettingsAncestorNullCheckSettingsAncestorAbove = async (dataFromAncestor, settingName, parent, entitiesList, handleGetRequest) => {
    if (null === checkIfValueIsNull(dataFromAncestor[settingName])) {
        const ancestor = (getAncestor(parent[0].id, entitiesList));
        if (ancestor) {
            const data = await handleGetRequest(`/api/entities/${parent[0].id}/defaultValues`);

            return data[settingName];
        }
    }

    return dataFromAncestor[settingName];
};

// ici on regarde les paramètres du reseau
// tant que la value du setting passé en paramètre est nulle et qu'il y a un reseau parent on va voir chez le parent
const ifSettingsNullCheckSettingsAncestor = async (settingName, settingValue, entityId, entitiesList, handleGetRequest) => {
    const ancestor = getAncestor(entityId, entitiesList);
    while (null === checkIfValueIsNull(settingValue) && ancestor) {
        // eslint-disable-next-line no-await-in-loop
        const dataFromAncestor = await handleGetRequest(`/api/entities/${entityId}/defaultValues`);

        return ifSettingsAncestorNullCheckSettingsAncestorAbove(dataFromAncestor, settingName, ancestor, entitiesList, handleGetRequest);
    }

    return settingValue;
};

export default ifSettingsNullCheckSettingsAncestor;
