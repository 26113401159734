import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import Icon from 'common/components/Icon';
import Spinner from 'components/Spinner';
import './VersionWarning.scss';

const VersionWarning = ({ close, reload }) => (
    <div className="version-warning-modal">
        <Icon icon="times" className="close-action" onClick={close} />
        <h3 className="text-danger">
            <Icon icon="warning" className="fa-fw" /> Une mise à jour est disponible
        </h3>
        <div className="text-right">
            <button onClick={reload}>
                <Icon icon="refresh" className="fa-fw" /> Recharger l&apos;application
            </button>
        </div>
    </div>
);

VersionWarning.propTypes = {
    close: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => ({
    close: () => dispatch(setModal(null)),
    reload: () => {
        dispatch(setModal(() => <Spinner>Rechargement en cours...</Spinner>, false));
        setTimeout(() => document.location.reload(), 10000);
    },
}))(VersionWarning);
