import React from 'react';
import { setModal, setOutdated } from 'actions';
import VersionWarning from 'components/VersionWarning';

const handleVersion = (store) => (res) => {
    if (res.headers.get('ERIP-Version-Id') !== process.env.VERSION.id
        && !store.getState().outdated) {
        store.dispatch(setOutdated());
        store.dispatch(setModal(() => <VersionWarning />));
    }

    return res;
};

export default handleVersion;
