import React from 'react';
import Dropdown from 'components/Dropdown';
import { FloatField } from 'components/form/field';

const PlafondLoyersPinel = () => (
    <Dropdown title="Plafonds des loyers">
        <table className="settings-table third">
            <tbody>
                <tr>
                    <td>Zone géographique</td>
                    <td>Plafond de loyer (€/m²)</td>
                </tr>
                <tr>
                    <td>Zone A</td>
                    <td><FloatField name="simulation.pinel.plafonds.zone.A" /></td>
                </tr>
                <tr>
                    <td>Zone A Bis</td>
                    <td><FloatField name="simulation.pinel.plafonds.zone.A bis" /></td>
                </tr>
                <tr>
                    <td>Zone B1</td>
                    <td><FloatField name="simulation.pinel.plafonds.zone.B1" /></td>
                </tr>
                <tr>
                    <td>Zone B2</td>
                    <td><FloatField name="simulation.pinel.plafonds.zone.B2" /></td>
                </tr>
                <tr>
                    <td>Zone C</td>
                    <td><FloatField name="simulation.pinel.plafonds.zone.C" /></td>
                </tr>
            </tbody>
        </table>
    </Dropdown>
);

export default PlafondLoyersPinel;
