import React, { useState } from 'react';
import SimulationField from './SimulationField';
import Error from '../../../Error';

const Creation = (props) => {
    const [isBeneficiarySign, setIsBeneficiarySign] = useState(false);

    return (
        <div>
            <div>
                <SimulationField
                    name="simulationId"
                    title="Séléctionner la simulation"
                    setIsBeneficiarySign={setIsBeneficiarySign}
                    {...props}
                />
                <Error forceerror={isBeneficiarySign} errortext="Veuillez signer le RIP avant de réaliser une souscription." />
            </div>
        </div>

    );
};

export default Creation;
export { default as formValidate } from './formValidate';
