import React from 'react';
import { withProps } from 'recompose';
import FormGroup from 'components/form/Group';
import Title from 'components/Title';
import TypedCollection from 'components/form/TypedCollection';
import { lifeInsuranceEventTypes } from 'common/choices';
import Event from './Event';
import RecurrentEvent from './RecurrentEvent';

import './PlannedEvents.css';

const types = {
    payment: {
        component: withProps({ type: 'payment' })(Event),
        title: lifeInsuranceEventTypes.payment,
        defaultValues: { fees: '0.05' },
    },
    withdrawal: {
        component: Event,
        title: lifeInsuranceEventTypes.withdrawal,
    },
    recurrentPayment: {
        component: withProps({ type: 'payment' })(RecurrentEvent),
        title: lifeInsuranceEventTypes.recurrentPayment,
        defaultValues: { fees: '0.05' },
    },
    recurrentWithdrawal: {
        component: RecurrentEvent,
        title: lifeInsuranceEventTypes.recurrentWithdrawal,
    },
};

const PlannedEvents = () => (
    <FormGroup className="life-insurance-events">
        <Title><b>Événements à prévoir</b></Title>
        <TypedCollection name="data.events" types={types} textOnly />
    </FormGroup>
);

export default PlannedEvents;
