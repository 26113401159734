import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { pure, compose } from 'recompose';
import fullName from 'common/utils/fullName';
import { isCouple } from 'common/utils/hasPartner';
import {
    Item,
    Initials,
    InitialsErrorBadge,
    Date,
    Actions,
    Action,
    connectActiveItem,
    PdfAction,
} from 'components/list';
import Contact from 'components/rip/Contact';
import { withSession } from '../../../../components/sessions';

const ListItem = ({
    isActive,
    contactDisplayed,
    show,
    item: rip,
    allMode,
    session,
}) => {
    const {
        id,
        firstName,
        lastName,
        updatedAt,
        mobile,
        personalEmail,
        familySituation,
        partnerFirstName,
        partnerLastName,
        partnerMobile,
        partnerPersonalEmail,
        user,
        contactOrigin,
        contactOriginOther,
        errorCount,
        signatureDate,
        validators,
    } = rip;

    return (
        <Fragment>
            <Item
                key={id}
                displayActions={isActive}
                onFocus={!isActive ? () => show() : null}
            >
                <Initials firstName={firstName} lastName={lastName}>
                    {null !== errorCount && (
                        <InitialsErrorBadge
                            errorCount={errorCount}
                            signatureDate={signatureDate}
                            isAvailable
                        />
                    )}
                </Initials>
                <td>{fullName({ firstName, lastName })}</td>
                {session.status !== 'cgp' ? (<td>{fullName(user)}</td>) : <td />}
                {session.status !== 'cgp' ? <td>{get('entity.name', user)}</td> : <td />}
                {allMode && session.status !== 'cgp' && (
                    <td>
                        {validators.map((validator) => validator && (
                            <div key={validator.id}>{fullName(validator)}</div>
                        ))}
                    </td>
                )}
                {session.status !== 'cgp' ? (<Date date={updatedAt} />) : <td />}
                <td>En attente</td>
                {session.status !== 'cgp' && (
                    <Actions>
                        <Action
                            to={`/rip/${id}/etat-civil`}
                            icon="pencil"
                            text="modifier"
                        />
                        <PdfAction url={`/api/rips/${id}/pdf`} />
                        <Action
                            onClick={() => show('contact')}
                            icon="comments-o"
                            text="contact"
                        />
                        <Action
                            to={`/rip/${id}/approval/none`}
                            onClick={() => show('contact')}
                            iconNew="true"
                            icon="share"
                            text="Validation"
                        />
                        <Action
                            iconNew="true"
                            icon="eSign"
                            text="eSign"
                        />
                    </Actions>
                )}
            </Item>
            {contactDisplayed ? (
                <Contact
                    key={`${id}-contact`}
                    fullName={fullName({ firstName, lastName })}
                    mobile={mobile}
                    personalEmail={personalEmail}
                    isCouple={isCouple(familySituation)}
                    partnerFullName={fullName({ firstName: partnerFirstName, lastName: partnerLastName })}
                    partnerMobile={partnerMobile}
                    partnerPersonalEmail={partnerPersonalEmail}
                    contactOrigin={contactOrigin}
                    contactOriginOther={contactOriginOther}
                />
            ) : null}
        </Fragment>
    );
};

ListItem.propTypes = {
    isActive: PropTypes.bool.isRequired,
    contactDisplayed: PropTypes.bool.isRequired,
    show: PropTypes.func.isRequired,
    item: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        mobile: PropTypes.string,
        personalEmail: PropTypes.string,
        familySituation: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        partnerMobile: PropTypes.string,
        partnerPersonalEmail: PropTypes.string,
        user: PropTypes.string,
        contactOrigin: PropTypes.string,
        contactOriginOther: PropTypes.string,
        errorCount: PropTypes.number,
        signatureDate: PropTypes.string,
        validators: PropTypes.string,
        approval: PropTypes.string,
    }).isRequired,
    session: PropTypes.shape({
        status: PropTypes.string,
    }).isRequired,
    allMode: PropTypes.bool,
};

ListItem.defaultProps = {
    allMode: false,
};

export default compose(
    withSession,
    pure,
    connectActiveItem(({ item: { id } }) => id, (item, { activeItem, target }) => {
        const isActive = item === activeItem;

        return {
            isActive,
            contactDisplayed: isActive && 'contact' === target,
        };
    }),
)(ListItem);
