// * les paramètres indiqués ici sont enregistrés directement en base dans les paramètres de la table simulation
// * un changement de taux à cet endroit n'impactera donc pas les anciennes simulations
const initialValues = {
    condominiumFees: 0.06,
    initialCashFlow: 2000,
    realEstateIndexation: 0.015,
    fundingADI: 3.5,

    showOperationReview: true,
    showCapitalOutflowAssumptions: true,
    showAnnuityRetirementAssumptions: true,
    showHouseholdTaxSummary: true,
    showDepreciationTable: true,
    showAssumptionsWithReindexation: true,
    showMonthlySavingsColumn: true,
    showSmoothSavingsColumn: true,
    data: {
        showCashFlowColumn: true,
        showYearlySavingsColumn: true,
        showAssumptionsWithFees: true,
    },

    beneficiary: 'client',
};

export default initialValues;
