import { split } from 'lodash/fp';
import getItem from './getItem';

const getItemProperty = (items, index, property) => {
    const item = getItem(index, items);

    return item ? item[property] : null;
};

const getSubjectName = (subject, rip) => {
    // split the subject into a type and an index
    const [subjectType, index] = split('_', subject);

    switch (subjectType) {
        case 'child':
            return getItemProperty(rip.children, index, 'firstNames');
        case 'dependent':
            return getItemProperty(rip.dependents, index, 'type');
        default:
            return null;
    }
};

export default getSubjectName;
