import { flowRight, omit, pick } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import moment from 'moment';

import { getContext, withProps } from 'recompose';
import { Field, fieldPropTypes } from 'redux-form';
import Error from '../../Error';
import withScroll from '../withScroll';
import Warning from '../../Warning';
import FieldInfo from '../FieldInfo';
import { withDisableField } from './TypedDisabled';

/**
 * Props passed to the input instead of the group
 */
const inputProps = ['max', 'min', 'placeholder', 'step', 'type', 'ref', 'disabled'];

const InputInner = ({
    formGroup: Group,
    input: { name, value, ...input },
    info,
    programType,
    meta,
    required,
    classNameInput,
    ...props
}) => {
    useEffect(() => {
        const handleWheel = (event) => {
            event.preventDefault();
        };

        const inputElement = document.getElementById(name);
        if (inputElement) {
            inputElement.addEventListener('wheel', handleWheel);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('wheel', handleWheel);
            }
        };
    }, [name]);

    const checkDateActeInferieur2023 = (nameInput, valueInput) => {
        if ('propertyActDate' === nameInput) {
            const dateActe = moment(valueInput);
            const dateLimite = moment('2022-12-31');
            const warningMessage = 'La date saisie ne permet pas de bénéficier du dispositif';

            if (dateActe > dateLimite) {
                return <Warning id={nameInput}>{warningMessage}</Warning>;
            }
        }

        return null;
    };

    return (
        <Group component="label" htmlFor={name} {...omit(inputProps, props)} required={required} >
            <input
                id={name}
                className={`${value || 0 === value ? ' filled' : ''} ${classNameInput}`}
                name={name}
                value={value}
                required={required}
                {...pick(inputProps, props)}
                {...input}
                maxLength={props.nombrecaracteremax}
            />
            {info ? <FieldInfo id={name}>{info}</FieldInfo> : null}
            {'censi-bouvard' === programType ? checkDateActeInferieur2023(name, value) : null}
            <span className="validation-feedback" />
            {props.forceerror ? <Error {...meta} forceerror={props.forceerror} errortext={props.errortext} /> : <Error {...meta} />}
        </Group>
    );
};

InputInner.propTypes = {
    ...fieldPropTypes,
    formGroup: PropTypes.func.isRequired,
    required: PropTypes.bool,
};

InputInner.defaultProps = {
    required: false,
};

const Input = flowRight([
    withDisableField(),
    withProps({ component: InputInner }),
    getContext({ formGroup: PropTypes.func.isRequired }),
    withScroll,
])(Field);

export default Input;
