import React from 'react';
import PropTypes from 'prop-types';
import { Action, Actions, Date, Item, connectActiveItem } from 'components/list';
import fullName from 'common/utils/fullName';
import Icon from 'common/components/Icon';
import './Entity.scss';

const Entity = ({ id, name, caseManager, manager, createdAt, children, displayChildren, show, isActive }) => (
    <Item
        displayActions={isActive}
        onFocus={!isActive ? () => show() : null}
        ignoreFocus
    >
        <td>{name}</td>
        <td>{manager ? fullName(manager) : ''}</td>
        <td>{caseManager ? fullName(caseManager) : ''}</td>
        <Date date={createdAt} />
        <td>
            {children && children.length > 0 && (
                /* eslint-disable-next-line */
                <button
                    className="children-count"
                    onFocus={() => {
                        show('none');
                        displayChildren({ id, name });
                    }}
                >
                    <span>{children.length}</span>
                    <Icon icon="chevron-down" />
                </button>
            )}
        </td>
        <Actions>
            <Action to={`/admin/entity/${id}`} icon="pencil" text="modifier" />
            <Action to="/" icon="trash-o" text="supprimer" right />
        </Actions>
    </Item>
);

Entity.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    caseManager: PropTypes.shape({}),
    manager: PropTypes.shape({}),
    createdAt: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    displayChildren: PropTypes.func,
    show: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
};

export default connectActiveItem(
    ({ id }) => id,
    (item, { activeItem, target }) => ({ isActive: item === activeItem && 'default' === target }),
)(Entity);
