import {
    get,
    getOr,
    isFunction,
    isObject,
} from 'lodash/fp';

const computeNewInvestorProfile = (investorProfile, newQuestions, sufficientKnowledge) => {
    let point = [];
    let selectRejectedProfile = [];
    // list of profiles still available
    const availableProfiles = ['P', 'E', 'D'];

    for (const [name, choices] of Object.entries(newQuestions)) {
        // get the user answer
        const userAnswer = get(name, investorProfile);

        const questionResult = isFunction(choices)
            ? choices(userAnswer, investorProfile)
            : getOr({ resolvedProfile: null }, userAnswer, choices);

        if (!isObject(questionResult)) {
        // we got a definitive profile
            return questionResult;
        }

        const {
            point: answerValues = [],
            resolvedProfile = undefined,
            rejectedProfiles = [],
        } = questionResult;

        if (undefined !== resolvedProfile) {
            // return it
            return { resolvedProfile };
        }

        point = point.concat(answerValues);
        selectRejectedProfile = selectRejectedProfile.concat(rejectedProfiles);
    }
    const initialValue = 0;
    const totalPoints = point.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
    if (sufficientKnowledge) {
        if (totalPoints < 10) {
            return { resolvedProfile: 'P' };
        }
    }

    return { totalPoints, availableProfiles, selectRejectedProfile };
};

export default computeNewInvestorProfile;
