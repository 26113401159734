import { mergeWith } from 'lodash/fp';
import mergeArrays from './mergeArrays';

export const addLandRentalResults = (rip, { landRentalResults }, { effectiveIncome }) => mergeWith(
    // we need to concat arrays together
    mergeArrays,
    // the original object is the original rip
    rip,
    // then we add the incomes from the pinel
    {
        incomes: [
            {
                type: 'landRentalIncome',
                regime: 'real',
                result: effectiveIncome - landRentalResults,
            },
        ],
    },
);

const addPinelToRIP = (
    rip,
    summary,
    simulationResult,
    fiscalHome = 'client',
) => mergeWith(
    // we need to concat arrays together
    mergeArrays,
    // the previous rip
    addLandRentalResults(rip, summary, simulationResult),
    // then the tax reduction
    {
        reductions: [
            {
                type: 'other',
                amount: simulationResult.taxReduction,
                capped: true,
                fiscalHome,
            },
        ],
    },
);

export default addPinelToRIP;
