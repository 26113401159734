import React from 'react';
import { EditableField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import FutureOwner from './FutureOwner';

const FreeAccomodation = () => (
    <HorizontalLayout>
        <EditableField
            title="Loyer Fictif"
            name="hypotheticalRent"
            editName="editHypotheticalRent"
            defaultValue={500}
            type="number"
            required
        />
        <FutureOwner />
    </HorizontalLayout>
);

export default FreeAccomodation;
