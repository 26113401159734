// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rib-finances-taxation-grid {
    margin-bottom: 2rem;
}

.rib-finances-taxation-grid > .checkbox {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/profile/Taxation.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".rib-finances-taxation-grid {\n    margin-bottom: 2rem;\n}\n\n.rib-finances-taxation-grid > .checkbox {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
