import classNames from 'classnames';
import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { branch, withProps } from 'recompose';
import dataProvider from 'components/dataProvider';
import SummaryContent from './SummaryContent';
import CohabitationSummary from './CohabitationSummary';

const Summary = ({ overlay, rip, summaryComponent: Inner }) => (
    <div className={classNames('rip-summary', { 'overlay-scrollable': overlay })}>
        <div className="container">
            <Inner rip={rip} />
        </div>
    </div>
);

Summary.propTypes = {
    overlay: PropTypes.bool,
    rip: PropTypes.shape({}).isRequired,
    summaryComponent: PropTypes.func,
};

Summary.defaultProps = {
    overlay: true,
    summaryComponent: SummaryContent,
};

export default flowRight([
    dataProvider({
        id: ({ id }) => id,
        type: 'rip',
    }),
    branch(
        ({
            rip: {
                familySituation,
                dualRip,
            },
        }) => 'cohabitation' === familySituation && dualRip,
        withProps({ summaryComponent: CohabitationSummary }),
    ),
])(Summary);
