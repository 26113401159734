import * as Sentry from '@sentry/browser';
import { get } from 'lodash/fp';
import { library } from '@fortawesome/fontawesome-svg-core/index';
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import moment from 'moment';
import { createElement } from 'react';
import { render } from 'react-dom';
import SmoothScroll from 'smoothscroll-polyfill';
import './bootstrap.scss';
import './font-awesome.scss';

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
}

SmoothScroll.polyfill();

moment.locale('fr');

// get the container & settings/data
const container = document.getElementById('app');
const appData = JSON.parse(get('dataset.app', container) || '{}');

if (appData.sentryDSN) {
    Sentry.init({
        dsn: appData.sentryDSN,
        environment: appData.sentryEnv,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // tracesSampleRate: 1.0,
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
    });
}

// eslint-disable-next-line global-require
const setup = () => {
    // get the App component (latest version when running with HMR)
    // eslint-disable-next-line global-require
    const App = require('./App').default;

    // render it
    render(createElement(App, { appData }), container);
};

setup();

library.add(faComments, faPencil, faFilePdf, faCalculator, faFileWord, faHistory, faUpload, faArchive, faTrash, faUser, faUsers, faSearch);

if ('production' !== process.env.NODE_ENV && module.hot) {
    module.hot.accept('./App.jsx', setup);
}
