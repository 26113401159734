import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { savingsTypes } from 'common/choices';
import { ChoiceField, IntegerField, TextField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';

const RetirementDeductionInner = ({ name, savingsType }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <ChoiceField name={`${name}.savingsType`} title="Type d&apos;épargne" choices={savingsTypes} />
        {'other' === savingsType ? <TextField name={`${name}.description`} title="Précisez" /> : null}
        <IntegerField name={`${name}.amount`} title="Montant annuel" required />
        <Recurrent name={name} />
    </HorizontalLayout>
);

RetirementDeductionInner.propTypes = {
    name: PropTypes.string.isRequired,
    savingsType: PropTypes.string.isRequired,
};

const RetirementDeduction = formValues(({ name }) => ({
    savingsType: `${name}.savingsType`,
}))(RetirementDeductionInner);

export default RetirementDeduction;
