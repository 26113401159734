import { flatten, get, isEmpty } from 'lodash/fp';
import branchValidate from './branchValidate';

const validateCollection = (field, getValidators) => branchValidate(
    (errors, values) => !isEmpty(get(field, values)),
    (values, props) => flatten(get(field, values).map((element, index) => getValidators(
        // element name (collection[index])
        `${field}[${index}]`,
        // element values
        element,
        // form values
        values,
        // properties received by reduxForm
        props,
        // index of the current element
        index,
    ))),
);

export default validateCollection;
