const choices = {
    actions: 'Actions ou OPCVM ou trackers',
    risk: 'Capital Risque (actions non cotées, FCPR, FCPI, FIP, SCR)',
    products: 'Produits réservés à certains investisseurs',
    obligations: 'Obligations ou OPCVM obligataires',
    realEstate: 'Produits immobiliers (OPCI, SCPI)',

};

export default choices;
