import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { GridLayout } from 'components/form/layout';
import { IntegerField, PercentageField, YesNoField } from 'components/form/field';
import { checkRequiredField, branchValidate } from 'common/formValidation/lib';

export const validators = [
    checkRequiredField('condominiumFees'),
    // pinel validation
    branchValidate((errors, { product }) => 'pinel' === product || 'pinelDenormandie' === product, [
        checkRequiredField('managementFees'),
        checkRequiredField('guaranteedIncome'),
        checkRequiredField('rentalFees'),
    ]),
];

const renderOtherFees = (product) => {
    switch (product) {
        case 'pinel':
            return (
                <Fragment>
                    <IntegerField name="otherFees" title="Détails des charges diverses (TTC)" />
                    <YesNoField title="Charges diverses récurrentes ?" name="recurringOtherFees" />
                </Fragment>
            );
        case 'pinelDenormandie':
            return (
                <Fragment>
                    <IntegerField name="otherFees" title="Détails des charges diverses (TTC)" />
                    <YesNoField title="Charges diverses récurrentes ?" name="recurringOtherFees" />
                </Fragment>
            );

        default:
            return (
                <IntegerField
                    name="otherFees"
                    title={`Détails des charges diverses ${'lmnp' === product ? '(HT)' : '(TTC)'}`}
                />
            );
    }
};

const FeesFormInner = ({ className, product }) => (
    <div className={className}>
        <GridLayout className="condensed">
            {'pinel' === product || 'pinelDenormandie' === product ? (
                <Fragment>
                    <PercentageField name="managementFees" title="Frais de gestion (%)" required />
                    <PercentageField name="guaranteedIncome" title="Assurance revenu (%)" required />
                    <PercentageField name="rentalFees" title="Honoraires de location (%)" required />
                </Fragment>
            ) : null}
            <PercentageField name="condominiumFees" title="Charges de copropriété (%)" required />
            {renderOtherFees(product)}
            {'lmnp' === product ? (
                <IntegerField name="data.fees.accounting" title="Frais de comptabilité (HT)" />
            ) : null}
        </GridLayout>
    </div>
);

FeesFormInner.propTypes = {
    className: PropTypes.string,
    product: PropTypes.string.isRequired,
};

FeesFormInner.defaultProps = {
    className: null,
};

const FeesForm = formValues({ product: 'product' })(FeesFormInner);

export default FeesForm;
