import isStrongEnough from '../../utils/checkPassword';
import createSimpleValidator from './createSimpleValidator';

const errorString = 'Votre mot de passe doit faire au moins 8 caractères et contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial.';
const checkPasswordStrongEnough = createSimpleValidator(
    (value) => (value ? isStrongEnough(value) : true),
    errorString,
);

export default checkPasswordStrongEnough;
