import scpi from './scpi';
import dismemberedScpi from './dismemberedScpi';
import lifeInsurance from './lifeInsurance';
import pinel from './pinel';
import lmnp from './lmnp';
import pinelDenormandie from './pinelDenormandie';
import per from './per';

const index = {
    scpi,
    dismemberedScpi,
    lifeInsurance,
    pinel,
    pinelDenormandie,
    lmnp,
    // per - nouveau
    per,
};

export default index;
