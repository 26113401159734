import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { flowRight } from 'lodash/fp';

import Title from 'components/Title';
import Collection from 'components/form/Collection';
import FormGroup from 'components/form/Group';
import Dependent from './Dependent';

const Dependents = ({ id, dependents, familyEvents, change }) => (
    <FormGroup>
        <Title>Avez-vous <b>d&apos;autres personnes à charge&nbsp;? *</b></Title>
        <Collection
            name="dependents"
            component={Dependent}
            addLabel="Ajouter une personne à charge"
            id={id}
            dependents={dependents}
            familyEvents={familyEvents}
            change={change}
        />
    </FormGroup>
);

Dependents.propTypes = {
    id: PropTypes.string,
    dependents: PropTypes.arrayOf(PropTypes.shape({})),
    familyEvents: PropTypes.arrayOf(PropTypes.shape({})),
    change: PropTypes.func,
};

export default flowRight([
    formValues({
        id: 'id',
        dependents: 'dependents',
        familyEvents: 'familyEvents',
    }),
])(Dependents);
