import PropTypes from 'prop-types';
import React from 'react';
import { AmountItem, PercentageItem } from './index';

const BodyOptionalPart = ({ availableIncomesPopover, remainingIncomesPopover, rip: { financialCapacity, financialMaximumSupply }, summary }) => (
    <div>
        <dl className="important">
            <PercentageItem title="Taux d'endettement" value={summary.debtRatio} important />
        </dl>
        <dl>
            <AmountItem title={remainingIncomesPopover || 'Reste à vivre mensuel'} value={summary.remainingIncomes} />
            <AmountItem title={availableIncomesPopover || 'Disponibilité mensuelle'} value={summary.availableIncomes} />
            <AmountItem title="Épargne projet" value={financialCapacity ? financialMaximumSupply : 0} />
        </dl>
    </div>
);

BodyOptionalPart.propTypes = {
    availableIncomesPopover: PropTypes.node,
    remainingIncomesPopover: PropTypes.node,
    rip: PropTypes.shape({
        financialCapacity: PropTypes.bool,
        financialMaximumSupply: PropTypes.number,
    }),
    summary: PropTypes.shape({
        availableIncomes: PropTypes.number.isRequired,
        debtRatio: PropTypes.number.isRequired,
        remainingIncomes: PropTypes.number.isRequired,
    }).isRequired,
};

BodyOptionalPart.defaultProps = {
    availableIncomesPopover: null,
    rip: {
        financialCapacity: null,
        financialMaximumSupply: 0,
    },
    remainingIncomesPopover: null,
};

export default BodyOptionalPart;
