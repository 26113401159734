import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import WizardItem from 'common/components/rip/InvestorProfile/WizardItem';

import './ProfileChoice.scss';

const ProfileChoice = ({ name, profileKey: value, disabled, icon, label, active, ...props }) => {
    const id = `${name}-${value}`;

    return (
        <label htmlFor={id} className={classNames('investor-profile-radio', { disabled })}>
            {!disabled ? (<input
                id={id}
                type="radio"
                value={value}
                name={name}
                {...props}
                checked={active}
            />) : null}
            <WizardItem icon={icon} label={label} active={active} />
        </label>
    );
};

ProfileChoice.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    profileKey: PropTypes.string.isRequired,
};

export default ProfileChoice;
