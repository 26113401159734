import { join, indexOf, toArray, isArray } from 'lodash/fp';

const toString = (value) => (isArray(value) ? join('', value) : value);

const writeZeros = (n) => (new Array(n + 1)).join('0');

// moveDecimal property on an extend String type to remember if we've to force the fraction or not
class DecimalString extends String {
    constructor(str, originalValue = null) {
        // call the String constructor
        super(str);
        // then keep the information about the original value
        this.originalValue = originalValue;
    }
}

const formatNumber = (isNegative) => (int, frac = null) => {
    // stringify our int part
    let formattedInt = toString(int);

    // remove zeros
    while (formattedInt.length > 1 && '0' === formattedInt[0]) {
        formattedInt = formattedInt.substr(1);
    }

    // join the whole thing together
    return new DecimalString(toString([
        isNegative ? '-' : '',
        formattedInt,
        frac && frac.length ? '.' : '',
        toString(frac),
    ]));
};

const moveDecimal = (value, offset) => {
    if (!value || !value.toString || 0 === offset) {
        // we need something valid to work
        return value;
    }

    // turn our value into a string then an array
    const str = toArray(value.toString());
    const isNegative = '-' === str[0];

    // custom inner function to format the output
    const format = formatNumber(isNegative);

    if (isNegative) {
        // remove the negation from our array
        str.splice(0, 1);
    }

    // get the original index
    let index = indexOf('.', str);

    if (-1 === index) {
        index = indexOf(',', value);
    }

    if (-1 === index) {
        // put it at the very end
        // and move it
        index = (str.length - 1) + offset;
    } else {
        // remove it from the array
        str.splice(index, 1);
        // and move it
        index = (index - 1) + offset;
    }

    let result;

    if (index >= 0) {
        const missingZeros = -Math.min(0, str.length - index - 1);

        result = format([...str.splice(0, index + 1), ...writeZeros(missingZeros)], str);
    } else {
        // we've to add zero to our array
        result = format(0, [...writeZeros(-index - 1), ...str]);
    }

    // we must remember the original value (as a native string)
    result.originalValue = value.toString();

    return result;
};

export default moveDecimal;
