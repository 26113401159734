import { checkRequiredField } from '../lib';

const validateDismembermentShares = (prefix) => [5, 7, 10, 15].reduce((acc, year) => {
    const usufructPath = `${prefix}share.${year}.usufruct`;
    const bareOwnershipPath = `${prefix}share.${year}.bareOwnership`;

    return [
        ...acc,
        checkRequiredField(usufructPath),
        checkRequiredField(bareOwnershipPath),
    ];
}, []);

export const buildScpiValidation = (type, prefix = '', distribution = []) => [
    checkRequiredField(`${prefix}value`),
    checkRequiredField(`${prefix}minimumParts`),
    checkRequiredField(`${prefix}beforeDividendsDuration`),
    checkRequiredField(`${prefix}fees`),
    checkRequiredField(`${prefix}simulationRate`),
    checkRequiredField(`${prefix}description`),
    ...distribution.reduce((distributionValidators, _, index) => [
        ...distributionValidators,
        checkRequiredField(`${prefix}distribution.${index}.name`),
        checkRequiredField(`${prefix}distribution.${index}.value`),
    ], []),
    ...'dismemberedScpi' === type ? validateDismembermentShares(prefix) : [],
];

export const scpiValidators = (product, distribution) => buildScpiValidation('scpi', `simulation.scpi.${product}.`, distribution);
export const dismemberedScpiValidators = (product, distribution) => buildScpiValidation('dismemberedScpi', `simulation.dismemberedScpi.${product}.`, distribution);

const validators = (settings) => {
    let scpisValidators = [];

    if (settings.simulation && settings.simulation.scpi) {
        const scpiProducts = Object.entries(settings.simulation.scpi);
        const scpiProductsValidators = scpiProducts
            .reduce((acc, [scpiProduct, { distribution }]) => [...acc, ...scpiValidators(scpiProduct, distribution)], []);

        scpisValidators = [...scpisValidators, ...scpiProductsValidators];
    }

    if (settings.simulation && settings.simulation.dismemberedScpi) {
        const dismemberedScpiProducts = Object.entries(settings.simulation.dismemberedScpi);
        const dismemberedScpiProductsValidators = dismemberedScpiProducts
            .reduce((acc, [dismemberedScpiProduct, { distribution }]) => [...acc, ...dismemberedScpiValidators(dismemberedScpiProduct, distribution)], []);

        scpisValidators = [...scpisValidators, ...dismemberedScpiProductsValidators];
    }

    return scpisValidators;
};

export default validators;
