import React, { useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';
import { camelCase } from 'lodash/fp';
import { reduxForm, Form, SubmissionError, Field } from 'redux-form';
import Checkbox from 'components/form/Checkbox';
import { POST } from 'utils/httpMethods';
import Title from 'components/Title';
import { GridLayout } from 'components/form/layout';
import { IntegerField, PercentageFloatField, TextField, MarkdownField } from 'components/form/field';
import validate from 'common/formValidation/lib';
import { buildLifeInsuranceTypesValidation } from 'common/formValidation/settings/lifeInsuranceSettingsValidation';
import SupportRepartition from './SupportRepartition/SupportRepartition';

const CreateLifeInsuranceType = ({ error, baseProducts, handleSubmit, hideForm, hiddenAll, showForm }) => {
    const [hidden, setHidden] = useState(true);
    const showNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(false);
        showForm();
    }, [setHidden]);
    const hideNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(true);
        hideForm();
    }, [setHidden]);

    return (
        <div className={classNames('new-simu', { hidden: hiddenAll })}>
            {hidden ? (
                <button onClick={showNewForm}>Nouvelle simulation AV</button>
            ) : (
                <button onClick={hideNewForm} className="cancel">Annuler</button>
            )}
            <div className={classNames('new-simulation-form', { hidden })}>
                <h1 className="text-center">Paramètres de la nouvelle simulation AV</h1>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <GridLayout className="double condensed full-width">
                        <TextField
                            name="name"
                            title="Nom"
                        />
                        <IntegerField
                            name="accountAvailability"
                            title="Durée de disponibilité"
                        />
                        <PercentageFloatField
                            name="fees.min"
                            title="Frais d'entrée min"
                        />
                        <PercentageFloatField
                            name="fees.max"
                            title="Frais d'entrée max"
                        />
                        <IntegerField
                            name="miniInitialFunding"
                            title="Minimum versement initial"
                        />
                        <IntegerField
                            name="miniScheduledPaymentAmount"
                            title="Minimum versement programmé"
                        />
                        {['P', 'E', 'D'].map((profile) => (
                            <Fragment key={profile}>
                                <SupportRepartition
                                    key={`collection-${profile}`}
                                    profile={profile}
                                    baseProducts={baseProducts}
                                />
                                <IntegerField
                                    name={`profiles.${profile}.minimumDuration`}
                                    title="Durée minimum"
                                />
                            </Fragment>
                        ))}
                        <div className="double">
                            <Title><b>Description</b></Title>
                            <MarkdownField
                                name="presentation"
                            />
                        </div>
                    </GridLayout>
                    <Title><b>Souscription</b></Title>
                    <Field
                        title={<span>HUB Primonial</span>}
                        component={Checkbox}
                        name="subscription"
                    />
                    <div className="submit">
                        <button type="submit">Créer</button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

CreateLifeInsuranceType.propTypes = {
    baseProducts: PropTypes.shape({}),
    handleSubmit: PropTypes.func,
    hiddenAll: PropTypes.bool,
    showForm: PropTypes.func,
    hideForm: PropTypes.func,
    error: PropTypes.string,
};

export default reduxForm({
    form: 'simulation-lifeInsurance-create',
    onSubmit: async (values) => {
        const id = `simulation.lifeInsurance.types.${camelCase(values.name)}`;

        try {
            await POST('/api/settings/lifeInsurance', { body: { id, value: values } });
        } catch (error) {
            const data = await error.response.json();

            throw new SubmissionError({ _error: 'La Création à échoué !', ...data });
        }
    },
    onSubmitSuccess: () => {
        document.location.href = '/admin/settings/simulation';
    },
    validate: validate(buildLifeInsuranceTypesValidation()),
})(CreateLifeInsuranceType);
