import { find } from 'lodash/fp';

const getItem = (index, items) => {
    // If there is an item with this index as id, return it.
    const item = find(['id', index], items);

    if (item) {
        return item;
    }

    // parse it because so far we've a string
    const parsed = parseInt(index, 10);

    if (!items || Number.isNaN(parsed) || parsed < 0 || parsed >= items.length) {
        // no need to go further
        // somehow we can't make it
        return null;
    }

    return items[parsed];
};

export default getItem;
