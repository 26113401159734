import Capital from './Capital';
import Other from './Other';
import Retirement from './Retirement';
import Taxation from './Taxation';
import Transmission from './Transmission';
import Wealth from './Wealth';

const types = {
    retirement: {
        component: Retirement,
        title: 'Préparer sa retraite',
        unique: true,
    },
    capital: {
        component: Capital,
        title: 'Se créer et/ou valoriser un capital',
        unique: true,
    },
    transmission: {
        component: Transmission,
        icon: 'sitemap',
        title: 'Protéger vos proches',
        unique: true,
    },
    taxation: {
        component: Taxation,
        icon: 'university',
        title: 'Agir sur sa fiscalité',
        unique: true,
    },
    wealth: {
        component: Wealth,
        title: 'Se créer un patrimoine',
        unique: true,
    },
    more_income: {
        component: Other,
        title: 'Percevoir des revenus complémentaires',
        unique: true,
    },
    diversify_estate: {
        component: Other,
        title: 'Diversifier son patrimoine',
        unique: true,
    },
    prepare_dependance: {
        component: Other,
        title: 'Préparer la dépendance',
        unique: true,
    },
    transmit_patrimony: {
        component: Other,
        title: 'Transmettre mon patrimoine',
        unique: true,
    },
    other: {
        component: Other,
        title: 'Autre',
        unique: true,
    },
};

export default types;
