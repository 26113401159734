import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { get } from 'lodash/fp';
import { formatAmount } from 'common/utils/format';
import { DisabledField } from 'components/form/field';
import useSettings from 'components/sessions/useSettings';

const typeToTitle = {
    credit: 'Soit un crédit d\'impôt maximal de:',
    reduction: 'Soit une réduction maximale de:',
};

const DisabledTaxField = ({
    data,
    computeAmount,
    name,
    type = 'reduction',
    info,
}) => {
    // Get settings
    const settings = useSettings();
    // As computeAmount may returns an object, we might want to get only one key
    const value = computeAmount(data, settings);

    const formattedValue = formatAmount(get('computedAmount', value) || value);

    return (
        <Fragment>
            <DisabledField
                title={typeToTitle[type]}
                name={`${name}-estimate`}
                value={formattedValue}
                info={info}
            />
        </Fragment>
    );
};

DisabledTaxField.propTypes = {
    data: PropTypes.shape({}),
    computeAmount: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['reduction', 'credit']).isRequired,
    info: PropTypes.string,
};

DisabledTaxField.defaultProps = {
    info: null,
};

export default formValues(({ name }) => ({ data: name }))(DisabledTaxField);
