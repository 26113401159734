import React from 'react';
import { get } from 'lodash/fp';
import { withProps } from 'recompose';
import HeaderInner from '../../Header';

const Header = withProps({
    product: 'Assurance Vie',
    withApprovalWarnings: false,
    // eslint-disable-next-line react/prop-types
    textRenderer: ({ session }) => {
        const displayName = get('settings.displayName', session);

        return (
            <em>
                Cet outil est mis à la disposition de l’utilisateur professionnel afin de proposer à son client un
                produit d’assurance-vie. Il s’engage à remettre à son client la proposition d’assurance-vie générée
                suite à cette simulation. Cette proposition comprendra les différentes hypothèses de rendement, les taux
                des supports et les informations concernant la fiscalité. Cette simulation n’est donnée qu’à titre
                indicatif et n’a aucune valeur contractuelle. Les éléments indiqués ne peuvent engager la responsabilité
                de la société {displayName}.
            </em>
        );
    },
})(HeaderInner);

export default Header;
