import { get, set } from 'lodash/fp';
import validate from 'common/formValidation/lib';
import { verifyPrerequisites, prerequisitesNames } from './index';

const checkPrerequisites = (
    errors,
    {
        data = {},
        simulation: { data: { contractType } },
    },
) => {
    if (!verifyPrerequisites(get('verify', data), contractType)) {
        const names = prerequisitesNames(contractType);

        return set(
            ['data', 'verify', names[names.length - 1]],
            'L\'ensemble des conditions requises pour procéder à une signature électronique ne sont pas remplies. Nous vous invitons à souscrire le contrat en version papier.',
            errors,
        );
    }

    return errors;
};

const formValidate = validate([
    checkPrerequisites,
]);

export default formValidate;
