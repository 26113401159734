import Router from 'components/routes/routers/simulation/dismemberedScpi/DismemberedScpiSimulationRouter';
import Creation, { formValidate, initialValues } from './Creation';

const dismemberedScpi = {
    // human readable title for this kind of product
    label: 'Nue-propriété de SCPI',
    // it may have additional fields (the RIP is given as property)
    formComponent: Creation,
    // it may have to validate data (must be a validator signature)
    formValidate: (errors, values, props) => formValidate(values, props, errors),
    // it may have initial values
    initialValues,
    // and an entry point for the form
    router: Router,
    entryPoint: 'products',
};

export default dismemberedScpi;
