import { flatten, get, getOr, set, filter, has, isEmpty } from 'lodash/fp';
import validate, { checkRequiredField } from 'common/formValidation/lib';
import getSettings from 'common/utils/settings';
import computeSummary from 'common/model/summary';
import computeInvestment from 'common/model/simulation/dismemberedScpi/computeInvestment';

const checkChangingProfileLowInvestment = (mode) => (
    errors,
    { data: { products, duration } = {} },
    { rip, session },
) => {
    const investment = computeInvestment(duration)(products);
    const { assets } = computeSummary(rip);

    const isCaseManager = session.permissions.isCaseManager(rip.entity);

    if (investment > 0.2 * assets
        && ('error' === mode ? !isCaseManager : isCaseManager)) {
        return set(
            `_${mode}`,
            'Simulation impossible car la souscription représente plus de 20% du patrimoine global du client',
            errors,
        );
    }

    return errors;
};

const formValidate = validate((values) => {
    const products = get('data.products', values);

    return [
        checkRequiredField('data.products', true),
        checkRequiredField('data.subscriptionDate'),
        (errors) => {
            const duration = get('data.duration', values);

            if (!duration || ![5, 7, 10, 15, 20].includes(parseInt(duration, 10))) {
                return set('data.duration', 'Durée invalide', errors);
            }

            return errors;
        },
        (errors) => {
            const duration = get('data.duration', values);
            const dismemberedScpis = getSettings('simulation.dismemberedScpi');

            const wrongScpis = filter(({ type }) => !has([type, 'share', duration], dismemberedScpis), products);

            if (!isEmpty(wrongScpis)) {
                return set('data.duration', 'Une SCPI est incompatible avec la durée sélectionnée', errors);
            }

            return errors;
        },
        ...(products ? flatten(products.map(({ type }, index) => [
            (errors) => {
                const duration = get('data.duration', values);
                const parts = get(`data.products[${index}].parts`, values);

                const scpiType = getSettings(['simulation', 'dismemberedScpi', type]);

                if (scpiType.minimumInvestment && scpiType.minimumInvestment > computeInvestment(duration)([{
                    type,
                    parts,
                }])) {
                    return set(
                        `data.products[${index}].parts`,
                        `Un investissement d'un minimum de ${scpiType.minimumInvestment}€ est requis`,
                        errors,
                    );
                } else if (!scpiType.minimumInvestment && (!parts || parts < scpiType.minimumParts)) {
                    return set(
                        `data.products[${index}].parts`,
                        `Un minimum de ${scpiType.minimumParts} parts est requis`,
                        errors,
                    );
                }

                return errors;
            },
            checkRequiredField(`data.products[${index}].revaluation`),
        ])) : []),
        checkChangingProfileLowInvestment('error'),
        (errors) => {
            const chargeableDeficit = getOr(0, 'data.chargeableDeficit', values);
            const landRentalResult = getOr(0, 'data.landRentalResult', values);
            if (landRentalResult >= 0 && chargeableDeficit < 0) {
                return set('data.chargeableDeficit', 'Le déficit imputable doit être nul en cas de bénéfice foncier', errors);
            }

            if (landRentalResult < 0 && (
                chargeableDeficit < -10700 ||
                chargeableDeficit < landRentalResult ||
                chargeableDeficit > 0)) {
                return set('data.chargeableDeficit', 'Le déficit imputable doit être négatif, supérieur au résultat foncier et supérieur à -10 700€', errors);
            }

            return errors;
        },
    ];
});

export const formWarn = validate([
    checkChangingProfileLowInvestment('warning'),
]);

export default formValidate;
