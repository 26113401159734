import { sum } from 'lodash/fp';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { AmountItem, PercentageItem } from 'common/components/summary';
import Title from 'components/Title';
import { getMaxAmountLoanable } from './computeLoan';

import './LoanResults.scss';

const disponibilitiesBound = 245;
const outstandingBeforeBound = 95400;
const outstandingAfterBound = 0;
const debtRatioWithoutDeltaBound = 0.4;
/*
const debtRatioWithDeltaBound = 0.3499;
*/
const savingsLowerBound = 3000;
const savingsUpperBound = 10000;
const savingsAmountPart = 0.06;

const getOutstandingAfterScore = (outstandingAfter) => {
    if (outstandingAfter >= outstandingAfterBound) {
        return 20;
    }

    return -30;
};
const getDebtRatioWithoutDeltaScore = (debtRatioWithoutDelta) => {
    if (debtRatioWithoutDelta <= debtRatioWithoutDeltaBound) {
        return 20;
    }

    return -100;
};

/*
const getDebtRatioWithDeltaScore = (debtRatioWithDelta) => {
    if (debtRatioWithDelta <= debtRatioWithDeltaBound) {
        return 20;
    }

    return -30;
};
*/

const getSavingsScore = (savings, amount) => {
    if (savings < savingsLowerBound) {
        return -100;
    }

    if (savings < savingsUpperBound || savings <= savingsAmountPart * amount) {
        return -30;
    }

    return 20;
};

/*
const getScore = ({
    savings,
    debtRatioWithoutDelta,
    debtRatioWithDelta,
    outstandingAfter,
    amount,
}) => (
    getOutstandingAfterScore(outstandingAfter) +
    getDebtRatioWithoutDeltaScore(debtRatioWithoutDelta) +
    getDebtRatioWithDeltaScore(debtRatioWithDelta) +
    getSavingsScore(savings, amount)
);

const getTextOverview = (score) => {
    if (score > 35) {
        return 'Très bon paramétrage pour ce profil !';
    }
    if (score > -50) {
        return 'Le paramétrage semble ok mais approfondissement nécessaire';
    }

    return 'Désolé paramétrage incompatible';
};
*/

const getTextOverview = (debtRatioWithoutDelta, outstandingAfter) => {
    let information = 'Désolé paramétrage incompatible';
    if ((debtRatioWithoutDelta <= 0.35) && (outstandingAfter > 0)) {
        information = 'Très bon paramétrage pour ce profil !';
    }
    if (((debtRatioWithoutDelta > 0.35) && (debtRatioWithoutDelta < 0.4)) || ((outstandingAfter > -40000) && (outstandingAfter < 0))) {
        information = 'Endettement supérieur au maximum autorisé mais à approfondir.';
    }
    if (((debtRatioWithoutDelta > 0.35) && (debtRatioWithoutDelta < 0.4)) && ((outstandingAfter > -40000) && (outstandingAfter < 0))) {
        information = 'Endettement supérieur au maximum autorisé mais à approfondir.';
    }

    if ((debtRatioWithoutDelta > 0.4) || (outstandingAfter < -40000)) {
        information = 'Désolé paramétrage incompatible';
    }

    return information;
};

const LoanResult = ({
    maxAmountLoanable,
    loanAmount = 0,
    monthlyPayment = 0,
    monthlyLoan = 0,
    rip: {
        investments,
    },
    summary: {
        debtRatioNum,
        debtRatioDenom,
        availableIncomes,
        outstandingCapital,
        rawRentalIncomes,
        rawProfessionalIncomes,
        savings,
    },
}) => {
    let investissementsTotal = 0;
    if (investments) {
        const investissementAvecCapital = investments.filter((x) => x.capital);
        const investissementAvecValeur = investments.filter((x) => x.value);

        investissementsTotal =
        sum(investissementAvecCapital.map((investissement) => investissement.capital)) +
        sum(investissementAvecValeur.map((investissement) => investissement.value));
    }
    const outstandingBefore = maxAmountLoanable;
    const outstandingAfter = getMaxAmountLoanable({
        rawRentalIncomes,
        rawProfessionalIncomes: rawProfessionalIncomes + (12 * monthlyLoan * 0.9),
        outstandingCapital: outstandingCapital + loanAmount,
    });
    const debtRatioWithoutDelta = (debtRatioNum + monthlyPayment) / (debtRatioDenom + (0.75 * monthlyLoan));
    /*
    const delta = (monthlyLoan * 0.85) - monthlyPayment;
    const debtRatioWithDelta = (debtRatioNum + (delta < 0 ? -delta : 0)) / (debtRatioDenom + (delta >= 0 ? delta : 0));
    */

    /*
    const score = getScore({
        savings,
        debtRatioWithoutDelta,
        debtRatioWithDelta,
        outstandingAfter,
        amount: loanAmount,
    });
    */

    return (
        <Fragment>
            <Title><b>Disponibilités avant opération</b></Title>
            <div className="summary-body col3">
                <dl>
                    <AmountItem
                        negative={availableIncomes < disponibilitiesBound}
                        title="Disponibilités"
                        value={availableIncomes}
                    />
                    <AmountItem
                        negative={outstandingBefore < outstandingBeforeBound}
                        title="Encours disponible"
                        value={outstandingBefore}
                    />
                    <AmountItem
                        negative={getSavingsScore(savings + investissementsTotal, loanAmount) < 0}
                        title="Épargne"
                        value={investissementsTotal}
                    />
                </dl>
            </div>
            <hr />
            <Title><b>Résultats avec nouvelle opération</b></Title>
            <div className="summary-body col3">
                <dl>
                    <PercentageItem
                        negative={getDebtRatioWithoutDeltaScore(debtRatioWithoutDelta) < 0}
                        title="Taux d'endettement"
                        value={debtRatioWithoutDelta}
                    />
                    <AmountItem
                        negative={getOutstandingAfterScore(outstandingAfter) < 0}
                        title="Encours restant"
                        value={outstandingAfter}
                    />
                </dl>
            </div>
            <Title><b>Synthèse</b></Title>
            <div className="loan-simulator-overview">
                <p>{getTextOverview(debtRatioWithoutDelta, outstandingAfter)}</p>
            </div>
        </Fragment>
    );
};

LoanResult.propTypes = {
    maxAmountLoanable: PropTypes.number.isRequired,
    loanAmount: PropTypes.number,
    monthlyLoan: PropTypes.number,
    monthlyPayment: PropTypes.number,
    rip: PropTypes.shape({
        investments: PropTypes.shape({
            filter: PropTypes.func,
        }),
    }).isRequired,
    summary: PropTypes.shape({
        debtRatioNum: PropTypes.number,
        debtRatioDenom: PropTypes.number,
        availableIncomes: PropTypes.number,
        outstandingCapital: PropTypes.number,
        rawIncomes: PropTypes.number,
        rawRentalIncomes: PropTypes.number,
        rawProfessionalIncomes: PropTypes.number,
        savings: PropTypes.number,
    }).isRequired,
};

export default formValues({
    loanAmount: 'loanAmount',
    loanDuration: 'loanDuration',
    monthlyPayment: 'monthlyPayment',
    monthlyLoan: 'monthlyLoan',
})(LoanResult);
