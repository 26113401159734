import { get } from 'lodash/fp';
import validate, {
    checkChoice,
    checkRequiredIntegerField,
    checkRequiredField,
    checkRequiredEditableField,
} from 'common/formValidation/lib';
import { fundingTypes, handlingsOfBrokerageFees, handlingsOfLeapInterests, deferralTypes } from 'common/choices';

const formValidate = validate((values) => {
    if (!get('needFunding', values)) {
        // if there's not need of funding
        // so we have nothing to check
        return [];
    }

    const fundingType = get('fundingType', values);

    return [
        // LoanForm
        checkRequiredField('fundingType'),
        checkChoice('fundingType', fundingTypes),
        checkRequiredField('fundingADI'),
        checkRequiredField('fundingRate'),
        checkRequiredIntegerField('fundingDuration'),
        // DetailForm
        checkRequiredField('data.handlingOfFraisCourtage'),
        checkChoice('data.handlingOfFraisCourtage', handlingsOfBrokerageFees),
        checkRequiredIntegerField('data.fraisCourtage'),
        checkRequiredField('data.handlingOfBankApplicationFees'),
        checkChoice('data.handlingOfBankApplicationFees', handlingsOfBrokerageFees),
        checkRequiredIntegerField('data.bankApplicationFees'),
        checkRequiredIntegerField('handlingOfBrokerageFees'),
        checkChoice('handlingOfBrokerageFees', handlingsOfBrokerageFees),
        checkRequiredIntegerField('brokerageFees'),
        ...('depreciable' === fundingType ? [
            checkRequiredField('deferralType'),
            checkChoice('deferralType', deferralTypes),
        ] : []),
        checkRequiredField('handlingOfLeapInterests'),
        checkChoice('handlingOfLeapInterests', handlingsOfLeapInterests),
        checkRequiredEditableField('editableLeapInterestsAmount', 'leapInterestsAmount'),
        // InfineForm
        ...('in-fine' === fundingType ? [
            checkRequiredField('collateralRate'),
            checkRequiredIntegerField('collateralInitialContribution'),
            checkRequiredIntegerField('collateralMonthlyContribution'),
        ] : []),
    ];
});

export default formValidate;
