import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { IntegerField } from 'components/form/field';

const Deductions = ({ name }) => (
    <Fragment>
        <Title>Pension alimentaire - Plafonds</Title>
        <GridLayout className="double condensed full-width">
            <IntegerField
                name={`${name}.alimonyCeils.sameRoof`}
                title="Enfant sous le même toit (€)"
            />
            <IntegerField
                name={`${name}.alimonyCeils.default`}
                title="Par défault (€)"
            />
        </GridLayout>
    </Fragment>
);

Deductions.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Deductions;
