import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { withPropsOnChange } from 'recompose';
import computeSummary from 'common/model/summary';
import { formatAmount, formatBoolean, formatPercentage } from 'common/utils/format';
import { WizardLayout } from 'components/layouts';
import Group from 'components/form/Group';
import Title from 'components/Title';
import FormButtons from 'components/FormButtons';
import simulationLoader from '../../simulationLoader';
import withBeneficiaryRip from '../../withBeneficiaryRip';
import Header from './Header';
import wizard from './wizardData';

const OverviewInner = ({
    navigate,
    params: { id, simulationId },
    summary,
}) => {
    const handleSubmit = (event) => {
        // prevent the browser to reload the document
        event.preventDefault();
        // redirect to the next step
        navigate(`/rip/${id}/simulations/${simulationId}/products`);
    };

    return (
        <WizardLayout className="simulation-form simulation-overview" hasFooter wizard={wizard}>
            <form onSubmit={handleSubmit} id="main-form">
                <div>
                    <div className="simulation-form-inner">
                        <div className="container">
                            <Header />
                            <Group>
                                <Title><b>Résumé</b> des informations du RIP</Title>
                                <table className="table table-striped overview">
                                    <tbody>
                                        <tr>
                                            <td>TMI</td>
                                            <td>{formatPercentage(summary.tmi)}</td>
                                        </tr>
                                        <tr>
                                            <td>Régime micro</td>
                                            <td>{formatBoolean('micro' === summary.landRentalRegime)}</td>
                                        </tr>
                                        <tr>
                                            <td>Résultat foncier</td>
                                            <td>{formatAmount(summary.landRentalResults)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link className="rip-edit-link" to={`/rip/${id}/etat-civil`}>
                                    <p>Editer le rip</p>
                                </Link>
                            </Group>
                        </div>
                    </div>
                    <FormButtons
                        back={`/rip/${id}/simulations/`}
                        list={`/rip/${id}/simulations/`}
                    />
                </div>
            </form>
        </WizardLayout>
    );
};

OverviewInner.propTypes = {
    navigate: PropTypes.shape({}).isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string,
    }).isRequired,
    summary: PropTypes.shape({
        tmi: PropTypes.number,
        landRentalRegime: PropTypes.number,
        landRentalResults: PropTypes.number,
    }).isRequired,
};

const Overview = flowRight([
    simulationLoader,
    withBeneficiaryRip,
    withPropsOnChange(
        ['rip'],
        ({ rip }) => ({
            summary: computeSummary(rip, null, true),
        }),
    ),
])(OverviewInner);

export default Overview;
