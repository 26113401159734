import React from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { formValues } from 'redux-form';
import { set } from 'lodash/fp';
import FormGroup from '../../form/Group';
import TypedBlockCollectionItem from '../../form/TypedBlockCollectionItem';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import FieldInfo from '../../form/FieldInfo';
import Salary from './Salary';
import Profit from './Profit';
import LandRentalIncome from './LandRentalIncome';
import FurnishedRentalIncome from './FurnishedRentalIncome';
import Dividends from './Dividends';
import Pension from './Pension';
import OtherIncome from './OtherIncome';
import TaxExemptedIncome from './TaxExemptedIncome';
import ChildrenIncome from './ChildrenIncome';
import agriculture from '../../../images/pictos/agriculture.svg';
import patrimoineLocatif from '../../../images/pictos/patrimoine-locatif.svg';
import locatifMeuble from '../../../images/pictos/locatif-meuble.svg';
import dividendes from '../../../images/pictos/dividendes.svg';
import circle from '../../../images/pictos/circle.svg';
import retraite from '../../../images/pictos/retraite.svg';

const types = {
    salary: {
        component: Salary,
        icon: 'money',
        title: 'Salaire',
        defaultValues: {
            incomeType: 'monthly',
            realExpenses: false,
        },
    },
    BNC: {
        component: withProps({ type: 'BNC' })(Profit),
        icon: 'stethoscope',
        title: 'BNC',
        defaultValues: {
            managementCenter: true,
        },
    },
    BIC: {
        component: withProps({ type: 'BIC' })(Profit),
        icon: 'shopping-basket',
        title: 'BIC',
        defaultValues: {
            managementCenter: true,
        },
    },
    BA: {
        component: withProps({ type: 'BA' })(Profit),
        path: agriculture,
        title: 'BA',
        defaultValues: {
            managementCenter: true,
        },
    },
    landRentalIncome: {
        component: LandRentalIncome,
        path: patrimoineLocatif,
        title: 'Locatifs foncier',
    },
    furnishedRentalIncome: {
        component: FurnishedRentalIncome,
        path: locatifMeuble,
        title: 'Locatifs meublé',
    },
    dividends: {
        component: Dividends,
        path: dividendes,
        title: 'Dividendes',
    },
    pension: {
        component: Pension,
        path: retraite,
        title: 'Pension',
    },
    other: {
        component: OtherIncome,
        path: circle,
        title: 'Autres',
    },
    exemptedIncome: {
        component: TaxExemptedIncome,
        icon: 'handshake-o',
        title: 'Revenus exonérés',
    },
    childrenIncome: {
        component: ChildrenIncome,
        icon: 'handshake-o',
        title: 'Salaire des enfants à charge',
    },
};

const TaxIncomesList = ({ children }) => (
    <FormGroup className="condensed">
        <Title>
            Quels sont <b>vos revenus&nbsp;?</b>
            <FieldInfo id="incomesInfo">
                Ne pas oublier les revenus mobiliers, à placer dans «&nbsp;autres&nbsp;»
            </FieldInfo>
        </Title>
        <TypedCollection
            name="incomes"
            types={set('childrenIncome.shouldDisplay', children && children.length > 0, types)}
            textOnly
            itemComponent={TypedBlockCollectionItem}
        />
    </FormGroup>
);

TaxIncomesList.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({})),
};

export default formValues('children')(TaxIncomesList);
