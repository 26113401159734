import React, { Fragment } from 'react';
import { Panel, Well } from 'react-bootstrap';
import loop from './loop.png';
import condition from './condition.png';

export default function Documentation() {
    return (
        <Fragment>
            <Panel>
                <Panel.Heading>
                    <Panel.Title componentClass="h3">Dates</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    Pour obtenir la date du jour (au moment de la génération du document), il suffit d&apos;écrire :
                    <Well>
                        +++INS dateDuJour()+++
                    </Well>
                    Pour obtenir la date du jour + x jours, il suffit d&apos;écrire :
                    <Well>
                        +++INS dateDuJour(x)+++
                    </Well>
                </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>
                    <Panel.Title componentClass="h3">Boucles</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    Une boucle vous permet d&apos;itérer à travers une collection d&apos;éléments, et d&apos;en afficher
                    que les valeurs souhaitées.
                    <br />
                    Une liste d&apos;enfants :
                    <Well>
                        <img className="loop-img" src={loop} alt="exemple boucle" />
                    </Well>
                </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>
                    <Panel.Title componentClass="h3">Conditions</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    Une condition permet de n&apos;exécuter une action que si une certaine condition est remplie :
                    <Well>
                        <img className="condition-img" src={condition} alt="exemple condition" />
                    </Well>
                </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>
                    <Panel.Title componentClass="h3">Image</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    Les codes qui sont de la forme :<Well>+++IMAGE cocher(xxx.yyy.zzz)+++</Well>
                    permettent d’afficher une case, cochée ou non en fonction de la réponse remplie.
                </Panel.Body>
            </Panel>
        </Fragment>
    );
}
