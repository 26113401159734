import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import SlicesTable from './SlicesTable';

const Slices = ({ settings }) => (
    <Fragment>
        <Title>Tranches d&apos;imposition</Title>
        <GridLayout className="double condensed full-width">
            <SlicesTable
                title="Tranches générales"
                slices={settings.slices}
                name="summary.incomeTax.slices"
            />
            <SlicesTable
                title="Hauts revenus (célibataire)"
                slices={settings.highIncomesSlices.single}
                name="summary.incomeTax.highIncomesSlices.single"
            />
            <SlicesTable
                title="Hauts revenus (couple)"
                slices={settings.highIncomesSlices.couple}
                name="summary.incomeTax.highIncomesSlices.couple"
            />
        </GridLayout>
    </Fragment>
);

Slices.propTypes = {
    settings: PropTypes.shape({
        slices: PropTypes.string,
        highIncomesSlices: PropTypes.shape({
            single: PropTypes.string,
            couple: PropTypes.string,
        }),
    }),
};

export default Slices;
