import PropTypes from 'prop-types';
import React from 'react';
import ProfileDescription from 'common/components/rip/InvestorProfile/ProfileDescription';
import Title from 'components/Title';
import Group from 'components/form/Group';

const Profile = ({
    simulation: {
        data: {
            profile,
            contractType,
        },
    },
}) => (
    <Group>
        <Title><b>Votre profil investisseur</b></Title>
        <ProfileDescription
            investorProfile={profile}
            chartProps={{ profile, contractType }}
            noChart
        />
    </Group>
);

Profile.propTypes = {
    simulation: PropTypes.shape({
        data: PropTypes.shape({
            profile: PropTypes.shape({}),
            contractType: PropTypes.string,
        }),
    }).isRequired,
};

export default Profile;
