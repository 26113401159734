import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';

import './EchecModalPrisme.scss';
import Icon from '../common/components/Icon';
import xmark from '../images/pictos/xmark.svg';

const EchecModalPrisme = ({ close }) => (
    <div className="modal-container">
        <Icon icon="times" className="close-action" onClick={close} />
        <div className="echec-info-container">
            <img className="xmark" src={xmark} alt="xmark" />
            <p>
                Les informations du RIP n’ont pas pu être envoyées.
                <br />
                Vous pouvez réessayer ultérieurement
            </p>
        </div>
    </div>
);

EchecModalPrisme.propTypes = {
    close: PropTypes.func.isRequired,
};

export default compose(connect(null, (dispatch) => ({
    close: () => {
        dispatch(setModal(null));
    },
})))(EchecModalPrisme);
