import { useSession } from '../components/sessions';

const getPrecoChoiceParameters = () => {
    const session = useSession();
    const { needApprovalOnCIFDocument, needApprovalOnSimulations } = session;

    if (needApprovalOnCIFDocument && !needApprovalOnSimulations) {
        return 'cif';
    }

    if (needApprovalOnSimulations && !needApprovalOnCIFDocument) {
        return 'simulation';
    }

    return null;
};

export default getPrecoChoiceParameters;
