import PropTypes from 'prop-types';
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import Title from 'components/Title';
import Dropdown from 'components/Dropdown';
import { AmountItem } from 'common/components/summary';
import ResultBarChart from 'common/components/rip/simulators/LifeInsuranceResultBarChart';
import ResultTable from 'common/components/rip/simulators/LifeInsuranceResultTable';

import './Hypothesis.scss';
import reduceToTotals from '../../../../../common/model/simulation/common/reduceToTotals';

const bars = [
    { dataKey: 'paymentsCapital', name: 'Versements', fill: '#27cae6' },
    { dataKey: 'interestsCapital', name: 'Intérêts', fill: '#248fdf' },
];

const labelFormatter = (label) => `Année: ${label}`;

const formatter = (value) => `${value} €`;

const src = {
    fees: 0,
    payments: 0,
};

const calculTotaux = reduceToTotals(src);

const Hypothesis = ({
    title,
    active,
    data: {
        capital,
        rawPayments,
        simulation,
        totalBuyout,
    },
}) => {
    const montantTotal = calculTotaux(simulation);

    return (
        <Dropdown title={title} active={active}>
            <Title><b>Chiffres-clés</b></Title>
            <div className="summary-body col3">
                <dl>
                    <AmountItem title="Capital final" value={capital} />
                    <AmountItem title="Versements cumulés" value={rawPayments} />
                    <AmountItem title="Intérêts cumulés" value={capital - (montantTotal.payments - montantTotal.fees)} />
                </dl>
            </div>
            <Title><b>Tableau de synthèse</b></Title>
            <div className="table-responsive">
                <ResultTable
                    totalBuyout={totalBuyout}
                    simulation={simulation}
                    className="lifeInsurance-hypothesis-table table-hover"
                />
            </div>
            <Title>Votre <b>épargne</b> au fil des ans</Title>
            <ResponsiveContainer aspect={1.5} maxHeight={300}>
                <ResultBarChart
                    bars={bars}
                    barData={simulation}
                    labelFormatter={labelFormatter}
                    formatter={formatter}
                    margin={{ top: 25, left: 25, right: 15 }}
                />
            </ResponsiveContainer>
        </Dropdown>
    );
};

Hypothesis.propTypes = {
    title: PropTypes.string.isRequired,
    active: PropTypes.bool,
    data: PropTypes.shape({
        capital: PropTypes.number.isRequired,
        rawPayments: PropTypes.number.isRequired,
        simulation: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
        totalBuyout: PropTypes.shape({}).isRequired,
    }).isRequired,
};

Hypothesis.defaultProps = {
    active: false,
};

export default Hypothesis;
