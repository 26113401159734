import { get, debounce } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { searchList } from 'actions';
import Icon from 'common/components/Icon';

import './SearchField.scss';

class SearchField extends Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();
    }

    resetSearch() {
        this.props.onChange('');
        this.inputRef.current.value = '';
    }

    render() {
        const { onChange, value, placeholder } = this.props;

        return (
            <div className="layout-search-field">
                <input
                    placeholder={placeholder}
                    onChange={(event) => {
                        if (this.props.setMotRecherche) {
                            this.props.setMotRecherche(event.target.value);
                        }

                        return onChange(event.target.value);
                    }}
                    defaultValue={value}
                    ref={this.inputRef}
                />
                <Icon icon="search" />
            </div>
        );
    }
}

SearchField.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    setMotRecherche: PropTypes.func,
};

SearchField.defaultProps = {
    value: null,
    placeholder: 'Rechercher un client...',
};

export default connect(
    (state, { searchName }) => ({
        value: get(['lists', searchName.split(' '), 'search'], state),
    }),
    (dispatch, { searchName }) => ({
        onChange: debounce(600, (value) => dispatch(searchList(searchName, value))),
    }),
    null,
    { withRef: true },
)(SearchField);
