import classNames from 'classnames';
import { pick } from 'lodash/fp';
import React from 'react';
import { fieldPropTypes } from 'redux-form';

const TextArea = ({ input: { name, value, ...input }, meta, ...props }) => (
    <label htmlFor={name}>
        <textarea
            id={name}
            className={classNames('form-control', 'form-field-text-area', { filled: value })}
            name={name}
            value={value}
            {...pick(['placeholder'], props)}
            {...input}
        />
    </label>
);

TextArea.propTypes = {
    ...fieldPropTypes,
};

export default TextArea;
