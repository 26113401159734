import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Checkbox from 'components/form/Checkbox';
import Title from 'components/Title';
import PdfReader from './PdfReader';

class DocumentReader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { isRead: false, disabled: false };

        this.onRead = this.onRead.bind(this);
    }

    onRead() {
        this.setState({ isRead: true });
    }

    static getDerivedStateFromProps({ isChecked }, { disabled }) {
        return { disabled: disabled || isChecked };
    }

    render() {
        const { name, file, filename } = this.props;
        const { isRead, disabled } = this.state;

        const prefix = filename.toLowerCase().startsWith('document') ? '' : 'document ';

        return (
            <Fragment>
                <Title><b>{filename}</b></Title>
                <p className="text-help">
                    Votre client doit prendre connaissance du {prefix}<b>{filename}</b>, apr&eacute;s
                    l&apos;avoir fait d&eacute;filer dans son int&eacute;gralit&eacute;, vous devez le valider ensemble.
                </p>
                <PdfReader file={file} onRead={this.onRead} />
                <Field
                    title={<span>Je reconnais avoir pris connaissance du {prefix}<b>{filename}</b></span>}
                    component={Checkbox}
                    name={name}
                    disabled={!isRead || disabled}
                />
            </Fragment>
        );
    }
}

DocumentReader.propTypes = {
    // input name for redux field
    name: PropTypes.string.isRequired,
    // file/pdf to load
    file: PropTypes.string.isRequired,
    // file name (human readable)
    filename: PropTypes.string.isRequired,
    // is the file already read
    // eslint-disable-next-line react/no-unused-prop-types
    isChecked: PropTypes.bool,
};

DocumentReader.defaultProps = {
    isChecked: false,
};

export default DocumentReader;
