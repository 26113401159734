import { get } from 'lodash/fp';
import React from 'react';
import { Layout } from 'components/layouts';
import contractLoader from '../../contractLoader';
import Form from './Form';

import './Validation.scss';

const Validation = (props) => (
    <Layout hasFooter className="investorProfile-form-wrapper">
        {!get('contract.data.isApproved', props) ? <Form {...props} /> : (
            <div className="container margin-top-10">
                <div className="alert alert-warning">
                    <p className="text-center">
                        Souscription déjà validée
                    </p>
                </div>
            </div>
        )}
    </Layout>
);

export default contractLoader(Validation);
