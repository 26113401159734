import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import Title from 'components/Title';
import computeInvestment from 'common/model/simulation/scpi/computeInvestment';
import formatPercentage from 'common/utils/format/percentage';
import getSettings from 'common/utils/settings';
import colors from 'components/colors';

const chartSize = 350;

const formatLabel = ({ value }) => formatPercentage(value);

const chartData = (details) => details.map(({ type, parts }, index) => {
    const scpi = getSettings(['simulation', 'scpi', type]);

    return {
        name: scpi.name,
        value: (parts * scpi.value) / computeInvestment(details),
        fill: colors[index],
    };
});

const Allocations = ({
    data: { products },
}) => (
    <Fragment>
        <Title><b>Répartition de votre investissement</b></Title>
        <div className="investor-profile-resume">
            <div className="investor-profile-chart">
                <ResponsiveContainer height={chartSize} width="80%">
                    <PieChart height={chartSize} width={chartSize}>
                        <Pie
                            data={chartData(products)}
                            dataKey="value"
                            nameKey="name"
                            isAnimationActive={false}
                            label={formatLabel}
                            legendType="circle"
                        />
                        <Legend
                            layout="vertical"
                            verticalAlign="middle"
                            align="left"
                            wrapperStyle={null}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    </Fragment>
);

Allocations.propTypes = {
    data: PropTypes.shape({
        products: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            parts: PropTypes.number.isRequired,
            revaluation: PropTypes.string,
        })).isRequired,
    }).isRequired,
};

export default Allocations;
