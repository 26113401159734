import validate, { checkRequiredField, checkIsMobilePhoneNumber, checkIsEmail } from 'common/formValidation/lib';

const formValidate = validate([
    checkRequiredField('name'),
    checkRequiredField('data.firstName'),
    checkRequiredField('data.lastName'),
    checkRequiredField('data.mobile'),
    checkIsMobilePhoneNumber('data.mobile'),
    checkRequiredField('data.email'),
    checkIsEmail('data.email'),
]);

export default formValidate;
