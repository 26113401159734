import React from 'react';
import { IntegerField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import OwnerField from '../../field/Owner';
import FutureOwner from './FutureOwner';

const Tenant = () => (
    <HorizontalLayout>
        <OwnerField name="mainResidenceTenant" title="Locataire(s)" required />
        <IntegerField title="Montant du loyer (CC)" required name="mainResidenceRent" />
        <FutureOwner />
    </HorizontalLayout>
);

export default Tenant;
