import { getOr } from 'lodash/fp';

const choices = {
    optionOrOpcvm: { point: [3] },
    ventureCapital: { point: [4] },
    dutiesOpcvm: { point: [3] },
    realEstateProducts: { point: [3] },
};

const question = (point, investorProfile) => ('no' === investorProfile.sufferTradingLosses ? {} : getOr(null, point, choices));

export default question;
