import { withProps } from 'recompose';
import VerticalRadioButton from './VerticalRadioButton';

import './FlexVerticalRadioButton.scss';

const FlexVerticalRadioButton = withProps({
    flex: true,
})(VerticalRadioButton);

export default FlexVerticalRadioButton;
