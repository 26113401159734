import PropTypes from 'prop-types';
import React from 'react';
import DropDown from 'components/form/FieldDropDown';
import { FileField } from 'components/form/field';

const FreeClause = ({ uploader }) => (
    <DropDown title="Clause libre papier" name="files.freeClauseDocument">
        <FileField
            name="freeClauseDocument"
            uploader={uploader('freeClauseDocument')}
            uploadText="Télécharger votre clause libre papier"
            reduxField="files.freeClauseDocument"
        />
    </DropDown>
);

FreeClause.propTypes = {
    uploader: PropTypes.func.isRequired,
};

export default FreeClause;
