import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Title from '../Title';
import FormGroup from '../form/Group';
import withScroll from '../form/withScroll';
import GridRadioChoices from '../form/GridRadioChoices';

import './RadioQuestion.scss';

const RadioQuestionInner = ({ choices, name, required, title, helpText, helpTextColor, helpTextLeft, ...props }) => (
    <FormGroup>
        <Title>{title}</Title>
        {helpText ? (
            <p className={classNames('radio-question-help-text', helpTextColor ? 'colorText' : '', helpTextLeft ? 'displayLeft' : '')}>
                {helpText}
            </p>
        ) : null}
        <GridRadioChoices choices={choices} name={name} required={required} {...props} />
    </FormGroup>
);

RadioQuestionInner.propTypes = {
    choices: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    title: PropTypes.node.isRequired,
    helpText: PropTypes.string,
    helpTextColor: PropTypes.bool,
    helpTextLeft: PropTypes.bool,
};

RadioQuestionInner.defaultProps = {
    required: false,
    helpText: null,
    helpTextColor: false,
    helpTextLeft: false,
};

const RadioQuestion = withScroll(RadioQuestionInner);

export default RadioQuestion;
