import React from 'react';
import PropTypes from 'prop-types';
import { isBoolean } from 'lodash';

function Leaf({ label, value, path, onLeafClick, nodeIsArray, arrayPath }) {
    const handleClick = () => {
        const newLabel = label;

        if (nodeIsArray) {
            onLeafClick({
                type: 'LOOP',
                prefix: path,
                path: `${arrayPath}${arrayPath && '.'}${newLabel}`,
            });
        } else if (isBoolean(value)) {
            onLeafClick({
                type: 'IMAGE',
                path: `${path}${path && '.'}${newLabel}`,
            });
        } else {
            onLeafClick({
                type: 'INS',
                path: `${path}${path && '.'}${newLabel}`,
            });
        }
    };

    return (
        /* eslint-disable-next-line jsx-a11y/interactive-supports-focus */
        <div role="button" className="leaf" onClick={() => handleClick()} onKeyPress={() => handleClick()}>
            <strong>{label} :</strong>&nbsp;{isBoolean(value) ? value && 'X' : value}
        </div>
    );
}

Leaf.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    path: PropTypes.string.isRequired,
    onLeafClick: PropTypes.func.isRequired,
    nodeIsArray: PropTypes.bool.isRequired,
    arrayPath: PropTypes.string.isRequired,
};

export default Leaf;
