import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const Table = ({ children, wrapped }) => (
    <div className="relative-container">
        <div className={classNames({ container: !wrapped })}>
            <table className="table">
                {children}
            </table>
        </div>
    </div>
);

Table.propTypes = {
    children: PropTypes.node.isRequired,
    wrapped: PropTypes.bool,
};

export default Table;
