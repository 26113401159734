import React from 'react';
import ErrorMessage from 'components/ErrorMessage';

const NoRipMessage = () => (
    <ErrorMessage withoutIcon>
        Aucun document CIF n&apos;est en attente de validation selon vos critères
    </ErrorMessage>
);

export default NoRipMessage;
