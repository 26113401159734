import { flowRight } from 'lodash/fp';
import { branch, mapProps, renderComponent } from 'recompose';
import PdfReader from '../Documents/PdfReader';

export default flowRight([
    branch(({ filename }) => filename.toLowerCase().endsWith('pdf'), flowRight([
        mapProps(({ path }) => ({ file: path })),
        renderComponent(PdfReader),
    ])),
    mapProps(({ path }) => ({
        alt: 'justificatif',
        className: 'image-reader',
        src: path,
    })),
    renderComponent('img'),
])(null);
