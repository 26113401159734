import { flowRight } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lifecycle, branch, renderNothing } from 'recompose';
import { reduxForm } from 'redux-form';

import alertEmail from '../../../utils/alertEmail';
import { getIsValidator } from '../../../actions';
import dataProvider from '../../dataProvider';
import RipForm from '../Form';
import { YesNoField } from '../../form/field';
import { POST } from '../../../utils/httpMethods';
import Download from '../Download';
import Comment from './Comment';
import TargetField from './TargetField';
import Recommendations from './Recommendations';
import { withSession } from '../../sessions';
import withRouter from '../../withRouter';
import validate, { checkRequiredField } from '../../../common/formValidation/lib';

const formValidate = validate([
    checkRequiredField('isApproved'),
]);

const FormInner = ({ rip, session, ...props }) => {
    const { instanceTheseis } = session;

    return (
        <RipForm {...props} id={rip.id}>
            <Download rip={rip} isInstanceTheseis={instanceTheseis} />
            <TargetField rip={rip} />
            <Recommendations rip={rip} />
            <YesNoField title="Validez-vous ce rip et ces préconisations ?" name="isApproved" required />
            <Comment />
            <p>Cliquez sur &quot;Envoyer&quot; pour terminer et notifier le conseiller</p>
        </RipForm>
    );
};

FormInner.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    session: PropTypes.shape({
        instanceTheseis: PropTypes.bool,
    }).isRequired,
};

const Form = flowRight([
    withRouter,
    withSession,
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
        wait: true,
    }),
    reduxForm({
        form: 'rip-approval',
        validate: formValidate,
        onSubmit: (body, dispatch, { rip: { id } }) => POST(`/api/rips/${id}/sendAdvisorNotification`, { body }),
        onSubmitSuccess: alertEmail,
        onSubmitFail: (isErrors) => (isErrors ? null : alert('Erreur lors de l\'envoi du mail')),
    }),
    connect(null, { load: getIsValidator }),
    lifecycle({
        componentDidMount() {
            this.props.load(this.props.rip.id);
        },
    }),
    connect((state) => ({ isValidator: state.isValidator, isErrors: state.form['rip-approval'].syncErrors })),
    branch(({ isValidator }) => !isValidator, renderNothing),
])(FormInner);

export default Form;
