import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';
import { compose } from 'recompose';
import dataForm from 'components/rip/dataForm';
import { setModal } from 'actions';
import Spinner from 'components/Spinner';
import Group from 'components/form/Group';
import RadioGroup from 'components/form/RadioGroup';
import HorizontalRadioButton from 'components/form/HorizontalRadioButton';
import dataProvider from 'components/dataProvider';
import { withDatabase } from '../database';
import SuccessModalPrisme from '../SuccessModalPrisme';
import EchecModalPrisme from '../EchecModalPrisme';

import './SelectRecipientsForExport.scss';

const SelectRecipientsForExport = ({ handleSubmit, rip }) => {
    const [activate, setActivate] = useState(false);
    let onlyClient = true;

    if ('married' === rip.familySituation || 'partner' === rip.familySituation || ('cohabitation' === rip.familySituation && true === rip.dualRip)) {
        onlyClient = false;
    }

    return (
        <form onSubmit={handleSubmit} className="rip-recipients-selection">
            {!onlyClient && <h5>Choisissez qui est concerné par cet export</h5>}
            <Group name="selectRecipientsForExport" required>
                {onlyClient ? (
                    <RadioGroup name="selectRecipientsForExport" required>
                        <HorizontalRadioButton className="recipient" title="Contact principal" icon="user-circle-o" value="client" onlyClient />
                    </RadioGroup>
                ) : (
                    <RadioGroup name="selectRecipientsForExport" required>
                        <HorizontalRadioButton onClick={() => setActivate(true)} className="recipient" title="Contact principal" icon="user-circle-o" value="client" />
                        <HorizontalRadioButton onClick={() => setActivate(true)} className="recipient" title="Contact secondaire" icon="user-circle" value="partner" />
                        <HorizontalRadioButton onClick={() => setActivate(true)} className="recipient" title="Les deux" icon="users" value="both" />
                    </RadioGroup>)}
                <button type="submit" className="sendButton" disabled={!activate && !onlyClient}>
                    Exporter
                    <Icon icon="paper-plane" />
                </button>
            </Group>
        </form>
    );
};

SelectRecipientsForExport.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
        dualRip: PropTypes.bool,
    }),
};

export default compose(
    withDatabase,
    dataProvider({
        id: ({ id }) => id,
        type: 'rip',
    }),
    dataForm({
        form: 'rip-export-select-recipients',
        type: 'rip',
        id: ({ id }) => id,
        onSubmit: async ({ selectRecipientsForExport = 'client' }, dispatch, { id }) => {
            dispatch(setModal(() => <Spinner>Export des contacts en cours. <br /> Le chargement peut prendre quelques minutes</Spinner>, false));

            await fetch(`/api/rips/${id}/exportToPrisme/${selectRecipientsForExport}`, {
                method: 'POST',
            }).then((res) => {
                if ([200, 201, 202, 204].includes(res.status)) {
                    return dispatch(setModal(() => <SuccessModalPrisme />, false));
                }

                return dispatch(setModal(() => <EchecModalPrisme />, false));
            });
        },
    }),
)(SelectRecipientsForExport);
