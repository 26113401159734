import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import { flowRight } from 'lodash';
import withRouter from './withRouter';
import getQueryVariable from '../utils/getQueryVariable';
import { useAppData } from './withAppData';

const url = 'https://auth-pp.primonial.fr/auth/js/keycloak.js';

const PrimonialSsoRedirect = ({ Keycloak, location: { search } }) => {
    const redirectUrl = getQueryVariable(search, 'url');

    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { primonialSsoUrl, primonialSsoRealme, primonialSsoClientId } = useAppData();

    const initLogin = async () => {
        if (!redirectUrl) {
            setError('Url manquante');
            setIsLoading(false);

            return;
        }

        try {
            if (Keycloak) {
                // launch auth and redirect if succeded
                await Keycloak({
                    url: primonialSsoUrl,
                    realm: primonialSsoRealme,
                    clientId: primonialSsoClientId,
                }).init({
                    onLoad: 'login-required',
                    // uri when auth succeed
                    redirectUri: redirectUrl,
                    checkLoginIframe: true,
                    checkLoginIframeInterval: 5,
                });
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);

            setError('Une erreur interne est survenue');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        initLogin();
    }, [Keycloak]);

    return (
        <Fragment>
            {loading && <p>Authentification au hub primonial...</p>}
            {error && !loading && <p>{error}</p>}
        </Fragment>
    );
};

PrimonialSsoRedirect.propTypes = {
    Keycloak: PropTypes.func,
    location: PropTypes.shape({
        search: PropTypes.func,
    }).isRequired,
};

export default flowRight([
    withRouter,
    makeAsyncScriptLoader(url, { globalName: 'Keycloak', removeOnUnmount: true }),
])(PrimonialSsoRedirect);
