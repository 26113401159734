import { get } from 'lodash/fp';
import branchValidate from './branchValidate';
import checkRequiredIntegerField from './checkRequiredIntegerField';

const checkRequiredEditableField = (editableName, name) => branchValidate(
    (errors, values) => get(editableName, values),
    [checkRequiredIntegerField(name)],
);

export default checkRequiredEditableField;
