const overlay = (state = null, { type, ...payload }) => {
    switch (type) {
        case 'SHOW_OVERLAY':
            return payload.overlay;
        case 'HIDE_OVERLAY':
            return null;
        default:
            return state;
    }
};

export default overlay;
