import Router from 'components/routes/routers/simulation/pinelDenormandie/PinelDenormandieSimulationRouter';
import CommonCreation from '../common/Creation';
import commonValidation from '../common/formValidate';
import initialValues from './initialValues';

const pinelDenormandie = {
    // human readable title for this kind of product
    label: 'Dispositif Denormandie',
    // it may have additional fields (the RIP is given as property)
    formComponent: CommonCreation,
    // it may have to validate data (must be a validator signature)
    formValidate: (errors, values, props) => commonValidation(values, props, errors),
    // it may have initial values
    initialValues,
    // and an entry point for the form
    router: Router,
    entryPoint: 'resume',
};

export default pinelDenormandie;
