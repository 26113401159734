import { formValues } from 'redux-form';
import { compose, mapProps, renderNothing, withProps, branch } from 'recompose';
import TypedBlockDropdown from './TypedBlockDropdown';
import TypedBlockCollectionItemInner from './TypedBlockCollectionItemInner';

const TypedBlockCollectionDropdownsItem = compose(
    formValues(({ name }) => ({ type: `${name}.type` })),
    mapProps(({ type, types, ...props }) => ({ type: types[type], ...props })),
    branch(({ type }) => !type, renderNothing),
    withProps(({ openDropdown, toggle, name }) => ({
        component: TypedBlockDropdown,
        blockProps: { openDropdown, toggle, name },
    })),
)(TypedBlockCollectionItemInner);

export default TypedBlockCollectionDropdownsItem;
