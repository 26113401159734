import { flowRight } from 'lodash';
import { withProps } from 'recompose';
import withRouter from '../../withRouter';
import UniversignSuccess from './UniversignSuccess';

export default flowRight([
    withRouter,
    withProps({
        type: 'rip',
        getStatusUrl: ({ params: { ripId } }) => {
            const signStatusState = false;

            return (`/api/sign-status/rip/${ripId}/${signStatusState}`);
        } }),
])(UniversignSuccess);
