import React from 'react';

const DividendesSVG = (props) => (
    <svg
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 19.8 27.5"
        style={{
            enableBackground: 'new 0 0 19.8 27.5',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.st0{fill:#464646}'}</style>
        <g id="Calque_2">
            <g id="Calque_2-2">
                <path
                    className="st0"
                    d="m19.2 10-2.6-1.6 1.9-.1c.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4h-.2l-1.6.1c.9-1 .9-2.5-.1-3.5 0-.1-.1-.1-.2-.2l-2-1.4C13.8 0 13-.1 12.3.1L9.6 1h-.2L6.8.1C6-.1 5.2 0 4.6.5l-2 1.4c-1.1.8-1.4 2.3-.6 3.4.1.1.2.2.2.3l2.2 2.2-3.8 8.9c-.3.9-.6 1.8-.6 2.7 0 2.1.8 4.2 2.3 5.7 1.5 1.5 3.5 2.4 5.6 2.4h3.3c2.4 0 4.7-1.1 6.2-3 1.8-2.4 2.1-5.6.8-8.3l-2.4-4.9 1.8 1.1c.6.4 1.5.3 2-.3s.3-1.5-.3-2l-.1-.1zM6.1 2.8l2.9 1h1l3-.9L14.5 4l-2.2 2.2H6.7L4.6 3.9l1.5-1.1zm9.1 20c-1 1.2-2.4 1.9-4 1.9H7.9c-1.4 0-2.7-.5-3.7-1.5s-1.5-2.3-1.5-3.7c0-.6.2-1.1.4-1.7L7 8.9h4.6l4.1 8.5c.8 1.7.7 3.8-.5 5.4z"
                />
                <path
                    className="st0"
                    d="M11.5 20.6H11.2l-.2.2-.2.2-.2.2c-.1.1-.2.1-.3.1-.1 0-.3.1-.4 0-.4 0-.7-.1-1-.4-.3-.4-.5-.8-.6-1.3h1.9c.1 0 .1-.1.2-.1l.1-.1v-.7H8.3V18.1h2.6c.1 0 .1-.1.2-.1l.1-.1v-.7H8.4c.1-.5.3-.9.6-1.3.3-.3.6-.4 1-.4.2 0 .4 0 .5.1.1.1.2.1.3.2l.2.2c.1.1.2.1.2.1.1 0 .1 0 .2-.1l.1-.1.7-.7c-.3-.4-.6-.7-1-.9-.5-.2-1-.4-1.5-.3-.4 0-.8.1-1.2.2-.4.1-.7.4-1 .6-.3.3-.6.6-.8 1-.2.4-.4.8-.5 1.3h-.4v1h.6V18.7h-.6v1h.7c.1.5.2.9.4 1.3s.4.7.7 1c.3.3.6.5 1 .7.4.2.8.2 1.3.2s1.1-.1 1.5-.4c.4-.2.8-.6 1.1-1l-.8-.7c-.1-.1-.2-.1-.2-.2z"
                />
            </g>
        </g>
    </svg>
);

export default DividendesSVG;
