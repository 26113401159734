import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { fundingTypes } from 'common/choices';
import { GridLayout } from 'components/form/layout';
import { formatAmount, formatFullDate } from 'common/utils/format';
import { ChoiceField, DisabledField, FloatField, IntegerField, PercentageField } from 'components/form/field';

const LoanFormInner = ({ fundingAmount, defaultLeapInterestsAmount, editableLeapInterestsAmount, editedLeapInterestsAmount, handlingOfLeapInterests, propertyDeliveryDate }) => {
    const leapInterestsAmount = editableLeapInterestsAmount ? editedLeapInterestsAmount : defaultLeapInterestsAmount;

    return (
        <GridLayout className="condensed">
            <ChoiceField choices={fundingTypes} name="fundingType" title="Type" double required />
            <DisabledField
                name="fundingAmount"
                title="Montant du prêt"
                min={0}
                value={formatAmount(fundingAmount + ('financed' === handlingOfLeapInterests ? leapInterestsAmount : 0))}
            />
            <IntegerField name="fundingPersonalContribution" title="Apport personnel" min={0} />
            <FloatField name="fundingADI" title="ADI (par 10 000 €)" required />
            <PercentageField name="fundingRate" title="Taux" required />
            <DisabledField name="fundingStart" title="Début" value={formatFullDate(propertyDeliveryDate)} />
            <IntegerField name="fundingDuration" title="Durée (mois)" required min={0} />
        </GridLayout>
    );
};

LoanFormInner.propTypes = {
    fundingAmount: PropTypes.number,
    propertyDeliveryDate: PropTypes.string,
    defaultLeapInterestsAmount: PropTypes.number,
    editableLeapInterestsAmount: PropTypes.bool,
    editedLeapInterestsAmount: PropTypes.number,
    handlingOfLeapInterests: PropTypes.number,
};

const LoanForm = formValues({
    propertyDeliveryDate: 'propertyDeliveryDate',
    editableLeapInterestsAmount: 'editableLeapInterestsAmount',
    editedLeapInterestsAmount: 'leapInterestsAmount',
    handlingOfLeapInterests: 'handlingOfLeapInterests',
})(LoanFormInner);

export default LoanForm;
