import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Action } from '../list';

import './FieldInfo.css';

const FieldInfoAction = ({ children, id, buttonIsHidden, icon, text }) => (
    <OverlayTrigger
        trigger={['hover', 'click']}
        overlay={<Popover className="form-field-info-popover" placement="right" id={id}>{children}</Popover>}
        rootClose
    >
        <span>
            <Action
                className="rip-list-state-button deactivated"
                icon={icon}
                text={text}
                nonVisible={buttonIsHidden}
            />
        </span>
    </OverlayTrigger>
);

FieldInfoAction.propTypes = {
    buttonIsHidden: PropTypes.bool,
    children: PropTypes.node.isRequired,
    icon: PropTypes.string,
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
};

export default FieldInfoAction;
