import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { WizardLayout } from 'components/layouts';
import { VerticalLayout } from '../form/layout';

import './FormStep.css';

const Form = ({ children, handleSubmit, wizard, noScroll, className }) => (
    <WizardLayout
        wizard={wizard}
        hasFooter
        noScroll={noScroll}
        className={classNames('simulation-form', className)}
    >
        <form onSubmit={handleSubmit} id="main-form">
            <VerticalLayout noDiv>
                {children}
            </VerticalLayout>
        </form>
    </WizardLayout>
);

Form.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    wizard: PropTypes.shape({}),
    noScroll: PropTypes.bool,
};

Form.defaultProps = {
    className: null,
    wizard: null,
    noScroll: false,
};

export default Form;
