import { flow, omit, map, sum } from 'lodash/fp';

export const countErrors = (errors, excludes = []) => flow(
    // we can exclude some errors
    omit(excludes),
    // get values
    Object.values,
    // count the number of errors for each form
    map((step) => Object.keys(step).length),
    // and get the sum
    sum,
)(errors);

export default countErrors;
