import { mapProps, branch, renderComponent, compose } from 'recompose';
import { formValues } from 'redux-form';
import {
    formatAmount,
    formatFullDate,
    formatPercentage,
    formatSelect,
    formatBoolean,
} from 'common/utils/format';
import { connectDisabledForm } from '../withDisabledForm';
import Disabled from './Disabled';

const getDisplayedDefaultValue = (type, value, name) => {
    switch (type) {
        case 'number':
            return formatAmount(value);
        case 'date':
            return value ? formatFullDate(value) : 'Date non renseignée';
        case 'percentage':
            return formatPercentage(value);
        case 'select':
            return formatSelect(value, name);
        case 'boolean':
            return formatBoolean(value);
        default:
            return value || '';
    }
};

const typedDisabledFactory = ({ type } = {}) =>
    mapProps(({ value, type: propType, name, ...props }) => ({
        ...props,
        name,
        value: getDisplayedDefaultValue(type || propType, value, name),
    }))(Disabled);

export default typedDisabledFactory();

export const withDisableField = (options) => {
    const DisabledField = formValues(({ name }) => ({ value: name }))(typedDisabledFactory(options));

    return compose(
        connectDisabledForm,
        branch(({ disabled }) => disabled, renderComponent(DisabledField)),
    );
};
