// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.table.overview > tbody > tr > td {
    padding: 4px;
    vertical-align: middle;
}

.simulation-overview main {
    padding-top: 20px;
}

.rip-edit-link p {
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/simulation/types/lmnp/Overview.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["table.table.overview > tbody > tr > td {\n    padding: 4px;\n    vertical-align: middle;\n}\n\n.simulation-overview main {\n    padding-top: 20px;\n}\n\n.rip-edit-link p {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
