import favoriteEvolution from './favoriteEvolution';
import investmentDescription from './investmentDescription';
import investmentTimeHorizon from './investmentTimeHorizon';
import availableSavings from './availableSavings';
import percentageInvestment from './percentageInvestment';

const investmentWish = {
    availableSavings,
    favoriteEvolution,
    investmentDescription,
    investmentTimeHorizon,
    percentageInvestment,

};

export default investmentWish;
