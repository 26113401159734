import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { nest } from 'recompose';
import FormButtons from 'components/FormButtons';
import { Layout } from 'components/layouts';
import SummaryInner from './SummaryInner';
import withSummary from './withSummary';
import { GET } from '../../../../../utils/httpMethods';
import Spinner from '../../../../Spinner';

const SummaryPage = ({
    children,
    params: { id },
}) => {
    const [initialValues, setInitialValues] = useState(null);
    GET(`/simulations/${children.props.rip.id}/summary`)
        .then((response) => response.status)
        .then(setInitialValues);

    return (

        <Layout className="simulation-body" hasFooter>
            <div className="container summary">
                {
                    !initialValues ?
                        <div className="sign-frame-loader">
                            <Spinner>Récupération des informations...</Spinner>
                        </div>
                        :
                        children
                }
            </div>
            <FormButtons back={`/rip/${id}/simulations/`} list={`/rip/${id}/simulations/`} last />
        </Layout>
    );
};
SummaryPage.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    children: PropTypes.node.isRequired,
};

export default withSummary(nest(SummaryPage, SummaryInner));
