import { GET } from '../utils/httpMethods';

const setUsersRipStats = (usersRipStats) => ({
    type: 'SET_USER_RIP_STATS',
    usersRipStats,
});

export const getUsersRipStats = (userId) => async (dispatch) => {
    dispatch(setUsersRipStats({}));

    const response = await GET(`/api/users/${userId}/rip-stats`);

    dispatch(setUsersRipStats(await response.json()));
};
