import { get } from 'lodash/fp';
import validate, { checkRequiredField } from 'common/formValidation/lib';

// form validation for the creation form
const formValidate = validate(({ product }, { availableTypes }) => {
    // initial validators
    const validators = [checkRequiredField('product')];
    // look for an additional validator for the product
    const productFormValidate = get([product, 'formValidation'], availableTypes);

    if (productFormValidate) {
        // we have an additional form validation for this product
        validators.push(productFormValidate);
    }

    return validators;
});

export default formValidate;
