import { checkRequiredField, checkRequiredIntegerField } from '../lib';
import { lifeInsuranceProfiles } from '../../choices/settings/calculator';

const validators = [
    checkRequiredField('global.socialChargesRate'),
    checkRequiredField('global.pfu'),
    checkRequiredField('finance.livretARate'),
    checkRequiredField('finance.LDDRate'),
    checkRequiredField('calculator.lifeInsurance.inflation'),
    ...Object
        .keys(lifeInsuranceProfiles)
        .map((profile) => checkRequiredField(`calculator.lifeInsurance.profiles.${profile}`)),
    checkRequiredField('calculator.credit.loanRate20'),
    checkRequiredField('calculator.credit.loanRate25'),
    checkRequiredIntegerField('global.legalDisclaimer.notices'),
    checkRequiredIntegerField('global.legalDisclaimer.cookies'),
    checkRequiredIntegerField('global.legalDisclaimer.confidentiality'),
    checkRequiredIntegerField('global.legalDisclaimer.clientPdf'),
];

export default validators;
