import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { formatAmount, formatPercentage } from 'common/utils/format';
import Title from 'components/Title';
import getSettings from 'common/utils/settings';

class Hypothesis extends Component {
    renderFullOwnership() {
        const fullOwnership = _.get(this.props, 'results.fullOwnership', []);

        return (
            <tr>
                <td>Valeur de la pleine propriété</td>
                {fullOwnership.map((value) => (
                    <td key={value}>{formatAmount(value)}</td>
                ))}
            </tr>
        );
    }

    renderBareOwnershipShareRow() {
        const bareOwnershipShare = _.get(this.props, 'results.bareOwnershipShare', []);

        return (
            <tr>
                <td>Quote-part de la nue-propriété</td>
                {bareOwnershipShare.map((value) => (
                    <td key={value}>{formatPercentage(value)}</td>
                ))}
            </tr>
        );
    }
    renderBareOwnershipRow() {
        const bareOwnership = _.get(this.props, 'results.bareOwnership', []);

        return (
            <tr>
                <td>Valeur de la nue-propriété</td>
                {bareOwnership.map((value) => (
                    <td key={value}>{formatAmount(value, true)}</td>
                ))}
            </tr>
        );
    }

    render() {
        const { results, products } = this.props;

        return (
            <Fragment>
                <Title>
                    <b>Hypothèses du démembrement</b>
                </Title>
                <table className="table table-striped table-bordered lifeInsurance-hypothesis-table table-hover">
                    <thead>
                        <tr>
                            <th>SCPI</th>
                            {map(
                                ({ type }) => (
                                    <th key={type}>{getSettings(['simulation', 'dismemberedScpi', type, 'name'])}</th>
                                ),
                                products,
                            )}
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderFullOwnership()}
                        <tr>
                            <td>Durée du démembrement</td>
                            <td colSpan={products.length + 1}>{`${results.duration} ans`}</td>
                        </tr>
                        {this.renderBareOwnershipShareRow()}
                        {this.renderBareOwnershipRow()}
                    </tbody>
                </table>
            </Fragment>
        );
    }
}

Hypothesis.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    results: PropTypes.shape({
        duration: PropTypes.number,
    }).isRequired,
};

export default Hypothesis;
