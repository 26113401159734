import { flowRight } from 'lodash/fp';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import { setFormValidationErrors } from 'actions';

const multiStepValidation = (validate) => flowRight([
    // we will persist the errors in the store
    // so we prepare the action to do so
    connect(null, { setFormValidationErrors }),
    // we provide a custom validate method
    // but will validate all the steps
    // and persist the errors for the whole form
    withProps(({ setFormValidationErrors: setErrors }) => ({
        validate: (values, props) => {
            // validate the whole form (its every steps)
            const formErrors = validate(values, props);

            // persist the validation so it might be read out of the form itself
            setErrors(formErrors);

            return formErrors;
        },
    })),
]);

export default multiStepValidation;
