import React from 'react';
import PropTypes from 'prop-types';

const Warning = ({ children }) => (
    <p style={{ color: 'orange' }}>{children} </p>
);

Warning.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Warning;
