import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { IntegerField, PercentageField, TextField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import LoanRange from '../../LoanRange';

const Mortgage = ({ name }) => (
    <GridLayout className="triple condensed full-width">
        <Fragment>
            <TextField name={`${name}.mortgageType`} title="Type de crédit" required />
            <IntegerField name={`${name}.initialLoanAmount`} title="Montant initial du prêt" required />
            <PercentageField name={`${name}.interestRate`} title="Taux d&apos;intérêt" />
            <IntegerField name={`${name}.remainingCapital`} title="Capital restant dû" />
            <LoanRange
                startName={`${name}.startLoan`}
                startTitle="Date de début de votre crédit"
                endName={`${name}.endLoan`}
                endTitle="Date de fin de votre crédit"
                durationName={`${name}.durationLoan`}
                durationTitle="Durée en mois de votre crédit"
            />
            <TextField name={`${name}.bank`} title="Nom de votre Banque" />
            <IntegerField name={`${name}.dueDateAmount`} title="Mensualité de votre crédit" required />
            <IntegerField name={`${name}.insuranceAmount`} title="Mensualité de votre assurance de prêt (ADI)" />
        </Fragment>
    </GridLayout>
);

Mortgage.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Mortgage;
