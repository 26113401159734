import PropTypes from 'prop-types';
import React from 'react';
import hasPartner from 'common/utils/hasPartner';
import { IntegerField, TextAreaField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import Divider from 'components/Divider';
import RipTaxCommon from './TaxCommon';
import RipTaxIncomes from './TaxIncomesList';
import RipTaxDeductions from './TaxDeductionsList';
import RipTaxReductions from './TaxReductionsList';
import RipTaxCredits from './TaxCreditsList';
import Events from './EventsList';
import RipForm from '../Form';
import dataForm from '../dataForm';

const FormInner = ({ initialValues: { familySituation, createdAt }, ...props }) => {
    const isCohabitation = 'cohabitation' === familySituation;

    return (
        <RipForm {...props}>
            <RipTaxCommon createdAt={createdAt} />
            {hasPartner(familySituation) ? (
                <RipTaxCommon partner cohabitation={isCohabitation} createdAt={createdAt} />
            ) : null}
            <RipTaxIncomes />
            {isCohabitation ? (
                <GridLayout>
                    <IntegerField
                        title="Combien avez-vous payé d'impôts l'an passé ?"
                        name="previousTaxes"
                    />
                    <IntegerField
                        title="Combien votre conjoint a payé d'impôts l'an passé ?"
                        name="partnerPreviousTaxes"
                    />
                </GridLayout>
            ) : (
                <IntegerField title="Combien avez-vous payé d'impôts l'an passé ?" name="previousTaxes" />
            )}
            <Events />
            <RipTaxDeductions />
            <RipTaxReductions />
            <RipTaxCredits />
            <Divider />
            <TextAreaField title="Commentaires" name="taxesComment" />
        </RipForm>
    );
};

FormInner.propTypes = {
    initialValues: PropTypes.shape({
        familySituation: PropTypes.string,
        createdAt: PropTypes.string,
    }).isRequired,
};

const Form = dataForm({
    form: 'rip-taxes',
    id: ({ id }) => id,
    type: 'rip',
})(FormInner);

export default Form;
