import React, { Fragment } from 'react';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { IntegerField } from 'components/form/field';

const TenPercentsAbatement = () => (
    <Fragment>
        <Title>Déduction forfaitaire de 10%</Title>
        <GridLayout className="double condensed full-width">
            <IntegerField
                name="summary.incomeTax.10percentAbatmentBounds.retired.min"
                title="Seuil mini retraité (€)"
            />
            <IntegerField
                name="summary.incomeTax.10percentAbatmentBounds.retired.max"
                title="Plafond maxi retraité (€)"
            />
            <IntegerField
                name="summary.incomeTax.10percentAbatmentBounds.employed.min"
                title="Seuil mini salarié (€)"
            />
            <IntegerField
                name="summary.incomeTax.10percentAbatmentBounds.employed.max"
                title="Plafond maxi salarié (€)"
            />
        </GridLayout>
    </Fragment>
);

export default TenPercentsAbatement;
