// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-item {
    display: inline-block;
    vertical-align: top;
}

.summary-item .summary-item-inner {
    border-left: 2px solid #248fdf;
    padding: 10px 20px 0 20px;
    min-height: 82px;
    width: 100%;
}

.summary-item .summary-item-inner.no-title {
    padding: 20px 20px 0 20px;
}

.summary-item dt {
    font-size: 15px;
    font-weight: 400;
    color: #464646;
    min-height: 30px;
}

.summary-item dt > span.summary-help {
    display: block;
    font-style: italic;
    font-size: 13px;
}

.summary-item dd {
    font-weight: 900;
    font-size: 25px;
    color: #248fdf;
}

.summary-item.important .summary-item-inner {
    border-color: #27cae6;
}

.summary-item.important dt,
.summary-item.important dd {
    color: #27cae6;
}

.is {
    padding-top: 30px;
}

.is .summary-item.important dt,
.is .summary-item.important dd {
    color: #0D0DA7;
}
.is .summary-item.important .summary-item-inner {
    border-color: #0D0DA7;
    margin-bottom: 20px;
}

.summary-item.bold dt,
.summary-item.bold dd {
    font-weight: 900;
}

.summary-item.negative dd {
    color: rgb(255, 23, 23);
}
`, "",{"version":3,"sources":["webpack://./src/common/components/summary/Item.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;;IAEI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;IAEI,cAAc;AAClB;AACA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".summary-item {\n    display: inline-block;\n    vertical-align: top;\n}\n\n.summary-item .summary-item-inner {\n    border-left: 2px solid #248fdf;\n    padding: 10px 20px 0 20px;\n    min-height: 82px;\n    width: 100%;\n}\n\n.summary-item .summary-item-inner.no-title {\n    padding: 20px 20px 0 20px;\n}\n\n.summary-item dt {\n    font-size: 15px;\n    font-weight: 400;\n    color: #464646;\n    min-height: 30px;\n}\n\n.summary-item dt > span.summary-help {\n    display: block;\n    font-style: italic;\n    font-size: 13px;\n}\n\n.summary-item dd {\n    font-weight: 900;\n    font-size: 25px;\n    color: #248fdf;\n}\n\n.summary-item.important .summary-item-inner {\n    border-color: #27cae6;\n}\n\n.summary-item.important dt,\n.summary-item.important dd {\n    color: #27cae6;\n}\n\n.is {\n    padding-top: 30px;\n}\n\n.is .summary-item.important dt,\n.is .summary-item.important dd {\n    color: #0D0DA7;\n}\n.is .summary-item.important .summary-item-inner {\n    border-color: #0D0DA7;\n    margin-bottom: 20px;\n}\n\n.summary-item.bold dt,\n.summary-item.bold dd {\n    font-weight: 900;\n}\n\n.summary-item.negative dd {\n    color: rgb(255, 23, 23);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
