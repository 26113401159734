import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { incomeTypes } from 'common/choices';
import { formatAmount } from 'common/utils/format';
import computeSalary from 'common/model/rip/salary';
import OwnerField from '../field/Owner';
import RipTaxExecutive from './TaxExecutive';
import {
    DisabledField,
    IntegerField,
    SalaryField,
    SelectField,
    TextField,
    YesNoField,
    ChoiceField,
} from '../../form/field';
import { HorizontalLayout } from '../../form/layout';

const SalaryInner = (props) => {
    const { name, realExpenses, income, incomeType, variableIncome, ripFromRecommendation } = props;

    const {
        salaryMonthCountInt,
        disableIncome,
        disableFrequency,
        monthlyIncome,
        monthlyConstantIncome,
        constantIncome,
    } = computeSalary(props);

    return (
        <div>
            <HorizontalLayout>
                <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" ripFromRecommendation={ripFromRecommendation} required />
                <TextField name={`${name}.company`} title="Société" />
                <RipTaxExecutive name={`${name}.jobStatus`} title="Statut" />
            </HorizontalLayout>
            <HorizontalLayout>
                <ChoiceField name={`${name}.incomeType`} choices={incomeTypes} title="Salaire" />
                {'monthly' === incomeType
                    ? (
                        <SalaryField
                            salaryMonthCount={salaryMonthCountInt}
                            name={`${name}.income`}
                            title="Salaire mensuel net"
                            required
                        />
                    ) : (
                        <IntegerField
                            name={`${name}.income`}
                            title="Salaire annuel net"
                            required
                        />
                    )
                }
                <SelectField name={`${name}.salaryMonthCount`} title="Nombre de mois">
                    <option value="12">12 mois</option>
                    <option value="13">13 mois</option>
                    <option value="14">14 mois</option>
                </SelectField>
                <DisabledField
                    name={`${name}.disabledIncome`}
                    title={`Salaire ${disableFrequency} net`}
                    value={disableIncome ? formatAmount(disableIncome) : ''}
                />
                {'monthly' === incomeType ? (
                    <Fragment>
                        <SalaryField
                            salaryMonthCount={salaryMonthCountInt}
                            name={`${name}.variableIncome`}
                            title="Dont part variable mensuelle"
                            max={monthlyIncome}
                        />
                        <DisabledField
                            name={`${name}.disabledMonthlyConstantIncome`}
                            title="Part fixe mensuelle"
                            value={formatAmount(monthlyConstantIncome)}
                        />
                        <DisabledField
                            name={`${name}.disabledVariableIncome`}
                            title="Part variable annuelle"
                            value={variableIncome ? formatAmount(variableIncome) : ''}
                        />
                        <DisabledField
                            name={`${name}.disabledConstantIncome`}
                            title="Part fixe annuelle"
                            value={formatAmount(constantIncome)}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <IntegerField
                            name={`${name}.variableIncome`}
                            title="Dont Part variable annuelle"
                            max={income}
                        />
                        <DisabledField
                            name={`${name}.disabledConstantIncome`}
                            title="Part fixe annuelle"
                            value={formatAmount(constantIncome)}
                        />
                        <DisabledField
                            name={`${name}.disabledVariableIncome`}
                            title="Part variable mensuelle"
                            value={variableIncome ? formatAmount(variableIncome / salaryMonthCountInt) : ''}
                        />
                        <DisabledField
                            name={`${name}.disabledMonthlyConstantIncome`}
                            title="Part fixe mensuelle"
                            value={formatAmount(monthlyConstantIncome)}
                        />
                    </Fragment>
                )}
                <YesNoField name={`${name}.realExpenses`} title="Êtes-vous aux frais réels ?" />
                {realExpenses && (
                    <IntegerField
                        name={`${name}.realExpensesValue`}
                        title="Frais réels annuels"
                        required
                    />
                )}
            </HorizontalLayout>
        </div>
    );
};

SalaryInner.defaultProps = {
    realExpenses: false,
    income: null,
    incomeType: 'monthly',
    variableIncome: null,
};

SalaryInner.propTypes = {
    name: PropTypes.string.isRequired,
    realExpenses: PropTypes.bool,
    income: PropTypes.number,
    incomeType: PropTypes.string,
    variableIncome: PropTypes.number,
    ripFromRecommendation: PropTypes.shape({}),
};

const Salary = formValues(({ name }) => ({
    realExpenses: `${name}.realExpenses`,
    income: `${name}.income`,
    incomeType: `${name}.incomeType`,
    salaryMonthCount: `${name}.salaryMonthCount`,
    variableIncome: `${name}.variableIncome`,
}))(SalaryInner);

export default Salary;
