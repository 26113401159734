import { getOr, reduce } from 'lodash/fp';

const buildTree = (items) => {
    const tree = reduce((lookup, item) => {
        const { id, parentId } = item;
        // eslint-disable-next-line no-param-reassign
        lookup[id] = {
            ...item,
            children: getOr([], [id, 'children'], lookup),
        };

        if (parentId) {
            if (!lookup[parentId]) {
            // eslint-disable-next-line no-param-reassign
                lookup[parentId] = { children: [] };
            }

            lookup[parentId].children.push(lookup[id]);
        }

        return lookup;
    }, {})(items);

    return Object.values(tree).filter(({ parentId }) => !parentId);
};

export default buildTree;
