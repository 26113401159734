import * as React from 'react';

const OwnerSVG = (props) => (
    <svg
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 30.6 27.9"
        style={{
            enableBackground: 'new 0 0 30.6 27.9',
        }}
        xmlSpace="preserve"
        {...props}
        className="ownerSGV"
    >
        <style>{'.st0{fill:#464646}'}</style>
        <g id="Calque_2">
            <g id="Calque_2-2">
                <path
                    className="st0"
                    d="M23.3 25.1h-16v-9.6H4.5v10.2c0 1.2 1 2.1 2.1 2.1H24c1.2 0 2.1-1 2.1-2.1V15h-2.8v10.1zM30.1 9.6 17.1.4c-.8-.6-1.9-.6-2.7 0L.6 9.6c-.7.4-.8 1.3-.4 2s1.3.8 2 .4l13.6-9.1 12.7 9c.6.5 1.5.3 2-.3.3-.6.2-1.5-.4-2z"
                />
                <path
                    className="st0"
                    d="M12.4 16.1v.2h-.6v1h.7c.1.5.2.9.4 1.3s.4.7.7 1c.3.3.6.5 1 .7.4.2.8.2 1.3.2s1.1-.1 1.5-.4c.4-.2.8-.6 1.1-1l-.8-.7-.1-.1H17.3l-.3.3-.2.2-.2.2c-.1.1-.2.1-.3.1-.1 0-.3.1-.4 0-.4 0-.7-.1-1-.4-.3-.4-.5-.8-.6-1.3h1.9c.1 0 .1-.1.2-.1l.1-.1v-.7h-2.3v-.7h2.6c.1 0 .1-.1.2-.1l.1-.1v-.8h-2.7c.1-.4.3-.8.6-1.2.3-.3.6-.4 1-.4.2 0 .4 0 .5.1.1.1.2.1.3.2l.2.2c.1.1.2.1.2.1.1 0 .1 0 .2-.1l.1-.1.7-.7c-.3-.4-.6-.7-1-.9-.5-.2-1-.4-1.5-.3-.4 0-.8.1-1.2.2-.4.1-.7.4-1 .6-.3.3-.6.6-.8 1-.2.4-.4.8-.5 1.3h-.7v1h.6V16.1z"
                />
            </g>
        </g>
    </svg>
);

export default OwnerSVG;
