import React from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import Icon from 'common/components/fa/Icon';

import './VersionWarning.scss';

const VersionWarning = () => (
    <div className="container">
        <div className="alert alert-danger version-warning">
            <Icon icon="warning" className="fa-fw" /> Une nouvelle version de E-RIP est disponible
            <div className="text-right">
                <button onClick={() => document.location.reload()}>
                    <Icon icon="refresh" className="fa-fw" /> Recharger l&apos;application
                </button>
            </div>
        </div>
    </div>
);

export default compose(
    connect(({ outdated }) => ({ outdated })),
    branch(({ outdated }) => !outdated, renderNothing),
)(VersionWarning);
