import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { saveData } from 'actions';
import dataForm from 'components/dataForm';
import FormButtons from 'components/FormButtons';
import Form from 'components/simulation/FormStep';
import Documents from '../DocumentsUpload/Documents';
import getUploader from '../getUploader';

const DocumentsUploadInner = ({
    handleSubmit,
    params: { id, contractId },
}) => {
    const uploader = getUploader(contractId);

    return (
        <Form handleSubmit={handleSubmit}>
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <Documents uploader={uploader} />
                </div>
            </div>
            <FormButtons
                list={null}
                back={`/rip/${id}/contracts/${contractId}/ca-validation`}
                nextButtonContent="Validation"
            />
        </Form>
    );
};

DocumentsUploadInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
    }).isRequired,
};

const DocumentsUpload = flowRight([
    dataForm({
        form: 'contract-documents-edit',
        id: ({ params: { contractId } }) => contractId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, contractId },
        }) => {
            dispatch(saveData('contract', contractId, {}));
            navigate(`/rip/${id}/contracts/${contractId}/ca-validation`);
        },
        type: 'contract',
    }),
])(DocumentsUploadInner);

export default DocumentsUpload;
