import React, { Fragment } from 'react';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const MicroBA = () => (
    <Fragment>
        <Title>Micro BA</Title>
        <GridLayout className="double condensed full-width">
            <IntegerField
                name="summary.incomeTax.microBAAbatement.min"
                title="Seuil (€)"
            />
            <PercentageFloatField
                name="summary.incomeTax.microBAAbatement.rate"
                title="Abattement (%)"
            />
        </GridLayout>
    </Fragment>
);

export default MicroBA;
