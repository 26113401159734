import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import YesNoItem from './YesNoItem';
import TextItem from './Item';

const RecurrentItem = ({ recurrentEvent, endYear }) => (
    <Fragment>
        <YesNoItem value={recurrentEvent} title="Récurrent" />
        {!recurrentEvent ? (
            <TextItem value={endYear} title="Année de fin" />
        ) : null}
    </Fragment>
);

RecurrentItem.propTypes = {
    recurrentEvent: PropTypes.bool.isRequired,
    endYear: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

RecurrentItem.defaultProps = {
    endYear: null,
};

export default RecurrentItem;
