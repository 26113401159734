import React from 'react';
import PropTypes from 'prop-types';
import { Body, Head, Table } from 'components/list';
import ListItem from './ListItem';

const ListTable = ({ logs }) => (
    <Table wrapped className="syncLogs">
        <Head>
            <th className="text-center">Type</th>
            <th className="text-center">Source</th>
            <th className="text-center">Resource</th>
            <th className="text-center">Champs</th>
        </Head>
        <Body>
            {logs.map((item) => (
                <ListItem key={item.id} item={item} />
            ))}
        </Body>
    </Table>
);

ListTable.propTypes = {
    logs: PropTypes.shape({
        map: PropTypes.func,
    }).isRequired,
};

export default ListTable;
