// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-button {
    outline: none;
    text-align: center;
    color: rgb(36, 146, 223);
    width: 44px;
    height: 44px;
    border: 1px solid rgb(36, 146, 223);
    border-radius: 22px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 0;
}

.menu-button.primary {
    background: rgba(36,143,223,1);
    background: linear-gradient(135deg, rgba(36,143,223,1) 37%, rgba(37,175,227,1) 59%, rgba(40,234,234,1) 100%);
    color: white;
}

.menu-button.danger {
    color: #721c24;
    border-color: #721c24;
}

.menu-button > p {
    font-size: 20px;
    padding-top: 6px;
}

.menu-button.disabled {
    opacity: .55;
}`, "",{"version":3,"sources":["webpack://./src/components/menu/Button.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;IACX,YAAY;IACZ,mCAAmC;IACnC,mBAAmB;IACnB,oCAAoC;IACpC,2CAA2C;IAC3C,UAAU;AACd;;AAEA;IACI,8BAA8B;IAC9B,4GAA4G;IAC5G,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".menu-button {\n    outline: none;\n    text-align: center;\n    color: rgb(36, 146, 223);\n    width: 44px;\n    height: 44px;\n    border: 1px solid rgb(36, 146, 223);\n    border-radius: 22px;\n    background-color: rgb(255, 255, 255);\n    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);\n    padding: 0;\n}\n\n.menu-button.primary {\n    background: rgba(36,143,223,1);\n    background: linear-gradient(135deg, rgba(36,143,223,1) 37%, rgba(37,175,227,1) 59%, rgba(40,234,234,1) 100%);\n    color: white;\n}\n\n.menu-button.danger {\n    color: #721c24;\n    border-color: #721c24;\n}\n\n.menu-button > p {\n    font-size: 20px;\n    padding-top: 6px;\n}\n\n.menu-button.disabled {\n    opacity: .55;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
