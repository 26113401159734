import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import logo from 'images/logo-universign.png';
import { Alert } from 'react-bootstrap';
import './SelectSignature.scss';
import Icon from '../../../../../../common/components/Icon';

const SelectSignature = ({
    title,
    selectedSignature,
    onClick: clickHandler,
    manualOnly = false,
    enLigne,
    avecAlerte,
    investorProfiles,
    isRequiredField,
}) => {
    const disabledManualSign = isRequiredField && !investorProfiles && !title.includes('Conseiller');
    const disabledUniversignSign = !enLigne || (isRequiredField && !investorProfiles);
    const AMFFormIncomplete = isRequiredField && !investorProfiles && (<span className="tx-message-other"> (Questionnaire AMF incomplet)</span>);

    return (
        <div className="select-signatures">
            {title} :
            {
                (avecAlerte && !enLigne) &&
                <div className="select-signature__alerte">
                    <Alert className="select-signature__alerte__contenu" variant="info">
                        <Icon icon="info" className="select-signature__alerte-icone" />
                        La signature électronique ne sera accessible qu&apos;une fois l&apos;application connectée à Internet
                    </Alert>
                </div>
            }
            <div className="select-signature__contenu">
                <button
                    tabIndex="0"
                    className={classNames('signature-type', { selected: 'manual' === selectedSignature || manualOnly })}
                    onClick={clickHandler ? () => clickHandler('manual') : (e) => e}
                    disabled={disabledManualSign}
                >
                    Signature Manuscrite
                </button>
                {!manualOnly && (
                    <Fragment>
                        <button
                            tabIndex="-1"
                            className={classNames('signature-type', { selected: 'universign' === selectedSignature })}
                            onClick={() => clickHandler('universign')}
                            disabled={disabledUniversignSign}
                        >
                            <img src={logo} alt="Universign" />
                            Signature Électronique (Client non présent physiquement)
                        </button>
                        <button
                            tabIndex="-1"
                            className={classNames('signature-type', { selected: 'no' === selectedSignature })}
                            onClick={() => clickHandler('no')}
                        >
                            Pas de signature {AMFFormIncomplete}
                        </button>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

SelectSignature.propTypes = {
    title: PropTypes.string.isRequired,
    selectedSignature: PropTypes.string,
    onClick: PropTypes.func,
    manualOnly: PropTypes.bool,
    enLigne: PropTypes.bool,
    avecAlerte: PropTypes.bool,
    investorProfiles: PropTypes.shape({}),
    isRequiredField: PropTypes.bool,
};

export default SelectSignature;
