import React from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import { PercentageFloatField } from 'components/form/field';
import { lifeInsuranceProfiles } from 'common/choices/settings/calculator';

import '../EditForm.scss';

const LifeInsuranceSettings = ({ settings }) => (
    <GridLayout className="single condensed full-width">
        <PercentageFloatField name="calculator.lifeInsurance.inflation" title="Taux d'inflation" />
        <hr />
        <table className="settings-table">
            <thead>
                <tr>
                    <th colSpan="3">Rendement des profils</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Profiles</td>
                    <td>Rendement (%)</td>
                </tr>
                {Object.keys(settings.profiles).map((profile) => (
                    <tr key={profile}>
                        <td>{lifeInsuranceProfiles[profile]}</td>
                        <td><PercentageFloatField name={`calculator.lifeInsurance.profiles.${profile}`} /></td>
                    </tr>
                ))}
            </tbody>
        </table>
    </GridLayout>
);

LifeInsuranceSettings.propTypes = {
    settings: PropTypes.shape({
        profiles: PropTypes.shape({}),
    }).isRequired,
};

export default LifeInsuranceSettings;
