const modes = {
    isInactiveWithRips: {
        label: 'Conseillers inactifs avec RIP',
        icon: 'users',
    },
    itemsActiveRips: {
        label: 'Conseillers actifs',
        icon: 'users',
    },
    isInactiveWithoutRips: {
        label: 'Conseillers inactifs sans RIP',
        icon: 'users',
    },
};

export default modes;
