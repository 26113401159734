import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from 'common/components/Icon';
import logout from 'utils/logout';
import { useDatabase } from '../../database';
import { useSession } from '../../sessions';
import redirectToTopinvest from '../../../utils/redirectToTopinvest';

import './UserMenu.scss';

const UserMenu = ({ menuRef, toggleActiveUserMenu }) => {
    const session = useSession();
    const database = useDatabase();

    return (
        <div className="layout-user-menu" ref={menuRef}>
            <ul>
                {'Topinvest' === session.entity.name ? (
                    <li>
                        <button onClick={() => redirectToTopinvest(session)}>
                            Retour vers Topinvest <Icon icon="sign-out" />
                        </button>
                    </li>
                ) : (
                    <li>
                        <button onClick={() => logout(session, database)}>Se déconnecter<Icon icon="sign-out" /></button>
                    </li>

                )}
                <li>
                    <Link
                        to="/profile"
                        onClick={() => toggleActiveUserMenu()}
                    >
                        Mes informations <Icon icon="userN" />
                    </Link>
                </li>
                {(session.permissions.isCaseManager() || ('cgp' === session.status && (session.needApprovalOnSimulations || session.needApprovalOnCIFDocument))) && (
                    <li>
                        <Link
                            to="/pendingValidations"
                            onClick={() => toggleActiveUserMenu()}
                        >
                            Validations en attente<Icon icon="share" />
                        </Link>
                    </li>
                )}
                {(session.permissions.isManager() || session.permissions.isAdmin()) && (
                    <li>
                        <Link
                            to="/team"
                            onClick={() => toggleActiveUserMenu()}
                        >
                            Accéder aux rips de mon équipe<Icon icon="users" />
                        </Link>
                    </li>
                )}
                {(session.permissions.isAdmin() || session.permissions.isAdminReseau()) && (
                    <li>
                        <Link
                            to="/admin"
                            onClick={() => toggleActiveUserMenu()}
                        >
                            Administration<Icon icon="wrench" />
                        </Link>
                    </li>
                )}
                {session.instanceSettings.hasLegalDisclaimer && (
                    <li>
                        <Link
                            to="/legalDisclaimer/notices"
                            onClick={() => toggleActiveUserMenu()}
                        >
                            <button>Mentions légales<Icon icon="info" /></button>
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    );
};

UserMenu.propTypes = {
    menuRef: PropTypes.shape({}),
    toggleActiveUserMenu: PropTypes.func,
};

export default UserMenu;
