import { sum, sumBy } from 'lodash/fp';

const sumRemainingCapital = sumBy(({ remainingCapital = 0 }) => remainingCapital);

const computeOutstandingCapital = ({
    mainResidenceMortgages = [],
    property = [],
    charges = [],
}) => ({
    outstandingCapital: sum([
        sumRemainingCapital(charges),
        sumRemainingCapital(mainResidenceMortgages),
        ...property.map(({ mortgages = [] }) => sumRemainingCapital(mortgages)),
    ]),
});

export default computeOutstandingCapital;
