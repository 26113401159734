import { set } from 'lodash/fp';
import validate, { checkRequiredField } from 'common/formValidation/lib';

const formValidate = validate([
    checkRequiredField('beneficiary'),
    (errors, { beneficiary }, { rip, session }) =>
        (session.permissions.mayCreateSimulation(rip, 'dismemberedScpi', beneficiary)
            ? errors
            : set('product', 'Recommendation non validé pour ce bénéficiaire', errors)
        ),
]);

export default formValidate;
