import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, lifecycle, branch, renderNothing } from 'recompose';
import { isEmpty } from 'lodash/fp';
import validate, { checkRequiredField, checkIsEmail } from 'common/formValidation/lib';
import { GridLayout } from 'components/form/layout';
import { TextField, SearchableSelectField, EmailField } from 'components/form/field';
import Title from 'components/Title';
import { loadDataList } from 'actions';
import FormFields from '../../FormFields';
import { withSession } from '../../../sessions';
import sortEntitiesChildren from '../../../../../../common/src/utils/sortEntitiesChildren';

const formValidate = validate([
    checkRequiredField('firstName'),
    checkRequiredField('lastName'),
    checkRequiredField('email'),
    checkIsEmail('email'),
    checkRequiredField('entityId'),
]);

const FormInner = ({
    session,
    entities,
    ...props
}) => {
    let entitiesToMap;

    if (session.isAdmin) {
        entitiesToMap = entities;
    } else if (session.isAdminReseau) {
        const entityOfTheUser = entities.filter((entity) => entity.name === session.entity.name);

        const childrenEntities = sortEntitiesChildren(entities, entityOfTheUser[0].id);

        entitiesToMap = [entityOfTheUser, childrenEntities];
    }

    return (
        <FormFields {...props} back="/admin/user">
            <Title><b>Nouvel Utilisateur</b></Title>
            <GridLayout className="double condensed full-width gray-box">
                <TextField
                    title="Prénom"
                    name="firstName"
                    required
                />
                <TextField
                    title="Nom"
                    name="lastName"
                    required
                />
                <EmailField
                    title="Email"
                    placeholder="nicolas.dupond@yahoo.fr"
                    required
                    name="email"
                />
                <SearchableSelectField
                    name="entityId"
                    title="Réseau"
                    options={entitiesToMap.flat().map(({ id: entityId, name }) => ({ value: entityId, label: name }))}
                />
            </GridLayout>
        </FormFields>
    );
};

FormInner.propTypes = {
    session: PropTypes.shape({
        isAdminReseau: PropTypes.bool,
        isAdmin: PropTypes.bool,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
};

const Form = compose(
    withSession,
    reduxForm({
        form: 'user-create',
        validate: formValidate,
        initialValues: {
            isAdmin: false,
            payingUser: true,
            needApprovalOnSimulations: true,
            needApprovalOnCIFDocument: true,
        },
    }),
    connect(null, { loadDataList }),
    lifecycle({
        componentDidMount() {
            this.props.loadDataList('entity');
        },
    }),
    connect((state) => ({
        entities: Object.values(state.data.entity),
    })),
    branch(({ entities }) => isEmpty(entities), renderNothing),
)(FormInner);

export default Form;
