/*
 * Warning : worst practice /!\
 *
 * Because we cannot afford for now to remove fetch methods as globals, we allow to globally set the logout function
 * in the following scope to link our middleware to our component state
 *
 * this must be remove in future, do not reproduce such pattern
 */

let logout = null;

export const setLogoutFunction = (func) => {
    logout = func;
};

const handleErrors = (error) => {
    if (401 === error.status) {
        logout();

        return;
    }

    console.error(error);
};

export default handleErrors;
