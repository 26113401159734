import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../common/utils/format';

const Date = ({ date }) => (
    <td className="date">
        {formatDate(moment(date))}
    </td>
);

Date.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]).isRequired,
};

export default Date;
