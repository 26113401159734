import React from 'react';
import List from './List';
import Form from './Form';

import './Comments.scss';

const Comments = () => (
    <div className="rip-comments-box">
        <List />
        <Form />
    </div>
);

export default Comments;
