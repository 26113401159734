import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './SubscriptionInfos.scss';

const SubscriptionInfos = ({ type, ...data }) => {
    switch (type) {
        case 'free':
            return <h3>Cet utilisateur possède un abonnement gratuit illimité.</h3>;
        case 'ongoingSubscription':
            return (
                <Fragment>
                    <h3>Cet utilisateur à opté pour l&apos;offre {data.plan}</h3>
                    <p>Son abonnement sera automatiquement renouvelé le {data.periodEndDate}</p>
                </Fragment>
            );
        case 'endingSubscription':
            return (
                <Fragment>
                    <h3>Cet utilisateur à opté pour l&apos;offre {data.plan}</h3>
                    <p>Son abonnement s&apos;arretera le {data.periodEndDate}</p>
                </Fragment>
            );
        case 'endedSubscription':
            return (
                <Fragment>
                    <h3>Cet utilisateur avait opté pour l&apos;offre {data.plan}</h3>
                    <p>Son abonnement a pris fin le {data.periodEndDate}</p>
                </Fragment>
            );
        case 'renewedTrial':
            return (
                <h3>Cet utilisateur bénéficie d&apos;une periode d&apos;essai jusqu&apos;au {data.periodEndDate}.</h3>
            );
        case 'failedFirstPayment':
            return (
                <Fragment>
                    <h3>Cet utilisateur à opté pour l&apos;offre {data.plan}</h3>
                    <p>Le prélèvement automatique à échoué mais de nouvelles tentatives vont avoir lieu</p>
                </Fragment>
            );
        case 'failedPayment':
            return (
                <Fragment>
                    <h3>Cet utilisateur à opté pour l&apos;offre {data.plan}</h3>
                    <p>Le prélèvement automatique à échoué, veuillez contacter le support</p>
                </Fragment>
            );
        case 'noSubscription':
            return <h3>Cet utilisateur à fini sa periode d&apos;essai le {data.periodEndDate}.</h3>;

        default:
            return null;
    }
};

SubscriptionInfos.propTypes = {
    subscriptionInfos: PropTypes.shape({
    }),
    type: PropTypes.string,
};

export default SubscriptionInfos;
