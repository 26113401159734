const wizard = {
    items: [
        {
            name: 'Mentions légales',
            target: 'legalDisclaimer/notices',
        },
        {
            name: 'Confidentialité',
            target: 'legalDisclaimer/confidentiality',
        },
        {
            name: 'Gestion des Cookies',
            target: 'legalDisclaimer/cookies',
        },
        {
            name: 'Conditions Générales de ventes',
            target: 'legalDisclaimer/cgv',
        },
    ],
};

export default wizard;
