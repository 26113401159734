import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Dropdown from 'components/Dropdown';
import Title from 'components/Title';
import { PercentageFloatField, TextField, IntegerField, MarkdownField } from 'components/form/field';
import Checkbox from 'components/form/Checkbox';
import sortSettings from 'common/utils/sortSettings';
import { DELETE } from 'utils/httpMethods';
import UploadSettingFiles from '../../UploadSettingFiles';
import SupportRepartition from './SupportRepartition/SupportRepartition';

const LifeInsuranceDropdown = ({ deleted, baseProducts, name, type }) => {
    const deleteSetting = useCallback(async (e) => {
        e.preventDefault();
        if (window.confirm('Supprimer le contrat ?')) {
            await DELETE(`/api/settings/simulation.lifeInsurance.types.${type}`);
            window.location.reload(true);
        }
    }, []);

    const uploadSetting = (field) => (
        <UploadSettingFiles documents={field.input.value} settingKey={`simulation.lifeInsurance.types.${type}`} onChange={field.input.onChange} />
    );

    return (
        <Dropdown title={`${deleted ? '(supprimé) ' : ''}${name}`} key={type}>
            <GridLayout className="double condensed full-width">
                <TextField
                    name={`simulation.lifeInsurance.types.${type}.name`}
                    title="Nom"
                />
                <IntegerField
                    name={`simulation.lifeInsurance.types.${type}.accountAvailability`}
                    title="Durée de disponibilité"
                />
                <PercentageFloatField
                    name={`simulation.lifeInsurance.types.${type}.fees.min`}
                    title="Frais d'entrée min"
                />
                <PercentageFloatField
                    name={`simulation.lifeInsurance.types.${type}.fees.max`}
                    title="Frais d'entrée max"
                />
                <IntegerField
                    name={`simulation.lifeInsurance.types.${type}.miniInitialFunding`}
                    title="Minimum versement initial"
                />
                <IntegerField
                    name={`simulation.lifeInsurance.types.${type}.miniScheduledPaymentAmount`}
                    title="Minimum versement programmé"
                />
            </GridLayout>
            {['P', 'E', 'D'].map((profile) => (
                <GridLayout className="condensed full-width">
                    <SupportRepartition
                        baseProducts={baseProducts}
                        baseName={`simulation.lifeInsurance.types.${type}.`}
                        profile={profile}
                    />
                    <IntegerField
                        name={`simulation.lifeInsurance.types.${type}.profiles.${profile}.minimumDuration`}
                        title="Durée minimum"
                    />
                </GridLayout>
            ))}
            <GridLayout className="double condensed full-width">
                <Title><b>Annexes PDF</b></Title>
                <Field name={`simulation.lifeInsurance.types.${type}.documents`} component={uploadSetting} />

                <div className="double">
                    <Title><b>Presentation</b></Title>
                    <MarkdownField name={`simulation.lifeInsurance.types.${type}.presentation`} />
                </div>
            </GridLayout>
            <Title><b>Souscription</b></Title>
            <Field
                title={<span>HUB Primonial</span>}
                component={Checkbox}
                name={`simulation.lifeInsurance.types.${type}.subscription`}
            />
            <hr />
            <button className="theseis-btn" onClick={deleteSetting}>Supprimer le contrat</button>
        </Dropdown>
    );
};

LifeInsuranceDropdown.propTypes = {
    baseProducts: PropTypes.shape({}).isRequired,
    deleted: PropTypes.bool,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

const LifeInsuranceTypes = ({ baseProducts, types }) => sortSettings(types)
    .map(([type, contract]) => (
        <LifeInsuranceDropdown
            baseProducts={baseProducts}
            type={type}
            key={type}
            {...contract}
        />
    ));

LifeInsuranceTypes.propTypes = {
    baseProducts: PropTypes.shape({}).isRequired,
    types: PropTypes.shape({}).isRequired,
};

export default LifeInsuranceTypes;
