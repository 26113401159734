import React from 'react';
import PropTypes from 'prop-types';
import HorizontalRadioButton from './HorizontalRadioButton';
import RadioGroup from './RadioGroup';

import './Scale.css';

const parse = (value) => (value ? parseInt(value, 10) : 0);

const Scale = ({ title, name, required, values, ...props }) => (
    <div className="scale">
        {title ? <span>{title}</span> : null}
        <RadioGroup name={name} parse={parse} {...props}>
            {values.map((value) => (
                <HorizontalRadioButton required={required} key={value.value} {...value} scale />
            ))}
        </RadioGroup>
    </div>
);

Scale.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        path: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.number,
    })),
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

Scale.defaultProps = {
    values: [],
    required: false,
    title: '',
};

export default Scale;
