import { get, lowerCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { branch, renderNothing } from 'recompose';
import { Item } from '../../summary';
import ProfileChart from './ProfileChart';
import profiles from './profiles';

const ProfileDescriptionInner = ({
    investorProfile,
    originalInvestorProfile,
    chartComponent: Chart,
    chartProps,
    noChart,
}) => {
    const profileData = profiles[investorProfile];
    const originalProfileData = profiles[originalInvestorProfile];

    return (
        <Fragment>
            <div className="investor-profile-resume">
                <div className="investor-profile-description">
                    <div className="investor-profile-description-inner">
                        <Item
                            title={`Investisseur ${profileData.label}`}
                            value={profileData.description}
                        />
                    </div>
                </div>
                {!noChart ? (
                    <div className="investor-profile-chart">
                        <Chart profileData={profileData} {...chartProps} />
                    </div>
                ) : null}
            </div>
            {originalInvestorProfile && originalInvestorProfile < investorProfile ? (
                <p className="investor-profile-warning">
                    Nous avons bien noté que vous êtes un investisseur au profil&nbsp;
                    <b>{get([originalInvestorProfile, 'label'], profiles)}</b> cependant pour cet investissement,&nbsp;
                    nous vous préconisons un profil <b>{profileData.label}</b>
                </p>
            ) : null}
            {originalInvestorProfile && originalInvestorProfile > investorProfile ? (
                <p className="investor-profile-warning">
                    Votre profil de risque global est {lowerCase(originalProfileData.label)} mais votre projet
                    représente <b>moins de 10%</b> de votre patrimoine global et vous souhaitez opter pour un
                    profil {lowerCase(profileData.label)} pour cette investissement.
                </p>
            ) : null}
        </Fragment>
    );
};

ProfileDescriptionInner.propTypes = {
    investorProfile: PropTypes.string.isRequired,
    originalInvestorProfile: PropTypes.string,
    chartComponent: PropTypes.func,
    chartProps: PropTypes.shape({}),
    noChart: PropTypes.bool,
};

ProfileDescriptionInner.defaultProps = {
    originalInvestorProfile: null,
    chartComponent: ProfileChart,
    chartProps: null,
    noChart: false,
};

const ProfileDescription = branch(({ investorProfile }) => !investorProfile, renderNothing)(ProfileDescriptionInner);

export default ProfileDescription;
