import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { set } from 'lodash/fp';
import { Field, formValues } from 'redux-form';
import { compose } from 'recompose';
import { Layout } from 'components/layouts';
import fullName from 'common/utils/fullName';
import { concatAddress } from 'utils/shiftAddresses';
import dataForm from '../dataForm';
import Checkbox from '../form/Checkbox';
import { GridLayout, VerticalLayout } from '../form/layout';
import { TextField, TextAreaField, UserPasswordField, AddressField } from '../form/field';
import { withSession } from '../sessions';
import formValidate from './formValidate';
import withRouter from '../withRouter';

import './TermsOfUse.scss';

const TermsOfUse = ({ handleSubmit, email, firstName, lastName, termsOfUseAgreement, session }) => (
    <Layout>
        <form
            role="presentation"
            onSubmit={handleSubmit}
        >
            <div>
                <div className="personalDetails container">
                    <p>Vous vous appelez <b>{fullName({ firstName, lastName })}</b>.</p>
                    <p>Votre adresse mail est <b>{email}</b>.</p>
                    <GridLayout>
                        <UserPasswordField required />
                        {!session.isMiddleUser && (
                            <Fragment>
                                <TextField
                                    name="phone"
                                    title="Votre numéro de téléphone mobile"
                                    placeholder="numéro de mobile"
                                />
                                <AddressField
                                    required={'external' === session.entity.contractType}
                                    title="Votre adresse postale"
                                />
                                <TextAreaField
                                    double
                                    name="greeting"
                                    title="Formule de politesse à insérer dans les mails envoyés aux clients"
                                />
                            </Fragment>
                        )}
                    </GridLayout>
                    <p>
                        <i>
                            Ces informations serviront à éditer votre signature sur les mails envoyés aux&nbsp;
                            clients.
                        </i>
                    </p>
                    <VerticalLayout>
                        <Field
                            title={(
                                <p>
                                    Je déclare avoir lu, compris et accepté les&nbsp;
                                    <a href={session.instanceIdsoft ? '/idsoft/cgu.pdf' : 'theseis/cgu.pdf'} target="_blank" rel="noreferrer">
                                        Conditions Générales d&apos;Utilisations
                                    </a>
                                    .
                                </p>
                            )}
                            component={Checkbox}
                            name="termsOfUseAgreement"
                            required
                        />
                    </VerticalLayout>
                    {termsOfUseAgreement ? (
                        <button className="sendButton" type="submit">Accéder à l&apos;E-RIP</button>
                    ) : null}
                </div>
            </div>
        </form>
    </Layout>
);

TermsOfUse.propTypes = {
    email: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    lastName: PropTypes.string.isRequired,
    termsOfUseAgreement: PropTypes.bool,
    session: PropTypes.shape({
        instanceIdsoft: PropTypes.bool,
        entity: PropTypes.shape({
            contractType: PropTypes.string,
        }),
        isMiddleUser: PropTypes.bool,
        instanceSettings: PropTypes.shape({
            label: PropTypes.string,
        }),
    }),
};

export default compose(
    withSession,
    withRouter,
    dataForm({
        autoSave: false,
        form: 'user-termsOfUse',
        type: 'user',
        validate: formValidate,
        id: ({ session }) => `${session.id}/personalDetails`,
        processValues: (values) => set('address', concatAddress(values), values),
        onSubmitSuccess: (values, dispatch, { session, navigate }) => {
            const { update: updateSession, ...data } = session;

            updateSession({
                ...data,
                termsOfUseAgreement: true,
                temporaryPassword: false,
            });

            navigate('/');
        },
    }),
    formValues({
        email: 'email',
        lastName: 'lastName',
        firstName: 'firstName',
        termsOfUseAgreement: 'termsOfUseAgreement',
    }),
)(TermsOfUse);
