import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';
import { connect } from 'react-redux';
import { setModal } from 'actions';

import './Modal.scss';

class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpened: false, isUsed: false };
    }

    static getDerivedStateFromProps({ modal }, { isUsed }) {
        const isOpened = !!modal.content;

        return {
            isOpened,
            isUsed: isUsed || isOpened,
        };
    }

    render() {
        const { modal: { content, closable }, closeModal } = this.props;
        const { isOpened, isUsed } = this.state;

        const containerClassNames = classNames(
            'erip-modal-container',
            isUsed ? ['animated', isOpened ? 'fadeIn' : 'fadeOut'] : 'hidden',
        );

        const modalClassNames = classNames(
            'erip-modal',
            'animated',
            isOpened ? 'slideInDown' : 'hidden',
        );

        return (
            <div className={containerClassNames}>
                <div className={modalClassNames}>
                    {closable && (
                        <Icon icon="times" className="cross" onClick={closeModal} />
                    )}
                    {isOpened && content(closeModal)}
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    modal: PropTypes.shape({
        content: PropTypes.func,
        closable: PropTypes.bool,
    }),
    closeModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
    modal: null,
};

export default connect(
    ({ modal }) => ({ modal }),
    (dispatch) => ({ closeModal: () => dispatch(setModal(null)) }),
)(Modal);
