import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from 'components/form/layout';
import { ChoiceField, DateField, PercentageField, IntegerField } from 'components/form/field';
import { periodicities } from 'common/choices';

const RecurrentEvent = ({ name, type }) => (
    <HorizontalLayout>
        <DateField title="Date de début" name={`${name}.startDate`} />
        <DateField title="Date de fin" name={`${name}.endDate`} />
        <ChoiceField
            name={`${name}.periodicity`}
            title="Périodicité"
            choices={periodicities}
        />
        <IntegerField title="Montant" name={`${name}.amount`} />
        {'payment' === type ? (
            <PercentageField title="Frais d&apos;entrée (%)" name={`${name}.fees`} />
        ) : null}
    </HorizontalLayout>
);

RecurrentEvent.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
};

RecurrentEvent.defaultProps = {
    type: null,
};

export default RecurrentEvent;
