import PropTypes from 'prop-types';
import React from 'react';
import { FaLi, FaUl } from '../../common/components/fa';
import { useInstanceSettings } from '../sessions';

import './Contact.css';

const mailto = (email) => (email ? <a href={`mailto:${email}`}>{email}</a> : 'Non renseigné');

const Contact = ({
    fullName, isCouple, mobile, partnerFullName, partnerMobile, partnerPersonalEmail, personalEmail,
    contactOrigin, contactOriginOther, editContactOrigin,
}) => {
    const { contactOriginTypes } = useInstanceSettings();

    return (
        <tr className="contact">
            <td className="contact-background" />
            <td colSpan={3}>
                <div className="rip-contact-items">
                    <FaUl>
                        <FaLi icon="userN">{fullName}</FaLi>
                        <FaLi icon="phone">{mobile || 'Non renseigné'}</FaLi>
                        <FaLi icon="envelope">{mailto(personalEmail)}</FaLi>
                    </FaUl>
                    {isCouple ? (
                        <FaUl>
                            <FaLi icon="userN">{partnerFullName || 'Non renseigné'}</FaLi>
                            <FaLi icon="phone">{partnerMobile || 'Non renseigné'}</FaLi>
                            <FaLi icon="envelope">{mailto(partnerPersonalEmail)}</FaLi>
                        </FaUl>
                    ) : null}
                </div>
                <div className="rip-contact-items">
                    <FaUl>
                        <FaLi icon="assistive-listening-systems">
                            Origine du contact : {contactOriginTypes[contactOrigin]}
                            {'other' === contactOrigin && contactOriginOther ? ` (${contactOriginOther})` : null}
                        </FaLi>
                    </FaUl>
                    <FaUl className="contact-origin-edit">
                        <FaLi icon="pencil" onClick={editContactOrigin}>Editer</FaLi>
                    </FaUl>
                </div>
            </td>
        </tr>
    );
};

Contact.propTypes = {
    fullName: PropTypes.string.isRequired,
    isCouple: PropTypes.bool.isRequired,
    mobile: PropTypes.string,
    partnerFullName: PropTypes.string,
    partnerMobile: PropTypes.string,
    partnerPersonalEmail: PropTypes.string,
    personalEmail: PropTypes.string,
    contactOrigin: PropTypes.string,
    contactOriginOther: PropTypes.string,
    editContactOrigin: PropTypes.func.isRequired,
};

Contact.defaultProps = {
    mobile: null,
    partnerFullName: null,
    partnerMobile: null,
    partnerPersonalEmail: null,
    personalEmail: null,
    contactOrigin: null,
    contactOriginOther: null,
};

export default Contact;
