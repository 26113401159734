import React from 'react';
import PropTypes from 'prop-types';

import Group from 'components/form/Group';
import RadioGroup from 'components/form/RadioGroup';
import VerticalRadioButton from 'components/form/VerticalRadioButton';
import { shouldPartnerSign as hasPartnerActive } from '../../../../../common/src/utils/hasPartner';
import Title from '../../Title';
import './SelectContact.scss';

const SelectContact = ({ rip, isCif, isSimulation }) => {
    const simulationPrecoTarget = isSimulation && ('cohabitation' === rip.familySituation && rip.dualRip);

    return (
        <div className="">
            {((hasPartnerActive(rip) && isCif) || (simulationPrecoTarget)) && (
                <fieldset className="selectContact">
                    <Title>Pour quel contact souhaitez-vous générer le document ?*</Title>
                    <Group name="target">
                        <RadioGroup
                            name="target"
                            title="Choix de la préconisation"
                            className="container-vertical-radio"
                        >
                            <VerticalRadioButton
                                className="owner"
                                title={`${rip.firstName} ${rip.lastName} (client)`}
                                value="client"
                            />
                            <VerticalRadioButton
                                className="owner"
                                title={`${rip.partnerFirstName} ${rip.partnerLastName} (conjoint)`}
                                value="partner"
                            />
                        </RadioGroup>
                    </Group>
                </fieldset>
            )}
        </div>
    );
};

SelectContact.propTypes = {
    rip: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        partnerFirstName: PropTypes.string.isRequired,
        partnerLastName: PropTypes.string.isRequired,
        familySituation: PropTypes.string.isRequired,
        dualRip: PropTypes.bool.isRequired,
    }),
    isCif: PropTypes.bool.isRequired,
    isSimulation: PropTypes.bool.isRequired,
};

export default SelectContact;
