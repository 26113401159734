import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setDisplayName, wrapDisplayName } from 'recompose';

const withRouter = (Component) => {
    const ComponentWithRouterProp = (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
                location={location}
                navigate={navigate}
                params={params}
            />
        );
    };

    return setDisplayName(wrapDisplayName(Component, 'withRouter'))(ComponentWithRouterProp);
};

export default withRouter;
