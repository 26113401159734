import PropTypes from 'prop-types';
import React from 'react';

const getPath = (x, y, width, height) => {
    const center = x + (width / 2);

    return `M${center},${y + height} L${center},${y}`;
};

const RoundBar = ({ fill, x, y, width, height }) => (
    <path d={getPath(x, y, width, height)} strokeWidth={width} stroke={fill} strokeLinecap="round" />
);

RoundBar.defaultProps = {
    fill: '#000',
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};

RoundBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default RoundBar;
