import { flow, filter, has, map, sum } from 'lodash/fp';
import getSettings from '../../../utils/settings';

const computeInvestment = (duration) => flow([
    filter(({ type, parts }) => has(type, getSettings('simulation.dismemberedScpi')) && parts),
    map(({ type, parts }) => {
        const value = getSettings(['simulation', 'dismemberedScpi', type, 'value']);
        const bareOwnership = getSettings(['simulation', 'dismemberedScpi', type, 'share', duration, 'bareOwnership']);

        return parts * value * bareOwnership;
    }),
    sum,
]);

export default computeInvestment;
