import PropTypes from 'prop-types';
import React from 'react';
import { fieldPropTypes } from 'redux-form';
import Error from 'components/Error';
import { connectDisabledForm } from './withDisabledForm';

import './Checkbox.css';

const Checkbox = ({ className, input: { value, ...input }, title, meta, disabled, ...props }) => (
    <div className="form-check simple-checkbox">
        <label htmlFor={input.name} className="form-check-label">
            <div>
                <input
                    className="form-check-input"
                    id={input.name}
                    {...input}
                    {...props}
                    checked={value}
                    type="checkbox"
                    disabled={disabled}
                />
            </div>
            {'string' === typeof title ? <p>{title}</p> : title}
        </label>
        <Error {...meta} />
    </div>
);

Checkbox.propTypes = {
    ...fieldPropTypes,
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
    className: '',
};

export default connectDisabledForm(Checkbox);
