import { flowRight, identity, isEqual, pickBy } from 'lodash/fp';
import { reduxForm } from 'redux-form';
import { saveData } from 'actions';
import withFormValidationConsumer from './form/withFormValidationConsumer';
import dataProvider from './dataProvider';

const getDiff = (values, previousValues) => pickBy((value, key) => !isEqual(value, previousValues[key]), values);

const autoSaveOptions = (id, type, processValues) => {
    let timeOut = null;
    let startValues = null;

    const onChange = (values, dispatch, props, previousValues) => {
        if (props.disabled) {
            return;
        }

        if (null === startValues) {
            startValues = previousValues;
        }

        if (null !== timeOut) {
            clearTimeout(timeOut);
            timeOut = null;
        }

        timeOut = setTimeout(() => {
            const diff = getDiff(processValues(values), processValues(startValues));
            if (0 !== Object.keys(diff).length) {
                dispatch(saveData(type, id(props), { ...diff, page: window.location.href.split('/').pop() }));
            }

            timeOut = null;
            startValues = null;
        }, 500);
    };

    return {
        onChange,
        onSubmit: identity,
    };
};

const submitSaveOptions = (id, type, processValues) => {
    const onSubmit = (values, dispatch, props) => {
        if (props.disabled) {
            return true;
        }

        return dispatch(saveData(type, id(props), processValues(values)));
    };

    return {
        onSubmit,
    };
};

const dataForm = ({
    autoSave = true,
    id,
    type,
    // This one is for processing data from form to redux store
    processValues = identity,
    // This one is for processing data from redux store to form
    processData,
    // Prop to be passed to the dataProvider
    prop = 'initialValues',
    ...options
}) => flowRight([
    withFormValidationConsumer,
    dataProvider({ id, type, prop, wait: true, processData }),
    reduxForm({
        ...(autoSave ? autoSaveOptions : submitSaveOptions)(id, type, processValues),
        ...options,
    }),
]);

export default dataForm;
