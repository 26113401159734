import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import fullName from 'common/utils/fullName';
import { Item } from 'components/list';
import LogDetails from './LogDetails';
import './ListItem.scss';

const ListItem = ({
    item: {
        source,
        details,
        user,
        entity,
        userId,
        entityId,
    },
}) => {
    const { id, type, path, label } = useMemo(() => (user
        ? ({ id: userId, type: 'utilisateur', path: '/user', label: fullName(user) })
        : ({ id: entityId, type: 'entité', path: '/entity', label: entity.name })
    ), [userId, entityId, user]);

    return (
        <Item>
            <td>{type}</td>
            <td>{source}</td>
            <td>
                <Link to={`/admin${path}/${id}`}>
                    {label}
                </Link>
            </td>
            <LogDetails details={details} />
        </Item>
    );
};

ListItem.propTypes = {
    item: PropTypes.shape({
        source: PropTypes.string,
        details: PropTypes.shape({}),
        user: PropTypes.string,
        entity: PropTypes.string,
        userId: PropTypes.string,
        entityId: PropTypes.string,
    }).isRequired,
};

export default ListItem;
