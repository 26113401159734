import { isEmpty } from 'lodash/fp';
import { connect } from 'react-redux';
import { lifecycle, branch, renderNothing, withState, withProps, compose } from 'recompose';
import { loadDataList } from 'actions';
import { GET } from 'utils/httpMethods';
import withInstanceSettings from '../../../sessions/withInstanceSettings';
import withRouter from '../../../withRouter';

const userProvider = (getId) => compose(
    withRouter,
    connect(null, {
        loadDataList,
    }),
    lifecycle({
        componentDidMount() {
            this.props.loadDataList('user');
            this.props.loadDataList('entity');
        },
    }),
    connect((state, props) => ({
        user: state.data.user[getId(props)],
    })),
    branch(({ user }) => isEmpty(user), renderNothing),
    withState('subscriptionInfos', 'setSubscriptionInfos', null),
    withProps(({ setSubscriptionInfos }) => ({
        syncSubscriptionInfos: async (userId) => {
            const response = await GET(`/api/payment/subscriptions/${userId}`);

            const subscriptionInfos = await response.json();

            setSubscriptionInfos(subscriptionInfos);
        },
    })),
    withState('cddManager', 'setCddManager', null),
    withProps(({ setCddManager }) => ({
        syncCddManagerInfos: async (userId, parentEntityId) => {
            const response = await GET(`/api/entitiesCddManagers/${userId}/${parentEntityId}`);
            const data = await response.json();

            setCddManager(data.items.map(({ entityId }) => entityId));
        },
        syncCddManagerInfosUpdate: (data) => {
            setCddManager(data);
        },
    })),
    withInstanceSettings,
    lifecycle({
        async componentWillMount() {
            if (this.props.user.id && this.props.user.entityId) {
                await this.props.syncCddManagerInfos(this.props.user.id, this.props.user.entityId);
            }
        },
        async componentDidMount() {
            if (this.props.instanceSettings.hasStripeSubscription) {
                await this.props.syncSubscriptionInfos(this.props.user.id);
            }
        },
    }),
);

export default userProvider;
