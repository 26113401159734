import { getOr } from 'lodash/fp';
import { checkSupportRepartition, checkRequiredField, checkRequiredIntegerField } from '../lib';
import { checkSupportRepartitionProgramme } from '../lib/checkSupportRepartitionProgramme';

export const buildPerBaseProductsValidation = (prefix = '') => [
    checkRequiredField(`${prefix}label`),
    checkRequiredField(`${prefix}lowRate`),
    checkRequiredField(`${prefix}rate`),
    checkRequiredField(`${prefix}highRate`),
    checkRequiredField(`${prefix}isEuroSupport`),
    checkRequiredField(`${prefix}codeIsin`),
];

export const buildPerContractsValidation = (prefix = '') => [
    checkRequiredField(`${prefix}name`),
    checkRequiredIntegerField(`${prefix}fees.min`),
    checkRequiredIntegerField(`${prefix}fees.max`),
    checkRequiredIntegerField(`${prefix}miniInitialFunding`),
    checkRequiredIntegerField(`${prefix}miniScheduledPaymentAmount`),
    ...['P', 'E', 'D'].map((profile) => checkSupportRepartition(`${prefix}profiles.${profile}.repartition`)),
    ...['P', 'E', 'D'].map((profile) => checkSupportRepartitionProgramme(`${prefix}profiles.${profile}.repartition`)),
];

export const basePerValidators = (product) => buildPerBaseProductsValidation(`simulation.per.baseProducts.${product}.`);
export const contractValidators = (type) => buildPerContractsValidation(`simulation.per.contracts.${type}.`);

const validators = (settings) => {
    const baseProducts = Object.keys(getOr({}, 'simulation.per.baseProducts', settings));
    const contracts = Object.keys(getOr({}, 'simulation.per.contracts', settings));

    return [
        ...baseProducts.reduce((acc, product) => [
            ...acc,
            ...basePerValidators(product),
        ], []),
        ...contracts.reduce((acc, type) => [
            ...acc,
            ...contractValidators(type),
        ], []),
    ];
};

export default validators;
