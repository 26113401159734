import { isCouple } from 'common/utils/hasPartner';

export const hasSignedElectronically = (rip) => (
    (!!rip.universignTransaction && 'completed' === rip.universignTransactionState)
);

export const hasSignedManually = (rip) => (
    !!(rip.signature && rip.advisorSignature && (
        !isCouple(rip.familySituation) || rip.partnerSignature
    ))
);

export const hasOngoingSignatureProcess = (rip) => (
    (!!rip.universignTransaction && ['completed', 'ready', 'canceled'].includes(rip.universignTransactionState))
);

const hasSigned = (rip) => (
    hasSignedElectronically(rip) || hasSignedManually(rip)
);

export default hasSigned;
