import { isEmpty, has, filter, flow, map, size } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Icon from 'common/components/Icon';
import { Layout } from 'components/layouts';
import useRouter from './useRouter';
import ErrorMessage from './ErrorMessage';
import withRouter from './withRouter';

import './PdfErrorPage.scss';
import withInstanceSettings from './sessions/withInstanceSettings';

const formSteps = {
    'rip-edit': 'Etat-civil',
    'rip-taxes': 'Revenus & Impôts',
    'rip-finances': 'Patrimoine Financier',
    'rip-estate': 'Patrimoine Immo & Crédits',
    'rip-profil': 'Objectifs de vie & Profil client',
};

const formatSteps = flow([
    Object.entries,
    filter(([step, errors]) => !isEmpty(errors) && has(step, formSteps)),
    map(([step, errors]) => (
        <li key={step}>
            {formSteps[step]} <i className="text-muted">(&nbsp;{size(errors)} erreur(s)&nbsp;)</i>
        </li>
    )),
]);

const getPdfBody = ({ type, data }) => {
    switch (type) {
        case 'invalidForm': {
            const { count, form = {} } = data;

            const stepsToCheck = formatSteps(form);

            return (
                <Fragment>
                    <p>
                        {count} erreur(s) détectée(s), vérifiez si tous les champs ont été correctement remplis et
                        réessayer.
                    </p>
                    {!isEmpty(stepsToCheck) && <ul>{stepsToCheck}</ul>}
                </Fragment>
            );
        }
        case 'irr':
            return (
                <p>
                    Un problème est survenu sur le calcul du taux de rendement interne, vérifiez si tous les champs ont
                    été correctement remplis et réessayer.
                </p>
            );
        case 'docx-templates-user-error':
            return <p>{data.message || 'Veuillez vérifier la validité du document fournis'}</p>;
        case 'docx-templates-server-error':
        case 'internal':
        default:
            return <p>Une erreur interne est survenue, merci de réessayer ultérieurement.</p>;
    }
};

const getPdfHeader = ({ type }) => {
    switch (type) {
        case 'docx-templates-user-error':
        case 'docx-templates-server-error':
            return <h1>La création du document CIF a échoué…</h1>;
        case 'internal':
        default:
            return <h1>La création du pdf a échoué…</h1>;
    }
};

const PdfErrorPage = ({ error, instanceSettings: { emailError } }) => {
    const { navigate } = useRouter();

    return (
        <Layout>
            <ErrorMessage>
                <div className="pdf-error-page">
                    {getPdfHeader(error)}
                    {getPdfBody(error)}
                    <p>
                        Si le problème persiste vous pouvez contacter par email &agrave;&nbsp;:&nbsp;
                        <b>{emailError}</b>
                    </p>
                    <div className="buttons">
                        <button className="left-button" onClick={() => navigate(-1)}>
                            <Icon icon="arrow-left" />Page précédente
                        </button>
                        <button onClick={() => navigate('/')}>
                            <Icon icon="home" />Accueil
                        </button>
                    </div>
                </div>
            </ErrorMessage>
        </Layout>
    );
};

PdfErrorPage.propTypes = {
    error: PropTypes.shape({
        type: PropTypes.string,
    }).isRequired,
    instanceSettings: PropTypes.shape({
        emailError: PropTypes.string,
    }).isRequired,
};

export default compose(
    withRouter,
    withInstanceSettings,
    connect(({ pdfError: error }) => ({ error })),
)(PdfErrorPage);
