import { filter, isArray, isObject } from 'lodash/fp';

const collections = [
    'charges',
    'children',
    'deductions',
    'dependents',
    'familyEvents',
    'incomes',
    'property',
    'reductions',
    'taxCredits',
    'taxEvents',
    'sci',
    'investments',
    'incomesN1',
];

const normalizeCollection = (collection) => (isArray(collection) ? filter(isObject, collection) : []);

const normalize = (rip) => {
    const normalized = {};

    collections.forEach((collection) => {
        normalized[collection] = normalizeCollection(rip[collection]);
    });

    return {
        ...rip,
        ...normalized,
    };
};

export default normalize;
