import React from 'react';
import { CreateLink } from 'components/list';
import { SearchLayout } from 'components/layouts';
import UserTable from './UserTable';

import './List.scss';

const UserList = () => (
    <SearchLayout className="user-list" searchName="user" searchPlaceholder="Rechercher un utilisateur">
        <UserTable />
        <CreateLink to="/admin/user/create" />
    </SearchLayout>
);

export default UserList;
