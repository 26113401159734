import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { withSession } from 'components/sessions';
import { SubmissionError } from 'redux-form';
import { PATCH, POST, DELETE } from 'utils/httpMethods';
import { setModal } from 'actions';
import { Layout } from 'components/layouts';
import EditForm from './EditForm';
import userProvider from './userProvider';
import MiddleOfficeError from '../../MiddleOfficeError';

const updateCddManager = async ({ id, body }) => {
    if (body) {
        // Filtrer et obtenir les inputs qui commencent par 'input#'
        const inputs = Object.keys(body)
            .filter(key => key.startsWith('input#'))
            .reduce((acc, key) => {
                acc[key] = body[key];

                return acc;
            }, {});

        // Créer un tableau de promesses pour les requêtes DELETE et POST
        const promises = Object.entries(inputs).map(async ([inputKey, inputValue]) => {
            const entityIdEntityParentId = inputKey.replace('input#', '').trim();
            const entityId = entityIdEntityParentId.split('#')[0];
            const entityParentId = entityIdEntityParentId.split('#')[1];

            if (false === inputValue) {
                try {
                    return DELETE(`/api/entitiesCddManagers/${id}/${entityParentId}/${entityId}`);
                } catch (error) {
                    if (error.response) {
                        const data = await error.response.json();
                        throw new SubmissionError({ _error: data.message });
                    } else {
                        throw new Error('Erreur réseau ou serveur.');
                    }
                }
            } else {
                try {
                    return POST(`/api/entitiesCddManagers/${id}/${entityParentId}/${entityId}`);
                } catch (error) {
                    if (error.response) {
                        const data = await error.response.json();
                        throw new SubmissionError({ _error: data.message });
                    } else {
                        throw new Error('Erreur réseau ou serveur.');
                    }
                }
            }
        });

        // Attendre que toutes les promesses soient terminées
        await Promise.all(promises);
    }
};

const backToUsers = async (navigate, session) => {
    await session.sync();
    navigate('/admin/user');
};

const handleSubmit = async ({ id, ...body }) => {
    await updateCddManager({ id, body });

    try {
        const response = await PATCH(`/api/users/${id}`, { body });

        return response.json();
    } catch (error) {
        const data = await error.response.json();
        throw new SubmissionError({ _error: data.message });
    }
};
const resetPassword = (id) => () => PATCH(`/api/users/${id}/resetPassword`)
    .then(() => alert(`Mot de passe en cours de réinilitialisation,\n
                       consulter vos mails pour continuer la procédure.`))
    .catch(() => alert('Échec lors de la réinitialisation du mot de passe. Veuillez réessayer.'));

const Edit = ({ params: { id }, user, navigate, session, subscriptionInfos, syncSubscriptionInfos, cddManager, syncCddManagerInfos, syncCddManagerInfosUpdate }) => (
    <Layout hasFooter>
        <EditForm
            resetPassword={resetPassword(id)}
            onSubmit={handleSubmit}
            onSubmitSuccess={() => backToUsers(navigate, session)}
            onSubmitFail={({ _error }, dispatch) => _error && dispatch(setModal(() => <MiddleOfficeError error={_error} />))}
            initialValues={user}
            subscriptionInfos={subscriptionInfos}
            syncSubscriptionInfos={syncSubscriptionInfos}
            cddManager={cddManager}
            syncCddManagerInfos={syncCddManagerInfos}
            syncCddManagerInfosUpdate={syncCddManagerInfosUpdate}
        />
    </Layout>
);

Edit.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({}),
    navigate: PropTypes.shape({}).isRequired,
    subscriptionInfos: PropTypes.shape({}),
    syncSubscriptionInfos: PropTypes.func.isRequired,
    cddManager: PropTypes.shape({}),
    syncCddManagerInfos: PropTypes.func.isRequired,
    syncCddManagerInfosUpdate: PropTypes.func.isRequired,
    session: PropTypes.shape({
        sync: PropTypes.func,
        permissions: PropTypes.shape({
            isCaseManager: PropTypes.func,
        }),
    }),
};

Edit.defaultProps = {
    user: null,
};
const provider = compose(
    withSession,
    userProvider(({ params: { id } }) => id),
)(Edit);

export default provider;
