/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { get } from 'lodash/fp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { useEffect, Fragment, useState } from 'react';
import { pure, compose } from 'recompose';
import { connect } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';

import { ripStates } from 'common/choices';
import Icon from 'common/components/Icon';
import fullName from 'common/utils/fullName';
import { saveData, setModal } from 'actions';
import { Item, Initials, InitialsErrorBadge, Date as DateComponent, Actions, Action, connectActiveItem, SignaturesState } from '../list';
import Contact from './Contact';
import DownloadRipSelection from './DownloadRipSelection';
import EditContactOriginForm from './EditContactOriginForm';
import { useOnline } from '../withOnline';
import CallbackForm from './CallbackForm';
import RipState from './RipState';
import { useSession } from '../sessions';
import { GET, POST } from '../../utils/httpMethods';
import Logs from './Logs';
import SelectRecipientsForExport from './SelectRecipientsForExport';
import FieldInfoAction from '../form/FieldInfoAction';
import SelectRecipientsForShoyo from './SelectRecipientsForShoyo';
import withRouter from '../withRouter';
import { isCouple } from '../../../../common/src/utils/hasPartner';

const changesTitle = (hasChanges) => (hasChanges
    ? 'Des données sont en attente d\'enregistrement sur le serveur'
    : 'Les données ont bien été enregistrées sur le serveur'
);

const ListItemInner = ({
    isActive,
    contactDisplayed,
    show,
    item: rip,
    mode,
    onClickDelete,
    onClickArchive,
    onClickActivate,
    onClickExport,
    onClickShoyo,
    stateDisplayed,
    historyDisplayed,
    updateState,
    download,
    editContactOrigin,
    setRipAEnlever,
}) => {
    const onLine = useOnline();
    const session = useSession();

    const { shoyoToken } = session.shoyo;
    const { instanceTheseis } = session;

    const [historyLogs, setHistoryLogs] = useState('');
    const [displayLogs, setDisplayLogs] = useState(false);
    const [completude, setCompletude] = useState(null);
    const [completudeNumber, setCompletudeNumber] = useState('');
    const [isMiddle, setIsMiddle] = useState(false);

    const {
        id,
        firstName,
        hasChanges,
        lastName,
        createdAt,
        updatedAt,
        mobile,
        personalEmail,
        familySituation,
        partnerFirstName,
        partnerLastName,
        partnerMobile,
        partnerPersonalEmail,
        user,
        contactOrigin,
        contactOriginOther,
        errorCount,
        state,
        signatureDate,
        minified,
        idBoxShoyo,
        userId,
        statusBoxShoyo,
        clientAndPartnerSignatureDate,
    } = rip;

    useEffect(() => {
        if (instanceTheseis) {
            (async () => {
                try {
                    const idDuConseiller = 'user' === mode ? userId : user.id;

                    if (!idDuConseiller) {
                        throw new Error("L'ID du conseiller n'est pas défini");
                    }

                    const response = await GET(`/api/users/${idDuConseiller}/advisor`);
                    const conseillerDuRip = await response.json();

                    setIsMiddle(conseillerDuRip.middlePartnerId !== null);
                } catch (error) {
                    console.error('Erreur lors de la récupération des informations du conseiller:', error);
                }
            })();

            if (statusBoxShoyo) {
                setCompletude('incomplete');
            }
            if (idBoxShoyo) {
                POST(`/api/contracts/${id}/shoyo/box/rate`, { body: { id: idBoxShoyo, shoyoToken } })
                    .then(async (donnees) => {
                        const data = await donnees.json();
                        if (100 === Math.round(data)) {
                            setCompletude('complete');
                        }
                        if (Math.round(data) < 60) {
                            setCompletude('incomplete');
                        }
                        if (Math.round(data) >= 60 && Math.round(data) < 100) {
                            setCompletude('halfcomplete');
                        }
                        setCompletudeNumber(Math.round(data));
                    });
            }
        }

        const idTargetEsigneClient = rip.recommendationsCIF && rip.recommendationsCIF.LODEOMCIF && rip.recommendationsCIF.LODEOMCIF.idEnvelopeESigne;
        const idTargetEsigneClientPartner = rip.partner && rip.partner.recommendationsCIF && rip.partner.recommendationsCIF.LODEOMCIF && rip.partner.recommendationsCIF.LODEOMCIF.idEnvelopeESigne;
        if ((idTargetEsigneClient || idTargetEsigneClientPartner) && onLine) {
            POST(`/api/contracts/${rip.id}/eSigne/envelopes/getAndUpdateStatusEsigne`, { body: { session } })
                .then(async (response) => { await response.json(); })
                .catch((error) => {
                    console.log('Erreur getAndUpdateStatusEsigne :', error);
                });
        }

        const offLine = clientAndPartnerSignatureDate && clientAndPartnerSignatureDate.offLine;

        if (instanceTheseis && 0 === rip.errorCount && rip.signature && offLine) {
            if (!rip.idBoxShoyo) {
                try {
                    POST(`/api/contracts/${rip.id}/shoyo/box/offline`);
                } catch (error) {
                    console.error('error creation offline ', error);
                }
            } else {
                try {
                    POST(`/api/contracts/${rip.id}/shoyo/box/updateOffline`);
                } catch (error) {
                    console.error('error update offline ', error);
                }
            }
        }
    }, []);

    const isAvailable = !minified || onLine;

    const loadHistory = async () => {
        if (historyDisplayed) {
            show('off');
        } else {
            let histories = await GET(`/api/rips/${id}/history`);
            histories = await histories.json();
            const output = [];
            setHistoryLogs(histories.items);
            const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
            const toDate = (e) => new Date(e.date).getTime();
            const byValue = (a, b) => a - b;
            const byDate = sortByMapped(toDate, byValue);

            histories.items.sort(byDate).filter((item) => {
                if ('new' !== item.page) {
                    if (item.page.split('reaffectation').length > 1) {
                        output.push({ change: `${item.date.split('-').reverse().join('/')} - Réattribué de ${item.page.split('-')[2]} à ${item.page.split('-')[1]}`, par: item.user });
                    } else {
                        output.push({ change: `${item.date.split('-').reverse().join('/')} - ${capitalize(item.page)} : ${item.count} modification${item.count > 1 ? 's' : ''}`, par: item.user });
                    }
                }

                return true;
            });
            if (output.length > 0) {
                setDisplayLogs(true);
            }
            setHistoryLogs(output);
            show('history');
        }
    };

    let buttonIsHidden = true;
    if (session.permissions.isCaseManager() && instanceTheseis) {
        buttonIsHidden = false;
    }

    const isSignatureStateEnabled = useFlag('erip-427-signatureState');
    const isStatusBoxShoyoEnabled = useFlag('erip-1107-shoyo');

    const isShoyoCreateBoxFailedForClient = rip.statusBoxShoyo && rip.statusBoxShoyo.client && rip.statusBoxShoyo.client.statusCode;

    const tooltipShoyo = () => {
        if (idBoxShoyo) {
            return (
                `Coffre-fort complété à ${completudeNumber}%`
            );
        }

        return null;
    };

    return (

        <Fragment>
            <Item
                key={id}
                displayActions={isActive}
                onFocus={!isActive ? () => show() : null}
            >
                <Initials firstName={firstName} lastName={lastName}>
                    {null !== errorCount && (
                        <InitialsErrorBadge
                            errorCount={errorCount}
                            signatureDate={signatureDate}
                            isAvailable={isAvailable}
                        />
                    )}
                </Initials>
                <td>{fullName({ firstName, lastName })}</td>
                {['all', 'allForManager'].includes(mode) && (
                    <Fragment>
                        <td className="rip-user">{fullName(user)}</td>
                        <td>{get('entity.name', user)}</td>
                    </Fragment>
                )}
                <DateComponent date={createdAt} />
                <DateComponent date={updatedAt} />
                {isSignatureStateEnabled ? (!['all', 'allForManager'].includes(mode) ? (
                    <Fragment>
                        <td className="rip-list-state-button">
                            <button
                                className={classNames('transparent', (state || 'ongoing'))}
                                onFocus={() => isAvailable && show('state')}
                            >
                                <RipState rip={rip} />
                            </button>
                        </td>
                    </Fragment>
                ) : (
                    null
                )) : (
                    <Fragment>
                        <td className="rip-list-state-button">
                            <button
                                className={classNames('transparent', (state || 'ongoing'))}
                                onFocus={() => isAvailable && show('state')}
                            >
                                <RipState rip={rip} />
                            </button>
                        </td>
                    </Fragment>
                )}
                {isSignatureStateEnabled && (['all', 'allForManager', 'user'].includes(mode)) && (
                    <SignaturesState rip={rip} />
                )}
                <td><Icon icon={hasChanges ? 'times' : 'check'} title={changesTitle(hasChanges)} /></td>
                <Actions>
                    {stateDisplayed
                        ? (
                            <div className="rip-state-buttons">
                                {Object.entries(ripStates).map(([ripState, label]) => (
                                    <button
                                        key={ripState}
                                        className={classNames(ripState, { active: ripState === state })}
                                        onClick={updateState(ripState)}
                                    >
                                        {label}
                                    </button>
                                ))}
                            </div>
                        )
                        : (
                            <Fragment>
                                <Action
                                    to={`/rip/${id}/etat-civil`}
                                    icon="pencil"
                                    text="modifier"
                                    disabled={!isAvailable}
                                />
                                {
                                    errorCount || !onLine ?
                                        <FieldInfoAction id="downloadRip" icon="file-pdf-o" text="télécharger">
                                            <div>
                                                <p>
                                                    {!onLine ? 'Vous devez être connecté à internet pour télécharger le RIP' : 'RIP incomplet' }
                                                </p>
                                            </div>
                                        </FieldInfoAction>
                                        :
                                        <Action
                                            onClick={download}
                                            icon="file-pdf-o"
                                            text="télécharger"
                                            disabled={!onLine || errorCount}
                                        />
                                }
                                <Action
                                    onClick={() => show('contact')}
                                    icon="comments-o"
                                    text="contact"
                                    disabled={!isAvailable}
                                />
                                <Action
                                    onClick={() => loadHistory()}
                                    icon="history"
                                    text="Historique"
                                    disabled={!isAvailable}
                                />
                                <Action
                                    to={`/rip/${id}/simulations`}
                                    icon="calculator"
                                    text="simulation"
                                    disabled={!onLine}
                                    nonVisible={false === session.instanceSettings.canDoSimulation}
                                />
                                <Action
                                    to={`/rip/${id}/documents`}
                                    icon="file-word-o"
                                    text="document"
                                    disabled={!onLine}
                                />
                                {
                                    errorCount || !isMiddle ?
                                        <FieldInfoAction id="exportPrismePopover" buttonIsHidden={buttonIsHidden} icon="upload" text="export">
                                            <div>
                                                <p>
                                                    {errorCount ? 'RIP incomplet' : 'Conseiller du RIP inexistant dans le middle'}
                                                </p>
                                            </div>
                                        </FieldInfoAction>
                                        :
                                        <Action
                                            className="rip-list-state-button"
                                            onClick={onClickExport}
                                            icon="upload"
                                            text="export"
                                            nonVisible={buttonIsHidden}
                                        />
                                }
                                {instanceTheseis && idBoxShoyo &&
                                    <OverlayTrigger
                                        trigger={['hover', 'click']}
                                        overlay={<Popover className="form-field-info-popover" placement="right" id={id}>{tooltipShoyo()}</Popover>}
                                        rootClose
                                    >
                                        <span>
                                            <Action
                                                onClick={onClickShoyo}
                                                icon="box"
                                                statut={completude}
                                                iconNew="true"
                                                text="eDoc"
                                            />
                                        </span>
                                    </OverlayTrigger>
                                }
                                {/* //* On affiche le picto sans popover pour les erreurs de création de box shoyo */}
                                {instanceTheseis && isStatusBoxShoyoEnabled && isShoyoCreateBoxFailedForClient && !idBoxShoyo &&
                                    <span>
                                        <Action
                                            onClick={onClickShoyo}
                                            icon="box"
                                            statut={completude}
                                            iconNew="true"
                                            text="eDoc"
                                        />
                                    </span>

                                }

                                {false && (
                                    <Action
                                        to={`/rip/${id}/contracts`}
                                        icon="pencil"
                                        text="souscriptions"
                                        disabled={!onLine}
                                    />
                                )}
                                <Action
                                    onClick={() => { onClickDelete(); setRipAEnlever(id); }}
                                    icon="trash-o"
                                    text="supprimer"
                                    right
                                />
                                {'archives' === mode ? (
                                    <Action
                                        onClick={() => { onClickActivate(); setRipAEnlever(id); }}
                                        icon="archive"
                                        text="activer"
                                        right
                                    />
                                ) : (
                                    <Action
                                        onClick={() => { onClickArchive(); setRipAEnlever(id); }}
                                        icon="archive"
                                        text="archiver"
                                        right
                                    />
                                )}
                            </Fragment>
                        )}
                </Actions>
            </Item>
            {contactDisplayed ? (
                <Contact
                    key={`${id}-contact`}
                    fullName={fullName({ firstName, lastName })}
                    mobile={mobile}
                    personalEmail={personalEmail}
                    isCouple={isCouple(familySituation)}
                    partnerFullName={fullName({ firstName: partnerFirstName, lastName: partnerLastName })}
                    partnerMobile={partnerMobile}
                    partnerPersonalEmail={partnerPersonalEmail}
                    editContactOrigin={editContactOrigin}
                    contactOrigin={contactOrigin}
                    contactOriginOther={contactOriginOther}
                />
            ) : null}

            {historyDisplayed ?
                <Logs historyLogs={historyLogs} displayLogs={displayLogs} />
                : null}
        </Fragment>
    );
};

ListItemInner.propTypes = {
    isActive: PropTypes.bool.isRequired,
    contactDisplayed: PropTypes.bool.isRequired,
    show: PropTypes.func.isRequired,
    item: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        hasChanges: PropTypes.bool,
        mobile: PropTypes.string,
        personalEmail: PropTypes.string,
        familySituation: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        partnerMobile: PropTypes.string,
        partnerPersonalEmail: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
        }),
        contactOrigin: PropTypes.string,
        contactOriginOther: PropTypes.string,
        errorCount: PropTypes.number,
        state: PropTypes.string,
        signatureDate: PropTypes.string,
        minified: PropTypes.bool,
        idBoxShoyo: PropTypes.string,
        userId: PropTypes.string,
        recommendationsCIF: PropTypes.shape({
            LODEOMCIF: PropTypes.shape({
                idEnvelopeESigne: PropTypes.string,
            }),
        }),
        partner: PropTypes.shape({
            recommendationsCIF: PropTypes.shape({
                LODEOMCIF: PropTypes.shape({
                    idEnvelopeESigne: PropTypes.string,
                }),
            }),
        }),
        statusBoxShoyo: PropTypes.shape({
            client: PropTypes.shape({
                statusMessage: PropTypes.string,
                statusCode: PropTypes.number,
            }),
            partner: PropTypes.shape({
                statusMessage: PropTypes.string,
                statusCode: PropTypes.number,
            }),
        }),
        clientAndPartnerSignatureDate: PropTypes.shape({
            offLine: PropTypes.bool,
        }),
        signature: PropTypes.string,
    }).isRequired,
    mode: PropTypes.string.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    stateDisplayed: PropTypes.bool.isRequired,
    historyDisplayed: PropTypes.bool.isRequired,
    updateState: PropTypes.func.isRequired,
    onClickArchive: PropTypes.func.isRequired,
    onClickActivate: PropTypes.func.isRequired,
    onClickExport: PropTypes.func.isRequired,
    onClickShoyo: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    editContactOrigin: PropTypes.func.isRequired,
    setRipAEnlever: PropTypes.func,
};

const ListItem = compose(
    withRouter,
    pure,
    connectActiveItem(({ item: { id } }) => id, (item, { activeItem, target }) => {
        const isActive = item === activeItem;

        return {
            isActive,
            contactDisplayed: isActive && 'contact' === target,
            stateDisplayed: isActive && 'state' === target,
            historyDisplayed: isActive && 'history' === target,
        };
    }),
    connect(null, (dispatch, { item: { id, personalEmail, partnerPersonalEmail, universignTransactionState }, session, hide, navigate }) => (({

        download: () => dispatch(setModal(() => (
            <DownloadRipSelection
                universignTxState={universignTransactionState}
                id={id}
                navigate={navigate}
            />
        ))),
        editContactOrigin: () => dispatch(setModal(() => <EditContactOriginForm id={id} />)),
        onClickDelete: () => {
            if (!window.confirm('Souhaitez vous vraiment supprimer ce rip ?')) {
                return;
            }

            dispatch(saveData('rip', id, { deleted: true }));
        },
        onClickArchive: () => {
            if (!window.confirm('Souhaitez vous vraiment archiver ce rip ?')) {
                return;
            }

            dispatch(saveData('rip', id, { archived: true }));
        },
        onClickActivate: () => {
            if (!window.confirm('Souhaitez vous vraiment activer ce rip ?')) {
                return;
            }

            dispatch(saveData('rip', id, { archived: false }));
        },
        onClickExport: () => dispatch(setModal(() => (
            <SelectRecipientsForExport
                id={id}
            />
        ))),
        onClickShoyo: () => dispatch(setModal(() => (
            <SelectRecipientsForShoyo
                id={id}
                partnerPersonalEmail={partnerPersonalEmail}
                personalEmail={personalEmail}
                session={session}
            />
        ))),
        updateState: (state) => () => {
            switch (state) {
                case 'toCallBack':
                    dispatch(setModal(() => <CallbackForm id={id} />));
                    break;
                default:
                    dispatch(saveData('rip', id, { state }));
                    break;
            }

            hide();
        },
    }))),
)(ListItemInner);

export default ListItem;
