import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { InputField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import LoanRange from '../LoanRange';
import OtherLoan from './OtherLoan';

const CarLoanInner = ({ carOption, name }) => {
    switch (carOption) {
        case 'loan':
            return <OtherLoan name={name} />;
        case 'LOA':
        case 'LLD':
            return (
                <HorizontalLayout>
                    <InputField name={`${name}.rent`} title="Loyer" required type="number" />
                    <LoanRange
                        startName={`${name}.start`}
                        startTitle="Début"
                        endName={`${name}.end`}
                        endTitle="Fin"
                        durationName={`${name}.duration`}
                        durationTitle="Durée en mois"
                    />
                </HorizontalLayout>
            );
        default:
            return null;
    }
};

CarLoanInner.propTypes = {
    carOption: PropTypes.string,
    name: PropTypes.string.isRequired,
};

const CarLoan = formValues(({ name }) => ({
    carOption: `${name}.carOption`,
}))(CarLoanInner);

export default CarLoan;
