import React from 'react';
import useFetch from 'components/useFetch';

const RipVars = () => {
    const [vars, loading] = useFetch({ url: '/api/documents/raw-tree' });

    return (
        <table className="table table-condensed table-bordered">
            <thead>
                <tr>
                    <th>Clé</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries({
                    clientSignature: 'Signature électronique du client',
                    partnerSignature: 'Signature électronique du conjoint',
                    advisorSignature: 'Signature électronique du conseiller',
                }).map(([key, label]) => (
                    <tr key={key}>
                        <td>{key}</td>
                        <td>{label}</td>
                    </tr>
                ))}
                {!loading ? Object.entries(vars.variables).map(([key, { label }]) => (
                    <tr key={key}>
                        <td>{key}</td>
                        <td>{label}</td>
                    </tr>
                )) : null}
            </tbody>
        </table>
    );
};

export default RipVars;
