import React from 'react';
import PropTypes from 'prop-types';
import dataProvider from 'components/dataProvider';
import { get } from 'lodash/fp';
import Layout from '../layouts/Layout';
import Signatures from './Signatures/Signatures';

const Sign = ({ rip, location, params: { docId } }) => {
    const completedDocument = get('state.file', location);
    const back = get('state.back', location);

    return (
        <Layout>
            <Signatures
                rip={rip}
                back={back}
                completedDocument={completedDocument}
                documentId={docId}
            />
        </Layout>
    );
};

Sign.propTypes = {
    rip: PropTypes.shape({}),
    location: PropTypes.shape({
        state: PropTypes.shape({
            file: PropTypes.shape({}),
        }),
    }).isRequired,
    params: PropTypes.shape({
        docId: PropTypes.string.isRequired,
    }),
};

export default dataProvider({
    id: ({ params: { id } }) => id,
    type: 'rip',
    wait: true,
})(Sign);
