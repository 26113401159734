import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from 'common/components/Icon';
import redirectToTopinvest from '../../../utils/redirectToTopinvest';
import { useSession } from '../../sessions';

const Button = (props) => <button {...props}>{props.children}</button>;

Button.propTypes = {
    children: PropTypes.node.isRequired,
};

const checkIsReadyForSimulationTopinvest = (topinvestUser, productName, productChoice) => {
    if (topinvestUser && productName && productChoice) {
        return true;
    }

    return false;
};

const NextButton = ({ formId, nextButtonContent, disabled, nextRedirectTo, topinvestUser, ripId, productChoice, productName, setCompleted, isButtonForSimulationTopInvest, enveloppeESigneCreated = true }) => {
    const session = useSession();
    const isReadyForSimulationTopinvest = checkIsReadyForSimulationTopinvest(topinvestUser, productName, productChoice);

    return (
        <div className="formNextAction">
            {nextRedirectTo ? (
                <Link to={nextRedirectTo}>
                    <button >
                        {nextButtonContent}&nbsp;
                        <Icon icon="angle-right" />
                    </button>
                </Link>
            ) : (
                <button
                    type="submit"
                    form={formId}
                    disabled={disabled || !enveloppeESigneCreated}
                    onClick={() =>
                        // eslint-disable-next-line no-nested-ternary
                        (isReadyForSimulationTopinvest ? redirectToTopinvest(session, 'simulation', ripId, productChoice) : isButtonForSimulationTopInvest ? setCompleted(false) : null)
                    }
                >
                    {nextButtonContent}&nbsp;

                    <Icon icon="angle-right" />
                </button>
            )}
        </div>
    );
};

NextButton.propTypes = {
    nextButtonContent: PropTypes.string,
    formId: PropTypes.string,
    disabled: PropTypes.bool,
    nextRedirectTo: PropTypes.string,
    usersIds: PropTypes.shape({}),
    session: PropTypes.shape({}),
    topinvestUser: PropTypes.bool,
    ripId: PropTypes.string,
    productChoice: PropTypes.string,
    productName: PropTypes.string,
    setCompleted: PropTypes.func,
    isButtonForSimulationTopInvest: PropTypes.bool,
    enveloppeESigneCreated: PropTypes.bool,
};

NextButton.defaultProps = {
    nextButtonContent: 'Continuer',
    formId: 'main-form',
    disabled: false,
    nextRedirectTo: null,
    isButtonForSimulationTopInvest: false,
};

export default NextButton;
