import PropTypes from 'prop-types';
import React from 'react';
import { scrollToNext } from '../../utils/scrollToNext';
import { VerticalLayout } from '../form/layout';

const FormFields = ({ children, handleSubmit, fluid, footer }) => (
    <form
        className="rip-form-inner"
        role="presentation"
        onSubmit={handleSubmit}
        onKeyDown={scrollToNext}
        id="main-form"
    >
        <div>
            <VerticalLayout className={fluid ? 'container-fluid' : 'container'}>
                {children}
            </VerticalLayout>
        </div>
        {footer || null}
    </form>
);

FormFields.propTypes = {
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    handleSubmit: PropTypes.func.isRequired,
    fluid: PropTypes.bool,
};

FormFields.defaultProps = {
    fluid: false,
};

export default FormFields;
