import { get, set } from 'lodash/fp';
import moment from 'moment';

const getDate = (date) => moment(date).startOf('day');

const checkDateDependencies = (field, refField, error, options = {}) => (errors, values) => {
    if (errors[field] || errors[refField]) {
        // well one of those is not valid yet
        // no need for us to test further
        return errors;
    }

    const date = get(field, values);
    const refDate = get(refField, values);

    const { buildDate = getDate, buildRefDate = getDate } = options;

    return buildDate(date).isBefore(buildRefDate(refDate))
        ? set(field, error, errors)
        : errors;
};

export default checkDateDependencies;
