import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ResponsiveContainer } from 'recharts';
import { withPropsOnChange } from 'recompose';
import { formValues } from 'redux-form';
import Title from 'components/Title';
import FieldInfo from 'components/form/FieldInfo';
import computeSummary from 'common/model/summary';
import SummaryBodyRequiredPart from 'common/components/summary/BodyRequiredPart';
import SummaryBodyOptionalPart from 'common/components/summary/BodyOptionalPart';
import BalancePie from 'common/components/summary/BalancePie';
import TaxEstimationBarChart from 'common/components/summary/TaxEstimationBarChart';
import SeeMore from '../../SeeMore';
import Charges from './Charges';
import Investments from './Investments';
import Properties from './Properties';
import Savings from './Savings';
import './Summary.css';

const remainingIncomesPopover = (
    <div>
        Reste à vivre mensuel
        <FieldInfo id="remainingIncomesPopover">
            <div>
                <p>Total des revenus (avec pondération des revenus locatifs) - total des charges</p>
            </div>
        </FieldInfo>
    </div>
);

const availableIncomesPopover = (
    <div>
        Disponibilité mensuelle
        <FieldInfo id="availableIncomesPopover">
            <div>
                <p>Total des revenus (avec pondération des revenus locatifs) x 35 % - total des charges</p>
            </div>
        </FieldInfo>
    </div>
);

const bars = [
    { dataKey: 'incomes', name: 'revenus', fill: '#27cae6' },
    { dataKey: 'taxes', name: 'impôts', fill: '#248fdf' },
];
// summary
const SummaryInner = ({ expandedSummary, rip, summary }) => (
    <Fragment>
        <Title><b>Récapitulatif</b> de votre situation</Title>
        <div className="summary-body col3">
            <SummaryBodyRequiredPart summary={summary} />
            <SeeMore name="Summary" />
            {expandedSummary ?
                <SummaryBodyOptionalPart
                    summary={summary}
                    remainingIncomesPopover={remainingIncomesPopover}
                    availableIncomesPopover={availableIncomesPopover}
                    rip={rip}
                /> : null}
        </div>
        <Title>Votre estimation d&apos;<b>imposition</b></Title>
        <ResponsiveContainer aspect={1.5} maxHeight={300}>
            <TaxEstimationBarChart
                rip={rip}
                bars={bars}
                label={({ x, y, value, dataKey, index, barData }) => {
                    if ('taxes' !== dataKey || 0 === index) {
                        return null;
                    }

                    const prevTaxes = parseInt(barData[index - 1].taxes, 10);
                    const taxes = parseInt(value, 10);

                    if (prevTaxes === taxes) {
                        return null;
                    }

                    const [icon, fill] = prevTaxes > taxes
                        ? ['&#xf063;', 'green']
                        : ['&#xf062;', 'red'];

                    return (
                        <g>
                            <text
                                fill={fill}
                                fontSize="15"
                                fontFamily="FontAwesome"
                                x={x}
                                y={y}
                                dx={20}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: icon }}
                            />
                            <text
                                fill={fill}
                                fontSize="15"
                                fontFamily="Helvetica Neue Light"
                                x={x}
                                y={y}
                                dx={20}
                                dy={20}
                            >
                                {taxes - prevTaxes}€
                            </text>
                        </g>
                    );
                }}
                margin={{ top: 20, left: 25, right: 10 }}
            />
        </ResponsiveContainer>
        <Title><b>Répartition de vos revenus et de vos charges</b></Title>
        <ResponsiveContainer aspect={1} width="30%" height="inherit" minWidth={350}>
            {summary && summary.rawIncomes ? (
                <BalancePie
                    className="simulation-balance-page-pie"
                    balance={{
                        charges: summary.charges,
                        rawIncomes: summary.rawIncomes,
                        taxes: summary.incomeTax + summary.socialCharges,
                    }}
                    margin={{ top: 10 }}
                />
            ) : (
                <p>
                    Vous n&apos;avez pas déclaré de revenus.
                </p>
            )}
        </ResponsiveContainer>
        <Investments rip={rip} />
        <Charges rip={rip} />
        <Properties rip={rip} />
        <Savings rip={rip} summary={summary} />
    </Fragment>
);
SummaryInner.propTypes = {
    expandedSummary: PropTypes.bool,
    rip: PropTypes.shape({}).isRequired,
    summary: PropTypes.shape({
        credits: PropTypes.number.isRequired,
        deductions: PropTypes.number.isRequired,
        incomes: PropTypes.number.isRequired,
        incomeTax: PropTypes.number.isRequired,
        parts: PropTypes.number.isRequired,
        reductions: PropTypes.number.isRequired,
        tmi: PropTypes.number.isRequired,
        rawIncomes: PropTypes.number,
        charges: PropTypes.number,
        socialCharges: PropTypes.number,
    }).isRequired,
};

SummaryInner.defaultProps = {
    expandedSummary: false,
};
const Summary = flowRight([
    withPropsOnChange(['rip'], ({ rip }) => ({ summary: computeSummary(rip, null, true) })),
    formValues('expandedSummary'),
])(SummaryInner);

export default Summary;
