import React, { Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import fullName from 'common/utils/fullName';
import Universign from 'components/Universign';
import Footer from 'components/rip/Footer';

const ElectronicSignature = ({ rip, documentId, completedDocument, clientSigner, partnerSigner, advisorSigner, partnerShouldSign }) => {
    const universignSigners = [];
    if ('universign' === clientSigner) {
        universignSigners.push('client');
    }
    if ('universign' === partnerSigner && partnerShouldSign) {
        universignSigners.push('partner');
    }
    if ('universign' === advisorSigner) {
        universignSigners.push('advisor');
    }

    return (
        <Fragment>
            <div className="e-signature-container">
                <div style={{ display: 'none' }}>
                    <Universign
                        docId={documentId}
                        docSign="document"
                        ripId={rip.id}
                        signers={universignSigners}
                        signUrl={`/api/documents/${documentId}/sign/${rip.id}`}
                        completedDocument={completedDocument}
                    />
                </div>
                <Alert variant="info">
                    <h2>Une transaction a été ouverte auprès d&apos;universign !</h2>
                    <hr />
                    <p>Un mail va être envoyé aux signataires dans l&apos;ordre suivant :</p>
                    <ul>
                        {'universign' === clientSigner ? <li>{fullName(rip)}</li> : null}
                        {(partnerShouldSign && 'universign' === partnerSigner) ? (
                            <li>
                                {fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName })}
                            </li>
                        ) : null}
                        {'universign' === advisorSigner ? (
                            <li>{fullName(rip.user)}</li>
                        ) : null}
                    </ul>
                    <hr />
                    <p>Le lien généré est valable 14 jours.</p>
                    <p>Pour information, tant que le signataire précédent n’a pas effectué sa signature, le signataire suivant ne reçoit pas le mail lui permettant de signer à son tour.</p>
                </Alert>
            </div>
            <Footer showPrev={false} nextRedirectTo="/" />
        </Fragment>
    );
};

ElectronicSignature.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
    }),
    documentId: PropTypes.string.isRequired,
    completedDocument: PropTypes.shape({}),
    clientSigner: PropTypes.string.isRequired,
    partnerSigner: PropTypes.string.isRequired,
    advisorSigner: PropTypes.string.isRequired,
    partnerShouldSign: PropTypes.string.isRequired,
};

export default ElectronicSignature;
