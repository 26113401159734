import { parseInteger } from '../utils';

const computeSalary = ({ id, income, incomeType, salaryMonthCount = 12, variableIncome, beneficiary }) => {
    const salaryMonthCountInt = parseInteger(salaryMonthCount);
    const monthlyIncome = income / salaryMonthCountInt;
    const constantIncome = income - (variableIncome || 0);
    const monthlyConstantIncome = constantIncome / salaryMonthCountInt;
    const disableFrequency = 'monthly' === incomeType ? 'annuel' : 'mensuel';
    const disableIncome = 'monthly' === incomeType ? income : income / salaryMonthCountInt;

    return {
        id,
        income,
        incomeType,
        variableIncome,
        salaryMonthCountInt,
        disableIncome,
        disableFrequency,
        monthlyIncome,
        monthlyConstantIncome,
        constantIncome,
        beneficiary,
    };
};

export default computeSalary;
