import PropTypes from 'prop-types';
import React from 'react';
import { getContext } from 'recompose';
import { jobStatuses } from '../../../common/choices';
import HorizontalRadioButton from '../../form/HorizontalRadioButton';
import RadioGroup from '../../form/RadioGroup';
import { delayedScrollToNext } from '../../../utils/scrollToNext';

const onClick = delayedScrollToNext(400);

const TaxExecutiveInner = ({ formGroup: Group, name, ...props }) => (
    <Group name={name} {...props}>
        <RadioGroup name={name}>
            {Object.entries(jobStatuses).map(([key, title]) => (
                <HorizontalRadioButton key={key} title={title} value={key} onClick={onClick} />
            ))}
        </RadioGroup>
    </Group>
);

TaxExecutiveInner.propTypes = {
    formGroup: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

const TaxExecutive = getContext({
    formGroup: PropTypes.func.isRequired,
})(TaxExecutiveInner);

export default TaxExecutive;
