import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalLayout } from '../../form/layout';
import { DateField, IntegerField } from '../../form/field';
import SelectSalaryField from '../field/SelectSalary';

const SalaryDecreaseEvent = ({ name }) => (
    <HorizontalLayout>
        <SelectSalaryField name={`${name}.income`} title="Revenu concerné" required />
        <DateField name={`${name}.date`} title="Date prévue de la baisse" required />
        <IntegerField name={`${name}.fixedAmount`} title="Montant de la baisse (part fixe)" />
        <IntegerField name={`${name}.variableAmount`} title="Montant de la baisse (part variable)" />
    </HorizontalLayout>
);

SalaryDecreaseEvent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default SalaryDecreaseEvent;
