import { withProps } from 'recompose';
import Input from './Input';

const normalize = (value, previousValue = null) => {
    if ('' === value) {
        return null;
    }

    const parsed = parseFloat(value);

    return Number.isNaN(parsed) ? previousValue : parsed;
};

const Float = withProps({
    step: 0.01,
    normalize,
    type: 'number',
})(Input);

export default Float;
