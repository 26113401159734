// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-body dl.important {
    padding-top: 30px;
}

.summary-body.col1 .summary-item {
    width: 100%;
}

.summary-body.col2 .summary-item {
    width: 50%;
}

.summary-body.col3 .summary-item {
    width: 33%;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/summary/Body.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".summary-body dl.important {\n    padding-top: 30px;\n}\n\n.summary-body.col1 .summary-item {\n    width: 100%;\n}\n\n.summary-body.col2 .summary-item {\n    width: 50%;\n}\n\n.summary-body.col3 .summary-item {\n    width: 33%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
