import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'components/layouts';
import { VerticalLayout } from '../form/layout';

const InvestorProfileForm = ({
    children,
    handleSubmit,
}) => (
    <Layout hasFooter>
        <form onSubmit={handleSubmit} id="main-form">
            <VerticalLayout>
                {children}
            </VerticalLayout>
        </form>
    </Layout>
);

InvestorProfileForm.propTypes = {
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default InvestorProfileForm;
