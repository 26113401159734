import { get, getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import computeInvestorProfile from 'common/model/rip/investorProfile';
import hasPartner from 'common/utils/hasPartner';
import { Grid, AmountItem, Item, PercentageItem } from 'common/components/summary';
import Icon from 'common/components/Icon';
import Title from 'components/Title';
import types from 'components/rip/profile/LifePlansTypes';

import './Savings.css';

const profiles = {
    P: 'Prudent',
    E: 'Equilibré',
    D: 'Dynamique',
    declined: '',
};

const getProfile = (profile) => getOr('NC', profile, profiles);

const Savings = ({
    rip: {
        firstName,
        partnerFirstName,
        familySituation,
        financialCapacity,
        projectMinimumSavings,
        projectMaximumSavings,
        investorProfiles,
        lifePlans,
    },
    summary: { debtRatio },
}) => {
    const withPartner = hasPartner(familySituation);
    const investorProfile = computeInvestorProfile(get('client', investorProfiles));
    const partnerInvestorProfile = withPartner ? computeInvestorProfile(get('partner', investorProfiles)) : null;

    return (
        <Fragment>
            {lifePlans && lifePlans.length > 0 && (
                <Fragment>
                    <Title>Vos <b>projets de vie</b></Title>
                    {lifePlans.map(({ type }, index) => {
                        const { icon, path, title } = types[type];

                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index.toString()} className="summary-life-plan">
                                <Icon icon={icon} path={path} />
                                <p>{title}</p>
                            </div>
                        );
                    })}
                </Fragment>
            ) }
            {(investorProfile || (withPartner && partnerInvestorProfile)) && (
                <Fragment>
                    <Title>Votre profil d&apos;<b>investisseur</b></Title>
                    <Grid size={withPartner ? 2 : 1}>
                        <Item
                            title={withPartner ? firstName : null}
                            value={getProfile(investorProfile)}
                        />
                        {withPartner ? (
                            <Item
                                title={partnerFirstName}
                                value={getProfile(partnerInvestorProfile)}
                            />
                        ) : null}
                    </Grid>
                </Fragment>
            )}
            <Title>Votre <b>capacité d&apos;épargne</b></Title>
            <Grid size={financialCapacity ? 3 : 2}>
                {financialCapacity && (
                    <Fragment>
                        <AmountItem title="Capacité mensuelle minimum" value={projectMinimumSavings} />
                        <AmountItem title="Capacité mensuelle maximum" value={projectMaximumSavings} />
                    </Fragment>
                )}
                <PercentageItem title="Taux d'endettement" value={debtRatio} />
            </Grid>
        </Fragment>
    );
};

Savings.propTypes = {
    rip: PropTypes.shape({
        firstName: PropTypes.string,
        partnerFirstName: PropTypes.string,
        familySituation: PropTypes.string,
        financialCapacity: PropTypes.bool,
        projectMinimumSavings: PropTypes.number,
        projectMaximumSavings: PropTypes.number,
        investorProfiles: PropTypes.shape({}),
        lifePlans: PropTypes.arrayOf(PropTypes.shape({
            length: PropTypes.number,
            map: PropTypes.shape({}),
        })),
    }).isRequired,
    summary: PropTypes.shape({
        debtRatio: PropTypes.number,
    }).isRequired,
};

export default Savings;
