import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from '../../form/layout';
import { DateField } from '../../form/field';
import { HouseholdLeaveSubjectField } from '../field';

const HouseholdLeaveEvent = ({ name }) => (
    <HorizontalLayout>
        <HouseholdLeaveSubjectField name={`${name}.subject`} title="Personne concernée" required />
        <DateField name={`${name}.date`} title="Date de départ" required />
    </HorizontalLayout>
);

HouseholdLeaveEvent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default HouseholdLeaveEvent;
