import { get } from 'lodash/fp';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import zipcelx from 'zipcelx';
import Icon from 'common/components/Icon';
import fullName from 'common/utils/fullName';
import { ripStates } from 'common/choices';
import { formatDate } from 'common/utils/format';
import { useSession } from '../sessions';
import { saveAndExportRips } from '../../utils/saveAndExportRips';
import { useOnline } from '../withOnline';

import './ExportButton.scss';

const getState = (state, callbackDate) => {
    switch (state) {
        case 'toCallBack':
            if (callbackDate) {
                return `${ripStates.toCallBack} (${formatDate(callbackDate)})`;
            }
        // eslint-disable-next-line no-fallthrough
        default:
            return get(state, ripStates);
    }
};

const getRow = ({
    callbackDate,
    createdAt,
    updatedAt,
    firstName,
    lastName,
    state,
    user,
    phone,
    mobile,
    personalEmail,
}) => [
    {
        value: fullName({ firstName, lastName }),
        type: 'string',
    },
    {
        value: fullName(user),
        type: 'string',
    },
    {
        value: get('entity.name', user),
        type: 'string',
    },
    {
        value: moment(createdAt).format('DD/MM/YYYY HH:mm'),
        type: 'string',
    },
    {
        value: moment(updatedAt).format('DD/MM/YYYY HH:mm'),
        type: 'string',
    },
    {
        value: phone,
        type: 'string',
    },
    {
        value: mobile,
        type: 'string',
    },
    {
        value: personalEmail,
        type: 'string',
    },
    {
        value: getState(state, callbackDate),
        type: 'string',
    },
];

const onClick = (items, userId, userStatus) => () => {
    const ripsAExporter = saveAndExportRips(userId, items, userStatus, 'all');

    const config = {
        filename: `export-rips-${moment().format('DDMMYYYY_HHmm')}`,
        sheet: {
            data: [
                [
                    {
                        value: 'Client',
                        type: 'string',
                    },
                    {
                        value: 'Conseiller',
                        type: 'string',
                    },
                    {
                        value: 'Réseau',
                        type: 'string',
                    },
                    {
                        value: 'Date de création',
                        type: 'string',
                    },
                    {
                        value: 'Date de modification',
                        type: 'string',
                    },
                    {
                        value: 'Téléphone fixe',
                        type: 'string',
                    },
                    {
                        value: 'Téléphone mobile',
                        type: 'string',
                    },
                    {
                        value: 'Email',
                        type: 'string',
                    },
                    {
                        value: 'Status',
                        type: 'string',
                    },
                ],
                ...ripsAExporter.map(getRow),
            ],
        },
    };

    zipcelx(config);
};

const ExportButton = ({ items, userStatus }) => {
    const session = useSession();
    const onLine = useOnline();

    return (
        session.isAdmin && (
            <button
                onClick={onClick(items, session.id, userStatus)}
                className={classNames('list-export-button', {
                    disabled: !onLine,
                })}
                disabled={!onLine}
            >
                <Icon icon="download" />
            </button>
        )
    );
};

ExportButton.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        state: PropTypes.string,
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
        phone: PropTypes.string,
        mobile: PropTypes.string,
        personalEmail: PropTypes.string,
    })).isRequired,
    userStatus: PropTypes.string,
};

export default ExportButton;
