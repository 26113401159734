import computeSavings from './computeSavings';
import getMedianRate from './getMedianRate';

const computeSimulation = (params) => (investorProfile, opts = null) => computeSavings({
    ...params,
    ...opts,
    yearRate: getMedianRate()[investorProfile],
});

export default computeSimulation;
