import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { GET } from 'utils/httpMethods';
import { documentsList } from 'common/choices';
import ViewerDocument from './ViewerDocument';

class ViewerBoard extends Component {
    constructor(props) {
        super(props);

        this.state = { activeDocument: 0, documents: {} };
    }

    componentDidMount() {
        this.loadDocuments();
    }

    async loadDocuments() {
        const { contract } = this.props;

        const response = await GET(`/api/contracts/${contract.id}/documents`);
        const documents = await response.json();

        this.setState({ documents });
    }

    render() {
        const { activeDocument, documents } = this.state;
        const { ripId, contract: { id: contractId } } = this.props;

        const document = documents[activeDocument];

        return (
            <div className="row">
                <div className="col-md-4">
                    <div className="viewer-board-list">
                        {Object.keys(documents).map((type) => (
                            <button
                                key={type}
                                className={classNames({ selected: type === activeDocument })}
                                type="button"
                                onClick={() => this.setState({ activeDocument: type })}
                            >
                                {documentsList[type]}
                            </button>
                        ))}
                    </div>
                    <div className="viewer-board-link">
                        <Link
                            to={`/rip/${ripId}/contracts/${contractId}/ca-documents`}
                        >
                            Modifier les justificatifs
                        </Link>
                    </div>
                </div>
                <div className="col-md-8">
                    {document && <ViewerDocument {...document} />}
                </div>
            </div>
        );
    }
}

ViewerBoard.propTypes = {
    ripId: PropTypes.string.isRequired,
    contract: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default ViewerBoard;
