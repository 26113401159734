import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withProps } from 'recompose';
import { faCar, faEllipsis, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import FormGroup from '../../form/Group';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import RipCar from './Car';
import RipOther from './Other';
import RipOtherLoan from './OtherLoan';
import FieldInfo from '../../form/FieldInfo';
import RipPension from './Pension';
import TypedBlockCollectionItem from '../../form/TypedBlockCollectionItem';

const formSelector = formValueSelector('rip-finances');

const BlockTitle = connect((state, { name }) => ({
    type: formSelector(state, `${name}.type`),
    fiscalHome: formSelector(state, `${name}.fiscalHome`),
    otherOption: formSelector(state, `${name}.otherOption`),
}))(({ type, fiscalHome, otherOption, title }) => (
    <Fragment>
        {title}
        {
            'other' === type && /^sci-/i.test(fiscalHome) && 'loan' === otherOption && (
                <FieldInfo>
                    Pour les prêts rattachés à une SCI, saisir les informations sur
                    la totalité du prêt contracté par la SCI.
                </FieldInfo>
            )
        }
    </Fragment>
));

const types = {
    carLoan: {
        component: RipCar,
        icon: faCar,
        title: 'Voiture',
    },
    consumerLoan: {
        component: RipOtherLoan,
        icon: faShoppingCart,
        title: 'Crédit conso',
    },
    pension: {
        component: RipPension,
        path: 'pension',
        title: 'Pension versée',
    },
    other: {
        component: RipOther,
        icon: faEllipsis,
        title: 'Autres',
        itemTitle: withProps({ title: 'Autres' })(BlockTitle),
    },
};

const ChargeList = () => (
    <FormGroup>
        <Title>Quels sont <b>vos autres crédits&nbsp;? (hors immobilier)</b></Title>
        <TypedCollection
            name="charges"
            types={types}
            itemComponent={TypedBlockCollectionItem}
        />
    </FormGroup>
);

export default ChargeList;
