import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import { MarkdownField } from 'components/form/field';
import Dropdown from 'components/Dropdown';

import './SaveButton.css';

const SettingEntityDropdown = ({ name, title, isAdminReseau, variables = {} }) => (
    <Dropdown title={title}>
        <MarkdownField name={name} />
        {isAdminReseau &&
        <div className="submit">
            <button className="sendSettingsButton" type="submit">
                Enregistrer
            </button>
        </div>}
        {!isEmpty(variables) && (
            <Fragment>
                <b>Variables :</b>
                <table className="table table-condensed table-bordered email-templates-variables">
                    <thead>
                        <tr>
                            <th className="var">Clé</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(variables).map(([varKey, varTitle]) => (
                            <tr key={varKey}>
                                <td className="var">
                                    <span>&#123;&#123;{varKey}&#125;&#125;</span>
                                </td>
                                <td>{varTitle}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        )}
    </Dropdown>
);

SettingEntityDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isAdminReseau: PropTypes.bool.isRequired,
    variables: PropTypes.shape({}),
};

export default SettingEntityDropdown;
