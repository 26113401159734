import PropTypes from 'prop-types';
import React from 'react';
import RipTaxForm from './taxes/Form';
import RipLayout from './RipLayout';
import withRouter from '../withRouter';

const Taxes = ({ navigate, params: { id } }) => (
    <RipLayout>
        <RipTaxForm
            back={`/rip/${id}/etat-civil`}
            id={id}
            onSubmitSuccess={() => navigate(`/rip/${id}/summary`)}
        />
    </RipLayout>
);

Taxes.propTypes = {
    navigate: PropTypes.func,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(Taxes);
