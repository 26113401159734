import React from 'react';
import PropTypes from 'prop-types';
import { PercentageFloatField, SelectField } from 'components/form/field';

const SupportType = ({ baseProducts, name }) => (
    <div className="profile-support">
        <SelectField title="Support" name={`${name}.baseProduct`}>
            <option />
            {
                Object
                    .entries(baseProducts)
                    .map(([baseProductValue, baseProductInfo]) => (
                        <option key={baseProductValue} value={baseProductValue}>{baseProductInfo.label}</option>
                    ))
            }
        </SelectField>
        <div className="proportion">
            <PercentageFloatField
                title="Proportion versement initial"
                name={`${name}.proportion`}
            />
            <PercentageFloatField
                title="Proportion versement programmé"
                name={`${name}.proportionVersementProgramme`}
            />
        </div>
    </div>
);

SupportType.propTypes = {
    name: PropTypes.string.isRequired,
    baseProducts: PropTypes.shape({}).isRequired,
};

export default SupportType;
