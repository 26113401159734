import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { filter } from 'lodash/fp';
import { processCollections } from 'actions';
import { faUniversity, faGavel, faRing, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import FamilyInfo from './FamilyInfo';
import { delayedScrollToNext } from '../../../utils/scrollToNext';
import { familySituations } from '../../../common/choices';
import RadioGroup from '../../form/RadioGroup';
import RadioIcon from '../../form/RadioIcon';

const icons = {
    single: { icon: faUser },
    married: { icon: faRing },
    partner: { icon: faUniversity },
    cohabitation: { icon: faUserGroup },
    divorced: { icon: faGavel },
    widower: { icon: faUser },
};

const confirm =
    'Attention, le passage en célibataire du client aura pour effet de supprimer toutes les données ' +
    'concernant les revenus, biens et charges attachés au conjoint, voulez-vous continuer ?';

const confirmChange =
    'Attention, la modification de la situation familiale du client aura pour effet de supprimer toutes les données ' +
    'concernant les revenus, biens et charges attachés au conjoint, voulez-vous continuer ?';

const FormFamilySituation = ({
    incomes,
    investments,
    scis,
    charges,
    properties,
    fiscalParticularCases,
    mainResidenceTenant,
    taxEvents,
    change,
}) => (
    <div>
        <FormGroup role="presentation" onClick={delayedScrollToNext(100)} onKeyDown={delayedScrollToNext(100)}>
            <Title>
                Quelle est <b>votre situation familiale actuelle&nbsp;? *</b>
            </Title>
            <RadioGroup
                name="familySituation"
                onChange={(event, newValue, previousValue) => {
                    const changeSituationFamily = true;
                    if (
                        ['married', 'partner'].includes(previousValue) &&
                        'cohabitation' === newValue
                    ) {
                        event.preventDefault();
                        if (window.confirm(confirmChange)) {
                            processCollections(
                                incomes,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                change,
                                changeSituationFamily,
                            );
                        }
                    }

                    if (
                        ['married', 'partner', 'cohabitation'].includes(previousValue) &&
                        'single' === newValue
                    ) {
                        event.preventDefault();
                        // eslint-disable-next-line no-alert
                        if (window.confirm(confirm)) {
                            processCollections(
                                incomes,
                                investments,
                                scis,
                                charges,
                                properties,
                                taxEvents,
                                mainResidenceTenant,
                                change,
                            );
                        }
                    }

                    if ('widower' === previousValue) {
                        change(
                            'fiscalParticularCases',
                            filter((partCase) => 'widowerWithMultiplePersonInCharge' !== partCase, fiscalParticularCases),
                        );
                    }
                    if (['single', 'divorved', 'widower'].includes(previousValue) && ['married', 'partner', 'cohabitation'].includes(newValue)) {
                        change(
                            'fiscalParticularCases',
                            filter((partCase) => !['singleParent', 'singleHavingRaisedChildMoreThan5Years'].includes(partCase), fiscalParticularCases),
                        );
                    }
                    if (['married', 'partner'].includes(previousValue)) {
                        change('communityRegime', null);
                    }
                }}
            >
                {Object.entries(familySituations).map(([key, title]) => (
                    <RadioIcon title={title} value={key} key={key} {...icons[key]} />
                ))}
            </RadioGroup>
        </FormGroup>
        <FamilyInfo />
    </div>
);

FormFamilySituation.propTypes = {
    change: PropTypes.func.isRequired,
    scis: PropTypes.arrayOf(PropTypes.shape({})),
    incomes: PropTypes.arrayOf(PropTypes.shape({})),
    properties: PropTypes.arrayOf(PropTypes.shape({})),
    investments: PropTypes.arrayOf(PropTypes.shape({})),
    taxEvents: PropTypes.arrayOf(PropTypes.shape({})),
    charges: PropTypes.arrayOf(PropTypes.shape({})),
    fiscalParticularCases: PropTypes.arrayOf(PropTypes.string),
    mainResidenceTenant: PropTypes.string,
};

export default formValues({
    scis: 'sci',
    incomes: 'incomes',
    properties: 'property',
    investments: 'investments',
    charges: 'charges',
    taxEvents: 'taxEvents',
    fiscalParticularCases: 'fiscalParticularCases',
    mainResidenceTenant: 'mainResidenceTenant',
})(FormFamilySituation);
