import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { ResponsiveContainer } from 'recharts';
import computeSimulation from 'common/model/simulation/lifeInsurance/miniSimulation';
import { AmountItem } from 'common/components/summary';
import LifeInsuranceResultBarChart from 'common/components/rip/simulators/LifeInsuranceResultBarChart';
import LifeInsuranceResultLineChart from 'common/components/rip/simulators/LifeInsuranceResultLineChart';
import Title from 'components/Title';

const bars = [
    { dataKey: 'fundings', name: 'Versements', fill: '#27cae6' },
    { dataKey: 'interests', name: 'Intérêts', fill: '#248fdf' },
];

const lines = [
    { dataKey: 'livretAInterests', name: 'Intérêts (livret A)', fill: '#cc3333' },
    { dataKey: 'inflationInterests', name: 'Inflation', fill: '#000000' },
    { dataKey: 'avInterests', name: 'Intérêts (AV)', fill: '#248fdf' },
];

const labelFormatter = (label) => `Année: ${label}`;

const formatter = (value) => `${value} €`;

const LifeInsuranceResultInner = ({
    years,
    initialFunding,
    investorProfile,
    monthlyFunding,
    ...opts
}) => {
    const duration = years || 0;
    const simulate = computeSimulation({ initialFunding, monthlyFunding, duration });

    const livretASimulation = simulate('livretA');
    const inflationSimulation = simulate('inflation');
    const lifeInsuranceSimulation = simulate(investorProfile, opts);

    const lifeInsuranceFinal = lifeInsuranceSimulation[duration];
    const inflationFinal = inflationSimulation[duration];
    const livretAFinal = livretASimulation[duration];

    const interests = lifeInsuranceSimulation.map(({ interests: avInterests, year }) => ({
        year,
        avInterests,
        livretAInterests: livretASimulation[year].interests,
        inflationInterests: inflationSimulation[year].interests,
    }));

    return (
        <Fragment>
            <div className="av-summary-warning">
                <p>
                    Cette simulation est lissée sur le temps et ne présente pas la volatilité, pour rappel les unités de
                    compte ne sont pas garanties en capital et sont susceptibles de fluctuer et d’etre négatives.
                </p>
            </div>
            <Title><b>Chiffres-clés</b></Title>
            <div className="summary-body col3">
                <dl>
                    <AmountItem title="Capital final" value={lifeInsuranceFinal.capital} />
                    <AmountItem title="Versements cumulés" value={lifeInsuranceFinal.fundings} />
                    <AmountItem title="Intérêts cumulés" value={lifeInsuranceFinal.interests} />
                </dl>
            </div>
            <Title>Votre <b>épargne</b> au fil des ans</Title>
            <ResponsiveContainer aspect={1.5} maxHeight={300}>
                <LifeInsuranceResultBarChart
                    bars={bars}
                    barData={lifeInsuranceSimulation}
                    labelFormatter={labelFormatter}
                    formatter={formatter}
                />
            </ResponsiveContainer>
            <Title><b>Performances comparées</b></Title>
            <div className="summary-body col3">
                <dl>
                    <AmountItem title="Intérêts cumulés (livret A)" value={livretAFinal.interests} />
                    <AmountItem title="Intérêts cumulés (inflation)" value={inflationFinal.interests} />
                    <AmountItem title="Intérêts cumulés (AV)" value={lifeInsuranceFinal.interests} />
                </dl>
            </div>
            <Title><b>Performances</b> au fil des ans</Title>
            <ResponsiveContainer aspect={1.5} maxHeight={300}>
                <LifeInsuranceResultLineChart
                    lines={lines}
                    lineData={interests}
                    labelFormatter={labelFormatter}
                    formatter={formatter}
                />
            </ResponsiveContainer>
        </Fragment>
    );
};

LifeInsuranceResultInner.propTypes = {
    years: PropTypes.number,
    initialFunding: PropTypes.number,
    investorProfile: PropTypes.string,
    monthlyFunding: PropTypes.number,
};

const LifeInsuranceResult = formValues({
    years: 'duration',
    initialFunding: 'initialFunding',
    investorProfile: 'investorProfile',
    monthlyFunding: 'monthlyFunding',
    entryFees: 'entryFees',
    monthlyFees: 'monthlyFees',
})(LifeInsuranceResultInner);

export default LifeInsuranceResult;
