const simulator = (state = null, { type, ...payload }) => {
    switch (type) {
        case 'SHOW_SIMULATOR':
            return payload.simulator;
        case 'HIDE_SIMULATOR':
            return null;
        default:
            return state;
    }
};

export default simulator;
