// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulators {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.simulator-title {
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/overlays/Simulators/Simulators.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".simulators {\n    display: inline-flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.simulator-title {\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
