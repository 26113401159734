import PropTypes from 'prop-types';
import React from 'react';
import { useSession } from '../sessions';
import withRouter from '../withRouter';
import RipAppointmentForm from './appointment/Form';
import RipLayout from './RipLayout';

const Appointment = ({ params: { id }, history }) => {
    const session = useSession();
    let last = true;
    let nextRedirectTo;
    if ('Topinvest' === session.entity.name) {
        last = false;
        nextRedirectTo = `/rip/${id}/simulations/new`;
    }

    return (
        <RipLayout>
            <RipAppointmentForm
                back={`/rip/${id}/commitment`}
                nextButtonContent="Simuler"
                nextRedirectTo={nextRedirectTo}
                id={id}
                last={last}
                history={history}
            />
        </RipLayout>
    );
};

Appointment.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({}).isRequired,
};

export default withRouter(Appointment);
