import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Children, cloneElement, createElement } from 'react';

import './Horizontal.css';
import FieldInfo from '../FieldInfo';

const Horizontal = ({ children, component, double, title, required, info, ...props }) => createElement(
    component,
    { className: classNames('form-group-horizontal', { double }), ...props },
    createElement('span', {}, title, required ? '*' : ''),
    info ? createElement(FieldInfo, {}, info) : null,
    Children.map(children, (child) => (child
        ? cloneElement(child, {
            className: classNames(child.props.className, { 'form-control': 'input' === child.type }),
        })
        : null)),
);

Horizontal.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.string,
    double: PropTypes.bool,
    title: PropTypes.node.isRequired,
    required: PropTypes.bool,
    info: PropTypes.bool,
};

Horizontal.defaultProps = {
    component: 'div',
    double: false,
    required: false,
};

export default Horizontal;
