import React from 'react';
import PropTypes from 'prop-types';
import { branch, renderNothing } from 'recompose';
import RadioQuestion from '../../InvestorProfile/RadioQuestion';

const TargetField = ({ rip }) => (
    <RadioQuestion
        choices={{
            client: rip.firstName,
            partner: rip.partnerFirstName,
        }}
        name="target"
        title={<span><b>Concubin</b></span>}
        required
    />
);

TargetField.propTypes = {
    rip: PropTypes.shape({
        firstName: PropTypes.string,
        partnerFirstName: PropTypes.string,
    }).isRequired,
};

export default branch(
    ({ rip: { familySituation, dualRip } }) => 'cohabitation' !== familySituation || !dualRip,
    renderNothing,
)(TargetField);
