import PropTypes from 'prop-types';
import React from 'react';
import { InputField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';

const Diversification = ({ name }) => (
    <GridLayout className="condensed full-width">
        <InputField name={`${name}.gff`} title="GFF" type="checkbox" />
        <InputField name={`${name}.gffComment`} title="Commentaire" type="text" />
        <InputField name={`${name}.fineArtSelection`} title="Fine Art Selection" type="checkbox" />
        <InputField name={`${name}.fineArtSelectionComment`} title="Commentaire" type="text" />
        <InputField name={`${name}.foresight`} title="Prévoyance" type="checkbox" />
        <InputField name={`${name}.foresightComment`} title="Commentaire" type="text" />
    </GridLayout>
);

Diversification.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Diversification;
