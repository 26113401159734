import { range } from 'lodash/fp';
import React from 'react';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import { GridLayout } from 'components/form/layout';
import { IntegerField } from 'components/form/field';

import './PreviousDeficits.scss';

const PreviousDeficits = () => (
    <FormGroup>
        <Title><b>Déficits fonciers reportables</b></Title>
        <GridLayout className="previous-deficits">
            {range(10, 0).map((year) => (
                <IntegerField
                    key={year.toString()}
                    name={`data.previousDeficits[${10 - year}]`}
                    title={`Année n-${year}`}
                />
            ))}
        </GridLayout>
    </FormGroup>
);

export default PreviousDeficits;
