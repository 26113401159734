import { flowRight } from 'lodash/fp';
import { branch, renderNothing } from 'recompose';
import dataProvider from '../dataProvider';

const contractLoader = flowRight([
    dataProvider({
        id: ({ params: { contractId } }) => contractId,
        type: 'contract',
        wait: true,
    }),
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
    }),
    branch(
        ({ rip, contract }) => !rip || !contract,
        renderNothing,
    ),
]);

export default contractLoader;
