import PropTypes from 'prop-types';
import { createElement } from 'react';
import dataProvider from '../dataProvider';
import types from './types';

const FormInner = ({ simulation: { product }, ...props }) => (createElement(types[product].router, props)
);

FormInner.propTypes = {
    simulation: PropTypes.shape({
        product: PropTypes.string.isRequired,
    }).isRequired,
};

const Form = dataProvider({
    id: ({ params: { simulationId } }) => simulationId,
    type: 'simulation',
    wait: true,
})(FormInner);

export default Form;
