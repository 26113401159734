import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import withAppData from 'components/withAppData';
import { Layout } from '../layouts';
import Spinner from '../Spinner';
import CheckoutForm from './CheckoutForm';
import { GET } from '../../utils/httpMethods';

const Subscription = ({ appData: { stripePublicKey } }) => {
    const [initialValues, setInitialValues] = useState(null);

    useEffect(() => {
        GET('/api/payment/subscriptions/personalDetails')
            .then((response) => response.json())
            .then(setInitialValues);
    }, []);

    if (!initialValues) {
        return (
            <Layout>
                <Spinner />
            </Layout>
        );
    }
    const stripePromise = loadStripe(stripePublicKey);

    return (
        <Layout>
            <div className="container">
                <h1>Abonnement E-Rip</h1>
                <br />
                <Elements stripe={stripePromise} >
                    <CheckoutForm initialValues={initialValues} />
                </Elements>
            </div>
        </Layout>
    );
};

Subscription.propTypes = {
    appData: PropTypes.shape({
        stripePublicKey: PropTypes.string.isRequired,
    }).isRequired,
};

export default withAppData(Subscription);
