import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Layout } from 'components/layouts';
import { CreateLink } from '../../list';
import SignaturesTable from './SignaturesTable';
import mapPrefixToProps from '../mapPrefixToProps';

import './List.css';

const SignatureList = ({ prefix }) => (
    <Layout className="signature-list">
        <Fragment>
            <SignaturesTable />
            <CreateLink to={`${prefix}/signatures/new`} />
        </Fragment>
    </Layout>
);

SignatureList.propTypes = {
    prefix: PropTypes.string.isRequired,
};

export default mapPrefixToProps(SignatureList);
