const choices = {
    lifeInsurance: 'Assurance vie',
    trading: 'PEA (actions) / Compte titres (actions, obligations, OPCVM…)',
    precautionarySaving: 'Livret A, LDD, PEL',
    complex: 'Instruments complexes (options, warrants : produits de bourse à effet de levier qui permet à ' +
        'l\'investisseur d\'amplifier les variations d\'un actif à la hausse comme à la baisse…)',
    none: 'Aucun',
};

export default choices;
