import { countBy, findLastIndex, get, set } from 'lodash/fp';
import hasPartner from '../../utils/hasPartner';
import validate, {
    checkRequiredOwnerField,
    validateCollection,
} from '../lib';

export default validate([
    validateCollection('investments', (name, element) => [
        checkRequiredOwnerField(
            `${name}.subscriber`,
            {
                child: true,
                ...['currentAccount', 'SCPI', 'funding', 'other'].includes(element.type) && { showSci: true },
            },
        ),
    ]),
    (errors, values) => {
        const { familySituation, investments } = values;
        const max = hasPartner(familySituation) ? 2 : 1;

        return max < countBy(get('type'), investments).PEA
            ? set(
                `investments[${findLastIndex(({ type }) => 'PEA' === type, investments)}]._error`,
                `Maximum ${max} PEA`,
                errors,
            )
            : errors;
    },
    (errors, values) => {
        const { familySituation, investments } = values;
        const max = hasPartner(familySituation) ? 2 : 1;

        return max < countBy(get('type'), investments).PEAPME
            ? set(
                `investments[${findLastIndex(({ type }) => 'PEAPME' === type, investments)}]._error`,
                `Maximum ${max} PEA-PME`,
                errors,
            )
            : errors;
    },
    (errors, values) => {
        const { investments, createdAt } = values;
        const requiredInvestments = ['currentAccount', 'LDD', 'savingsAccount', 'PEL', 'CEL', 'CSL'];

        function checkHasInvestments(objects, requiredTypes) {
            return objects && objects.some(obj => requiredTypes.includes(obj.type));
        }

        const hasInvestments = checkHasInvestments(investments, requiredInvestments);

        // Une disponibilité minimun obligatoire à partir du 18/07/24
        const dateLimit = new Date('2024-07-18T00:00:00.000Z');
        const createdDateRip = new Date(createdAt);

        return !hasInvestments && createdDateRip >= dateLimit ?
            set('_error', 'Vous devez renseigner au moins une disponibilité', errors)
            : errors;
    },
]);
