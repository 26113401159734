import { get } from 'lodash/fp';
import { validators as propertyValidators } from 'components/simulation/types/common/property/PropertyForm';
import validate, {
    checkRequiredIntegerField,
    checkRequiredEditableField,
    checkRequiredField,
    checkChoice,
    branchValidate,
} from 'common/formValidation/lib';
import { lmnpTypes, tvaFunderTypes } from 'common/choices';

const formValidate = validate([
    ...propertyValidators,
    checkRequiredField('data.program.type'),
    checkChoice('data.program.type', lmnpTypes),
    checkRequiredIntegerField('propertyValue'),
    checkRequiredIntegerField('data.property.landValue'),
    checkRequiredIntegerField('data.property.furnitureValue'),
    checkRequiredIntegerField('parkingLotValue'),
    branchValidate((errors, { parkingLotWithSeparateLease }) => parkingLotWithSeparateLease, [
        checkRequiredField('parkingLotRent'),
        checkRequiredEditableField('parkingEditableNotaryFees', 'parkingNotaryFees'),
        checkRequiredEditableField('parkingEditableOtherAcquisitionCost', 'parkingOtherAcquisitionCost'),
    ]),
    checkRequiredIntegerField('data.property.estateBrokerageFees'),
    checkRequiredEditableField('editableNotaryFees', 'notaryFees'),
    checkRequiredEditableField('editableOtherAcquisitionCost', 'otherAcquisitionCost'),
    checkRequiredEditableField(
        'data.property.editableFurnitureDepreciationBasis',
        'data.property.furnitureDepreciationBasis',
    ),
    branchValidate((errors, values) => 'censi-bouvard' === get('', values), [
        checkRequiredField('data.taxBenefitBasis.edited', '"data.taxBenefitBasis.value'),
    ]),
    checkRequiredIntegerField('propertyMonthlyRent'),
    checkRequiredField('data.funding.tvaFunder'),
    checkChoice('data.funding.tvaFunder', tvaFunderTypes),
]);

export default formValidate;
