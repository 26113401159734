// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group-horizontal {
    margin: 0 2rem 0 0;
    width: 26rem;
}

.form-group-horizontal  > span {
    font-size: 1.5rem;
}

.form-group-horizontal > .form-control {
    width: 100%;
    box-shadow: none;
}

.form-group-horizontal > .form-control[type=checkbox] {
    width: 20px;
    cursor: pointer;
}

.form-group-horizontal.double {
    width: 52rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/group/Horizontal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".form-group-horizontal {\n    margin: 0 2rem 0 0;\n    width: 26rem;\n}\n\n.form-group-horizontal  > span {\n    font-size: 1.5rem;\n}\n\n.form-group-horizontal > .form-control {\n    width: 100%;\n    box-shadow: none;\n}\n\n.form-group-horizontal > .form-control[type=checkbox] {\n    width: 20px;\n    cursor: pointer;\n}\n\n.form-group-horizontal.double {\n    width: 52rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
