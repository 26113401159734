import { GET } from '../utils/httpMethods';

const setSimulationSummary = (simulationSummary) => ({
    type: 'SET_SIMULATION_SUMMARY',
    simulationSummary,
});

export const getSimulationSummary = (id) => async (dispatch) => {
    dispatch(setSimulationSummary({}));

    const response = await GET(`/api/simulations/${id}/summary`);

    dispatch(setSimulationSummary(await response.json()));
};
