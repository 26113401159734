import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withStateHandlers } from 'recompose';
import Presets from './Presets';
import MonthlyPayment from './MonthlyPayment';

const LoanDropdowns = ({
    toggle,
    openDropdown,
    change,
    maxAmountLoanable,
}) => (
    <Fragment>
        <MonthlyPayment
            openDropdown={openDropdown}
            toggle={toggle}
        />
        <Presets
            change={change}
            openDropdown={openDropdown}
            toggle={toggle}
            maxAmountLoanable={maxAmountLoanable}
        />
    </Fragment>
);

LoanDropdowns.propTypes = {
    change: PropTypes.func.isRequired,
    maxAmountLoanable: PropTypes.number.isRequired,
    toggle: PropTypes.func.isRequired,
    openDropdown: PropTypes.string,
};

export default withStateHandlers(
    () => ({
        openDropdown: null,
    }),
    {
        toggle: ({ openDropdown }) => (id) => ({
            openDropdown: id === openDropdown ? null : id,
        }),
    },
)(LoanDropdowns);
