const initialState = {
    frame: {},
};

const pdfViewer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'UNSET_PDF_VIEWER':
            return initialState;
        case 'SET_PDF_VIEWER_FRAME':
            return {
                ...state,
                frame: payload.frame,
            };
        case 'SET_PDF_VIEWER_PAGE':
            return {
                ...state,
                page: payload.page,
            };
        case 'SET_PDF_VIEWER_PAGES':
            return {
                ...state,
                page: 1,
                pages: payload.pages,
            };
        default:
            return state;
    }
};

export default pdfViewer;
