import { reduce, pick, keys, mergeWith } from 'lodash/fp';

// we're going to limit the output
const limitTo = (src) => pick(keys(src));

// here is our merge function
// first we limit the property to the ones we want
// and make the sum
const reduceToTotals = (src) => {
    const trimProperties = limitTo(src);

    return reduce((totals, obj) => mergeWith((total, value) => total + value, totals, trimProperties(obj)), src);
};

export default reduceToTotals;
