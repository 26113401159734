import { flowRight, isEmpty } from 'lodash/fp';
import React from 'react';
import { mapProps, branch, renderNothing } from 'recompose';
import PropTypes from 'prop-types';
import { FaUl, FaLi } from 'common/components/fa';
import recommendations from 'common/choices/recommendations';
import { filterRecommendations } from 'common/utils/recommendations';
import Group from 'components/form/Group';
import Title from 'components/Title';

const Approved = ({ ripRecommendations }) => (
    <Group>
        <Title><b>Recommandations validées</b></Title>
        <FaUl className="approved-recommendations">
            {ripRecommendations.map(({ type }) => (
                <FaLi key={type} icon="check" className="valid-color">
                    {recommendations[type].title}
                </FaLi>
            ))}
        </FaUl>
    </Group>
);

Approved.propTypes = {
    ripRecommendations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default flowRight([
    mapProps(({ recommendations: ripRecommendations }) => ({
        ripRecommendations: filterRecommendations(ripRecommendations, true),
    })),
    branch(
        ({ ripRecommendations }) => isEmpty(ripRecommendations),
        renderNothing,
    ),
])(Approved);
