import { flowRight } from 'lodash/fp';
import { connect } from 'react-redux';
import { lifecycle, branch, renderNothing, withState } from 'recompose';
import { loadDataList } from 'actions';
import { GET } from 'utils/httpMethods';
import withRouter from '../../withRouter';

const entityProvider = (getId) => flowRight([
    withRouter,
    connect(null, {
        loadDataList,
    }),
    lifecycle({
        componentDidMount() {
            this.props.loadDataList('entity');
            this.props.loadDataList('user');
        },
    }),
    connect((state, props) => ({
        entity: state.data.entity[getId(props)],
    })),
    branch(({ entity }) => !entity, renderNothing),
    withState('settings', 'setSettings', null),
    lifecycle({
        async componentDidMount() {
            const response = await GET(`/api/entities/${this.props.entity.id}/defaultValues`);
            const settings = await response.json();
            this.props.setSettings(settings);
        },
    }),
    branch(({ settings }) => !settings, renderNothing),
]);

export default entityProvider;
