import { flowRight } from 'lodash/fp';
import { connect } from 'react-redux';
import { downloadPdf } from 'actions';
import { mapProps } from 'recompose';
import Action from './Action';
import withRouter from '../withRouter';

export default flowRight([
    withRouter,
    connect(null, { download: downloadPdf }),
    mapProps(({ url, download, navigate }) => (
        ({
            onClick: () => download(url, navigate),
            icon: 'file-pdf-o',
            text: 'télécharger',
        }))),
])(Action);
