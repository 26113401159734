import React from 'react';
import { compose } from 'recompose';
import { listProvider } from '../list';
import withTutorial from '../withTutorial';
import withSpinner from '../withSpinner';
import ErrorMessage from '../ErrorMessage';
import ListTable from './ListTable';

export const NoInvoiceMessage = () => (
    <ErrorMessage>
        <div>Aucune facture n&apos;a été trouvée</div>
    </ErrorMessage>
);

export default compose(
    listProvider({
        name: 'invoice',
        type: 'invoice',
    }),
    withSpinner,
    withTutorial(NoInvoiceMessage),
)(ListTable);
