import classNames from 'classnames';
import { omit, pick } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import { compose, getContext, withProps } from 'recompose';
import { Field, fieldPropTypes } from 'redux-form';
import withScroll from '../withScroll';
import Error from '../../Error';
import FieldInfo from '../FieldInfo';
import { withDisableField } from './TypedDisabled';

/**
 * Props passed to the input instead of the group
 */
const inputProps = ['placeholder', 'mask', 'maskChar'];

const Phone = ({
    formGroup: Group,
    input: { name, value, ...input },
    info,
    meta,
    required,
    ...props
}) => (
    <Group component="label" htmlFor={name} {...omit(inputProps, props)} required={required}>
        <InputMask
            id={name}
            className={classNames({ filled: value || 0 === value })}
            name={name}
            value={value}
            required={required}
            type="text"
            {...pick(inputProps, props)}
            {...input}
        />
        {info ? <FieldInfo id={name}>{info}</FieldInfo> : null}
        <span className="validation-feedback" />
        <Error {...meta} />
    </Group>
);

Phone.propTypes = {
    ...fieldPropTypes,
    formGroup: PropTypes.func.isRequired,
    required: PropTypes.bool,
    maskChar: PropTypes.string,
    mask: PropTypes.string,
};

Phone.defaultProps = {
    required: false,
    maskChar: null,
    mask: '99 99 99 99 99',
};

export default compose(
    withDisableField(),
    withProps({ component: Phone }),
    getContext({ formGroup: PropTypes.func.isRequired }),
    withScroll,
)(Field);
