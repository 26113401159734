const commonSteps = (contractType) => [
    { name: 'Conditions', target: `conditions/${contractType}` },
    { name: 'Souscription', target: `overview/${contractType}` },
    { name: 'Informations', target: `informations/${contractType}` },
];

const pvpSteps = (contractType) => [
    { name: 'Justificatifs', target: `documents/${contractType}` },
    { name: 'Signature', target: `signatures/${contractType}` },
];

const targetSteps = (contractType) => [
    { name: 'Docs internes', target: `internalDocs/${contractType}` },
    { name: 'Signature', target: `signatures/${contractType}` },
    { name: 'Hub Primonial', target: `primonialHubWarning/${contractType}` },
];

const getWizard = (contractType) => ({
    prefixTemplate: ({ id, contractId }) => `/rip/${id}/contracts/${contractId}`,
    items: [
        ...commonSteps(contractType),
        ...(['pvp', 'pvpImmo'].includes(contractType) ? pvpSteps(contractType) : targetSteps(contractType)),
    ],
});

export default getWizard;
