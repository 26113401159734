// Calcul de l'impot pour hauts revenus.
import { get } from 'lodash/fp';
import getTaxFromSlices from './getTaxFromSlices';

const computeHighIncomesTax = ({ rip: { familySituation }, referenceIncomes, incomeTax }, settings) => {
    // Définition des taux à appliquer en fonction de la situation familiale.
    const situation = ['married', 'partner'].includes(familySituation) ? 'couple' : 'single';
    const taxRates = get(`summary.incomeTax.highIncomesSlices.${situation}`, settings);

    // Calcul de la contribution sur les hauts revenus.
    const { tax } = getTaxFromSlices(referenceIncomes, taxRates);

    return (incomeTax + tax);
};

export default computeHighIncomesTax;
