/**
 * Traduit la valeur 'programDuration' en durée réelle
 * @param {number} valeur
 * @returns {number} La durée du dispositif
 */
const modifieValeurDureeDispositif = (valeur) => {
    let valeurModifiee;

    switch (valeur) {
        case 63:
            valeurModifiee = 9;
            break;
        case 66:
            valeurModifiee = 12;
            break;
        case 93:
            valeurModifiee = 12;
            break;
        default:
            valeurModifiee = valeur;
    }

    return valeurModifiee;
};

export default modifieValeurDureeDispositif;
