import { flowRight, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import { Layout } from 'components/layouts';
import { withDatabase } from 'components/database';
import dataProvider from '../dataProvider';
import { VerticalLayout } from '../form/layout';
import { InputField } from '../form/field';
import FormButtons from '../FormButtons';
import getUrl from './getUrl';

const CloneInner = ({ handleSubmit, params: { id } }) => (
    <Layout className="creation-form">
        <form className="container" onSubmit={handleSubmit} id="main-form">
            <VerticalLayout>
                <InputField name="name" title="Nom de la simulation" type="text" />
            </VerticalLayout>
            <FormButtons back={`/rip/${id}/simulations/`} list={`/rip/${id}/simulations/`} />
        </form>
    </Layout>
);

CloneInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

const Clone = flowRight([
    dataProvider({
        id: ({ params: { simulationId } }) => simulationId,
        type: 'simulation',
        wait: true,
    }),
    withDatabase,
    reduxForm({
        form: 'simulation',
        onSubmit: (values, dispatch, { simulation, database }) => database.simulations.update(uuidv4(), {
            ...omit(['id', 'approval', 'locked'], simulation),
            ...values,
        }),
        onSubmitSuccess: (result, dispatch, { navigate }) => navigate(getUrl(result)),
    }),
])(CloneInner);

export default Clone;
