import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const EmployeeAtHome = ({ name }) => (
    <Fragment>
        <Title>Salarié à domicile</Title>
        <GridLayout className="double condensed full-width">
            <PercentageFloatField
                name={`${name}.rate`}
                title="Taux de crédit (%)"
            />
            <IntegerField
                name={`${name}.ceil`}
                title="Plafond de credit (€)"
            />
            <IntegerField
                name={`${name}.maxCeil`}
                title="Plafond MAX de credit (€)"
            />
            <IntegerField
                name={`${name}.childrenOrDependentPart`}
                title="Majoration par personne à charge (€)"
            />
        </GridLayout>
    </Fragment>
);

EmployeeAtHome.propTypes = {
    name: PropTypes.string.isRequired,
};

export default EmployeeAtHome;
