import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'components/layouts';
import InvestorProfile from 'common/components/rip/InvestorProfile';
import FormButtons from '../FormButtons';
import Title from '../Title';
import profileProvider from './profileProvider';

const ResultInner = ({
    rip: { id },
    target,
    navigate,
    investorProfile,
    next: { path, label },
}) => {
    const handleSubmit = (event) => {
        // prevent the event
        event.preventDefault();
        // and move to the next page
        navigate(path);
    };

    return (
        <Layout hasFooter className="simulation-form simulation-overview">
            <form onSubmit={handleSubmit} id="main-form">
                <div>
                    <div className="simulation-form-inner">
                        <div className="container">
                            <Title>
                                <b>Le profil d&apos;investisseur</b>
                            </Title>
                            <InvestorProfile investorProfile={investorProfile} noChart />
                        </div>
                    </div>
                    <FormButtons
                        list={null}
                        back={`/rip/${id}/investorProfile/${target}`}
                        nextButtonContent={label}
                    />
                </div>
            </form>
        </Layout>
    );
};

ResultInner.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
    target: PropTypes.string.isRequired,
    investorProfile: PropTypes.string.isRequired,
    // history: PropTypes.shape({
    navigate: PropTypes.shape({}),
    // }).isRequired,
    next: PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

const Result = profileProvider(ResultInner);

export default Result;
