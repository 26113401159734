import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InvestorProfile from 'components/simulation/types/lifeInsurance/InvestorProfile';
import Product from 'components/simulation/types/lifeInsurance/Product';
import Details from 'components/simulation/types/lifeInsurance/Details';

const Router = () => (
    <Routes>
        <Route path="/profile" element={<InvestorProfile />} />
        <Route path="/product" element={<Product />} />
        <Route path="/simulation" element={<Details />} />
    </Routes>
);

export default Router;
