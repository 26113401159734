import { some, get } from 'lodash/fp';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, withProps } from 'recompose';
import { Fields } from 'redux-form';
import AsyncSelect from 'react-select/lib/Async';
import Error from 'components/Error';
import withScroll from 'components/form/withScroll';
import tokenize from 'utils/tokenize';
import { mappedOptions, options, noOptionsMessage, customStyles } from './CityField';
import addPrefix from '../../../utils/addPrefix';

class BirthCityField extends Component {
    constructor(props) {
        super(props);

        this.cache = {};

        this.loadOptions = this.loadOptions.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async onChange(value) {
        const { partner } = this.props;
        const getIdentifier = addPrefix('partner')(partner);
        const {
            [getIdentifier('birthPlace')]: {
                input: { onChange: onChangeBirthPlace },
            },
            [getIdentifier('birthZipcode')]: {
                input: { onChange: onChangeBirthZipcode },
            },
        } = this.props;

        const { name, postalCode } = value || { name: null, postalCode: null };

        onChangeBirthPlace(name);
        onChangeBirthZipcode(postalCode);
    }

    get inputValue() {
        const { partner } = this.props;
        const getIdentifier = addPrefix('partner')(partner);
        const {
            [getIdentifier('birthPlace')]: {
                input: { value: place },
            },
            [getIdentifier('birthZipcode')]: {
                input: { value: zipcode },
            },
        } = this.props;

        return place && zipcode ? get(`${zipcode}.${place}`, mappedOptions) : null;
    }

    async loadOptions(inputValue) {
        let filteredOptions = get(inputValue, this.cache);

        if (!filteredOptions) {
            filteredOptions = tokenize(inputValue).reduce(
                (results, word) => results.filter(({ tokens }) => some((token) => token.startsWith(word), tokens)),
                get(inputValue.slice(0, inputValue.length - 1), this.cache) || options,
            );

            this.cache[inputValue] = filteredOptions;
        }

        return filteredOptions.slice(0, 100);
    }

    render() {
        const {
            partner,
            formGroup: Group,
            [`${partner ? 'partnerBirthPlace' : 'birthPlace'}`]: { meta: birthPlaceMeta },
        } = this.props;

        return (
            <Group
                component="label"
                htmlFor="birth"
                title={`Code postal de la commune de naissance${partner ? ' du conjoint' : ''}`}
            >
                <AsyncSelect
                    className="react-select"
                    placeholder="Commune ou code postal"
                    noOptionsMessage={noOptionsMessage}
                    name="birth"
                    isSearchable
                    isClearable
                    loadOptions={this.loadOptions}
                    getOptionValue={({ key }) => key}
                    getOptionLabel={({ label }) => label}
                    onChange={this.onChange}
                    value={this.inputValue}
                    styles={customStyles}
                />
                <Error {...birthPlaceMeta} />
            </Group>
        );
    }
}

BirthCityField.propTypes = {
    birthPlace: PropTypes.shape({}),
    birthZipcode: PropTypes.shape({}),
    formGroup: PropTypes.func.isRequired,
    partner: PropTypes.bool,
};

BirthCityField.defaultProps = {
    partner: false,
};
export default compose(
    withProps(({ partner }) => {
        const getIdentifier = addPrefix('partner')(partner);

        return {
            component: BirthCityField,
            names: [getIdentifier('birthPlace'), getIdentifier('birthZipcode')],
        };
    }),
    getContext({ formGroup: PropTypes.func.isRequired }),
    withScroll,
)(Fields);
