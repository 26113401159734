import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { POST } from '../../utils/httpMethods';
import { useSession } from '../sessions';
import getSubscriptionType from '../../common/utils/getSubscriptionType';

import './Subscription.scss';

const cancelSubscription = async (session) => {
    if (!window.confirm('Confirmez-vous l\'annulation de votre abonnement ?')) {
        return;
    }

    try {
        await POST('/api/payment/unsubscribe');
        await session.sync();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error happens');
    }
};

export const getSubscriptionData = ({ payingUser, subscription, trialEndDate, instanceSettings }, instanceLabelFromEditForm) => {
    if (!payingUser) {
        // no need of extra information
        // this is a free account
        return { type: 'free' };
    }

    const endTrial = moment(trialEndDate);
    const trialing = moment() < endTrial;

    if (subscription) {
        const { type, nextPayment, cancelAtPeriodEnd, status } = subscription;
        const periodEndDate = moment(nextPayment);

        // Pour accèder à la page "mes informations"

        // Depuis le menu accessible par l'icone, il faut extraire le label de l'instance qui est dans le HOC
        let instanceLabelFromHOC;
        if (instanceSettings) {
            instanceLabelFromHOC = instanceSettings.label;
        }

        // Depuis l'administration et l'onglet utilisateurs, le label est en paramètre dans la fonction getSubscriptionData
        const labelOfTheInstance = instanceLabelFromEditForm || instanceLabelFromHOC;

        const data = {
            plan: getSubscriptionType(labelOfTheInstance)[type].title,
            ended: periodEndDate < moment(),
            canceled: 'canceled' === status,
            periodEndDate: periodEndDate.format('DD/MM/YYYY'),
            cancelAtPeriodEnd,
        };

        switch (status) {
            case 'incomplete':
                data.type = 'failedFirstPayment';
                break;
            case 'incomplete_expired':
                data.type = 'failedFirstPayments';
                break;
            case 'canceled':
                data.type = trialing ? 'renewedTrial' : 'canceled';
                break;
            case 'active':
                data.type = cancelAtPeriodEnd ? 'endingSubscription' : 'ongoingSubscription';
                break;
            case 'past_due':
                data.type = 'failedRenewal';
                break;
            case 'trialing':
                data.type = 'renewedTrial';
                break;
            default:
                break;
        }

        return data;
    }

    return {
        type: endTrial < moment() ? 'noSubscription' : 'renewedTrial',
        periodEndDate: endTrial.format('DD/MM/YYYY'),
    };
};

const SubscriptionActions = ({ type, canceled, ended, cancelAtPeriodEnd, session }) => {
    switch (type) {
        // trial or ended trail (with no subscriptions) can subscribe to a plan
        case 'trial':
        case 'noSubscription':
            return (
                <div className="buttons">
                    <Link className="subscription full" to="/subscription">
                        Abonnement
                    </Link>
                </div>
            );

        // otherwise they have all options
        case 'canceled':
        case 'failedFirstPayment':
        case 'failedFirstPayments':
        case 'ongoingSubscription':
        case 'failedRenewal':
        case 'endingSubscription':
        case 'renewedTrial':
            return (
                <div className="buttons">
                    <Link className="subscription full" to="/subscription">
                        {cancelAtPeriodEnd ? 'Choisir une nouvelle offre' : 'Changer d\'offre'}
                    </Link>
                    <Link className="subscription" to="/profile/invoices">
                        Voir mes factures
                    </Link>
                    {!canceled && !ended && !cancelAtPeriodEnd && (
                        <button
                            type="button"
                            className="subscription"
                            onClick={() => cancelSubscription(session)}
                        >
                            Annuler l&apos;abonnement
                        </button>
                    )}
                </div>
            );

        // free account have no options
        case 'free':
        default:
            return null;
    }
};

SubscriptionActions.propTypes = {
    type: PropTypes.string.isRequired,
    ended: PropTypes.bool,
    session: PropTypes.shape({}).isRequired,
    canceled: PropTypes.string,
    cancelAtPeriodEnd: PropTypes.bool,
};

const SubscriptionBody = ({ type, ...data }) => {
    switch (type) {
        case 'failedFirstPayment':
            return (
                <Fragment>
                    <h3>Votre abonnement de {data.plan} a bien été pris en compte.</h3>
                    <p>Le premier paiement à échoué. Un nouvel essai aura lieu dans les prochaines 24h.</p>
                </Fragment>
            );
        case 'failedFirstPayments':
            return (
                <Fragment>
                    <h3>Votre abonnement de {data.plan} n&apos;a pas pu aboutir.</h3>
                    <p>Veuillez contacter le support pour plus d&apos;informations.</p>
                </Fragment>
            );
        case 'free':
            return (
                <h3>
                    <strong>Votre abonnement gratuit est illimité!</strong>
                </h3>
            );
        case 'noSubscription':
            return (
                <h3>
                    Votre essai gratuit s&apos;est terminé le {data.periodEndDate}.
                </h3>
            );
        case 'trial':
            return (
                <Fragment>
                    <h3>Vous bénéficiez de 2 mois d&apos;abonnement offerts (jusqu&apos;au {data.periodEndDate}).</h3>
                    <p>Veuillez vous abonner pour reconduire votre abonnement au delà.</p>
                </Fragment>
            );
        case 'renewedTrial':
            return (
                <Fragment>
                    <h3>Vous bénéficiez de 2 mois d&apos;abonnement offerts (jusqu&apos;au {data.periodEndDate}).</h3>
                    <p>Votre abonnement de {data.type} commencera à l&apos;issue de cette periode.</p>
                </Fragment>
            );
        case 'ongoingSubscription':
        case 'failedRenewal':
            return (
                <Fragment>
                    <h3>Vous avez opté pour l&apos;offre {data.plan}.</h3>
                    {'failedRenewal' === type && (
                        <p>Le prélèvement automatique à échoué mais de nouvelles tentatives vont avoir lieu.</p>
                    )}
                    {!data.cancelAtPeriodEnd && 'failedRenewal' !== type && (
                        <p>Votre abonnement sera automatiquement renouvelé le {data.periodEndDate}.</p>
                    )}
                </Fragment>
            );
        case 'canceled':
            return (
                <Fragment>
                    <h3>Vous aviez opté pour l&apos;offre {data.plan}.</h3>
                    <p>Votre abonnement a pris fin le {data.periodEndDate}.</p>
                </Fragment>
            );
        case 'endingSubscription':
            return (
                <Fragment>
                    <h3>Vous avez opté pour l&apos;offre {data.plan}.</h3>
                    <p>Votre abonnement prendra fin le {data.periodEndDate}.</p>
                </Fragment>
            );
        default:
            return null;
    }
};

SubscriptionBody.propTypes = {
    type: PropTypes.string.isRequired,
};

const Subscription = () => {
    const session = useSession();
    const data = useMemo(() => getSubscriptionData(session), [session]);

    return (
        <div className="article">
            <Title>
                <b>Abonnement</b>
            </Title>
            <GridLayout className="double condensed full-width gray-box subscription-container">
                <div className="double">
                    <SubscriptionBody {...data} />
                    <SubscriptionActions {...data} session={session} />
                </div>
            </GridLayout>
        </div>
    );
};

export default Subscription;
