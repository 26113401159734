import React from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { formValues } from 'redux-form';
import { map, flow, filter } from 'lodash/fp';
import { revenuTypes } from 'common/choices';
import { HorizontalLayout } from '../../form/layout';
import OwnerField from '../field/Owner';
import { IntegerField, DateField, MultiSelectField } from '../../form/field';
import hasPartner from '../../../../../common/src/utils/hasPartner';

const GoingRetired = ({ name, options, beneficiary, familySituation }) => {
    const none = { value: 'none', label: 'Aucun revenu à clôturer' };
    // eslint-disable-next-line no-param-reassign
    options = [...options, none];

    return (
        <HorizontalLayout>
            <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" goingRetiredComponent required />
            <DateField name={`${name}.date`} title="Date prévue de départ en retraite" required />
            <IntegerField name={`${name}.pension`} title="Pension mensuelle nette" required />
            { (beneficiary || !hasPartner(familySituation)) && (
                <MultiSelectField
                    name={`${name}.endingIncomes`}
                    double
                    title="Revenu(s) cloturé(s)"
                    options={options}
                    required
                />
            )}
        </HorizontalLayout>
    );
};

GoingRetired.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    beneficiary: PropTypes.string.isRequired,
    familySituation: PropTypes.string.isRequired,
};

export default compose(
    formValues(({ name }) => ({
        incomes: 'incomes',
        beneficiary: `${name}.beneficiary`,
        familySituation: 'familySituation',
    })),
    withPropsOnChange(['incomes', 'beneficiary'], ({ incomes, beneficiary }) => ({
        options: flow([
            filter(({ type, beneficiary: incomeBeneficiary }) =>
                ['salary', 'BNC', 'BIC', 'BA', 'dividends', 'other'].includes(type) &&
                (beneficiary ? (incomeBeneficiary === beneficiary) : true)),
            map(({
                id,
                type,
                income = null,
                company = null,
                description = null,
                result = null,
            }) => ({ value: id, label: `${revenuTypes[type]} - ${company || description || `${result || income} €`}` })),
        ])(incomes),
    })),
)(GoingRetired);
