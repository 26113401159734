import { FormItem } from 'components/layout/Wizard';

const wizard = (needFunding) => ({
    className: 'wizard-form',
    prefixTemplate: ({ id, simulationId }) => `/rip/${id}/simulations/${simulationId}`,
    itemComponent: FormItem,
    items: [
        {
            name: 'Client',
            target: 'resume',
        },
        {
            name: 'Bien',
            target: 'bien',
            form: 'simulation-property',
        },
        {
            name: 'Financement',
            target: 'financement',
            hide: !needFunding,
            form: 'simulation-funding',
        },
        {
            name: 'Charges',
            target: 'charges',
            form: 'simulation-charges',
        },
        {
            name: 'Simulation',
            target: 'simulation',
            form: 'simulation-details',
        },
    ],
});

export default wizard;
