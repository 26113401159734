import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Collapse } from 'react-bootstrap';
import { withStateHandlers } from 'recompose';
import Icon from 'common/components/Icon';

import './Dropdown.scss';

const DropdownInner = ({ active, onEnter, onExited, open, title, toggle, children, className }) => (
    <div className={classNames('rip-summary-dropdown', { active }, className)}>
        <h6>
            <button type="button" onClick={toggle}>
                {title}
                <Icon icon={active ? 'chevron-up' : 'chevron-down'} className="dropdown-action" />
            </button>
        </h6>
        <Collapse in={open} onEnter={onEnter} onExited={onExited}>
            <div>{children}</div>
        </Collapse>
    </div>
);

DropdownInner.propTypes = {
    active: PropTypes.bool.isRequired,
    onEnter: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    toggle: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

DropdownInner.defaultProps = {
    className: null,
};

export const ControlledDropdown = withStateHandlers(
    ({ active = false }) => ({
        active,
    }),
    {
        onEnter: (state, { onEnter = null }) => () => {
            if (onEnter) {
                onEnter();
            }

            return {
                active: true,
            };
        },
        onExited: () => () => ({
            active: false,
        }),
    },
)(DropdownInner);

export default withStateHandlers(
    ({ active = false }) => ({
        open: active,
    }),
    {
        toggle: ({ open }) => () => ({
            open: !open,
        }),
    },
)(ControlledDropdown);
