import { get } from 'lodash/fp';
import computeInstalmentCosts from 'common/model/simulation/pinel/instalmentCosts';
import responseApprovalFactory from '../../approval/responseApprovalFactory';
import { ApprovalForm } from './RequestApproval';

export default responseApprovalFactory({
    formComponent: ApprovalForm,
    recommendationCheck: ({ rip, simulation }) => {
        if (!get('user.needsValidation', rip)) {
            return false;
        }

        const recommendation = get([
            ...('partner' === simulation.beneficiary ? ['partner'] : []),
            'recommendations',
            'Pinel',
        ], rip);

        const { instalmentCosts: { investment } } = computeInstalmentCosts(simulation);
        const amount = get('data.amount', recommendation);

        return !get('isApproved', recommendation) || !amount || amount < investment;
    },
});
