import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import scrollToFirstError from 'components/form/scrollToFirstError';
import FormButtons from 'components/FormButtons';
import dataForm from 'components/dataForm';
import Error from '../../../../Error';
import { withSession } from '../../../../sessions';
import simulationLoader from '../../../simulationLoader';
import Header from '../Header';
import Form from '../Form';
import Operation from './Operation';
import formValidate, { formWarn } from './formValidate';

const ProductsInner = ({
    handleSubmit,
    params: { id },
    ...meta
}) => (
    <Form handleSubmit={handleSubmit}>
        <div className="simulation-form-inner" id="simulationFormInner">
            <div className="container">
                <Header />
                <Operation />
                <Error {...meta} />
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/`}
            list={`/rip/${id}/simulations/`}
        />
    </Form>
);

ProductsInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
};

const Products = compose(
    simulationLoader,
    withSession,
    dataForm({
        form: 'simulation-product',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => navigate(`/rip/${id}/simulations/${simulationId}/simulation`),
        onSubmitFail: scrollToFirstError({ containerId: 'simulationFormInner' }),
        type: 'simulation',
        validate: formValidate,
        warn: formWarn,
    }),
)(ProductsInner);

export default Products;
