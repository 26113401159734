import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { GridLayout } from 'components/form/layout';
import OwnerField from 'components/rip/field/Owner';
import Mortgages from '../Mortgages';
import OwnerInfo from './OwnerInfo';

const Owner = ({ mainResidenceTenant }) => (
    <Fragment>
        <GridLayout className="triple condensed full-width">
            <OwnerField name="mainResidenceTenant" title="Propriétaire(s)" required showSci />
            <OwnerInfo />
        </GridLayout>
        <Mortgages name="mainResidenceMortgages" owner={mainResidenceTenant} />
    </Fragment>
);

Owner.propTypes = {
    mainResidenceTenant: PropTypes.string.isRequired,
};

export default formValues('mainResidenceTenant')(Owner);
