import moment from 'moment-timezone';

const formatFullDate = (value) => {
    if (!value) {
        return 'NC';
    }

    const date = value instanceof moment ? value : moment(value);

    return date.format('DD/MM/YYYY');
};

export default formatFullDate;
