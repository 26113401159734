import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import { branch, lifecycle, renderNothing } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import { getSimulationSummary } from 'actions';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import simulationLoader from '../simulationLoader';
import Hypothesis from './Hypothesis';

import './Summary.scss';
import Contracts from './Contracts';

const SummaryInner = ({ simulationSummary: { low, medium, high } }) => (
    <FormGroup>
        <Title><b>Récapitulatif</b></Title>
        <div className="av-summary-warning">
            <p>
                Cette simulation est lissée sur le temps et ne présente pas la volatilité, pour rappel les unités de
                compte ne sont pas garanties en capital et sont susceptibles de fluctuer et d’être négatives. Ces
                scénarios ne peuvent pas etre garantis. Les simulations présentent des performances nettes des frais du
                contrat, hors prélèvements sociaux et fiscaux.
            </p>
        </div>
        <Hypothesis title="Hypothèse basse" data={low} />
        <Hypothesis title="Hypothèse médiane" data={medium} active />
        <Hypothesis title="Hypothèse haute" data={high} />
        <br />
        <Contracts />
    </FormGroup>
);

SummaryInner.propTypes = {
    simulationSummary: PropTypes.shape({
        low: PropTypes.shape({
            capital: PropTypes.number,
            fundings: PropTypes.number,
            interests: PropTypes.number,
            simulation: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
        }).isRequired,
        medium: PropTypes.shape({
            capital: PropTypes.number,
            fundings: PropTypes.number,
            interests: PropTypes.number,
            simulation: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
        }).isRequired,
        high: PropTypes.shape({
            capital: PropTypes.number,
            fundings: PropTypes.number,
            interests: PropTypes.number,
            simulation: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
        }).isRequired,
    }),
};

const mapStateToProps = (state) => ({ simulationSummary: state.simulationSummary });

const mapDispatchToProps = { getSimulationSummary };

const Summary = flowRight([
    simulationLoader,
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        componentDidMount() {
            const { simulation, getSimulationSummary: computeSummary } = this.props;
            computeSummary(simulation.id);
        },
    }),
    branch(
        ({ simulationSummary: { low, medium, high } }) => !low || !medium || !high,
        renderNothing,
    ),
])(SummaryInner);

export default Summary;
