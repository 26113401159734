import { compose } from 'recompose';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import modes from './modes';
import { withSession } from '../sessions';

const ListInner = ({ mode, component: Component }) => Component && <Component key={mode} />;

ListInner.propTypes = {
    mode: PropTypes.string,
    component: PropTypes.func,
};

ListInner.defaultProps = {
    mode: null,
    component: null,
};

const formValues = formValueSelector('pendingValidation-modes');

const List = compose(
    withSession,
    connect((state, props) => {
        let mode = formValues(state, 'mode');

        const filter = formValues(state, 'filter');
        const enabled = state.flagUnleash.activated;
        const { session } = props;
        if (enabled && (session.permissions.isCaseManager() || (session.needApprovalOnSimulations && session.needApprovalOnCIFDocument))) {
            return {
                mode,
                component: mode && filter && modes.modesCifAndSimulations[mode] && modes.modesCifAndSimulations[mode][filter].component,
            };
        }

        if (enabled && !session.needApprovalOnSimulations && session.needApprovalOnCIFDocument) {
            mode = 'documentsCIF';

            return {
                mode,
                component: mode && filter && modes.modesCifOnly[mode] && modes.modesCifOnly[mode][filter].component,
            };
        }

        return {
            mode,
            component: mode && filter && modes.modesSimulationOnly[mode] && modes.modesSimulationOnly[mode][filter].component,
        };
    }),
)(ListInner);

export default List;
