import classNames from 'classnames';
import React from 'react';
import { withProps } from 'recompose';
import { Field, fieldPropTypes, formValues } from 'redux-form';
import Icon from '../../common/components/Icon';

import './SeeMore.css';

const SeeMoreInner = ({ input: { value, ...input }, meta, ...props }) => (
    <div className={classNames('rip-see-more', value ? 'primary' : null)}>
        <label htmlFor={input.name}>
            <input
                id={input.name}
                {...input}
                {...props}
                checked={value}
                type="checkbox"
            />
            <Icon icon={value ? 'minus' : 'plus'} />
        </label>
    </div>
);

SeeMoreInner.propTypes = fieldPropTypes;

const SeeMore = withProps(({ name }) => ({
    component: SeeMoreInner,
    name: `expanded${name}`,
}))(Field);

export const withSeeMore = formValues(({ name }) => ({
    seeMore: `expanded${name}`,
}));

export default SeeMore;
