const modes = {
    scpi: {
        label: 'SCPI',
    },
    av: {
        label: 'Assurance-vie',
    },
    per: {
        label: 'PER',
    },
};

export default modes;
