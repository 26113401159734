class Manager {
    constructor(settings) {
        this.settings = settings;
    }

    get isAvailable() {
        return this.settings.is in document;
    }

    get isActive() {
        return document[this.settings.is];
    }

    request(target) {
        target[this.settings.request].call(target);
    }

    exit() {
        document[this.settings.exit]();
    }

    listen(listener) {
        // register the listener for the event
        document.addEventListener(this.settings.listener, listener);

        // returns a function to call to stop listening
        return () => document.removeEventListener(this.settings.listener, listener);
    }
}

const managers = [
    // Common
    new Manager({
        is: 'fullScreen',
        listener: 'fullscreenchange',
        request: 'requestFullscreen',
        exit: 'exitFullscreen',
    }),
    // Webkit
    new Manager({
        is: 'webkitIsFullScreen',
        listener: 'webkitfullscreenchange',
        request: 'webkitRequestFullscreen',
        exit: 'webkitExitFullscreen',
    }),
    // Mozilla
    new Manager({
        is: 'mozFullScreen',
        listener: 'mozfullscreenchange',
        request: 'mozRequestFullScreen',
        exit: 'mozCancelFullScreen',
    }),
    // Microsoft
    new Manager({
        is: 'msFullscreenEnabled',
        listener: 'MSFullscreenChange',
        request: 'requestFullscreen',
        exit: 'msExitFullscreen',
    }),
];

const getFullScreenManager = () => {
    for (const manager of managers) {
        if (manager.isAvailable) {
            return manager;
        }
    }

    return null;
};

export default getFullScreenManager();
