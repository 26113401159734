import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'components/form/Group';
import Allocations from './Allocations';
import Funding from './Funding';
import Result from './Result';
import Settings from './Settings';

const SummaryInner = ({ id, data }) => (
    <FormGroup>
        <Settings data={data} />
        <Allocations data={data} />
        <Funding data={data} />
        <Result data={data} id={id} />
    </FormGroup>
);

SummaryInner.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({}).isRequired,
};

export default SummaryInner;
