import React from 'react';
import Payouts from './Payouts';
import Layout from '../../layouts/Layout';

const InvoicesList = () => (
    <Layout>
        <h1 className="text-center">Factures par versements</h1>
        <Payouts />
    </Layout>
);

export default InvoicesList;
