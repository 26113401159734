// Revenus annuels nets avant abattements (somme des revenus déclarés)

import { filter, flow, get, has, map, sum, toInteger, find } from 'lodash/fp';
import { getSciShareFromAmount } from '../utils';

const getIncome = ({ income = 0 }) => toInteger(income);

const getResult = ({ result = 0 }) => toInteger(result);

const getPension = ({ pension = 0 }) => toInteger(pension);

export const computeIncome = {
    salary: getIncome,
    BNC: getResult,
    BIC: getResult,
    BA: getResult,
    landRentalIncome: getIncome,
    furnishedRentalIncome: getIncome,
    dividends: getIncome,
    pension: getPension,
    other: getIncome,
    exemptedIncome: getIncome,
    childrenIncome: getIncome,
};

const computeRawRentalIncome = {
    landRentalIncome: getIncome,
    furnishedRentalIncome: getIncome,
};

const filterIncomes = (rip) => ({ type, ...income }) => {
    switch (type) {
        // Remove children not anymore in the fiscal household
        case 'childrenIncome': {
            const childId = income.child;
            const child = find(({ id }) => childId === id, rip.children);

            if (!child) {
                return false;
            }

            return 'outside_household' !== child.situation && !child.deadChild;
        }
        default:
            return has(type, computeIncome);
    }
};

const computeIncomes = (rip) => {
    const rawIncomes = flow([
        get('incomes'),
        filter(filterIncomes(rip)),
        map(({ type, ...details }) => {
            const amount = computeIncome[type](details);

            return amount > 0 ? amount : 0;
        }),
        sum,
    ])(rip);

    const rentalPropertyIncomes = flow([
        get('property'),
        filter(({ type }) => 'rentalProperty' === type),
        map(({ rentalIncome, owner }) => getSciShareFromAmount(rentalIncome, rip, owner)),
        sum,
    ])(rip);

    const rawRentalIncomes = flow([
        get('incomes'),
        filter(({ type }) => has(type, computeRawRentalIncome)),
        map(({ type, ...details }) => computeRawRentalIncome[type](details)),
        sum,
    ])(rip);

    return {
        rawIncomes,
        rawRentalIncomes,
        rentalPropertyIncomes,
        rawProfessionalIncomes: rawIncomes - rawRentalIncomes,
    };
};

export default computeIncomes;
