import { createElement } from 'react';
import _downloadPdf from '../utils/downloadPdf';
import _syncRipAndDownload from '../utils/syncRipAndDownload';
import PdfSpinner from '../components/PdfSpinner';
import { setModal } from './modal';

const unsetPdfError = () => ({
    type: 'PDF_ERROR_UNSET',
});

const setPdfError = ({ type, data = null }) => ({
    type: 'PDF_ERROR_SET',
    error: { type, data },
});

const handleError = (navigate, dispatch) => (error) => {
    dispatch(setPdfError(error));
    navigate('/pdfError');
};

export const downloadPdf = (url, navigate, options) => (dispatch) => {
    dispatch(unsetPdfError());
    dispatch(setModal(() => createElement(PdfSpinner), false));

    return _downloadPdf(url, options)
        .catch(handleError(navigate, dispatch))
        .finally(() => dispatch(setModal(null)));
};

export const syncRipAndDownload = (id, navigate, full = false, url = null, options = {}) => (dispatch, getState, { database }) => {
    dispatch(unsetPdfError());
    dispatch(setModal(() => createElement(PdfSpinner), false));

    return _syncRipAndDownload(id, database, full, url, options)
        .catch(handleError(navigate, dispatch))
        .finally(() => dispatch(setModal(null)));
};
