import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../common/components/Icon';

const getId = ({ name, value }) => `${name}-${value}`;

const Product = ({ product: { label, icon, path }, classValue = null, ...props }) => {
    const id = getId(props);
    const isNew = 'per' === classValue;

    return (
        <label
            className={classNames(classValue, { selected: props.checked })}
            htmlFor={id}
        >
            {isNew ? <div className="new"> <p>Nouveauté</p></div> : null}
            <input id={id} type="radio" {...props} />
            <div className="label-icon">
                <Icon icon={icon} path={path} />
            </div>
            <div className="label-text">
                <span>{label}</span>
            </div>
        </label>
    );
};

Product.propTypes = {
    checked: PropTypes.bool,
    classValue: PropTypes.string,
    isNew: PropTypes.bool,
    product: PropTypes.shape({
        icon: PropTypes.string,
        path: PropTypes.string,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

Product.defaultProps = {
    checked: false,
    classValue: null,
    isNew: false,
};

export default Product;
