import Cookies from 'universal-cookie';

import cleanUrl from '../../../common/src/utils/cleanUrl';
import isKnownTopinvestUrl from '../../../common/src/utils/isKnownTopinvestUrl';
import cleanDataNavigation from './cleanDataNavigation';

const redirectToTopinvest = async (session, simulation, erip, typeSimulation) => {
    const cookies = new Cookies();
    const jeton = cookies.get('__jeton');
    const topinvestHost = jeton && decodeURI(jeton.split('¤')[2]);

    const { defaultUrlTopinvest, simulationUrl } = session;
    const returnUrlTopinvest = topinvestHost && isKnownTopinvestUrl(topinvestHost)
        ?
        `https://${topinvestHost}`
        :
        defaultUrlTopinvest;

    cleanUrl(returnUrlTopinvest);
    cleanDataNavigation();

    cookies.remove('__jeton', { path: '/' });
    cookies.remove('session', { path: '/' });

    window.location.assign(simulation && erip && typeSimulation
        ?
        `${returnUrlTopinvest}/${simulationUrl}?erip=${erip}&type=${typeSimulation}`
        :
        returnUrlTopinvest);
};

export default redirectToTopinvest;
