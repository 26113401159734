import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../common/components/Icon';

import './Card.css';

const Card = ({ title, icon, path, ...props }) => (
    <div className="simulator-card" {...props}>
        <div className="simulator-image">
            <Icon icon={icon} path={path} />
        </div>
        <p className="simulator-title">{title}</p>
    </div>
);

Card.propTypes = {
    icon: PropTypes.string,
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
};

Card.defaultProps = {
    icon: null,
    path: null,
};

export default Card;
