import PropTypes from 'prop-types';
import React from 'react';
import getInitials from '../../utils/getInitials';

const Initials = ({ firstName, lastName, children }) => (
    <td className="initials">
        <div>
            {getInitials(lastName, firstName)}
            {children}
        </div>
    </td>
);

Initials.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    children: PropTypes.node,
};

Initials.defaultProps = {
    children: null,
    firstName: '',
    lastName: '',
};

export default Initials;
