import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Spinner.scss';

const Spinner = ({ children, eSigne = false }) => {
    const initialMessages = ['Demande de création du document', 'Envoi des données à eSigne', 'Génération du document en cours'];
    const [messageIndex, setMessageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setMessageIndex((prevIndex) => {
                if (prevIndex + 1 === initialMessages.length) {
                    clearInterval(intervalId);

                    return prevIndex;
                }

                return prevIndex + 1;
            });
        }, 4000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="spinner">
            <div />
            {eSigne ? initialMessages[messageIndex] : children && <p>{children}</p>}
        </div>
    );
};

Spinner.propTypes = {
    children: PropTypes.node,
    eSigne: PropTypes.bool,
};

export default Spinner;
