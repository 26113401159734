import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { withProps } from 'recompose';
import hasPaid from 'utils/hasPaid';
import RipCreation from 'components/rip/Creation';
import RipVitalRecord from 'components/rip/VitalRecord';
import RipTaxes from 'components/rip/Taxes';
import RipSummaryAfterTaxes from 'components/rip/taxes/SummaryAfter';
import RipFinances from 'components/rip/Finances';
import RipEstate from 'components/rip/Estate';
import RipClientProfile from 'components/rip/Profile';
import RipCommitment from 'components/rip/Commitment';
import RipAppointment from 'components/rip/Appointment';
import RipApproval from 'components/rip/Approval';
import CifApproval from 'components/rip/ApprovalCif';
import { useSession } from 'components/sessions';
import SimulationCreation from 'components/simulation/Creation';
import SimulationClone from 'components/simulation/Clone';
import SimulationForm from 'components/simulation/Form';
import SubcriptionProcess from 'components/subscription/notAuthorized';
import ContractList from 'components/contract/List';
import ContractCreation from 'components/contract/Creation';
import InvestorProfile from './InvestoreProfileRouter';
import ContractForm from '../contract/ContractRouter';
import BaseSignatureRouter from '../signature/SignatureRouter';
import DocumentRouter from './DocumentRouter';
import SignatureState from '../../../rip/SignatureState';
import SimulationList from '../../../simulation/List';

const SignatureRouter = withProps({ prefix: '/rip/:ripId' })(BaseSignatureRouter);

const RipAccess = () => {
    const session = useSession();

    if (!hasPaid(session)) {
        return (
            <Routes>
                <Route path="/*" element={<SubcriptionProcess />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/new" element={<RipCreation />} />
            <Route path="/:id/etat-civil" element={<RipVitalRecord />} />
            <Route path="/:id/impots" element={<RipTaxes />} />
            <Route path="/:id/summary" element={<RipSummaryAfterTaxes />} />
            <Route path="/:id/finances" element={<RipFinances />} />
            <Route path="/:id/patrimoine" element={<RipEstate />} />
            <Route path="/:id/profil-client" element={<RipClientProfile />} />
            <Route path="/:id/commitment" element={<RipCommitment />} />
            <Route path="/:id/investorProfile/*" element={<InvestorProfile />} />
            <Route path="/:id/appointment" element={<RipAppointment />} />
            <Route path="/:id/signatureState" element={<SignatureState />} />
            <Route path="/:id/documents/*" element={<DocumentRouter />} />
            <Route path="/:id/approval" element={<RipApproval />} />
            <Route path="/:id/approvalCif/:target" element={<CifApproval />} />

            <Route key="all-simulation" path="/:id/simulations/" element={<SimulationList />} />,
            <Route path="/:id/simulations/new" element={<SimulationCreation />} />
            <Route path="/:id/simulations/:simulationId/clone" element={<SimulationClone />} />
            <Route path="/:id/simulations/:simulationId/*" element={<SimulationForm />} />
            <Route path="/:id/contracts" element={<ContractList />} />
            <Route path="/:id/contracts/new" element={<ContractCreation />} />
            <Route path="/:id/contracts/:contractId/*" element={<ContractForm />} />
            <Route path="/:ripId/signatures" element={<SignatureRouter />} />
        </Routes>
    );
};

export default RipAccess;
