import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Overview from 'components/simulation/types/pinelDenormandie/Overview';
import Property from 'components/simulation/types/pinelDenormandie/Property';
import Funding from 'components/simulation/types/pinelDenormandie/Funding';
import Charges from 'components/simulation/types/pinelDenormandie/Charges';
import Details from 'components/simulation/types/pinelDenormandie/Details';
import { SummaryForm, SummaryPage } from 'components/simulation/types/pinelDenormandie/Summary';
import RequestApproval from 'components/simulation/types/pinelDenormandie/RequestApproval';
import ResponseApproval from 'components/simulation/types/pinelDenormandie/ResponseApproval';

const Pinel = () => (
    <Routes>
        <Route path="/resume" element={<Overview />} />
        <Route path="/bien" element={<Property />} />
        <Route path="/financement" element={<Funding />} />
        <Route path="/charges" element={<Charges />} />
        <Route path="/simulation" element={<Details />} />
        <Route path="/summary/form" element={<SummaryForm />} />
        <Route path="/approval/request" element={<RequestApproval />} />
        <Route path="/approval/:approval" element={<ResponseApproval />} />
        <Route path="/summary" element={<SummaryPage />} />
    </Routes>
);

export default Pinel;
