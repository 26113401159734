import FileSaver from 'file-saver';
import { GET } from './httpMethods';

/*  FYI
    the following code for the content disposition mostly comes from the content package on npm
    https://github.com/hapijs/content/blob/master/lib/index.js
 */

const contentDispositionRegex = /^\s*attachment\s*(?:;\s*(.+))?$/i;
const contentDispositionParamRegex =
/([^=*\s]+)(\*)?\s*=\s*(?:([^;'"\s]+'[\w-]*'[^;\s]+)|(?:"([^"]*)")|([^;\s]*))(?:\s*(?:;\s*)|$)/g;

const getFilenameByContentDisposition = (contentDisposition) => {
    if (!contentDisposition) {
        return null;
    }

    const match = contentDisposition.match(contentDispositionRegex);

    if (!match || !match[1]) {
        return null;
    }

    const result = {};

    match[1].replace(contentDispositionParamRegex, ($0, $1, $2, $3, $4, $5) => {
        if ($2) {
            result[$1] = decodeURIComponent($3.split('\'')[2]);
        } else {
            result[$1] = $4 || $5 || '';
        }
    });

    return result.filename;
};

const downloadPdf = async (url, { method = GET, data = null, forcedFileName = null } = {}) => {
    try {
        const response = await method(url, { responseType: 'blob', body: data });
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = forcedFileName || getFilenameByContentDisposition(contentDisposition);
        const blob = await response.blob();
        FileSaver.saveAs(blob, filename);
    } catch ({ response, status }) {
        if (400 === status && response) {
            throw (await response.json()).error;
        }
    }

    return null;
};

export default downloadPdf;
