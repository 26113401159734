import { find } from 'lodash/fp';

const getOwnerName = (rip, owner) => {
    const match = owner.match(/^sci-(.*)/);

    if (match) {
        const sci = find({ id: match[1] }, rip.sci);

        return `SCI ${sci && sci.sciName}`;
    }

    switch (owner) {
        case 'client':
            return rip.firstName;
        case 'partner':
            return rip.partnerFirstName;
        case 'clientAndPartner':
            return `${rip.firstName} et ${rip.partnerFirstName}`;
        default:
            return null;
    }
};

export default getOwnerName;
