import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { HorizontalLayout } from '../../form/layout';
import {
    IntegerField,
    TextField,
} from '../../form/field';
import OwnerField from '../field/Owner';

const ProfessionalInvestmentsInner = ({ name }) => (
    <HorizontalLayout>
        <TextField title="Nom de la société" name={`${name}.companyName`} />
        <TextField title="Forme de la société" name={`${name}.structure`} />
        <IntegerField title="Valeur des parts" name={`${name}.value`} />
        <OwnerField name={`${name}.subscriber`} title="Souscripteur" required single child />
    </HorizontalLayout>
);

ProfessionalInvestmentsInner.propTypes = {
    name: PropTypes.string.isRequired,
};

const ProfessionalInvestments = formValues(({ name }) => ({
    beneficiary: `${name}.beneficiary`,
}))(ProfessionalInvestmentsInner);

export default ProfessionalInvestments;
