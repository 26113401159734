import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { periodicities } from 'common/choices';
import { Grid, DateItem, ChoiceItem, AmountItem, PercentageItem } from 'common/components/summary';
import Title from 'components/Title';
import Group from 'components/form/Group';

import './ScheduledPayments.scss';

const ScheduledPayments = ({
    simulation: {
        data: {
            scheduledPayments: {
                periodicity,
                amount,
                fees,
                firstPaymentDate,
            } = {},
        },
    },
}) => {
    const firstPaymentDateTooEarly = moment().add(36, 'd') > moment(firstPaymentDate);

    return (amount ? (
        <Group>
            <Title><b>Versements programmés</b></Title>
            <Grid size={2}>
                <div className={classNames('summary-item', { error: firstPaymentDateTooEarly })}>
                    <DateItem
                        value={firstPaymentDate}
                        title="Date du début de programmation"
                    />
                    {firstPaymentDateTooEarly ? (
                        <div className="firstPaymentDate-error-message">
                            La date de premier versement doit être supérieure
                            au <b>Jour de saisie</b> de la souscription + 30 jours calendaires
                            (délai de renonciation) + 6 jours ouvrés.
                        </div>
                    ) : null}
                </div>
                <ChoiceItem title="Périodicité" value={periodicity} choices={periodicities} />
                <AmountItem title="Montant" value={amount} />
                <PercentageItem title="Frais d'entrée (%)" value={fees} />
            </Grid>
        </Group>
    ) : null);
};

ScheduledPayments.propTypes = {
    simulation: PropTypes.shape({
        data: PropTypes.shape({
            scheduledPayments: PropTypes.shape({
                periodicity: PropTypes.string,
                amount: PropTypes.number,
                fees: PropTypes.number,
                firstPaymentDate: PropTypes.string,
            }),
        }),
    }).isRequired,
};

export default ScheduledPayments;
