import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { IntegerField } from 'components/form/field';

const Education = ({ name }) => (
    <Fragment>
        <Title>Frais de scolarité</Title>
        <GridLayout className="double condensed full-width">
            <IntegerField
                name={`${name}.highSchool`}
                title="Montant pour enfant lycéen (€)"
            />
            <IntegerField
                name={`${name}.middleSchool`}
                title="Montant pour enfant collégien (€)"
            />
            <IntegerField
                name={`${name}.higherEducation`}
                title="Montant pour enfant étudiant (€)"
            />
        </GridLayout>
    </Fragment>
);

Education.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Education;
