import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import { branch, renderNothing } from 'recompose';
import { formValues } from 'redux-form';

const ConditionalNode = ({ children }) => children;

ConditionalNode.propTypes = {
    children: PropTypes.node.isRequired,
};

export default flowRight([
    formValues(({ field }) => ({ value: field })),
    branch(({ value, condition = null }) => !(condition ? condition(value) : value), renderNothing),
])(ConditionalNode);
