import validate, { checkRequiredField, checkPasswordStrongEnough, checkEqualFields } from 'common/formValidation/lib';

const formValidate = validate(() => [
    checkEqualFields(
        'passwordConfirmation', 'password',
        'La confirmation de mot de passe ne correspond pas au mot de passe',
    ),
    checkRequiredField('password'),
    checkRequiredField('passwordConfirmation'),
    checkPasswordStrongEnough('password'),
]);

export default formValidate;
