import PropTypes from 'prop-types';
import React from 'react';
import hasPartner from 'common/utils/hasPartner';
import RadioQuestion from '../../../../InvestorProfile/RadioQuestion';

const Form = ({
    rip: {
        familySituation,
        firstName,
        partnerFirstName,
    },
}) => {
    const withPartner = hasPartner(familySituation);
    const isCohabitation = 'cohabitation' === familySituation;

    return withPartner ? (
        <RadioQuestion
            choices={{
                client: firstName,
                partner: partnerFirstName,
                ...(!isCohabitation ? {
                    clientAndPartner: `${firstName} et ${partnerFirstName}`,
                } : null),
            }}
            className={!isCohabitation ? 'triple' : null}
            name="beneficiary"
            title="Bénéficiaire"
            required
        />
    ) : null;
};

Form.propTypes = {
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
        firstName: PropTypes.string,
        partnerFirstName: PropTypes.string,
    }).isRequired,
};

export default Form;
