import classNames from 'classnames';
import { sumBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { formatPercentage } from 'common/utils/format';
import FormGroup from 'components/form/Group';
import RadioGroup from 'components/form/RadioGroup';
import Collection from 'components/form/Collection';
import FlexVerticalRadioButton from 'components/form/FlexVerticalRadioButton';
import Beneficiary from '../DocumentsUpload/Beneficiary';
import FreeClause from './FreeClause';

import './Clause.scss';

const ClauseInner = ({ uploader, clauseBeneficiary, freeClause }) => {
    const totalProportion = clauseBeneficiary && 0 < clauseBeneficiary.length
        ? sumBy(({ proportion }) => (proportion ? parseFloat(proportion) : 0), clauseBeneficiary)
        : 0;

    return (
        <FormGroup title="Le souscripteur désigne comme bénéficiaire(s) en cas de décès avant le terme de la souscription : ">
            <RadioGroup name="data.freeClause">
                <FlexVerticalRadioButton
                    title={<span><b>Clause générale :</b> son conjoint non séparé de corps ou la personne avec laquelle il a conclu un pacte civil de solidarité (Pacs) en vigueur à la date du décès, à défaut ses enfants nés ou à naître, vivants ou représentés par parts égales, à défaut ses héritiers en proportion de leurs parts héréditaires y compris les légataires universels.</span>}
                    value="generalClause"
                />
                {/*
                Disable those clause for now because it's not supported by our API

                <FlexVerticalRadioButton
                    title={<b>Clause libre papier</b>}
                    value="freeClause"
                />
                <FlexVerticalRadioButton
                    title={<span><b>Clause libre :</b> autres bénéficiaires</span>}
                    value="otherFreeClause"
                />
                */}
            </RadioGroup>
            <div className="clause-details">
                {'freeClause' === freeClause ? (
                    <FreeClause uploader={uploader} />
                ) : null}
                {'otherFreeClause' === freeClause ? (
                    <Fragment>
                        <p>Veuillez désigner ci-dessous les autres bénéficiaires</p>
                        <Collection
                            name="data.clauseBeneficiary"
                            component={Beneficiary}
                            addLabel="Ajouter un bénéficiaire"
                        />
                        <div
                            className={classNames('total-proportion', {
                                grey: 1 > totalProportion,
                                red: 1 < totalProportion,
                            })}
                        >
                            <p>Total des parts : {formatPercentage(totalProportion)}</p>
                        </div>
                    </Fragment>
                ) : null}
            </div>
        </FormGroup>
    );
};

ClauseInner.propTypes = {
    uploader: PropTypes.func.isRequired,
    clauseBeneficiary: PropTypes.arrayOf(PropTypes.shape({})),
    freeClause: PropTypes.string,
};

const Clause = formValues({
    freeClause: 'data.freeClause',
    clauseBeneficiary: 'data.clauseBeneficiary',
})(ClauseInner);

export default Clause;
