import moment from 'moment';

const hasPaid = (session) => {
    const {
        instanceSettings: { hasStripeSubscription },
        payingUser,
        trialEndDate,
        activityEndDate,
    } = session;

    if (!hasStripeSubscription) {
        return true;
    }

    // free users
    if (!payingUser) {
        return true;
    }

    // no subscription → freemium
    if (moment() < moment(trialEndDate)) {
        return true;
    }

    return moment(activityEndDate) >= moment();
};

export default hasPaid;
