// Parts fiscales

import { clamp, filter, flow, forEach, map, sortBy, sum, includes } from 'lodash/fp';

const computeParts = (rip) => {
    let parts = 0;
    let halfPartsCount = 0;
    let quarterPartsCount = 0;

    // Base part
    parts += ['married', 'partner'].includes(rip.familySituation) ? 2 : 1;

    // Handicap des clients
    if (includes('disabled', rip.fiscalParticularCases)) {
        parts += 0.5;
    }
    if (['married', 'partner'].includes(rip.familySituation) &&
        includes('disabled', rip.partnerFiscalParticularCases)) {
        parts += 0.5;
    }

    // Former war veterans
    if (includes('warVeteran', rip.fiscalParticularCases) &&
        !includes('disabled', rip.fiscalParticularCases)) {
        parts += 0.5;
    }

    if (['married', 'partner'].includes(rip.familySituation) &&
        includes('warVeteran', rip.partnerFiscalParticularCases) &&
        !includes('disabled', rip.partnerFiscalParticularCases)) {
        parts += 0.5;
    }

    // Get dependent children
    const dependentChildren = flow([
        filter((child) => 'outside_household' !== child.situation),
        sortBy((child) => ('dependant' === child.situation ? 0 : 1)),
    ])(rip.children);

    // Widower bonus part for a dependent child or person
    if ('widower' === rip.familySituation && includes('widowerWithMultiplePersonInCharge', rip.fiscalParticularCases)) {
        parts += 1;
        halfPartsCount += 2;
    }

    // Demi-part supplémentaire pour les Célibataires, Divorcés ou Veuf(ve) qui :
    // - ont un ou plusieurs enfants majeurs ou mineurs faisant l’objet d’une imposition distincte;
    // - ont eu a charge ces enfants pendant au moins cinq années au cours desquelles ils vivaient seul.
    if (includes('singleHavingRaisedChildMoreThan5Years', rip.fiscalParticularCases)) {
        parts += 0.5;
        halfPartsCount += 1;
    }

    // Children parts
    flow([
        (arr) => arr.map((child, index) => {
            let part = index < 2 ? 0.5 : 1;
            if (child.disabled) {
                part += 0.5;
            }

            if ('alternate_custody' === child.situation) {
                part /= 2;
            }
            // parts des enfants pour des concubins
            const isInCohabitation = 'cohabitation' === rip.originalFamilySituation;
            if ('single' === rip.familySituation && isInCohabitation && 'clientAndPartner' === child.fiscalHome) {
                part /= 2;
            }

            return part;
        }),
        forEach((part) => {
            parts += part;
            quarterPartsCount += (part % 0.5) / 0.25;
            halfPartsCount += (part - (part % 0.5)) / 0.5;
        }),
    ])(dependentChildren);

    // personne veuve ayant ≥ 1 personne à charge
    if ('widower' === rip.familySituation && dependentChildren.length > 0) {
        parts += 1;
    }
    // Other dependents
    flow([
        filter((dependent) => 'outside_household' !== dependent.situation),
        map((dependent) => {
            const parsed = parseFloat(dependent.parts);

            return Number.isNaN(parsed) ? 0 : parsed;
        }),
        forEach((part) => {
            parts += part;
            quarterPartsCount += (part % 0.5) / 0.25;
            halfPartsCount += (part - (part % 0.5)) / 0.5;
        }),
    ])(rip.dependents);

    // Single parent
    if (['single', 'divorced'].includes(rip.familySituation) && includes('singleParent', rip.fiscalParticularCases)) {
        const part = flow([
            map((child) => ('alternate_custody' === child.situation ? 0.25 : 0.5)),
            sum,
            clamp(0, 0.5),
        ])(dependentChildren);
        parts += part;
        quarterPartsCount += (part % 0.5) / 0.25;
        halfPartsCount += (part - (part % 0.5)) / 0.5;
    }

    return { parts, halfPartsCount, quarterPartsCount };
};

export default computeParts;
