import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.resetErrors = this.resetErrors.bind(this);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    /* eslint-disable-next-line class-methods-use-this */
    componentDidCatch(error, info) {
        console.error('Error :', error);
        console.warn('Info :', info);
        Sentry.captureException(error);
    }

    resetErrors() {
        this.setState({
            hasError: false,
        });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage resetErrors={this.resetErrors} />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
