import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { FooterContext } from 'components/layouts';
import PrevButton from './buttons/PrevButton';
import NavButton from './buttons/NavButton';
import NextButton from './buttons/NextButton';

import './FormButtons.scss';

const FormButtons = (props) => {
    // get the footer element on which we'll attach the portal
    const footerElement = useContext(FooterContext);
    // we won't attach the portal directly into it but in a div as children
    const rootElement = useMemo(() => document.createElement('div'), []);

    // append/remove this div on mounting cycles
    useEffect(() => {
        footerElement.appendChild(rootElement);

        return () => {
            footerElement.removeChild(rootElement);
        };
    }, [footerElement]);

    const {
        last,
        showNav,
        showPrev,
        prevButtonComponent: Prev,
        navButtonComponent: Nav,
        nextButtonComponent: Next,
        isHiddenButton,
        ...rest
    } = props;

    const { list, hideBackButton } = props;

    // get button elements
    const prevButton = showNav && showPrev && <Prev {...rest} />;
    const navButton = null !== list && <Nav {...rest} />;
    const nextButton = !last && showNav && <Next {...rest} />;

    return createPortal(
        (
            <div className="formButtons">
                {hideBackButton ? null : prevButton}
                {navButton}
                {!isHiddenButton && nextButton}
            </div>
        ),
        rootElement,
    );
};

FormButtons.propTypes = {
    list: PropTypes.string,
    last: PropTypes.bool,
    showNav: PropTypes.bool,
    prevButtonComponent: PropTypes.func,
    navButtonComponent: PropTypes.func,
    nextButtonComponent: PropTypes.func,
};

FormButtons.defaultProps = {
    list: '/',
    last: false,
    showNav: true,
    showPrev: true,
    prevButtonComponent: PrevButton,
    navButtonComponent: NavButton,
    nextButtonComponent: NextButton,
};

export default FormButtons;
