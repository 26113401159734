import React from 'react';
import { fieldPropTypes } from 'redux-form';
import Icon from '../../../../common/components/Icon';

import './EditButton.scss';

const EditButton = ({ input: { value, ...input } }) => (
    <label htmlFor={`${input.name}-edit`} className="editable-field-button">
        <input
            id={`${input.name}-edit`}
            {...input}
            checked={value}
            type="checkbox"
        />
        <Icon icon={value ? 'calculator' : 'pencil'} />
    </label>
);

EditButton.propTypes = {
    ...fieldPropTypes,
};

export default EditButton;
