import * as React from 'react';

const PensionSVG = (props) => (
    <svg
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 27.9 18.4"
        style={{
            enableBackground: 'new 0 0 27.9 18.4',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.st0{fill:#464646}'}</style>
        <g id="Calque_2">
            <g id="Calque_2-2">
                <path
                    className="st0"
                    d="M10.1 10.2c1-2.2 0-4.7-2.2-5.6s-4.7 0-5.6 2.2c-.3.5-.4 1.1-.4 1.7 0 .6.1 1.2.4 1.8C1.2 10.7 0 12 0 15.2c-.1 1.7 1.3 3.2 3 3.2h6.3c1.7-.1 3.1-1.5 3-3.2 0-3.2-1.2-4.5-2.2-5zM6.2 6.1c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3-2.3-1-2.3-2.3 1-2.3 2.3-2.3zm-2.7 5.7c1.6 1.2 3.7 1.2 5.3 0 1.4.2 1.6 2.4 1.6 3.3.1.7-.4 1.2-1.1 1.3H3c-.7-.1-1.1-.7-1.1-1.3 0-.9.2-3 1.6-3.3zM23.9 10.2c1-2.2 0-4.7-2.1-5.6s-4.7 0-5.6 2.1c-.5 1.1-.5 2.4 0 3.5-1 .5-2.3 1.8-2.3 4.9-.1 1.7 1.3 3.2 3 3.2h6.3c1.7-.1 3.1-1.5 3-3.2-.1-3.1-1.3-4.4-2.3-4.9zm-6.3-1.8h4.6v.1c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3v-.1zm-.3 3.4c1.6 1.2 3.7 1.2 5.3 0 1.4.2 1.6 2.4 1.6 3.3.1.7-.4 1.2-1.1 1.3h-6.3c-.7-.1-1.1-.7-1.1-1.3.1-.9.2-3 1.6-3.3zM27.8 2.1c-.2-.6-.8-1-1.4-1-.1-.8-.9-1.3-1.6-1.1-.5.1-1 .5-1.1 1l-.3 1.1c-.4 0-.9.1-1.1.5-.5.6-.4 1.5.1 2l1.3 1.1c.6.5 1.5.4 1.9-.2.3-.4.4-.9.2-1.4l1-.3c.8-.2 1.2-1 1-1.7zM14.1 5.5c.5.6 1.4.7 2 .2l1.3-1.1c.6-.5.7-1.4.2-2-.3-.3-.7-.5-1.1-.5L16.1 1c-.2-.7-1-1.2-1.7-1-.5.1-.9.5-1 1.1-.8 0-1.4.6-1.5 1.3 0 .6.4 1.2 1 1.4l1 .3c-.2.5-.1 1 .2 1.4z"
                />
            </g>
        </g>
    </svg>
);

export default PensionSVG;
