import { getOr } from 'lodash/fp';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ripStates } from 'common/choices';
import { formatDate } from 'common/utils/format';

const RipState = ({ rip: { state, callbackDate } }) => {
    switch (state) {
        case 'toCallBack':
            if (callbackDate) {
                return (
                    <Fragment>
                        <span>{ripStates.toCallBack}</span>
                        <span>{formatDate(callbackDate)}</span>
                    </Fragment>
                );
            }
        // eslint-disable-next-line no-fallthrough
        default:
            return getOr('en cours', state, ripStates);
    }
};

RipState.propTypes = {
    rip: PropTypes.shape({
        state: PropTypes.string,
        callbackDate: PropTypes.string,
    }).isRequired,
};

export default RipState;
