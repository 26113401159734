import { flowRight, sortBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import { loadDataList } from '../../../../actions';
import withRouter from '../../../withRouter';
import Comment from './Comment';

const ListInner = ({ comments }) => (
    <div className="comments">
        {null === comments ? 'Loading' : comments.map((comment) => (
            <Comment
                key={comment.id}
                comment={comment}
            />
        ))}
    </div>
);

ListInner.propTypes = {
    comments: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })),
};

ListInner.defaultProps = {
    comments: null,
};

const mapStateToProps = (state) => ({
    comments: sortBy('createdAt', state.data.comment),
});

const mapDispatchToProps = {
    loadDataList,
};

const List = flowRight([
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    lifecycle({
        componentWillMount() {
            this.props.loadDataList('comment', { ripId: this.props.params.id });
        },
    }),
])(ListInner);

export default List;
