import { compose, mapProps } from 'recompose';
import Error from '../Error';
import withFieldError from './withFieldError';

const FieldError = compose(
    withFieldError,
    mapProps(({ meta, error }) => ({
        ...meta,
        error,
    })),
)(Error);

export default FieldError;
