import { findIndex } from 'lodash/fp';

const getWizardStep = ({ wizard, url, next = true }) => {
    const { items } = wizard;
    const current = url.split('/').slice(-2).join('/');
    const currentIndex = findIndex(({ target }) => target === current, items);

    const jump = next ? 1 : -1;

    return items[currentIndex + jump].target;
};

export default getWizardStep;
