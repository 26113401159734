import {
    isEqual,
    concat,
    cloneDeep,
    flow,
    get,
    filter,
    has,
    isObject,
    isArray,
    map,
    split,
    sortBy,
    includes,
} from 'lodash/fp';
import moment from 'moment';
import getItem from './getItem';
import normalize from '../model/rip/normalize';
import { availableEstateForReductions } from '../model/summary/cappedReductions';
import { availableEstateForDeductions } from '../model/summary/deductions';

const formatDate = (value) => (value instanceof moment ? value : moment(value));

const getYear = (dateField) => (event) => {
    // get the raw date
    const rawDate = get(dateField, event);

    // run the test with a formatted date
    return rawDate && formatDate(rawDate).year();
};

const isBefore = (dateField) => (event, year) => {
    const eventYear = getYear(dateField)(event);

    return eventYear && eventYear <= year;
};

const isOn = (dateField) => (event, year) => {
    const eventYear = getYear(dateField)(event);

    return eventYear && eventYear === year;
};

const subjectsMap = {
    child: 'children',
    dependent: 'dependents',
};

const applyUnionEvent = (familySituation) => (rip, event, year) => {
    if (rip.originalRip && rip.originalRip.dualRip) {
        return {
            // apply event back on the originalRip
            // eslint-disable-next-line no-use-before-define
            ...applyEvents(rip.originalRip, year),
            stopEventPropagation: true,
        };
    }

    return {
        ...rip,
        familySituation,
        unionDate: event.date,
        incomes: event.salary
            ? [
                ...rip.incomes,
                {
                    type: 'salary',
                    income: event.salary,
                },
            ]
            : rip.incomes,
    };
};

const computeSalaryEvolution = (rip, event, year) => {
    // get the salary
    const salary = getItem(event.income, rip.incomes);
    const updatedAt = new Date(rip.updatedAt);
    const evolutionCountYear =
        event.recurrentEvent || event.endYear > year
            ? year - updatedAt.getFullYear()
            : event.endYear - updatedAt.getFullYear();

    if (!salary) {
        return rip;
    }

    /* eslint-disable-next-line */
    switch (salary.type) {
        case 'BNC':
        case 'BIC':
        case 'BA':
            salary.result *= (1 + parseFloat(event.percentage)) ** Math.max(0, evolutionCountYear);
            break;
        case 'pension':
            salary.pension *= (1 + parseFloat(event.percentage)) ** Math.max(0, evolutionCountYear);
            break;
        case 'exemptedIncome':
        case 'other':
        case 'salary':
            salary.income *= (1 + parseFloat(event.percentage)) ** Math.max(0, evolutionCountYear);
            break;
    }

    return rip;
};

const computeSalaryIncrease = (rip, event, year) => {
    // get the salary
    const salary = getItem(event.income, rip.incomes);
    let montantSalaire;

    if (!salary) {
        return rip;
    }

    /* eslint-disable-next-line */
    switch (salary.type) {
        case 'BNC':
        case 'BIC':
        case 'BA':
            montantSalaire = salary.result;
            break;
        case 'pension':
            montantSalaire = salary.pension;
            break;
        case 'exemptedIncome':
        case 'other':
        case 'salary':
            montantSalaire = salary.income;
    }

    const calculAugmentationSiDepartRetraite = (salaire, montantMensuelPrime, moisDeDebutDePrime) => {
        const moisDeFin = moment(salaire.dateFin).month() + 1;

        const montantTotalDuSalaire = montantSalaire + (montantMensuelPrime * (moisDeFin - moisDeDebutDePrime));

        return montantTotalDuSalaire;
    };

    const calculAugmentationAvecProrataAnneeEnCours = (montantMensuelPrime, duree) => {
        const montantTotalDuSalaire = montantSalaire + (montantMensuelPrime * duree);

        return montantTotalDuSalaire;
    };

    const calculAugmentationSansProrata = (evenement) => {
        const montantTotalDuSalaire = montantSalaire + ((evenement.amount || 0) + (evenement.netAmount || 0));

        return montantTotalDuSalaire;
    };

    const calculAugmentationSalaire = () => {
        const moisDeMiseEnPlace = moment(event.date).month() + 1;
        const dureeAAppliquer = (12 - (moisDeMiseEnPlace)) + 1;
        const montantPrimeMensuel = ((event.amount || 0) + (event.netAmount || 0)) / 12;

        if (year === moment(event.date).year()) {
            if (salary.dateFin) {
                return calculAugmentationSiDepartRetraite(salary, montantPrimeMensuel, moisDeMiseEnPlace);
            }

            return calculAugmentationAvecProrataAnneeEnCours(montantPrimeMensuel, dureeAAppliquer);
        }

        return calculAugmentationSansProrata(event);
    };

    /* eslint-disable-next-line */
    switch (salary.type) {
        case 'BNC':
        case 'BIC':
        case 'BA':
            salary.result = calculAugmentationSalaire();
            break;
        case 'pension':
            salary.pension = calculAugmentationSalaire();
            break;
        case 'exemptedIncome':
        case 'other':
        case 'salary':
            salary.income = calculAugmentationSalaire();
    }

    return rip;
};

const computeSalaryDecrease = (rip, { income, date, fixedAmount = 0, variableAmount = 0 }, year) => {
    // get the salary
    const salary = getItem(income, rip.incomes);
    let montantSalaire;

    if (!salary) {
        return rip;
    }

    /* eslint-disable-next-line */
    switch (salary.type) {
        case 'BNC':
        case 'BIC':
        case 'BA':
            montantSalaire = salary.result;
            break;
        case 'pension':
            montantSalaire = salary.pension;
            break;
        case 'exemptedIncome':
        case 'other':
        case 'salary':
            montantSalaire = salary.income;
    }

    const calculDiminutionSiDepartRetraite = (salaire, montantDiminutionMensuel, moisDeDebut) => {
        const moisDeFin = moment(salaire.dateFin).month() + 1;
        const montantSalaireAvecDiminution = montantSalaire - (montantDiminutionMensuel * (moisDeFin - moisDeDebut));

        return montantSalaireAvecDiminution;
    };

    const calculDiminutionAvecProrataAnneeEnCours = (montantDiminutionMensuel, duree) => {
        const montantSalaireAvecDiminution = montantSalaire - (montantDiminutionMensuel * duree);

        return montantSalaireAvecDiminution;
    };

    const calculDiminutionSansProrata = (partFixe, partVariable) => {
        const montantSalaireAvecDiminution = montantSalaire - (partFixe + partVariable);

        return montantSalaireAvecDiminution;
    };

    const calculDiminutionSalaire = () => {
        const moisDeMiseEnPlace = moment(date).month() + 1;
        const dureeAAppliquer = (12 - (moisDeMiseEnPlace)) + 1;
        const montantDiminutionMensuel = (fixedAmount + variableAmount) / 12;

        if (year === moment(date).year()) {
            if (salary.dateFin) {
                return calculDiminutionSiDepartRetraite(salary, montantDiminutionMensuel, moisDeMiseEnPlace);
            }

            return calculDiminutionAvecProrataAnneeEnCours(montantDiminutionMensuel, dureeAAppliquer);
        }

        return calculDiminutionSansProrata(fixedAmount, variableAmount);
    };

    /* eslint-disable-next-line */
    switch (salary.type) {
        case 'BNC':
        case 'BIC':
        case 'BA':
            salary.result = calculDiminutionSalaire();
            break;
        case 'pension':
            salary.pension = calculDiminutionSalaire();
            break;
        case 'exemptedIncome':
        case 'other':
        case 'salary':
            salary.income = calculDiminutionSalaire();
    }

    return rip;
};

const eventTypes = {
    birth: {
        sortBy: getYear('presumedBirthDate'),
        dateTest: isBefore('presumedBirthDate'),
        compute: (rip) => ({
            ...rip,
            // add a new children
            children: isArray(rip.children)
                ? concat(rip.children, { situation: 'dependant', fiscalHome: rip.familyEvents[0].parent })
                : [{ situation: 'dependant', fiscalHome: rip.familyEvents[0].parent }],
        }),
    },
    householdLeave: {
        sortBy: getYear('date'),
        dateTest: isBefore('date'),
        compute: (rip, event) => {
            // split the subject into a type and an index
            const [subjectType, index] = split('_', event.subject);
            // get the subjects
            const subjectKey = get(subjectType, subjectsMap);
            const subjects = get(subjectKey, rip);

            if (!subjects) {
                // nothing to do about it
                // there's no subjects
                return rip;
            }

            // get the subject
            const subject = getItem(index, subjects);

            if (subject) {
                // update his situation
                subject.situation = 'outside_household';
            }

            return rip;
        },
    },
    marriage: {
        sortBy: getYear('date'),
        dateTest: isBefore('date'),
        compute: applyUnionEvent('married'),
    },
    partnership: {
        sortBy: getYear('date'),
        dateTest: isBefore('date'),
        compute: applyUnionEvent('partner'),
    },
    divorce: {
        sortBy: getYear('date'),
        dateTest: isBefore('date'),
        compute: (rip, event) => ({
            ...rip,
            // update the family situation
            familySituation: 'divorced',
            divorcedDate: event.date,
        }),
    },
    bonus: {
        sortBy: getYear('date'),
        dateTest: isOn('date'),
        compute: (rip, event) => {
            // get the salary
            const salary = getItem(event.income, rip.incomes);
            if (salary && 'salary' === salary.type) {
                // update the salary
                salary.income += event.amount;
            }
            if (salary && 'pension' === salary.type) {
                salary.pension += event.amount;
            }
            if (salary && 'other' === salary.type) {
                salary.income += event.amount;
            }

            return rip;
        },
    },
    salaryIncrease: {
        sortBy: getYear('date'),
        dateTest: isBefore('date'),
        compute: computeSalaryIncrease,
    },
    salaryDecrease: {
        sortBy: getYear('date'),
        dateTest: isBefore('date'),
        compute: computeSalaryDecrease,
    },
    goingRetired: {
        sortBy: getYear('date'),
        dateTest: isBefore('date'),
        compute: (rip, { beneficiary: target, pension, endingIncomes, date }, year) => {
            const nombreDeMoisAvantFin = moment(date).month();
            let nombreDeMoiDePension = 12;

            return ({
                ...rip,
                incomes: [
                    ...map((income) => {
                        if (includes(income.id, endingIncomes)) {
                            const montantSalaire = (income.income / 12) * nombreDeMoisAvantFin;

                            if (year === moment(date).year()) {
                                nombreDeMoiDePension -= nombreDeMoisAvantFin;

                                return { ...income, income: montantSalaire, incomeType: 'annual', dateFin: date };
                            }

                            return { ...income, type: 'none' };
                        }

                        return income;
                    }, rip.incomes),
                    {
                        type: 'pension',
                        pension: (nombreDeMoiDePension) * parseInt(pension, 10),
                        beneficiary: target,
                    },
                ],
            });
        },
    },
    salaryEvolution: {
        sortBy: () => 0,
        dateTest: () => true,
        compute: computeSalaryEvolution,
    },
};

const childrenToEvent = (children) =>
    children.map(({ leaveDate, deadChild = null, childDeathDate = null }, index) => ({
        type: 'householdLeave',
        subject: `child_${index}`,
        date: deadChild ? childDeathDate : leaveDate,
    }));

const isBetween = (year, start, end) => moment(year) >= moment(start).year() &&
    moment(year) < moment(end).year();

const applyEvents = (originalRip, year) => {
    // clone our original rip
    let rip = {
        ...normalize(cloneDeep(originalRip)),
        // it's very important to reset this data
        stopEventPropagation: false,
    };
    const updatedAt = new Date(rip.updatedAt);

    // process not-recurring incomes
    rip.incomes = map((income) => {
        if (!['other', 'exemptedIncome'].includes(income.type) ||
            income.recurring ||
            (!income.recurring && (updatedAt.getFullYear() + 1) === year)) {
            return income;
        }

        return { ...income, income: 0 };
    }, rip.incomes);

    // On crée les événements liés aux départs des enfants du foyer fiscal.
    const childHousholdLeave = childrenToEvent(get('children', rip) || []);
    // get events
    const events = [].concat(get('familyEvents', rip), get('taxEvents', rip), childHousholdLeave);

    // process events
    flow([
        // we can only deal with objects
        filter(isObject),
        // we can only process known types
        filter(({ type }) => has(type, eventTypes)),
        // sort events by date
        // it's very important to execute them in order
        sortBy((event) => eventTypes[event.type].sortBy(event)),
        // process events
        (sortedEvents) => {
            for (const event of sortedEvents) {
                const { dateTest, compute } = eventTypes[event.type];

                // est-ce que l'évènement à lieu l'année en cours de verif
                if (dateTest(event, year)) {
                    // process the rip
                    rip = compute(rip, event, year);

                    if (rip.stopEventPropagation) {
                        // the previous event specifically asked to not continue this job
                        // we won't compute any further events
                        return rip;
                    }
                }
            }

            return rip;
        },
    ])(events);

    if (rip.stopEventPropagation) {
        // same things here
        // it's a cascade effect
        return rip;
    }

    // process reduction/deduction/credit end events
    let recurringEvents = concat(get('reductions', rip), get('taxCredits', rip));
    recurringEvents = recurringEvents.concat(get('deductions', rip));
    flow([
        // event having an end date
        filter(({ recurrentEvent, endYear }) => !recurrentEvent && endYear),
        map((evt) => {
            const { endYear } = evt;
            if (endYear < year) {
                rip.reductions = rip.reductions.filter((reduc) => !isEqual(evt, reduc));
                rip.deductions = rip.deductions.filter((deduc) => !isEqual(evt, deduc));
                rip.taxCredits = rip.taxCredits.filter((cred) => !isEqual(evt, cred));
            }
        }),
    ])(recurringEvents);

    // process reductions/deductions on estate
    rip.property = map((property) => {
        const {
            type,
            fiscalFramework,
            detaxStartDate,
            detaxEndDate,
            prorogatedReduction,
            prorogationDetaxAmount,
            prorogationDetaxEndDate,
            mortgages = [],
        } = property;

        const onGoingMortgages = mortgages.filter((mortgage) => isBetween(year, mortgage.startLoan, mortgage.endLoan));

        if (
            'rentalProperty' === type && ['ScellierIntermediaire', 'Pinel'].includes(fiscalFramework)
            && prorogatedReduction
            && formatDate(detaxEndDate).year() < year
            && (formatDate(prorogationDetaxEndDate).year() > year)
        ) {
            return { ...property, mortgages: onGoingMortgages, mortdetaxAmount: prorogationDetaxAmount };
        }

        if (
            // must be of type rental property
            'rentalProperty' === type &&
            // must have a fiscal framework with detax
            (availableEstateForReductions.includes(fiscalFramework) ||
                availableEstateForDeductions.includes(fiscalFramework) || 'other' === fiscalFramework) && // must have a start date on its detax
            ((detaxStartDate &&
                // and we only look for unstarted detax
                formatDate(detaxStartDate).year() > year) ||
                // must have an end date on its detax
                (detaxEndDate &&
                    // and we only look for ended detax
                    formatDate(detaxEndDate).year() < year))
        ) {
            return { ...property, mortgages: onGoingMortgages, detaxAmount: 0 };
        }

        return { ...property, mortgages: onGoingMortgages };
    }, rip.property);

    // process charges/mortgages to keep only yearly-based ones
    rip.charges = filter((charge) =>
        'pension' === charge.type ||
        isBetween(year, charge.start, charge.end), rip.charges);

    rip.mainResidenceMortgages = filter((mortgage) =>
        'owed' === mortgage.type &&
        isBetween(year, mortgage.startLoan, mortgage.endLoan), rip.mainResidenceMortgages);

    return rip;
};

export default applyEvents;
