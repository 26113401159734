import { getOr } from 'lodash/fp';

const computeFundingAmount = ({
    brokerageFees,
    fundingPersonalContribution,
    handlingOfBrokerageFees,
    needFunding,
    data = {},
}, {
    instalmentCosts: {
        investmentWithTVA,
        promoterTVA,
    },
}) => {
    if (!needFunding) {
        return { fundingAmount: 0 };
    }

    const tvaFunder = getOr('loan', 'funding.tvaFunder', data);
    const hasPromoterTVA = 'promoter' === tvaFunder;
    let fundingAmount = investmentWithTVA
        - (hasPromoterTVA ? promoterTVA : 0)
        - fundingPersonalContribution;

    // because of a wrong wording at the beginning
    // brokerage fees are in fact engineering fees
    if ('inFunding' === handlingOfBrokerageFees) {
        fundingAmount += brokerageFees;
    }

    // here come the real brokerage fees
    const { handlingOfFraisCourtage, fraisCourtage } = data;

    if ('inFunding' === handlingOfFraisCourtage) {
        fundingAmount += fraisCourtage;
    }

    const { handlingOfBankApplicationFees, bankApplicationFees } = data;

    if ('inFunding' === handlingOfBankApplicationFees) {
        fundingAmount += bankApplicationFees;
    }

    return { fundingAmount };
};

export default computeFundingAmount;
