import * as React from 'react';

const HomeWorkSVG = (props) => (
    <svg
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 30.6 27.9"
        style={{
            enableBackground: 'new 0 0 30.6 27.9',
        }}
        xmlSpace="preserve"
        {...props}
    >
        <style>{'.st0{fill:#464646}'}</style>
        <g id="Calque_2">
            <g id="Calque_2-2">
                <path
                    className="st0"
                    d="M24 27.9H6.7c-1.2 0-2.1-1-2.1-2.1V15.5h2.8v9.6h16V15h2.8v10.8c-.1 1.1-1 2.1-2.2 2.1zM1.4 12.2c-.8 0-1.4-.6-1.4-1.4 0-.5.2-.9.6-1.2L14.5.4c.8-.6 1.9-.6 2.7 0l12.9 9.2c.6.5.7 1.4.2 2-.4.6-1.3.7-1.9.3l-12.7-9L2.2 12c-.3.1-.5.2-.8.2z"
                />
                <path
                    className="st0"
                    d="M18.7 13.1V13l-1.2 1.2-2.3-.5-.5-2.3 1.4-1.4c-1.7-.4-3.4.7-3.8 2.4-.3 1.5.4 3 1.8 3.6V21.9c0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4V16c1.1-.6 1.8-1.7 1.8-2.9zm-3.2 9.3c-.3 0-.6-.2-.6-.5s.2-.6.5-.6.6.2.6.5-.2.6-.5.6z"
                />
            </g>
        </g>
    </svg>
);

export default HomeWorkSVG;
