// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-field-info-button {
    border: 2px solid #cfcfcf;
    background-color: white;
    color: #cfcfcf;
    font-size: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;
    margin-left: 0.5em;
}

.form-field-info-button:hover,
.form-field-info-button:active,
.form-field-info-button:focus {
    border-color: #248fdf;
    color: #248fdf;
}

.form-field-info-popover {
    max-width: 400px;
    color: #248fdf;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/form/FieldInfo.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,cAAc;IACd,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,kBAAkB;AACtB;;AAEA;;;IAGI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,2CAA2C;AAC/C","sourcesContent":[".form-field-info-button {\n    border: 2px solid #cfcfcf;\n    background-color: white;\n    color: #cfcfcf;\n    font-size: 15px;\n    width: 25px;\n    height: 25px;\n    border-radius: 50%;\n    padding: 0;\n    margin-left: 0.5em;\n}\n\n.form-field-info-button:hover,\n.form-field-info-button:active,\n.form-field-info-button:focus {\n    border-color: #248fdf;\n    color: #248fdf;\n}\n\n.form-field-info-popover {\n    max-width: 400px;\n    color: #248fdf;\n    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
