/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import { useFlag } from '@unleash/proxy-client-react';
import withRouter from './withRouter';

const SuccessModalForPreco = ({ close }) => {
    const enabled = useFlag('erip-1223-CIF');

    return enabled ? (
        <div className="modal-container">
            <p>Votre message a bien &eacute;t&eacute; envoy&eacute; !</p>
            {/* <button
                onClick={close}
                className="lastButtonRipForm sendButton"
                type="button"
            >
                Acc&eacute;der aux validations en attente
            </button> */}
            <a onClick={close}>
                Retour vers la liste des RIPs
            </a>

        </div>
    ) : (
        <div className="modal-container">
            <p>Votre message a bien &eacute;t&eacute; envoy&eacute; !</p>
            <button
                onClick={close}
                className="lastButtonRipForm sendButton"
                type="button"
            >
                Retour vers la liste des rips
            </button>
        </div>
    );
};

SuccessModalForPreco.propTypes = {
    close: PropTypes.func.isRequired,
};

export default compose(
    withRouter,
    connect(null, (dispatch, {
        navigate,
    }) => ({
        close: () => {
            dispatch(setModal(null));
            navigate('/');
        },
    })),
)(SuccessModalForPreco);
