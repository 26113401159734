import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import DropDown from 'components/form/FieldDropDown';
import { GridLayout } from 'components/form/layout';
import { DateField, FileField, SelectField } from 'components/form/field';

const IdCardInner = ({ uploader, idType }) => (
    <DropDown title="Justificatif d'identité" name="virtual.idCard">
        <GridLayout className="double condensed">
            <SelectField
                title="Type de justificatif"
                name="data.idType"
                required
            >
                <option />
                <option key="passport" value="passport">
                    Passeport
                </option>
                <option key="idCard" value="idCard">
                    Carte d&apos;identité
                </option>
            </SelectField>
            <DateField
                title="Date de fin de validité"
                name="data.idCardEndYear"
                required
            />
        </GridLayout>
        {'passport' === idType ? (
            <Fragment>
                <hr />
                <FileField
                    icon="user"
                    name="passport"
                    uploader={uploader('passport')}
                    uploadText="Passeport"
                    reduxField="files.passport"
                />
            </Fragment>
        ) : null}
        {'idCard' === idType ? (
            <Fragment>
                <hr />
                <GridLayout className="double condensed">
                    <FileField
                        icon="user"
                        name="idCardFront"
                        uploader={uploader('idCardFront')}
                        uploadText="Carte d'identité recto"
                        reduxField="files.idCardFront"
                    />
                    <FileField
                        icon="user"
                        name="idCardBack"
                        uploader={uploader('idCardBack')}
                        uploadText="Carte d'identité verso"
                        reduxField="files.idCardBack"
                    />
                </GridLayout>
            </Fragment>
        ) : null}
    </DropDown>
);

IdCardInner.propTypes = {
    uploader: PropTypes.func.isRequired,
    idType: PropTypes.string,
};

const IdCard = formValues({
    idType: 'data.idType',
})(IdCardInner);

export default IdCard;
