import moment from 'moment';
import { withProps } from 'recompose';
import Input from './Input';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const format = (value) => {
    if (!value) {
        return '';
    }

    const date = moment(value);

    if (date.isValid()) {
        return date.format(dateTimeFormat);
    }

    return value;
};

const parse = (value) => {
    if (!value) {
        return null;
    }

    const date = moment(value, dateTimeFormat);

    if (date.isValid()) {
        return date;
    }

    return value;
};

const DateTime = withProps({
    format,
    parse,
    type: 'datetime-local',
})(Input);

export default DateTime;
