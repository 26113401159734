import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import { formValues } from 'redux-form';
import { AmountItem } from 'common/components/summary';
import Title from 'components/Title';

const computeResults = ({
    differedAmount,
    rate,
    differedDuration,
    differedFranchise,
}) => {
    const capitalizedInterestsFranchiseBefore = (differedAmount * rate) * (differedFranchise / 24);
    const capitalFranchiseBefore = capitalizedInterestsFranchiseBefore + differedAmount;

    const monthlyRate = rate / 12;
    const monthlyPayment = ((capitalFranchiseBefore * monthlyRate) / (1 - ((1 + monthlyRate) ** -(differedDuration)))) || 0;

    return {
        monthlyPayment,
        partialDiffered: (capitalFranchiseBefore * monthlyRate) || 0,
    };
};

const MonthlyPaymentResults = ({
    differedAmount,
    rate,
    differedDuration,
    differedFranchise,
}) => {
    const { monthlyPayment, partialDiffered } = computeResults({
        differedAmount,
        rate,
        differedDuration,
        differedFranchise,
    });

    return (
        <Fragment>
            <Title><b>Résultats</b></Title>
            <div className="summary-body col2">
                <dl>
                    <AmountItem
                        title="Mensualité d'amortissement hors assurance"
                        value={monthlyPayment}
                    />
                    <AmountItem
                        title="Calcul du différé"
                        value={partialDiffered}
                    />
                </dl>
            </div>
        </Fragment>
    );
};

MonthlyPaymentResults.propTypes = {
    differedAmount: PropTypes.number,
    rate: PropTypes.number,
    differedDuration: PropTypes.number,
    differedFranchise: PropTypes.number,
};

export default compose(
    formValues({
        differedDuration: 'differedDuration',
        differedFranchise: 'differedFranchise',
        rate: 'rate',
        differedAmount: 'differedAmount',
    }),
    mapProps(({
        differedDuration,
        differedFranchise,
        rate,
        differedAmount,
    }) => ({
        rate: parseFloat(rate || '0'),
        differedAmount: differedAmount || 0,
        differedFranchise: differedFranchise || 0,
        differedDuration: differedDuration || 0,
    })),
)(MonthlyPaymentResults);
