import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InputField } from '.';

// TODO: Make generic names for fields to be used multiple times in form.
const Address = ({ required, title, disabled }) => (
    <Fragment>
        <hr />
        <h3 className="double">{title}</h3>
        <InputField
            name="address.street"
            title="Numéro et voie"
            type="text"
            required={required}
            disabled={disabled}
        />
        <InputField
            name="address.complement"
            title="Complément d'adresse"
            type="text"
            disabled={disabled}
        />
        <InputField
            name="address.zipCode"
            title="Code postal"
            type="text"
            required={required}
            disabled={disabled}
        />
        <InputField
            name="address.city"
            title="Ville"
            type="text"
            required={required}
            disabled={disabled}
        />
        <hr />
    </Fragment>
);

Address.propTypes = {
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
};

export default Address;
