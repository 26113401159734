import { get, isEmpty } from 'lodash/fp';
import { paymentTypes } from 'common/choices';
import validate, { branchValidate, checkChoice, checkRequiredField } from 'common/formValidation/lib';
import formatageDesPlansDeTarificationStripe from '../../../../common/src/utils/formatTarificationStripe';

export default validate([
    // validate the plan
    checkRequiredField('selectedPlan'),
    checkChoice('selectedPlan', (values, { session, instanceSettings }) => formatageDesPlansDeTarificationStripe(get('stripeTarification', instanceSettings), 'plans')[session.entity.contractType]),
    // validate the payment type
    checkRequiredField('paymentType'),
    checkChoice('paymentType', paymentTypes),
    // validate User Agreement
    checkRequiredField('userAgreement'),
    // validate CGV
    checkRequiredField('cgvAgreement'),
    // validate Payment Data Agreement
    checkRequiredField('paymentDataAgreement'),
    // on IBAN we have to validate additional fields
    branchValidate((errors, { paymentType }) => 'iban' === paymentType, [
        checkRequiredField('name'),
        checkRequiredField('email'),
    ]),
    // validate other buyers
    branchValidate((errors, values, { initialValues }) => isEmpty(initialValues.potentialBuyers), [
        checkRequiredField('buyer.buyerFirstName'),
        checkRequiredField('buyer.buyerLastName'),
        checkRequiredField('buyer.buyerEmail'),
        checkRequiredField('buyer.buyerAddress'),
    ]),
    // validate middle buyers
    branchValidate((errors, values, { initialValues }) => !isEmpty(initialValues.potentialBuyers), [
        checkRequiredField('buyer'),
    ]),
]);
