import React from 'react';
import { wrapDisplayName, setDisplayName } from 'recompose';
import useInstanceSettings from './useInstanceSettings';

const withInstanceSettings = (WrappedComponent) => {
    const SessionInstanceSettingsConsumer = (props) => {
        const instanceSettings = useInstanceSettings();

        return <WrappedComponent {...props} instanceSettings={instanceSettings} />;
    };

    return setDisplayName(wrapDisplayName(WrappedComponent, 'withInstanceSettings'))(SessionInstanceSettingsConsumer);
};

export default withInstanceSettings;
