import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { branch, renderNothing } from 'recompose';
import { fundingTypes } from 'common/choices';
import { AmountItem, CountItem, Item, PercentageItem } from 'common/components/summary';
import getMedianRate from 'common/model/simulation/lifeInsurance/getMedianRate';
import computeInvestment from 'common/model/simulation/scpi/computeInvestment';
import Title from 'components/Title';

const Funding = ({
    data: {
        products,
        funding,
    },
}) => (
    <Fragment>
        <Title><b>Crédit</b></Title>
        <div className="summary-body col3">
            <dl>
                <AmountItem title="Apport personnel" value={funding.personalContribution} />
                <AmountItem title="Frais d'ingénierie" value={funding.brokerageFees} />
                <AmountItem
                    title="Montant du crédit"
                    value={(computeInvestment(products) + funding.brokerageFees) - funding.personalContribution}
                />
            </dl>
            <dl>
                <CountItem title="Durée en mois" value={funding.duration} />
                <Item title="Type de prêt" value={fundingTypes[funding.type]} />
                <PercentageItem title="TAEG" value={funding.rate} />
            </dl>
        </div>
        {'in-fine' === get('type', funding) ? (
            <Fragment>
                <Title><b>Assurance Vie</b></Title>
                <div className="summary-body col3">
                    <dl>
                        <AmountItem
                            title="Versement initial"
                            value={funding.initialFunding}
                        />
                        <PercentageItem
                            title="Frais d'entrée pour le montant initial"
                            value={funding.entryFees}
                        />
                    </dl>
                    <dl>
                        <AmountItem
                            title="Épargne mensuelle"
                            value={funding.monthlyFunding.amount}
                        />
                        <PercentageItem
                            title="Frais d'entrée pour les montants mensuels"
                            value={funding.monthlyFunding.fees}
                        />
                    </dl>
                    <dl>
                        <PercentageItem
                            title="Rendement"
                            value={getMedianRate()[funding.investorProfile]}
                        />
                    </dl>
                </div>
            </Fragment>
        ) : null}
    </Fragment>
);

Funding.propTypes = {
    data: PropTypes.shape({
        products: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            parts: PropTypes.number.isRequired,
            revaluation: PropTypes.string,
        })).isRequired,
        funding: PropTypes.shape({
            rate: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            initialFunding: PropTypes.number,
            entryFees: PropTypes.string,
            monthlyFunding: PropTypes.shape({
                fees: PropTypes.string,
                amount: PropTypes.number,
            }),
            investorProfile: PropTypes.string,
            personalContribution: PropTypes.number.isRequired,
            brokerageFees: PropTypes.number.isRequired,
        }),
    }).isRequired,
};

export default branch(({ data: { needFunding } }) => !needFunding, renderNothing)(Funding);
