// Recherche les réseaux et sous réseaux
const filterEntitiesChildren = (items, id, entitiesArray) => {
    // eslint-disable-next-line array-callback-return
    items.filter((item) => {
        if (item.parentId === id) {
            entitiesArray.push(item);
        }
    });
};
// Retire tout les réseaux enfants de la liste des réseaux
const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => !arr2.find((element) => element.id === el.id));

    return res;
};
// Selectionne le réseau enfant du réseau actuel
const sortEntitiesChildren = (items, idReseau, noChildren) => {
    const entitiesArray = [];
    filterEntitiesChildren(items, idReseau, entitiesArray);
    for (let i = 0; i < entitiesArray.length; i++) {
        filterEntitiesChildren(items, entitiesArray[i].id, entitiesArray);
    }
    if (noChildren) {
        return filterByReference(items, entitiesArray);
    }

    return entitiesArray;
};
export default sortEntitiesChildren;
