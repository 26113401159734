// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-button {
    padding: 8px;
    border: 1px solid rgb(70, 70, 70);
    border-radius: 2px;
    opacity: 0.8;
    color: #464646;
    background-color: inherit;
    outline: none;
}

.download-button:active,
.download-button:focus,
.download-button:hover {
    color: inherit;
    text-decoration: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/DownloadButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,yBAAyB;IACzB,aAAa;AACjB;;AAEA;;;IAGI,cAAc;IACd,wBAAwB;AAC5B","sourcesContent":[".download-button {\n    padding: 8px;\n    border: 1px solid rgb(70, 70, 70);\n    border-radius: 2px;\n    opacity: 0.8;\n    color: #464646;\n    background-color: inherit;\n    outline: none;\n}\n\n.download-button:active,\n.download-button:focus,\n.download-button:hover {\n    color: inherit;\n    text-decoration: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
