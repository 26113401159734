const getLifeInsuranceData = (type, returnOne, returnTwo) => {
    if (type.toLowerCase().includes('target')) {
        return returnOne;
    }

    if (type.toLowerCase().includes('pvp')) {
        return returnTwo;
    }

    return false;
};

export default getLifeInsuranceData;
