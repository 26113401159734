import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TextItem, AmountItem } from 'common/components/summary';

const SCPI = ({
    recommendation: {
        description,
        amount,
    },
}) => (
    <Grid size={2}>
        <TextItem title="Type" value={description} />
        <AmountItem title="Montant approximatif" value={amount} />
    </Grid>
);

SCPI.propTypes = {
    recommendation: PropTypes.shape({
        description: PropTypes.string,
        amount: PropTypes.number,
    }).isRequired,
};

export default SCPI;
