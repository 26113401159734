import React, { useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { faCircleUser as partner } from '@fortawesome/free-solid-svg-icons';
import { faCircleUser as client } from '@fortawesome/free-regular-svg-icons';
import dataForm from 'components/rip/dataForm';
import { setModal } from 'actions';
import Spinner from 'components/Spinner';
import Group from 'components/form/Group';
import RadioGroup from 'components/form/RadioGroup';
import HorizontalRadioButton from 'components/form/HorizontalRadioButton';
import dataProvider from 'components/dataProvider';
import { withDatabase } from '../database';
import redirectToShoyo from '../../utils/redirectToShoyo';
import { shouldPartnerSign as hasPartnerActive } from '../../../../common/src/utils/hasPartner';
import boxShoyoCreationError from '../../utils/boxShoyoCreationError';
import withRouter from '../withRouter';
import xmark from '../../images/pictos/triangle-exclamation.svg';

import './SelectRecipientsForShoyo.scss';

const isPartnerButtonDisplay = (rip) => {
    if (rip.partnerIdBoxShoyo) {
        return true;
    }

    return false;
};

const renderBoxFailed = (
    rip,
    navigate,
    close,
    setRenderRecipientShoyo,
    targetPartnerBoxShoyo,
) => {
    const errorMessageBox = boxShoyoCreationError(
        rip,
        true === targetPartnerBoxShoyo,
    );
    const target = true === targetPartnerBoxShoyo ? rip.statusBoxShoyo.partner : rip.statusBoxShoyo.client;

    const { tentative, statusCode } = target;

    return (
        <form className="rip-recipients-selection">
            <div className="echec-shoyo-modal">
                <div className="echec-container">
                    <img className="xmark" src={xmark} alt="xmark" />
                    <h3>Echec de la création du coffre eDoc.</h3>
                </div>
                <div className="echec-content">
                    <div className="error-message-shoyo">{errorMessageBox}</div>
                    Merci d’envoyer un mail avec le message d’erreur à l’adresse{' '}
                    <a href="mailto:evolutionsdesoutils@theseis.fr">
                        evolutionsdesoutils@theseis.fr
                    </a>
                </div>
            </div>
            <div className="error-message-500">
                {500 === statusCode && (
                    <div>
                        <button
                            className="sendButton"
                            onClick={() => {
                                navigate(`/rip/${rip.id}/signatureState`);
                                close();
                            }}
                            disabled={3 === tentative}
                        >
                            Relancer la création du coffre-fort eDoc
                        </button>
                        <div>
                            <p>Relance {tentative - 1}/2</p>
                        </div>
                    </div>
                )}
            </div>
            <button
                className="sendButton"
                onClick={() => setRenderRecipientShoyo(true)}
            >
                Retour
            </button>
        </form>
    );
};

const renderSelectRecipient = (
    handleSubmit,
    rip,
    setRenderRecipientShoyo,
    setActivate,
    activate,
    isShoyoCreateBoxFailedForPartner,
    isShoyoCreateBoxFailedForClient,
    setTargetPartnerBoxShoyo,
) => (
    <form onSubmit={handleSubmit} className="rip-recipients-selection">
        {hasPartnerActive(rip) && <h5>Sélectionnez la box que vous souhaitez voir</h5>}
        <Group name="selectRecipientsForShoyo" required>
            {hasPartnerActive(rip) ? (
                <RadioGroup name="selectRecipientsForShoyo" required>
                    <HorizontalRadioButton
                        onClick={() => setActivate(true)}
                        className="recipient"
                        title="Contact principal"
                        icon={client}
                        value="client"
                        disabled={isShoyoCreateBoxFailedForClient}
                        isBoxFailed={isShoyoCreateBoxFailedForClient}
                        setRenderRecipientShoyo={setRenderRecipientShoyo}
                    />
                    <HorizontalRadioButton
                        onClick={() => setActivate(true)}
                        className="recipient"
                        title="Contact secondaire"
                        icon={partner}
                        value="partner"
                        disabled={!isPartnerButtonDisplay(rip)}
                        isBoxFailed={isShoyoCreateBoxFailedForPartner}
                        setRenderRecipientShoyo={setRenderRecipientShoyo}
                        setTargetPartnerBoxShoyo={setTargetPartnerBoxShoyo}
                        isPartner

                    />
                </RadioGroup>
            ) : (
                <RadioGroup name="selectRecipientsForShoyo" required>
                    <HorizontalRadioButton
                        className="recipient"
                        title="Contact principal"
                        icon={client}
                        value="client"
                        onlyClient={!isShoyoCreateBoxFailedForClient}
                        disabled={isShoyoCreateBoxFailedForClient}
                        isBoxFailed={isShoyoCreateBoxFailedForClient}
                        setRenderRecipientShoyo={setRenderRecipientShoyo}

                    />
                </RadioGroup>
            )}
            <button type="submit" className="sendButton" disabled={(!activate && hasPartnerActive(rip)) || (isShoyoCreateBoxFailedForClient && !hasPartnerActive(rip)) || (isShoyoCreateBoxFailedForClient && isShoyoCreateBoxFailedForPartner)}>
                Accéder
            </button>
        </Group>
    </form>
);

const SelectRecipientsForShoyo = ({ handleSubmit, rip, navigate, close }) => {
    const [renderRecipientShoyo, setRenderRecipientShoyo] = useState(true);
    const [targetPartnerBoxShoyo, setTargetPartnerBoxShoyo] = useState(false);

    const isShoyoCreateBoxFailedForClient = !!(rip.statusBoxShoyo && rip.statusBoxShoyo.client && rip.statusBoxShoyo.client.statusCode);
    const isShoyoCreateBoxFailedForPartner = !!(rip.statusBoxShoyo && rip.statusBoxShoyo.partner && rip.statusBoxShoyo.partner.statusCode);

    const [activate, setActivate] = useState(false);

    return renderRecipientShoyo
        ? renderSelectRecipient(handleSubmit, rip, setRenderRecipientShoyo, setActivate, activate, isShoyoCreateBoxFailedForPartner, isShoyoCreateBoxFailedForClient, setTargetPartnerBoxShoyo, targetPartnerBoxShoyo)
        : renderBoxFailed(rip, navigate, close, setRenderRecipientShoyo, targetPartnerBoxShoyo);
};

SelectRecipientsForShoyo.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
        dualRip: PropTypes.bool,
        id: PropTypes.string,
        statusBoxShoyo: PropTypes.shape({
            statusCode: PropTypes.number,
            tentative: PropTypes.number,
        }),
    }),
    navigate: PropTypes.func,
    close: PropTypes.func,
};

export default compose(
    withRouter,
    withDatabase,
    dataProvider({
        id: ({ id }) => id,
        type: 'rip',
    }),
    dataForm({
        form: 'rip-box-shoyo-select-recipients',
        type: 'rip',
        id: ({ id }) => id,
        onSubmit: async (
            { selectRecipientsForShoyo = 'client' },
            dispatch,
            { personalEmail, partnerPersonalEmail, session },
        ) => {
            dispatch(setModal(
                () => <Spinner>Redirection vers la plateforme Shoyo.</Spinner>,
                false,
            ));

            if ('client' === selectRecipientsForShoyo) {
                redirectToShoyo(personalEmail, session);
            } else {
                redirectToShoyo(partnerPersonalEmail, session);
            }

            dispatch(setModal(null));
        },
    }),
    connect(
        null,
        (dispatch) => ({
            close: () => {
                dispatch(setModal(null));
            },
        }),
    ),
)(SelectRecipientsForShoyo);
