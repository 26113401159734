import PropTypes from 'prop-types';
import React from 'react';
import { Grid, AmountItem } from 'common/components/summary';

const LMNP = ({
    recommendation: { amount },
}) => (
    <Grid size={1}>
        <AmountItem title="Montant approximatif" value={amount} />
    </Grid>
);

LMNP.propTypes = {
    recommendation: PropTypes.shape({
        amount: PropTypes.number,
    }).isRequired,
};

export default LMNP;
