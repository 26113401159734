import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ResponsiveContainer } from 'recharts';
import { Grid, AmountItem } from 'common/components/summary';
import BalancePie from 'common/components/simulation/BalancePie';
import Title from 'components/Title';
import colors from 'components/colors';

const Balance = ({
    simulationSummary: {
        balance: {
            fees,
            funding,
            guaranteedIncomeFee,
            propertyTax,
            rent,
            saving,
            taxGains,
        },
    },
}) => (
    <Fragment>
        <Title className="margin-top"><b>Trésorerie lissée de l&apos;opération</b></Title>
        {saving <= 0 ? (
            <Grid size={3}>
                <AmountItem title="Recettes mensuelles" value={rent + taxGains} />
                <AmountItem title="Charges mensuelles" value={fees + propertyTax + guaranteedIncomeFee + funding} />
                <AmountItem title="Bénéfice mensuel" value={-saving} />
            </Grid>
        ) : (
            <div className="row">
                <div className="col-sm-4">
                    <Grid size={1}>
                        <AmountItem title="Recettes mensuelles" value={rent + taxGains} />
                        <AmountItem
                            title="Charges mensuelles"
                            value={fees + propertyTax + guaranteedIncomeFee + funding}
                        />
                        <AmountItem title="Épargne mensuelle" value={saving} />
                    </Grid>
                </div>
                <div className="col-sm-8">
                    <div className="charts-wrapper">
                        <ResponsiveContainer aspect={1.5} width="100%" minWidth={450} maxHeight={450}>
                            <BalancePie
                                balance={{ rent, saving, taxGains, colors }}
                            />
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        )}
    </Fragment>
);

Balance.propTypes = {
    simulationSummary: PropTypes.shape({
        balance: PropTypes.shape({
            fees: PropTypes.number,
            funding: PropTypes.number,
            guaranteedIncomeFee: PropTypes.number,
            propertyTax: PropTypes.number,
            rent: PropTypes.number,
            saving: PropTypes.number,
            taxGains: PropTypes.number,
        }),
    }).isRequired,
};

export default Balance;
