import { connect } from 'react-redux';
import { compose, withProps, branch, renderComponent, mapProps } from 'recompose';
import { change, formValues, formValueSelector } from 'redux-form';
import { connectDisabledForm } from '../../withDisabledForm';
import withFormName from '../../withFormName';
import { EditableFieldInner } from '../EditableField';
import InheritanceButton from './InheritanceButton';

const InheritanceField = compose(
    connectDisabledForm,
    branch(({ disabled }) => disabled, compose(
        formValues(({ name }) => ({ currentValue: name })),
        mapProps(({ currentValue, defaultValue, ...props }) => ({
            ...props,
            defaultValue: null === currentValue ? defaultValue : currentValue,
        })),
        renderComponent(EditableFieldInner),
    )),
    withFormName,
    connect((state, { form, name }) => ({
        isEdited: null !== formValueSelector(form)(state, name),
    }), { change }),
    withProps(({ name, defaultValue }) => ({
        editName: name,
        buttonComponent: InheritanceButton,
        buttonProps: {
            defaultValue,
            format: (value) => value !== null,
        },
    })),
)(EditableFieldInner);

export default InheritanceField;
