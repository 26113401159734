import { omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { alimonyTypes } from 'common/choices';
import { formValues } from 'redux-form';
import { DateField, IntegerField, ChoiceField, YesNoField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';

const AlimonyDeduction = ({ name, alimonyType, adultChildren }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <YesNoField
            name={`${name}.adultChildren`}
            title="Enfant(s) majeur(s)"
        />
        <ChoiceField
            name={`${name}.alimonyType`}
            choices={adultChildren ? alimonyTypes : omit(['sameRoof'], alimonyTypes)}
            title="Type"
            required
        />
        {!adultChildren || 'sameRoof' !== alimonyType ? (
            <IntegerField
                name={`${name}.amount`}
                title="Montant annuel"
                required
            />
        ) : null}
        {'withVerdict' === alimonyType ? (
            <DateField
                name={`${name}.date`}
                title="Date du jugement"
                required
            />
        ) : null}
        <IntegerField
            name={`${name}.childrenCount`}
            title="Nombre d&apos;enfants concern&eacute;s"
            required
        />
        <Recurrent name={name} />
    </HorizontalLayout>
);

AlimonyDeduction.propTypes = {
    name: PropTypes.string.isRequired,
    alimonyType: PropTypes.string.isRequired,
    adultChildren: PropTypes.bool.isRequired,
};

export default formValues(({ name }) => ({
    adultChildren: `${name}.adultChildren`,
    alimonyType: `${name}.alimonyType`,
}))(AlimonyDeduction);
