import { get, set } from 'lodash/fp';
import { socioProfessionalCategories } from '../../choices';

const isCspFieldOkWithStatus = (status, csp) => ['active', 'seekingJob', 'retired', 'student', 'unemployed'].includes(status) &&
    Object.keys(socioProfessionalCategories[status]).includes(csp);

const errorString = 'Vous devez renseigner ce champ.';

const checkCspField = (field1, field2) => (errors, values) => {
    const isCspFieldOk = isCspFieldOkWithStatus(get(field1, values), get(field2, values));

    return (!isCspFieldOk || null === get(field2, values)) ? set(field2, errorString, errors) : errors;
};

export default checkCspField;
