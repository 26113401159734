import multipleChoiceQuestion from '../../../../../utils/multipleChoiceQuestion';

const choices = {
    cash: { point: [0] },
    obligation: { point: [3] },
    action: { point: [4] },
    OPCVM: { point: [2] },
    SCPI: { point: [4] },
    estate: { point: [2] },
};

const question = (point, investorProfile) => multipleChoiceQuestion(investorProfile.investmentPossession, choices);

export default question;
