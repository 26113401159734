/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from 'lodash/fp';
import Icon from 'common/components/Icon';
import { useSession } from '../../sessions';
import redirectToTopinvest from '../../../utils/redirectToTopinvest';
import logo from '../../../images/topinvest-nav.png';

const NavButton = ({ list }) => {
    const session = useSession();

    return ('Topinvest' === session.entity.name ?
        (
            <div className="back-link to-home" >
                <Link onClick={() => redirectToTopinvest(session)} style={{ width: '7%', marginTop: '15px' }}>
                    <img src={logo} alt={get('entity.name', session)} style={{ width: '100%', marginBottom: '5px' }} />
                </Link>
            </div>
        ) : (
            <div className="back-link to-home">
                <Link to={list}>
                    <Icon icon="home" />
                </Link>
            </div>
        )
    );
};

NavButton.propTypes = {
    list: PropTypes.string,
};

NavButton.defaultProps = {
    list: null,
};

export default NavButton;
