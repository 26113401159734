import { FormItem } from '../layout/Wizard';

const wizard = {
    className: 'wizard-form',
    prefixTemplate: ({ id }) => `/rip/${id}`,
    itemComponent: FormItem,
    items: [
        {
            name: 'Etat Civil',
            target: 'etat-civil',
            form: 'rip-edit',
        },
        {
            name: 'Revenus & Impôts',
            target: 'impots',
            form: 'rip-taxes',
        },
        {
            name: 'Immobiliers & Crédits',
            target: 'patrimoine',
            form: 'rip-estate',
        },
        {
            name: 'Placements',
            target: 'finances',
            form: 'rip-finances',
        },
        {
            name: 'Objectifs',
            target: 'profil-client',
            form: 'rip-profil',
        },
    ],
};

export default wizard;
