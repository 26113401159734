import _ from 'lodash';

import { hasApprovalFor } from 'common/utils/recommendations';

const verifieAutorisationSimulationSelonProduit = (produit, session) => {
    const { instanceIdsoft } = session;
    switch (produit) {
        case 'lifeInsurance':
            return instanceIdsoft ? hasApprovalFor('lifeInsurance') : () => true;

        case 'per':
            return instanceIdsoft ? hasApprovalFor('per') : () => true;

        default:
            return hasApprovalFor(produit);
    }
};

const isSimulationRestricted = (produit) => {
    switch (produit) {
        case 'pinel':
        case 'pinelDenormandie':
        case 'scpi':
        case 'dismemberedScpi':
        case 'lmnp':
            return true;

        default:
            return false;
    }
};

export default class PermissionManager {
    constructor(session) {
        this.session = session;
    }

    /* Vérifie si l'utilisateur est administrateur */
    isAdmin() {
        return this.session.isAdmin;
    }

    /* Vérifie si l'utilisateur est administrateur reseau */
    isAdminReseau() {
        return this.session.isAdminReseau;
    }

    /* Vérifie si l'utilisateur est caseManager */
    isCaseManager(entity = null) {
        if ('caseManager' === this.session.status) {
            return true;
        }

        if ('cdd' === this.session.status) {
            return entity ? this.session.childrenIds.includes(entity.id) : false;
        }

        return false;
    }

    /* Vérifie si l'utilisateur est manager */
    isManager(entity = null) {
        if (this.session.isManager && entity) {
            return entity.managerId === this.session.id;
        }

        return this.session.isManager;
    }

    isCddManager() {
        if ('cdd' === this.session.status) {
            return true;
        }

        return false;
    }

    isEliteCGP() {
        return this.session.isEliteCGP;
    }

    /* Vérifie si l'utilisateur peut approuvé des simulation ou des recommandations de rip  */
    isValidator(entity = null) {
        return this.isManager() || this.isCaseManager(entity);
    }

    /* Vérifie si l'utilisateur peut approuver les contrats */
    mayValidateContracts() {
        return this.isCaseManager();
    }

    /* Vérifie si l'utilisateur peut créer les simulations selon le produit */
    mayCreateSimulation(rip, produit, target = 'client') {
        if (this.isCaseManager(_.get(rip, 'user.entity'))) {
            // Les caseManage peuvent tout créer
            return true;
        }

        // Vérifie si l'utilisateur à le droit d'accès à certaines simulations
        if (this.needApprovalOnSimulations() && isSimulationRestricted(produit)) {
            return false;
        }

        // Vérifie l'approbation par défaut
        return verifieAutorisationSimulationSelonProduit(produit, this.session)(
            rip,
            target,
            {
                canCreateSimulation: this.session.settings.canCreateSimulation,
                needApprovalOnSimulations: this.session.needApprovalOnSimulations,
                isEliteCGP: this.isEliteCGP(),
            },
        );
    }

    /* Vérifie si l'utilisateur peut lire une simulation */
    mayReadSimulation(simulation, rip) {
        return !simulation.deleted
            && (
                this.isCaseManager(rip.user.entity)
                || this.isEliteCGP()
                || this.session.settings.canCreateSimulation
                || simulation.completed
                || this.mayCreateSimulation(rip, simulation.product, simulation.beneficiary)
            );
    }

    /* Vérifie si l'utilisateur peut modifier une simulation */
    mayWriteOnSimulation(rip, simulation) {
        return !simulation.locked
            && !simulation.deleted
            && this.mayCreateSimulation(rip, simulation.product, simulation.beneficiary);
    }

    /* Vérifie si l'utilisateur peut télécharger le pdf d'une simulation */
    mayDownloadSimulationPDF(simulation, rip) {
        switch (simulation.product) {
            case 'lifeInsurance':
                return true;
            default:
                return this.isCaseManager(rip.user.entity)
                    || !this.session.needApprovalOnSimulations
                    || (this.session.needApprovalOnSimulations && 'approved' === simulation.approval)
                    || (!this.session.settings.canCreateSimulation && simulation.completed);
        }
    }

    /* may the user create a contract */
    // eslint-disable-next-line class-methods-use-this
    mayCreateContract() {
        // TODO : Every user can create a contract, for demo purpose only.
        // return !!this.session.oriasNumber;

        return true;
    }

    /* Vérifie si l'utilisateur est est soumis à validation */
    needApprovalOnSimulations() {
        return this.session.needApprovalOnSimulations;
    }
}
