import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import Icon from 'common/components/Icon';
import getOwnerFullName from 'common/utils/getOwnerFullName';
import dataProvider from '../dataProvider';
import { withSession } from '../sessions';
import withBeneficiaryRip from './withBeneficiaryRip';

import './Header.scss';

const ApprovalWarning = ({ approval = null }) => {
    switch (approval) {
        case 'approved':
            return (
                <div className="approval-warning">
                    <Icon icon="exclamation" className="fa-fw" />
                    Cette simulation est approuvée, toute modification invalidera celle-ci.
                </div>
            );
        case 'refused':
            return (
                <div className="approval-danger">
                    <Icon icon="times" className="fa-fw" />
                    Cette simulation est refusée
                </div>
            );
        case 'waiting':
            return (
                <div className="approval-info">
                    <Icon icon="info" className="fa-fw" />
                    Cette simulation est en cours de validation.
                </div>
            );
        default:
            return null;
    }
};

ApprovalWarning.propTypes = {
    approval: PropTypes.string,
};

const Header = ({ textRenderer, ...props }) => {
    const { rip, product, simulation, withApprovalWarnings } = props;

    return (
        <h4 className="simulation-header">
            <p>{textRenderer(props)}</p>
            <b>
                Simulation {product} de {getOwnerFullName(rip, simulation.beneficiary)}
            </b>
            {withApprovalWarnings ? <ApprovalWarning approval={simulation.approval} /> : null}
        </h4>
    );
};

Header.propTypes = {
    rip: PropTypes.shape({}).isRequired,
    simulation: PropTypes.shape({
        beneficiary: PropTypes.string,
        approval: PropTypes.string,
    }).isRequired,
    product: PropTypes.string.isRequired,
    withApprovalWarnings: PropTypes.bool,
    session: PropTypes.shape({}).isRequired,
    textRenderer: PropTypes.func,
};

Header.defaultProps = {
    withApprovalWarnings: true,
    // eslint-disable-next-line react/prop-types
    textRenderer: ({ session }) => (
        <em>
            Cette simulation n&apos;est donnée qu&apos;à titre indicatif et n&apos;a
            aucune valeur contractuelle. Les éléments indiqués ne peuvent engager
            la responsabilité de la société : {get('settings.displayName', session)}
        </em>
    ),
};

export default compose(
    dataProvider({
        id: ({ params: { simulationId } }) => simulationId,
        type: 'simulation',
    }),
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
    }),
    withBeneficiaryRip,
    withSession,
)(Header);
