import { mapValues, omitBy } from 'lodash/fp';
import React from 'react';
import { withProps } from 'recompose';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import TypedBlockCollectionItem from 'components/form/TypedBlockCollectionItem';
import TypedCollection from 'components/form/TypedCollection';
import { useSettingsMemo } from 'components/sessions/useSettings';
import SCPI from './SCPI';

const SCPICollection = (required) => {
    const types = useSettingsMemo(({ simulation: { scpi: scpis } }) => {
        const scpiSimus = omitBy(({ deleted }) => deleted, scpis);

        return mapValues(({ name, ...scpi }) => ({
            component: withProps({ scpi })(SCPI),
            title: name,
            itemTitle: `${name} - ${scpi.value}€`,
            unique: true,
            defaultValues: {
                parts: scpi.minimumParts,
                revaluation: '0.005',
            },
        }), scpiSimus);
    });

    return (
        <FormGroup>
            <Title><b>Produits {required ? '*' : ''}</b></Title>
            <TypedCollection
                name="data.products"
                types={types}
                textOnly
                itemComponent={TypedBlockCollectionItem}
            />
        </FormGroup>
    );
};

export default SCPICollection;
