import React from 'react';
import PropTypes from 'prop-types';

import './Button.css';

const MenuButton = ({ children, color, ...props }) => (
    <button type="button" className={`menu-button${color ? ` ${color}` : ''}`} {...props}>
        <p>{children}</p>
    </button>
);

MenuButton.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node.isRequired,
};

MenuButton.defaultProps = {
    color: null,
};

export default MenuButton;
