import { checkRequiredField, checkRequiredIntegerField } from '../lib';

const validateTaxItem = (name) => [
    checkRequiredIntegerField(`${name}.min`),
    checkRequiredIntegerField(`${name}.rate`),
];

const validateSlices = (name, length) => [...Array(length)].reduce((acc, _, index) => [
    ...acc,
    ...validateTaxItem(`${name}.${index}`),
], []);

const validateInvestment = (type) => [
    checkRequiredIntegerField(`summary.reductions.investment.${type}.rate`),
    checkRequiredIntegerField(`summary.reductions.investment.${type}.singleCeil`),
    checkRequiredIntegerField(`summary.reductions.investment.${type}.coupleCeil`),
];

const validateFamilyCeils = (name) => [
    checkRequiredIntegerField(`${name}.single`),
    checkRequiredIntegerField(`${name}.couple`),
];

const validators = [
    checkRequiredField('summary.incomeTax.rentalPropertyIncomesWeight'),
    checkRequiredField('summary.incomeTax.parts.defaultHalf'),
    checkRequiredField('summary.incomeTax.parts.defaultQuarter'),
    checkRequiredField('summary.incomeTax.fiscalParticularCasesValues.singleHavingRaisedChildMoreThan5Years'),
    checkRequiredField('summary.incomeTax.fiscalParticularCasesValues.widower'),
    checkRequiredField('summary.incomeTax.fiscalParticularCasesValues.singleParent'),
    checkRequiredField('summary.incomeTax.fiscalParticularCasesValues.disabled'),
    checkRequiredField('summary.incomeTax.fiscalParticularCasesValues.various'),
    checkRequiredField('summary.incomeTax.10percentAbatmentBounds.employed.min'),
    checkRequiredField('summary.incomeTax.10percentAbatmentBounds.employed.max'),
    checkRequiredField('summary.incomeTax.10percentAbatmentBounds.retired.min'),
    checkRequiredField('summary.incomeTax.10percentAbatmentBounds.retired.max'),
    ...validateSlices('summary.incomeTax.highIncomesSlices.single', 4),
    ...validateSlices('summary.incomeTax.highIncomesSlices.couple', 4),
    ...validateSlices('summary.incomeTax.slices', 5),
    ...validateTaxItem('summary.incomeTax.microBICAbatement.trade'),
    ...validateTaxItem('summary.incomeTax.microBICAbatement.services'),
    ...validateTaxItem('summary.incomeTax.microBNCAbatement'),
    ...validateTaxItem('summary.incomeTax.microBAAbatement'),
    checkRequiredField('summary.deductions.alimonyCeils.default'),
    checkRequiredField('summary.deductions.alimonyCeils.sameRoof'),
    checkRequiredField('summary.reductions.donation.ceil'),
    checkRequiredField('summary.reductions.donation.rate1.rate'),
    checkRequiredField('summary.reductions.donation.rate1.max'),
    checkRequiredField('summary.reductions.donation.rate2.rate'),
    checkRequiredField('summary.reductions.education.middleSchool'),
    checkRequiredField('summary.reductions.education.highSchool'),
    checkRequiredField('summary.reductions.education.higherEducation'),
    ...validateInvestment('fcpi'),
    ...validateInvestment('fip'),
    checkRequiredField('summary.reductions.subscription.rate'),
    checkRequiredField('summary.reductions.subscription.singleCeil'),
    checkRequiredField('summary.reductions.subscription.coupleCeil'),
    checkRequiredField('summary.credits.employeeAtHome.rate'),
    checkRequiredField('summary.credits.employeeAtHome.ceil'),
    checkRequiredField('summary.credits.employeeAtHome.maxCeil'),
    checkRequiredField('summary.credits.employeeAtHome.childrenOrDependentPart'),
    checkRequiredField('summary.credits.childCare.rate'),
    checkRequiredField('summary.credits.childCare.ceil'),
    checkRequiredField('summary.credits.homeWork.rate1'),
    checkRequiredField('summary.credits.homeWork.rate2'),
    checkRequiredField('summary.incomeTax.decote.rate'),
    ...validateFamilyCeils('summary.incomeTax.decote.incomeTaxCeils'),
    ...validateFamilyCeils('summary.incomeTax.decote.reductionCeils'),
    checkRequiredIntegerField('summary.incomeTax.extraReduction.rate'),
    ...validateFamilyCeils('summary.incomeTax.extraReduction.incomeCeils'),
    ...validateFamilyCeils('summary.incomeTax.extraReduction.degressiveReductionIncomeCeils'),
    ...validateFamilyCeils('summary.incomeTax.extraReduction.degressiveDivisors'),
    checkRequiredField('summary.incomeTax.extraReduction.extraPartIncrease.half'),
    checkRequiredField('summary.incomeTax.extraReduction.extraPartIncrease.quarter'),
];

export default validators;
