const choices = {
    marketplace: 'Rendez-vous TMP',
    eContractTMP: 'E-contact TMP',
    booge: 'Leads Booge',
    businessCapital: 'Fonds de commerce',
    reSignature: 'Re-signature client',
    personalProspect: 'Prospection personnelle',
    recommendation: 'Recommandation/Parrainage',
    forums: 'Salons',
    evenings: 'Soirées / Dîner clients',
    websites: 'Site Internet',
    socialNetwork: 'Réseaux sociaux',
    fourniParSGA: 'Rendez-vous fourni par la SGA',
    other: 'Autre',
};

export default choices;
