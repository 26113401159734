import { omit, get } from 'lodash/fp';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { Field, formValues } from 'redux-form';
import SingleEmailTemplateManager from 'components/SingleEmailTemplateManager';
import { POST } from 'utils/httpMethods';
import Checkbox from '../../form/Checkbox';
import Divider from '../../Divider';
import FormGroup from '../../form/Group';
import { GridLayout } from '../../form/layout';
import { useSession } from '../../sessions';
import RipForm from '../Form';
import { DateTimeField, TextAreaField, YesNoField, FileInputField } from '../../form/field';
import supportingDocuments from '../../../common/components/rip/appointment/supportingDocuments';
import Title from '../../Title';
import dataForm from '../dataForm';
import Icon from '../../../common/components/Icon';
import { setModal } from '../../../actions';
import SelectRecipients from './SelectRecipients';
import EmailPreviewed from './EmailPreviewed';

import './Form.css';

const Form = ({ renderCustomerEmailPreview, temporaryEmailTemplate, nextRedirectTo, ...props }) => {
    const session = useSession();
    const { instanceTheseis } = session;

    return (
        <RipForm {...props} nextRedirectTo={nextRedirectTo}>
            <DateTimeField
                title={
                    <span>
                        Date du <b>prochain rendez-vous</b>
                    </span>
                }
                name="appointmentDate"
            />
            {
                !instanceTheseis && (
                    <Fragment>

                        <FormGroup>
                            <Title>
                                <b>Envoyez un fichier personnalisé</b>
                            </Title>
                            <FileInputField name="customAttachment" allowEmpty />
                        </FormGroup>
                        {supportingDocuments.map((section) => (
                            <FormGroup key={section.title}>
                                <Title>
                                    <b>{section.title}</b>
                                </Title>
                                {section.fields ? (
                                    <GridLayout>
                                        {Object.entries(section.fields).map(([key, value]) => (
                                            <Field title={value} component={Checkbox} name={key} key={key} />
                                        ))}
                                    </GridLayout>
                                ) : (
                                    section.subsections.map((subsection) => (
                                        <Fragment key={subsection.title}>
                                            <h6 className="subsection-title">{subsection.title}</h6>
                                            <GridLayout>
                                                {Object.entries(subsection.fields).map(([key, value]) => (
                                                    <Field title={value} component={Checkbox} name={key} key={key} />
                                                ))}
                                            </GridLayout>
                                        </Fragment>
                                    ))
                                )}
                            </FormGroup>
                        ))}
                        <Divider />
                        <YesNoField
                            title="Voulez-vous inclure le récapitulatif du Relevé d'Informations Patrimoniales en PDF en pièce jointe ?"
                            name="attachedPDF"
                        />
                        <Divider />
                        {false === get('entity.overrideEmailTemplates', session) && (
                            <SingleEmailTemplateManager
                                templateName="customerEmail"
                                previewRender={renderCustomerEmailPreview}
                            />
                        )}
                    </Fragment>

                )
            }
            <TextAreaField title="Commentaires" name="appointmentComment" />
            <button className="lastButtonRipForm sendButton" type="submit">
                {temporaryEmailTemplate ? 'Prévisualiser l\'email' : 'Selectionner les destinataires'}&nbsp;
                <Icon icon="angle-right" />
            </button>
        </RipForm>
    );
};

Form.propTypes = {
    renderCustomerEmailPreview: PropTypes.func.isRequired,
    temporaryEmailTemplate: PropTypes.shape({}),
    nextRedirectTo: PropTypes.string,
};

export default compose(
    dataForm({
        processValues: omit(['customAttachment']),
        form: 'rip-appointment',
        id: ({ id }) => id,
        type: 'rip',
        onSubmit: ({ customAttachment, temporaryEmailTemplate, id }, dispatch, session) => {
            if (temporaryEmailTemplate) {
                dispatch(setModal(() => (
                    <EmailPreviewed
                        template={temporaryEmailTemplate}
                        id={id}
                        customAttachment={customAttachment}
                    />
                )));
            } else {
                dispatch(setModal(() => (
                    <SelectRecipients
                        id={id}
                        customAttachment={customAttachment}
                        temporaryEmailTemplate={temporaryEmailTemplate}
                        session={session}
                    />
                )));
            }
        },
    }),
    withProps(({ id }) => ({
        renderCustomerEmailPreview: async (plainText) => {
            const response = await POST(`/api/rips/${id}/emailPreview/customerEmail`, {
                method: 'POST',
                body: {
                    temporaryEmailTemplate: JSON.stringify({
                        subject: '',
                        body: plainText,
                    }),
                },
                credentials: 'include',
            });

            const data = await response.json();

            return data.body;
        },
    })),
    formValues('temporaryEmailTemplate'),
)(Form);
