import { isString } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import Group from '../Group';
import Title from '../../Title';
import FieldInfo from '../FieldInfo';

const Vertical = ({ children, htmlFor, info, required, title, ...props }) => (
    <Group required={required} htmlFor={htmlFor} {...props}>
        <Title>
            {isString(title) ? <b>{title}</b> : title}
            {required ? <b>*</b> : null}
            {info ? <FieldInfo id={`${htmlFor}Info`}>{info}</FieldInfo> : null}
        </Title>
        {children}
    </Group>
);

Vertical.propTypes = {
    children: PropTypes.node.isRequired,
    htmlFor: PropTypes.string,
    info: PropTypes.node,
    required: PropTypes.bool,
    title: PropTypes.node.isRequired,
};

Vertical.defaultProps = {
    htmlFor: null,
    info: null,
    required: false,
};

export default Vertical;
