import LifeInsurance from './LifeInsurance';
import Loan from './Loan';
import ADICalculator from './ADICalculator';

const simulators = {
    lifeInsurance: {
        card: {
            icon: 'calculator',
            title: 'Assurance-Vie',
        },
        component: LifeInsurance,
    },
    loan: {
        card: {
            icon: 'credit-card',
            title: 'Crédit',
        },
        component: Loan,
    },
    adi: {
        card: {
            icon: 'calculator',
            title: 'Calculette ADI',
        },
        component: ADICalculator,
    },
};

export default simulators;
