import React from 'react';
import { faScaleBalanced, faPersonCane, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import RipAlimonyDeduction from './AlimonyDeduction';
import RipRetirementDeduction from './RetirementDeduction';
import RipOtherDeduction from './OtherDeduction';

const types = {
    alimony: {
        component: RipAlimonyDeduction,
        icon: faScaleBalanced,
        title: 'Pensions alimentaires',
        defaultValues: {
            adultChildren: false,
            alimonyType: 'withoutVerdict',
            recurrentEvent: true,
        },
    },
    retirement: {
        component: RipRetirementDeduction,
        icon: faPersonCane,
        title: 'Epargne retraite',
        defaultValues: {
            recurrentEvent: true,
        },
    },
    other: {
        component: RipOtherDeduction,
        icon: faEllipsis,
        title: 'Autres pensions',
        defaultValues: {
            recurrentEvent: true,
        },
    },
};

const TaxDeductionsList = () => (
    <FormGroup>
        <Title>Avez-vous <b>des déductions fiscales&nbsp;?</b></Title>
        <TypedCollection name="deductions" types={types} />
    </FormGroup>
);

export default TaxDeductionsList;
