import validate from './validate';

const branchValidate = (test, branch) => {
    const validator = validate(branch);

    return (errors, values, props) => (test(errors, values, props)
        ? validator(values, props, errors)
        : errors
    );
};

export default branchValidate;
