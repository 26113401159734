import React from 'react';
import { Layout } from 'components/layouts';
import { useSession } from '../sessions';
import MenuItem from './MenuItem';

import './Menu.scss';

const getMenuItems = (session) => [
    {
        to: '/admin/user',
        title: 'Utilisateurs',
        description: 'Accédez à la liste de tous les utilisateurs d\'e-Rip',
        icon: 'users',
    },
    {
        to: '/admin/entity',
        title: 'Réseaux',
        description: 'Accédez à la liste des réseaux sous forme d\'arborescence',
        icon: 'building',
    },
    ...session.instanceSettings.hasStripeSubscription && session.permissions.isAdmin()
        ? [{
            to: '/admin/invoices',
            title: 'Factures',
            description: 'Accédez à la liste des factures d\'abonnement à e-Rip',
            icon: 'book',
        }]
        : [],
    {
        to: '/admin/settings',
        title: 'Paramètres',
        description: 'Accédez au paramétrage des champs (impôt, simulation...)',
        icon: 'wrench',
    },
    ...session && session.permissions.isAdmin() ? [
        {
            to: '/admin/syncLogs',
            title: 'Logs MO',
            description: 'Accédez à l\'historique des actions de synchro MO',
            icon: 'newspaper-o',
        }] : [],
];

const Menu = () => {
    const session = useSession();
    const menuItems = getMenuItems(session);

    return (
        <Layout>
            <div className="admin-menu">
                {menuItems.map((itemProps) => <MenuItem key={itemProps.to} {...itemProps} />)}
            </div>
        </Layout>
    );
};

export default Menu;
