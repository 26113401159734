import PropTypes from 'prop-types';
import React from 'react';
import { compose, withContext } from 'recompose';
import { formValues } from 'redux-form';
import Divider from '../../Divider';
import RipForm from '../Form';
import { TextAreaField } from '../../form/field';
import dataForm from '../dataForm';
import InvestmentsList from './InvestmentsList';

const WithHaveCharges = formValues('haveCharges')(({ haveCharges, children }) => children(haveCharges));

WithHaveCharges.propTypes = {
    children: PropTypes.func.isRequired,
};

const Form = (props) => (
    <RipForm {...props}>
        <InvestmentsList />
        <Divider />
        <TextAreaField title="Commentaires" name="chargesComment" />
    </RipForm>
);

export default compose(
    dataForm({
        form: 'rip-finances',
        id: ({ id }) => id,
        type: 'rip',
    }),
    withContext({
        change: PropTypes.func.isRequired,
    }, ({ change }) => ({ change })),
)(Form);
