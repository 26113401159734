import React from 'react';
import _, { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import fullName, { afficheNomPrenom } from 'common/utils/fullName';
import isRequiredField from '../../../../../../utils/isRequiredField';
import SelectSignature from './SelectSignature';
import { useSession } from '../../../../../sessions';
import { getRequiredFieldActivationDate } from '../../../../../../actions';

const SelectSignatures = ({ rip, clientState, partnerState, partnerShouldSign, enLigne, requiredFieldActivationDateReducer }) => {
    const [clientSignatureMode, setClientSignatureMode] = clientState;
    const [partnerSignatureMode, setPartnerSignatureMode] = partnerState;
    const isRequiredInvestorProfiles = isRequiredField(rip.createdAt, requiredFieldActivationDateReducer, 'investorProfiles');

    if (!_.get(rip, 'investorProfiles.client') && isRequiredInvestorProfiles) {
        setClientSignatureMode('no');
    }

    if (!_.get(rip, 'investorProfiles.partner') && isRequiredInvestorProfiles) {
        setPartnerSignatureMode('no');
    }

    const session = useSession();

    const definiLeNomDuConseillerAAfficher = () => {
        if (!enLigne) {
            return afficheNomPrenom(_.get(session, 'firstName'), _.get(session, 'lastName'));
        }

        return afficheNomPrenom(_.get(rip, 'user.firstName'), _.get(rip, 'user.lastName'));
    };

    return (
        <div className="sign-configuration">
            <SelectSignature
                title={`Client (${fullName(rip)})`}
                selectedSignature={clientSignatureMode}
                onClick={setClientSignatureMode}
                enLigne={enLigne}
                avecAlerte
                investorProfiles={_.get(rip, 'investorProfiles.client')}
                isRequiredField={isRequiredInvestorProfiles}
            />
            {partnerShouldSign && (
                <SelectSignature
                    title={`Conjoint (${fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName })})`}
                    selectedSignature={partnerSignatureMode}
                    onClick={setPartnerSignatureMode}
                    enLigne={enLigne}
                    avecAlerte
                    investorProfiles={_.get(rip, 'investorProfiles.partner')}
                    isRequiredField={isRequiredInvestorProfiles}

                />
            )}
            <SelectSignature
                title={`Conseiller (${definiLeNomDuConseillerAAfficher()})`}
                manualOnly
            />
        </div>
    );
};

SelectSignatures.propTypes = {
    rip: PropTypes.shape({
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        createdAt: PropTypes.string,
    }).isRequired,
    clientState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    partnerState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    partnerShouldSign: PropTypes.bool,
    enLigne: PropTypes.bool,
    requiredFieldActivationDateReducer: PropTypes.string,
};

export default flowRight([
    connect(({ requiredFieldActivationDateReducer }) => ({
        requiredFieldActivationDateReducer,
    }), { getRequiredFieldActivationDate }), lifecycle({
        componentDidMount() {
            this.props.getRequiredFieldActivationDate('investorProfiles');
        },
    }),
])(SelectSignatures);
