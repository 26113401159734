import React, { Fragment, useEffect, useState } from 'react';
import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import { pure, getContext } from 'recompose';
import { useFlag } from '@unleash/proxy-client-react';

import { Table, Head, Header, Body, withActiveItem } from '../list';
import ListItem from './ListItem';
import withSpinner from '../withSpinner';
import withTutorial from '../withTutorial';
import ErrorMessage from '../ErrorMessage';
import { GET } from '../../utils/httpMethods';

const NoRipMessage = ({ hasActiveSearch }) => (
    <ErrorMessage>
        {hasActiveSearch ? (
            <div>Aucun e-RIP ne correspond à votre recherche</div>
        ) : (
            <div>Pour créer votre premier e-RIP, cliquez sur le (+) en bas à droite de cette page.</div>
        )}
    </ErrorMessage>
);

NoRipMessage.propTypes = {
    hasActiveSearch: PropTypes.bool.isRequired,
};

const ListTableInner = ({ items, mode, miseAJourListe, userId, listeDeMesEquipes, setRipAEnlever }) => {
    const [ripsDuConseiller, setRipsDuConseiller] = useState([]);
    const [triChoisi, setTriChoisi] = useState({ property: 'updatedAt', order: 'desc' });

    const isSignatureStateEnabled = useFlag('erip-427-signatureState');

    useEffect(() => {
        if (listeDeMesEquipes) {
            GET(`/api/rips/user-rips/${userId}/${triChoisi.property}/${triChoisi.order ? triChoisi.order.toUpperCase() : 'DESC'}`)
                .then(async (donnees) => {
                    const ripsRecuperes = await donnees.json();
                    setRipsDuConseiller(ripsRecuperes.rows);
                });
        }
    }, [triChoisi]);

    if (ripsDuConseiller.length > 0 || (items.length > 0 && items[0].id)) {
        return (
            <Table>
                <Head>
                    <th />
                    <Header sortProperty="lastName" text="Nom" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                    {['all', 'allForManager'].includes(mode) ? (
                        <Fragment>
                            <Header sortProperty="user.lastName" text="Conseiller" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                            <Header sortProperty="user.entity.name" text="Réseau" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                        </Fragment>
                    ) : null}
                    <Header sortProperty="createdAt" text="Date de création" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                    <Header sortProperty="updatedAt" text="Dernière modification" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                    {isSignatureStateEnabled && ['all', 'allForManager'].includes(mode) ?
                        <Fragment>
                            <Header sortProperty="signature" text="Signature" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                        </Fragment>
                        : (
                            <Fragment>
                                <Header sortProperty="state" text="Statut" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                            </Fragment>)
                    }
                    {isSignatureStateEnabled && 'user' === mode ? (
                        <Fragment>
                            <Header sortProperty="signature" text="Signature" miseAJourListe={miseAJourListe} setTriChoisi={setTriChoisi} />
                        </Fragment>
                    ) : null}
                    <th />
                    <th />
                </Head>
                <Body>
                    {(ripsDuConseiller.length > 0 ? ripsDuConseiller : items).map((item) => (item.id && <ListItem key={item.id} item={item} mode={mode} setRipAEnlever={setRipAEnlever} />))}
                </Body>
            </Table>
        );
    }

    return (
        <ErrorMessage><div>Aucun e-RIP ne correspond à votre recherche</div></ErrorMessage>
    );
};

export const itemShape = {
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
};

ListTableInner.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
    mode: PropTypes.string.isRequired,
    miseAJourListe: PropTypes.func,
    userId: PropTypes.string,
    listeDeMesEquipes: PropTypes.bool,
    setRipAEnlever: PropTypes.func,
};

const ListTable = flowRight([
    withSpinner,
    withTutorial(getContext({ hasActiveSearch: PropTypes.bool.isRequired })(NoRipMessage)),
    withActiveItem,
    pure,
])(ListTableInner);

export default ListTable;
