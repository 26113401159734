// Charges

import { filter, flow, get, has, map, sum } from 'lodash/fp';
import _ from 'lodash';
import { parseInteger, getSciShareFromAmount } from '../utils';

const getMonthlyPayment = ({ monthlyPayment }) => parseInteger(monthlyPayment);

const getRent = ({ rent }) => parseInteger(rent);

const getDueDateAmount = (field = 'mortgages') =>
    flow([
        get(field),
        map(({ dueDateAmount, type, insuranceAmount }) =>
            ('owed' === type ? parseInteger(dueDateAmount) + parseInteger(insuranceAmount) : 0)),
        sum,
    ]);

const getSecondaryResidenceDueDateAmount = getDueDateAmount('mortgages');

const getMainResidenceDueDateAmount = getDueDateAmount('mainResidenceMortgages');

const getCarMonthlyPayment = ({ carOption, monthlyPayment, rent }) => {
    if ('loan' === carOption) {
        return parseInteger(monthlyPayment);
    }

    return parseInteger(rent);
};

const getCreditAmount = {
    secondHome: getSecondaryResidenceDueDateAmount,
    rentalProperty: getSecondaryResidenceDueDateAmount,
    other: getSecondaryResidenceDueDateAmount,
    scpi: getSecondaryResidenceDueDateAmount,
};

// computeCharges renvoie un objet:
// {
//  charges: somme de toutes les charges (pensions et échéances locatives comprises),
//  pensions: somme des pensions alimentaires
//  rentalPropertyCharges: somme des échéances pour le locatif
// }

const computeCharges = (rip) => {
    const getCharges = {
        carLoan: getCarMonthlyPayment,
        consumerLoan: getMonthlyPayment,
        other: (charge) => getSciShareFromAmount(charge.monthlyPayment, rip, charge.fiscalHome),
        pension: getRent,
    };

    let charges = flow([
        filter(({ type }) => has(type, getCharges)),
        map((charge) => getCharges[charge.type](charge)),
        sum,
    ])(rip.charges);

    charges += flow([
        filter(({ type }) => has(type, getCreditAmount)),
        map((property) => getCreditAmount[property.type](property)),
        sum,
    ])(rip.property);

    if ('owner' === rip.mainResidence) {
        charges += getMainResidenceDueDateAmount(rip);
    }

    if ('tenant' === rip.mainResidence) {
        charges += parseInteger(rip.mainResidenceRent);
    }

    if ('freeAccomodation' === rip.mainResidence) {
        charges += parseInteger(rip.hypotheticalRent);
    }

    const creditScpi = [];

    let investissementsSCPIAvecCredit = [];

    if (rip.investments) {
        investissementsSCPIAvecCredit = _.get(rip, 'investments', []).filter((investissement) => 'SCPI' === investissement.type && _.get(investissement, 'mortgages', []).length > 0);

        investissementsSCPIAvecCredit.forEach((investissement) => {
            creditScpi.push({
                ...investissement,
                type: 'scpi',
            });
        });

        charges += flow([
            map((credit) => getCreditAmount[credit.type](credit)),
            sum,
        ])(creditScpi);
    }

    const pensions = flow([
        filter(({ type }) => 'pension' === type),
        map((charge) => getCharges[charge.type](charge)),
        sum,
    ])(rip.charges);

    const rentalPropertyCharges = flow([
        filter(({ type }) => 'rentalProperty' === type),
        map((charge) => getSecondaryResidenceDueDateAmount(charge)),
        sum,
    ])(rip.property);

    return { charges, pensions, rentalPropertyCharges };
};

export default computeCharges;
