import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useFlag } from '@unleash/proxy-client-react';
import { FaIcon } from '../../../common/components/fa';
import { hideMenu, showOverlay } from '../../../actions';
import hasSigned from '../../../utils/hasSigned';
import MenuButton from '../../menu/Button';
import { withSession } from '../../sessions';
import redirectToTopinvest from '../../../utils/redirectToTopinvest';
import withRouter from '../../withRouter';

import './Items.css';

const renderPrecoButton = (rip, onClickItem, session) => {
    const enabled = useFlag('erip-1223-CIF');

    if (hasSigned(rip) && ((enabled && session.needApprovalOnCIFDocument) || session.needApprovalOnSimulations)) {
        return (
            <div className="item" >
                <span>Préconisations</span>
                <MenuButton onClick={onClickItem('recommendation')}>
                    <FaIcon icon="share" />
                </MenuButton>
            </div>
        );
    }
    if (enabled && !hasSigned(rip) && (session.needApprovalOnCIFDocument || session.needApprovalOnSimulations)) {
        return (
            <OverlayTrigger
                trigger={['hover', 'click']}
                overlay={<Popover className="form-field-info-popover" placement="bottom" >Pour accéder aux préconisations, vous devez signer le RIP.</Popover>}
                placement="left"
                rootClose
            >
                <div className="item">
                    <div className="disabled" >
                        <span>Préconisations</span>
                        <MenuButton onClick={onClickItem('recommendation')} disabled color="disabled">
                            <FaIcon icon="share" />
                        </MenuButton>
                    </div>
                </div>
            </OverlayTrigger>
        );
    }

    return null;
};

const MenuItems = ({ onClickItem, rip, session }) => (
    <div className="rip-menu-items">
        {session.isAdmin || ((session.managedIds.includes(rip.user.entity.id) || 'caseManager' === session.status) && !session.isAdminReseau) ? (
            <div className="item">
                <span>Réattribuer</span>
                <MenuButton onClick={onClickItem('reattribute')}>
                    <FaIcon icon="exchange" />
                </MenuButton>
            </div>
        ) : null}
        {'IFB' === get('settings.displayName', session) ? (
            <div className="item">
                <span>Guide de vente</span>
                <MenuButton onClick={onClickItem('ifb-sells-guide')}>
                    <FaIcon icon="file" />
                </MenuButton>
            </div>
        ) : null}

        {'Topinvest' !== session.entity.name ? (
            renderPrecoButton(rip, onClickItem, session)
        ) : null
        }

        {'Topinvest' !== session.entity.name ? (
            <div className="item">
                <span>Parrainage</span>
                <MenuButton onClick={onClickItem('sponsoring')}>
                    <FaIcon icon="id-card-o" />
                </MenuButton>
            </div>
        ) : null}
        <div className="item">
            <span>Récapitulatif</span>
            <MenuButton onClick={onClickItem('summary')}>
                <FaIcon icon="pie-chart" />
            </MenuButton>
        </div>
        <div className="item">
            <span>Simulateurs</span>
            <MenuButton onClick={'Topinvest' !== session.entity.name ? onClickItem('simulators') : () => redirectToTopinvest(session)}>
                <FaIcon icon="bar-chart" />
            </MenuButton>
        </div>
        <div className="item">
            <span>Notes</span>
            <MenuButton onClick={onClickItem('comments')}>
                <FaIcon icon="commenting" />
            </MenuButton>
        </div>
    </div>
);

MenuItems.propTypes = {
    onClickItem: PropTypes.func.isRequired,
    rip: PropTypes.shape({
        user: PropTypes.shape({
            entity: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    }).isRequired,
    session: PropTypes.shape({
        isAdmin: PropTypes.bool,
        isAdminReseau: PropTypes.bool,
        managedIds: PropTypes.string,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        status: PropTypes.string,
        needApprovalOnSimulations: PropTypes.bool,
        needApprovalOnCIFDocument: PropTypes.bool,
    }).isRequired,
};

const mapStateToProps = (state, { params: { id } }) => ({
    rip: state.data.rip[id],
});

const mapDispatchToProps = (dispatch) => ({
    onClickItem: (overlay) => () => {
        dispatch(hideMenu());
        dispatch(showOverlay(overlay));
    },
});

export default compose(
    withRouter,
    withSession,
    connect(mapStateToProps, mapDispatchToProps),
)(MenuItems);
