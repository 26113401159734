import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './Button.css';

const Button = ({ children, className, ...props }) => (
    <button type="button" className={classNames('white-btn', className)} {...props}>
        {children}
    </button>
);

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Button.defaultProps = {
    className: null,
};

export default Button;
