import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { faFaceAngry, faFaceFrown, faFaceGrin, faFaceMeh, faFaceSmileBeam } from '@fortawesome/free-regular-svg-icons';

import { formatPercentage } from 'common/utils/format';
import useSettings from 'components/sessions/useSettings';
import { investmentRisks, investmentBeneficiaries } from '../../../common/choices';
import Scale from '../../form/Scale';
import { HorizontalLayout } from '../../form/layout';
import {
    ChoiceField,
    DateField,
    DisabledField,
    EditableField,
    PercentageField,
    IntegerField,
    TextField,
} from '../../form/field';
import OwnerField from '../field/Owner';
import Mortgages from '../estate/Mortgages';

const values = [
    {
        icon: faFaceAngry,
        value: 1,
    },
    {
        icon: faFaceFrown,
        value: 2,
    },
    {
        icon: faFaceMeh,
        value: 3,
    },
    {
        icon: faFaceGrin,
        value: 4,
    },
    {
        icon: faFaceSmileBeam,
        value: 5,
    },
];

const Profitability = (type, name) => {
    const { LDDRate, livretARate } = useSettings(['finance']);

    switch (type) {
        case 'savingsAccount':
            return (
                <DisabledField
                    title="Rentabilité"
                    name={`${name}.profitability`}
                    value={formatPercentage(livretARate)}
                />
            );
        case 'LDD':
            return (
                <EditableField
                    type="percentage"
                    title="Rentabilité"
                    name={`${name}.profitability`}
                    editName={`${name}.editProfitability`}
                    defaultValue={LDDRate}
                />
            );
        default:
            return <PercentageField title="Rentabilité" name={`${name}.profitability`} />;
    }
};

const InvestmentsCommonInner = ({ beneficiary, name, showBeneficiary, type, isRisky, showSci }) => (
    <Fragment>
        <HorizontalLayout>
            {!['savingsAccount', 'LDD'].includes(type) && <TextField title="Intitulé" name={`${name}.title`} />}
            {Profitability(type, name)}
            <IntegerField title="Capital" name={`${name}.capital`} />
            {'currentAccount' !== type && <IntegerField title="Versements mensuels" name={`${name}.monthlyPayment`} />}
            {isRisky && (
                <ChoiceField
                    name={`${name}.risks`}
                    choices={investmentRisks}
                    title="Risque de l'investissement&nbsp;?"
                />
            )}
            {'PEE' === type && <DateField title="Date de disponibilité" name={`${name}.availabilityDate`} />}
            <DateField title="Date de souscription" name={`${name}.subscriptionDate`} />
            <TextField title="Établissement" name={`${name}.establishment`} />
            <OwnerField name={`${name}.subscriber`} title="Souscripteur" required showSci={showSci} child />
            {showBeneficiary && (
                <ChoiceField name={`${name}.beneficiary`} choices={investmentBeneficiaries} title="Bénéficiaire" />
            )}
            {showBeneficiary && 'other' === beneficiary && (
                <TextField name={`${name}.specifyBeneficiary`} title="Précisez" />
            )}
            <TextField title="Objectif" name={`${name}.goal`} />
            <Scale name={`${name}.satisfaction`} title="Satisfaction" values={values} />
        </HorizontalLayout>
        {'SCPI' === type && <Mortgages name={`${name}.mortgages`} owner={beneficiary} />}
    </Fragment>
);

InvestmentsCommonInner.propTypes = {
    beneficiary: PropTypes.string,
    name: PropTypes.string.isRequired,
    showBeneficiary: PropTypes.bool,
    type: PropTypes.string,
    isRisky: PropTypes.bool,
    showSci: PropTypes.bool,
};

InvestmentsCommonInner.defaultProps = {
    beneficiary: null,
    showBeneficiary: false,
    type: null,
    isRisky: false,
    showSci: false,
};

const InvestmentsCommon = formValues(({ name }) => ({
    beneficiary: `${name}.beneficiary`,
}))(InvestmentsCommonInner);

export default InvestmentsCommon;
