/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { POST, DELETE } from 'utils/httpMethods';
import Footer from 'components/rip/Footer';
import Icon from 'common/components/Icon';
import Spinner from 'components/Spinner';
import SelectSignatures from './SelectSignatures/SelectSignatures';
import SignatureStatus from './SignatureStatus/SignatureStatus';

import '../../../rip/commitment/Signatures/Menu/Menu.scss';

const LaunchSign = (
    changeComponent,
    clientSigner,
    partnerSigner,
    advisorSigner,
    hasOngoingSignatureProcess,
    documentId,
    ripId,
) => () => (
    <div className="sign-btn-wrapper">
        <button
            className="sign-btn"
            onClick={async () => {
                if (hasOngoingSignatureProcess) {
                    await DELETE(`/api/documents/${documentId}/sign/${ripId}`);
                }

                return changeComponent('electronic-sign', { clientSigner, partnerSigner, advisorSigner })();
            }}
        >
            Lancer les signatures&nbsp;&nbsp;&nbsp;
            <Icon icon="angle-right" />
        </button>
    </div>
);

const Menu = ({ rip, documentId, changeComponent, back, completedDocument, partnerShouldSign }) => {
    const [loading, setLoading] = useState(true);
    const [hasOngoingSignatureProcess, setHasOngoingSignatureProcess] = useState(true);

    const [canSelectSignatures, setCanSelectSignatures] = useState(!hasOngoingSignatureProcess);

    const [clientSignatureMode, setClientSignatureMode] = useState('universign');
    const [partnerSignatureMode, setPartnerSignatureMode] = useState('universign');
    const [advisorSignatureMode, setAdvisorSignatureMode] = useState('universign');

    const [clientResignUrl, setClientResignUrl] = useState(null);
    const [partnerResignUrl, setPartnerResignUrl] = useState(null);
    const [advisorResignUrl, setAdvisorResignUrl] = useState(null);

    const [clientTransactionStatus, setClientTransactionStatus] = useState(null);
    const [partnerTransactionStatus, setPartnerTransactionStatus] = useState(null);
    const [advisorTransactionStatus, setAdvisorTransactionStatus] = useState(null);

    useEffect(() => {
        POST(`/api/sign-status/document/${documentId}/${rip.id}`)
            .then((res) => res.json())
            .then((transactionStatus) => {
                setHasOngoingSignatureProcess(transactionStatus.hasOngoingSignatureProcess);
                setCanSelectSignatures(!transactionStatus.hasOngoingSignatureProcess);
                for (const tx of transactionStatus.signerInfos) {
                    if (tx.firstName.trim().toLowerCase() === rip.firstName.trim().toLowerCase() && tx.lastName.trim().toLowerCase() === rip.lastName.trim().toLowerCase()) {
                        setClientSignatureMode('universign');
                        setClientResignUrl(tx.url);
                        setClientTransactionStatus(tx.status);
                    }

                    if (partnerShouldSign && tx.firstName.trim().toLowerCase() === rip.partnerFirstName.trim().toLowerCase() && tx.lastName.trim().toLowerCase() === rip.partnerLastName.trim().toLowerCase()) {
                        setPartnerSignatureMode('universign');
                        setPartnerResignUrl(tx.url);
                        setPartnerTransactionStatus(tx.status);
                    }

                    if (tx.firstName.trim().toLowerCase() === rip.user.firstName.trim().toLowerCase() && tx.lastName.trim().toLowerCase() === rip.user.lastName.trim().toLowerCase()) {
                        setAdvisorSignatureMode('universign');
                        setAdvisorResignUrl(tx.url);
                        setAdvisorTransactionStatus(tx.status);
                    }
                }
                setLoading(false);
            });
    }, []);

    const canContinue = useMemo(
        () => [
            clientSignatureMode,
            ...partnerShouldSign ? [partnerSignatureMode] : [],
            advisorSignatureMode,
        ].some((signatureMode) => 'universign' === signatureMode) && canSelectSignatures,
        [clientSignatureMode, partnerSignatureMode, advisorSignatureMode, canSelectSignatures],
    );

    if (loading) {
        return <Spinner />;
    }

    return (
        <Fragment>
            <div className="signature-menu">
                <SignatureStatus
                    rip={rip}
                    client={{
                        status: clientTransactionStatus,
                        mode: clientSignatureMode,
                        resignUrl: clientResignUrl,
                    }}
                    partner={{
                        status: partnerTransactionStatus,
                        mode: partnerSignatureMode,
                        resignUrl: partnerResignUrl,
                    }}
                    advisor={{
                        status: advisorTransactionStatus,
                        mode: advisorSignatureMode,
                        resignUrl: advisorResignUrl,
                    }}
                    partnerShouldSign={partnerShouldSign}
                />
                {canSelectSignatures && [
                    ...hasOngoingSignatureProcess ? [(
                        <div className="relaunch" key="relaunchButton">
                            <button className="cancel" onClick={() => setCanSelectSignatures(false)}>Annuler la relance</button>
                        </div>
                    )] : [],
                    <SelectSignatures
                        key="select-signatures"
                        rip={rip}
                        clientState={[clientSignatureMode, setClientSignatureMode]}
                        partnerShouldSign={partnerShouldSign}
                        partnerState={[partnerSignatureMode, setPartnerSignatureMode]}
                        advisorState={[advisorSignatureMode, setAdvisorSignatureMode]}
                    />,
                ]}
                {!canSelectSignatures && completedDocument && (
                    <div className="relaunch">
                        <button onClick={() => setCanSelectSignatures(true)}>Relancer le process de signature</button>
                    </div>
                )}
            </div>
            <Footer
                back={back}
                nextButtonComponent={LaunchSign(
                    changeComponent,
                    clientSignatureMode,
                    partnerSignatureMode,
                    advisorSignatureMode,
                    hasOngoingSignatureProcess,
                    documentId,
                    rip.id,
                )}
                last={!canContinue}
                isHiddenButtonForTheseis={!canSelectSignatures}
            />
        </Fragment>
    );
};

Menu.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
    }).isRequired,
    documentId: PropTypes.string.isRequired,
    changeComponent: PropTypes.func.isRequired,
    back: PropTypes.string.isRequired,
    completedDocument: PropTypes.shape({}),
    partnerShouldSign: PropTypes.bool,
};

export default Menu;
