const requiredFieldActivationDateReducer = (state = {}, { type, field, activationDate }) => {
    switch (type) {
        case 'SET_REQUIRED_FIELD_ACTIVATION_DATE':
            return {
                ...state,
                [field]: activationDate,
            };
        default:
            return state;
    }
};

export default requiredFieldActivationDateReducer;
