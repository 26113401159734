import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { DateField, DisabledField, InputField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
import { OwnerField, ChildrenField } from '../field';
import GenerationalAnalysis from './GenerationalAnalysis';
import isRequiredGrandChildrenAndPartnerField from '../../../common/utils/isRequiredGrandChildrenAndPartnerField';

const GrandChild = ({ name, birthDate, initialValues }) => {
    const { createdAt } = initialValues;
    const isRequired = isRequiredGrandChildrenAndPartnerField(createdAt);

    return (
        <HorizontalLayout>
            <OwnerField
                name={`${name}.ascendant`}
                title="Ascendant"
                onlyCohabitation
                required={isRequired}
                single
            />
            <ChildrenField name={`${name}.childParent`} title="Parent" isRequired={isRequired} />
            <InputField name={`${name}.firstName`} title="Prénom" type="text" required={isRequired} />
            <InputField name={`${name}.lastName`} title="Nom" type="text" />
            <DateField name={`${name}.birthDate`} title="Date de naissance" />
            <DisabledField
                name={`${name}.age`}
                value={birthDate ? `${moment().diff(birthDate, 'years', false)} ans` : ''}
                title="Âge"
            />
            <GenerationalAnalysis name={name} />
        </HorizontalLayout>
    );
};

GrandChild.propTypes = {
    birthDate: PropTypes.string,
    name: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        createdAt: PropTypes.string,
    }),
};

export default formValues(({ name }) => ({ birthDate: `${name}.birthDate` }))(GrandChild);
