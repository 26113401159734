import React, { Fragment } from 'react';
import { withState, lifecycle } from 'recompose';
import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import { POST } from '../utils/httpMethods';
import PublicLayout from './PublicLayout';
import withRouter from './withRouter';

const Reset = ({
    checked,
}) => (
    <PublicLayout>
        {null !== checked && (
            <div className="messageBlock">
                {checked ? (
                    <Fragment>
                        <h3>Votre demande de réinitialisation a été effectuée</h3>
                        <p>Consultez vos mails pour obtenir votre nouveau mot de passe</p>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h3>Votre lien de réinitialisation a expiré</h3>
                        <p>Veuillez retourner à la page mot de passe oublié pour en généner un nouveau</p>
                    </Fragment>
                )}
            </div>
        )}
    </PublicLayout>
);

Reset.propTypes = {
    checked: PropTypes.bool,
};

Reset.defaultProps = {
    checked: null,
};

export default flowRight([
    withRouter,
    withState('checked', 'setChecked', undefined),
    lifecycle({
        componentDidMount() {
            const { params: { id }, setChecked } = this.props;
            POST(`/api/reset/${id}`, {
                body: { id },
            })
                .then((response) => response.json())
                .then((response) => setChecked(response.isOkToReset))
                .catch((response) => {
                    if (401 === response.status) {
                        setChecked(false);
                    }
                });
        },
    }),
])(Reset);
