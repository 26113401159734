import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const Donation = ({ name }) => (
    <Fragment>
        <Title>Dons à des associations</Title>
        <GridLayout className="double condensed full-width">
            <div className="double">
                <PercentageFloatField
                    name={`${name}.ceil`}
                    title="Plafond de réduction (en % des revenus) (%)"
                />
            </div>
            <PercentageFloatField
                name={`${name}.rate1.rate`}
                title="Pourcentage de réduction 1 (%)"
            />
            <IntegerField
                name={`${name}.rate1.max`}
                title="Plafond de réduction 1 (€)"
            />
            <PercentageFloatField
                name={`${name}.rate2.rate`}
                title="Pourcentage de réduction 2 (%)"
            />
        </GridLayout>
    </Fragment>
);

Donation.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Donation;
