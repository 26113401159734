import { flowRight, pick } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { branch, renderNothing } from 'recompose';
import DropDown from 'components/form/FieldDropDown';
import { GridLayout } from 'components/form/layout/index';
import { FileField, SelectField } from 'components/form/field/index';
import { documentsList } from 'common/choices';

const options = pick([
    'houseBill',
    'electricityCertificate',
    'townhallCertificate',
    'taxNotice',
    'insuranceCertificate',
    'rentReceipt',
    'proofOfAccommodation',
], documentsList);

const AddressProofInner = ({ uploader, addressProof }) => (
    <DropDown title="Justificatif de domicile de moins de 3 mois" name="virtual.addressProof">
        <GridLayout className="single condensed">
            <SelectField
                title="Type du justificatif"
                name="data.addressProof"
                required
            >
                <option />
                {Object.entries(options).map(([value, label]) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </SelectField>
        </GridLayout>
        {addressProof ? (
            <Fragment>
                <hr />
                <FileField
                    // we need it as a controlled component
                    key={addressProof}
                    name={addressProof}
                    uploader={uploader(addressProof)}
                    uploadText="Justificatif"
                    reduxField="files.addressProof"
                />
            </Fragment>
        ) : null}
    </DropDown>
);

AddressProofInner.propTypes = {
    uploader: PropTypes.func.isRequired,
    addressProof: PropTypes.string,
};

const AddressProof = flowRight([
    formValues({
        phoneBillAddressDiffers: 'data.phoneBillAddressDiffers',
        professionalPhone: 'data.professionalPhone',
        addressProof: 'data.addressProof',
    }),
    branch(
        ({ professionalPhone, phoneBillAddressDiffers }) => !professionalPhone && !phoneBillAddressDiffers,
        renderNothing,
    ),
])(AddressProofInner);

export default AddressProof;
