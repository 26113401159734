import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, YesNoField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import { grandParentsSituations } from 'common/choices';

const GrandParent = ({ name }) => (
    <GridLayout className="triple condensed full-width">
        <SelectField name={`${name}.situation`} title="Situation">
            <option />
            {Object.entries(grandParentsSituations)
                .map(([value, label]) => (
                    <option key={value} value={value}>{label}</option>
                ))}
        </SelectField>
        <YesNoField
            title="Transmission ?"
            name={`${name}.transmission`}
            double
        />
    </GridLayout>
);

GrandParent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default GrandParent;
