import PropTypes from 'prop-types';
import React from 'react';
import { Legend, Pie, PieChart } from 'recharts';
import { formatPercentage } from '../../utils/format';

const data = ({ charges, rawIncomes, taxes }) => {
    const remainingIncomes = rawIncomes - taxes - (charges * 12);

    return [
        { name: 'impôts', value: taxes / rawIncomes, fill: '#248fdf' },
        { name: 'charges', value: (charges * 12) / rawIncomes, fill: '#27cae6' },
        { name: 'revenus après impôts et charges', value: remainingIncomes / rawIncomes, fill: '#a2ebac' },
    ];
};

const formatLabel = ({ value }) => formatPercentage(value);

const BalancePie = ({ balance, ...props }) => (
    <PieChart {...props}>
        <Pie
            data={data(balance)}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            isAnimationActive={false}
            label={formatLabel}
            labelLine
            legendType="circle"
        />
        <Legend layout="vertical" verticalAlign="bottom" />
    </PieChart>
);

BalancePie.propTypes = {
    balance: PropTypes.shape({
        charges: PropTypes.number.isRequired,
        rawIncomes: PropTypes.number.isRequired,
        taxes: PropTypes.number.isRequired,
    }).isRequired,
};

export default BalancePie;
