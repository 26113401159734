import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import FormGroup from 'components/form/Group';
import Title from 'components/Title';
import Divider from 'components/Divider';
import diffMonths from 'common/model/simulation/common/funding/diffMonths';
import computeLeapInterestsAmount from 'common/model/simulation/common/funding/leapInterestsAmount';
import DetailForm from './DetailForm';
import LoanForm from './LoanForm';
import InfineForm from './InfineForm';

const FundingForm = ({ fundingAmount, fundingRate = 0, instalmentCosts, propertyActDate, propertyDeliveryDate }) => {
    const fundedMonths = propertyActDate && propertyDeliveryDate
        ? diffMonths(new Date(propertyActDate), new Date(propertyDeliveryDate))
        : 0;

    const leapInterestsAmount = computeLeapInterestsAmount(fundingAmount, fundingRate, fundedMonths);

    return (
        <Fragment>
            <FormGroup>
                <Title><b>L&apos;emprunt bancaire</b></Title>
                <LoanForm fundingAmount={fundingAmount} defaultLeapInterestsAmount={leapInterestsAmount} />
            </FormGroup>
            <Divider />
            <FormGroup>
                <Title><b>Détail du financement</b></Title>
                <DetailForm
                    fundingAmount={fundingAmount}
                    leapInterestsAmount={leapInterestsAmount}
                    fundedMonths={fundedMonths}
                />
                <InfineForm
                    fundingAmount={fundingAmount}
                    instalmentCosts={instalmentCosts}
                    fundedMonths={fundedMonths}
                />
            </FormGroup>
        </Fragment>
    );
};

FundingForm.propTypes = {
    fundingAmount: PropTypes.number,
    fundingRate: PropTypes.number,
    instalmentCosts: PropTypes.number,
    propertyDeliveryDate: PropTypes.string,
    propertyActDate: PropTypes.string,
};

export default formValues({
    propertyActDate: 'propertyActDate',
    propertyDeliveryDate: 'propertyDeliveryDate',
    fundingRate: 'fundingRate',
})(FundingForm);

export { default as formValidate } from './formValidate';
