import { get } from 'lodash/fp';
// this is a work around to to use settings as global
// and work on an isomorphic scale (both server & client sides)

let settings = null;

export const setSettings = (newSettings) => {
    // trick to update the value returned by the singleton
    settings = newSettings;
};

// temporary solution to quickly set it up
// in future, we should get settings from another source
const getSettings = (path = null) => (path ? get(path, settings) : settings);

export default getSettings;
