import * as Sentry from '@sentry/browser';
import { POST } from './httpMethods';

// historisation des données exportées
const saveExportLogs = async (id, count, mode) => {
    const dataExport = {
        mode,
        ripsCount: count,
    };

    await POST(`/api/users/${id}/exportLogs`, { body: dataExport });
};

export default saveExportLogs;

// vérification des rips à exporter
export const saveAndExportRips = (userId, items, userStatus, mode) => {
    const filteredRips = items.filter((item) => item.user.entity.managerId === userId || item.user.id === userId);

    if (filteredRips.length === items.length) {
        saveExportLogs(userId, items.length, mode);
        Sentry.captureMessage(`Un export des rips en mode ${mode} a été éffectué par l'utilisateur ${userId}, ${userStatus}, nombre de rip(s): ${items.length}`, 'info');

        return items;
    }

    const nombreDeRipsDifferents = items.length - filteredRips.length;
    const ripExclus = items.filter((item) => item.user.entity.managerId !== userId && item.user.id !== userId);

    saveExportLogs(userId, filteredRips.length, mode);

    Sentry.withScope((scope) => {
        scope.setLevel('warning');

        Sentry.captureException(new Error(` Une erreur s'est produite lors de l'export des rips en mode ${mode} par l'utilisateur ${userId}, ${userStatus}, nombre de rip(s) non autorisé(s): ${nombreDeRipsDifferents} ; ${ripExclus.map((rip) => rip.id)}`));
    });

    return filteredRips;
};
