import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { GridLayout } from 'components/form/layout';
import Dropdown from 'components/Dropdown';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField, TextAreaField } from 'components/form/field';
import sortSettings from 'common/utils/sortSettings';
import { DELETE } from 'utils/httpMethods';
import DismembermentShare from './DismembermentShare';
import ScpiDistribution from './ScpiDistribution';
import UploadSettingFiles from '../../UploadSettingFiles';
import '../../EditForm.scss';

export const getOpposite = (type) => ('dismemberedScpi' === type ? 'scpi' : 'dismemberedScpi');

const ScpiDropdown = ({ deleted, name, product, updateScpi, type }) => {
    const deleteSetting = useCallback(async (e) => {
        e.preventDefault();
        if (window.confirm('Supprimer le contrat ?')) {
            await DELETE(`/api/settings/simulation.${type}.${product}`);
            window.location.reload(true);
        }
    }, []);

    const uploadSetting = (field) => (
        <UploadSettingFiles documents={field.input.value} settingKey={`simulation.${type}.${product}`} onChange={field.input.onChange} />
    );

    return (
        <Dropdown title={`${deleted ? '(supprimé) ' : ''}${name}`}>
            <GridLayout className="triple condensed full-width">
                <IntegerField
                    onChange={updateScpi(`${product}.value`)}
                    name={`simulation.${type}.${product}.value`}
                    title="Prix de part (€)"
                />
                <IntegerField
                    onChange={updateScpi(`${product}.minimumParts`)}
                    name={`simulation.${type}.${product}.minimumParts`}
                    title="Nombre de part minimum"
                />
                <IntegerField
                    onChange={updateScpi(`${product}.beforeDividendsDuration`)}
                    name={`simulation.${type}.${product}.beforeDividendsDuration`}
                    title="Délai de jouissance (mois)"
                />
                <PercentageFloatField
                    onChange={updateScpi(`${product}.fees`)}
                    name={`simulation.${type}.${product}.fees`}
                    title="Commission de souscription (%)"
                />
                <PercentageFloatField
                    onChange={updateScpi(`${product}.simulationRate`)}
                    name={`simulation.${type}.${product}.simulationRate`}
                    title="Rendement locatif (%)"
                />
                {'dismemberedScpi' === type && <DismembermentShare product={product} />}
                <TextAreaField
                    onChange={updateScpi(`${product}.description`)}
                    className="full"
                    name={`simulation.${type}.${product}.description`}
                    title="Description"
                />
            </GridLayout>
            <ScpiDistribution baseName={`simulation.${type}.${product}.distribution`} />
            <Title><b>Annexes PDF</b></Title>
            <Field name={`simulation.${type}.${product}.documents`} component={uploadSetting} />
            <hr />
            <button className="theseis-btn" onClick={deleteSetting}>Supprimer le contrat</button>
        </Dropdown>
    );
};

ScpiDropdown.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    deleted: PropTypes.bool,
    updateScpi: PropTypes.func.isRequired,
};

const ScpiSettings = ({ settings, type, changeValue }) => {
    const updateScpi = useCallback((path) => (_, value) => {
        changeValue('settings-simulation-edit', `simulation.${getOpposite(type)}.${path}`, value);
    }, [type]);

    return (
        <Fragment>
            {sortSettings(settings).map(([product, { deleted, documents, name }]) => (
                <ScpiDropdown
                    key={product}
                    deleted={deleted}
                    documents={documents}
                    name={name}
                    type={type}
                    product={product}
                    updateScpi={updateScpi}
                />
            ))}
        </Fragment>
    );
};

ScpiSettings.propTypes = {
    settings: PropTypes.shape({}).isRequired,
    type: PropTypes.string.isRequired,
    changeValue: PropTypes.func.isRequired,
};

export default connect(null, { changeValue: change })(ScpiSettings);
