import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Ul = ({ children, className }) => (
    <ul className={classNames('fa-ul', className)}>
        {children}
    </ul>
);

Ul.defaultProps = {
    className: '',
};

Ul.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Ul;
