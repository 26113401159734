/* eslint-disable array-callback-return */
import { flowRight, get, last } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withPropsOnChange } from 'recompose';
import buildTree from 'common/utils/buildInheritanceTree';
import withSpinner from 'components/withSpinner';
import { Body, Head, Header, Table, listProvider, withActiveItem, CreateLink } from 'components/list';
import BreadCrumbs from './BreadCrumbs';
import Entity from './Entity';
import { withSession } from '../../../sessions';
import filterEntitiesChildren from '../../../../common/utils/sortEntitiesChildren';

class EntityTableInner extends Component {
    constructor(props) {
        super(props);

        this.displayChildren = this.displayChildren.bind(this);
        this.displayParents = this.displayParents.bind(this);

        this.state = {
            parents: [],
        };
    }

    getChildren(parents, items) {
        return parents.reduce((children, { id: parentId }) => {
            const foundParent = children.find(({ id }) => parentId === id);

            return foundParent && foundParent.children ? foundParent.children : items;
        }, this.props.items);
    }

    displayChildren(entity) {
        const parents = [...this.state.parents, entity];
        const children = this.getChildren(parents);

        if (children.length <= 0) {
            return;
        }

        this.setState({ parents });
    }

    displayParents(entityId) {
        const index = this.state.parents
            .map(get('id'))
            .indexOf(entityId);

        this.setState({ parents: this.state.parents.slice(0, index + 1) });
    }

    render() {
        const { hasActiveSearch, items } = this.props;
        const { parents } = this.state;
        const entities = this.getChildren(parents, items);
        const lastParentUrl = last(parents) ? `/${last(parents).id}` : '';

        return (
            <Fragment>
                {!hasActiveSearch && <BreadCrumbs parents={parents} moveUp={this.displayParents} />}
                <Table>
                    <Head>
                        <Header sortProperty="name" text="Nom" />
                        <Header sortProperty="manager.firstName" text="Manager" />
                        <Header sortProperty="caseManager.firstName" text="Chargé d'affaires" />
                        <Header sortProperty="createdAt" text="Date de création" />
                        <Header sortProperty="updatedAt" text="Sous-réseaux" />
                        <th />
                    </Head>
                    <Body>
                        {entities && entities.map((entity) => (
                            <Entity
                                key={entity.id}
                                {...entity}
                                displayChildren={this.displayChildren}
                            />
                        ))}
                    </Body>
                </Table>
                <CreateLink to={`/admin/entity/create${lastParentUrl}`} />
            </Fragment>
        );
    }
}

EntityTableInner.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
    })).isRequired,
    hasActiveSearch: PropTypes.bool.isRequired,
    session: PropTypes.shape({
        isAdmin: PropTypes.bool,
        isAdminReseau: PropTypes.bool,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    entities: PropTypes.arrayOf(PropTypes.shape({
        filter: PropTypes.func,
    })).isRequired,

};

const EntityTable = flowRight([
    listProvider({
        sorts: {
            name: { initialOrder: 'asc', default: true },
            updatedAt: { initialOrder: 'desc' },
            createdAt: { initialOrder: 'desc' },
        },
        name: 'entity',
        type: 'entity',
    }),
    withActiveItem,
    withSession,
    withPropsOnChange(
        ['items', 'hasActiveSearch'],
        ({ items, hasActiveSearch, session }) => {
            let managed = [];

            // condition pour l'admin réseau, afin qu'il ne voit que ses réseaux et réseaux enfants
            const entityOfTheUser = items.filter((entity) => entity.name === session.entity.name);
            if (session.isAdminReseau && !session.isAdmin && entityOfTheUser[0]) {
                // pour construire l'arborescence de l'admin réseau il faut supprimer le parentId du réseau "parent"
                entityOfTheUser[0].parentId = null;

                const myChildrenEntities = filterEntitiesChildren(items, entityOfTheUser[0].id);
                managed = [entityOfTheUser, myChildrenEntities].flat();

                // la fonction buildTree sert à construire l'arborescence des réseaux parents et enfants
                return ({ items: hasActiveSearch ? managed : buildTree(managed) });
            }

            return ({ items: hasActiveSearch ? items : buildTree(items) });
        },
    ),
    connect((state) => ({
        entities: Object.values(state.data.entity),
    })),
    withSpinner,
])(EntityTableInner);

export default EntityTable;
