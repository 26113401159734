import React from 'react';
import Layout from '../layouts/Layout';
import Invoices from './Invoices';

const InvoicesList = () => (
    <Layout className="invoices">
        <h1 className="text-center">Mes factures</h1>
        <Invoices />
    </Layout>
);

export default InvoicesList;
