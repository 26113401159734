import PropTypes from 'prop-types';
import React from 'react';
import { InputField, IntegerField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import OwnerField from '../field/Owner';

const TaxExemptedIncome = ({ name, ripFromRecommendation }) => (
    <HorizontalLayout>
        <IntegerField name={`${name}.income`} title="Revenu annuel exonéré" required />
        <InputField name={`${name}.recurring`} title="Récurrent" type="checkbox" />
        <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" ripFromRecommendation={ripFromRecommendation} required />
    </HorizontalLayout>
);

TaxExemptedIncome.propTypes = {
    name: PropTypes.string.isRequired,
    ripFromRecommendation: PropTypes.shape({}),
};

export default TaxExemptedIncome;
