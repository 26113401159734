import { clamp } from 'lodash/fp';
import getSettings from '../settings';

// we preserve an aproximative compute function
// we cannot access summary right in rip, as it might not be complete
// refer to uncappedReductions.js => computeDonation
const computeAmountDonation = ({ percentage: rawPercentage, amount }) => {
    const {
        rate1: { rate: rate1, max },
    } = getSettings('summary.reductions.donation');

    const percentage = parseFloat(rawPercentage);

    const donation = rate1 === percentage ? clamp(0, max)(amount) : amount;

    return { computedAmount: donation * percentage };
};

export default computeAmountDonation;
