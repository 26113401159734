import { subscriptionTypesIdsoft, subscriptionTypesTheseis, subscriptionTypesInovia } from '../choices/subscriptionTypes';

const getSubscriptionType = (instanceSettingLabel) => {
    switch (instanceSettingLabel.toUpperCase()) {
        case 'IDSOFT':
            return subscriptionTypesIdsoft;
        case 'THESEIS':
            return subscriptionTypesTheseis;
        case 'INOVIA':
            return subscriptionTypesInovia;

        default:
            return null;
    }
};

export default getSubscriptionType;
