import { get, flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { formatAmount, formatCount } from 'common/utils/format';
import computeInstalmentCosts from 'common/model/simulation/lmnp/instalmentCosts';
import { tvaFunderTypes } from 'common/choices';
import { GridLayout } from 'components/form/layout';
import {
    DisabledField,
    FloatField,
    IntegerField,
    PercentageField,
    SelectField,
    TextField,
    YesNoField,
} from 'components/form/field';
import withFormName from 'components/form/withFormName';
import FieldInfo from 'components/form/FieldInfo';
import Dropdown from 'components/Dropdown';
import EditableField from 'components/simulation/EditableField';
import EditableDataField from 'components/form/field/EditableField';

const InnerOperationForm = ({ className, simulation }) => {
    // compute some
    const {
        instalmentCosts: {
            estateDepreciationAmount,
            investment,
            investmentWithTVA,
            tva,
            rawTVA,
            defaultNotaryFees,
            defaultOtherAcquisitionCost,
            yearlyRent,
            taxBenefitBasis,
        },
    } = computeInstalmentCosts(simulation);

    const isTvaPromoter = 'promoter' === get('data.funding.tvaFunder', simulation);

    return (
        <div className={className}>
            <GridLayout className="condensed">
                {/* notary fields */}
                <TextField name="notaryName" title="Nom du notaire" />
                <TextField name="notaryCity" title="Ville du notaire" />
                <IntegerField name="propertyValue" title="Montant de l'immobilier (HT)" min={0} required />
                <IntegerField name="data.property.landValue" title="Montant du terrain (HT)" min={0} />
                <IntegerField name="data.property.furnitureValue" title="Montant du mobilier (HT)" min={0} />
                {/* parking lot fields */}
                <FloatField name="parkingLotValue" title="Montant du parking (HT)" min={0} required />
            </GridLayout>
            <Dropdown title="Taux de TVA">
                <GridLayout className="condensed">
                    <EditableDataField
                        type="percentage"
                        title="Taux de TVA de l'immobilier"
                        editName="data.tvaRates.editablePropertyTvaRate"
                        name="data.tvaRates.propertyTvaRate"
                        defaultValue={0.2}
                    />
                    <EditableDataField
                        type="percentage"
                        title="Taux de TVA du terrain"
                        editName="data.tvaRates.editableLandTvaRate"
                        name="data.tvaRates.landTvaRate"
                        defaultValue={0.2}
                    />
                    <EditableDataField
                        type="percentage"
                        title="Taux de TVA du mobilier"
                        editName="data.tvaRates.editableFurnitureTvaRate"
                        name="data.tvaRates.furnitureTvaRate"
                        defaultValue={0.2}
                    />
                    <EditableDataField
                        type="percentage"
                        title="Taux de TVA du parking"
                        editName="data.tvaRates.editableParkingTvaRate"
                        name="data.tvaRates.parkingTvaRate"
                        defaultValue={0.2}
                    />
                </GridLayout>
            </Dropdown>
            <GridLayout className="condensed">
                <YesNoField name="parkingLotWithSeparateLease" title="Le parking fait-il objet d'un bail séparé ?" />
                {simulation.parkingLotWithSeparateLease ? (
                    <FloatField name="parkingLotRent" title="Loyer du parking" min={0} />
                ) : null}
                {/* end of the parking lo fields */}
            </GridLayout>
            <GridLayout className="condensed">
                <IntegerField
                    name="data.property.estateBrokerageFees"
                    title={(
                        <div>
                            Frais d&apos;ing&eacute;nierie (HT)
                            <FieldInfo id="estateBrokerageFeesInfo">
                                liés au bien
                            </FieldInfo>
                        </div>
                    )}
                    required
                />
            </GridLayout>
            <GridLayout className="condensed">
                <EditableField
                    editable={simulation.editableNotaryFees}
                    name="notaryFees"
                    editableName="editableNotaryFees"
                    title="Frais de notaire"
                    defaultValue={defaultNotaryFees}
                />
                <EditableField
                    editable={simulation.editableOtherAcquisitionCost}
                    name="otherAcquisitionCost"
                    editableName="editableOtherAcquisitionCost"
                    title="Autres frais d&apos;acquisition"
                    defaultValue={defaultOtherAcquisitionCost}
                />
            </GridLayout>
            <GridLayout className="condensed">
                <PercentageField
                    name="data.property.furnitureDepreciationRate"
                    title="Taux d'amortissement du mobilier"
                />
                <DisabledField
                    title="Durée de l'amortissement du mobilier"
                    name="data.product.furnitureDepreciationDuration"
                    value={formatCount(1 / get('data.property.furnitureDepreciationRate', simulation))}
                />
                <EditableField
                    editable={get('data.property.editableFurnitureDepreciationBasis', simulation)}
                    name="data.property.furnitureDepreciationBasis"
                    editableName="data.property.editableFurnitureDepreciationBasis"
                    title="Base de calcul de l'amortissement du mobilier"
                    defaultValue={get('data.property.furnitureValue', simulation)}
                />
            </GridLayout>
            <GridLayout className="condensed">
                {'censi-bouvard' === get('data.program.type', simulation) ? (
                    <EditableField
                        editable={get('data.taxBenefitBasis.edited', simulation)}
                        title="Base de calcul de l'avantage fiscal"
                        name="data.taxBenefitBasis.value"
                        editableName="data.taxBenefitBasis.edited"
                        defaultValue={taxBenefitBasis}
                        max={300000}
                    />
                ) : null}
                {estateDepreciationAmount ? (
                    <Fragment>
                        <PercentageField
                            name="data.property.estateDepreciationRate"
                            title="Taux d'amortissement de l'immobilier"
                        />
                        <DisabledField
                            title="Durée de l'amortissement de l'immobilier"
                            name="data.product.estateDepreciationDuration"
                            value={formatCount(1 / get('data.property.estateDepreciationRate', simulation))}
                        />
                        <EditableField
                            editable={get('data.property.editableEstateDepreciationBasis', simulation)}
                            title="Base de calcul de l'amortissement de l'immobilier"
                            name="data.property.estateDepreciationBasis"
                            editableName="data.property.editableEstateDepreciationBasis"
                            defaultValue={estateDepreciationAmount}
                        />
                    </Fragment>
                ) : null}
            </GridLayout>
            <GridLayout className="condensed">
                <DisabledField
                    title="Montant de l'investissement (HT)"
                    name="investmentWithoutTaxes"
                    value={formatAmount(investment)}
                />
                <div className="content-field-info">

                    <DisabledField
                        title={(
                            <Fragment>
                                Montant de l&apos;investissement (TTC) + frais d&apos;acquisition
                                <FieldInfo id="investmentWithTaxes" className="FieldInfo">
                                    <div>
                                        <p> Le montant s&apos;entend HT lorsque la TVA est avancée par le promoteur. </p>
                                    </div>
                                </FieldInfo>
                            </Fragment>
                        )}
                        name="investmentWithTaxes"
                        value={formatAmount(isTvaPromoter ? investment : investmentWithTVA)}
                        info={"Le montant s'entend HT lorsque la TVA est avancée par le promoteur."}
                    />
                </div>
                <DisabledField
                    title="Montant de la TVA"
                    name="TVA"
                    value={formatAmount(rawTVA)}
                />
                <DisabledField
                    title="Montant de la TVA récupérable par le client"
                    name="recoverableTVA"
                    value={formatAmount(tva)}
                />
                <FloatField name="propertyMonthlyRent" title="Loyer / Recette mensuel(le)s (HT)" required />
                <DisabledField
                    title="Loyer / Recette annuel(le)s (HT)"
                    name="yearlyRent"
                    value={formatAmount(yearlyRent, true)}
                />
                <SelectField name="data.funding.tvaFunder" title="Mode de financement de la TVA">
                    {Object.entries(tvaFunderTypes).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </SelectField>
                <YesNoField
                    title="Avez-vous besoin d&apos;ajouter un financement&nbsp;?"
                    name="needFunding"
                />
            </GridLayout>
        </div>
    );
};

InnerOperationForm.propTypes = {
    className: PropTypes.string,
    simulation: PropTypes.shape({
        parkingLotWithSeparateLease: PropTypes.bool,
        editableNotaryFees: PropTypes.bool,
        editableOtherAcquisitionCost: PropTypes.bool,
    }).isRequired,
};

InnerOperationForm.defaultProps = {
    className: null,
};

const OperationForm = flowRight([
    withFormName,
    connect((state, { form }) => ({ simulation: getFormValues(form)(state) })),
])(InnerOperationForm);

export default OperationForm;
