import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { formValues } from 'redux-form';
import Group from 'components/form/Group';
import TypedBlockCollectionItem from 'components/form/TypedBlockCollectionItem';
import TypedCollection from 'components/form/TypedCollection';
import { GridLayout } from '../../form/layout';
import { TextField, InputField, IntegerField } from '../../form/field';
import OwnerField from '../field/Owner';

class SciCommon extends Component {
    renderShare() {
        const { name, owner, familySituation } = this.props;
        const partner = 'cohabitation' === familySituation ? 'concubin' : 'conjoint';

        switch (owner) {
            case 'partner':
                return (
                    <IntegerField
                        name={`${name}.partnerShare`}
                        title={`Quote-part de votre ${partner}`}
                        required
                        min="0"
                        max="100"
                    />
                );
            case 'clientAndPartner':
                return (
                    <Fragment>
                        <IntegerField
                            name={`${name}.yourShare`}
                            title="Votre quote-part"
                            required
                            min="0"
                            max="100"
                        />
                        <IntegerField
                            name={`${name}.partnerShare`}
                            title={`Quote-part de votre ${partner}`}
                            required
                            min="0"
                            max="100"
                        />
                    </Fragment>
                );
            default:
                return <IntegerField name={`${name}.yourShare`} title="Votre quote-part" required min="0" max="100" />;
        }
    }

    // Render collection of other owner (name with share)
    renderOther() {
        const { name: prefix } = this.props;
        const otherType = {
            otherOwner: {
                component: ({ name }) => (
                    <GridLayout className="triple condensed full-width">
                        <TextField name={`${name}.name`} title="Nom" required />
                        <IntegerField name={`${name}.share`} title="Quote-part" min="0" max="100" />
                    </GridLayout>
                ),
                title: 'Propriétaire externe au foyer',
            },
        };

        return (
            <Group className="in-grid">
                <TypedCollection
                    name={`${prefix}.otherOwnerWithShare`}
                    types={otherType}
                    textOnly
                    itemComponent={TypedBlockCollectionItem}
                />
            </Group>
        );
    }

    render() {
        const { type, name } = this.props;

        return (
            <Fragment>
                <GridLayout className="triple condensed full-width">
                    <InputField title="Nom de la SCI" type="text" name={`${name}.sciName`} required />
                    <OwnerField name={`${name}.owner`} title="Propriétaire(s)" />
                    {/* Quote part de chacun */}
                    {this.renderShare()}
                </GridLayout>
                {this.renderOther()}
                <GridLayout className="triple condensed full-width">
                    {'sciIR' === type && <IntegerField name={`${name}.landRentalResults`} title="Résultat foncier" />}
                    {'sciIS' === type && <IntegerField name={`${name}.taxableProfit`} title="Bénéfice imposable" />}
                </GridLayout>
            </Fragment>
        );
    }
}

SciCommon.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    owner: PropTypes.string,
    familySituation: PropTypes.string,
};

SciCommon.defaultProps = {
    name: null,
    owner: null,
    familySituation: null,
};

export default formValues(({ name }) => ({
    owner: `${name}.owner`,
    familySituation: 'familySituation',
}))(SciCommon);
