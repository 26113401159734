import PropTypes from 'prop-types';
import React from 'react';
import { withContext } from 'recompose';
import HorizontalGroup from '../group/Horizontal';

import './Horizontal.css';

const HorizontalInner = ({ children }) => (
    <div className="form-layout-horizontal">
        {children}
    </div>
);

HorizontalInner.propTypes = {
    children: PropTypes.node.isRequired,
};

const Horizontal = withContext({
    formGroup: PropTypes.func.isRequired,
}, () => ({
    formGroup: HorizontalGroup,
}))(HorizontalInner);

export default Horizontal;
