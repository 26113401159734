// Impot sur le revenu

import { clamp, filter, flow, map, sortBy, sum, includes, get } from 'lodash/fp';
import getTaxFromSlices from './getTaxFromSlices';
import computeLowIncomesTax from './lowIncomesTax';
import computeHighIncomesTax from './highIncomesTax';

const maxDecrease = 10000;

// La réduction d'impôt liée au quotient familial est limitée.
// Par défaut elle est limitée à 1 527 € pour chaque demi-part supplémentaire.
// (764 € pour chaque quart de part supplémentaire).
// Ce plafond change en fonction des situations cf https://www.service-public.fr/particuliers/vosdroits/F2702

// Cas non pris en charge:
// - personnes qui ont eu au moins un enfant à charge dans le passé
// pendant au moins cinq ans (hasBeenSingleParent)
// - les titulaires de carte d'invalidité ou d'ancien combattant
// ou ?? (various)

const getCeil = (rip, settings) => {
    const {
        parts,
        fiscalParticularCasesValues: partCases,
    } = get('summary.incomeTax', settings);

    let ceil = 0;
    // Plafond lié aux demi-part lié au handicap
    if (includes('disabled', rip.fiscalParticularCases)) {
        ceil += parts.defaultHalf + partCases.disabled;
    }

    // Plafond lié aux anciens combattant
    if (includes('warVeteran', rip.fiscalParticularCases)) {
        ceil += parts.defaultHalf + partCases.various;
    }

    if (includes(rip.familySituation, ['married', 'partner'])
        && includes('disabled', rip.partnerFiscalParticularCases)) {
        ceil += parts.defaultHalf + partCases.disabled;
    }

    // Get dependent children
    const dependentChildren = flow([
        filter((child) => 'outside_household' !== child.situation),
        sortBy((child) => ('dependant' === child.situation ? 0 : 1)),
    ])(rip.children);

    // Widower bonus part for a dependent child or person
    if ((dependentChildren.length > 0 || rip.dependents.length > 0) && 'widower' === rip.familySituation) {
        ceil += partCases.widower;
    }

    // Plafond lié à la demi-part supplémentaire pour les Célibataires, Divorcés ou Veuf(ve) qui :
    // - ont un ou plusieurs enfants majeurs ou mineurs faisant l’objet d’une imposition distincte;
    // - ont eu a charge ces enfants pendant au moins cinq années au cours desquelles ils vivaient seul.
    if (includes('singleHavingRaisedChildMoreThan5Years', rip.fiscalParticularCases)) {
        ceil += partCases.singleHavingRaisedChildMoreThan5Years;
    }

    // Children ceils
    ceil += flow([
        (arr) => arr.map((child, index) => {
            let amount = index < 2 ? parts.defaultHalf : 2 * parts.defaultHalf;

            if (child.disabled) {
                amount += partCases.various;
            }

            if ('alternate_custody' === child.situation) {
                amount /= 2;
            }

            return amount;
        }),
        sum,
    ])(dependentChildren);

    // Other dependents
    ceil += flow([
        filter((dependent) => 'outside_household' !== dependent.situation),
        map((dependent) => {
            const parsed = parseFloat(dependent.parts);

            if (Number.isNaN(parsed)) {
                return 0;
            }
            // Pour une personne à charge, on applique les plafonds par défaut :
            // Par quart de part supplémentaire :
            let dependentCeil = ((parsed % 0.5) / 0.25) * parts.defaultQuarter;
            // Par demi part supplémentaire :
            dependentCeil += ((parsed - (parsed % 0.5)) / 0.5) * parts.defaultHalf;

            return dependentCeil;
        }),
        sum,
    ])(rip.dependents);

    // Single parent
    if (['single', 'divorced'].includes(rip.familySituation) && includes('singleParent', rip.fiscalParticularCases)) {
        ceil += flow([
            map((child) => ('alternate_custody' === child.situation ? 0.25 : 0.5)),
            sum,
            clamp(0, 0.5),
            (res) => res * partCases.singleParent,
        ])(dependentChildren);
    }

    return ceil;
};

const computeIncomeTax = (
    rip,
    {
        credits,
        netIncomes,
        referenceIncomes,
        parts,
        halfPartsCount,
        quarterPartsCount,
        cappedReductions,
        uncappedReductions,
    },
    settings,
) => {
    const isCouple = ['married', 'partner'].includes(rip.familySituation);

    const coreParts = isCouple ? 2 : 1;
    const familyRatio = netIncomes / parts;
    const coreRatio = netIncomes / coreParts;

    const taxRates = get('summary.incomeTax.slices', settings);

    const { tax: coreTaxPart, tmi: coreTmi } = getTaxFromSlices(coreRatio, taxRates);
    const coreTax = coreParts * coreTaxPart;
    const lowerBound = coreTax - getCeil(rip, settings);

    const { tax: unceiledTaxPart, tmi: unceiledTmi } = getTaxFromSlices(familyRatio, taxRates);
    const unceiledTax = parts * unceiledTaxPart;

    // Impot brut avant application des réductions et crédits d'impôts.
    // et des mécanismes de réduction d'impôt pour les impositions inférieures à un plafond.
    const ceiledTax = Math.max(unceiledTax, lowerBound);

    let incomeTax = ceiledTax;
    // Calcul de l'impôt pour les foyers modestes.
    incomeTax = computeLowIncomesTax({ rip, referenceIncomes, incomeTax, halfPartsCount, quarterPartsCount });
    // Calcul de l'impôt pour les hauts revenus.
    incomeTax = computeHighIncomesTax({ rip, referenceIncomes, incomeTax }, settings);

    // Application des réductions et crédits d'impôts.
    // Le total réductions + crédits est plafonné.
    // Tant qu'il y a de l'impôt, les réductions passent avant les crédits.
    const ceiledReduction = Math.min(maxDecrease, cappedReductions);
    const effectiveCeiledReduction = clamp(0, incomeTax)(ceiledReduction);

    const effectiveReduction = clamp(
        0,
        incomeTax,
    )(effectiveCeiledReduction + uncappedReductions);

    const effectiveCredit = Math.min(credits, maxDecrease - effectiveCeiledReduction);

    incomeTax -= effectiveReduction + effectiveCredit;

    return {
        incomeRawTax: ceiledTax,
        incomeTax,
        tmi: (unceiledTax < lowerBound) ? coreTmi : unceiledTmi,
    };
};

export default computeIncomeTax;
