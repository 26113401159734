import { GET, PATCH } from '../utils/httpMethods';

const toValue = (value) => {
    if (value instanceof Date) {
        return value.toISOString();
    }

    return value;
};

class API {
    constructor(path) {
        this.path = path;
    }

    find(criteria = null) {
        let url = this.path;

        if (null !== criteria) {
            url += `?${Object.entries(criteria).map(([key, value]) => `${key}=${toValue(value)}`).join('&')}`;
        }

        return GET(url)
            .then((response) => response.json())
            .then(({ items }) => items);
    }

    get(id) {
        return GET(`${this.path}${id}`).then((response) => response.json());
    }

    update(id, change) {
        return PATCH(`${this.path}${id}`, { body: change })
            .then((response) => response.json());
    }
}

export default API;
