import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../common/components/Icon';

import './ErrorMessage.css';

const ErrorMessage = ({ children, withoutIcon }) => (
    <div className="error-message container">
        {withoutIcon || <Icon icon="info-circle" />}
        {children}
    </div>
);

ErrorMessage.propTypes = {
    children: PropTypes.node.isRequired,
    withoutIcon: PropTypes.bool,
};

ErrorMessage.defaultProps = {
    withoutIcon: false,
};

export default ErrorMessage;
