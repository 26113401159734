import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash/fp';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { GridLayout } from 'components/form/layout';
import Icon from '../../common/components/Icon';

import './Header.scss';

function computeCodeToInsert(obj) {
    const afficheVariable = (objet) => {
        const verifieSiCheminSeFiniParUnNombre = new RegExp('[0-9]');

        if (verifieSiCheminSeFiniParUnNombre.test(objet.path)) {
            const constructionCheminSansNombre = obj.path.split('.');

            constructionCheminSansNombre.pop();
            const nouveauCheminSansLeNombre = constructionCheminSansNombre.join('.');

            const cle = objet.path.split('.').pop();

            return `+++IMAGE cocher(${nouveauCheminSansLeNombre}['${cle}'])+++`;
        }

        return `+++IMAGE cocher(${obj.path})+++`;
    };

    switch (obj.type) {
        case 'IMAGE':
            return isEmpty(obj) ? '' : afficheVariable(obj);
        case 'INS':
            return isEmpty(obj) ? '' : `+++INS ${obj.path}+++`;
        case 'LOOP': {
            const item = obj.prefix
                .split('.')
                .pop()
                .slice(0, 3);

            return `+++FOR ${item} IN ${obj.prefix}+++
+++INS $${item}.${obj.path}+++
+++END-FOR ${item}+++`;
        }
        default:
            return '';
    }
}

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = { copySuccess: false };
        this.pathRef = React.createRef();

        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    componentDidUpdate(_, prevState) {
        if (this.state.copySuccess && this.state.copySuccess !== prevState.copySuccess) {
            setTimeout(() => {
                this.setState({ copySuccess: false });
            }, 500);
        }
    }

    copyToClipboard(e) {
        e.preventDefault();

        this.pathRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccess: true });
    }

    render() {
        const { selectedPath } = this.props;

        const btnClasses = classNames({
            'copy-button': true,
            copied: this.state.copySuccess,
        });

        return (
            <form>
                <GridLayout className=" condensed full-width gray-box">
                    <label className="" htmlFor="path">
                        <h4 className="header-title">Chemin</h4>

                        <input id="path" name="path" type="text" value={selectedPath.path} readOnly />
                        <span className="validation-feedback" />
                    </label>
                    <label className="" htmlFor="codeToInsert">
                        <h4 className="header-title">
                            Code à insérer
                            {document.queryCommandSupported('copy') && (
                                <OverlayTrigger
                                    trigger={['hover', 'click']}
                                    placement="bottom"
                                    overlay={
                                        <Popover
                                            id="copy-to-clipboard"
                                            className="form-field-info-popover popover-positioned-bottom"
                                        >
                                            Copier dans le presse-papier
                                        </Popover>
                                    }
                                >
                                    <button type="button" onClick={this.copyToClipboard} className={btnClasses}>
                                        <Icon icon="copy" />
                                    </button>
                                </OverlayTrigger>
                            )}
                        </h4>
                        <textarea
                            rows={3}
                            readOnly
                            className="code-to-copy"
                            id="codeToInsert"
                            ref={this.pathRef}
                            name="codeToInsert"
                            value={computeCodeToInsert(selectedPath)}
                        />
                        <span className="validation-feedback" />
                    </label>
                </GridLayout>
            </form>
        );
    }
}

Header.propTypes = {
    selectedPath: PropTypes.shape({
        path: PropTypes.string,
    }).isRequired,
};

export default Header;
