import { flowRight, get, isFunction, pickBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { withProps, withState } from 'recompose';
import { formValues } from 'redux-form';
import { loadDataList } from 'actions';
import { Layout } from 'components/layouts';
import dataCreationForm from '../../dataCreationForm';
import dataProvider from '../../dataProvider';
import { VerticalLayout } from '../../form/layout';
import FormButtons from '../../FormButtons';
import types from '../types';
import ProductChoice from './ProductChoice';
import formValidate from './formValidate';
import { withSession } from '../../sessions';
import lifeInsurance from '../lifeInsurance';
import { GET } from '../../../utils/httpMethods';

const CreationInner = ({
    availableTypes,
    handleSubmit,
    product,
    rip,
    contractType,
    setContractType,
    beneficiary,
    setBeneficiaryType,
}) => {
    const FormComponent = product ? get([product, 'formComponent'], availableTypes) : null;
    const contractTypeState = { contractType, setContractType };
    const beneficiaryTypeState = { beneficiary, setBeneficiaryType };
    const signsState = get(beneficiary, rip.clientAndPartnerSignatureDate);

    return (
        <Layout className="contract-form creation-form" hasFooter>
            <form onSubmit={handleSubmit} id="main-form">
                <div>
                    <div className="contract-form-inner">
                        <div className="container">
                            <VerticalLayout>
                                <ProductChoice
                                    name="product"
                                    title="Séléctionner le type de souscription"
                                    types={availableTypes}
                                />
                                {FormComponent ? createElement(FormComponent, { rip, contractTypeState, beneficiaryTypeState }) : null}
                            </VerticalLayout>
                        </div>
                    </div>
                    <FormButtons back={`/rip/${rip.id}/contracts/`} list={`/rip/${rip.id}/contracts/`} disabled={!signsState} />
                </div>
            </form>
        </Layout>
    );
};

CreationInner.propTypes = {
    availableTypes: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    product: PropTypes.string,
    rip: PropTypes.shape({
        id: PropTypes.string,
        clientAndPartnerSignatureDate: PropTypes.shape({}),
    }).isRequired,
    contractType: PropTypes.string,
    setContractType: PropTypes.func,
    beneficiary: PropTypes.string,
    setBeneficiaryType: PropTypes.func,
};

CreationInner.defaultProps = {
    product: null,

};

const Index = flowRight([
    withSession,
    withState('hasValidOriasNumber', 'setHasValidOriasNumber', null),
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
        wait: true,
    }),
    withProps(({ rip, session, setHasValidOriasNumber, hasValidOriasNumber }) => {
        useEffect(() => {
            (async () => {
                let validOriasNumberResponse = await GET(`/api/users/${session.id}/validOriasNumber`);
                validOriasNumberResponse = await validOriasNumberResponse.json();
                setHasValidOriasNumber(validOriasNumberResponse);
            })();
        }, []);

        const { instanceTheseis } = session;
        const subscriptionsAvailable = hasValidOriasNumber && instanceTheseis ? types : { lifeInsurance };

        return ({ availableTypes: pickBy(({ isAvailable }) => isAvailable(rip), subscriptionsAvailable) });
    }),
    withState('contractType', 'setContractType', null),
    withState('beneficiary', 'setBeneficiaryType', null),
    connect(null, {
        loadSimulation: loadDataList,
    }),
    dataCreationForm({
        form: 'contact-creation',
        onSubmitSuccess: ({ id }, dispatch, { navigate, rip, contractType }) =>
            navigate(`/rip/${rip.id}/contracts/${id}/verifications/${contractType}`),
        type: 'contract',
        onSubmitData: ({ product, ...values }, { availableTypes, ...props }) => {
            const productValues = get([product, 'initialValues'], availableTypes);

            return ({
                ...values,
                ...(isFunction(productValues) ? productValues(values, props) : productValues),
            });
        },
        validate: formValidate,
    }),
    formValues({ product: 'product' }),
])(CreationInner);

export default Index;
