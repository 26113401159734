import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getDatabase from 'data';
import handleErrorsMiddleware from 'middlewares/handleErrors';
import reducer from 'reducer';

import Routing from './routes/Routing';
import { DatabaseProvider } from './database';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Bootstrap = ({ session, navigateRouter }) => {
    const { navigate } = navigateRouter;

    // create the database instance
    const database = useMemo(() => session && getDatabase(session.id), []);

    // create the redux store instance
    const store = useMemo(() => createStore(reducer, composeEnhancers(applyMiddleware(
        handleErrorsMiddleware,
        thunkMiddleware.withExtraArgument({ navigate, database }),
    ))), []);

    // on mount sync with the user data
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (session && session.id) {
            session.bindStore(store);
            // then synchronize
            session.sync();

            return () => {
                // unbind the store
                session.bindStore(null);
            };
        }
        // first bind the store
    }, []);

    return (
        <Provider store={store}>
            <DatabaseProvider value={database}>
                <Routing />
            </DatabaseProvider>
        </Provider>
    );
};

Bootstrap.propTypes = {
    navigateRouter: PropTypes.shape({
        navigate: PropTypes.func,
    }).isRequired,
    session: PropTypes.shape({
        id: PropTypes.string.isRequired,
        update: PropTypes.func.isRequired,
        bindStore: PropTypes.func,
        sync: PropTypes.func,
        syncTokensShoyo: PropTypes.func,
        refreshTokenShoyo: PropTypes.func,
    }),
};

Bootstrap.defaultProps = {
    session: null,
};

export default Bootstrap;
