import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField } from 'components/form/field';

const RentalPropertyWeight = ({ name }) => (
    <Fragment>
        <Title>Pondération des revenus fonciers</Title>
        <GridLayout className="triple condensed full-width">
            <PercentageFloatField
                name={name}
                title="Micro-foncier"
            />
        </GridLayout>
    </Fragment>
);

RentalPropertyWeight.propTypes = {
    name: PropTypes.string.isRequired,
};

export default RentalPropertyWeight;
