// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.white-btn {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    vertical-align: text-bottom;
    border: 1px solid rgb(70, 70, 70);
    border-radius: 2px;
    opacity: 0.8;
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
    left: 10px;
    margin: 0 8px 6px 0;
}

button.white-btn p {
    padding-top: 10px;
    height: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,iCAAiC;IACjC,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":["button.white-btn {\n    font-size: 13px;\n    font-weight: 300;\n    text-align: center;\n    vertical-align: text-bottom;\n    border: 1px solid rgb(70, 70, 70);\n    border-radius: 2px;\n    opacity: 0.8;\n    background-color: white;\n    padding-left: 8px;\n    padding-right: 8px;\n    left: 10px;\n    margin: 0 8px 6px 0;\n}\n\nbutton.white-btn p {\n    padding-top: 10px;\n    height: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
