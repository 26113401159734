class Storage {
    constructor(db, name) {
        this.db = db;
        this.name = name;
        this.items = db[name];
        this.changes = db[`${name}Changes`];
    }

    getLastSync() {
        return this.db.syncs.get(this.name)
            .then((sync) => (sync ? sync.date : null));
    }

    setLastSync(date) {
        return this.db.syncs.put({
            id: this.name,
            date,
        });
    }
}

export default Storage;
