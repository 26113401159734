import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { GridLayout } from 'components/form/layout';
import { DateField, IntegerField, PercentageField } from 'components/form/field';
import {
    checkRequiredField,
    checkIsDate,
    checkRequiredIntegerField,
    checkDateDependencies,
    checkIsSimulationDuration,
} from 'common/formValidation/lib';

export const validators = [
    checkRequiredField('simulationStart'),
    checkIsDate('simulationStart'),
    checkRequiredIntegerField('initialCashFlow'),
    checkIsSimulationDuration('simulationDuration'),
    checkRequiredField('rentsIndexation'),
    checkDateDependencies(
        'simulationStart',
        'propertyActDate',
        'La date de début de la simulation doit être postérieure à la date de l\'acte',
    ),
];

const CustomisationFormInner = ({ product }) => (
    <GridLayout className="condensed">
        <DateField title="Début de la simulation" name="simulationStart" required />
        <IntegerField title="Trésorerie initiale" name="initialCashFlow" required />
        <IntegerField title="Durée de la simulation (années)" name="simulationDuration" min={1} max={60} required />
        <PercentageField title="Indexation des charges/loyers" name="rentsIndexation" max={3} required />
        {('pinel' === product || 'pinelDenormandie' === product) ? (
            <Fragment>
                <IntegerField
                    title="Durée avant réindexation de l'immobilier (années)"
                    name="durationBeforeRealEstateIndexation"
                />
                <PercentageField title="Indexation de l'immobilier" name="realEstateIndexation" max={2.4} />
            </Fragment>
        ) : null}
    </GridLayout>
);

CustomisationFormInner.propTypes = {
    product: PropTypes.string.isRequired,
};

const CustomisationForm = formValues({ product: 'product' })(CustomisationFormInner);

export default CustomisationForm;
