import PropTypes from 'prop-types';
import React from 'react';
import { compose, pure } from 'recompose';
import { Table, Head, Header, Body, withActiveItem } from 'components/list';
import withSpinner from 'components/withSpinner';
import withTutorial from 'components/withTutorial';
import ListItem from './ListItem';
import NoRipMessage from './NoRipMessage';
import { withSession } from '../../../../components/sessions';

const DocumentCifList = ({ items, allMode, session }) => (
    <Table>
        <Head>
            <th />
            <Header sortProperty="lastName" text="Nom" />
            {'cgp' === session.status ? (<th />) : (<Header sortProperty="user.lastName" text="Conseiller" />)}
            {'cgp' === session.status ? (<th />) : (<Header sortProperty="user.entity.name" text="Réseau" />)}
            {allMode && 'cgp' !== session.status && (<Header sortProperty="user.validators" text="Validateur(s)" />)}
            {'cgp' === session.status ? (<th />) : (<Header sortProperty="updatedAt" text="Dernière modification" />)}
            <Header sortProperty="approval" text="Statut" />
        </Head>
        <Body>
            {items.map((item) => <ListItem key={item.id} item={item} allMode={allMode} />)}
        </Body>
    </Table>
);

export const itemShape = {
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    approval: PropTypes.string,
};

DocumentCifList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
    allMode: PropTypes.bool,
    session: PropTypes.shape({
        status: PropTypes.string,
    }).isRequired,
};

DocumentCifList.defaultProps = {
    allMode: false,
};

export default compose(
    withSession,
    withSpinner,
    withTutorial(NoRipMessage),
    withActiveItem,
    pure,
)(DocumentCifList);
