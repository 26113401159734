import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalLayout } from '../../form/layout';
import { DateField, IntegerField } from '../../form/field';
import SelectSalaryField from '../field/SelectSalary';

const BonusEvent = ({ name }) => (
    <HorizontalLayout>
        <SelectSalaryField name={`${name}.income`} title="Revenu concerné" required />
        <DateField name={`${name}.date`} title="Date prévue pour la prime" required />
        <IntegerField name={`${name}.amount`} title="Montant net de la prime" required />
    </HorizontalLayout>
);

BonusEvent.propTypes = {
    name: PropTypes.string.isRequired,
};

export default BonusEvent;
