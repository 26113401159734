import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import colors from 'components/colors';
import Title from 'components/Title';
import { formatAmount } from 'common/utils/format';

const TooltipContent = ({ active, payload, label }) => (active ? (
    <div className="custom-tooltip">
        <p className="recharts-tooltip-label">{label}</p>
        <p style={{ color: payload[0].fill }}>{formatAmount(payload[0].value)}</p>
    </div>
) : null);

TooltipContent.propTypes = {
    active: PropTypes.bool.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({
        fill: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    label: PropTypes.string.isRequired,
};

const TaxHousehold = ({ summary, afterwardSummary }) => (
    <Fragment>
        <Title className="margin-top"><b>Avis d&apos;imp&ocirc;ts</b></Title>
        <ResponsiveContainer aspect={1.5} width="100%" maxHeight={200}>
            <BarChart
                data={[
                    { name: 'Avant opération', before: summary.incomeTax },
                    { name: 'Après opération', after: afterwardSummary.incomeTax },
                ]}
                layout="vertical"
                margin={{ right: 35, left: 30 }}
            >
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" />
                <Tooltip content={TooltipContent} />
                <ReferenceLine x={0} stroke="#000" />
                <Bar stackId={1} dataKey="before" fill={colors[0]} />
                <Bar stackId={1} dataKey="after" fill={colors[1]} />
            </BarChart>
        </ResponsiveContainer>
    </Fragment>
);

TaxHousehold.propTypes = {
    summary: PropTypes.shape({
        incomeTax: PropTypes.number,
    }).isRequired,
    afterwardSummary: PropTypes.shape({
        incomeTax: PropTypes.number,
    }).isRequired,
};

export default TaxHousehold;
