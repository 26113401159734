export const computeCollateralInitialContribution = (fundingAmount, monthlyRate, fundingDuration, collateralMonthlyContribution) =>
    ((fundingAmount + (collateralMonthlyContribution / monthlyRate)) / ((1 + monthlyRate) ** fundingDuration))
    -
    (collateralMonthlyContribution / monthlyRate);

export const computeCollateralMonthlyContribution = (fundingAmount, monthlyRate, fundingDuration, collateralInitialContribution) => Math.max(
    0,
    (((fundingAmount / ((1 + monthlyRate) ** fundingDuration)) - collateralInitialContribution)
    /
    ((1 / monthlyRate) - (1 / (monthlyRate * ((1 + monthlyRate) ** fundingDuration))))),
);
