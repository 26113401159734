import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { branch, renderNothing, mapProps } from 'recompose';
import { formatAmount } from 'common/utils/format';
import { fundingTypes } from 'common/choices';
import computeInvestment from 'common/model/simulation/scpi/computeInvestment';
import FormGroup from 'components/form/Group';
import { GridLayout } from 'components/form/layout';
import { ChoiceField, PercentageField, IntegerField, DisabledField } from 'components/form/field';
import Title from 'components/Title';
import LifeInsurance from './LifeInsurance';

const FundingInner = ({ type, fundingAmount }) => (
    <Fragment>
        <FormGroup>
            <Title><b>Financement</b></Title>
            <GridLayout className="triple condensed">
                <IntegerField
                    name="data.funding.personalContribution"
                    title="Montant de l'apport personnel"
                    min={0}
                />
                <IntegerField
                    name="data.funding.brokerageFees"
                    title="Frais d'ingénierie"
                    min={0}
                />
                <DisabledField
                    value={formatAmount(fundingAmount)}
                    title="Montant du prêt"
                    name="data.funding.fundingAmount"
                />
                <IntegerField
                    name="data.funding.duration"
                    title="Durée (mois)"
                    min={10}
                    required
                />
                <ChoiceField
                    choices={fundingTypes}
                    name="data.funding.type"
                    title="Type"
                    double
                    required
                />
                <PercentageField
                    name="data.funding.rate"
                    title="TAEG"
                    required
                />
            </GridLayout>
        </FormGroup>
        {'in-fine' === type ? <LifeInsurance capital={fundingAmount} /> : null}
    </Fragment>
);

FundingInner.propTypes = {
    type: PropTypes.string,
    fundingAmount: PropTypes.number.isRequired,
};

FundingInner.defaultProps = {
    type: null,
};

const Funding = flowRight([
    formValues({
        needFunding: 'data.needFunding',
    }),
    branch(({ needFunding }) => !needFunding, renderNothing),
    formValues({
        type: 'data.funding.type',
        personalContribution: 'data.funding.personalContribution',
        brokerageFees: 'data.funding.brokerageFees',
        products: 'data.products',
    }),
    mapProps(({ personalContribution, brokerageFees, products, type }) => ({
        fundingAmount: computeInvestment(products) + (brokerageFees - personalContribution),
        type,
    })),
])(FundingInner);

export default Funding;
