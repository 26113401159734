import { flowRight } from 'lodash/fp';
import { withProps } from 'recompose';
import { formValues } from 'redux-form';
import Input from './Input';
import moveDecimal from '../../../common/utils/moveDecimal';

const parse = (value) => (value ? moveDecimal(value, -2) : null);

class FloatValue extends Number {
    constructor(value) {
        super(parseFloat(value));
        this.originalValue = (value && value.originalValue) || value;
    }
}

const format = (value) => {
    if (value && value.originalValue) {
        // return the original value
        return value.originalValue;
    }

    // transform the value
    return null !== value ? moveDecimal(value, 2) : '';
};

const getValue = (value) => {
    if (value && value.originalValue) {
        // return the original value
        return value.originalValue;
    }

    return value;
};

const normalizeToFloat = (value) => {
    if (value) {
        return new FloatValue(value);
    }

    return value;
};

const Percentage = flowRight([
    formValues(({ name }) => ({ value: name })),
    withProps(({ value }) => ({
        parse,
        format,
        step: 'any',
        type: 'number',
        value: getValue(value),
    })),
])(Input);

export const PercentageFloat = withProps({ normalize: normalizeToFloat })(Percentage);

export default Percentage;
