import { merge } from 'lodash/fp';

const handleErrors = (response) => {
    if (!response.ok) {
        const error = Error(response.statusText);
        error.status = response.status;
        error.response = response;

        throw error;
    }

    return response;
};

const headers = {
    'Content-type': 'application/json',
};

const factory = (method) => {
    const defaultOptions = {
        credentials: 'include',
        method,
    };

    return (url, { body, ...customOptions } = {}) => {
        const options = { ...defaultOptions };

        if (body) {
            if (body instanceof FormData) {
                options.body = body;
            } else {
                options.body = JSON.stringify(body);
                options.headers = headers;
            }
        }

        return fetch(url, merge(options, customOptions)).then(handleErrors);
    };
};

export const GET = factory('GET');
export const PATCH = factory('PATCH');
export const POST = factory('POST');
export const DELETE = factory('DELETE');
