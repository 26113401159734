const initialValues = {
    data: {
        duration: 10,
        funding: {
            entryFees: '0.05',
            monthlyFunding: {
                fees: '0.05',
            },
        },
    },
};

export default initialValues;
