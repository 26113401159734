import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import InvestmentType from './InvestmentType';

const Investment = ({ name }) => (
    <Fragment>
        <Title>Placements</Title>
        <GridLayout className="triple condensed full-width">
            <InvestmentType name={`${name}.fcpi`} type="fcpi" />
            <InvestmentType name={`${name}.fip`} type="fip" />
        </GridLayout>
    </Fragment>
);

Investment.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Investment;
