import { omit } from 'lodash/fp';

const pdfEditions = (state = { editions: {}, signatures: {} }, { type, ...payload }) => {
    switch (type) {
        case 'SET_PDF_EDITIONS':
            return { ...state, ...payload.pdfEditions };
        case 'ADD_PDF_TEXT_EDITION':
            return {
                ...state,
                activeId: payload.index,
                activeTool: 'text',
                editions: {
                    ...state.editions,
                    [payload.index]: {
                        createdAt: payload.createdAt,
                        type: 'text',
                        position: payload.position,
                        temporary: payload.temporary,
                        page: payload.page,
                    },
                },
            };
        case 'SET_PDF_TEXT_EDITION':
            return {
                ...state,
                editions: {
                    ...state.editions,
                    [payload.index]: {
                        ...(state.editions[payload.index]),
                        type: 'text',
                        position: payload.position,
                        temporary: payload.temporary,
                    },
                },
            };
        case 'TOGGLE_PDF_EDITION':
            return {
                ...state,
                activeId: null,
                activeTool: 'none',
                editions: {
                    ...state.editions,
                    [payload.index]: {
                        ...state.editions[payload.index],
                    },
                },
            };
        case 'SET_PDF_EDITION_CONTENT':
            return {
                ...state,
                editions: {
                    ...state.editions,
                    [payload.index]: {
                        ...state.editions[payload.index],
                        content: payload.content,
                    },
                },
            };
        case 'DELETE_PDF_EDITION':
            return {
                ...state,
                activeTool: 'none',
                activeId: (state.activeId === payload.index ? null : state.activeId),
                editions: omit([payload.index], state.editions),
            };
        case 'DELETE_PDF_SIGNATURE':
            return {
                ...state,
                activeTool: 'none',
                activeId: (state.activeId === payload.signatory ? null : state.activeId),
                signatures: omit([payload.signatory], state.signatures),
            };
        case 'SET_PDF_EDITION_TOOL':
            return {
                ...state,
                activeTool: payload.tool,
            };
        case 'SET_PDF_EDITION_SIGNATURE_TOOL':
            return {
                ...state,
                activeId: payload.signatory,
                activeTool: 'signature',
            };
        case 'ADD_PDF_SIGNATURE':
            return {
                ...state,
                activeId: null,
                activeTool: 'none',
                signatures: {
                    ...state.signatures,
                    [payload.signatory]: {
                        createdAt: payload.createdAt,
                        type: 'signature',
                        position: payload.position,
                        page: payload.page,
                    },
                },
            };
        default:
            return state;
    }
};

export default pdfEditions;
