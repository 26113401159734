import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import Divider from 'components/Divider';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import FormButtons from 'components/FormButtons';
import simulationLoader from 'components/simulation/simulationLoader';
import Fees from 'components/simulation/types/common/charges/FeesForm';
import PropertyTax from 'components/simulation/types/common/charges/PropertyTaxForm';
import Form from '../Form';
import SimulationHeader from '../Header';
import dataForm from '../dataForm';

const ChargesInner = ({ handleSubmit, params: { id, simulationId }, initialValues: { needFunding }, simulation }) => (
    <Form handleSubmit={handleSubmit} needFunding={needFunding}>
        <div className="simulation-form-inner">
            <div className="container">
                <SimulationHeader />
                <FormGroup>
                    <Title><b>Frais</b></Title>
                    <Fees />
                </FormGroup>
                <Divider />
                <FormGroup>
                    <Title><b>Taxe Foncière</b></Title>
                    <PropertyTax simulation={simulation} />
                </FormGroup>
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/${needFunding ? 'financement' : 'bien'}`}
            list={`/rip/${id}/simulations/`}
        />
    </Form>
);

ChargesInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    initialValues: PropTypes.shape({
        needFunding: PropTypes.bool,
    }).isRequired,
    simulation: PropTypes.shape({}),
};

const Charges = flowRight([
    simulationLoader,
    dataForm({
        form: 'simulation-charges',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => navigate(`/rip/${id}/simulations/${simulationId}/simulation`),
        type: 'simulation',
    }),
])(ChargesInner);

export default Charges;
