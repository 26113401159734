import { get, set } from 'lodash/fp';

const errorString = 'Une durée minimum est requise.';

const checkIsSimulationDuration = (field) => (errors, values) => {
    const value = get(field, values);

    return (value >= 1)

        ? errors
        : set(field, errorString, errors);
};

export default checkIsSimulationDuration;
