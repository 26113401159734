import { get, map, min } from 'lodash/fp';
import computeInvestorProfile from 'common/model/rip/investorProfile';
import getSettings from 'common/utils/settings';

const initialValues = ({ beneficiary }, { rip: { investorProfiles } }) => {
    const lifeInsuranceTypes = getSettings('simulation.lifeInsurance.types');
    const defaultFees = min(map(({ fees: { max } }) => max, lifeInsuranceTypes));

    return {
        data: {
            taxMode: 'pfu',
            profile: computeInvestorProfile(get(beneficiary, investorProfiles)),
            duration: 5,
            initialFunding: 0,
            entryFees: defaultFees,
            scheduledPayments: { fees: defaultFees },
        },
    };
};

export default initialValues;
