import { flowRight, isEmpty } from 'lodash/fp';
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSimulationSummary } from 'actions';
import { AmountItem, PercentageItem } from 'common/components/summary';
import { formatAmount } from 'common/utils/format';
import Title from 'components/Title';

class Result extends Component {
    async componentDidMount() {
        await this.props.getSimulationSummary(this.props.id);
    }

    processResults() {
        const { data: { duration }, results } = this.props;

        if (isEmpty(results)) {
            return null;
        }

        const yearlyDetails = _.get(results, 'yearlyDetails', []);
        const balance = _.get(results, 'balance', {});
        const { total: summary, recurrent: recurrentBalance, internalReturnRate } = balance;
        const monthlyIncome = Math.abs(recurrentBalance / (duration * 12));

        return {
            yearlyDetails,
            duration,
            summary,
            monthlyIncome,
            internalReturnRate,
            recurrentBalance,
        };
    }

    render() {
        let results = null;

        try {
            results = this.processResults();
        } catch (error) {
            console.error(error);
        }

        if (null === results) {
            return null;
        }

        return (
            <Fragment>
                <Title><b>Résultats de votre investissement</b></Title>
                <p>
                    Sur une durée de {results.duration} ans, et supposant une vente des parts de SCPI au terme,
                    le bilan financier de votre opération est le suivant :
                </p>
                <div className="summary-body col3">
                    <dl>
                        <AmountItem
                            title="Enrichissement"
                            value={results.summary}
                        />
                        <AmountItem
                            title={0 < results.recurrentBalance
                                ? 'Revenu net mensualisé'
                                : 'Effort d\'épargne net mensualisé'}
                            value={results.monthlyIncome}
                        />
                        <PercentageItem
                            title="Taux de Rendement interne"
                            value={results.internalReturnRate}
                        />
                    </dl>
                </div>
                <Title><b>Résultats détaillés de votre investissement</b></Title>
                <table className="table lifeInsurance-hypothesis-table table-hover">
                    <thead>
                        <tr>
                            <th>Année</th>
                            <th>Loyers nets (1)</th>
                            <th>Échéances de prêt</th>
                            <th>Prélèvements sociaux</th>
                            <th>Impôt</th>
                            <th>Autres</th>
                            <th>Flux de trésorerie</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.get(results, 'yearlyDetails', []).map(({
                            year,
                            periodRent,
                            periodLoanPayments,
                            csg,
                            tax,
                            other,
                            balance,
                        }) => (
                            <tr key={year.id}>
                                <td>{year}</td>
                                <td>{formatAmount(periodRent)}</td>
                                <td>{formatAmount(periodLoanPayments)}</td>
                                <td>{formatAmount(csg)}</td>
                                <td>{formatAmount(tax)}</td>
                                <td>{formatAmount(other)}</td>
                                <td>{formatAmount(balance)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        );
    }
}

Result.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
        duration: PropTypes.number,
    }).isRequired,
    results: PropTypes.shape({}).isRequired,
    getSimulationSummary: PropTypes.func.isRequired,
};

export default flowRight([
    connect((state) => ({ results: state.simulationSummary }), { getSimulationSummary }),
])(Result);
