import { flatten, flow, map, round } from 'lodash/fp';
import useSettings from 'components/sessions/useSettings';

const loanAmounts = [
    [100000, 106500],
    [120000, 127500],
    [145000, 153750],
    [160000, 169500],
    [180000, 190500],
    [200000, 211500],
    [220000, 232500],
    [240000, 253500],
    [260000, 274500],
    [280000, 295500],
    [300000, 316500],
];

const loanDurations = [20, 25]; // in years

const adiRate = 0.0035;

const computeTotalMonthlyPayment = (amountWithFees, annualRate, duration) => {
    const durationInMonths = (duration - 1) * 12; // we withdraw one because the first year is free of payments;
    const totalAmount = amountWithFees * (1 + annualRate); // amount with fees and interests
    const monthlyRate = annualRate / 12;
    const monthlyInsurance = (amountWithFees * (adiRate / 12));
    const monthlyPayment = (totalAmount * monthlyRate) / (1 - (1 / ((1 + monthlyRate) ** (durationInMonths))));

    return monthlyInsurance + monthlyPayment;
};

export const lots = () => {
    const { loanRate20, loanRate25 } = useSettings('calculator.credit');

    return flow([
        map(([amount, amountWithFees]) => loanDurations.map((duration) => [amount, amountWithFees, duration])),
        flatten,
        map(([amount, amountWithFees, duration]) => ({
            amount,
            duration,
            amountWithFees, //  amountWithFees: amount * 1.06, // 6% of fees
            monthlyPayment: round(computeTotalMonthlyPayment(
                amountWithFees,
                20 === duration ? loanRate20 : loanRate25,
                duration,
            )),
            rent: round(amount * (0.035 / 12)), // the 3.5% rate is an estimation and has to be monthly

        })),
    ])(loanAmounts);
};

export const getMaxAmountLoanable = ({
    rawRentalIncomes,
    rawProfessionalIncomes,
    outstandingCapital,
}) =>
    ((rawProfessionalIncomes + (0.9 * rawRentalIncomes)) * 7) - outstandingCapital;
