export const isCouple = (familySituation) => ['married', 'partner'].includes(familySituation);

export const shouldPartnerSign = (rip) => {
    // ? Premier impact pour les PDF complets en dualRip
    // ? à tester avec d'autres appel à cette fonction.
    // if (rip.originalRip) {
    //     return shouldPartnerSign(rip.originalRip);
    // }

    if (['married', 'partner'].includes(rip.familySituation)
        || ('cohabitation' === rip.familySituation && rip.dualRip)) {
        return true;
    }

    return false;
};

const hasPartner = (familySituation) => (['married', 'partner', 'cohabitation'].includes(familySituation));

export default hasPartner;
