import PropTypes from 'prop-types';
import React from 'react';
import { InputField, IntegerField, YesNoField } from 'components/form/field';
import { HorizontalLayout } from 'components/form/layout';
// we load from uncappedReductions cause both function are the same
import { computeOther as computeAmountOther } from 'common/model/summary/cappedReductions';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const OtherReduction = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <InputField name={`${name}.description`} title="Précisez" required type="text" />
        <IntegerField name={`${name}.amount`} title="Montant annuel" required />
        <YesNoField name={`${name}.capped`} title="Soumis au plafonnement des 10 000€ ?" required />
        <Recurrent name={name} />
        <DisabledTaxField name={name} computeAmount={computeAmountOther} type="reduction" />
    </HorizontalLayout>
);

OtherReduction.propTypes = {
    name: PropTypes.string.isRequired,
};

export default OtherReduction;
