import getWizardStep from 'utils/getWizardStep';
import getWizard from './wizardData';

const onSubmitRedirect = (
    result,
    dispatch,
    {
        location,
        params,
        navigate,
        ...props
    },
) => {
    const { router: { params: { contractType } } } = props;
    const wizard = getWizard(contractType);
    const baseUrl = wizard.prefixTemplate(params);
    const nextStep = getWizardStep({ wizard, url: location.pathname });

    navigate(`${baseUrl}/${nextStep}`);
};

export default onSubmitRedirect;
