const formatPercentage = (value, precision = 2) => {
    const parsedValue = parseFloat(value);
    if (Number.isNaN(parsedValue)) {
        return 'NC';
    }

    return new Intl.NumberFormat('fr-FR', {
        style: 'percent',
        maximumFractionDigits: precision,
    }).format(parsedValue);
};

export default formatPercentage;
