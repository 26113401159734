const getTaxFromSlices = (income, rates) => {
    const res = {
        tax: 0,
        tmi: 0,
    };

    const taxRateLength = rates.length;
    for (let i = 0; i < taxRateLength; i++) {
        const item = rates[i];
        if (income < item.min) {
            return res;
        }

        const lastItem = (i === (taxRateLength - 1));
        const upperBound = lastItem ? +Infinity : rates[i + 1].min;
        const slice = Math.min(upperBound, income) - item.min;
        res.tax += item.rate * slice;
        res.tmi = item.rate;
    }

    return res;
};

export default getTaxFromSlices;
