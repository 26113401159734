import { merge } from 'lodash/fp';
import commonInitialValues from '../common/initialValues';

const initialValues = merge(
    commonInitialValues,
    {
        data: {
            program: {
                type: 'depreciable',
            },
            property: {
                furnitureDepreciationRate: 0.2,
                estateDepreciationRate: 0.04,
                landValue: 0,
                furnitureValue: 0,
                estateBrokerageFees: 0,
            },
            funding: {
                tvaFunder: 'loan',
            },
            fees: {
                accounting: 0,
            },
        },
        otherFees: 0,
        rentsIndexation: 0.02,
    },
);

export default initialValues;
