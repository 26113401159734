import PropTypes from 'prop-types';
import React from 'react';
import { formValues, fieldArrayFieldsPropTypes, Field } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { IntegerField } from 'components/form/field';
import Checkbox from 'components/form/Checkbox';
import DividendesSVG from '../../images/pictos/dividences';
import HomeWorkSVG from '../../images/pictos/travaux-a-domicile';
import PensionSVG from '../../images/pictos/pension';
import RadioQuestion from '../InvestorProfile/RadioQuestion';
import redirectToShoyo from '../../utils/redirectToShoyo';
import { useSession } from '../sessions';
import getRecommendationIndice from '../../utils/getIndiceRecommendation';

import './TypedCollectionButtons.scss';

const renderButton = (
    isEnveloppeProgress,
    path,
    title,
    type,
    textOnly,
    fields,
    defaultValues,
    icon,
    unique,
    hasOneOfType,
    values,
    maxElementCount,
    shouldDisplay,
    isDependentsExist,
    isCif,
    divorceDisabled,
    marriageDisabled,
    partnershipDisabled,
) => {
    if (true === isEnveloppeProgress && isCif) {
        return (
            <OverlayTrigger
                trigger={['hover', 'click']}
                overlay={<Popover className="form-field-info-popover" placement="right">Une demande de Document Conseil CIF est en cours pour ce client</Popover>}
            >
                <button
                    key={type}
                    type="button"
                    className={`${textOnly ? 'textOnly' : ''}`}
                    onClick={() =>
                        fields.push({ id: uuidv4(), type, ...defaultValues })
                    }
                    disabled
                >
                    {!textOnly ? <FontAwesomeIcon icon={icon} path={path} /> : null}
                    {'prime' === path ? <DividendesSVG /> : null}
                    {'homeWork' === path ? <HomeWorkSVG /> : null}
                    {'pension' === path ? <PensionSVG /> : null}
                    <p>{title}</p>
                </button>
            </OverlayTrigger>
        );
    }

    const isButtonDisabled = () => (
        (unique && hasOneOfType(values, type)) ||
            (maxElementCount && values.length >= maxElementCount) ||
            !shouldDisplay ||
            (!isDependentsExist && 'householdLeave' === type) ||
            ('divorce' === type && divorceDisabled) ||
            ('marriage' === type && marriageDisabled) ||
            ('partnership' === type && partnershipDisabled)
    );

    return (
        <button
            key={type}
            type="button"
            className={`${textOnly ? 'textOnly' : ''}`}
            onClick={() =>
                fields.push({ id: uuidv4(), type, ...defaultValues })
            }
            disabled={isButtonDisabled()}
        >
            {!textOnly ? <FontAwesomeIcon icon={icon} path={path} /> : null}
            {'prime' === path ? <DividendesSVG /> : null}
            {'homeWork' === path ? <HomeWorkSVG /> : null}
            {'pension' === path ? <PensionSVG /> : null}
            <p>{title}</p>
        </button>

    );
};
const hasOneOfType = (values, type) =>
    values && values.some((value) => value.type === type);

const handleRedirectToShoyo = (session, mail) => {
    redirectToShoyo(mail, session);
};

const RedirectLink = ({ rip, target }) => {
    const { personalEmail, partnerPersonalEmail, idBoxShoyo, partnerIdBoxShoyo } =
        rip;
    const session = useSession();
    if ('client' === target && idBoxShoyo) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" onClick={() => handleRedirectToShoyo(session, personalEmail)}>
                coffre-fort eDoc
            </a>
        );
    }
    if ('partner' === target && partnerIdBoxShoyo) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                href="#"
                onClick={() => handleRedirectToShoyo(session, partnerPersonalEmail)}
            >
                coffre-fort eDoc
            </a>
        );
    }

    return 'coffre-fort eDoc';
};

RedirectLink.propTypes = {
    rip: PropTypes.arrayOf(PropTypes.shape({
        personalEmail: PropTypes.string,
        partnerPersonalEmail: PropTypes.string,
        idBoxShoyo: PropTypes.string,
        partnerIdBoxShoyo: PropTypes.string,
    })),
    target: PropTypes.string,
};

const TypedCollectionButtonsInner = ({
    fields,
    types,
    values,
    textOnly,
    maxElementCount,
    isDependentsExist,
    isCif,
    rip,
    target,
    isEnveloppeProgress,
    divorceDisabled,
    marriageDisabled,
    partnershipDisabled,
}) => {
    let indice = 0;
    let indicePartner = 0;
    let isLodeomCif = false;
    let isTypeAmountSelected = false;
    let typeAmountSelected;

    if (values && values.length > 0) {
        values.map((value) => {
            if ('LODEOMCIF' === value.type) {
                isLodeomCif = true;
                if (value.typeAmount) {
                    isTypeAmountSelected = true;
                    typeAmountSelected = value.typeAmount;
                }

                return null;
            }

            return null;
        });
    }

    if (rip && rip.recommendationsCIF && isCif) {
        indice = getRecommendationIndice(isLodeomCif, values);
    }
    if (rip && rip.partner && rip.partner.recommendationsCIF && isCif) {
        indicePartner = getRecommendationIndice(isLodeomCif, values);
    }

    const enveloppeFields =
        'client' === target
            ? `recommendationsCIF[${indice}]`
            : `partner.recommendationsCIF[${indicePartner}]`;

    const titleTypeAmount =
        'reduction' === typeAmountSelected
            ? 'Montant de la réduction'
            : `Montant de l'${typeAmountSelected}`;

    return (
        <div>
            {isCif && isLodeomCif && (
                <div>
                    <RadioQuestion
                        choices={{
                            reduction: 'Montant de la réduction',
                            apport: "Montant de l'apport",
                        }}
                        name={`${enveloppeFields}.typeAmount`}
                        title="Enveloppe souhaitée*"
                        className="double"
                        required
                    />
                    {isLodeomCif && isTypeAmountSelected && (
                        <IntegerField
                            name={`${enveloppeFields}.amountCif`}
                            title={titleTypeAmount}
                            required
                        />
                    )}
                    <Field
                        title={
                            <span style={{ marginBottom: '1em' }}>
                                *Je certifie que l’ensemble des pièces nécessaires à la
                                signature d’un document de conseil CIF (avis d’imposition,
                                photocopie de la carte vitale,…) sont présents dans le{' '}
                                <RedirectLink rip={rip} target={target} /> du ou des contacts
                                sélectionnés (ou à défaut que les pièces sont transmises aux
                                acteurs du dossier via une solution sécurisée)
                            </span>
                        }
                        component={Checkbox}
                        name={`${enveloppeFields}.checkboxCifLodeom`}
                        required
                    />
                </div>
            )}

            <div className="form-typed-collection-buttons">
                {Object.entries(types).map(([
                    type,
                    {
                        defaultValues = {},
                        icon,
                        path,
                        title,
                        unique = false,
                        shouldDisplay = true,
                    },
                ]) => (
                    renderButton(
                        isEnveloppeProgress,
                        path,
                        title,
                        type,
                        textOnly,
                        fields,
                        defaultValues,
                        icon,
                        unique,
                        hasOneOfType,
                        values,
                        maxElementCount,
                        shouldDisplay,
                        isDependentsExist,
                        isCif,
                        divorceDisabled,
                        marriageDisabled,
                        partnershipDisabled,
                    )
                ))}
            </div>
        </div>
    );
};

TypedCollectionButtonsInner.propTypes = {
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
    textOnly: PropTypes.bool.isRequired,
    types: PropTypes.objectOf(PropTypes.shape()).isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
    })),
    maxElementCount: PropTypes.number,
    isDependentsExist: PropTypes.bool,
    isCif: PropTypes.bool,
    rip: PropTypes.shape({
        personalEmail: PropTypes.string,
        partnerPersonalEmail: PropTypes.string,
        idBoxShoyo: PropTypes.string,
        partnerIdBoxShoyo: PropTypes.string,
        recommendations: PropTypes.shape(),
        partner: PropTypes.shape({
            recommendations: PropTypes.shape(),
            recommendationsCIF: PropTypes.shape(),
        }),
        recommendationsCIF: PropTypes.shape(),
    }),
    target: PropTypes.string,
    isEnveloppeProgress: PropTypes.bool,
    divorceDisabled: PropTypes.bool,
    marriageDisabled: PropTypes.bool,
    partnershipDisabled: PropTypes.bool,
};

TypedCollectionButtonsInner.defaultProps = {
    values: [],
};

const TypedCollectionButtons = compose(formValues(({ fields: { name } }) => ({
    values: name,
})))(TypedCollectionButtonsInner);

export default TypedCollectionButtons;
