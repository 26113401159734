import { compose, withProps, withStateHandlers, mapProps } from 'recompose';
import { FieldArray } from 'redux-form';
import TypedBlockCollectionDropdownsItem from './TypedBlockCollectionDropdownsItem';
import TypedCollectionInner from './TypedCollectionInner';

const TypedCollectionDropDown = compose(
    withStateHandlers(
        () => ({
            openDropdown: null,
        }),
        {
            toggle: ({ openDropdown }) => (id) => ({
                openDropdown: id === openDropdown ? null : id,
            }),
        },
    ),
    mapProps(({ openDropdown, toggle, ...props }) => ({
        ...props,
        itemComponent: TypedBlockCollectionDropdownsItem,
        itemProps: {
            openDropdown,
            toggle,
        },
    })),
)(TypedCollectionInner);

export default withProps({
    component: TypedCollectionDropDown,
})(FieldArray);
