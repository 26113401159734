import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field, formValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { branch, lifecycle, renderComponent, compose } from 'recompose';
import {
    IntegerField,
    TextField,
    DateField,
    PercentageField,
    TypedDisabled,
    YesNoField,
    SelectField,
    TextAreaField,
} from 'components/form/field';
import { connectDisabledForm } from '../../withDisabledForm';
import withFormName from '../../withFormName';
import EditButton from './EditButton';

/* Warning: this EditableField has a breaking change
 *
 * This field update the value itself on automatic mode
 */

export const EditableFieldInner = ({
    disabled,
    editName,
    isEdited,
    name,
    title,
    defaultValue,
    type,
    buttonComponent: Button,
    buttonProps,
    children,
}) => {
    const editButton = <Field name={editName} title="Éditer" component={Button} {...buttonProps} />;

    if (isEdited && !disabled) {
        switch (type) {
            case 'text':
                return (
                    <TextField
                        name={name}
                        title={<Fragment>{title}{editButton}</Fragment>}
                    />
                );
            case 'date':
                return (
                    <DateField
                        name={name}
                        title={<Fragment>{title}{editButton}</Fragment>}
                        min={0}
                    />
                );
            case 'percentage':
                return (
                    <PercentageField
                        name={name}
                        title={<Fragment>{title}{editButton}</Fragment>}
                        min={0}
                    />
                );
            case 'boolean':
                return (
                    <YesNoField
                        name={name}
                        title={<Fragment>{title}{editButton}</Fragment>}
                    />
                );
            case 'select':
                return (
                    <SelectField
                        name={name}
                        title={<Fragment>{title}{editButton}</Fragment>}
                    >
                        {children}
                    </SelectField>
                );
            case 'textArea':
                return (
                    <TextAreaField
                        name={name}
                        title={<Fragment>{title}{editButton}</Fragment>}
                    />
                );
            default:
                return (
                    <IntegerField
                        name={name}
                        title={<Fragment>{title}{editButton}</Fragment>}
                        min={0}
                    />
                );
        }
    }

    return (
        <TypedDisabled
            title={disabled ? title : <Fragment>{title}{editButton}</Fragment>}
            name={name}
            value={defaultValue}
            type={type}
        />
    );
};

EditableFieldInner.propTypes = {
    // is this field on automatic mode (the value is retrieved through formValues)
    isEdited: PropTypes.bool,
    // where to store the information about either we're on automatic mode or not
    editName: PropTypes.string.isRequired,
    // the real field name
    name: PropTypes.string.isRequired,
    // the title
    title: PropTypes.node.isRequired,
    type: PropTypes.string,
    // the default value (being in fact the value on automatic mode)
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.shape({}),
        PropTypes.array,
    ]),
    // is the form on disable mode
    disabled: PropTypes.bool.isRequired,
    // edit component
    buttonComponent: PropTypes.func,
    buttonProps: PropTypes.shape({}),
    children: PropTypes.node,
};

EditableFieldInner.defaultProps = {
    isEdited: false,
    defaultValue: null,
    type: 'number',
    buttonComponent: EditButton,
    buttonProps: null,
    children: null,
};

const EditableField = compose(
    connectDisabledForm,
    branch(({ disabled }) => disabled, compose(
        formValues(({ name }) => ({ defaultValue: name })),
        renderComponent(EditableFieldInner),
    )),
    withFormName,
    connect((state, { form, editName }) => ({
        isEdited: formValueSelector(form)(state, editName),
    }), { change }),
    lifecycle({
        componentWillUpdate(nextProps) {
            const { defaultValue, isEdited, change: update, form } = this.props;

            if (!nextProps.isEdited && (isEdited || defaultValue !== nextProps.defaultValue)) {
                // we are on automatic mode
                // and the default value has changed
                // or we just got back on automatic mode
                // either way we've to persist the new value
                update(form, nextProps.name, nextProps.defaultValue);
            }
        },
    }),
)(EditableFieldInner);

export default EditableField;
