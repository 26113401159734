import React, { createContext, useContext } from 'react';
import { wrapDisplayName, setDisplayName } from 'recompose';

const Context = createContext(null);

export const DatabaseProvider = Context.Provider;

export const useDatabase = () => useContext(Context);

export const withDatabase = (WrappedComponent) => {
    const WithDatabase = (props) => {
        const database = useDatabase();

        return <WrappedComponent {...props} database={database} />;
    };

    const displayName = wrapDisplayName(WrappedComponent, 'withDatabase');

    return setDisplayName(displayName)(WithDatabase);
};
