import { withPropsOnChange } from 'recompose';
import getRipFor from 'common/model/rip/getRipFor';

const withBeneficiaryRip = withPropsOnChange(
    ['rip'],
    ({ rip, simulation: { beneficiary } }) => ({
        rip: getRipFor(rip, beneficiary),
    }),
);

export default withBeneficiaryRip;
