import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'common/components/Icon';

const PrevButton = ({ back }) => (
    <div className="back-link to-previous">
        <Link to={back}>
            <Icon icon="angle-left" />
            &nbsp;Retour
        </Link>
    </div>
);

PrevButton.propTypes = {
    back: PropTypes.string,
};

PrevButton.defaultProps = {
    back: '/',
};

export default PrevButton;
