import { flowRight } from 'lodash/fp';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { withScrollNode } from 'components/layouts';

const InfiniteScroller = ({ component: Component, loadMore, hasMore, items, scrollNode, dataLength, ...props }) => (
    <InfiniteScroll
        dataLength={dataLength || items.length}
        next={loadMore}
        hasMore={hasMore}
        scrollableTarget={scrollNode}
    >
        <Component {...props} items={items} />
    </InfiniteScroll>
);

InfiniteScroller.propTypes = {
    component: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    scrollNode: PropTypes.shape({}).isRequired,
    dataLength: PropTypes.number,
};

export default flowRight([
    withScrollNode,
])(InfiniteScroller);
