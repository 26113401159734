import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import Group from 'components/form/Group';
import Title from 'components/Title';
import Dropdown from 'components/Dropdown';
import recommendations from 'common/choices/recommendations';
import { filterRecommendations } from 'common/utils/recommendations';
import withTargetRecommendations from '../withTargetRecommendations';
import LifeInsurance from './LifeInsurance';
import LODEOM from './LODEOM';
import SCPI from './SCPI';
import Madelin from './Madelin';
import Diversifications from './Diversifications';
import Other from './Other';
import LMNP from './LMNP';
import Pinel from './Pinel';

const summaries = {
    lifeInsurance: LifeInsurance,
    LODEOM,
    FIP: LODEOM,
    SCPI,
    dismemberedSCPI: SCPI,
    PER: Madelin,
    PERP: Madelin,
    Madelin,
    diversification: Diversifications,
    otherFinancial: Other,
    Malraux: LODEOM,
    LMNP,
    Bouvard: LMNP,
    Pinel,
    optimizedPinel: Pinel,
    PinelDenormandie: Pinel,
    landDeficit: Other,
    otherRealEstate: Other,
};

const Recommendations = ({ recommendations: ripRecommendations }) => {
    const requestedRecommendations = filterRecommendations(ripRecommendations);

    const requestedRecommendationsFiltered = requestedRecommendations.filter((request) => 'CIF' !== request.type.slice(-3));

    return (
        <Group>
            <Title><b>Recommandations soumises</b></Title>
            {requestedRecommendationsFiltered.map(({ type, ...recommendation }) => (
                <Dropdown key={type} title={recommendations[type].title} active>
                    {createElement(summaries[type], { recommendation })}
                </Dropdown>
            ))}
        </Group>
    );
};

Recommendations.propTypes = {
    recommendations: PropTypes.shape({}).isRequired,
};

export default withTargetRecommendations(Recommendations);
