import React, { Component, Fragment } from 'react';
import { map } from 'lodash/fp';
import PropTypes from 'prop-types';
import zipcelx from 'zipcelx';
import Dropdown from 'components/Dropdown';
import Icon from 'common/components/Icon';
import { GET } from 'utils/httpMethods';
import { header, getRow, getTotalRow } from './invoicesExport';
import Invoices from './Invoices';
import Spinner from '../../Spinner';

class Payout extends Component {
    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.exportList = this.exportList.bind(this);
        this.state = { invoices: null };
    }

    async onEnter() {
        if (this.state.invoices) {
            return;
        }

        const { payoutId } = this.props;
        const response = await GET(`/api/payment/payouts/${payoutId}/invoices/${this.props.dateVirement}`);
        const { items: invoices } = await response.json();
        this.setState({ invoices });
    }

    exportList() {
        const { invoices } = this.state;

        return () => {
            const config = {
                filename: 'factures',
                sheet: {
                    data: [header,
                        ...map(getRow, invoices),
                        getTotalRow(invoices),
                    ],
                },
            };

            zipcelx(config);
        };
    }

    render() {
        const { invoices } = this.state;
        const { title } = this.props;

        return (
            <Dropdown onEnter={this.onEnter} title={title} >
                {invoices ? (
                    <Fragment>
                        <div className="alert alert-warning text-center">
                            Frais de plateforme: - <b>{invoices.reduce((acc, { fee }) => acc + fee, 0) / 100} EUR</b>
                        </div>
                        <Invoices items={invoices} />
                        <button type="submit" className="sendButton lastButton" onClick={this.exportList()}>
                            Exporter la liste
                            <Icon icon="download" />
                        </button>
                    </Fragment>
                ) : <Spinner>Chargement des Factures...</Spinner>}
            </Dropdown>
        );
    }
}

Payout.propTypes = {
    title: PropTypes.string.isRequired,
    dateVirement: PropTypes.number,
    payoutId: PropTypes.string,
};

export default Payout;
