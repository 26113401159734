import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createElement } from 'react';
import Title from './Title';

import './Group.scss';

const FormGroup = ({ children, className, component, required, title, info, ...props }) => createElement(
    component,
    { className: classNames('form-group', className), ...props },
    title ? createElement(Title, { required, title, info }) : null,
    children,
);

FormGroup.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    component: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    info: PropTypes.string,
};

FormGroup.defaultProps = {
    className: null,
    component: 'div',
    required: false,
    title: null,
    info: null,
};

export default FormGroup;
