import React from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { get } from 'lodash/fp';
import { formValues } from 'redux-form';
import displaySci from 'common/utils/displaySci';
import Group from '../../form/Group';
import TypedBlockCollectionItem from '../../form/TypedBlockCollectionItem';
import SciCommon from './SciCommon';
import TypedCollectionDropDowns from '../../form/TypedCollectionDropDowns';

const types = {
    sciIR: {
        component: withProps({ type: 'sciIR' })(SciCommon),
        title: 'SCI à l\'IR',
    },
    sciIS: {
        component: withProps({ type: 'sciIS' })(SciCommon),
        title: 'SCI à l\'IS',
    },
};

const ScisList = ({ familySituation, sci }) => {
    const shouldDisplay = (name, index) => {
        const owner = get([index, 'owner'], sci);

        return displaySci(owner, familySituation);
    };

    return (
        <Group title="SCI">
            <TypedCollectionDropDowns
                name="sci"
                types={types}
                textOnly
                itemComponent={TypedBlockCollectionItem}
                shouldDisplay={shouldDisplay}
            />
        </Group>
    );
};

ScisList.propTypes = {
    familySituation: PropTypes.string.isRequired,
    sci: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default formValues(() => ({
    familySituation: 'familySituation',
    sci: 'sci',
}))(ScisList);
