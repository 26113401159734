import multipleChoiceQuestion from '../../../../../utils/multipleChoiceQuestion';

const choices = {
    lifeInsurance: { point: [2] },
    trading: { point: [3] },
    precautionarySaving: { point: [1] },
    complex: { point: [4] },
    none: { point: [0] },
};

const question = (point, investorProfile) => multipleChoiceQuestion(investorProfile.doneInvestmentTypes, choices);

export default question;
