import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { withProps } from 'recompose';
import computeInvestment from 'common/model/simulation/scpi/computeInvestment';
import { formatAmount } from 'common/utils/format';
import { DisabledField } from 'components/form/field';

const InvestmentInner = ({ amount }) => (
    <DisabledField
        name="data.investmentAmount"
        title="Montant estimé de l'investissement"
        value={formatAmount(amount)}
    />
);

InvestmentInner.propTypes = {
    amount: PropTypes.number.isRequired,
};

const Investment = flowRight([
    formValues({ products: 'data.products' }),
    withProps(({ products }) => ({ amount: computeInvestment(products) })),
])(InvestmentInner);

export default Investment;
