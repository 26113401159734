import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GET } from 'utils/httpMethods';

import Menu from './Menu/Menu';
import ManualSignatures from './Manual/ManualSignatures';
import ElectronicSignatures from './Electronic/ElectronicSignatures';
import ifSettingsNullCheckSettingsAncestor from '../../../../common/utils/getAncestorSettings';

const handleGetRequest = async (url) => {
    const response = await GET(url);
    const data = await response.json();

    return data;
};

const handleSignatureMode = (signature, setSignatureMode) => {
    if (signature && 'manuellement' === signature.type) {
        setSignatureMode('manual');
    } else if (signature && ['universign', 'électroniquement', 'refusal elect'].includes(signature.type)) {
        setSignatureMode('universign');
    }
};

const Signatures = ({ back, rip }) => {
    const [component, setComponent] = useState('menu');
    const [componentProps, setComponentProps] = useState({});

    const changeComponent = useCallback((newComponent, props) => () => {
        setComponentProps(props);
        setComponent(newComponent);
    }, [setComponent, setComponentProps]);

    const [settingsEntity, setSettingsEntity] = useState(null);
    const [settingsGlobal, setSettingsGlobal] = useState(null);

    const [clientSignatureMode, setClientSignatureMode] = useState('manual');
    const [partnerSignatureMode, setPartnerSignatureMode] = useState('manual');

    const { clientAndPartnerSignatureDate } = rip;

    useEffect(() => {
        (async () => {
            // récupération de l'arborescence des réseaux parents
            const entitiesList = await handleGetRequest(`/api/entities/${rip.user.entity.id}/arborescence`);
            const entityData = entitiesList.items.filter((entity) => entity.id === rip.user.entity.id);

            // vérification de la value d'un paramètre dans l'arboresence familiale
            const legalDisclaimerRip = await ifSettingsNullCheckSettingsAncestor('legalDisclaimerRip', entityData[0].legalDisclaimerRip, rip.user.entity.id, entitiesList.items, handleGetRequest);
            const consentement = await ifSettingsNullCheckSettingsAncestor('consentement', entityData[0].consentement, rip.user.entity.id, entitiesList.items, handleGetRequest);

            setSettingsEntity({ legalDisclaimerRip, consentement });

            // récupération des paramètres globaux
            const globalData = await handleGetRequest('/api/settings');
            setSettingsGlobal(globalData);
        })();

        if (clientAndPartnerSignatureDate) {
            handleSignatureMode(clientAndPartnerSignatureDate.client, setClientSignatureMode);
            handleSignatureMode(clientAndPartnerSignatureDate.partner, setPartnerSignatureMode);
        }
    }, []);

    switch (component) {
        case 'menu':
            return <Menu rip={rip} back={back} changeComponent={changeComponent} clientSignatureMode={clientSignatureMode} setClientSignatureMode={setClientSignatureMode} partnerSignatureMode={partnerSignatureMode} setPartnerSignatureMode={setPartnerSignatureMode} />;
        case 'manual-sign':
            return <ManualSignatures rip={rip} back={back} {...componentProps} changeComponent={changeComponent} settingsEntity={settingsEntity} settingsGlobal={settingsGlobal} clientSignatureMode={clientSignatureMode} partnerSignatureMode={partnerSignatureMode} />;
        case 'electronic-sign':
            return <ElectronicSignatures rip={rip} {...componentProps} />;
        default:
            return null;
    }
};

Signatures.propTypes = {
    back: PropTypes.string,
    rip: PropTypes.shape({
        user: PropTypes.shape({
            entity: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        clientAndPartnerSignatureDate: PropTypes.shape({
            client: PropTypes.shape({}),
            partner: PropTypes.shape({}),
        }),
    }),
};

export default Signatures;
