import { get } from 'lodash/fp';
import useSession from './useSession';

const useInstanceSettings = (path = null) => {
    const { instanceSettings } = useSession() || {};

    return path ? get(path, instanceSettings) : instanceSettings;
};

export default useInstanceSettings;
