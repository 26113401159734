const createRecommendation = ({
    // required options
    title, // human readable name
    category, // category key
    // optional options
    simulator = null, // which simulator is unlock, null means none will be
    defaultValue = {}, // default value for this recommendations
}) => ({ title, category, simulator, defaultValue });

export const categories = {
    realEstate: 'Produits immobiliers',
    financial: 'Produits financiers',
    financialCif: 'Produit financier',
};

const recommendations = {
    lifeInsurance: createRecommendation({ title: 'Assurance vie', category: 'financial', simulator: 'lifeInsurance' }),
    LODEOM: createRecommendation({ title: 'LODEOM', category: 'financial' }),
    FIP: createRecommendation({ title: 'FIP', category: 'financial' }),
    SCPI: createRecommendation({ title: 'SCPI', category: 'financial', simulator: 'scpi' }),
    dismemberedSCPI: createRecommendation({ title: 'SCPI démembrée', category: 'financial', simulator: 'dismemberedScpi' }),
    PER: createRecommendation({ title: 'PER', category: 'financial' }),
    PERP: createRecommendation({ title: 'PERP', category: 'financial' }),
    Madelin: createRecommendation({ title: 'Madelin', category: 'financial' }),
    diversification: createRecommendation({ title: 'Diversification', category: 'financial' }),
    otherFinancial: createRecommendation({ title: 'Autre', category: 'financial' }),
    Malraux: createRecommendation({ title: 'Malraux', category: 'realEstate' }),
    LMNP: createRecommendation({ title: 'LMNP classique', category: 'realEstate', simulator: 'lmnp' }),
    Bouvard: createRecommendation({ title: 'LMNP Censi-Bouvard', category: 'realEstate', simulator: 'lmnp' }),
    Pinel: createRecommendation({ title: 'Pinel', category: 'realEstate', simulator: 'pinel' }),
    PinelDenormandie: createRecommendation({ title: 'Dispositif Denormandie', category: 'realEstate', simulator: 'pinelDenormandie' }),
    optimizedPinel: createRecommendation({ title: 'Pinel optimisée', category: 'realEstate' }),
    landDeficit: createRecommendation({ title: 'Déficit foncier', category: 'realEstate' }),
    otherRealEstate: createRecommendation({ title: 'Autre', category: 'realEstate' }),
    LODEOMCIF: createRecommendation({ title: 'LODEOM', category: 'financialCif' }),

};

export default recommendations;
