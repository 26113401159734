import { set, min } from 'lodash/fp';
import validate, {
    checkRequiredIntegerField,
    checkRequiredEditableField,
} from 'common/formValidation/lib';
import { validators as propertyValidators } from '../../common/property/PropertyForm';
import { validators as programValidators } from '../../common/property/ProgramForm';
import { GET } from '../../../../../utils/httpMethods';

let rentCeils = {};

const checkPropertyMonthlyRate = (
    errors,
    { propertyMonthlyRent, programZone, propertyWeightedSurface },
) => {
    GET('/api/settings/')
        .then((response) => response.json())
        .then((data) => {
            rentCeils = data.simulation.pinel.plafonds.zone;
        });

    const ceil =
        min([
            (0.7 + (propertyWeightedSurface ? 19 / propertyWeightedSurface : 0)).toFixed(2),
            1.2,
        ]) *
        rentCeils[programZone] *
        propertyWeightedSurface;

    if (ceil < propertyMonthlyRent) {
        return set(
            'propertyMonthlyRent',
            'Attention votre loyer dépasse le plafond au m² de la zone séléctionnée.',
            errors,
        );
    }

    return errors;
};

const formValidate = validate([
    ...propertyValidators,
    ...programValidators,
    checkRequiredIntegerField('programDuration'),
    checkRequiredIntegerField('programZone'),
    checkRequiredIntegerField('propertyValue'),
    checkRequiredIntegerField('parkingLotValue'),
    checkRequiredIntegerField('propertyMonthlyRent'),
    checkPropertyMonthlyRate,
    checkRequiredEditableField(
        'parkingEditableNotaryFees',
        'parkingNotaryFees',
    ),
    checkRequiredEditableField(
        'parkingEditableOtherAcquisitionCost',
        'parkingOtherAcquisitionCost',
    ),
    checkRequiredEditableField('editableNotaryFees', 'notaryFees'),
    checkRequiredEditableField(
        'editableOtherAcquisitionCost',
        'otherAcquisitionCost',
    ),
    checkRequiredEditableField(
        'data.taxBenefitBasis.edited',
        'data.taxBenefitBasis.value',
    ),
]);

export default formValidate;
