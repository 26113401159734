const question = (value) => {
    if (value <= 2) {
        return { values: ['P'] };
    }

    if (value <= 5) {
        return { values: ['E'] };
    }

    return { values: ['D'] };
};

export default question;
