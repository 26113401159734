import { flowRight, get } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { contractStates } from 'common/choices';
import isSimuAvailableForContract from 'common/utils/isSimuAvailableForContract';
import { GET, POST } from 'utils/httpMethods';
import { Action, Actions, Date, Initials, Item, connectActiveItem } from '../list';
import { verifyPrerequisites } from './lifeInsurance/Verify';
import { verifyPerPrerequisites } from './per/Verify';
import getSettings from '../../common/utils/settings';
import getLifeInsuranceData from '../../common/utils/getLifeInsuranceData';
import getPerData from '../../common/utils/getPerData';

const capitelizeFirstLetter = (string) => {
    if (!string) { return ''; }

    return (
        string.charAt(0).toUpperCase() + string.slice(1)

    );
};

const ListItem = ({ rip, item, isActive, show }) => {
    const contractType = get('simulation.data.contractType', item);
    const product = get('simulation.product', item);

    const types = 'per' === product ? 'contracts' : 'types';
    const isPer = 'per' === product;

    const subscription = getSettings(`simulation.${product}.${types}.${contractType}.subscription`);
    const verify = (val, typer) => {
        const verifyContract = isPer ? verifyPerPrerequisites(val, typer) : verifyPrerequisites(val, typer);

        return verifyContract;
    };

    const contractStateProduct = () => {
        const contractState = isPer ?
            <td>{get([getPerData(contractType, 'cardif', 'mma'), item.state], contractStates)}</td>
            :
            <td>{get([getLifeInsuranceData(contractType, 'target', 'pvp'), item.state], contractStates)}</td>;

        return contractState;
    };

    const shouldRedirectToHub = (subscription && !isPer && 'completedDigital' === item.state) || (isPer && 'completedDigital' === item.state);

    const redirectMessage = isPer ? `accéder au portail ${capitelizeFirstLetter(get('simulation.data.subscriptionChoice', item))}` : 'accéder au hub primonial';

    const redirectToHub = async () => {
        if (isPer) {
            const response = await POST(`/api/contracts/${item.id}/per/url`);
            const { url } = await response.json();
            window.open(url, '_blank');
        } else {
            const response = await GET(`/api/contracts/${item.id}/primonial/url`);
            const { hubUrl } = await response.json();

            window.open(`/sso-redirect-primonial?url=${encodeURIComponent(hubUrl)}`, '_blank');
        }
    };

    return (
        <Item
            displayActions={isActive}
            onFocus={!isActive ? () => show() : null}
        >
            <Initials firstName={rip.firstName} lastName={rip.lastName} />
            <td>{item.simulation.name}</td>
            <Date date={item.createdAt} />
            <Date date={item.updatedAt} />
            <td>{contractStateProduct()}</td>

            <Actions>
                {shouldRedirectToHub ? (
                    <Action onClick={redirectToHub} icon="desktop" text={redirectMessage} />
                ) : (
                    <Action
                        to={isSimuAvailableForContract(subscription)
                            && verify(get('data.verify', item), contractType)
                            ? `/rip/${rip.id}/contracts/${item.id}/conditions/${contractType}`
                            : `/rip/${rip.id}/contracts/${item.id}/verifications/${contractType}`
                        }
                        icon="pencil"
                        text="modifier"
                    />
                )}
            </Actions>
        </Item>
    );
};

ListItem.propTypes = {
    rip: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
    item: PropTypes.shape({
        simulation: PropTypes.shape({
            name: PropTypes.string,
        }),
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        state: PropTypes.string,
        id: PropTypes.string,
        refAct: PropTypes.string,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    show: PropTypes.func.isRequired,
};

export default flowRight([
    connectActiveItem(
        ({ item: { id } }) => id,
        (item, { activeItem }) => ({ isActive: item === activeItem }),
    ),
    pure,
])(ListItem);
