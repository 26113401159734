import { branch, compose, mapProps, renderNothing, withProps } from 'recompose';
import { formValues } from 'redux-form';
import TypedBlockCollectionItemInner from './TypedBlockCollectionItemInner';
import TypedBlock from './TypedBlock';

const TypedBlockCollectionItem = compose(
    formValues(({ name }) => ({ type: `${name}.type` })),
    mapProps(({ type, types, ...props }) => ({ type: types[type], ...props })),
    branch(({ type }) => !type, renderNothing),
    withProps({
        component: TypedBlock,
    }),
)(TypedBlockCollectionItemInner);

export default TypedBlockCollectionItem;
