import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import fullName from 'common/utils/fullName';
import withSpinner from 'components/withSpinner';
import { Body, Table, Header, Head, listProvider, withActiveItem } from 'components/list';
import ListItem from './ListItem';

const ContractTableInner = ({ rip, items }) => (
    <Table>
        <Head>
            <th />
            <Header sortProperty="name" text={`Souscriptions de ${fullName(rip)}`} />
            <Header sortProperty="createdAt" text="Date de création" />
            <Header sortProperty="updatedAt" text="Dernière modification" />
            <Header sortProperty="state" text="Statut de la souscription" />
            <th />
        </Head>
        <Body>
            {items.map((item) => (
                <ListItem
                    key={item.id}
                    rip={rip}
                    item={item}
                />
            ))}
        </Body>
    </Table>
);

ContractTableInner.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rip: PropTypes.shape({}).isRequired,
};

const ContractTable = flowRight([
    listProvider({
        sorts: {
            createdAt: { initialOrder: 'desc', default: true },
            name: { initialOrder: 'asc' },
        },
        name: '',
        type: 'contract',
        criteria: ({ rip: { id } }) => ({ ripId: id }),
    }),
    withSpinner,
    withActiveItem,
    pure,
])(ContractTableInner);

export default ContractTable;
