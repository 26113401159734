import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, get } from 'lodash/fp';

import { contractStates } from 'common/choices';
import Title from 'components/Title';
import { formatDate } from 'common/utils/format';
import { GET } from 'utils/httpMethods';

import './Contracts.scss';
import withRouter from '../../../../withRouter';

class Contracts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contracts: [],
        };
    }

    componentDidMount() {
        this.loadContracts();
    }

    async loadContracts() {
        const {
            params: { simulationId },
        } = this.props;

        const response = await GET(`/api/simulations/${simulationId}/contracts`);
        const { items: contracts } = await response.json();

        this.setState({ contracts });
    }

    render() {
        const { contracts } = this.state;
        const {
            params: { id: ripId },
        } = this.props;

        return (
            !isEmpty(contracts) && (
                <div className="contracts-list">
                    <Title>
                        <b>Souscriptions liées à cette simulation :</b>
                    </Title>
                    <div className="simulation-field">
                        <div className="head">
                            <div>Date de création</div>
                            <div>Dernière modification</div>
                            <div>Status</div>
                        </div>
                        {contracts.map((contract) => (
                            <Link key={contract.id} to={`/rip/${ripId}/contracts/${contract.id}/conditions`}>
                                <div>{formatDate(contract.createdAt)}</div>
                                <div>{formatDate(contract.updatedAt)}</div>
                                <div>{get(contract.state, contractStates)}</div>
                            </Link>
                        ))}
                    </div>
                </div>
            )
        );
    }
}

Contracts.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
};

// export default withRouter(Contracts);
export default withRouter(Contracts);
