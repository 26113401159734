import { get, set, isBoolean } from 'lodash/fp';

const checkRequiredBoolean = (field, requiredValue, errorString) => (errors, values) => {
    const value = get(field, values);

    return (isBoolean(value) && value === requiredValue)
        ? errors
        : set(field, errorString, errors);
};

export default checkRequiredBoolean;
