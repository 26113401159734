import PropTypes from 'prop-types';
import React from 'react';
import { scrollToNext } from 'utils/scrollToNext';
import FormButtons from 'components/FormButtons';
import { VerticalLayout } from 'components/form/layout';

const FormFields = ({ children, handleSubmit, back, last, nextButtonContent, isHiddenButton }) => (
    <form
        role="presentation"
        onSubmit={handleSubmit}
        onKeyDown={scrollToNext}
        id="main-form"
    >
        <div>
            <VerticalLayout className="container">
                {children}
            </VerticalLayout>
        </div>
        <FormButtons back={back} nextButtonContent={nextButtonContent} last={last} isHiddenButton={isHiddenButton} />
    </form>
);

FormFields.propTypes = {
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    back: PropTypes.string.isRequired,
    last: PropTypes.bool,
    nextButtonContent: PropTypes.string,
    isHiddenButton: PropTypes.string,
};

FormFields.defaultProps = {
    last: false,
    nextButtonContent: 'Enregistrer',
};

export default FormFields;
