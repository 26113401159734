import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getContext, compose, withProps } from 'recompose';

import './TableHeader.css';

const sortDisplay = (sortProperty, sort) => {
    if (sortProperty === sort.property) {
        return <i className={`fa fa-sort-${('asc' === sort.order) ? 'desc' : 'asc'} asc-desc`} aria-hidden="true" />;
    }

    return (
        <span className="fa-stack available-sort">
            <i className="fa fa-sort-asc fa-stack-1x" />
            <i className="fa fa-sort-desc fa-stack-1x" />
        </span>
    );
};

const TableHeaderInner = ({ sortProperty, text, sort, toggleSort, centered = false, miseAJourListe, setTriChoisi }) => {
    const [triSelectionne, setTriSelectionne] = useState({ property: 'updatedAt', order: 'desc' });

    useEffect(() => {
        if (!_.isEmpty(sort) && !_.isEqual(sort, triSelectionne)) {
            setTriSelectionne(sort);
        }
    }, [sort]);

    useEffect(() => {
        if (!_.isEmpty(sort)) {
            if (miseAJourListe) {
                miseAJourListe(triSelectionne);
            }

            if (setTriChoisi) {
                setTriChoisi(sort);
            }
        }
    }, [triSelectionne]);

    return (
        <th
            className={classNames('list-table-header', { 'text-center': centered })}
            onClick={() => toggleSort(sort)}
        >
            {text}
            {sortDisplay(sortProperty, sort)}
        </th>
    );
};

TableHeaderInner.propTypes = {
    toggleSort: PropTypes.func.isRequired,
    sortProperty: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    sort: PropTypes.shape({
        property: PropTypes.string.isRequired,
        order: PropTypes.oneOf(['desc', 'asc']).isRequired,
    }).isRequired,
    centered: PropTypes.bool,
    miseAJourListe: PropTypes.func,
    setTriChoisi: PropTypes.func,
};

const TableHeader = compose(
    getContext({
        list: PropTypes.shape({
            getSort: PropTypes.func.isRequired,
            toggleSort: PropTypes.func.isRequired,
        }).isRequired,
    }),
    connect(
        (state, { list }) => ({
            sort: list.getSort(state),
        }),
        (dispatch, { list, sortProperty }) => ({
            toggleSort: (currentSort) => dispatch(list.toggleSort(currentSort, sortProperty)),
        }),
    ),
    withProps(({ miseAJourListe }) => ({
        miseAJourListe,
    })),
)(TableHeaderInner);

export default TableHeader;
