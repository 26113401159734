const fullName = ({ firstName, lastName }) => `${firstName || ''} ${lastName ? lastName.toUpperCase() : ''}`.trim();

export const fullNamePartner = ({ partnerFirstName, partnerLastName }) => `${partnerFirstName || ''} ${partnerLastName ? partnerLastName.toUpperCase() : ''}`.trim();

export const afficheNomPrenom = (prenom, nom) => {
    if (!prenom && !nom) {
        return '';
    }

    return fullName({ firstName: prenom, lastName: nom });
};

export const invertedFullName = ({ firstName, lastName }) =>
    `${lastName ? lastName.toUpperCase() : ''} ${firstName || ''}`.trim();

export default fullName;
