import React from 'react';
import { withProps } from 'recompose';
import Icon from 'common/components/Icon';
import InitialsBadge from './InitialsBadge';

const InitialsLockBadge = withProps({
    className: 'locked',
    children: <Icon icon="lock" />,
})(InitialsBadge);

export default InitialsLockBadge;
