import { get } from 'lodash/fp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Icon from '../../../../common/components/Icon';
import { formatDate } from '../../../../common/utils/format';
import useSettings from '../../../sessions/useSettings';

const Simulation = ({
    checked,
    simulation: { id, name, createdAt, updatedAt, beneficiary, data },
    contractTypeState,
    beneficiaryTypeState,
    rip: { clientAndPartnerSignatureDate },
    setIsBeneficiarySign,
    ...inputProps
}) => {
    const contractType = get('contractType', data);

    const { name: contractName } = useSettings(['simulation', 'per', 'contracts', contractType]);
    const htmlId = `${inputProps.name}-${id}`;
    const signsState = get(beneficiary, clientAndPartnerSignatureDate);

    useEffect(() => {
        if (checked) {
            contractTypeState.setContractType(contractType);
            beneficiaryTypeState.setBeneficiaryType(beneficiary);
            setIsBeneficiarySign(!signsState);
        }
    }, [checked]);

    return (
        <label
            className={classNames({ selected: checked })}
            htmlFor={htmlId}
        >
            <input id={htmlId} type="radio" value={id} {...inputProps} />
            <div><Icon icon="check" /></div>
            <div>{name}</div>
            <div>{contractName}</div>
            <div>{formatDate(createdAt)}</div>
            <div>{formatDate(updatedAt)}</div>
        </label>
    );
};

Simulation.propTypes = {
    checked: PropTypes.bool,
    simulation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        createdAt: PropTypes.instanceOf(Date),
        updatedAt: PropTypes.instanceOf(Date),
        data: PropTypes.shape({}),
        beneficiary: PropTypes.string,
    }).isRequired,
    contractTypeState: PropTypes.shape({
        setContractType: PropTypes.func,
    }).isRequired,
    beneficiaryTypeState: PropTypes.shape({
        setBeneficiaryType: PropTypes.func,
    }),
    rip: PropTypes.shape({
        clientAndPartnerSignatureDate: PropTypes.string,
    }),
    setIsBeneficiarySign: PropTypes.func,
};

Simulation.defaultProps = {
    checked: false,
};

export default Simulation;
