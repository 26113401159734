import { Layout } from 'components/layouts';
import { flowRight, get, isFunction } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createElement, useEffect, useState } from 'react';
import { withProps, withState } from 'recompose';
import { formValues } from 'redux-form';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

import { setModal } from 'actions/modal';
import Spinner from 'components/Spinner';
import { GET } from '../../utils/httpMethods';
import { withDatabase } from '../database';
import dataCreationForm from '../dataCreationForm';
import dataProvider from '../dataProvider';
import { InputField, SelectField } from '../form/field';
import { VerticalLayout } from '../form/layout';
import FormButtons from '../FormButtons';
import { withSession } from '../sessions';
import formValidate from './formValidate';
import getUrl from './getUrl';
import topinvestSimulationList from './topinvestSimulationList';
import types from './types';
import Icon from '../../common/components/Icon';

import './Creation.scss';
import withRouter from '../withRouter';

const WithProduct = formValues('product')(({ product, children }) => children(product));

const CreationInner = ({
    handleSubmit,
    params: { id },
    rip,
    session,
    ...props
}) => {
    const { productName } = props;

    const [hasValidOriasNumber, setHasValidOriasNumber] = useState(null);
    const [productChoice, setProductChoice] = useState(null);
    const [completed, setCompleted] = useState(true);

    const check = session.permissions.mayCreateSimulation.bind(session.permissions, rip);
    const { instanceTheseis } = session;

    const runCreationChecks = 'cohabitation' === rip.familySituation && rip.dualRip
        ? (product) => check(product) || check(product, 'partner')
        : check;

    let topinvestUser = false;
    if ('Topinvest' === session.entity.name) {
        topinvestUser = true;
    }

    useEffect(() => {
        (async () => {
            let validOriasNumberResponse = await GET(`/api/users/${session.id}/validOriasNumber`);
            validOriasNumberResponse = await validOriasNumberResponse.json();
            setHasValidOriasNumber(validOriasNumberResponse);
        })();
    }, []);

    useEffect(() => {
        if (productChoice && productName) {
            setCompleted(true);
        }
    }, [productChoice, productName]);

    if (null !== hasValidOriasNumber) {
        if (!hasValidOriasNumber || !instanceTheseis) {
            delete types.per;
        }
    }

    const renderForm = () => (
        <form onSubmit={handleSubmit} id="main-form">
            <div>
                <div className="simulation-form-inner">
                    <div className="container">
                        <VerticalLayout>
                            <InputField name="name" title="Nom de la simulation" type="text" required />
                            <SelectField name="product" title="Produit" required>
                                <option />
                                {Object
                                    .entries(types)
                                    .map(([productKey, { label }]) => (
                                        runCreationChecks(productKey)
                                            ? <option value={productKey} key={productKey}>{label}</option>
                                            : null))}
                            </SelectField>
                            <WithProduct>
                                {(product) => {
                                    // get the additional fields component
                                    const AdditionalFieldComponent = get([product, 'formComponent'], types);

                                    return AdditionalFieldComponent
                                        ? createElement(AdditionalFieldComponent, { rip })
                                        : null;
                                }}
                            </WithProduct>
                        </VerticalLayout>
                    </div>
                </div>
                <FormButtons
                    topinvestUser={topinvestUser}
                    back={topinvestUser ? `/rip/${id}/appointment` : `/rip/${id}/simulations/`}
                    list={`/rip/${id}/simulations/`}
                    isHiddenButton={!navigator.onLine && topinvestUser}
                    isButtonForSimulationTopInvest
                />
            </div>
        </form>
    );

    const renderTopinvest = () => (
        <div>
            <div className="simulation-form-inner">
                <div className="container">
                    {
                        (!navigator.onLine) &&
                        <Alert className="simulation__alerte__contenu" variant="info">
                            <Icon icon="info" className="simulation__alerte-icone" />
                            <p>
                                La simulation ne sera accessible qu&apos;une fois l&apos;application connectée à Internet
                            </p>
                        </Alert>
                    }
                    <VerticalLayout>
                        <InputField name="name" title="Nom de la simulation" type="text" required />
                        <SelectField name="product" title="Produit" required>
                            <option />
                            {Object
                                .entries(topinvestSimulationList)
                                .map(([productKey, { label }]) => (
                                    runCreationChecks(productKey)
                                        ? <option value={productKey} key={productKey}>{label}</option>
                                        : null))}
                        </SelectField>
                        <WithProduct>
                            {(product) => {
                                setProductChoice(product);

                                // get the additional fields component
                                const AdditionalFieldComponent = get([product, 'formComponent'], types);

                                return AdditionalFieldComponent
                                    ? createElement(AdditionalFieldComponent, { rip })
                                    : null;
                            }}
                        </WithProduct>
                        {
                            (!completed) &&
                            <div className="informativeMessage">
                                <Icon icon="exclamation" className="fa-fw" />

                                {
                                    `${!productName && !productChoice ?
                                        'Vous devez renseigner les champs "Nom de la simulation" et "Produit"'
                                        :
                                        `Vous devez renseigner le champ  ${!productName ? '"Nom de la simulation"' : ''}${!productChoice ? ' "Produit"' : ''}`
                                    }`
                                }
                            </div>
                        }
                    </VerticalLayout>
                </div>
            </div>
            <FormButtons
                topinvestUser={topinvestUser}
                back={topinvestUser ? `/rip/${id}/appointment` : `/rip/${id}/simulations/`}
                list={`/rip/${id}/simulations/`}
                isHiddenButton={!navigator.onLine && topinvestUser}
                ripId={rip.id}
                productChoice={productChoice}
                productName={productName}
                setCompleted={setCompleted}
            />
        </div>
    );

    return (
        <Layout className="simulation-form creation-form" hasFooter>
            <div>
                {topinvestUser ?
                    renderTopinvest()
                    :
                    renderForm()}
            </div>

        </Layout>
    );
};

CreationInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    rip: PropTypes.shape(),
    session: PropTypes.shape({
        instanceIdsoft: PropTypes.string,
        instanceInovia: PropTypes.string,
        instanceTheseis: PropTypes.bool,
        id: PropTypes.string,
        oriasNumber: PropTypes.string,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        instanceSettings: PropTypes.shape({
            label: PropTypes.string,
        }),
        permissions: PropTypes.shape({
            isCaseManager: PropTypes.func,
            mayCreateSimulation: PropTypes.shape({
                bind: PropTypes.func,
            }),
        }),
    }).isRequired,
    productName: PropTypes.string,
};

const Creation = flowRight([
    withRouter,
    withProps(({ params: { id } }) => ({
        initialValues: {
            ripId: id,
            beneficiary: 'client',
        },
    })),
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
        wait: true,
    }),
    withDatabase,
    withSession,
    withState('productName', 'setProductName', null),
    connect((state, props) => {
        const { setProductName } = props;
        setProductName(get('form.simulation-creation.values.name', state));
    }),
    withProps(({ productName }) => ({
        productName,
    })),
    dataCreationForm({
        form: 'simulation-creation',
        onSubmitSuccess: (result, dispatch, { session, navigate }) => {
            if ('TOPINVEST' === session.entity.name.toUpperCase()) {
                dispatch(setModal(() => <Spinner />, false));
            }

            if ('Topinvest' !== session.entity.name) {
                navigate(getUrl(result));
            }
        },
        type: 'simulation',
        // eslint-disable-next-line consistent-return
        onSubmitData: (values, props) => {
            const productValues = get([values.product, 'initialValues'], types);

            return ({
                ...(isFunction(productValues) ? productValues(values, props) : productValues),
                ...values,
            });
        },
        validate: formValidate,
    }),
])(CreationInner);

export default Creation;
