import React from 'react';
import { fieldPropTypes } from 'redux-form';
import Icon from '../../common/components/Icon';

import './EditButton.css';

const EditButton = ({ input: { value, ...input } }) => (
    <label htmlFor={input.name} className="edit-button">
        <input
            id={input.name}
            {...input}
            checked={value}
            type="checkbox"
        />
        <Icon icon={value ? 'calculator' : 'pencil'} />
    </label>
);

EditButton.propTypes = {
    ...fieldPropTypes,
};

export default EditButton;
