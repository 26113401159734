import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { getContext, withProps } from 'recompose';

import './Error.css';

const ErrorInner = ({ mustShowErrors, error, warning, forceerror, errortext }) => (
    <Fragment>
        {forceerror ? (
            <div className="form-field-error">{errortext}</div>
        ) : null}
        {mustShowErrors && error ? (
            <div className="form-field-error">{error}</div>
        ) : null}
        {mustShowErrors && warning ? (
            <div className="form-field-warning">{warning}</div>
        ) : null}
    </Fragment>
);

ErrorInner.propTypes = {
    mustShowErrors: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
    forceerror: PropTypes.bool,
    errortext: PropTypes.string,
};

ErrorInner.defaultProps = {
    error: null,
    warning: null,
    forceerror: false,
    errortext: '',
};

const Error = flowRight([
    // first look into the context
    // maybe we are forced to show errors
    getContext({ mustShowErrors: PropTypes.bool }),
    // setup props
    withProps(({ mustShowErrors, submitFailed = false, dirty = false }) => ({
        mustShowErrors: mustShowErrors || dirty || submitFailed,
    })),
])(ErrorInner);

export default Error;
