import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from 'components/documents/List';
import Upload from 'components/documents/Upload';
import Sign from 'components/documents/Sign';
import Check from 'components/documents/Check';
import Cif from 'components/cif';

const Router = () => (
    <Routes>
        <Route path="/" element={<List />} />
        <Route path="/cif" element={<Cif />} />
        <Route path="/:docId/upload" element={<Upload />} />
        <Route path="/:docId/check" element={<Check />} />
        <Route path="/:docId/sign" element={<Sign />} />
    </Routes>
);

export default Router;
