import React, { useCallback, useState } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import Dropdown from 'components/Dropdown';
import { PercentageFloatField, TextField, YesNoField } from 'components/form/field';
import sortSettings from 'common/utils/sortSettings';
import { DELETE } from 'utils/httpMethods';
import UploadSettingFiles from '../../UploadSettingFiles';

import './perBaseProducts.scss';

const PerSupportDropdown = ({ label, product, types }) => {
    const [error, setError] = useState('');
    const deleteBaseProduct = useCallback(async (e) => {
        e.preventDefault();
        const contractUsedIn = [];
        for (const { name, profiles } of Object.values(types)) {
            if (profiles) {
                for (const [profileName, { repartition }] of Object.entries(profiles)) {
                    if (repartition) {
                        for (const { baseProduct } of repartition) {
                            if (baseProduct === product) {
                                contractUsedIn.push(`répartition ${profileName} du contrat ${name}`);
                            }
                        }
                    }
                }
            }
        }
        if (0 === contractUsedIn.length && window.confirm('Supprimer le support ?')) {
            await DELETE(`/api/settings/per/baseProducts/${product}`);
            window.location.reload(true);
        } else {
            setError(`Le contrat ${product} est utilisé dans les contrats suivants : ${contractUsedIn.join(', ')}`);
        }
    }, [setError]);

    const uploadSetting = (field) => (
        <UploadSettingFiles documents={field.input.value} settingKey={`simulation.per.baseProducts.${product}`} onChange={field.input.onChange} />
    );

    return (
        <Dropdown title={label}>
            {error && (
                <div className="form-field-error">
                    <p>{error}</p>
                    <p>Modifiez les contrats pour que ce support ne soit plus utilisé</p>
                </div>
            )}
            <GridLayout className="triple condensed full-width">
                <TextField
                    name={`simulation.per.baseProducts.${product}.label`}
                    title="Nom"
                />
                <TextField
                    name={`simulation.per.baseProducts.${product}.codeIsin`}
                    title="Code ISIN"
                />
                <YesNoField
                    className="double"
                    name={`simulation.per.baseProducts.${product}.isEuroSupport`}
                    title="Fond Euros ?"
                />
                <PercentageFloatField
                    name={`simulation.per.baseProducts.${product}.lowRate`}
                    title="Taux min"
                />
                <PercentageFloatField
                    name={`simulation.per.baseProducts.${product}.rate`}
                    title="Taux"
                />
                <PercentageFloatField
                    name={`simulation.per.baseProducts.${product}.highRate`}
                    title="Taux max"
                />

                <Title><b>Annexes PDF</b></Title>
                <Field name={`simulation.per.baseProducts.${product}.documents`} component={uploadSetting} />
            </GridLayout>
            <hr />
            <button className="theseis-btn" onClick={deleteBaseProduct}>Supprimer le support</button>
        </Dropdown>
    );
};

PerSupportDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    types: PropTypes.shape({}).isRequired,
};

const PerSupport = ({ products, types }) => sortSettings(products)
    .map(([product, { documents, label }]) => (
        <PerSupportDropdown key={product} types={types} product={product} label={label} documents={documents} />
    ));

PerSupport.propTypes = {
    products: PropTypes.shape({}).isRequired,
    types: PropTypes.shape({}).isRequired,
};

export default PerSupport;
