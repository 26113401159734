import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import { formatFullDate } from 'common/utils/format';
import LogsTable from './LogsTable';

const SyncLog = ({ date, logs }) => (
    <Dropdown title={formatFullDate(date)} >
        <LogsTable logs={logs} />
    </Dropdown>
);

SyncLog.propTypes = {
    date: PropTypes.string.isRequired,
    logs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SyncLog;
