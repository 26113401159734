import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { formatAmount } from 'common/utils/format';
import computeInstalmentCosts from 'common/model/simulation/pinel/instalmentCosts';
import { GridLayout } from 'components/form/layout';
import { DisabledField, FloatField, IntegerField, TextField, YesNoField } from 'components/form/field';
import EditableField from '../../../EditableField';

const InnerOperationForm = ({
    className,
    ...simulation
}) => {
    // compute some
    const {
        instalmentCosts: {
            investmentWithTVA,
            defaultParkingNotaryFees,
            defaultParkingOtherAcquisitionCost,
            defaultNotaryFees,
            defaultOtherAcquisitionCost,
            taxBenefitBasis,
            yearlyRent,
        },
    } = computeInstalmentCosts({ ...simulation, data: simulation.data ? simulation.data : {} });

    localStorage.setItem('investmentWithTVA', investmentWithTVA);

    // Même test que dans le fichier formValidate mais pour un affichage du message d'erreur qui s'actualise en direct
    let checkWorkAmount = false;
    if (0 === simulation.montantTravaux) { checkWorkAmount = true; }
    if (simulation.montantTravaux) {
        if (simulation.montantTravaux < (investmentWithTVA * 0.25)) {
            checkWorkAmount = true;
        }
    }

    // get the rest from the simulation
    const {
        editableNotaryFees,
        editableOtherAcquisitionCost,
        parkingEditableNotaryFees,
        parkingEditableOtherAcquisitionCost,
        parkingLotWithSeparateLease,
    } = simulation;

    return (
        <div className={className}>
            <GridLayout className="condensed">
                {/* notary fields */}
                <TextField name="notaryName" title="Nom du notaire" />
                <TextField name="notaryCity" title="Ville du notaire" />
                <IntegerField name="propertyValue" title="Montant de l'immobilier (TTC)" min={0} required />
                {/* <IntegerField name="montantTravaux" title="Montant des travaux (TTC)" required /> */}
                <IntegerField name="montantTravaux" title="Montant des travaux (TTC)" required forceerror={checkWorkAmount} errortext="Le montant des travaux est inférieur à 25% de votre investissement" />
                {/* parking lot fields */}
                <FloatField name="parkingLotValue" title="Montant du parking (TTC)" min={0} required />
                <YesNoField name="parkingLotWithSeparateLease" title="Le parking fait-il objet d'un bail séparé" />
                {parkingLotWithSeparateLease ? (
                    <FloatField name="parkingLotRent" title="Loyer du parking" min={0} />
                ) : null}
                <EditableField
                    editable={parkingEditableNotaryFees}
                    name="parkingNotaryFees"
                    editableName="parkingEditableNotaryFees"
                    title="Frais de notaire (parking)"
                    defaultValue={defaultParkingNotaryFees}
                />
                <EditableField
                    editable={parkingEditableOtherAcquisitionCost}
                    name="parkingOtherAcquisitionCost"
                    editableName="parkingEditableOtherAcquisitionCost"
                    title="Autres frais d&apos;acquisition (parking)"
                    defaultValue={defaultParkingOtherAcquisitionCost}
                />
                {/* end of the parking lo fields */}
                <EditableField
                    editable={editableNotaryFees}
                    name="notaryFees"
                    editableName="editableNotaryFees"
                    title="Frais de notaire"
                    defaultValue={defaultNotaryFees}
                />
                <EditableField
                    editable={editableOtherAcquisitionCost}
                    name="otherAcquisitionCost"
                    editableName="editableOtherAcquisitionCost"
                    title="Autres frais d&apos;acquisition"
                    defaultValue={defaultOtherAcquisitionCost}
                />
                <DisabledField
                    title="Montant de l'investissement (TTC) + frais d'acquisition"
                    name="investmentWithTaxes"
                    value={formatAmount(investmentWithTVA)}
                />
                <EditableField
                    editable={get('data.taxBenefitBasis.edited', simulation)}
                    title="Montant de la base de calcul de l'avantage fiscal (TTC)"
                    name="data.taxBenefitBasis.value"
                    editableName="data.taxBenefitBasis.edited"
                    defaultValue={taxBenefitBasis}
                    max={300000}
                />
                <IntegerField
                    name="propertyMonthlyRent"
                    title="Loyer / Recette mensuel(le)s (TTC)"
                    required
                />
                <DisabledField
                    title="Loyer / Recette annuel(le)s (TTC)"
                    name="yearlyRent"
                    value={formatAmount(yearlyRent)}
                />
                <YesNoField
                    title="Avez-vous besoin d&apos;ajouter un financement&nbsp;?"
                    name="needFunding"
                />
            </GridLayout>
        </div>
    );
};

InnerOperationForm.propTypes = {
    className: PropTypes.string,
    parkingLotWithSeparateLease: PropTypes.bool,
    parkingLotValue: PropTypes.number,
    propertyValue: PropTypes.number,
    notaryFees: PropTypes.number,
    otherAcquisitionCost: PropTypes.number,
    propertyMonthlyRent: PropTypes.number,
    editableNotaryFees: PropTypes.bool,
    editableOtherAcquisitionCost: PropTypes.bool,
    propertyWeightedSurface: PropTypes.number,
    parkingEditableNotaryFees: PropTypes.bool,
    parkingEditableOtherAcquisitionCost: PropTypes.bool,
    parkingNotaryFees: PropTypes.number,
    parkingOtherAcquisitionCost: PropTypes.number,
    parkingLotRent: PropTypes.number,
    montantTravaux: PropTypes.number,
};

InnerOperationForm.defaultProps = {
    className: null,
    parkingLotWithSeparateLease: false,
    parkingLotValue: 0,
    propertyValue: 0,
    notaryFees: 0,
    otherAcquisitionCost: 0,
    propertyMonthlyRent: 0,
    editableNotaryFees: false,
    editableOtherAcquisitionCost: false,
    propertyWeightedSurface: 0,
    parkingEditableNotaryFees: false,
    parkingEditableOtherAcquisitionCost: false,
    parkingNotaryFees: 0,
    parkingOtherAcquisitionCost: 0,
    parkingLotRent: 0,
    montantTravaux: 0,
};

const OperationForm = formValues({
    parkingLotWithSeparateLease: 'parkingLotWithSeparateLease',
    propertyValue: 'propertyValue',
    notaryFees: 'notaryFees',
    otherAcquisitionCost: 'otherAcquisitionCost',
    propertyMonthlyRent: 'propertyMonthlyRent',
    parkingLotValue: 'parkingLotValue',
    editableNotaryFees: 'editableNotaryFees',
    editableOtherAcquisitionCost: 'editableOtherAcquisitionCost',
    propertyWeightedSurface: 'propertyWeightedSurface',
    parkingEditableNotaryFees: 'parkingEditableNotaryFees',
    parkingEditableOtherAcquisitionCost: 'parkingEditableOtherAcquisitionCost',
    parkingOtherAcquisitionCost: 'parkingOtherAcquisitionCost',
    parkingNotaryFees: 'parkingNotaryFees',
    parkingLotRent: 'parkingLotRent',
    editablePropertyTaxEstimated: 'editablePropertyTaxEstimated',
    propertyTaxEstimated: 'propertyTaxEstimated',
    product: 'product',
    montantTravaux: 'montantTravaux',
    venteImmeubleARenover: 'venteImmeubleARenover',
    data: 'data',
    needFunding: 'needFunding',
})(InnerOperationForm);

export default OperationForm;
