export const unsetPdfViewer = () => ({ type: 'UNSET_PDF_VIEWER' });

export const setPdfViewerFrame = (frame) => ({
    type: 'SET_PDF_VIEWER_FRAME',
    frame,
});

export const setPdfViewerPage = (page) => ({
    type: 'SET_PDF_VIEWER_PAGE',
    page,
});

export const setPdfViewerPages = (pages) => ({
    type: 'SET_PDF_VIEWER_PAGES',
    pages,
});
