import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withProps } from 'recompose';
import Input from './Input';

const UserPasswordNew = withProps({
    title: 'Votre nouveau mot de passe',
    name: 'password',
    type: 'password',
    info: 'Votre mot de passe doit être composé de au moins 8 caratères et contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial.',
})(Input);

const UserPasswordConfirmation = withProps({
    title: 'Confirmation du mot de passe',
    name: 'passwordConfirmation',
    type: 'password',
})(Input);

const UserPassword = ({ required }) => (
    <Fragment>
        <UserPasswordNew required={required} />
        <UserPasswordConfirmation required={required} />
    </Fragment>
);

UserPassword.defaultProps = {
    required: false,
};

UserPassword.propTypes = {
    required: PropTypes.bool,
};

export default UserPassword;
