const choicesEnvironmentalCriteria = {
    climateChange: ' L’atténuation du changement climatique',
    adaptationClimateChange: 'L’adaptation au changement climatique',
    useAndProtectAquaticResources: ' L’utilisation durable et la protection des ressources aquatiques et marines',
    transition: 'La transition vers une économie circulaire',
    pollutionPrevention: 'La prévention et la réduction de la pollution',
    protectionOfBiodiversity: 'La protection et la restauration de la biodiversité et des écosystèmes',
};

export default choicesEnvironmentalCriteria;
