import { flowRight, isEmpty } from 'lodash/fp';
import { branch, renderNothing, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { getSimulationSummary } from '../../actions';

export default flowRight([
    connect(({ simulationSummary }) => ({ simulationSummary }), { getSimulationSummary }),
    lifecycle({
        componentDidMount() {
            this.props.getSimulationSummary(this.props.params.simulationId);
        },
    }),
    branch(({ simulationSummary }) => isEmpty(simulationSummary), renderNothing),
]);
