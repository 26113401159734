import { branch, renderComponent, withProps } from 'recompose';
import Spinner from './Spinner';

// yes I know... it's almost exactly as the official documentation
const withSpinner = branch(
    ({ isLoading }) => isLoading,
    renderComponent(withProps({
        children: 'Merci de patienter, nous mettons à jour vos données',
    })(Spinner)),
);

export default withSpinner;
