// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rip-menu {
    position: absolute;
    bottom: 60px;
    right: 30px;
    text-align: right;
    z-index: 1000;
}

.rip-menu-dots {
    display: block;
    font-size: 6px;
    padding-top: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/Menu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".rip-menu {\n    position: absolute;\n    bottom: 60px;\n    right: 30px;\n    text-align: right;\n    z-index: 1000;\n}\n\n.rip-menu-dots {\n    display: block;\n    font-size: 6px;\n    padding-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
