// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.signature-state-container{
    width: 1170px;
    margin-left: 400px;
    font-size: 18px;
    color: #464646;
    margin-bottom: 30px;
    font-weight: 300;
    padding: 40px 40px;
}
.text-content{
    margin-left: 1.6em
}

.render-electronic-sign, .render-manual-sign{
    margin-bottom: 3em;
}
.title{
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/rip/signatureState/Form.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI;AACJ;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB","sourcesContent":["\n.signature-state-container{\n    width: 1170px;\n    margin-left: 400px;\n    font-size: 18px;\n    color: #464646;\n    margin-bottom: 30px;\n    font-weight: 300;\n    padding: 40px 40px;\n}\n.text-content{\n    margin-left: 1.6em\n}\n\n.render-electronic-sign, .render-manual-sign{\n    margin-bottom: 3em;\n}\n.title{\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
