import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { incomeRegimes } from '../../../common/choices';
import { ChoiceField, IntegerField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import { OwnerField } from '../field';

const LandRentalIncomeInner = ({ name, regime, ripFromRecommendation }) => {
    const regimeMicro = 'micro' === regime;

    return (
        <HorizontalLayout>
            <OwnerField name={`${name}.beneficiary`} title="Bénéficiaire(s)" ripFromRecommendation={ripFromRecommendation} required />
            <IntegerField name={`${name}.income`} title="Revenus locatifs annuels bruts" required />
            <ChoiceField name={`${name}.regime`} title="Régime" choices={incomeRegimes} required />
            {regime && <IntegerField name={`${name}.result`} title="Résultat foncier annuel" required={!regimeMicro} disabled={regimeMicro} />}
        </HorizontalLayout>
    );
};

LandRentalIncomeInner.propTypes = {
    name: PropTypes.string.isRequired,
    regime: PropTypes.string,
    ripFromRecommendation: PropTypes.shape({}),
};

const LandRentalIncome = formValues(({ name }) => ({
    regime: `${name}.regime`,
}))(LandRentalIncomeInner);

export default LandRentalIncome;
