// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.life-insurance-events .form-typed-collection-item-type {
    width: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    height: auto;
}

.life-insurance-events .form-typed-collection-buttons {
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/simulation/types/lifeInsurance/Product/PlannedEvents.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".life-insurance-events .form-typed-collection-item-type {\n    width: 100px;\n    flex-grow: 0;\n    flex-shrink: 0;\n    height: auto;\n}\n\n.life-insurance-events .form-typed-collection-buttons {\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
