// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-background {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #efefef;
    height: 110px;
}

tr.contact {
    height: 110px;
}

.table > tbody > tr.contact > td {
    padding: 0;
}

.contact ul li {
    font-size: 14px;
}

.contact a:hover {
    font-color: inherit;
}

.contact a {
    color: inherit;
}

.rip-contact-items {
    display: flex;
}

.contact-origin-edit {
    font-weight: bold;
    color: #26C9E6;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/Contact.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,OAAO;IACP,WAAW;IACX,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".contact-background {\n    position: absolute;\n    left: 0;\n    width: 100%;\n    background-color: #efefef;\n    height: 110px;\n}\n\ntr.contact {\n    height: 110px;\n}\n\n.table > tbody > tr.contact > td {\n    padding: 0;\n}\n\n.contact ul li {\n    font-size: 14px;\n}\n\n.contact a:hover {\n    font-color: inherit;\n}\n\n.contact a {\n    color: inherit;\n}\n\n.rip-contact-items {\n    display: flex;\n}\n\n.contact-origin-edit {\n    font-weight: bold;\n    color: #26C9E6;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
