import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import FormGroup from 'components/form/Group';
import { GridLayout } from 'components/form/layout';
import { ChoiceField, IntegerField, PercentageField, EditableField } from 'components/form/field';
import FieldInfo from 'components/form/FieldInfo';
import Title from 'components/Title';
import { periodicities } from 'common/choices';
import getSettings from 'common/utils/settings';

export const getAccountAvailabilityDate = (contractType, subscriptionDate) => {
    if (!contractType || !subscriptionDate) {
        return null;
    }

    const date = moment(subscriptionDate);

    const accountAvailability = getSettings(['simulation', 'lifeInsurance', 'types', contractType, 'accountAvailability']);

    if (accountAvailability) {
        date.add(accountAvailability, 'days');
    }

    return date;
};

const ScheduledPayments = ({ contractType, subscriptionDate }) => (
    <FormGroup>
        <Title><b>Versements programmés</b></Title>
        <GridLayout className="condensed">
            <IntegerField
                name="data.scheduledPayments.amount"
                title="Montant"
                required
            />
            <PercentageField
                name="data.scheduledPayments.fees"
                title="Frais d'entrée pour les montants programmés (%)"
            />
            <ChoiceField
                name="data.scheduledPayments.periodicity"
                title="Périodicité"
                choices={periodicities}
                required
            />
            <EditableField
                type="date"
                name="data.scheduledPayments.firstPaymentDate"
                editName="data.scheduledPayments.editableFirstPaymentDate"
                title={
                    <span>
                        Date du premier versement
                        {'pvp' === contractType ? (
                            <FieldInfo id="firstPaymentDate">
                                <p>
                                    Si vous souhaitez effectuer une souscription électronique alors
                                    la date de premier versement doit être supérieure
                                    au <b>Jour de saisie</b> de la souscription + 30 jours calendaires
                                    (délai de renonciation) + 6 jours ouvrés.
                                </p>
                            </FieldInfo>
                        ) : null}
                    </span>
                }
                defaultValue={getAccountAvailabilityDate(contractType, subscriptionDate)}
            />
        </GridLayout>
    </FormGroup>
);

ScheduledPayments.propTypes = {
    subscriptionDate: PropTypes.string,
    contractType: PropTypes.string,
};

export default formValues({
    subscriptionDate: 'data.subscriptionDate',
    contractType: 'data.contractType',
})(ScheduledPayments);
