import getMedianRate from '../model/simulation/lifeInsurance/getMedianRate';
import { formatPercentage } from '../utils/format';

const profileChoices = {
    P: `Prudent (${formatPercentage(getMedianRate().P)})`,
    E: `Équilibré  (${formatPercentage(getMedianRate().E)})`,
    DE: `Dynamique Équilibre Immo (${formatPercentage(getMedianRate().DE)})`,
    D: `Dynamique  (${formatPercentage(getMedianRate().D)})`,
};

export default profileChoices;
