import PropTypes from 'prop-types';
import React from 'react';
import { other as computeAmountOtherCredit } from 'common/model/summary/credits';
import { IntegerField, TextField } from '../../form/field';
import { HorizontalLayout } from '../../form/layout';
import { OwnerField } from '../field';
import Recurrent from '../Recurrent';
import DisabledTaxField from './DisabledTaxField';

const OtherCredit = ({ name }) => (
    <HorizontalLayout>
        <OwnerField name={`${name}.fiscalHome`} title="Concubin concerné" onlyCohabitation required single />
        <IntegerField name={`${name}.amount`} title="Montant du crédit d&apos;impôts" required />
        <TextField name={`${name}.description`} title="Précisez" />
        <Recurrent name={name} />
        <DisabledTaxField type="credit" name={name} computeAmount={computeAmountOtherCredit} />
    </HorizontalLayout>
);

OtherCredit.propTypes = {
    name: PropTypes.string.isRequired,
};

export default OtherCredit;
