import multipleChoiceQuestion from '../../../../../utils/multipleChoiceQuestion';

const choices = {
    actions: { point: [3] },
    risk: { point: [3] },
    products: { point: [4] },
    obligations: { point: [2] },
    realEstate: { point: [2] },
};

const question = (point, investorProfile) => multipleChoiceQuestion(investorProfile.investmentInFinancialSupport, choices);

export default question;
