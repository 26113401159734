import { flowRight, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { getContext, mapProps } from 'recompose';
import { Field } from 'redux-form';
import listProvider from '../../../list/listProvider';
import Error from '../../../Error';
import Simulation from './Simulation';

import './SimulationField.scss';

const SimulationFieldInner = ({
    formGroup: Group,
    items,
    input: { value, ...inputProps },
    meta,
    contractTypeState,
    beneficiaryTypeState,
    rip,
    setIsBeneficiarySign,
    ...props
}) => (
    <Group {...props} >
        <div className="simulation-field">
            <div className="head">
                <div />
                <div>Nom de la simulation</div>
                <div>Contrat</div>
                <div>Date de création</div>
                <div>Dernière modification</div>
            </div>
            {items.map((simulation) => (
                <Simulation
                    simulation={simulation}
                    key={simulation.id}
                    checked={value === simulation.id}
                    contractTypeState={contractTypeState}
                    beneficiaryTypeState={beneficiaryTypeState}
                    setIsBeneficiarySign={setIsBeneficiarySign}
                    rip={rip}
                    {...inputProps}
                />
            ))}
        </div>
        <Error {...meta} />
    </Group>
);

SimulationFieldInner.propTypes = {
    formGroup: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    input: PropTypes.shape({
        value: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    contractTypeState: PropTypes.shape({}).isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    beneficiaryTypeState: PropTypes.shape({}),
    rip: PropTypes.shape({}),
    setIsBeneficiarySign: PropTypes.func,
};

SimulationFieldInner.defaultProps = {
    items: [],
};

const SimulationField = flowRight([
    getContext({ formGroup: PropTypes.func.isRequired }),
    listProvider({
        sorts: {
            createdAt: { initialOrder: 'desc', default: true },
            name: { initialOrder: 'asc' },
        },
        name: 'simulation',
        type: 'simulation',
        filter: () => ({ product, data }) => ('per' === product
                && data
                && data.contractType),
        criteria: ({ rip: { id } }) => ({ ripId: id }),
    }),
    mapProps((props) => ({
        ...omit(['load', 'dispatch'], props),
        component: SimulationFieldInner,
    })),
])(Field);

export default SimulationField;
