import { get } from 'lodash/fp';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GET, POST, PATCH } from 'utils/httpMethods';
import fullName from 'common/utils/fullName';
import Icon from 'common/components/Icon';
import Spinner from 'components/Spinner';
import Title from 'components/Title';

import './Universign.scss';

class Universign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 'init',
            currentSigner: null,
            signerInfos: null,
            error: null,
        };

        this.onSignMessage = this.onSignMessage.bind(this);
        this.toNextSigner = this.toNextSigner.bind(this);
    }

    componentDidMount() {
        // start listening
        window.addEventListener('message', this.onSignMessage, false);

        this.getStatus()
            .then(async ({ initialized, completed, ...originalStatus }) => {
                if (!initialized) {
                    // we're going to create it
                    // inform the user because it may take some times
                    this.setState({ step: 'create' });

                    // then call the API
                    this.create()
                        .then(({ error, ...newStatus }) => {
                            if (error) {
                                // an error happened on our back
                                // it tries to tell us about it
                                this.setState({
                                    step: 'error.create',
                                    error,
                                });
                            } else {
                                const { currentSigner, signerInfos } = newStatus;

                                // update sign state
                                this.setState({
                                    step: 'sign.ongoing',
                                    currentSigner,
                                    signerInfos,
                                });
                            }
                        })
                        .catch(() => this.setState({ step: 'error.unknown' }));
                } else if (completed) {
                    this.processCompletion();
                } else {
                    const { currentSigner, signerInfos } = originalStatus;

                    // update sign state
                    this.setState({
                        step: 'pending-validation' === signerInfos[currentSigner].status
                            ? 'sign.pendingValidation'
                            : 'sign.ongoing',
                        currentSigner,
                        signerInfos,
                    });
                }
            })
            .catch(() => this.setState({ step: 'error.unknown' }));
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onSignMessage);
    }

    async onSignMessage(message) {
        if ('end' === get('data.type', message)) {
            // update the state
            this.setState({ step: 'sign.sync' });

            try {
                // update status
                const { completed, canceled, currentSigner, signerInfos } = await this.getStatus();

                if (canceled) {
                    // it means the current signer just cancelled it
                    this.setState({ step: 'cancel' });
                } else if (completed) {
                    this.processCompletion();
                } else if (currentSigner !== this.currentSigner.key) {
                    // we should wait to move on the next signer
                    this.setState({ step: 'sign.next' });
                } else if ('pending-validation' === signerInfos[currentSigner].status) {
                    // we should inform we're on pending validation
                    this.setState({ step: 'sign.pendingValidation' });
                } else {
                    // we should show back the iframe
                    this.setState({ step: 'sign.ongoing' });
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                this.setState({ step: 'error.unknown' });
            }
        }
    }

    get currentSigner() {
        const { currentSigner, signerInfos } = this.state;

        return {
            // we are going to set it up as a controlled component
            // we can also use it as an ID
            key: currentSigner,
            // spread all information from universign
            ...signerInfos[currentSigner],
        };
    }

    async getStatus() {
        // get the information from the server
        const response = await GET(this.props.signUrl);

        return response.json();
    }

    processCompletion() {
        // everybody has sign
        // inform the server

        PATCH(this.props.signUrl)
            .then(() => {
                // update the step
                this.setState({ step: 'done' });
                // call the complete hook
                this.props.onComplete();
            })
            .then(async () => {
                try {
                    await POST('/api/setHistoryLogs', {
                        body: {
                            docId: this.props.docId,
                            ripId: this.props.ripId,
                            docSign: this.props.docSign,
                            signers: this.props.signers,
                        },
                    });
                } catch (error) {
                    console.log('Universign ~ error => ', error);
                }
            });
    }

    async create() {
        let body = {};

        if (!this.props.completedDocument) {
            body.signers = this.props.signers;
        } else {
            const formData = new FormData();

            formData.append('signers', this.props.signers);
            formData.append('completedDocument', this.props.completedDocument);

            body = formData;
        }

        // call the url to create the transaction
        const response = await POST(this.props.signUrl, {
            body,
        });

        return response.json();
    }

    toNextSigner() {
        const { currentSigner } = this.state;

        this.setState({
            step: 'sign.ongoing',
            currentSigner: currentSigner + 1,
        });
    }

    render() {
        switch (this.state.step) {
            // first screen to display while waiting to know more about the current state
            case 'init':
                return (
                    <div className="sign-frame-loader">
                        <Spinner>Récupération des informations...</Spinner>
                    </div>
                );
            // waiting screen while the documents/transaction is being created by the backend
            case 'create':
                return (
                    <div className="sign-frame-loader">
                        <Spinner>Création du dossier...</Spinner>
                    </div>
                );
            // the transaction is completed, done
            case 'done':
                return (
                    <div className="container sign-frame-padding">
                        <div className="alert alert-success">
                            <Icon icon="check" className="fa-fw" />
                            &nbsp;
                            <span>Signature éléctronique complétée !</span>
                        </div>
                    </div>
                );
            // waiting screen between steps
            // the iframe is kept to keep universign running and eventually its errors
            case 'sign.sync': {
                const { url } = this.currentSigner;

                return (
                    <div className="sign-frame-loader ">
                        <Spinner>Récupération des informations...</Spinner>
                        <iframe
                            title="Signature universign"
                            src={url}
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts"
                            key="universign-frame"
                        />
                    </div>
                );
            }
            // screen on which we display the universign frame to let the user sign
            case 'sign.ongoing': {
                const { url, firstName, lastName } = this.currentSigner;

                return (
                    <div className="sign-frame">
                        <Title>Signature de <b>{fullName({ firstName, lastName })}</b></Title>
                        <iframe
                            title="Signature universign"
                            src={url}
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts"
                            key="universign-frame"
                        />
                    </div>
                );
            }
            // screen on which we wait for the user to click to move on the next step
            case 'sign.next': {
                const { firstName, lastName } = this.currentSigner;

                return (
                    <div className="container sign-frame-padding">
                        <Title>Signature de <b>{fullName({ firstName, lastName })}</b></Title>
                        <div className="alert alert-success">
                            <Icon icon="check" className="fa-fw" />
                            <span>Documents signés avec succès !</span>
                        </div>
                        <button type="button" className="btn" onClick={this.toNextSigner}>
                            <Icon icon="forward" className="fa-fw" />
                            &nbsp;Passer à la signature suivante
                        </button>
                    </div>
                );
            }
            // screen on which we inform we're waiting for the identity to be certified
            case 'sign.pendingValidation': {
                const { firstName, lastName } = this.currentSigner;

                return (
                    <div className="container sign-frame-padding">
                        <Title>Signature de <b>{fullName({ firstName, lastName })}</b></Title>
                        <div className="alert alert-warning">
                            <Icon icon="clock-o" className="fa-fw" />
                            <span>
                                Documents signés avec succès !
                                L&apos;identité est actuellement en cours de validation.
                            </span>
                        </div>
                    </div>
                );
            }
            // screen on which we inform the transaction has been cancelled
            case 'cancel':
                return (
                    <div className="container sign-frame-padding">
                        <div className="alert alert-danger">
                            <Icon icon="times" className="fa-fw" />&nbsp;
                            <span>Signature refusée ou annulée !</span>
                        </div>
                    </div>
                );
            // screen to show an error happening on the creation process
            case 'error.create': {
                const { error } = this.state;

                return (
                    <div className="container sign-frame-padding">
                        <div className="alert alert-warning">
                            <h3>La préparation de vos documents a échoué.</h3>
                            <p>Vous pouvez recharger la page pour recommencer.</p>
                            <p>Vous trouverez ci-dessous le message d&apos;erreur rencontré par l&apos;application.</p>
                            <hr />
                            <p className="text-muted">{error}</p>
                        </div>
                    </div>
                );
            }
            // screen to show unknown errors
            case 'error.unknown':
                return (
                    <div className="container sign-frame-padding">
                        <div className="alert alert-danger">
                            <Icon icon="exclamation-triangle" className="fa-fw" />
                            &nbsp;
                            <span>Une erreur est survenue</span>
                            <p className="text-muted">
                                Vous pouvez recharger la page pour recommencer ou contacter un administrateur.
                            </p>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }
}

Universign.propTypes = {
    docId: PropTypes.string,
    ripId: PropTypes.string,
    docSign: PropTypes.string,
    signUrl: PropTypes.string.isRequired,
    signers: PropTypes.arrayOf(PropTypes.string),
    completedDocument: PropTypes.shape({}),
    onComplete: PropTypes.func,
};

Universign.defaultProps = {
    signers: [],
    completedDocument: null,
    onComplete: () => { },
};

export default Universign;
