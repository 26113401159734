const flagUnleash = (state = {}, { type, ...payload }) => {
    switch (type) {
        case 'SET_DOCUMENT_CIF':
            return payload;
        default:
            return state;
    }
};

export default flagUnleash;
