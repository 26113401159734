import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { flowRight } from 'lodash/fp';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import fullName from 'common/utils/fullName';
import hasPartner from 'common/utils/hasPartner';
import Universign from 'components/Universign';
import Footer from 'components/rip/Footer';
import RipSignatureState from '../../../signatureState/Form';
import { useSession } from '../../../../sessions';
import { saveData } from '../../../../../actions';

import './ElectronicSignature.scss';

const ElectronicSignature = ({ rip, clientSigner, partnerSigner, saveData: save }) => {
    const session = useSession();
    const { instanceTheseis, instanceIdsoft, instanceInovia } = session;

    const universignSigners = [];
    if ('universign' === clientSigner) {
        universignSigners.push('client');
    }
    if ('universign' === partnerSigner) {
        universignSigners.push('partner');
    }

    let signatureTimeAndType = rip.clientAndPartnerSignatureDate;

    //  ** enregistrement de la demande de signature électronique
    useEffect(() => {
        universignSigners.forEach((signer) => {
            if ('client' === signer) {
                signatureTimeAndType = { ...signatureTimeAndType, client: { type: 'universign', date: Date.now() } };
                save('rip', rip.id, { clientAndPartnerSignatureDate: signatureTimeAndType });
            }
            if ('partner' === signer) {
                signatureTimeAndType = { ...signatureTimeAndType, partner: { type: 'universign', date: Date.now() } };
                save('rip', rip.id, { clientAndPartnerSignatureDate: signatureTimeAndType });
            }
        });
    }, [clientSigner, partnerSigner]);

    return (
        <Fragment>
            <div className="e-signature-container">
                <div style={{ display: 'none' }}>
                    <Universign
                        docId={rip.id}
                        docSign="rip"
                        ripId={rip.id}
                        signers={universignSigners}
                        signUrl={`/api/rips/${rip.id}/sign`}
                    />
                </div>
                {(instanceIdsoft || instanceInovia) && (
                    <Alert variant="info">
                        <h2>Une transaction a été ouverte auprès d&apos;universign !</h2>
                        <hr />
                        <p>Un mail va être envoyé aux signataires dans l&apos;ordre suivant :</p>
                        <ul>
                            {'universign' === clientSigner ? <li>{fullName(rip)}</li> : null}
                            {(hasPartner(rip.familySituation) && 'universign' === partnerSigner) ? (
                                <li>
                                    {fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName })}
                                </li>
                            ) : null}
                        </ul>
                        <hr />
                        <p>Le lien généré est valable 14 jours.</p>
                        <p>Pour information, tant que le signataire précédent n’a pas effectué sa signature, le signataire suivant ne reçoit pas le mail lui permettant de signer à son tour.</p>
                    </Alert>)
                }
                {instanceTheseis && (
                    <div>
                        <RipSignatureState
                            back={`/rip/${rip.id}/commitment`}
                            id={rip.id}
                            rip={rip}
                            last={false}
                            nextRedirectTo
                            clientSigner={clientSigner}
                            partnerSigner={partnerSigner}
                            isElectronicSign
                        />
                    </div>
                )}
            </div>
            {instanceIdsoft && (<Footer back={`/rip/${rip.id}/commitment`} nextRedirectTo={`/rip/${rip.id}/appointment`} />)}
        </Fragment>
    );
};

ElectronicSignature.propTypes = {
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        id: PropTypes.string,
        clientAndPartnerSignatureDate: PropTypes.shape({}),
    }),
    clientSigner: PropTypes.string.isRequired,
    partnerSigner: PropTypes.string.isRequired,
    saveData: PropTypes.func.isRequired,
};

export default flowRight([
    connect(null, { saveData }),
])(ElectronicSignature);
