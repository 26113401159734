import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Wizard from './Wizard';
import ProfileDescription from './ProfileDescription';

const InvestorProfile = ({ investorProfile, ...props }) => (
    <Fragment>
        <Wizard investorProfile={investorProfile} />
        <ProfileDescription investorProfile={investorProfile} {...props} />
    </Fragment>
);

InvestorProfile.propTypes = {
    investorProfile: PropTypes.string.isRequired,
};

export default InvestorProfile;
