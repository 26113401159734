import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { branch, mapProps, renderNothing } from 'recompose';
import { formValues } from 'redux-form';
import getOwners from 'common/utils/getOwners';
import { SelectField } from '../../form/field';

const OwnerInner = ({ options, ...props }) => (
    <SelectField {...props}>
        <option />
        {options.map(({ value, text }) => <option key={value} value={value}>{text}</option>)}
    </SelectField>
);

OwnerInner.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })).isRequired,
};

const Owner = flowRight([
    formValues({
        firstName: 'firstName',
        partnerFirstName: 'partnerFirstName',
        familySituation: 'familySituation',
        children: 'children',
        sci: 'sci',
    }),
    mapProps(({
        child,
        children,
        familySituation,
        firstName,
        partnerFirstName,
        sci,
        showSci,
        single,
        onlyCohabitation,
        onlyCouple,
        pensionComponent,
        goingRetiredComponent,
        ripFromRecommendation,
        ...props
    }) => {
        const rip = ripFromRecommendation ?
            {
                firstName: ripFromRecommendation.firstName,
                partnerFirstName: ripFromRecommendation.partnerFirstName,
                familySituation: ripFromRecommendation.familySituation,
                children: ripFromRecommendation.children,
                sci: ripFromRecommendation.sci,
            } : {
                firstName,
                partnerFirstName,
                familySituation,
                children,
                sci,
            };

        const options = { child, showSci, single, onlyCohabitation, onlyCouple };

        return {
            ...props,
            options: getOwners(rip, options, pensionComponent, goingRetiredComponent),
        };
    }),
    branch(
        ({ options }) => options.length < 2,
        renderNothing,
    ),
])(OwnerInner);

export default Owner;
