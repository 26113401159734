import PropTypes from 'prop-types';
import React from 'react';

const InformationsInstance = ({ nom, adresse, label, emailSupport, informationsFacture }) => {
    const { capital, adressePostale, tel, RCS, APE, orias } = informationsFacture;

    return (
        <div className="container disclaimer">
            <h3> Informations communiquées préalablement à la présentation d&apos;un contrat d&apos;assurance&nbsp;: </h3>
            <p className="paragraph">
                Vous avez émis le souhait d&apos;obtenir une réponse de professionnels contrôlés exerçant une activité réglementée. Conformément aux principes de la loi du 6 janvier 1978 relative à l&apos;informatique aux fichiers et aux libertés nous vous rappelons que la présente collecte d&apos;information ainsi que leur exploitation sont effectuées dans votre intérêt et ont pour finalité de permettre au professionnel de fournir un conseil adapté à votre problématique. La qualité de son travail dépendra étroitement de la véracité, de l&apos;exactitude et de l&apos;exhaustivité de vos réponses à ce questionnaire. Votre conseiller s&apos;est engagé au respect de règles de bonne conduite et de déontologie issues du décret n° 2015-1090 du 28 août 2015, incluant le secret professionnel et la protection de vos données personnelles.
            </p>
            <p className="paragraph">
                {label} ({capital}) {adressePostale}. Tél&nbsp;:&nbsp;{tel} - Mail&nbsp;:&nbsp;{emailSupport} RCS&nbsp;{RCS} - APE&nbsp;{APE} {orias ? `${label} membre ORIAS ${orias}` : null}
            </p>
            {adresse &&
                <p className="paragraph">
                    {adresse}
                </p>}
            {orias &&
                <p className="paragraph">
                    Pour vérifier l&apos;immatriculation ORIAS, vous pouvez consulter le site de l&apos;ORIAS&nbsp;: <a href="www.orias.fr">www.orias.fr</a>. Pour toute réclamation, veuillez-vous adresser à {nom} ou THESEIS aux adresses ci-dessus. Pour votre information, notre autorité de contrôle est l&apos;ACPR, Autorité de Contrôle Prudentiel et de Résolution, 4, place de Budapest C92459 75436 PARIS CEDEX 09 Tél.&nbsp;: 01.55.50.41.41 - Adresse des médiateurs de la consommation&nbsp;: vous pouvez consulter la liste actualisée des Médiateurs sur le site suivant&nbsp;: <a href="http://www.economie.gouv.fr/mediation-conso">http://www.economie.gouv.fr/mediation-conso</a>.
                    Conformes aux articles L520-1, II, 1°,b, R520-1 et R520-2 du Code des Assurances&nbsp;: THESEIS est un intermédiaire en assurances exerçant selon les dispositions de l&apos;article L520-1, II, 1°, b du Code des Assurances, non soumis à une obligation contractuelle de travailler exclusivement avec une ou plusieurs entreprises d&apos;assurances. Je certifie que les renseignements figurant sur les pages de ce document sont sincères et exacts.
                </p>}
        </div>
    );
};

InformationsInstance.propTypes = {
    nom: PropTypes.string.isRequired,
    adresse: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    emailSupport: PropTypes.string.isRequired,
    informationsFacture: PropTypes.shape({
        capital: PropTypes.string,
        adressePostale: PropTypes.string,
        tel: PropTypes.string,
        RCS: PropTypes.string,
        APE: PropTypes.string,
        orias: PropTypes.string,
    }),
};

export default InformationsInstance;
