import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { withProps, compose } from 'recompose';
import computeInvestment from 'common/model/simulation/dismemberedScpi/computeInvestment';
import { formatAmount } from 'common/utils/format';
import { DisabledField } from 'components/form/field';

const InvestmentInner = ({ amount }) => (
    <DisabledField
        name="data.investmentAmount"
        title="Montant estimé de l'investissement"
        value={formatAmount(amount, true)}
    />
);

InvestmentInner.propTypes = {
    amount: PropTypes.number.isRequired,
};

const Investment = compose(
    formValues({ products: 'data.products', duration: 'data.duration' }),
    withProps(({ products, duration }) => ({ amount: computeInvestment(duration)(products) })),
)(InvestmentInner);

export default Investment;
