import { mapValues, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { GET, POST } from 'utils/httpMethods';
import ConnectedNextButton from 'components/FormButtons/buttons/ConnectedNextButton';
import FormButtons from '../../../FormButtons';
import useSettings from '../../../sessions/useSettings';
import Form from '../../../simulation/FormStep';
import contractLoader from '../../contractLoader';
import getWizard from '../wizardData';
import onSubmitRedirect from '../onSubmitRedirect';
import DocumentsReader from './DocumentsReader';

const DocumentsInner = ({
    handleSubmit,
    contract: {
        simulation: {
            data: {
                contractType,
            },
        },
    },
    history,
    documents,
    ...props
}) => {
    const { params: { id } } = props;
    const { name: contractName } = useSettings(['simulation', 'per', 'contracts', contractType]);
    const wizard = getWizard(contractType);

    return (
        <Form
            handleSubmit={handleSubmit}
            wizard={wizard}
        >
            <div className="simulation-form-inner" id="simulationFormInner">
                <div className="container">
                    <p className="text-help">
                        Bienvenue dans votre module de souscription avec signature électronique du
                        contrat <b>{contractName}</b> pour votre client.
                    </p>
                    <p className="text-help">
                        Préalablement à la souscription du contrat et dans le cadre de la réglementation, vous devez
                        faire prendre connaissance et valider avec votre client l&apos;ensemble des documents suivants :
                    </p>
                    <ul className="margin-bottom-40 text-help">
                        {Object.entries(documents).map(([key, { filename }]) => (
                            <li key={key}>
                                {filename}
                            </li>
                        ))}
                    </ul>
                    <p>
                        Vous pouvez préconiser à votre client de l&apos;imprimer ou de les sauvegarder sur un support
                        durable.
                    </p>
                    <DocumentsReader documents={documents} />
                </div>
            </div>
            <FormButtons
                back={`/rip/${id}/contracts/`}
                list={`/rip/${id}/contracts/`}
                nextButtonComponent={ConnectedNextButton}
            />
        </Form>
    );
};

DocumentsInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    documents: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    contract: PropTypes.shape({
        simulation: PropTypes.shape({
            data: PropTypes.shape({
                contractType: PropTypes.string,
            }),
        }),
    }).isRequired,
};

const Documents = compose(
    contractLoader,
    withState('documents', 'setDocuments', {}),
    lifecycle({
        componentDidMount() {
            const { setDocuments, params: { contractId } } = this.props;

            GET(`/api/contracts/${contractId}/timestamp`)
                .then((response) => response.json())
                .then(setDocuments);
        },
    }),
    withPropsOnChange(['documents'], ({ documents }) => ({
        initialValues: {
            documents: mapValues(({ processed }) => processed, documents),
        },
    })),
    reduxForm({
        form: 'contract-documents',
        enableReinitialize: true,
        validate: ({ documents = {} }) => {
            for (const [doc, value] of Object.entries(documents)) {
                if (!value) {
                    return {
                        documents: {
                            [doc]: 'Vous devez lire et accepter le document pour continuer',
                        },
                    };
                }
            }

            return {};
        },
        onSubmit: onSubmitRedirect,
        onChange: (
            { documents = {} },
            dispatch,
            { documents: originalDocuments },
            { documents: previousDocuments },
        ) => {
            Object.entries(documents).forEach(([doc, value]) => {
                const { [doc]: { url, processed } } = originalDocuments;

                if (!processed && value && value !== get(doc, previousDocuments)) {
                    POST(url);
                }
            });
        },
    }),
)(DocumentsInner);

export default Documents;
