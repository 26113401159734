import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { reduxForm, Field } from 'redux-form';
import { downloadPdf } from 'actions';
import { POST } from 'utils/httpMethods';
import Group from 'components/form/Group';
import RadioGroup from 'components/form/RadioGroup';
import VerticalRadioButton from 'components/form/VerticalRadioButton';
import dataProvider from '../dataProvider';

import './DocumentUploader.scss';
import withRouter from '../withRouter';

class DocumentUploader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputFileName: null,
        };

        this.formRef = React.createRef();

        this.onInputFileChange = this.onInputFileChange.bind(this);
    }

    onInputFileChange(evt) {
        try {
            const inputFileName = evt.target.value.split('\\').pop();
            this.setState({ inputFileName });
        } catch (error) {
            this.setState({ inputFileName: null });
            console.error(error);
        }
    }

    render() {
        const {
            handleSubmit,
            rip: { familySituation, firstName, lastName, partnerFirstName, partnerLastName },
        } = this.props;
        const { inputFileName } = this.state;

        return (
            <form className="upload" onSubmit={handleSubmit} ref={this.formRef}>
                <div>
                    <Field
                        name="template"
                        type="file"
                        id="template"
                        className="inputfile"
                        component={(props) => (
                            <input
                                {...props}
                                onChange={(e) => {
                                    this.onInputFileChange(e);
                                    props.input.onChange(e);
                                }}
                            />
                        )}
                    />

                    <label htmlFor="template">
                        <figure>
                            <i className="fa fa-upload" aria-hidden="true" />
                        </figure>
                        <span>{inputFileName || 'Charger un template'}</span>
                    </label>
                </div>
                {'cohabitation' === familySituation && (
                    <fieldset className="cohabitation">
                        <h4>Pour quel concubin souhaitez-vous générer le document ?</h4>
                        <Group name="ownerSelection">
                            <RadioGroup name="ownerSelection">
                                <VerticalRadioButton
                                    className="owner"
                                    title={`${firstName} ${lastName} (client)`}
                                    value="cohabitationClient"
                                />
                                <VerticalRadioButton
                                    className="owner"
                                    title={`${partnerFirstName} ${partnerLastName} (conjoint)`}
                                    value="cohabitationPartner"
                                />
                                <VerticalRadioButton className="owner" title="Les deux" value="both" />
                            </RadioGroup>
                        </Group>
                    </fieldset>
                )}
                {['partner', 'married'].includes(familySituation) && (
                    <fieldset className="cohabitation">
                        <h4>Pour qui souhaitez-vous générer le document ?</h4>
                        <Group name="ownerSelection">
                            <RadioGroup name="ownerSelection">
                                <VerticalRadioButton
                                    className="owner"
                                    title={`${firstName} ${lastName} (client)`}
                                    value="client"
                                />
                                <VerticalRadioButton
                                    className="owner"
                                    title={`${partnerFirstName} ${partnerLastName} (conjoint)`}
                                    value="partner"
                                />
                                <VerticalRadioButton className="owner" title="Les deux" value="both" />
                            </RadioGroup>
                        </Group>
                    </fieldset>
                )}
                <button type="submit" className="white-btn main">
                    Générer le livret CIF
                </button>
            </form>
        );
    }
}

DocumentUploader.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    rip: PropTypes.shape({
        familySituation: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
    }).isRequired,
};

export default compose(
    dataProvider({
        id: ({ ripId: id }) => id,
        type: 'rip',
        wait: true,
    }),
    withRouter,
    reduxForm({
        form: 'document-uploader',
        onSubmit: async ({ template, ownerSelection: ripOwner = null }, dispatch, { ripId, navigate }) => {
            const formData = new FormData();

            formData.append('template', template[0]);
            formData.append('ripOwner', ripOwner);
            formData.append('ripId', ripId);

            dispatch(downloadPdf('/api/documents/parse', navigate, { method: POST, data: formData }));
        },
    }),
)(DocumentUploader);
