const defaultState = {
    errors: {},
    showErrors: false,
};

const formValidation = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case 'FORM_VALIDATION_ERRORS_SET':
            return { ...state, errors: payload.errors };
        case 'FORM_VALIDATION_DISPLAY_SET':
            return { ...state, showErrors: payload.showErrors };
        default:
            return state;
    }
};

export default formValidation;
