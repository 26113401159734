import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Donation from './Donation';
import Education from './Education';
import Investment from './Investment';
import Subscription from './Subscription';

const Reductions = ({ name }) => (
    <Fragment>
        <Donation name={`${name}.donation`} />
        <hr />
        <Education name={`${name}.education`} />
        <hr />
        <Investment name={`${name}.investment`} />
        <hr />
        <Subscription name={`${name}.subscription`} />
    </Fragment>
);

Reductions.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Reductions;
