import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withContext } from 'recompose';
import GridGroup from '../group/Grid';

import './Grid.scss';

const GridInner = ({ children, className }) => (
    <div className={classNames('form-layout-grid', className)}>
        {children}
    </div>
);

GridInner.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

GridInner.defaultProps = {
    className: null,
};

const Grid = withContext({
    formGroup: PropTypes.func.isRequired,
}, () => ({
    formGroup: GridGroup,
}))(GridInner);

export default Grid;
