import PropTypes from 'prop-types';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import List from '../../../signature/List';
import Creation from '../../../signature/Creation';
import Documents from '../../../signature/Documents';
import Edition from '../../../signature/Edition';
import Signatures from '../../../signature/Signatures';

const Router = ({ prefix = '' }) => (
    <Routes>
        <Route exact path={`${prefix}/signatures`} component={List} />
        <Route exact path={`${prefix}/signatures/new`} component={Creation} />
        <Route exact path={`${prefix}/signatures/:id/documents`} component={Documents} />
        <Route exact path={`${prefix}/signatures/:id/edition`} component={Edition} />
        <Route exact path={`${prefix}/signatures/:id/signature`} component={Signatures} />
    </Routes>
);

Router.propTypes = {
    prefix: PropTypes.string,
};

export default Router;
