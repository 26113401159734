// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h6.subsection-title {
    font-size: 16px;
    text-decoration: underline;
}

.lastButtonRipForm {
    margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/appointment/Form.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["h6.subsection-title {\n    font-size: 16px;\n    text-decoration: underline;\n}\n\n.lastButtonRipForm {\n    margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
