import { get, isEmpty, isFinite, set } from 'lodash/fp';

const errorString = 'Vous devez renseigner ce champ.';

const checkRequiredIntegerField = (field) => (errors, values) => {
    const value = get(field, values);

    return (!isEmpty(value) || isFinite(value))
        ? errors
        : set(field, errorString, errors);
};

export default checkRequiredIntegerField;
