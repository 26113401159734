import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import EmployeeAtHome from './EmployeeAtHome';
import ChildCare from './ChildCare';
import HomeWork from './HomeWork';

const Credits = ({ name }) => (
    <Fragment>
        <EmployeeAtHome name={`${name}.employeeAtHome`} />
        <hr />
        <ChildCare name={`${name}.childCare`} />
        <hr />
        <HomeWork name={`${name}.homeWork`} />
    </Fragment>
);

Credits.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Credits;
