import { filter, flow, map } from 'lodash/fp';
import getTaxFromSlices from './getTaxFromSlices';
import hasPartner from '../../utils/hasPartner';

const rates = [
    {
        min: 0,
        rate: 0.15,
    },
    {
        min: 38120,
        rate: 0.28,
    },
    {
        min: 500000,
        rate: 0.31,
    },
];

const computeSci = (rip) => ({
    sci: flow([
        filter(({ type, owner, taxableProfit }) => ('sciIS' === type && parseInt(taxableProfit, 10) > 0) &&
            !('partner' === owner && !hasPartner(rip.familySituation))),
        map(({ sciName, taxableProfit }) => ({
            name: sciName,
            tax: getTaxFromSlices(parseInt(taxableProfit, 10), rates).tax,
        })),
    ])(rip.sci),
});

export default computeSci;
