import React from 'react';
import { setDisplayName, wrapDisplayName } from 'recompose';
import { FormName } from 'redux-form';

const withFormName = (WrappedComponent) => {
    const Component = (props) => (
        <FormName>
            {({ form }) => <WrappedComponent {...props} form={form} />}
        </FormName>
    );

    return setDisplayName(wrapDisplayName(Component, 'withFormName'))(Component);
};

export default withFormName;
