import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { Header, Main, Modal } from './sections';
import { FooterElementProvider } from './footerContext';

import './Layout.scss';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.footerRef = createRef();
    }

    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        const { children, className, header, ...props } = this.props;

        return (
            <div className={classNames(className, 'layout')}>
                <Header {...props}>{header}</Header>
                <Main {...props}>
                    {this.footerRef.current && (
                        <FooterElementProvider value={this.footerRef.current}>
                            {children}
                        </FooterElementProvider>
                    )}
                </Main>
                <div ref={this.footerRef} />
                <Modal />
            </div>
        );
    }
}

Layout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    header: PropTypes.node,
};

Layout.defaultProps = {
    className: null,
    children: null,
    header: null,
};

export default Layout;
