import createSimpleValidator from './createSimpleValidator';

const errorString = 'Vous devez entrer un email valide.';
const mailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line no-useless-escape

const checkIsEmail = createSimpleValidator(
    (value) => mailRegex.test(value),
    errorString,
);

export default checkIsEmail;
