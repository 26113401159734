const caracterMap = Object.entries({
    a: ['à', 'â'],
    c: ['ç'],
    e: ['é', 'è', 'ê'],
    i: ['î', 'ï'],
    o: ['ô'],
    oe: ['œ'],
    u: ['ù', 'û', 'ü'],
});

const tokenize = (text) => text
    .split(' ')
    .filter((word) => word.length > 1)
    .map((word) => {
        let token = word.toLowerCase();

        caracterMap.forEach(([to, froms]) => {
            froms.forEach((from) => {
                token = token.replace(new RegExp(from, 'g'), to);
            });
        });

        return token;
    });

export default tokenize;
