import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withPropsOnChange } from 'recompose';
import computeInstalmentCosts from 'common/model/simulation/lmnp/instalmentCosts';
import computeFundingAmount from 'common/model/simulation/common/fundingAmount';
import dataForm from 'components/dataForm';
import withFormName from '../../../form/withFormName';
import Form from './Form';
import simulationLoader from '../../simulationLoader';
import FormButtons from '../../../FormButtons';
import FundingForm from '../common/funding';
import SimulationHeader from './Header';

const FundingInner = ({
    handleSubmit,
    params: { id, simulationId },
    needFunding,
    fundingAmount,
    instalmentCosts,
}) => (
    <Form handleSubmit={handleSubmit} needFunding={needFunding}>
        <div className="simulation-form-inner">
            <div className="container">
                <SimulationHeader />
                <FundingForm fundingAmount={fundingAmount} instalmentCosts={instalmentCosts} />
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/bien`}
            list={`/rip/${id}/simulations/`}
        />
    </Form>
);

FundingInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    needFunding: PropTypes.bool,
    fundingAmount: PropTypes.number,
    instalmentCosts: PropTypes.number,
};

const Funding = flowRight([
    simulationLoader,
    dataForm({
        form: 'simulation-funding',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => navigate(`/rip/${id}/simulations/${simulationId}/charges`),
        type: 'simulation',
    }),
    withFormName,
    connect((state, { form }) => ({ simulation: getFormValues(form)(state) })),
    withPropsOnChange(['simulation'], (({ simulation }) => {
        const { needFunding } = simulation;
        const { instalmentCosts } = computeInstalmentCosts(simulation);
        const { fundingAmount } = computeFundingAmount(simulation, { instalmentCosts });

        return { instalmentCosts, fundingAmount, needFunding };
    })),
])(FundingInner);

export default Funding;
