const index = {
    pinel: { label: 'Pinel' },
    pinelR: { label: 'Pinel réhabilitation ' },
    lmnp: { label: 'LMNP' },
    scpi: { label: 'SCPI' },
    commum: { label: 'Régime commun (déficit foncier)' },
    malraux: { label: 'Malraux' },
    demembrement: { label: 'Démembrement ' },
    monument: { label: 'Monument historique ' },
    lmp: { label: 'LMP' },
};

export default index;
