// Déductions

import { concat, filter, flow, forEach, has, map, get } from 'lodash/fp';
import moment from 'moment';
import { parseInteger, sumOrAdd } from '../utils';

const childAlimonyIncrease = 1.25;

const alimony = (deduction, settings) => {
    const { sameRoof: sameRoofCeil, default: defaultCeil } = get('summary.deductions.alimonyCeils', settings);
    const { id, amount, alimonyType, adultChildren, childrenCount } = deduction;
    let reportedAmount = parseInteger(amount);
    const ceil = (adultChildren ? defaultCeil : Infinity) * childrenCount;
    let computedAmount = 0;

    switch (alimonyType) {
        case 'withVerdict': {
            const { date } = deduction;

            computedAmount = date && 2006 > moment(date).year()
                ? Math.min(reportedAmount * childAlimonyIncrease, ceil)
                : Math.min(reportedAmount, ceil);

            break;
        }
        case 'withoutVerdict':
            computedAmount = Math.min(reportedAmount, ceil);
            break;
        case 'sameRoof':
            reportedAmount = sameRoofCeil * childrenCount;
            computedAmount = sameRoofCeil * childrenCount;
            break;
        default:
            break;
    }

    return { id, reportedAmount, computedAmount };
};

const identityAmount = ({ id, amount }) => ({
    id,
    reportedAmount: amount,
    computedAmount: parseInteger(amount),
});

const getDeduction = {
    alimony,
    other: identityAmount,
    otherEstate: identityAmount,
    retirement: identityAmount,
};

export const availableEstateForDeductions = [
    'recenteredRobien',
    'Robien',
    'Besson',
    'Perrissol',
];

const computeDeductions = (rip, _, settings) => {
    let deductions = 0;
    let deductionsDetails = {};

    flow([
        filter(({ type }) => has(type, getDeduction)),
        forEach((deduction) => {
            const element = getDeduction[deduction.type](deduction, settings);
            const { computedAmount } = element;
            deductionsDetails = sumOrAdd(deductionsDetails, deduction.type, element);
            deductions += computedAmount;
        }),
    ])(concat(
        rip.deductions,
        // deductions from estate
        flow([
            filter(({ type, fiscalFramework, detaxType }) => 'rentalProperty' === type
                && (availableEstateForDeductions.includes(fiscalFramework)
                || ('other' === fiscalFramework && 'deduction' === detaxType))),
            map(({ detaxAmount }) => ({
                type: 'otherEstate',
                amount: detaxAmount,
            })),
        ])(rip.property),
    ));

    return { deductions, deductionsDetails };
};

export default computeDeductions;
