import getOwners from '../../utils/getOwners';
import checkRequiredField from './checkRequiredField';

const checkRequiredOwnerField = (field, options) => {
    const validator = checkRequiredField(field);

    return (errors, values) => {
        if (values.hasPartner) {
            return validator(errors, values);
        }
        const owners = getOwners(values, options);

        return owners.length > 1 ? validator(errors, values) : errors;
    };
};

export default checkRequiredOwnerField;
