import React from 'react';
import { GridLayout } from 'components/form/layout';
import { PercentageFloatField } from 'components/form/field';

const CreditSettings = () => (
    <GridLayout className="double condensed full-width">
        <PercentageFloatField name="calculator.credit.loanRate20" title="Taux des prêts sur 20 ans" />
        <PercentageFloatField name="calculator.credit.loanRate25" title="Taux des prêts sur 25 ans" />
    </GridLayout>
);

export default CreditSettings;
