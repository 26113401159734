import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { pick } from 'lodash/fp';
import { Layout } from 'components/layouts';
import { GET, PATCH } from 'utils/httpMethods';
import VariousForm from '../various/EditForm';
import VariousEntityForm from '../variousEntity/SettingEntity';
import SimulationForm from '../simulation/EditForm';
import SummaryForm from '../summary/EditForm';
import DocumentsForm from '../documents/EditForm';
import { withSession } from '../../../sessions';
import ifSettingsNullCheckSettingsAncestor from '../../../../common/utils/getAncestorSettings';
import withRouter from '../../../withRouter';

const types = {
    various: {
        types: ['global', 'finance', 'calculator'],
        component: VariousForm,
    },
    simulation: { component: SimulationForm },
    summary: { component: SummaryForm },
    documents: {
        component: DocumentsForm,
        types: ['global.documents'],
    },
};

const typesForEntity = {
    various: {
        types: ['settings'],
        component: VariousEntityForm,
    },
};

const handleGetRequest = async (url) => {
    const response = await GET(url);
    const data = await response.json();

    return data;
};

const renderForm = (group, navigate, session, settingsGlobal, settingsEntity) => {
    let Form;
    let properties;

    if (session.isAdmin) {
        Form = types[group].component;
        properties = types[group].types || group;
    } else if (session.isAdminReseau) {
        Form = typesForEntity[group].component;
    }

    const consentementGlobal = settingsGlobal && settingsGlobal.global.legalDisclaimer.consentement;
    const legalDisclaimerRipGlobal = settingsGlobal && settingsGlobal.global.legalDisclaimer.clientPdf;

    const legalDisclaimerRipEntity = settingsEntity && settingsEntity.legalDisclaimerRip;
    const consentementEntity = settingsEntity && settingsEntity.consentement;

    const newLegalDisclaimerRipValue = {};
    Object.defineProperty(newLegalDisclaimerRipValue, 'legalDisclaimerRip', {
        value: legalDisclaimerRipEntity || legalDisclaimerRipGlobal,
        writable: true,
        enumerable: true,
        configurable: true,
    });

    const newConsentementValue = {};
    Object.defineProperty(newConsentementValue, 'consentement', {
        value: consentementEntity || consentementGlobal,
        writable: true,
        enumerable: true,
        configurable: true,
    });

    const settings = { legalDisclaimerRip: newLegalDisclaimerRipValue, consentement: newConsentementValue };

    const handleSubmit = (body) => PATCH('/api/settings', { body });
    const handleSubmitEntitySettings = (body) => PATCH(`/api/entities/${session.entity.id}/settings`, { body });

    return (
        <Form
            initialValues={!session.isAdminReseau ? pick(properties, settingsGlobal) : settings}
            onSubmit={session.isAdminReseau ? handleSubmitEntitySettings : handleSubmit}
            onSubmitSuccess={() => navigate('/admin/settings')}
        />
    );
};

const EditInner = ({ navigate, params: { group }, session }) => {
    const [settingsGlobal, setSettingsGlobal] = useState(null);
    const [settingsEntity, setSettingsEntity] = useState({ legalDisclaimerRip: '', consentement: '' });

    useEffect(() => {
        (async () => {
            const entitiesList = await handleGetRequest('/api/entities');
            const entityData = await handleGetRequest(`/api/entities/${session.entity.id}/settings`);

            // vérification de la value d'un paramètre dans l'arboresence familiale
            const legalDisclaimerRip = await ifSettingsNullCheckSettingsAncestor('legalDisclaimerRip', entityData.legalDisclaimerRip, session.entity.id, entitiesList.items, handleGetRequest);
            const consentement = await ifSettingsNullCheckSettingsAncestor('consentement', entityData.consentement, session.entity.id, entitiesList.items, handleGetRequest);

            setSettingsEntity({ legalDisclaimerRip, consentement });

            // timeout en place pour retarder l'initialisation de la vriable "globalData"
            // afin de laisser le temps à la fonction "ifSettingsNullCheckSettingsAncestor" de vérifier la présence de paramètre réseau
            setTimeout(async () => {
                const globalData = await handleGetRequest('/api/settings');
                setSettingsGlobal(globalData);
            }, 1000);
        })();
    }, []);

    return (
        <Layout hasFooter>
            {settingsGlobal ? (
                renderForm(group, navigate, session, settingsGlobal, settingsEntity)
            ) : null}
        </Layout>
    );
};

EditInner.propTypes = {
    navigate: PropTypes.shape({}).isRequired,
    params: PropTypes.shape({
        group: PropTypes.string.isRequired,
    }).isRequired,
    session: PropTypes.shape({
        entity: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
};

const Edit = compose(
    withSession,
    withRouter,
)(EditInner);

export default Edit;
