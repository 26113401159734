import { set } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { compose, mapProps } from 'recompose';
import { faDesktop, faBabyCarriage, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import RipEmployeeAtHome from './EmployeeAtHome';
import RipChildCare from './ChildCare';
import RipHomeWork from './HomeWork';
import RipOtherCredit from './OtherCredit';

const initialTypes = {
    employeeAtHome: {
        component: RipEmployeeAtHome,
        icon: faDesktop,
        title: 'Salarié à domicile',
        defaultValues: {
            recurrentEvent: true,
        },
    },
    childCare: {
        component: RipChildCare,
        icon: faBabyCarriage,
        title: 'Garde d\'enfant(s)',
        unique: true,
        defaultValues: {
            recurrentEvent: true,
        },
    },
    homeWork: {
        component: RipHomeWork,
        path: 'homeWork',
        title: 'Travaux au domicile',
        defaultValues: {
            recurrentEvent: true,
            rate: 0.3,
        },
    },
    other: {
        component: RipOtherCredit,
        icon: faEllipsis,
        title: 'Autres',
        defaultValues: {
            recurrentEvent: true,
        },
    },
};

const TaxCreditsList = ({ types }) => (
    <FormGroup>
        <Title>Avez-vous <b>des crédits d&apos;impôts&nbsp;?</b></Title>
        <TypedCollection name="taxCredits" types={types} />
    </FormGroup>
);

TaxCreditsList.propTypes = {
    types: PropTypes.shape({}).isRequired,
};

export default compose(
    formValues({ familySituation: 'familySituation' }),
    mapProps(({ familySituation }) => ({
        types: 'cohabitation' === familySituation
            ? set('childCare.unique', false, initialTypes)
            : initialTypes,
    })),
)(TaxCreditsList);
