import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './Item.scss';

const Item = ({ children, className, displayActions, ignoreFocus, ...props }) => (
    <tr className={classNames('list-item', className, { displayActions, ignoreFocus })} tabIndex={0} {...props}>
        {children}
    </tr>
);

Item.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    displayActions: PropTypes.bool,
    ignoreFocus: PropTypes.bool,
};

Item.defaultProps = {
    className: null,
    displayActions: false,
    ignoreFocus: false,
};

export default Item;
