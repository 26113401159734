import PropTypes from 'prop-types';
import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';

const LifeInsuranceResultLineChart = ({
    legendWrapperStyle,
    legendLayout,
    lines,
    lineData,
    formatter,
    labelFormatter,
    domain,
    ...props
}) => (
    <LineChart className="bar-chart" data={lineData} barSize={15} {...props}>
        <XAxis
            dataKey="year"
            type="number"
            interval="preserveStartEnd"
            tickCount={10}
            domain={['dataMin', 'dataMax']}
        />
        <YAxis unit="€" padding={{ bottom: 20 }} domain={domain} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={formatter} labelFormatter={labelFormatter} />
        {lines.map(({ dataKey, name, fill }) => (
            <Line
                key={dataKey}
                dataKey={dataKey}
                name={name}
                stroke={fill}
                strokeWidth={3}
                isAnimationActive={false}
            />
        ))}
        <Legend
            layout={legendLayout}
            verticalAlign="bottom"
            wrapperStyle={legendWrapperStyle}
        />
    </LineChart>
);

LifeInsuranceResultLineChart.defaultProps = {
    domain: null,
    legendWrapperStyle: null,
    legendLayout: 'vertical',
};

LifeInsuranceResultLineChart.propTypes = {
    legendWrapperStyle: PropTypes.shape({}),
    legendLayout: PropTypes.string,
    lines: PropTypes.arrayOf(PropTypes.shape({
        dataKey: PropTypes.string,
        name: PropTypes.string,
        stroke: PropTypes.string,
    })).isRequired,
    lineData: PropTypes.arrayOf(PropTypes.shape({
        year: PropTypes.number.isRequired,
    })).isRequired,
    formatter: PropTypes.func,
    labelFormatter: PropTypes.func,
    domain: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])),
};

export default LifeInsuranceResultLineChart;
