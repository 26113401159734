import PropTypes from 'prop-types';
import React from 'react';
import FieldError from './FieldError';

const TypedBlockCollectionItemInner = ({
    fields,
    index,
    name,
    component: Block,
    type: { component: Component, title, itemTitle = title },
    meta,
    blockProps,
}) => (
    <Block title={itemTitle} name={name} deleteAction={() => fields.remove(index)} {...blockProps}>
        <Component name={name} />
        <FieldError meta={meta} name={name} />
    </Block>
);

TypedBlockCollectionItemInner.propTypes = {
    fields: PropTypes.shape({
        remove: PropTypes.func.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.shape({
        component: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        itemTitle: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
    }).isRequired,
    component: PropTypes.func,
    meta: PropTypes.shape({}).isRequired,
    blockProps: PropTypes.shape({}),
};

export default TypedBlockCollectionItemInner;
