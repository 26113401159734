import React from 'react';

import { Body, Head, Header, Table } from '../../list';
import Item from './Item';

const teamListTable = ({ items, type }) => (
    <Table>
        <Head>
            <th>
                <Header sortProperty="lastName" text="Nom" />
            </th>
        </Head>
        <Body>
            {items.map(user => <Item user={user} key={user.id} userActive={type} />)}
        </Body>
    </Table>
);
export default teamListTable;
