import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import FormGroup from 'components/form/Group';
import Title from 'components/Title';
import FormButtons from 'components/FormButtons';
import Divider from 'components/Divider';
import { SelectField } from 'components/form/field';
import simulationLoader from 'components/simulation/simulationLoader';
import ProgramForm from '../../common/property/ProgramForm';
import PropertyForm from '../../common/property/PropertyForm';
import dataForm from '../dataForm';
import Form from '../Form';
import SimulationHeader from '../Header';
import OperationForm from './OperationForm';
import Icon from '../../../../../common/components/Icon';

import './Property.scss';
import withRouter from '../../../../withRouter';

const programTypeField = (
    <SelectField
        name="programDuration"
        title="Durée du dispositif"
        parse={(value) => (value === undefined ? null : parseInt(value, 10))}
        required
    >
        <option />
        <option value={6}>6 ans</option>
        <option value={63}>6+3 ans</option>
        <option value={66}>6+6 ans</option>
        <option value={9}>9 ans</option>
        <option value={93}>9+3 ans</option>
    </SelectField>
);
const taxRegime = (
    <SelectField
        name="taxRegime"
        title="Régime fiscal du bien"
        required
    >
        <option />
        <option value="pinel" >Pinel</option>
        <option value="pinelPlus">Pinel+</option>
    </SelectField>
);
const informativeMessage = (
    <div className="informativeMessage">
        <Icon icon="exclamation" className="fa-fw" />
        Le choix du régime Pinel + nécessite que l’utilisateur ait vérifié l’éligibilité du lot au dispositif au préalable.
    </div>
);

const PropertyInner = ({ handleSubmit, params: { id, simulationId }, needFunding, programDuration, change, ...props }) => {
    const { simulation } = props;
    const isPinelPlus = 'pinelPlus' === simulation.taxRegime;

    if (12 === programDuration) {
        change('programDuration', 93);
    }

    return (
        <Form handleSubmit={handleSubmit} needFunding={needFunding}>
            <div className="simulation-form-inner">
                <div className="container">
                    <SimulationHeader />
                    <FormGroup>
                        <Title><b>Dispositif</b></Title>
                        { isPinelPlus &&
                        informativeMessage
                        }
                        <ProgramForm programTypeField={programTypeField} taxRegime={taxRegime} />
                    </FormGroup>
                    <Divider />
                    <FormGroup>
                        <Title><b>Le bien</b></Title>
                        <PropertyForm />
                    </FormGroup>
                    <Divider />
                    <FormGroup>
                        <Title><b>L&apos;opération</b></Title>
                        <OperationForm />
                    </FormGroup>
                </div>
            </div>
            <FormButtons
                back={`/rip/${id}/simulations/${simulationId}/resume`}
                list={`/rip/${id}/simulations/`}
            />
        </Form>
    );
};

PropertyInner.defaultProps = {
    needFunding: false,
};

PropertyInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    needFunding: PropTypes.bool,
    programDuration: PropTypes.number,
    change: PropTypes.func,
    simulation: PropTypes.shape({
        taxRegime: PropTypes.string,
    }).isRequired,
};

const Property = flowRight([
    withRouter,
    simulationLoader,
    dataForm({
        form: 'simulation-property',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => navigate(`/rip/${id}/simulations/${simulationId}/${result.needFunding ? 'financement' : 'charges'}`),
        type: 'simulation',
    }),
    formValues('needFunding', 'programDuration'),
])(PropertyInner);

export default Property;
