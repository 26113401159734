import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { createElement } from 'react';
import dataProvider from 'components/dataProvider';
import types from 'components/contract/types';

const RouterInner = ({
    contract: { simulation: { product } },
    ...props
}) => (createElement(get([product, 'router'], types), props)
);

RouterInner.propTypes = {
    contract: PropTypes.shape({
        simulation: PropTypes.shape({
            product: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

const Router = dataProvider({
    id: ({ params: { contractId } }) => contractId,
    type: 'contract',
    wait: true,
})(RouterInner);

export default Router;
