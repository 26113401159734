import { flowRight } from 'lodash/fp';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { formValues } from 'redux-form';
import { getContext, lifecycle } from 'recompose';
import { DateField, DisabledField, IntegerField } from '../form/field';
import { formatFullDate } from '../../common/utils/format';

const LoanRangeInner = ({ change, startName, endName, durationName, startDate, endDate, duration, startTitle, endTitle, durationTitle }) => {
    const durationChange = (value) => {
        const months = parseInt(value, 10);
        const end = (startDate && !Number.isNaN(months)) ? moment(startDate).add(months, 'months').format('YYYY-MM-DD') : null;
        change(endName, end);

        return value;
    };

    const startChange = (value) => {
        const months = parseInt(duration, 10);
        const end = (value && !Number.isNaN(months)) ? moment(value).add(months, 'months').format('YYYY-MM-DD') : null;
        change(endName, end);

        return value;
    };

    return (
        <Fragment>
            <DateField normalize={startChange} name={startName} title={startTitle} required />
            <IntegerField parse={durationChange} name={durationName} title={durationTitle} required />
            <DisabledField name={endName} title={endTitle} value={formatFullDate(endDate)} />
        </Fragment>
    );
};

LoanRangeInner.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    duration: PropTypes.number,
    change: PropTypes.func.isRequired,
    startName: PropTypes.string.isRequired,
    endName: PropTypes.string.isRequired,
    durationName: PropTypes.string.isRequired,
    startTitle: PropTypes.string.isRequired,
    endTitle: PropTypes.string.isRequired,
    durationTitle: PropTypes.string.isRequired,
};

const LoanRange = flowRight([
    getContext({
        change: PropTypes.func.isRequired,
    }),
    formValues(({ startName, endName, durationName }) => ({
        endDate: endName,
        startDate: startName,
        duration: durationName,
    })),
    lifecycle({
        componentWillMount() {
            const { change, duration, startDate, endDate, durationName } = this.props;
            if (duration === undefined && startDate && endDate) {
                change(durationName, moment(endDate).diff(moment(startDate), 'months'));
            }
        },
    }),
])(LoanRangeInner);

export default LoanRange;
