import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Details from 'components/simulation/types/dismemberedScpi/Details';
import Products from 'components/simulation/types/dismemberedScpi/Products';
import RequestApproval from 'components/simulation/types/dismemberedScpi/RequestApproval';
import ResponseApproval from 'components/simulation/types/dismemberedScpi/ResponseApproval';
import { SummaryPage } from 'components/simulation/types/dismemberedScpi/Summary';

const Router = () => (
    <Routes>
        <Route path="/products" element={<Products />} />
        <Route path="/simulation" element={<Details />} />
        <Route path="/approval/request" element={<RequestApproval />} />
        <Route path="/approval/:approval" element={<ResponseApproval />} />
        <Route path="/summary" element={<SummaryPage />} />
    </Routes>
);

export default Router;
