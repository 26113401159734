import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withState } from 'recompose';
import { reduxForm } from 'redux-form';
import Icon from 'common/components/Icon';
import { GET, POST } from 'utils/httpMethods';
import FormButtons from './FormButtons';
import Form from './simulation/FormStep';

import './Signatures.scss';

const getUniversignInfos = async (type, id) => {
    const response = await GET(`/api/${type}/${id}/sign/transactionInfo`);

    return response.json();
};

const initializeUniversignInfos = (type, setUniversignInfos, id) => async () => {
    setUniversignInfos('waiting');
    try {
        const response = await POST(`/api/${type}/${id}/sign`);
        const result = await response.json();

        if (result && !result.error) {
            const universignInfos = await getUniversignInfos(type, id);

            setUniversignInfos(universignInfos);
        } else {
            setUniversignInfos(result);
        }
    } catch (err) {
        setUniversignInfos({ error: err.message });
    }
};

const toggleIframe = (type, setUniversignInfos, id) => async () => {
    const universignInfos = await getUniversignInfos(type, id);

    if ('ready' !== universignInfos.statusCgp) {
        alert('Veuillez finir la signature du client. Si le problème persiste, recharger la page et recommencer.');
    } else {
        setUniversignInfos(universignInfos);
    }
};

const SignaturesInner = ({
    data: { state },
    type,
    id,
    wizard,
    back,
    list,
    handleSubmit,
    universignInfos,
    setUniversignInfos,
}) => (
    <Form handleSubmit={handleSubmit} wizard={wizard}>
        <div className="simulation-form-inner" id="simulationFormInner">
            <div className="container contract-signatures">
                {'ongoing' !== state ? (
                    <p>Signé</p>
                ) : (
                    <Fragment>
                        {null === universignInfos ? (
                            <button type="button" className="sign" onClick={initializeUniversignInfos(type, setUniversignInfos, id)}>
                                <Icon icon="pencil" />
                                <p>Accéder à la signature éléctronique</p>
                            </button>
                        ) : (
                            <Fragment>
                                {'waiting' === universignInfos ? <div className="spinner">Merci de patienter, nous préparons vos documents</div> : null}
                                {universignInfos && universignInfos.error ? (
                                    <div className="error-message">
                                        <h1>La préparation de vos documents a échoué.</h1>
                                        <p>Vous pouvez recharger la page pour recommencer.</p>
                                        <p>Vous trouverez ci-dessous le message d&apos;erreur rencontré par l&apos;application.</p>
                                        <p className="error-body"><Icon icon="quote-left" />{universignInfos.error}<Icon icon="quote-right" /></p>
                                    </div>
                                ) : null}
                                {universignInfos.urlClient && ['ready', 'code-sent'].includes(universignInfos.statusClient) ? (
                                    <Fragment>
                                        <iframe id="client-iframe" title="client-iframe" sandbox="allow-same-origin allow-scripts allow-forms" name="universign-iframe" src={universignInfos.urlClient} />
                                        <div className="toggleIframe">
                                            <button type="button" className="btn" onClick={toggleIframe(type, setUniversignInfos, id)}>
                                                <Icon icon="refresh" />Passer à la signature du conseiller
                                            </button>
                                        </div>
                                    </Fragment>
                                ) : null}
                                {universignInfos.urlCgp && ['ready', 'code-sent'].includes(universignInfos.statusCgp) ? (
                                    <iframe id="cgp-iframe" title="cgp-iframe" sandbox="allow-same-origin allow-scripts allow-forms" name="universign-iframe" src={universignInfos.urlCgp} />
                                ) : null}
                            </Fragment>
                        )
                        }
                    </Fragment>
                )}
            </div>
        </div>
        <FormButtons
            back={back}
            list={list}
        />
    </Form>
);

SignaturesInner.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    wizard: PropTypes.shape({}),
    back: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    data: PropTypes.shape({
        state: PropTypes.string.isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setUniversignInfos: PropTypes.func.isRequired,
    universignInfos: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        statusClient: PropTypes.string,
        statusCgp: PropTypes.string,
        urlClient: PropTypes.string,
        urlCgp: PropTypes.string,
    })]),
};

const Signatures = flowRight([
    reduxForm({
        form: 'signatures-form',
        onSubmit: (result, dispatch, {
            type,
            id,
        }) => getUniversignInfos(type, id),
        onSubmitSuccess: (result, dispatch, {
            id,
            type,
            next,
            history: { push },
        }) => {
            if ('completed' === result.status) {
                return GET(`/api/${type}/${id}/sign/success`).then(() => push(next));
            }

            return alert('La signature n\'est pas complète.');
        },
        onSubmitFail: () => alert('La signature n\'est pas complète.'),
    }),
    withState('universignInfos', 'setUniversignInfos', null),
])(SignaturesInner);

export default Signatures;
