import React from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import dataForm from 'components/dataForm';
import { ChoiceField, TextField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';
import { useInstanceSettings } from '../sessions';

const EditContactOriginForm = ({ handleSubmit, close, contactOrigin }) => {
    const { contactOriginTypes } = useInstanceSettings();

    return (
        <form onSubmit={handleSubmit}>
            <GridLayout className="single condensed full-width">
                <ChoiceField choices={contactOriginTypes} title="Origine du contact" name="contactOrigin" />
                {
                    'other' === contactOrigin ? (
                        <TextField title="Précisez" name="contactOriginOther" />
                    ) : null
                }
            </GridLayout>
            <div className="submit-footer">
                <div>
                    <button type="button" className="cancel-button" onClick={close}>
                        Annuler
                    </button>
                </div>
                <div>
                    <button type="submit" className="submit-button">
                        Continuer
                    </button>
                </div>
            </div>
        </form>
    );
};

EditContactOriginForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    contactOrigin: PropTypes.string.isRequired,
};

export default compose(
    connect(null, (dispatch) => ({ close: () => dispatch(setModal(null)) })),
    dataForm({
        form: 'rip-edit-contact-origin-form',
        autoSave: false,
        type: 'rip',
        id: ({ id }) => id,
        onSubmitSuccess: (values, dispatch, { close }) => close(),
    }),
    formValues({ contactOrigin: 'contactOrigin' }),
)(EditContactOriginForm);
