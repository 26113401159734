import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ItemCheckbox = ({ title, value, important, bold, negative, disabled, name, help, allowChangingInvestorProfile }) => (
    <div
        className={
            classNames(
                'summary-item',
                { important },
                { bold },
                { negative },
            )
        }
    >
        <div className={classNames('summary-item-inner', { 'no-title': !title })}>
            {title ? (<dt>{title}{help ? <span className="summary-help">{help}</span> : null}</dt>) : null}
            <div className="checkbox-value-container">
                <div className="checkbox-input">
                    <input
                        id={name}
                        checked={allowChangingInvestorProfile}
                        type="checkbox"
                        disabled={disabled}
                    />
                </div>
                <dd className="checkbox-value">{value || 'NC'}</dd>
            </div>
        </div>
    </div>
);

ItemCheckbox.propTypes = {
    allowChangingInvestorProfile: PropTypes.bool,
    bold: PropTypes.bool,
    disabled: PropTypes.string,
    help: PropTypes.string,
    important: PropTypes.bool,
    name: PropTypes.string,
    negative: PropTypes.bool,
    title: PropTypes.node,
    value: PropTypes.node,
};

ItemCheckbox.defaultProps = {
    bold: false,
    important: false,
    value: null,
    help: null,
};

export default ItemCheckbox;
