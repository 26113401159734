import React from 'react';
import PropTypes from 'prop-types';
import { branch, renderNothing } from 'recompose';
import { flowRight } from 'lodash/fp';
import { formValues } from 'redux-form';

import { shouldPartnerSign } from '../../../common/utils/hasPartner';
import RadioQuestion from '../../InvestorProfile/RadioQuestion';

import './choiceContact.scss';

const ChoiceContact = ({ rip }) => (
    <div className="choice-contact">
        <RadioQuestion
            choices={{
                client: `${rip.firstName} ${rip.lastName} (client)`,
                partner: `${rip.partnerFirstName} ${rip.partnerLastName} (conjoint)`,
            }}
            name="target"
            title={<b>Contact souhaité pour générer le document*</b>}
            className="single"
            required
            errortext="Vous devez choisir une réponse."
        />
    </div>
);

ChoiceContact.propTypes = {
    rip: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
    }).isRequired,
};

export default flowRight([
    formValues({ targetForESign: 'target' }),
    branch(
        ({ rip }) => !shouldPartnerSign(rip),
        renderNothing,
    ),
])(ChoiceContact);
