import { maxBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deletePdfEdition, deletePdfSignature, setPdfEditionTool, setPdfEditionSignatureTool } from 'actions';
import Tool from './Tool';

const lastEdition = (editions) => {
    const result = maxBy(
        ({ createdAt }) => createdAt,
        Object.entries(editions).map(([index, { createdAt }]) => ({ createdAt, index })),
    );

    return result ? result.index : null;
};

const toggleSignatureTool = (signatures, target, del, set) => {
    if (signatures[target]) {
        del(target);
    } else {
        set(target);
    }
};

const Edition = ({
    deleteEdition,
    deleteSignature,
    setSignatureTool,
    setTool,
    pdfEditions: { editions, activeTool, activeId, signatures },
}) => (
    <div className="edition-toolbox">
        <h3>Éditer votre PDF</h3>
        <div className="tools">
            <Tool
                title="Ajouter du texte"
                onClick={() => setTool('text')}
                selected={'text' === activeTool}
            />
            <Tool
                className="client"
                title="Placer la signature du client"
                onClick={() => toggleSignatureTool(signatures, 'client', deleteSignature, setSignatureTool)}
                selected={!!signatures.client || 'client' === activeId}
            />
            <Tool
                className="advisor"
                title="Placer la signature du conseiller"
                onClick={() => toggleSignatureTool(signatures, 'advisor', deleteSignature, setSignatureTool)}
                selected={!!signatures.advisor || 'advisor' === activeId}
            />
            <Tool
                title="Annuler"
                onClick={() => deleteEdition(lastEdition(editions))}
            />
        </div>
    </div>
);

Edition.propTypes = {
    setTool: PropTypes.func.isRequired,
    deleteEdition: PropTypes.func.isRequired,
    setSignatureTool: PropTypes.func.isRequired,
    deleteSignature: PropTypes.func.isRequired,
    pdfEditions: PropTypes.shape({
        activeId: PropTypes.string,
        activeTool: PropTypes.string,
        editions: PropTypes.shape({
            type: PropTypes.string,
            position: PropTypes.shape({
                top: PropTypes.number,
                left: PropTypes.number,
                right: PropTypes.number,
                bottom: PropTypes.number,
            }),
            temporary: PropTypes.bool,
        }),
        signatures: PropTypes.shape({
            client: PropTypes.string,
            advisor: PropTypes.string,
        }),
    }).isRequired,
};

const mapStateToProps = (state) => ({
    pdfEditions: state.pdfEditions,
});

const mapDispatchToProps = {
    setTool: setPdfEditionTool,
    deleteEdition: deletePdfEdition,
    deleteSignature: deletePdfSignature,
    setSignatureTool: setPdfEditionSignatureTool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edition);
