import React from 'react';
import { formValues } from 'redux-form';
import PropTypes from 'prop-types';

import FormGroup from 'components/form/Group';
import { GridLayout } from 'components/form/layout';
import { ChoiceField, IntegerField, PercentageField } from 'components/form/field';
import Title from 'components/Title';
import { periodicities } from 'common/choices';

const defineRemuneration = (isScheduleLineaire) => (isScheduleLineaire ? (
    <p style={{ color: 'red', textAlign: 'center' }}>Au regard des droits d’entrée appliqués, vous bénéficierez du barème de rémunération linéaire</p>
) : (
    <p style={{ color: '#4FA5E5', textAlign: 'center' }}>Au regard des droits d’entrée appliqués, vous bénéficierez du barème de rémunération escompte</p>));

const ScheduledPayments = ({ subscriptionChoice, isScheduleLineaire }) => (
    <FormGroup>
        <Title><b>Versements programmés</b></Title>
        <GridLayout className="condensed">
            <IntegerField
                name="data.scheduledPayments.amount"
                title="Montant"
                required
            />
            <PercentageField
                name="data.scheduledPayments.fees"
                title="Frais d'entrée pour les montants programmés (%)"
            />
            <ChoiceField
                name="data.scheduledPayments.periodicity"
                title="Périodicité"
                choices={periodicities}
                required
            />
            {'cardif' === subscriptionChoice ?
                <div className="double">
                    <p style={{ fontStyle: 'italic' }}>Les versements réguliers seront en place à la fin du mois courant (ou du mois suivant selon la date de traitement de l’opération)</p>
                </div> : null}
        </GridLayout>
        {'mma' === subscriptionChoice ?
            defineRemuneration(isScheduleLineaire)
            : null}
    </FormGroup>
);

ScheduledPayments.propTypes = {
    subscriptionChoice: PropTypes.string,
    isScheduleLineaire: PropTypes.bool,
};

export default formValues({
    subscriptionDate: 'data.subscriptionDate',
    contractType: 'data.contractType',
})(ScheduledPayments);
