import Dexie from 'dexie';

const createDatabase = (userId) => {
    // we are going to get the database for the current user
    // if this information does not exist yet, we will use a default database
    const db = new Dexie(`sync.${userId}`);

    db.version(1).stores({
        syncs: 'id',
        rips: 'id',
    });

    db.version(2).stores({
        syncs: 'id',
        rips: 'id',
        ripsChanges: 'id',
    });

    db.version(3).stores({
        syncs: 'id',
        rips: 'id',
        ripsChanges: 'id',
        comments: 'id,ripId',
        commentsChanges: 'id',
    });

    db.version(4).stores({
        syncs: 'id',
        rips: 'id',
        ripsChanges: 'id',
        comments: 'id,ripId',
        commentsChanges: 'id',
        simulations: 'id,ripId',
        simulationsChanges: 'id',
    });

    db.version(5).stores({
        syncs: 'id',
        rips: 'id,userId,asManager',
        ripsChanges: 'id',
        comments: 'id,ripId',
        commentsChanges: 'id',
        simulations: 'id,ripId',
        simulationsChanges: 'id',
    });

    db.version(6).stores({
        syncs: 'id',
        rips: 'id,userId',
        ripsChanges: 'id',
        comments: 'id,ripId',
        commentsChanges: 'id',
        simulations: 'id,ripId',
        simulationsChanges: 'id',
    });

    return db;
};

export default createDatabase;
