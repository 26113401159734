import PropTypes from 'prop-types';
import React from 'react';
import withRouter from '../withRouter';
import RipProfileForm from './profile/Form';
import RipLayout from './RipLayout';

const Profile = ({ params: { id } }) => (
    <RipLayout>
        <RipProfileForm back={`/rip/${id}/finances`} id={id} />
    </RipLayout>
);

Profile.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(Profile);
