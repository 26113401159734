import { takeWhile } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import WizardItem from './WizardItem';

const profiles = {
    P: { label: 'Prudent', icon: 'life-ring' },
    E: { label: 'Équilibré', icon: 'balance-scale' },
    D: { label: 'Dynamique', icon: 'motorcycle' },
};

const InvestorProfileWizard = ({ investorProfile, limit, itemComponent: Item }) => {
    const enabledProfiles = [
        // all lower profiles
        ...takeWhile((profile) => profile !== limit, Object.keys(profiles)),
        // plus the limit itself
        limit,
    ];

    return (
        <div className="investor-profile-wizard">
            {Object.entries(profiles).map(([key, { label, icon }]) => (
                <Item
                    key={key}
                    active={investorProfile === key}
                    disabled={!enabledProfiles.includes(key)}
                    icon={icon}
                    label={label}
                    profileKey={key}
                />
            ))}
            <span className="help-text left">Sécurité</span>
            <span className="help-text right">Rendement</span>
        </div>
    );
};

InvestorProfileWizard.propTypes = {
    investorProfile: PropTypes.string.isRequired,
    itemComponent: PropTypes.func,
    limit: PropTypes.string,
};

InvestorProfileWizard.defaultProps = {
    itemComponent: WizardItem,
    limit: 'D',
};

export default InvestorProfileWizard;
