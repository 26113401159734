const reducer = (state = false, { type }) => {
    switch (type) {
        case 'UPDATES_SET_OUTDATED':
            return true;
        default:
            return state;
    }
};

export default reducer;
