import { useState, useEffect } from 'react';
import * as http from '../utils/httpMethods';

const useFetch = ({ url, method = 'GET', body = {} }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetcher = http[method];

    useEffect(() => {
        (async () => {
            const response = await (('GET' === method) ? fetcher(url) : fetcher(url, { body }));
            try {
                const responseData = await response.json();

                setData(responseData);
                setLoading(false);
            } catch (e) {
                // eslint-disable-next-line
                console.error('Error in useFetch:', e);
            }
        })();
    }, [setData, method, url]);

    return [data, loading];
};

export default useFetch;
