import { has, get } from 'lodash/fp';
import { GET } from '../utils/httpMethods';

const getCollections = ({
    comments,
    contracts,
    entities,
    rips,
    simulations,
    users,
    signatures,
    documentsCifPendingValidations,
    ripsWithoutDocumentCifPendingValidations,
    invoices,
    payouts,
    syncLogs,
}) => ({
    contract: contracts,
    comment: comments,
    entity: entities,
    rip: rips,
    simulation: simulations,
    user: users,
    signature: signatures,
    documentsCifPendingValidations,
    ripsWithoutDocumentCifPendingValidations,
    invoice: invoices,
    payout: payouts,
    syncLog: syncLogs,
});

const setData = (type, id, data) => ({
    type: 'DATA_SET',
    data: {
        data,
        id,
        type,
    },
});

const setDataList = (type, data) => ({
    type: 'DATA_SET_LIST',
    data: {
        data,
        type,
    },
});

export const loadData = (type, id) => async (dispatch, getState, { database }) => {
    const state = getState().data;

    if (has([type, id], state) && !get([type, id, 'minified'], state)) {
        return;
    }

    dispatch(setData(type, id, null));

    const collections = getCollections(database);

    let donneesRecuperees;

    if ('rip' === type) {
        try {
            donneesRecuperees = await collections[type].get(id);
        } catch (error) {
            donneesRecuperees = await GET(`/api/rips/${id}`);
            donneesRecuperees = await donneesRecuperees.json();
        }
    } else {
        donneesRecuperees = await collections[type].get(id);
    }

    dispatch(setData(type, id, donneesRecuperees));
};

export const loadDataList = (type, criteria = null) => async (dispatch, getState, { database }) => {
    // reset the store
    dispatch(setDataList(type, []));

    const collections = getCollections(database);

    // load the requested list
    dispatch(setDataList(type, await collections[type].find(criteria)));
};

export const saveData = (type, id, changes) => async (dispatch, getState, { database }) => {
    const collections = getCollections(database);

    const data = await collections[type].update(id, changes);

    dispatch(setData(type, id, data));

    return data;
};

const chekIfUpdate = (previousValue, newValue) => {
    if (JSON.stringify(previousValue) === JSON.stringify(newValue)) {
        return false;
    }

    return true;
};

export const saveDataInvestor = (type, id, changes) => async (dispatch, getState, { database }) => {
    const collections = getCollections(database);
    const nouvellesdonnees = changes.investorProfiles;
    const ripDatabase = await collections[type].get(id);
    const bdd = ripDatabase.investorProfiles;

    if (chekIfUpdate(bdd, nouvellesdonnees)) {
        const data = await collections[type].update(id, changes);
        dispatch(setData(type, id, data));

        return data;
    }

    return ripDatabase;
};
