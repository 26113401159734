import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import Client from './Client';
import Partner from './Partner';

import './FinancialCapacity.scss';

const FinancialCapacity = ({ familySituation }) => ('cohabitation' !== familySituation
    ? <Client />
    : (
        <div className="rip-financial-capacities">
            <Client />
            <Partner />
        </div>
    ));

FinancialCapacity.propTypes = {
    familySituation: PropTypes.string,
};

FinancialCapacity.defaultProps = {
    familySituation: null,
};

export default formValues('familySituation')(FinancialCapacity);
