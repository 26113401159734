import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { withProps } from 'recompose';
import { formValues } from 'redux-form';
import { employmentStatuses, socioProfessionalCategories } from 'common/choices';
import addPrefix from 'common/utils/addPrefix';
import { IntegerField, TextField, ChoiceField } from '../../form/field';
import { GridLayout } from '../../form/layout';

const TaxCommon = ({
    createdAt, employmentStatus, partnerEmploymentStatus,
    partner, required,
}) => {
    const getIdentifier = addPrefix('partner')(partner);

    // Cette date limite correspond à la date de mise en prod de cette fonctionnalité
    // Afin de ne pas affecter les rips antérieur à cette date
    const dateLimit = new Date('2023-05-25T00:00:00.000Z');
    const createdDateRip = new Date(createdAt);

    const isRequired = createdDateRip >= dateLimit;

    return (
        <div className="rip-civil-state">
            <GridLayout className="triple condensed wide-inputs gray-box">
                <TextField
                    title={`Profession${partner ? ' du conjoint' : ''}`}
                    placeholder="Profession"
                    required={required}
                    name={getIdentifier('job')}
                    nombreCaractereMax={32}
                />
                <IntegerField
                    title={`Âge de début d'activité${partner ? ' du conjoint' : ''}`}
                    name={getIdentifier('startingActivityAge')}
                />
                <IntegerField
                    title={`Ancienneté${partner ? ' du conjoint' : ''} (nombre d'années)`}
                    info="Ancienneté dans ce métier"
                    placeholder="Nombre d'années"
                    name={getIdentifier('seniority')}
                    min={0}
                    step={1}
                />
                <ChoiceField
                    choices={employmentStatuses}
                    title={`${partner ? '' : 'Votre '}Statut professionnel${partner ? ' du conjoint' : ''}`}
                    name={getIdentifier('employmentStatus')}
                    required={isRequired && required}
                />
                {((!partner && employmentStatus) || (partner && partnerEmploymentStatus)) ? (
                    <ChoiceField
                        choices={socioProfessionalCategories[partner ? partnerEmploymentStatus : employmentStatus]}
                        title={`Catégorie Socio-Professionnelle${partner ? ' du conjoint' : ''}`}
                        name={getIdentifier('socioProfessionalCategory')}
                        required={isRequired && required}
                    />
                ) : null}
            </GridLayout>
        </div>
    );
};

TaxCommon.propTypes = {
    createdAt: PropTypes.string,
    partner: PropTypes.bool,
    employmentStatus: PropTypes.string,
    partnerEmploymentStatus: PropTypes.string,
    required: PropTypes.bool.isRequired,
};

TaxCommon.defaultProps = {
    partner: false,
};

export default flowRight([
    formValues({
        employmentStatus: 'employmentStatus',
        partnerEmploymentStatus: 'partnerEmploymentStatus',
        required: 'dualRip',
    }),
    withProps(({ required = false, cohabitation = false }) => ({
        required: cohabitation ? required : true,
    })),
])(TaxCommon);
