import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LegalDisclaimerDropdown from '../../LegalDisclaimerDropdown';

const LegalDisclaimers = ({ settings }) => (
    <Fragment>
        {Object.entries(settings.legalDisclaimer).map(([type, { name }]) => (
            <LegalDisclaimerDropdown
                key={name}
                title={name}
                name={`simulation.legalDisclaimer.${type}.text`}
                variables={{ nomReseau: 'Nom d\'affichage du réseau' }}
            />
        ))}
    </Fragment>
);

LegalDisclaimers.propTypes = {
    settings: PropTypes.shape({
        legalDisclaimer: PropTypes.shape({}),
    }).isRequired,
};

export default LegalDisclaimers;
