import PropTypes from 'prop-types';
import React from 'react';
import { periodicities } from 'common/choices';
import { Grid, ChoiceItem, AmountItem, PercentageItem } from 'common/components/summary';
import Title from 'components/Title';
import Group from 'components/form/Group';

import './ScheduledPayments.scss';

const ScheduledPayments = ({
    simulation: {
        data: {
            scheduledPayments: {
                periodicity,
                amount,
                fees,
            } = {},
        },
    },
}) =>

    (amount ? (
        <Group>
            <Title><b>Versements programmés</b></Title>
            <Grid size={2}>
                <ChoiceItem title="Périodicité" value={periodicity} choices={periodicities} />
                <AmountItem title="Montant" value={amount} />
                <PercentageItem title="Frais d'entrée (%)" value={fees} />
            </Grid>
        </Group>
    ) : null);
ScheduledPayments.propTypes = {
    simulation: PropTypes.shape({
        data: PropTypes.shape({
            scheduledPayments: PropTypes.shape({
                periodicity: PropTypes.string,
                amount: PropTypes.number,
                fees: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default ScheduledPayments;
