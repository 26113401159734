import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Tool = ({ onClick, selected, title, className }) => (
    <div className="tool">
        <button type="button" className={classNames(className, { selected })} onClick={onClick}>
            {title}
        </button>
    </div>
);

Tool.propTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Tool;
