import { withProps, mapProps } from 'recompose';
import { Field } from 'redux-form';
import Wizard from 'common/components/rip/InvestorProfile/Wizard';
import ProfileChoice from './ProfileChoice';

const ProfileField = withProps({
    component: mapProps(({ input: { value, ...props }, limit }) => ({
        investorProfile: value,
        itemComponent: withProps(props)(ProfileChoice),
        limit,
    }))(Wizard),
})(Field);

export default ProfileField;
