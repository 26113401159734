import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { lmnpTypes } from 'common/choices';
import Title from 'components/Title';
import FormButtons from 'components/FormButtons';
import Divider from 'components/Divider';
import FormGroup from 'components/form/Group';
import { SelectField } from 'components/form/field';
import simulationLoader from 'components/simulation/simulationLoader';
import ProgramForm from 'components/simulation/types/common/property/ProgramForm';
import PropertyForm from 'components/simulation/types/common/property/PropertyForm';
import SimulationHeader from '../Header';
import Form from '../Form';
import dataForm from '../dataForm';
import OperationForm from './OperationForm';

const programTypeField = (
    <div className="double">
        <SelectField
            name="data.program.type"
            title="Dispositif"
            required
        >
            <option />
            {Object.entries(lmnpTypes).map(([key, value]) => (
                <option key={key} value={key}>{value}</option>
            ))}
        </SelectField>
    </div>
);

const PropertyInner = ({ handleSubmit, params: { id, simulationId }, needFunding, programType }) => (
    <Form handleSubmit={handleSubmit} needFunding={needFunding}>
        <div className="simulation-form-inner">
            <div className="container">
                <SimulationHeader />
                <FormGroup>
                    <Title><b>Dispositif</b></Title>
                    <ProgramForm programTypeField={programTypeField} withoutTaxRegime />
                </FormGroup>
                <Divider />
                <FormGroup>
                    <Title><b>Le bien</b></Title>
                    <PropertyForm programType={programType} />
                </FormGroup>
                <Divider />
                <FormGroup>
                    <Title><b>L&apos;opération</b></Title>
                    <OperationForm programType={programType} />
                </FormGroup>
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/resume`}
            list={`/rip/${id}/simulations/`}
        />
    </Form>
);

PropertyInner.defaultProps = {
    needFunding: false,
};

PropertyInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    needFunding: PropTypes.bool,
    programType: PropTypes.string.isRequired,
};

const Property = flowRight([
    simulationLoader,
    dataForm({
        form: 'simulation-property',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => navigate(`/rip/${id}/simulations/${simulationId}/${result.needFunding ? 'financement' : 'charges'}`),
        type: 'simulation',
    }),
    formValues({
        needFunding: 'needFunding',
        programType: 'data.program.type',
    }),
])(PropertyInner);

export default Property;
