/* eslint-disable react/no-children-prop */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import { Route } from 'react-router-dom';

const InnerStaticItem = ({ name, match }) => (
    <span className={classNames('wizard-item', { active: !!match })}>
        <div className="wizard-item-point" />
        <div className="wizard-item-label">{name}</div>
    </span>
);

InnerStaticItem.propTypes = {
    name: PropTypes.string.isRequired,
    match: PropTypes.shape({}),
};

const StaticItem = ({ name, to }) => createElement(
    Route,
    {
        path: to,
        children: (props) => <InnerStaticItem {...props} name={name} />,
    },
);

StaticItem.propTypes = {
    name: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export default StaticItem;
