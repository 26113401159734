import PropTypes from 'prop-types';
import React from 'react';
import { nest } from 'recompose';
import FormButtons from 'components/FormButtons';
import { Layout } from 'components/layouts';
import SummaryInner from './SummaryInner';
import withSummary from './withSummary';

const SummaryPage = ({
    children,
    params: { id },
}) => (
    <Layout className="simulation-body" hasFooter>
        <div className="container summary">
            {children}
        </div>
        <FormButtons back={`/rip/${id}/simulations/`} list={`/rip/${id}/simulations/`} last />
    </Layout>
);

SummaryPage.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    children: PropTypes.node.isRequired,
};

export default withSummary(nest(SummaryPage, SummaryInner));
