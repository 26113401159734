import { flow, filter, get, map, pick, isEmpty, toPairs } from 'lodash/fp';
import recommendations from '../choices/recommendations';

export const filterRecommendations = (recommendationsToFilter, approveState = false) => flow([
    toPairs,
    // ensure it
    filter(([, { isApproved }]) => !!isApproved === approveState),
    map(([type, { data }]) => ({ ...data, type })),
])(recommendationsToFilter);

export const hasApprovalFor = (type) => {
    const allowingRecommendations = flow([
        toPairs,
        filter(([, { simulator }]) => type === simulator),
        map(get(0)),
    ])(recommendations);

    const hasApproval = flow([
        get('recommendations'),
        pick(allowingRecommendations),
        filter(({ isApproved }) => isApproved),
        (value) => !isEmpty(value),
    ]);

    return (rip, target = 'client', { canCreateSimulation, isEliteCGP, needApprovalOnSimulations }) => {
        if (!canCreateSimulation && !isEliteCGP) {
            return false;
        }

        if (!needApprovalOnSimulations) {
            // some users don't need approvals to create simulation
            return true;
        }

        if ('partner' === target && 'cohabitation' === rip.familySituation && rip.dualRip) {
            return hasApproval(get('partner', rip));
        }

        return hasApproval(rip);
    };
};

const collectionHasApprovedRecommendations = (collection) => !isEmpty(collection)
    && collection.some(({ isApproved }) => isApproved);

export const hasApprovedRecommendations = (rip) => collectionHasApprovedRecommendations(get('recommendations', rip))
    || (
        'cohabitation' === rip.familySituation
        && rip.dualRip
        && collectionHasApprovedRecommendations(get('partner.recommendations', rip))
    );
