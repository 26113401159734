import { flowRight, get, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fullName from 'common/utils/fullName';
import { isCouple } from 'common/utils/hasPartner';
import Footer from 'components/rip/Footer';
import { DELETE, POST, PATCH } from 'utils/httpMethods';
import { setSignatureState } from 'actions/signature';
import Legal from '../../Legal';
import Signature from './ManualSignature';
import { GridLayout } from '../../../../form/layout';
import { saveData } from '../../../../../actions';
import dataForm from '../../../dataForm';
import Icon from '../../../../../common/components/Icon';
import { withSession } from '../../../../sessions';
import { useOnline } from '../../../../withOnline';

import './ManualSignatures.css';

const NextSignature = (changeComponent, clientSigner, partnerSigner, renew, rip, session) => () => (
    <div className="sign-btn-wrapper">
        <button
            className="sign-btn"
            onClick={async () => {
                if (renew) {
                    await DELETE(`/api/rips/${rip.id}/sign`);
                }
                if ('universign' === clientSigner) {
                    await POST(`/api/signatures/${rip.id}/logs`, { body: { rip, clientSigner } });
                }
                if ('universign' === partnerSigner) {
                    await POST(`/api/signatures/${rip.id}/logs`, { body: { rip, partnerSigner } });
                }
                await PATCH(`/api/rips/${rip.id}`, { body: { initiateSignatureAdvisor: session.id } });

                return changeComponent('electronic-sign', { clientSigner, partnerSigner })();
            }}
        >
            Continuer vers les signatures électroniques
        </button>
    </div>
);

const Signatures = ({ back, canContinue, changeComponent, clientSigner, partnerSigner, handleSubmit, rip, saveData: save, renew, settingsEntity, settingsGlobal, session, clientSignatureMode, partnerSignatureMode, ...props }) => {
    const { instanceTheseis } = session;
    const onLine = useOnline();

    const clientFullName = fullName(rip);

    const hasPartner = isCouple(rip.familySituation) || ('cohabitation' === rip.familySituation && rip.dualRip);

    const partnerFullName = hasPartner ? fullName({
        firstName: rip.partnerFirstName,
        lastName: rip.partnerLastName,
    }) : '';

    const hasSigned = !!(('manual' !== clientSigner || rip.signature) && rip.advisorSignature && (!hasPartner || (rip.partnerSignature || 'manual' !== partnerSigner)));

    const universignSigners = [];
    if ('universign' === clientSigner) {
        universignSigners.push('client');
    }
    if ('universign' === partnerSigner) {
        universignSigners.push('partner');
    }

    const noSignature = [];
    if ('no' === clientSignatureMode) {
        noSignature.push('clientNoSign');
    }
    if ('no' === partnerSignatureMode) {
        noSignature.push('partnerNoSign');
    }

    const isSignProcessCompleted = hasSigned && isEmpty(universignSigners);

    //  ** enregistrement des signatures manuelles
    let signatureTimeAndType = rip.clientAndPartnerSignatureDate;

    useEffect(() => {
        if (isSignProcessCompleted) {
            // Sauvegarde des signatures
            save('rip', rip.id, { signatureDate: Date.now() });
        }
        if (rip.signature && 'no' !== clientSignatureMode) {
            signatureTimeAndType = { ...signatureTimeAndType, client: { type: 'manuellement', date: Date.now() } };
            if (!onLine) {
                signatureTimeAndType = { ...signatureTimeAndType, offLine: true };
            }
        }
        if (hasPartner && rip.partnerSignature && 'no' !== partnerSignatureMode) {
            signatureTimeAndType = { ...signatureTimeAndType, partner: { type: 'manuellement', date: Date.now() } };
            if (!onLine) {
                signatureTimeAndType = { ...signatureTimeAndType, offLine: true };
            }
        }

        save('rip', rip.id, { clientAndPartnerSignatureDate: signatureTimeAndType });

        props.setSignatureState({ clientSignatureMode, partnerSignatureMode: hasPartner && partnerSignatureMode ? partnerSignatureMode : null });
    }, [isSignProcessCompleted, rip.signature, rip.partnerSignature]);

    const saveSignature = (target) => (value) => {
        save('rip', rip.id, { [target]: value });
    };

    const informativeMessage = (
        <div className="informativeMessage">
            <Icon icon="exclamation" className="fa-fw" />
            A l’issue de vos signatures, un coffre-fort sécurisé sera créé. Vous pourrez y retrouver le relevé d’information patrimonial.
        </div>
    );

    const redirectByInstance = () => {
        if (!navigator.onLine) {
            if ('TOPINVEST' === session.entity.name.toUpperCase()) {
                return `/rip/${rip.id}/etat-civil`;
            }

            return '/';
        }
        if (instanceTheseis) {
            return `/rip/${rip.id}/signatureState`;
        }

        return `/rip/${rip.id}/appointment`;
    };

    return (
        <div className="rip-form">
            <div className="rip-form-inner">
                <form onSubmit={handleSubmit} id="client-form">
                    <div className="signatures container">
                        {instanceTheseis &&
                            informativeMessage
                        }
                        <GridLayout>
                            {'manual' === clientSigner ? <Legal fullName={clientFullName} customer="client" double={!hasPartner} rip={rip} settingsEntity={settingsEntity} settingsGlobal={settingsGlobal} /> : null}
                            {hasPartner && 'manual' === partnerSigner ? <Legal fullName={partnerFullName} customer="partner" rip={rip} partner settingsEntity={settingsEntity} settingsGlobal={settingsGlobal} /> : null}
                        </GridLayout>
                        {'manual' === clientSigner ? <Signature
                            save={saveSignature('signature')}
                            fullName={clientFullName}
                            initialValue={rip.signature}
                            customer="client"
                        /> : null}
                        {(hasPartner && 'manual' === partnerSigner) ? (
                            <Signature
                                save={saveSignature('partnerSignature')}
                                fullName={partnerFullName}
                                initialValue={rip.partnerSignature}
                                customer="partner"
                            />
                        ) : null}
                        <Signature
                            save={saveSignature('advisorSignature')}
                            title="Signature du conseiller"
                            hasPartner={hasPartner}
                            initialValue={rip.advisorSignature}
                        />
                    </div>
                    {!isEmpty(universignSigners) ? (
                        <Footer
                            back={back}
                            nextButtonComponent={NextSignature(changeComponent, clientSigner, partnerSigner, renew, rip, session, signatureTimeAndType, save)}
                            last={!navigator.onLine || !canContinue || !hasSigned}
                        />
                    ) : (
                        <Footer
                            back={back}
                            last={!canContinue || !hasSigned}
                            nextRedirectTo={redirectByInstance()}
                            shoyo={instanceTheseis}
                            session={session}
                        />
                    )}
                </form>
            </div>
        </div>
    );
};

Signatures.propTypes = {
    back: PropTypes.string.isRequired,
    clientSigner: PropTypes.string.isRequired,
    partnerSigner: PropTypes.string.isRequired,
    canContinue: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    changeComponent: PropTypes.func.isRequired,
    rip: PropTypes.shape({
        familySituation: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        signature: PropTypes.string,
        advisorSignature: PropTypes.string,
        partnerSignature: PropTypes.string,
        id: PropTypes.string,
        dualRip: PropTypes.string,
        partnerId: PropTypes.string,
        idBoxShoyo: PropTypes.string,
        partnerIdBoxShoyo: PropTypes.string,
        clientAndPartnerSignatureDate: PropTypes.shape({}),
    }).isRequired,
    saveData: PropTypes.func.isRequired,
    renew: PropTypes.bool,
    settingsGlobal: PropTypes.shape({}).isRequired,
    settingsEntity: PropTypes.string.isRequired,
    session: PropTypes.shape({
        instanceTheseis: PropTypes.bool,
        isUserPiloteShoyo: PropTypes.bool,
        instanceSettings: PropTypes.shape({
            label: PropTypes.string,
            toUpperCase: PropTypes.func,
        }),
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
    }).isRequired,
    clientSignatureMode: PropTypes.func.isRequired,
    partnerSignatureMode: PropTypes.func.isRequired,
    setSignatureState: PropTypes.func,
};

export default flowRight([
    withSession,
    dataForm({
        form: 'rip-commitment',
        id: ({ rip: { id } }) => id,
        type: 'rip',
        onSubmit: (values, dispatch, session, { history: { push }, rip: { id } }) => push(`/rip/${id}/appointment`),
    }),
    connect(
        (state) => ({
            canContinue: isEmpty(get(['formValidation', 'errors', 'rip-commitment'], state)),
            signatureStateValue: state.signatureState,
        }),
        { saveData, setSignatureState },
    ),
])(Signatures);
