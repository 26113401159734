import classNames from 'classnames';
import { flowRight, get, getOr, pickBy } from 'lodash/fp';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { connect } from 'react-redux';
import { saveData } from 'actions';
import Icon from 'common/components/Icon';
import { Action, Actions, Date, Initials, InitialsLockBadge, Item, PdfAction, connectActiveItem } from '../list';
import { withSession } from '../sessions';
import getUrl from './getUrl';
import types from './types';

const approvalStates = {
    approved: 'Approuvée',
    refused: 'Refusée',
    waiting: 'En cours',
    default: 'Non soumise',
    useless: 'Non nécessaire',
};

const ListItem = ({ rip, item, onClickDelete, show, isActive, session }) => {
    const { locked } = item;
    const { appSettings: { simulation } } = session;

    const isDeleted = useMemo(() => {
        switch (item.product) {
            case 'lifeInsurance':
                return getOr(false, ['lifeInsurance', 'types', item.data.contractType, 'deleted'], simulation);
            case 'scpi':
            case 'dismemberedScpi': {
                const deletedSimulations = pickBy(({ deleted }) => deleted, simulation[item.product]);

                return item.data.products && item.data.products.some(({ type }) => type in deletedSimulations);
            }
            default:
                return false;
        }
    }, [item]);

    const approvalState = get([item.product, 'noRecommendation'], types)
        ? 'useless'
        : item.approval || 'default';

    const mayWrite = session.permissions.mayWriteOnSimulation(rip, item);

    return (
        <Item
            displayActions={isActive}
            onFocus={!isActive ? () => show() : null}
        >
            <Initials firstName={rip.firstName} lastName={rip.lastName}>
                {locked && <InitialsLockBadge />}
            </Initials>
            <td>{item.name}</td>
            <td>{types[item.product].label}</td>
            <Date date={item.createdAt} />
            <Date date={item.updatedAt} />

            {session.needApprovalOnSimulations && (
                <td className={classNames('simulation-approval-state', approvalState)}>
                    {approvalStates[approvalState]}
                </td>
            )}
            {isDeleted && (
                <td className="simulation-approval-state waiting">
                    <Icon icon="warning" className="fa-fw" />
                    Ce contrat n&apos;est plus commercialisable
                </td>
            )}
            <Actions>
                {mayWrite && (
                    <Action
                        to={getUrl(item)}
                        icon="pencil"
                        text="modifier"
                    />
                )}
                {!['per'].includes(item.product) && session.permissions.mayDownloadSimulationPDF(item, rip) && (
                    <PdfAction url={`/api/simulations/${item.id}/pdf`} />
                )}
                {['pinel', 'pinelDenormandie', 'lmnp', 'scpi'].includes(item.product) && (
                    <Action
                        to={`/rip/${rip.id}/simulations/${item.id}/summary`}
                        icon="pie-chart"
                        text="récapitulatif"
                    />
                )}
                {/* // TODO Laisser disponible sans orias */}
                {session.permissions.mayCreateSimulation(rip, item.product) && (
                    <Action
                        to={`/rip/${rip.id}/simulations/${item.id}/clone`}
                        icon="clone"
                        text="dupliquer"
                    />
                )}
                {mayWrite && (
                    <Action
                        onClick={onClickDelete}
                        icon="trash-o"
                        text="supprimer"
                        right
                    />
                )}
            </Actions>
        </Item>
    );
};

ListItem.propTypes = {
    rip: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
    item: PropTypes.shape({
        locked: PropTypes.bool,
        product: PropTypes.string,
        approval: PropTypes.string,
        name: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        data: PropTypes.shape({
            contractType: PropTypes.string,
            products: PropTypes.PropTypes.arrayOf(PropTypes.shape({
                some: PropTypes.func,
            })),
        }),
        id: PropTypes.string,
    }).isRequired,
    onClickDelete: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    show: PropTypes.func.isRequired,
    session: PropTypes.shape({
        permissions: PropTypes.shape({
            mayWriteOnSimulation: PropTypes.func,
            mayDownloadSimulationPDF: PropTypes.func,
            mayCreateSimulation: PropTypes.func.isRequired,
        }),
        appSettings: PropTypes.shape({
            simulation: PropTypes.shape({}),
        }),
        needApprovalOnSimulations: PropTypes.bool,
        mayDownloadSimulationPDF: PropTypes.func,
    }).isRequired,
};

export default flowRight([
    connect(null, (dispatch, { item: { id } }) => ({
        onClickDelete: () => {
            if (!window.confirm('Souhaitez vous vraiment supprimer cette simulation ?')) {
                return;
            }

            dispatch(saveData('simulation', id, { deleted: true }));
        },
    })),
    connectActiveItem(
        ({ item: { id } }) => id,
        (item, { activeItem }) => ({ isActive: item === activeItem }),
    ),
    pure,
    withSession,
])(ListItem);
