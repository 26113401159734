import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { InputField, IntegerField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';

const RealEstateFieldsInner = ({ currentValue, name }) => {
    switch (currentValue) {
        case 'Malraux':
            return (
                <GridLayout className="condensed full-width">
                    <InputField
                        name={`${name}.description`}
                        title="Type"
                        type="text"
                        required
                    />
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                </GridLayout>
            );
        case 'LMNP':
        case 'Bouvard':
            return (
                <GridLayout className="single condensed full-width">
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                </GridLayout>
            );
        case 'Pinel':
        case 'optimizedPinel':
        case 'PinelDenormandie':
            return (
                <GridLayout className="triple condensed full-width">
                    <InputField
                        name={`${name}.description`}
                        title="Type"
                        type="text"
                        required
                    />
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                    <InputField name={`${name}.vousFinancer`} title="Prêt VousFinancer" type="checkbox" />
                </GridLayout>
            );
        case 'landDeficit':
        case 'otherRealEstate':
            return (
                <GridLayout className="condensed full-width">
                    <InputField
                        name={`${name}.description`}
                        title="Précisez"
                        type="text"
                        required
                    />
                    <IntegerField name={`${name}.amount`} title="Montant approximatif" required />
                </GridLayout>
            );
        default:
            return null;
    }
};

RealEstateFieldsInner.propTypes = {
    currentValue: PropTypes.string,
    name: PropTypes.string.isRequired,
};

RealEstateFieldsInner.defaultProps = {
    currentValue: '',
};

const RealEstateFields = formValues(({ name }) => ({
    currentValue: `${name}.type`,
}))(RealEstateFieldsInner);

export default RealEstateFields;
