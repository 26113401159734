import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import getChoice from '../../utils/getChoice';
import Item from './Item';

const ChoiceItem = withProps(({ value, choices }) => ({
    value: getChoice(choices, value),
}))(Item);

ChoiceItem.propTypes = {
    choices: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChoiceItem.defaultProps = {
    value: null,
};

export default ChoiceItem;
