const cleanDataNavigation = () => {
    if (document.cookie) {
        document.cookie.split(';').forEach((cookie) => {
            const nameLength = cookie.indexOf('=');
            const name = nameLength > 0 ? cookie.substr(0, nameLength) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        });
    }

    localStorage.clear();
    sessionStorage.clear();
};

export default cleanDataNavigation;
