import PropTypes from 'prop-types';
import React from 'react';
import { mapProps } from 'recompose';
import { FieldArray, fieldArrayPropTypes } from 'redux-form';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import './Collection.css';
import Error from '../Error';
import UntypedBlock from './UntypedBlock';

const Collection = ({
    itemComponent: Item,
    componentProps,
    fields,
    meta,
    initialValues,
    addLabel,
    deleteAction = (index, fds) => fds.remove(index),
    inline,
}) => (
    <div className="grid-layout-collection">
        <div className={classNames('grid-layout-collection-items', { 'collection-inline': inline })}>
            {fields.map((name, index) => (
                <UntypedBlock deleteAction={() => deleteAction(index, fields)} key={name}>
                    <Item className="grid-layout-collection-item" name={name} index={index} fields={fields} {...componentProps} />
                </UntypedBlock>
            ))}
        </div>
        <Error {...meta} />
        <div className="form-typed-collection-buttons">
            <button
                type="button"
                onClick={() => fields.push({ ...initialValues, id: uuidv4() })}
                className="textOnly untyped"
            >
                <p>{addLabel}</p>
            </button>
        </div>
    </div>
);

Collection.propTypes = {
    ...fieldArrayPropTypes,
    itemComponent: PropTypes.func.isRequired,
    componentProps: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
    addLabel: PropTypes.string,
    inline: PropTypes.bool,
};

Collection.defaultProps = {
    initialValues: {},
    addLabel: 'Ajouter',
    inline: false,
};

export default mapProps(({ component, ...props }) => ({
    ...props,
    itemComponent: component,
    component: Collection,
}))(FieldArray);
