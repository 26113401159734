import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import Title from 'components/Title';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const Decote = ({ name }) => (
    <Fragment>
        <Title>Décote</Title>
        <GridLayout className="double condensed full-width">
            <div className="double">
                <PercentageFloatField
                    name={`${name}.rate`}
                    title="Taux d'application (%)"
                />
            </div>
            <IntegerField
                name={`${name}.incomeTaxCeils.single`}
                title="Plafond d'application (célibataire) (€)"
            />
            <IntegerField
                name={`${name}.incomeTaxCeils.couple`}
                title="Plafond d'application (couple) (€)"
            />
            <IntegerField
                name={`${name}.reductionCeils.single`}
                title="Plafond inclus dans le calcul de la décote (célibataire)"
            />
            <IntegerField
                name={`${name}.reductionCeils.couple`}
                title="Plafond inclus dans le calcul de la décote (couple)"
            />
        </GridLayout>
    </Fragment>
);

Decote.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Decote;
