import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TextItem, AmountItem, YesNoItem } from 'common/components/summary';

const Pinel = ({
    recommendation: {
        description,
        amount,
        vousFinancer,
    },
}) => (
    <Grid size={3}>
        <TextItem title="Type" value={description} />
        <AmountItem title="Montant approximatif" value={amount} />
        <YesNoItem title="Prêt VousFinancer" value={vousFinancer} />
    </Grid>
);

Pinel.propTypes = {
    recommendation: PropTypes.shape({
        description: PropTypes.string,
        amount: PropTypes.number,
        vousFinancer: PropTypes.bool,
    }).isRequired,
};

export default Pinel;
