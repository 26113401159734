import React, { useCallback, useState } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { camelCase } from 'lodash/fp';
import { reduxForm, Form, SubmissionError } from 'redux-form';
import { POST } from 'utils/httpMethods';
import { GridLayout } from 'components/form/layout';
import { PercentageFloatField, YesNoField, TextField } from 'components/form/field';
import validate from 'common/formValidation/lib';
import { buildPerBaseProductsValidation } from 'common/formValidation/settings/perSettingsValidation';

const CreateNewSupportPER = ({ error, handleSubmit, hideForm, hiddenAll, showForm }) => {
    const [hidden, setHidden] = useState(true);
    const showNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(false);
        showForm();
    }, [setHidden]);
    const hideNewForm = useCallback((e) => {
        e.preventDefault();
        setHidden(true);
        hideForm();
    }, [setHidden]);

    return (
        <div className={classNames('new-simu', { hidden: hiddenAll })}>
            {hidden ? (
                <button onClick={showNewForm}>Nouveau support PER</button>
            ) : (
                <button onClick={hideNewForm} className="cancel">Annuler</button>
            )}
            {error && <Alert variant="danger">{error}</Alert>}
            <div className={classNames('new-simulation-form', { hidden })}>
                <h1 className="text-center">Paramètres du nouveau support PER</h1>
                <Form onSubmit={handleSubmit}>
                    <GridLayout className="triple condensed full-width">
                        <TextField
                            name="label"
                            title="Nom"
                        />
                        <TextField
                            name="codeIsin"
                            title="Code ISIN"
                        />
                        <YesNoField
                            name="isEuroSupport"
                            title="Fond Euros ?"
                        />
                    </GridLayout>
                    <GridLayout className=" triple condensed max-width" >
                        <PercentageFloatField
                            name="lowRate"
                            title="Taux min"
                        />
                        <PercentageFloatField
                            name="rate"
                            title="Taux"
                        />
                        <PercentageFloatField
                            name="highRate"
                            title="Taux max"
                        />
                    </GridLayout>
                    <div className="submit">
                        <button type="submit">Créer</button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

CreateNewSupportPER.propTypes = {
    handleSubmit: PropTypes.func,
    hiddenAll: PropTypes.bool,
    showForm: PropTypes.func,
    hideForm: PropTypes.func,
    error: PropTypes.shape({}),
};

export default reduxForm({
    form: 'simulation-per-supports-create',
    onSubmit: async (values) => {
        const product = camelCase(values.label);
        const id = `simulation.per.baseProducts.${product}`;
        try {
            const response = await POST('/api/settings/supports', {
                body: {
                    id,
                    value: values,
                },
            });

            return response.json();
        } catch (error) {
            const data = await error.response.json();
            // trigger the onSubmitFail
            throw new SubmissionError({ _error: 'La création a échouée !', ...data });
        }
    },
    onSubmitSuccess: () => {
        document.location.href = '/admin/settings/simulation';
    },
    validate: validate(buildPerBaseProductsValidation()),
})(CreateNewSupportPER);
