import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import getWizardStep from 'utils/getWizardStep';
import { POST } from 'utils/httpMethods';
import Spinner from 'components/Spinner';
import FormButtons from '../../../FormButtons';
import Form from '../../../simulation/FormStep';
import getWizard from '../wizardData';
import './PrimonialHubWarning.scss';
import withRouter from '../../../withRouter';

const PrimonialHubWarning = ({ navigate, location, params }) => {
    const { id, contractId, contractType } = params;
    const [loading, setLoading] = useState(false);
    const [error, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const listUrl = `/rip/${id}/contracts/`;
    const wizard = getWizard(contractType);
    const baseUrl = wizard.prefixTemplate(params);
    const previousSlug = getWizardStep({ wizard, url: location.pathname, next: false });

    const previous = `${baseUrl}/${previousSlug}`;

    const redirectToHub = async (event) => {
        event.preventDefault();
        setLoading(true);
        setIsError(false);

        try {
            // We ask the backend to create a transaction
            const response = await POST(`/api/contracts/${contractId}/primonial`);
            // We get the hub url
            const { hubUrl } = await response.json();

            // We open the hub on new tab
            window.open(`/sso-redirect-primonial?url=${encodeURIComponent(hubUrl)}`, '_blank');
            // and redirect the current tab to contract list
            navigate(listUrl);
        } catch (e) {
            // eslint-disable-next-line no-console
            setIsError(true);
            switch (e.status) {
                case 400:
                    setErrorMessage('Vérifier les données de la souscription.');
                    break;
                case 404:
                    setErrorMessage('Conseiller introuvable.');
                    break;
                case 500:
                default:
                    setErrorMessage('Plateforme indisponible.');
                    break;
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            handleSubmit={redirectToHub}
            wizard={getWizard(contractType)}
            noScroll
        >
            <div className="alert-container">
                <Alert bsStyle="info">
                    <strong>Attention</strong>
                    <br />
                    A partir de cette étape la souscription sera initialisée sur le site partenaire et toute
                    modification ultérieure du RIP n’entrainera pas la mise à jour automatique sur le site du partenaire.
                    Ces modifications devront obligatoirement se faire en manuel sur ce dernier.
                </Alert>
            </div>
            {loading && <Spinner>Redirection vers le hub en cours...</Spinner>}
            {error && !loading && (
                <div className="alert-container">
                    <Alert bsStyle="danger">
                        <strong>Erreur</strong>
                        <br />
                        {errorMessage}
                    </Alert>
                </div>
            )}
            <FormButtons
                list={listUrl}
                back={previous}
            />
        </Form>
    );
};

PrimonialHubWarning.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        contractId: PropTypes.string,
        contractType: PropTypes.string,
    }),
    navigate: PropTypes.shape({}),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default withRouter(PrimonialHubWarning);
