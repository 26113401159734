import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import redirectToTopinvest from '../utils/redirectToTopinvest';
import Icon from '../common/components/Icon';

import './PageNotFound.scss';

const PageNotFound = ({ session }) => (
    <ErrorMessage>
        <div className="page-not-found">
            <h1>Pour rejoindre le relevé patrimonial, veuillez vous connecter sur Topinvest</h1>
            <p>
                En cas de problème, merci d’adresser un mail &agrave;&nbsp;:&nbsp;
                <b>serviceclient@topinvest.fr</b>
            </p>
            <div className="back-home">
                {'Topinvest' === session.entity.name ? (
                    <button
                        onClick={() => {
                            redirectToTopinvest(session);
                        }}
                        className="sendButton"
                    >
                        Connexion à Topinvest&nbsp;&nbsp;&nbsp;
                        <Icon icon="angle-right" />
                    </button>
                ) : (
                    <a href="/">Retourner à l&apos;accueil</a>)}
            </div>
        </div>
    </ErrorMessage>
);

PageNotFound.propTypes = {
    session: PropTypes.shape({
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        instanceSettings: PropTypes.shape({
            emailSupport: PropTypes.string,
        }),
    }).isRequired,
};

export default PageNotFound;
