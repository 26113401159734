import { get, without } from 'lodash/fp';

import computeNewInvestorProfile from './computeNewInvestorProfile';
import computeOldInvestorProfile from './computeOldInvestorProfile';
import knowledgeAndExperience from './newQuestions/knowledgeAndExperience/index';
import investmentWish from './newQuestions/investmentWish';
import lifeInsurance from './newQuestions/lifeInsurance/index';

// eslint-disable-next-line consistent-return
const computeInvestorProfile = (investorProfile) => {
    // Si le client ne souhaite pas répondre au questionnaire
    if (investorProfile && investorProfile.decline && true === investorProfile.decline) {
        return 'declined';
    }
    // Appel du compute pour le nouveau questionnaire de risque
    if (investorProfile && !investorProfile.AMFCustomer) {
        const sufficientKnowledge = true;

        // 1ere partie du questionnaire "Connaissance et expérience"
        const knowledgeAndExperienceInvestor = computeNewInvestorProfile(investorProfile, knowledgeAndExperience, sufficientKnowledge);
        if (knowledgeAndExperienceInvestor.resolvedProfile) {
            return knowledgeAndExperienceInvestor.resolvedProfile;
        }

        // 2ème partie du questionnaire "Souhait d'investissement"
        const investmentWishInvestor = computeNewInvestorProfile(investorProfile, investmentWish, sufficientKnowledge);
        if (investmentWishInvestor.resolvedProfile) {
            return investmentWishInvestor.resolvedProfile;
        }

        // 3ème partie du questionnaire "Assurance vie"
        const lifeInsuranceInvestor = computeNewInvestorProfile(investorProfile, lifeInsurance);

        const totalPoints = knowledgeAndExperienceInvestor.totalPoints + investmentWishInvestor.totalPoints + lifeInsuranceInvestor.totalPoints;

        const sufferTradingLosses = get('sufferTradingLosses', investorProfile);
        const yesNoLifeInsuranceLosses = get('lifeInsuranceLosses', investorProfile);

        const checkNoLosses = (a, b) => {
            if ('no' === a && 'no' === b) {
                return true;
            }

            return false;
        };

        let availableProfiles;
        if (knowledgeAndExperienceInvestor.availableProfiles && lifeInsuranceInvestor.availableProfiles && investmentWishInvestor.availableProfiles) {
            const concatAvailableProfiles = knowledgeAndExperienceInvestor.availableProfiles.concat(lifeInsuranceInvestor.availableProfiles, investmentWishInvestor.availableProfiles);
            availableProfiles = [...new Set(concatAvailableProfiles)];
        }
        let rejectedProfiles;
        if (knowledgeAndExperienceInvestor.selectRejectedProfile && investmentWishInvestor.selectRejectedProfile && lifeInsuranceInvestor.selectRejectedProfile) {
            const concatRejectedProfile = knowledgeAndExperienceInvestor.selectRejectedProfile.concat(investmentWishInvestor.selectRejectedProfile, lifeInsuranceInvestor.selectRejectedProfile);
            rejectedProfiles = [...new Set(concatRejectedProfile)];
        }

        availableProfiles = without(rejectedProfiles, availableProfiles);

        const noLosses = checkNoLosses(sufferTradingLosses, yesNoLifeInsuranceLosses);

        let profile;
        if (totalPoints <= 49 && availableProfiles.includes('P')) {
            profile = 'P';
        }
        if (totalPoints >= 50 && totalPoints <= 80 && availableProfiles.includes('E')) {
            profile = 'E';
        }
        if (totalPoints >= 41 && totalPoints <= 64 && true === noLosses && availableProfiles.includes('E')) {
            profile = 'E';
        }
        if (totalPoints >= 65 && true === noLosses && availableProfiles.includes('D')) {
            profile = 'D';
        }
        if (totalPoints > 80 && availableProfiles.includes('D')) {
            profile = 'D';
        }
        if (totalPoints > 80 && rejectedProfiles.includes('D')) {
            profile = 'E';
        }

        return profile;
    }
    // appel du compute pour l'ancien questionnaire de risque
    if (investorProfile && investorProfile.AMFCustomer) {
        return computeOldInvestorProfile(investorProfile);
    }
};

export default computeInvestorProfile;

export { default as profiles } from './profiles';
