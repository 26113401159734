import { GET } from '../utils/httpMethods';

const setRequiredFieldActivationDate = (field, activationDate) => ({
    type: 'SET_REQUIRED_FIELD_ACTIVATION_DATE',
    field,
    activationDate,
});

// eslint-disable-next-line import/prefer-default-export
export const getRequiredFieldActivationDate = (field) => async (dispatch) => {
    try {
        const response = await GET(`/api/requiredFieldsDate/${field}`);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        dispatch(setRequiredFieldActivationDate(field, data));
    } catch (error) {
        dispatch(setRequiredFieldActivationDate(field, null));
    }
};
