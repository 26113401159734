import { clamp } from 'lodash/fp';
// La plupart des valeurs rentrées dans le formulaire Pinel sont TTC.

const computeInstalmentCosts = ({
    parkingLotWithSeparateLease,
    propertyValue,
    notaryFees,
    otherAcquisitionCost,
    propertyMonthlyRent,
    parkingLotValue,
    editableNotaryFees,
    editableOtherAcquisitionCost,
    propertyWeightedSurface,
    parkingEditableNotaryFees,
    parkingEditableOtherAcquisitionCost,
    parkingNotaryFees,
    parkingOtherAcquisitionCost,
    parkingLotRent,
    editablePropertyTaxEstimated,
    propertyTaxEstimated,
    product,
    montantTravaux,
    venteImmeubleARenover,
    needFunding,
    data: {
        taxBenefitBasis: {
            value: taxBenefitBasisValue,
            edited: taxBenefitBasisEdited,
        } = {},
        fraisCourtage = 0,
    } = {},
}) => {
    // Loyer total mensuel
    const monthlyRent = parseFloat(propertyMonthlyRent) + (parkingLotWithSeparateLease ? parkingLotRent : 0);
    // calculate yearly rent
    const yearlyRent = 12 * monthlyRent;
    // Valeur de l'immobilier, cette valeur est soumise à l'indexation
    const amount = propertyValue + parkingLotValue;

    let defaultNotaryFees = propertyValue * 0.03;

    if ('pinelDenormandie' === product) {
        defaultNotaryFees = propertyValue * 0.08;

        if (venteImmeubleARenover) {
            defaultNotaryFees = (propertyValue + montantTravaux) * 0.08;
        }
    }

    let defaultOtherAcquisitionCost = false === needFunding ? 0 : propertyValue * 0.02;

    if ('pinelDenormandie' === product) {
        defaultOtherAcquisitionCost = false === needFunding ? 0 : (propertyValue + montantTravaux) * 0.02;
    }

    let defaultParkingNotaryFees = false === needFunding ? 0 : parkingLotValue * 0.03;

    if ('pinelDenormandie' === product) {
        defaultParkingNotaryFees = false === needFunding ? 0 : parkingLotValue * 0.08;
    }
    const defaultParkingOtherAcquisitionCost = false === needFunding ? 0 : parkingLotValue * 0.02;
    // calculate the investment
    const investment = amount / 1.20;
    // then calculate the TVA
    const tva = (amount / 1.20) * 0.20;

    const investmentAcquisitionCost = (editableNotaryFees ? notaryFees : defaultNotaryFees) +
    (editableOtherAcquisitionCost ? otherAcquisitionCost : defaultOtherAcquisitionCost);

    // we may now have the investment with TVA
    const mainInvestmentWithTVA = propertyValue + investmentAcquisitionCost;

    const parkingAcquisitionCost = (parkingEditableNotaryFees ? parkingNotaryFees : defaultParkingNotaryFees) +
        (parkingEditableOtherAcquisitionCost ? parkingOtherAcquisitionCost : defaultParkingOtherAcquisitionCost);

    const parkingWithTVA = parkingLotValue + parkingAcquisitionCost;

    const totalOtherAcquisitionCost = investmentAcquisitionCost + parkingAcquisitionCost;

    let investmentWithTVA = mainInvestmentWithTVA + parkingWithTVA;

    if ('pinelDenormandie' === product) {
        investmentWithTVA += montantTravaux;
    }

    const taxBenefitBasis = !taxBenefitBasisEdited ? clamp(
        0,
        propertyValue + (editableNotaryFees ? notaryFees : defaultNotaryFees)
        + ('pinelDenormandie' === product ? montantTravaux : 0) + (parkingLotWithSeparateLease ? 0 : parkingLotValue + (parkingEditableNotaryFees ? parkingNotaryFees : defaultParkingNotaryFees)),
        Math.min(300000, 5500 * propertyWeightedSurface),
    ) : taxBenefitBasisValue;

    // Taxe foncière
    const propertyTax = editablePropertyTaxEstimated ? propertyTaxEstimated : monthlyRent;

    const montantFrais = amount + ('pinelDenormandie' === product ? montantTravaux : 0);

    return {
        instalmentCosts: {
            amount: montantFrais,
            totalOtherAcquisitionCost,
            defaultNotaryFees,
            defaultOtherAcquisitionCost,
            defaultParkingNotaryFees,
            defaultParkingOtherAcquisitionCost,
            investment,
            tva,
            mainInvestmentWithTVA,
            parkingWithTVA,
            investmentWithTVA,
            yearlyRent,
            monthlyRent,
            taxBenefitBasis,
            propertyTax,
            fraisCourtage,
        },
    };
};

export default computeInstalmentCosts;
