import React from 'react';
import PropTypes from 'prop-types';
import { PercentageFloatField, IntegerField } from 'components/form/field';

const SlicesTable = ({ title, slices, name }) => (
    <table className="settings-table">
        <thead>
            <tr>
                <th colSpan="3">{title}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Tranches</td>
                <td>Seuil (€)</td>
                <td>Taux (%)</td>
            </tr>
            {slices.map((_, index) => (
                <tr key={slices.id}>
                    <td>{index + 1}e</td>
                    <td><IntegerField name={`${name}.${index}.min`} /></td>
                    <td><PercentageFloatField name={`${name}.${index}.rate`} /></td>
                </tr>
            ))}
        </tbody>
    </table>
);

SlicesTable.propTypes = {
    slices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default SlicesTable;
