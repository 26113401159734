import { flowRight } from 'lodash/fp';
import { lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Field } from 'redux-form';

const Captcha = ({ recaptchaPublicKey }) => {
    const renderRecaptchaField = ({
        meta: { touched, error },
        input: { onChange },
    }) => (
        <div className="recaptcha-class">
            <ReCAPTCHA
                sitekey={recaptchaPublicKey}
                onChange={onChange}
            />
            <div><p className="text-danger">{touched ? error : ''}</p></div>
        </div>
    );

    renderRecaptchaField.defaultProps = {
        meta: {
            touched: false,
            error: '',
        },
    };

    renderRecaptchaField.propTypes = {
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
        }),
        input: PropTypes.shape({
            onChange: PropTypes.func.isRequired,
        }).isRequired,
    };

    return <Field name="recaptcha" component={renderRecaptchaField} />;
};

Captcha.propTypes = {
    recaptchaPublicKey: PropTypes.string.isRequired,
};

export default flowRight([
    lifecycle({
        shouldComponentUpdate() {
            return false;
        },
    }),
])(Captcha);
