import { find, get, set, isEmpty } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';
import addPrefix from 'utils/addPrefix';
import validate, { checkRequiredField } from 'common/formValidation/lib';

const checkCompleteProfile = (
    errors,
    { simulationId },
    { rip, simulations },
) => {
    const simulation = find((simu) => simulationId === simu.id, simulations);
    const beneficiary = get('beneficiary', simulation);

    const getIdentifier = addPrefix('partner')('partner' === beneficiary);

    const country = get(getIdentifier('country'), rip);
    const birthCountry = get(getIdentifier('birthCountry'), rip);
    const birthPlace = get(getIdentifier('birthPlace'), rip);
    const birthZipCode = get(getIdentifier('birthZipcode'), rip);
    const employmentStatus = get(getIdentifier('employmentStatus'), rip);
    const socioProfessionalCategory = get(getIdentifier('socioProfessionalCategory'), rip);
    const lifePlans = get(getIdentifier('lifePlans'), rip);

    const renvoiChampsNonRenseigne = () => {
        const champsARenseigner = [!country && ' pays de l\'adresse', !birthCountry && ' pays de naissance', !employmentStatus && ' statut professionnel', !socioProfessionalCategory && ' catégorie socio-professionnelle', isEmpty(lifePlans) && ' objectifs'];

        if ('FR' === birthCountry) {
            champsARenseigner.push(!birthPlace && ' lieu de naissance', !birthZipCode && ' code postal de naissance');
        }
        const champs = champsARenseigner.filter((champ) => false !== champ);

        const intro = champs.length > 1 ? 'les champs suivants n\'ont pas été renseignés :' : 'le champ suivant n\'a pas été renseigné :';

        const phraseErreur = `${intro} ${champs}.`;

        return phraseErreur;
    };

    if (
        !country
        || !birthCountry
        || !employmentStatus
        || !socioProfessionalCategory
        || isEmpty(lifePlans)
    ) {
        return set(
            'product',
            <div>
                {renvoiChampsNonRenseigne()}
                <Link className="rip-edit-link" to={`/rip/${rip.id}/etat-civil`}>
                    <p>Editer le RIP</p>
                </Link>
            </div>,
            errors,
        );
    }
    if ('FR' === birthCountry) {
        if (!birthPlace || !birthZipCode || isEmpty(lifePlans)) {
            return set(
                'product',
                <div>
                    {renvoiChampsNonRenseigne()}
                    <Link className="rip-edit-link" to={`/rip/${rip.id}/etat-civil`}>
                        <p>Editer le RIP</p>
                    </Link>
                </div>,
                errors,
            );
        }
    }

    return errors;
};

const formValidate = validate([
    checkRequiredField('simulationId'),
    checkCompleteProfile,
]);

export default formValidate;
