import PropTypes from 'prop-types';
import React from 'react';
import FormButtons from 'components/FormButtons';
import { Layout } from 'components/layouts';
import simulationLoader from '../../../simulationLoader';
import SummaryInner from './SummaryInner';

const SummaryPage = ({
    params: { id },
    simulation,
}) => (
    <Layout className="simulation-body" hasFooter>
        <div className="container summary">
            <SummaryInner {...simulation} />
        </div>
        <FormButtons back={`/rip/${id}/simulations/`} list={`/rip/${id}/simulations/`} last />
    </Layout>
);

SummaryPage.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    simulation: PropTypes.shape({}).isRequired,
};

export default simulationLoader(SummaryPage);
