import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ icon, className, ...props }) => (
    <i className={`fa fa-${icon}${className ? ` ${className}` : ''}`} aria-hidden="true" {...props} />
);

Icon.defaultProps = {
    className: '',
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Icon;
