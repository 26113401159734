import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';
import modes from './modes';

const LeftComponent = ({ value }) => <Icon icon={modes[value].icon} />;

LeftComponent.propTypes = {
    value: PropTypes.string.isRequired,
};

export default LeftComponent;
