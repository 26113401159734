import { withProps } from 'recompose';
import EditButton from '../EditableField/EditButton';

export default withProps(({ input, defaultValue }) => ({
    input: {
        ...input,
        onChange: (event) => {
            if (event.target.checked) {
                input.onChange(defaultValue);
            } else {
                // reset the field to null
                input.onChange(null);
            }
        },
    },
}))(EditButton);
