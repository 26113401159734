const getQueryVariable = (search, variable) => {
    const query = search.substring(1);
    const vars = query.split('&');
    for (const kvpair of vars) {
        const pair = kvpair.split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }

    return null;
};

export default getQueryVariable;
