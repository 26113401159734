import React from 'react';
import { connect } from 'react-redux';
import _, { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { lifecycle } from 'recompose';
import { afficheNomPrenom } from 'common/utils/fullName';
import SendSignInviteAgainBtn from './SendSignInviteAgainButton';
import { useSession } from '../../../../../sessions';
import isRequiredField from '../../../../../../utils/isRequiredField';
import { getRequiredFieldActivationDate } from '../../../../../../actions';

const SignatureStatus = ({ rip, resignUrl, partnerShouldSign, enLigne, requiredFieldActivationDateReducer }) => {
    const { advisorSignature, clientAndPartnerSignatureDate } = rip;

    const canSendSignInvitAgain = {
        clientUrl: null,
        partnerUrl: null,
    };

    const renderSignatureState = (signatureInfos, contact) => {
        if (signatureInfos) {
            if (signatureInfos[contact]) {
                if ('refusal elect' === signatureInfos[contact].type) {
                    return 'Refus de signer';
                }
                if ('universign' === signatureInfos[contact].type) {
                    canSendSignInvitAgain[`${contact}Url`] = resignUrl[contact];

                    return `En attente de signature ${'partner' === contact ? 'du conjoint' : ''}`;
                }
                if (['électroniquement', 'manuellement'].includes(signatureInfos[contact].type)) {
                    return `Signé ${signatureInfos[contact].type}`;
                }
            }
        }

        return 'Non signé';
    };

    const clientHasRefusedElectSignture = (signatureVariable) => {
        if (signatureVariable && signatureVariable.client && 'refusal elect' === signatureVariable.client.type) {
            return true;
        }

        return false;
    };

    const signatureStatus = {
        client: renderSignatureState(clientAndPartnerSignatureDate, 'client'),
        partner: clientHasRefusedElectSignture(clientAndPartnerSignatureDate) ? 'Non signé' : renderSignatureState(clientAndPartnerSignatureDate, 'partner'),
        advisor: advisorSignature ? 'Signé manuellement' : 'Non signé',
    };

    const session = useSession();

    const definiLeNomDuConseillerAAfficher = () => {
        if (!enLigne) {
            return afficheNomPrenom(_.get(session, 'firstName'), _.get(session, 'lastName'));
        }

        return afficheNomPrenom(_.get(rip, 'user.firstName'), _.get(rip, 'user.lastName'));
    };

    let getClassAMFIncompleteClient = '';
    let getClassAMFIncompletePartner = '';
    let AMFFormIncompleteClient = null;
    let AMFFormIncompletePartner = null;
    const isRequiredInvestorProfiles = isRequiredField(rip.createdAt, requiredFieldActivationDateReducer, 'investorProfiles');

    if (isRequiredInvestorProfiles) {
        if (!_.get(rip, 'investorProfiles.client')) {
            AMFFormIncompleteClient = <span> - Questionnaire AMF incomplet</span>;
            getClassAMFIncompleteClient = 'tx-message-unfilled';
        }

        if (!_.get(rip, 'investorProfiles.partner')) {
            AMFFormIncompletePartner = <span> - Questionnaire AMF incomplet</span>;
            getClassAMFIncompletePartner = 'tx-message-unfilled';
        }
    }

    return (
        <div className="tx-status">
            <h2>État des signatures</h2>
            <div className="signature-status">
                <p className={getClassAMFIncompleteClient}>Client ({afficheNomPrenom(_.get(rip, 'firstName'), _.get(rip, 'lastName'))}) : {signatureStatus.client} {AMFFormIncompleteClient}</p>

                <SendSignInviteAgainBtn
                    ripId={rip.id}
                    type="client"
                    url={canSendSignInvitAgain.clientUrl}
                    investorProfiles={_.get(rip, 'investorProfiles.client')}
                    isRequiredField={isRequiredInvestorProfiles}
                />
            </div>
            {partnerShouldSign && (
                <div className="signature-status">
                    <p className={getClassAMFIncompletePartner}>Conjoint ({afficheNomPrenom(_.get(rip, 'partnerFirstName'), _.get(rip, 'partnerLastName'))}) : {signatureStatus.partner} {AMFFormIncompletePartner}</p>

                    <SendSignInviteAgainBtn
                        ripId={rip.id}
                        type="partner"
                        url={canSendSignInvitAgain.partnerUrl}
                        investorProfiles={_.get(rip, 'investorProfiles.partner')}
                        isRequiredField={isRequiredInvestorProfiles}
                    />
                </div>
            )}
            <div className="signature-status">
                <p>Conseiller ({definiLeNomDuConseillerAAfficher()}) : {signatureStatus.advisor}</p>
            </div>
        </div>
    );
};

SignatureStatus.propTypes = {
    rip: PropTypes.shape({
        advisorSignature: PropTypes.string,
        partnerSignature: PropTypes.string,
        signature: PropTypes.string,
        id: PropTypes.string,
        createdAt: PropTypes.string,
        clientAndPartnerSignatureDate: PropTypes.shape({
            client: PropTypes.shape({
                type: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
    resignUrl: PropTypes.shape({
        client: PropTypes.string,
        partner: PropTypes.string,
    }).isRequired,
    partnerShouldSign: PropTypes.bool,
    enLigne: PropTypes.bool,
    requiredFieldActivationDateReducer: PropTypes.string,
};

export default flowRight([
    connect(({ requiredFieldActivationDateReducer }) => ({
        requiredFieldActivationDateReducer,
    }), { getRequiredFieldActivationDate }), lifecycle({
        componentDidMount() {
            this.props.getRequiredFieldActivationDate('investorProfiles');
        },
    }),
])(SignatureStatus);
