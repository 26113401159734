import Router from 'components/routes/routers/simulation/lmnp/LmnpSimulationRouter';
import CommonCreation from '../common/Creation';
import commonValidation from '../common/formValidate';
import initialValues from './initialValues';

const lmnp = {
    // human readable title for this kind of product
    label: 'LMNP',
    // it may have additional fields (the RIP is given as property)
    formComponent: CommonCreation,
    // it may have to validate data (must be a validator signature)
    formValidate: (errors, values, props) => commonValidation(values, props, errors),
    // it may have initial values
    initialValues,
    // and an entry point for the form
    router: Router,
    entryPoint: 'resume',
};

export default lmnp;
