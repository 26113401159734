// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group-grid {
    display: block;
    margin-bottom: 5rem;
}

.form-group-grid > h6 {
    font-size: 17px;
    color: #464646;
    margin-bottom: 30px;
    font-weight: bold;
    height: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/group/Grid.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":[".form-group-grid {\n    display: block;\n    margin-bottom: 5rem;\n}\n\n.form-group-grid > h6 {\n    font-size: 17px;\n    color: #464646;\n    margin-bottom: 30px;\n    font-weight: bold;\n    height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
