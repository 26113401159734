import React, { Component, Fragment } from 'react';
import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSimulationSummary } from 'actions';
import Hypothesis from './Hypothesis';
import HypothesisBis from './HypothesisBis';

class Result extends Component {
    componentDidMount() {
        this.props.getSimulationSummary(this.props.id);
    }

    render() {
        const { results } = this.props;
        const { products } = this.props.data;

        if (isEmpty(results)) {
            return null;
        }

        return (
            <Fragment>
                <Hypothesis results={results} products={products} />
                <HypothesisBis results={results} products={products} />
            </Fragment>
        );
    }
}

Result.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
        products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        duration: PropTypes.string.isRequired,
        subscriptionDate: PropTypes.string.isRequired,
    }).isRequired,
    results: PropTypes.shape({}).isRequired,
    getSimulationSummary: PropTypes.func.isRequired,
};

export default connect(
    (state) => ({ results: state.simulationSummary }),
    { getSimulationSummary },
)(Result);
