import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { attachScrollNode } from '../withScrollNode';

import './Main.scss';

const Main = forwardRef(({ children, noScroll }, ref) => (
    <main id="main" className={classNames({ noScroll })} ref={ref}>
        {children}
    </main>
));

Main.propTypes = {
    children: PropTypes.node,
    noScroll: PropTypes.bool,
};

Main.defaultProps = {
    children: null,
};

export default attachScrollNode(Main);
