import { flowRight, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import dataForm from 'components/dataForm';
import FormButtons from 'components/FormButtons';
import Title from 'components/Title';
import Form from 'components/simulation/FormStep';
import Checkbox from 'components/form/Checkbox';
import contractLoader from '../../contractLoader';
import processValues from '../processValues';
import formValidate from './formValidate';
import getLifeInsuranceData from '../../../../common/utils/getLifeInsuranceData';

import './Verify.scss';
import PdfComponent from '../../../editPdf/pdfComponent';

const pvp = {
    isPhysicalPerson: 'Votre client est une personne physique',
    is18orMore: 'Votre client est majeur',
    isNotGuarded: 'Qu\'il n’est pas soumis à un régime de protection (tutelle, curatelle …)',
    isNoJointContract: 'Qu\'il ne s\'agit pas d\'une co-souscription',
    automaticWithdrawal: 'Que le règlement est réalisé par prélèvement ' +
        'automatique sur un compte bancaire domicilié en France au nom du souscripteur',
    isFrenchTaxResident: 'Qu\'il est résident fiscal français',
    isResident: 'Qu\'il n\'est pas non résident',
    isGeneralClause: 'Qu\'il a sélectionné la clause générale (dans le cas de la mise en place d\'une autre ' +
        'typologie de clause la souscription devra être réalisée sur papier)',
    agreeWithTheseisAllocation: 'Qu\'il accepte l’allocation préconisée par son conseiller lors du précédent entretien',
    agreeWithInfinityDuration: 'Qu\'il souhaite une durée viagère de contrat',
};

const target = {
    isPhysicalPerson: 'Votre client est une personne physique',
    is18orMore: 'Votre client est majeur',
    isLessThan80: 'Votre client a moins de 80 ans',
    isCapable: 'Il s\'agit d\'un majeur capable',
    isNotGuarded: 'Qu\'il n’est pas soumis à un régime de protection (tutelle, curatelle …)',
    isNoJointContract: 'Qu\'il ne s\'agit pas d\'une co-souscription',
    isFrenchTaxResident: 'Qu\'il est résident fiscal français',
    agreeWithTheseisAllocation: 'Qu\'il accepte l’allocation préconisée par son conseiller lors du précédent entretien',
    allDocumentsAreEnclosed: 'Que l\'ensemble des justificatifs nécéssaires à la souscription sont présents au dossier',
    isGeneralClause: 'Que la clause bénéficiaire est une clause générale (à défaut, fournir une copie de clause sur papier libre signée par le client).',
};

const prerequisites = { pvp, target };

export const prerequisitesNames = (contractType = '') => Object.keys(prerequisites[getLifeInsuranceData(contractType, 'target', 'pvp')]);

export const verifyPrerequisites = (verify, contractType) => verify && prerequisitesNames(getLifeInsuranceData(contractType, 'target', 'pvp')).reduce((result, value) => result && verify[value], true);

const VerifyInner = ({
    handleSubmit,
    params: { id, contractType },
    contract: { data = {} },
}) => (
    <Form handleSubmit={handleSubmit}>
        <div className="simulation-form-inner" id="simulationFormInner">
            <div className="container">
                <Title>
                    <b>
                        Afin de confirmer la possibilité de réaliser la souscription en signature électronique, nous
                        vous remercions de confirmer que :
                    </b>
                </Title>
                {Object.entries(prerequisites[getLifeInsuranceData(contractType, 'target', 'pvp')]).map(([name, title]) => (
                    <Fragment>
                        {'allDocumentsAreEnclosed' === name ?
                            <div className="div-fields">
                                <Field
                                    key={name}
                                    title={title}
                                    component={Checkbox}
                                    name={`data.verify.${name}`}
                                />
                                <PdfComponent route="/api/documents/pdf/liste_des_pieces_jutificatives" />
                            </div>
                            :
                            <Field
                                key={name}
                                title={title}
                                component={Checkbox}
                                name={`data.verify.${name}`}
                            />}
                    </Fragment>
                ))}
                {verifyPrerequisites(get('verify', data), contractType) ? (
                    <div className="verify-ok">
                        <p>
                            Bonne nouvelle, la souscription électronique est disponible
                            pour votre client. Nous vous invitons à préparer les éléments suivants pour la valider :
                        </p>
                        <ul>
                            <li>
                                une facture de mobile au nom de votre client correspondant au numéro de
                                la ligne utilisée pour la validation de la souscription de ce contrat
                            </li>
                            <li>
                                un justificatif de domicile de moins de 3 mois dans le cas où l&apos;adresse de
                                votre client ne correspond pas ou en cas de facture de mobile professionnel
                                (si la facture de mobile ne suffit pas)
                            </li>
                            <li>une pièce d&apos;identité en cours de validité</li>
                            <li>le RIB</li>
                            <li>le justificatif d&apos;origine des fonds pour une souscription &gt;= à 150 000€</li>
                        </ul>
                    </div>
                ) : null}
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/contracts/`}
            list={`/rip/${id}/contracts/`}
        />
    </Form>
);

VerifyInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
        contractType: PropTypes.string,
    }).isRequired,
    contract: PropTypes.shape({
        data: PropTypes.shape({
            verify: PropTypes.shape({
                isPhysicalPerson: PropTypes.bool,
                is18orMore: PropTypes.bool,
                isNotGuarded: PropTypes.bool,
                isNoJointContract: PropTypes.bool,
                automaticWithdrawal: PropTypes.bool,
            }),
        }),
    }).isRequired,
};

const Verify = flowRight([
    contractLoader,
    dataForm({
        autoSave: false,
        processValues,
        form: 'contract-verify',
        id: ({ params: { contractId } }) => contractId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, contractId, contractType },
        }) => navigate(`/rip/${id}/contracts/${contractId}/conditions/${contractType}`),
        type: 'contract',
        validate: formValidate,
    }),
])(VerifyInner);

export default Verify;
