import PropTypes from 'prop-types';
import React from 'react';

const Head = ({ children }) => (
    <thead>
        <tr>
            {children}
        </tr>
    </thead>
);

Head.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Head;
