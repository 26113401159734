import { isEmpty } from 'lodash/fp';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GridLayout } from 'components/form/layout';
import { TextField, MarkdownField } from 'components/form/field';

import './EmailTemplateForm.scss';

const EmailTemplateForm = ({ variables, simpleMdeOptions }) => (
    <Fragment>
        <GridLayout className="single condensed full-width">
            <TextField
                title="Sujet"
                name="subject"
            />
            <MarkdownField
                title="Contenu"
                name="body"
                options={simpleMdeOptions}
            />
        </GridLayout>
        {!isEmpty(variables) && (
            <Fragment>
                <div className="alert alert-warning text-center">
                    Attention : les &eacute;l&eacute;ments entre &apos;{'{{ ... }}'}&apos; sont automatis&eacute;s.
                    Toute modification de ces champs entrainera une correction du texte.
                </div>
                <hr />
                <b>Variables :</b>
                <table className="table table-condensed table-bordered email-templates-variables">
                    <thead>
                        <tr>
                            <th className="var">Clé</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(variables).map(([key, description]) => (
                            <tr key={key}>
                                <td className="var">
                                    <span>&#123;&#123; {key} &#125;&#125;</span>
                                </td>
                                <td>{description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        )}
    </Fragment>
);

EmailTemplateForm.propTypes = {
    variables: PropTypes.shape({}).isRequired,
    simpleMdeOptions: PropTypes.shape({}),
};

EmailTemplateForm.defaultProps = {
    simpleMdeOptions: null,
};

export default EmailTemplateForm;
