import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName, setDisplayName } from 'recompose';

const Context = createContext(false);

const withDisabledForm = (WrappedComponent) => {
    const FormDisabledProvider = (props) => (
        <Context.Provider value>
            <WrappedComponent {...props} disabled />
        </Context.Provider>
    );

    const displayName = wrapDisplayName(WrappedComponent, 'withDisabledFormProvider');

    return setDisplayName(displayName)(FormDisabledProvider);
};

export const connectDisabledForm = (WrappedComponent) => {
    const FormDisabledConsumer = ({ disabled = false, ...props }) => {
        const context = useContext(Context);

        return <WrappedComponent disabled={context || disabled} {...props} />;
    };

    FormDisabledConsumer.propTypes = {
        disabled: PropTypes.bool,
    };

    const displayName = wrapDisplayName(WrappedComponent, 'withDisabledFormConsumer');

    return setDisplayName(displayName)(FormDisabledConsumer);
};

export default withDisabledForm;
