import { get, flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { formValues } from 'redux-form';
import { setInvestorProfileSource } from 'actions';
import Button from 'components/Button';
import computeInvestorProfile, { profiles } from 'common/model/rip/investorProfile';
import hasPartner from 'common/utils/hasPartner';
import RadioQuestion from '../../../../InvestorProfile/RadioQuestion';
import withRouter from '../../../../withRouter';

const Form = ({
    beneficiary,
    setSource,
    navigate,
    rip: {
        id,
        familySituation,
        investorProfiles,
        firstName,
        partnerFirstName,
        dualRip,
    },
}) => {
    const toInvestorProfile = (target) => () => {
        // persist the entry point to come back
        setSource(`/rip/${id}/simulations/new`, 'Retour à la simulation');

        // then move on the investor profile
        return navigate(`/rip/${id}/investorProfile/${target}`);
    };

    const withPartner = 'cohabitation' === familySituation ? dualRip : hasPartner(familySituation);
    const investorProfile = computeInvestorProfile(get(beneficiary, investorProfiles));

    return (
        <div>
            {withPartner ? (
                <RadioQuestion
                    choices={{
                        client: firstName,
                        partner: partnerFirstName,
                    }}
                    name="beneficiary"
                    title="Bénéficiaire"
                    required
                />
            ) : null}
            {!investorProfile ? (
                <div>
                    <p className="form-field-error">Questionnaire de risque non renseigné</p>
                    <Button onClick={toInvestorProfile(beneficiary)}>
                        <p>Accéder au questionnaire de risque</p>
                    </Button>
                </div>
            ) : null}
            {'declined' === investorProfile ? (
                <p className="form-field-error">{profiles.declined}</p>
            ) : null}
        </div>
    );
};

Form.propTypes = {
    beneficiary: PropTypes.string.isRequired,
    setSource: PropTypes.func.isRequired,
    navigate: PropTypes.shape({}).isRequired,
    rip: PropTypes.shape({
        id: PropTypes.string,
        familySituation: PropTypes.string,
        investorProfiles: PropTypes.shape({}),
        firstName: PropTypes.string,
        partnerFirstName: PropTypes.string,
        dualRip: PropTypes.bool,
    }).isRequired,
};

export default flowRight([
    withRouter,
    connect(null, { setSource: setInvestorProfileSource }),
    formValues({ beneficiary: 'beneficiary' }),
])(Form);
