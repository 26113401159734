import { find } from 'lodash/fp';
import fullName from './fullName';
import hasPartner from './hasPartner';
import getSubjectName from './getSubjectName';

const getFullName = (rip, owner) => {
    const match = owner.match(/^sci-(.*)/);

    if (match) {
        const sci = find({ id: match[1] }, rip.sci);

        return `SCI ${sci && sci.sciName}`;
    }

    switch (owner) {
        case 'client':
            return fullName({ firstName: rip.firstName, lastName: rip.lastName });
        case 'partner':
            return fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName });
        case 'clientAndPartner':
            return `${fullName({ firstName: rip.firstName, lastName: rip.lastName })} et ${fullName({
                firstName: rip.partnerFirstName,
                lastName: rip.partnerLastName,
            })}`;
        case 'other':
            return 'Autre';
        default:
            return getSubjectName(owner, rip);
    }
};

const getOwnerFullName = (rip, owner) => {
    if (rip.originalRip) {
        return getOwnerFullName(rip.originalRip, owner);
    }

    if (!hasPartner(rip.familySituation)) {
        return getFullName(rip, owner || 'client');
    }

    if (!owner) {
        return 'NC';
    }

    return getFullName(rip, owner);
};

export default getOwnerFullName;
