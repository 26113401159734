import React, { useMemo, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import { syncRipAndDownload } from 'actions';
import Icon from 'common/components/Icon';
import useFetch from 'components/useFetch';
import { withSession } from 'components/sessions';
import Spinner from 'components/Spinner';
import { compose } from 'recompose';
import Layout from '../layouts/Layout';
import Footer from '../rip/Footer';
import useSettings from '../sessions/useSettings';
import { GET } from '../../utils/httpMethods';

import './List.scss';
import withRouter from '../withRouter';

const renderCif = ({ file, redirect = false, icon, navigate }) => (
    <div className="document-entry">
        <div className="document">
            <Icon icon={icon} />
            <p>{file}</p>
            {redirect && (
                <button onClick={() => navigate(redirect)}>
                    <Icon icon="arrow-right" />
                </button>
            )}
        </div>
    </div>
);

const renderPdfEditable = ({ ripId, onDocumentClicked, file, id, downloadable = true, icon, navigate, signable = true }) => {
    const [response, loading] = useFetch({ url: `/api/sign-status/document/${id}/${ripId}` });

    return (
        <div className="document-entry">
            <div className="document">
                <Icon icon={icon} />
                <p>{file}</p>
                {downloadable && (
                    <button
                        onClick={() => {
                            onDocumentClicked(ripId, id, navigate);
                        }}
                    >
                        <Icon icon="download" />
                    </button>
                )}
            </div>
            {loading && signable && (
                <Spinner />
            )}
            {!loading && signable && (
                <div className="options">
                    <button className="load" onClick={() => navigate(`/rip/${ripId}/documents/${id}/upload`)}>
                        Charger pour signature électronique
                    </button>
                    {response && response.hasOngoingSignatureProcess && (
                        <button className="status" onClick={() => navigate(`/rip/${ripId}/documents/${id}/sign`, { back: `/rip/${ripId}/documents` })}>
                            Accéder au suivi
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

const DocumentInner = ({ ripId, onDocumentClicked, file, id, downloadable = true, redirect = false, icon, navigate, signable = true }) => {
    const isCif = 'Cif' === file;

    return (
        <div>
            {isCif &&
            renderCif({ file, redirect, icon, navigate })}
            {!isCif &&
                renderPdfEditable({ ripId, onDocumentClicked, file, id, downloadable, redirect, icon, navigate, signable })
            }
        </div>
    );
};

DocumentInner.propTypes = {
    id: PropTypes.string,
    file: PropTypes.string.isRequired,
    downloadable: PropTypes.bool,
    signable: PropTypes.bool,
    redirect: PropTypes.string,
    icon: PropTypes.string.isRequired,
    navigate: PropTypes.shape({}),
    onDocumentClicked: PropTypes.func.isRequired,
    ripId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    onDocumentClicked: (ripId, docId, navigate) => dispatch(syncRipAndDownload(ripId, navigate, null, `/api/documents/downloadable/${ripId}/${docId}`)),
});

const Document = connect(null, mapDispatchToProps)(DocumentInner);

const DocumentSection = ({ title, documents, ripId, icon, navigate }) => (
    <div className="section">
        <h3 className="section-title">{title}</h3>
        <div className="section-documents">
            {documents.map((document) => (
                <Document key={document.file} ripId={ripId} navigate={navigate} icon={icon} {...document} />
            ))}
        </div>
    </div>
);

DocumentSection.propTypes = {
    title: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    ripId: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    navigate: PropTypes.shape({}),
};

const DocumentList = ({ params: { id: ripId }, navigate, session }) => {
    // we update session to get latest value of settings
    useEffect(() => {
        (async () => {
            await session.sync();
        })();
    }, []);

    const isCa = session.permissions.isCaseManager();

    const documents = useSettings('global.documents.documents');
    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        documents.map((doc) => {
            (async () => {
                await GET(`/api/documents/synchroDesPdfEntreAzureEtPostgre/${ripId}/${doc.id}`);
            })();
        });
    });

    const wordDocs = useMemo(() => [{ file: 'Cif', downloadable: false, redirect: `/rip/${ripId}/documents/cif`, signable: false }], [ripId]);

    return (
        <Layout>
            {isEmpty(documents) && !isCa ? (
                <div className="text-center">
                    <Icon icon="ban" />
                    <h1>Aucun document disponible</h1>
                </div>
            ) : (
                <Fragment>
                    <h1 className="text-center">
                        Documents
                    </h1>
                    <div className="documents">
                        {isCa && (
                            <DocumentSection ripId={ripId} title="Documents word" documents={wordDocs} icon="file-word-o" navigate={navigate} />
                        )}
                        {!isEmpty(documents) && (
                            <DocumentSection ripId={ripId} title="Documents pdf editables" documents={documents} icon="file-pdf-o" navigate={navigate} />
                        )}
                    </div>
                </Fragment>
            )}
            <Footer back="/" last />
        </Layout>
    );
};

DocumentList.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    navigate: PropTypes.shape({}),
    session: PropTypes.shape({
        sync: PropTypes.func,
        permissions: PropTypes.shape({
            isCaseManager: PropTypes.func,
        }),
    }),
};

const Doc = compose(
    withSession,
    withRouter,
)(DocumentList);

export default Doc;
