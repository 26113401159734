import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { downloadPdf } from 'actions';
import DownloadButton from 'components/DownloadButton';
import FormGroup from 'components/form/Group';
import dataForm from 'components/dataForm';
import { TextField } from 'components/form/field';
import FormButtons from 'components/FormButtons';
import Icon from 'common/components/Icon';
import { POST } from 'utils/httpMethods';
import handleError from 'utils/handleError';
import { withSession } from '../../../../sessions';
import Form from '../Form';
import { errorValidate } from '../Product/formValidate';
import simulationLoader from '../simulationLoader';
import Summary from './Summary';

const DetailsInner = ({
    handleSubmit,
    params: { id, simulationId },
    simulation,
    download,
    session,
    rip,
}) => (
    <Form handleSubmit={handleSubmit}>
        <div className="simulation-form-inner">
            <div className="container">
                <TextField
                    name="name"
                    title="Nom de la simulation"
                    placeholder="saisissez le nom de la simulation"
                    required
                />
                <Summary simulation={simulation} />
                {session.permissions.mayDownloadSimulationPDF(simulation, rip) ? (
                    <FormGroup>
                        <DownloadButton onClick={() => download(`/api/simulations/${simulationId}/pdf`)}>
                            <Icon icon="file-pdf-o" /> Télécharger
                        </DownloadButton>
                    </FormGroup>
                ) : null}
            </div>
        </div>
        <FormButtons
            back={`/rip/${id}/simulations/${simulationId}/product`}
            list={`/rip/${id}/simulations/`}
        />
    </Form>
);

DetailsInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        simulationId: PropTypes.string.isRequired,
    }).isRequired,
    simulation: PropTypes.shape({}).isRequired,
    download: PropTypes.func.isRequired,
    session: PropTypes.shape({
        permissions: PropTypes.shape({
            mayDownloadSimulationPDF: PropTypes.func,
        }),
    }).isRequired,
    rip: PropTypes.shape({}).isRequired,
};

const Details = compose(
    simulationLoader,
    withSession,
    dataForm({
        form: 'simulation-details',
        id: ({ params: { simulationId } }) => simulationId,
        onSubmitSuccess: (result, dispatch, {
            navigate,
            params: { id, simulationId },
        }) => POST(`/api/simulations/${simulationId}/complete`)
            .then(() => navigate(`/rip/${id}/simulations/`))
            .catch(handleError),
        type: 'simulation',
        validate: errorValidate,
    }),
    connect(null, { download: downloadPdf }),
)(DetailsInner);

export default Details;
