import { flowRight, round } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { change, formValues } from 'redux-form';
import {
    computeCollateralInitialContribution,
    computeCollateralMonthlyContribution,
} from 'common/model/simulation/common/funding/collateralContribution';
import computeFunding from 'common/model/simulation/common/funding';
import { GridLayout } from 'components/form/layout';
import { IntegerField, PercentageField } from 'components/form/field';

const InfineFormInner = ({
    fundingAmount,
    instalmentCosts,
    collateralInitialContribution,
    collateralRate,
    data,
    deferralType,
    deferralDuration,
    fundingType,
    fundingDuration,
    fundingRate,
    fundingADI,
    fundingPersonalContribution,
    handlingOfLeapInterests,
    product,
    propertyActDate,
    propertyDeliveryDate,
    needFunding,
    update,
    fundedMonths,
}) => {
    const { funding: { amountToCapitalize } } = computeFunding({
        product,
        collateralInitialContribution,
        collateralRate,
        deferralType,
        deferralDuration,
        fundingADI,
        fundingDuration,
        fundingPersonalContribution,
        fundingRate,
        fundingType,
        handlingOfLeapInterests,
        needFunding,
        propertyActDate: new Date(propertyActDate),
        propertyDeliveryDate: new Date(propertyDeliveryDate),
        data,
    }, {
        fundingAmount,
        instalmentCosts,
    });

    const changeMonthlyContribution = (toArgs) => (e) => update(
        'simulation-funding',
        'collateralMonthlyContribution',
        round(computeCollateralMonthlyContribution(...toArgs(e.target.value))),
    );

    const changeInitialContribution = (toArgs) => (e) => update(
        'simulation-funding',
        'collateralInitialContribution',
        round(computeCollateralInitialContribution(...toArgs(e.target.value))),
    );

    return (
        <GridLayout>
            <PercentageField
                name="collateralRate"
                title="Taux de nantissement"
                onChange={collateralInitialContribution ? changeMonthlyContribution((value) => [
                    amountToCapitalize,
                    value / 1200, fundedMonths + fundingDuration,
                    collateralInitialContribution,
                ]) : null}
            />
            <IntegerField
                name="collateralInitialContribution"
                title="Apport en nantissement"
                min={0}
                onChange={collateralRate ? changeMonthlyContribution((value) => [
                    amountToCapitalize,
                    collateralRate / 12,
                    fundedMonths + fundingDuration,
                    value,
                ]) : null}
            />
            <IntegerField
                name="collateralMonthlyContribution"
                title="Apport mensuel nanti"
                min={0}
                onChange={collateralRate ? changeInitialContribution((value) => [
                    amountToCapitalize,
                    collateralRate / 12,
                    fundedMonths + fundingDuration,
                    value,
                ]) : null}
            />
        </GridLayout>
    );
};

InfineFormInner.propTypes = {
    collateralInitialContribution: PropTypes.number,
    collateralRate: PropTypes.shape({}),
    fundingDuration: PropTypes.number,
    fundingType: PropTypes.string,
    fundingRate: PropTypes.string,
    fundingADI: PropTypes.string,
    propertyActDate: PropTypes.string,
    propertyDeliveryDate: PropTypes.string,
    update: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    deferralType: PropTypes.string,
    deferralDuration: PropTypes.number,
    fundingPersonalContribution: PropTypes.number,
    handlingOfLeapInterests: PropTypes.string,
    product: PropTypes.string,
    needFunding: PropTypes.bool.isRequired,
    fundingAmount: PropTypes.number,
    instalmentCosts: PropTypes.shape({
        investmentWithTVA: PropTypes.number.isRequired,
        tva: PropTypes.number.isRequired,
    }).isRequired,
    fundedMonths: PropTypes.number,
};

InfineFormInner.defaultProps = {
    collateralInitialContribution: null,
    collateralRate: null,
    fundingAmount: null,
    fundingDuration: null,
    fundingType: null,
    fundingRate: null,
    fundingADI: null,
    propertyActDate: null,
    propertyDeliveryDate: null,
};

const InfineForm = flowRight([
    formValues({ fundingType: 'fundingType' }),
    branch(({ fundingType }) => 'in-fine' !== fundingType, renderNothing),
    formValues({
        collateralInitialContribution: 'collateralInitialContribution',
        collateralRate: 'collateralRate',
        data: 'data',
        deferralType: 'deferralType',
        deferralDuration: 'deferralDuration',
        fundingDuration: 'fundingDuration',
        fundingRate: 'fundingRate',
        fundingADI: 'fundingADI',
        fundingPersonalContribution: 'fundingPersonalContribution',
        handlingOfLeapInterests: 'handlingOfLeapInterests',
        needFunding: 'needFunding',
        propertyActDate: 'propertyActDate',
        propertyDeliveryDate: 'propertyDeliveryDate',
        product: 'product',
    }),
    connect(null, { update: change }),
])(InfineFormInner);

export default InfineForm;
