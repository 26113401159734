import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';

import './SuccessModalPrisme.scss';
import Icon from '../common/components/Icon';
import check from '../images/pictos/check.svg';

const SuccessModalPrisme = ({ close }) => (
    <div className="modal-container">
        <Icon icon="times" className="close-action" onClick={close} />
        <div className="success-info-container">
            <img className="logo" src={check} alt="Logo" />
            <p>Les informations du RIP ont bien été envoyées.</p>
        </div>
    </div>
);

SuccessModalPrisme.propTypes = {
    close: PropTypes.func.isRequired,
};

export default compose(connect(null, (dispatch) => ({
    close: () => {
        dispatch(setModal(null));
    },
})))(SuccessModalPrisme);
