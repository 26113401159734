import { flowRight } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { SelectField } from '../../form/field';

const ChildrenInner = ({ children, required, child, ...props }) => (
    <SelectField {...props} required={required}>
        <option />
        {(children || child).map(({ id, firstNames }) => (
            <option key={id} value={id}>
                {firstNames}
            </option>
        ))}
    </SelectField>
);

ChildrenInner.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({
        firstNames: PropTypes.string.isRequired,
    })).isRequired,
    child: PropTypes.arrayOf(PropTypes.shape({
        firstNames: PropTypes.string.isRequired,
    })).isRequired,
    required: PropTypes.bool,
};

const Children = flowRight([
    formValues({
        children: 'children',
    }),
])(ChildrenInner);

export default Children;
