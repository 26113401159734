const getRecommendationIndice = (isLodeomCif, values) => {
    let indiceLodeomCif;
    if (isLodeomCif && 0 < values.length) {
        // eslint-disable-next-line array-callback-return
        values.map((value, index) => {
            if ('LODEOMCIF' === value.type) {
                indiceLodeomCif = index;
            }
        });
    }

    return indiceLodeomCif;
};

export default getRecommendationIndice;
