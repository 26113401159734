import { flowRight } from 'lodash/fp';
import { withProps } from 'recompose';
import modeProvider from '../list/modeProvider';

const getActiveListUrl = (mode) => {
    switch (mode) {
        case 'manager':
            return '/team';
        case 'user':
        default:
            return '/';
    }
};

const withActiveListUrl = (prop) => flowRight([
    modeProvider(prop, 'rip'),
    withProps(({ [prop]: mode }) => ({ [prop]: getActiveListUrl(mode) })),
]);

export default withActiveListUrl;
