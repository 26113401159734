// Revenu fiscal de référence.
// Pour l'instant le RFR = revenus exonérés + revenus après abattement
// + abattement des 40% sur les dividendes - pensions alimentaires.

import { filter, flow, map, sum } from 'lodash/fp';

const computeReferenceIncomes = (rip, { incomes, deductionsDetails }) => {
    // Montant total des dividendes.
    const dividends = flow([
        filter(({ type }) => 'dividends' === type),
        map(({ income }) => income),
        sum,
    ])(rip.incomes);

    // Montant des revenus exonérés.
    const exemptedIncomes = flow([
        filter(({ type }) => 'exemptedIncome' === type),
        map(({ income }) => income),
        sum,
    ])(rip.incomes);

    // Montant total des pensions alimentaires.
    const alimonyDeductions = deductionsDetails.alimony ? deductionsDetails.alimony.computedAmount : 0;

    // Calcul du revenu de référence.
    const referenceIncomes = (exemptedIncomes + incomes + (0.4 * dividends)) - alimonyDeductions;

    return { referenceIncomes };
};

export default computeReferenceIncomes;
