import React, { Fragment } from 'react';
import { EmailField, TextField } from 'components/form/field';

const ERIPUser = () => (
    <Fragment>
        <TextField
            title="Prénom"
            name="firstName"
            required
        />
        <TextField
            title="Nom"
            name="lastName"
            required
        />
        <EmailField
            title="Email"
            placeholder="nicolas.dupond@yahoo.fr"
            required
            name="email"
        />
    </Fragment>
);

export default ERIPUser;
