/* eslint-disable no-nested-ternary */
import Cookies from 'universal-cookie';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import React, { useState, useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import { compose, withState } from 'recompose';
import { Link } from 'react-router-dom';
import withRouter from './withRouter';
import { POST } from '../utils/httpMethods';
import PublicLayout from './PublicLayout';
import { withSession } from './sessions';
import getQueryVariable from '../utils/getQueryVariable';

import './login.scss';

const logUserIn = ({ session, navigate, userData, redirectUrl = '/' }) => {
    // update the session first
    session.update(userData);
    // then redirect to the right url
    navigate(redirectUrl);
};

const Login = ({
    handleSubmit,
    loginError,
    navigate,
    session,
    location: { search },
    isFromTopinvest,
}) => {
    const invalidCredentials = 'credentials' === loginError;
    const isDisconnected = 'connection' === loginError;
    const isThrottled = 'throttling' === loginError;

    const [isSSO, setIsSSO] = useState(false);
    const [isFieldsHidden, setIsFieldsHidden] = useState(false);
    const [isConnectedFromTopinvest, setIsConnectedFromTopinvest] = useState(false);

    const login = getQueryVariable(search, 'login');
    const token = getQueryVariable(search, 'token');

    const cookies = new Cookies();
    const jeton = cookies.get('__jeton');

    const redirectUrl = getQueryVariable(search, 'redirectUrl');

    useEffect(() => {
        (async () => {
            if (login && token) {
                setIsSSO(true);
                const res = await POST('/api/login-with-token', { body: { login, token } });

                if (200 === res.status) {
                    const userData = await res.json();

                    logUserIn({ session, navigate, userData, redirectUrl });
                }
            }
            // Pour les utilisateurs provenant de topinvest uniquement
            if (jeton) {
                setIsSSO(true);
                setIsConnectedFromTopinvest(true);

                try {
                    const jetonSplit = jeton.split('¤')[0];
                    const response = await POST('/api/secretKey', { body: { jeton: jetonSplit } });
                    const { tokenDecrypted } = await response.json();

                    const { email } = jwtDecode(tokenDecrypted);
                    const urlDestinationForUserTopinvest = decodeURI(jeton.split('¤')[1]);
                    const topinvestUrl = decodeURI(jeton.split('¤')[2]);

                    const res = await POST('/api/login-with-topinvest', {
                        headers:
                        {
                            Authorization: `Bearer ${tokenDecrypted}`,
                        },
                        body: {
                            identifiant: email,
                            redirectUrl: urlDestinationForUserTopinvest,
                            originUrl: topinvestUrl,
                        },
                    });

                    if (200 === res.status) {
                        const userData = await res.json();

                        logUserIn({ session, navigate, userData, redirectUrl: urlDestinationForUserTopinvest });
                    }
                } catch (error) {
                    console.log('❌ ~ error connexion topinvest => ', error);
                }
            }

            if (isFromTopinvest) {
                setIsFieldsHidden(true);
            }
        })();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            {isFieldsHidden ? (
                <PublicLayout className={classNames({ 'has-error': invalidCredentials })} >
                    <p className="info-reconnection">Votre session a expiré. <br /> Pour rejoindre le relevé patrimonial, <br /> veuillez vous connecter sur Topinvest</p>
                    <button className="info-reconnection-button" onClick={() => window.location.assign('https://dev-topinvest.idsoft.local/')}>
                        Connexion à Topinvest
                    </button>
                </PublicLayout>
            ) : (
                isConnectedFromTopinvest ? (
                    <PublicLayout className={classNames({ 'has-error': invalidCredentials })} >
                        <Alert variant="info">Authentification en cours ...</Alert>
                    </PublicLayout>
                ) : (
                    <PublicLayout
                        className={classNames({ 'has-error': invalidCredentials })}
                        floatingContent={(
                            <Link to="/reset">
                                <span>Mot de passe oublié</span>
                            </Link>
                        )}
                        buttonText="Me connecter"
                        buttonProps={{ type: 'submit' }}
                    >
                        {isSSO && <Alert variant="info">Authentification en cours ...</Alert>}
                        <label htmlFor="login">
                            <h5>Login</h5>
                            <Field
                                component="input"
                                type="text"
                                id="login"
                                name="login"
                                placeholder="Saisissez votre login"
                            />
                        </label>
                        <label htmlFor="password">
                            <h5>Mot de passe</h5>
                            <Field
                                component="input"
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Saisissez votre mot de passe"
                            />
                            {invalidCredentials ? (
                                <p className="error">
                                    Login ou mot de passe incorrect merci de vérifier vos informations de connexion.
                                </p>
                            ) : null}
                            {isThrottled ? (
                                <p className="error">
                                    En raison de multiples échecs de connexion nous avons bloqué votre compte pour 1 minute.
                                </p>
                            ) : null}
                            {isDisconnected ? (
                                <p className="error">
                                    Vous ne pouvez pas vous connecter si vous n&apos;êtes pas connecté à internet
                                </p>
                            ) : null}
                        </label>
                    </PublicLayout>
                ))}
        </form>
    );
};

Login.propTypes = {
    loginError: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    navigate: PropTypes.shape({}).isRequired,
    session: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({
        search: PropTypes.func,
    }).isRequired,
    isFromTopinvest: PropTypes.string,
};

Login.defaultProps = {
    loginError: null,
};

export default compose(
    withSession,
    withRouter,
    withState('loginError', 'setLoginError', null),
    reduxForm({
        form: 'login',
        onSubmit: (values, dispatch, { setLoginError }) => POST('/api/login', {
            body: values,
        })
            .then((response) => response.json())
            .catch((response) => {
                if (401 === response.status) {
                    // inform the user we got wrong credentials
                    setLoginError('credentials');
                } else if (429 === response.status) {
                    // inform the user he has to wait
                    setLoginError('throttling');
                } else if (undefined === response.status) {
                    // we couldn't contact the server somehow
                    setLoginError('connection');
                }

                // anyhow the form is not valid
                return Promise.reject();
            }),
        onSubmitSuccess: async (userData, dispatch, { session, navigate }) => {
            logUserIn({ session, navigate, userData });
        },
    }),
)(Login);
