import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const InitialsBadge = ({ children, className }) => (
    <div className={classNames('initials-badge', className)}>
        {children}
    </div>
);

InitialsBadge.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

InitialsBadge.defaultProps = {
    className: null,
};

export default InitialsBadge;
