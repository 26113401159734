import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import InteractiveItem from './InteractiveItem';

import './Wizard.scss';

const Wizard = ({
    className,
    prefixTemplate,
    items,
    itemComponent: Item,
}) => {
    if (null === items) {
        return null;
    }

    const params = useParams();

    const prefix = prefixTemplate ? prefixTemplate(params) : '';

    return (
        <div className={classNames('container', 'wizard', className)}>
            <div className="wizard-container">
                {items.map(({ target, hide = false, ...props }) => (!hide ? (
                    <Item
                        key={target}
                        {...props}
                        to={`${prefix}/${target}`}
                    />
                ) : null))}
            </div>
        </div>
    );
};

Wizard.propTypes = {
    className: PropTypes.string,
    prefixTemplate: PropTypes.func,
    itemComponent: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        show: PropTypes.func,
    })),
};

Wizard.defaultProps = {
    className: null,
    prefixTemplate: null,
    itemComponent: InteractiveItem,
    items: null,
};

export default Wizard;

export { InteractiveItem };
export { default as StaticItem } from './StaticItem';
export { default as FormItem } from './FormItem';
