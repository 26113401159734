import React from 'react';
import { mapProps } from 'recompose';
import moment from 'moment';
import Icon from 'common/components/Icon';
import InitialsBadge from './InitialsBadge';

const InitialsErrorBadge = mapProps(({ errorCount = 0, signatureDate = null, isAvailable }) => {
    if (!isAvailable) {
        return {
            className: 'offline',
            children: <Icon icon="wifi" title="Rip non synchronisé (hors-ligne)" />,
        };
    }

    if (errorCount) {
        return {
            className: 'invalid',
            children: <span title="Rip comportant des erreurs">{errorCount}</span>,
        };
    }

    if (!signatureDate || moment().diff(moment(signatureDate), 'months') < 10) {
        return {
            className: 'valid',
            children: <Icon icon="check" title="Rip valide" />,
        };
    }

    return {
        className: 'signatureCheck',
        children: <Icon icon="exclamation" title="Rip à actualiser (signatures il y a plus de 10 mois)" />,
    };
})(InitialsBadge);

export default InitialsErrorBadge;
