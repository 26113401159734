import { get } from 'lodash/fp';
import { isCouple } from '../../utils/hasPartner';
import validate, { checkRequiredTrue, branchValidate } from '../lib';

export default validate([
    branchValidate((errors, values, { clientSigner = null, instanceSettings = {}, rip = {} }) => instanceSettings.hasAVSubscription && 'manual' === clientSigner && !get('investorProfiles.client.decline', rip) && get('investorProfiles.client', rip) !== undefined, [
        checkRequiredTrue('clientCorrectInformationsAgreement'),
    ]),
    branchValidate(
        (errors, values, { clientSigner = null }) =>
        // check si session présente pour changer 'THESEIS' === instanceSettings.labe

            'manual' === clientSigner
        , [
            checkRequiredTrue('clientCollectInformationsAgreement'),
        ],
    ),
    branchValidate((errors, values, { clientSigner = null, instanceSettings = {} }) => 'THESEIS' === instanceSettings.label && 'manual' === clientSigner, [
        checkRequiredTrue('clientCollectInformationsShoyo'),
    ]),

    branchValidate((errors, values, { partnerSigner = null, rip = {} }) => {
        const hasPartner = isCouple(rip.familySituation) || ('cohabitation' === rip.familySituation && rip.dualRip);

        return hasPartner && 'manual' === partnerSigner;
    }, [
        checkRequiredTrue('partnerCollectInformationsAgreement'),
    ]),
    branchValidate((errors, values, { partnerSigner = null, instanceSettings = {}, rip = {} }) => {
        const hasPartner = isCouple(rip.familySituation) || ('cohabitation' === rip.familySituation && rip.dualRip);

        return 'THESEIS' === instanceSettings.label && hasPartner && 'manual' === partnerSigner;
    }, [
        checkRequiredTrue('partnerCollectInformationsShoyo'),
    ]),

    branchValidate((errors, values, { partnerSigner = null, instanceSettings = {}, rip = {} }) => {
        const hasPartner = isCouple(rip.familySituation) || ('cohabitation' === rip.familySituation && rip.dualRip);

        return instanceSettings.hasLegalDisclaimer && hasPartner && 'manual' === partnerSigner && !get('investorProfiles.partner.decline', rip) && get('investorProfiles.partner', rip) !== undefined;
    }, [
        checkRequiredTrue('partnerCorrectInformationsAgreement'),
    ]),
    checkRequiredTrue('advisorAgreement'),
]);
