import PropTypes from 'prop-types';
import React from 'react';
import { Grid, TextItem, AmountItem } from 'common/components/summary';

const LifeInsurance = ({
    recommendation: {
        description,
        initialPayment,
        monthlyPayment,
    },
}) => (
    <Grid size={3}>
        <TextItem title="Type" value={description} />
        <AmountItem title="Versement initial" value={initialPayment} />
        <AmountItem title="Versement mensuel" value={monthlyPayment} />
    </Grid>
);

LifeInsurance.propTypes = {
    recommendation: PropTypes.shape({
        description: PropTypes.string,
        initialPayment: PropTypes.number,
        monthlyPayment: PropTypes.number,
    }).isRequired,
};

export default LifeInsurance;
