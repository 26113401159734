import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Layout } from 'components/layouts';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';
import Footer from 'components/rip/Footer';
import { settingsGroups } from 'common/choices';
import { withSession } from '../../sessions';

import '../Menu.scss';

const MenuInner = ({ session }) => {
    const groups = Object.entries(settingsGroups);
    const groupsForAdminReseau = groups.filter((group) => (true === group[1].forAdminReseau));

    let groupsToMap = groups;

    if (session.isAdminReseau) {
        groupsToMap = groupsForAdminReseau;
    }

    return (
        <Layout>
            <div className="admin-menu">
                {groupsToMap.map(([group, { title, icon, description }]) => (
                    <Link to={`/admin/settings/${group}`} key={group} >
                        <div className="admin-link">
                            <div className="menu-icon">
                                <Icon icon={icon} />
                            </div>
                            {title}
                            <p>{description}</p>
                        </div>
                    </Link>
                ))
                }
            </div>
            <Footer back="/admin" last />
        </Layout>
    );
};

MenuInner.propTypes = {
    session: PropTypes.shape({
        isAdminReseau: PropTypes.bool,
    }),
};

const Menu = compose(withSession)(MenuInner);

export default Menu;
