import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { compose, branch, renderNothing } from 'recompose';
import DropDown from 'components/form/FieldDropDown';
import { FileField } from 'components/form/field';

const FundsOrigin = ({ uploader }) => (
    <DropDown title="Justificatif d'origine des fonds" name="virtual.fundsOrigin">
        <FileField
            name="fundsOrigin"
            uploader={uploader('fundsOrigin')}
            uploadText="Télécharger votre justificatif d'origine des fonds"
            reduxField="files.fundsOrigin"
        />
    </DropDown>
);

FundsOrigin.propTypes = {
    uploader: PropTypes.func.isRequired,
};

export default compose(
    formValues({ initialFunding: 'simulation.data.initialFunding' }),
    branch(({ initialFunding }) => initialFunding < 150000, renderNothing),
)(FundsOrigin);
