const isRequiredField = (createdDateRip, requiredFieldActivationDateReducer, field) => {
    const activationDateObj = requiredFieldActivationDateReducer && requiredFieldActivationDateReducer[field];

    if (!activationDateObj || !activationDateObj.activationDate) {
        return false;
    }

    const activationDate = new Date(activationDateObj.activationDate);
    const ripCreatedDate = new Date(createdDateRip);

    return ripCreatedDate > activationDate;
};

export default isRequiredField;
