import { flowRight, getOr, orderBy } from 'lodash/fp';
import moment from 'moment';
import { withProps } from 'recompose';
import { listProvider as genericListProvider } from 'components/list';
import modeProvider from 'components/list/modeProvider';
import { withSession } from '../../sessions';

const getCriteria = ({ mode, session, ...props }) => {
    switch (mode) {
        case 'all':
        case 'allForManager':
            return null;
        case 'archives':
            return session.permissions.isValidator() ? null : { userId: getOr(session.id, 'userId', props) };
        default:
            // get the user id we want to check out (by default use the authenticated user)
            return { userId: getOr(session.id, 'userId', props) };
    }
};

const getFilter = ({ mode }) => {
    switch (mode) {
        case 'archives':
            return ({ archived, deleted }) => (archived && !deleted);
        case 'outdated':
            return ({ archived, deleted, signatureDate = null }) => !(archived || deleted)
                && moment().diff(moment(signatureDate), 'months') >= 10;
        default:
            return ({ archived, deleted }) => !(archived || deleted);
    }
};

const listProvider = flowRight([
    withSession,
    modeProvider('mode', 'rip', 'user'),
    withProps(({ mode }) => ({ key: mode })),
    genericListProvider({
        criteria: getCriteria,
        sorts: {
            updatedAt: { initialOrder: 'desc', default: true },
            createdAt: { initialOrder: 'desc' },
            lastName: { initialOrder: 'asc' },
            state: {
                initialOrder: 'asc',
                sort: (order, list) => orderBy(['state', 'callbackDate'], [order, 'asc'], list),
            },
        },
        filter: getFilter,
        name: 'rip',
        type: 'rip',
    }),
]);

export default listProvider;
