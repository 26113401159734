import React, { Fragment } from 'react';
import { map } from 'lodash/fp';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import { VerticalLayout, GridLayout } from 'components/form/layout';
import { DateField, SelectField } from 'components/form/field';
import SCPICollection from './SCPICollection';
import Investment from './Investment';

const Operation = () => (
    <Fragment>
        <FormGroup>
            <Title>
                <b>L&apos;opération</b>
            </Title>
            <GridLayout className="condensed">
                <DateField name="data.subscriptionDate" title="Date de souscription" required />
                <SelectField name="data.duration" title="Durée du démembrement" required>
                    {map(
                        (years) => (
                            <option key={years} value={years}>{`${years} ans`}</option>
                        ),
                        [5, 7, 10, 15, 20],
                    )}
                </SelectField>
            </GridLayout>
        </FormGroup>
        <SCPICollection required />
        <VerticalLayout>
            <Investment />
        </VerticalLayout>
    </Fragment>
);

export default Operation;
