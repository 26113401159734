// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rip-finances-investor-profiles {
    display: flex;
}

.rip-finances-investor-profiles > * {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #7d96a0;
    flex: 1;
}

.rip-finances-investor-profiles > *:first-child {
    padding-left: 0;
}

.rip-finances-investor-profiles > *:last-child {
    padding-right: 0;
    border-right: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/rip/profile/InvestorProfile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,+BAA+B;IAC/B,OAAO;AACX;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".rip-finances-investor-profiles {\n    display: flex;\n}\n\n.rip-finances-investor-profiles > * {\n    padding-left: 10px;\n    padding-right: 10px;\n    border-right: 1px solid #7d96a0;\n    flex: 1;\n}\n\n.rip-finances-investor-profiles > *:first-child {\n    padding-left: 0;\n}\n\n.rip-finances-investor-profiles > *:last-child {\n    padding-right: 0;\n    border-right: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
