import React, { Fragment } from 'react';
import { countries, politicalPositions, politicalRelationships } from 'common/choices';
import ConditionalNode from 'components/form/ConditionalNode';
import { YesNoField, ChoiceField, SelectField } from 'components/form/field';
import { GridLayout } from 'components/form/layout';

import './politicalStatus.scss';

const politicalStatusFactory = ({ prefix, title, fromRelationship = false }) => {
    const getFieldName = (name) => `${prefix}.${name}`;

    return () => (
        <Fragment>
            <YesNoField
                title={title}
                name={getFieldName('heldPosition')}
            />
            <ConditionalNode field={getFieldName('heldPosition')}>
                <GridLayout className="condensed dynamic political-status-details">
                    {fromRelationship && (
                        <ChoiceField
                            title="Lien de parenté"
                            name={getFieldName('relationship')}
                            choices={politicalRelationships}
                        />
                    )}
                    <ChoiceField
                        title="Fonction exercée"
                        name={getFieldName('position')}
                        choices={politicalPositions}
                    />
                    <SelectField
                        title="Pays d'exposition"
                        name={getFieldName('countryOfExposure')}
                    >
                        <option />
                        {Object.entries(countries).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </SelectField>
                </GridLayout>
            </ConditionalNode>
        </Fragment>
    );
};

export default politicalStatusFactory;
