import decline from './decline';
import investmentTargets from './investmentTargets';
import investmentTimeHorizon from './investmentTimeHorizon';
import AMFCustomer from './AMFCustomer';
import tradingLossKnowledge from './tradingLossKnowledge';
import downwardPrediction from './downwardPrediction';
import sufferTradingLosses from './sufferTradingLosses';
import doneReactionOnLoss from './doneReactionOnLoss';
import investmentDescription from './investmentDescription';
import investmentStress from './investmentStress';
import investmentsRiskScale from './investmentsRiskScale';
import availableSavings from './availableSavings';

/*
 * Every attribute must be either a function or an object
 * The function will receive one argument (the value for the field)
 * The function can either direct return the profile or an object
 *
 * If the attribute is an object or if the function returns an object,
 * Then it must have this shape:
 *
 * {
 *      values: [String] // list of profiles matching this answers
 *      resolvedProfile: String // if informed, this profile will be used as result right away (optional)
 *      rejectedProfiles: [String] // if informed, these profiles cannot be used as result (optional)
 * }
 */
const questions = {
    decline,
    investmentTargets,
    investmentTimeHorizon,
    AMFCustomer,
    tradingLossKnowledge,
    downwardPrediction,
    sufferTradingLosses,
    doneReactionOnLoss,
    investmentDescription,
    investmentStress,
    investmentsRiskScale,
    availableSavings,
};

export default questions;
