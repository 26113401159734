import React from 'react';
import PropTypes from 'prop-types';
import { get, filter } from 'lodash/fp';
import { formValues } from 'redux-form';
import Collection from '../../form/Collection';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import Child from './Child';

const confirmChange =
    "Attention, vous devez supprimer tous les petits enfants afin de pouvoir supprimer l'enfant";

const isMoreThanOnechildrenOrNoGrandChildren = (child, grandChild) => {
    if ((child && 1 < child.length) || (null === grandChild || 0 === grandChild.length)) {
        return true;
    }

    return false;
};

const Children = ({ change, incomes, children, grandChildren }) => (
    <FormGroup>
        <Title>
            Combien <b>avez-vous d&apos;enfants&nbsp;? *</b>
        </Title>
        <Collection
            name="children"
            component={Child}
            addLabel="Ajouter un enfant"
            deleteAction={(index, fields) => {
                const childrenId = get('id', fields.get(index));
                // Only keep other incomes
                const filteredIncomes = filter(({ child }) => !child || child !== childrenId, incomes);

                change('incomes', filteredIncomes);
                if (children && 1 === children.length && grandChildren && grandChildren.length >= 1) {
                    return window.alert(confirmChange);
                }

                if (isMoreThanOnechildrenOrNoGrandChildren(children, grandChildren)) { return fields.remove(index); }

                return fields.remove(index);
            }}
            deleteChildren
        />
    </FormGroup>
);

Children.propTypes = {
    change: PropTypes.func.isRequired,
    incomes: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    grandChildren: PropTypes.arrayOf(PropTypes.shape({})),
};

export default formValues({
    incomes: 'incomes',
    children: 'children',
    grandChildren: 'grandChildren',
})(Children);
