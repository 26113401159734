import { flowRight, has } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { branch, renderNothing } from 'recompose';
import { formValues } from 'redux-form';
import FormGroup from 'components/form/Group';
import TypedBlock from 'components/form/TypedBlock';
import Owner from './Owner';
import Tenant from './Tenant';
import CompanyAccomodation from './CompanyAccomodation';
import FreeAccomodation from './FreeAccomodation';

const components = {
    owner: Owner,
    tenant: Tenant,
    companyAccomodation: CompanyAccomodation,
    freeAccomodation: FreeAccomodation,
};

const MainResidenceForm = ({ value }) => {
    const Component = components[value];

    if (!Component) {
        return null;
    }

    return (
        <FormGroup>
            <TypedBlock title="Résidence principale ">
                <Component />
            </TypedBlock>
        </FormGroup>
    );
};

MainResidenceForm.propTypes = {
    value: PropTypes.string.isRequired,
};

export default flowRight([
    formValues({ value: 'mainResidence' }),
    branch(({ value }) => !has(value, components), renderNothing),
])(MainResidenceForm);
