import React, { Component, createContext, createRef } from 'react';
import { setDisplayName, wrapDisplayName } from 'recompose';

const { Consumer, Provider } = createContext(null);

export const attachScrollNode = (WrappedComponent) => {
    class ScrollNodeProvider extends Component {
        constructor(props) {
            super(props);
            this.nodeRef = createRef();
        }

        render() {
            return (
                <Provider value={this.nodeRef}>
                    <WrappedComponent {...this.props} ref={this.nodeRef} />
                </Provider>
            );
        }
    }

    return setDisplayName(wrapDisplayName(WrappedComponent, 'attachScrollNode'))(ScrollNodeProvider);
};

const withScrollNode = (WrappedComponent) => {
    const ScrollNodeProvider = (props) => (
        <Consumer>
            {(nodeRef) => <WrappedComponent {...props} scrollNode={nodeRef ? nodeRef.current : null} />}
        </Consumer>
    );

    return setDisplayName(wrapDisplayName(WrappedComponent, 'withScrollNode'))(ScrollNodeProvider);
};

export default withScrollNode;
