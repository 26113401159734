import React from 'react';
import { faArrowUpLong, faArrowDownLong, faChartLine, faPersonCane } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '../../form/Group';
import Title from '../../Title';
import TypedCollection from '../../form/TypedCollection';
import BonusEvent from './BonusEvent';
import SalaryIncreaseEvent from './SalaryIncreaseEvent';
import SalaryEvolutionEvent from './SalaryEvolutionEvent';
import SalaryDecreaseEvent from './SalaryDecreaseEvent';
import GoingRetired from './GoingRetired';

const types = {
    bonus: {
        component: BonusEvent,
        path: 'prime',
        title: 'Prime ponctuelle',
    },
    salaryIncrease: {
        component: SalaryIncreaseEvent,
        icon: faArrowUpLong,
        title: 'Augmentation de revenus',
    },
    salaryDecrease: {
        component: SalaryDecreaseEvent,
        icon: faArrowDownLong,
        title: 'Baisse de revenus',
    },
    salaryEvolution: {
        component: SalaryEvolutionEvent,
        icon: faChartLine,
        title: 'Évolution de revenus',
    },
    goingRetired: {
        component: GoingRetired,
        icon: faPersonCane,
        title: 'Départ en retraite',
    },
};

const EventsList = () => (
    <FormGroup>
        <Title>Avez-vous un <b>évènement à prévoir&nbsp;?</b></Title>
        <TypedCollection name="taxEvents" types={types} />
    </FormGroup>
);

export default EventsList;
