import { set, get, isEmpty, sumBy, isObject } from 'lodash/fp';
import validate, {
    checkIsDate,
    checkRequiredField,
    checkRequiredTrue,
    checkTotal,
    branchValidate,
    validateCollection,
} from 'common/formValidation/lib';

const validateBeneficiary = (name) => [
    checkRequiredField(`${name}.lastName`),
    checkRequiredField(`${name}.firstName`),
    checkIsDate(`${name}.birthDate`),
    checkRequiredField(`${name}.birtPlace`),
    checkRequiredField(`${name}.address`),
    checkRequiredField(`${name}.proportion`),
];

const validatePoliticalStatusFactory = (prefix, fromRelationship = false) => {
    const heldField = `${prefix}.heldPosition`;

    return [
        checkRequiredField(heldField),
        branchValidate((errors, values) => get(heldField, values), [
            ...(fromRelationship ? [
                checkRequiredField(`${prefix}.relationship`),
            ] : []),
            checkRequiredField(`${prefix}.position`),
            checkRequiredField(`${prefix}.countryOfExposure`),
        ]),
    ];
};

const validateMandataireCourtier = (errors, values) => {
    const value = get('data.mandataire', values);
    const value2 = get('data.courtier', values);

    if (!isObject(value) && !value && !isObject(value2) && !value2) {
        return set('data.mandataire', 'Vous devez renseigner le statut du mandataire.', errors);
    }

    return errors;
};

const validateFundsOrigin = (errors, values) => {
    const value = get('data.fundsOrigin', values);

    if (!isObject(value) || !Object.values(value).includes(true)) {
        return set('_error', 'Vous devez renseigner l\'origine des fonds à disposition.', errors);
    }

    return errors;
};
const formValidate = validate((values) => [
    checkRequiredTrue('data.allowTheseisMailContact'),
    checkRequiredTrue('data.gotKeyInformationDocument'),
    checkRequiredTrue('data.gotInvestmentNotices'),
    checkRequiredField('data.reasonForTheChoiceOfContract'),
    validateFundsOrigin,
    validateMandataireCourtier,
    branchValidate(
        (errors, { data: { freeClause, clauseBeneficiary } = {} }) =>
            'otherFreeClause' === freeClause
            && !isEmpty(clauseBeneficiary)
            && clauseBeneficiary.length > 0,
        [
            validateCollection('data.clauseBeneficiary', validateBeneficiary),
            checkTotal(
                'data.clauseBeneficiary[0].proportion',
                1,
                sumBy(
                    ({ proportion }) => (proportion ? parseFloat(proportion) : 0),
                    get('data.clauseBeneficiary', values),
                ),
                'La somme des quote-parts doit être égale à 100%',
            ),
        ],
    ),
    // free clause
    branchValidate(
        (errors, { data: { freeClause } = {} }) => 'freeClause' === freeClause,
        [checkRequiredField('files.freeClauseDocument')],
    ),
    // political status
    branchValidate(
        (errors, { simulation: { data: { contractType } } }) => !['target', 'targetImmo'].includes(contractType),
        [
            checkRequiredField('data.freeClause'),
            ...validatePoliticalStatusFactory('data.political.client'),
            ...validatePoliticalStatusFactory('data.political.family', true),
        ],
        [],
    ),
]);

export default formValidate;
