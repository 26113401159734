import PropTypes from 'prop-types';
import { flowRight } from 'lodash/fp';
import React from 'react';

import { Layout } from 'components/layouts';
import CifApprovalForm from './approvalCif/Form';
import dataProvider from '../dataProvider';
import withRouter from '../withRouter';
import { shouldPartnerSign } from '../../common/utils/hasPartner';
import incomes from './approvalCif/initialValues';

const ApprovalInner = ({ rip, params: { target } }) => {
    const hasPartner = shouldPartnerSign(rip);

    return (
        <Layout>
            <CifApprovalForm
                initialValues={{ target, hasPartner, incomes }}
                nextButtonContent="Envoyer"
                hideMenu
            />
        </Layout>
    );
};

ApprovalInner.propTypes = {
    rip: PropTypes.shape({
    }).isRequired,
    params: PropTypes.shape({
        target: PropTypes.string.isRequired,
    }).isRequired,
};

const Approval = flowRight([
    withRouter,
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
        wait: true,
    }),
])(ApprovalInner);

export default Approval;
