import { flowRight, has, pick } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, formValues } from 'redux-form';
import FormGroup from '../../form/Group';
import { communityRegimes } from '../../../common/choices';
import VerticalRadioButton from '../../form/VerticalRadioButton';
import RadioGroup from '../../form/RadioGroup';
import { delayedScrollToNext } from '../../../utils/scrollToNext';

const onClick = delayedScrollToNext(400);

// list of available community regime by family situation
const options = {
    partner: {
        options: pick(['separationOfEstate', 'joint'], communityRegimes),
        default: 'separationOfEstate',
    },
    married: {
        options: pick(
            ['legalCommunity', 'universalCommunity', 'separationAsToProperty', 'acquiredProperty'],
            communityRegimes,
        ),
        default: 'legalCommunity',
    },
};

// helper to get the setting for a given family situation
const getSettings = (familySituation) => options[familySituation] || { options: {}, default: null };

// this function has no need of reference on the props' owner
const checkDefaultOption = (familySituation, communityRegime, update) => {
    // get settings
    const settings = getSettings(familySituation);

    if (settings.default !== null && (!communityRegime || !has(communityRegime, settings.options))) {
        // we got no option or an invalid one
        // so use the default option
        update('rip-edit', 'communityRegime', settings.default);
    }
};

class MatrimonialRegimeInner extends Component {
    componentDidMount() {
        // we must check the default option on the first call
        // otherwise it won't check anything
        checkDefaultOption(this.props.familySituation, this.props.communityRegime, this.props.change);
    }

    componentWillUpdate(nextProps) {
        checkDefaultOption(nextProps.familySituation, nextProps.communityRegime, nextProps.change);
    }

    getOptions() {
        // get settings
        const settings = getSettings(this.props.familySituation);

        return Object.entries(settings.options).map(([value, title]) => (
            <VerticalRadioButton
                title={title}
                value={value}
                onClick={onClick}
                key={value}
            />
        ));
    }

    render() {
        return (
            <FormGroup title="Régime matrimonial&nbsp;?" required>
                <RadioGroup name="communityRegime">
                    {this.getOptions()}
                </RadioGroup>
            </FormGroup>
        );
    }
}

MatrimonialRegimeInner.propTypes = {
    familySituation: PropTypes.string,
    communityRegime: PropTypes.string,
    change: PropTypes.func.isRequired,
};

MatrimonialRegimeInner.defaultProps = {
    familySituation: '',
    communityRegime: '',
};

const mapDispatchToProps = {
    change,
};

const MatrimonialRegime = flowRight([
    formValues({
        familySituation: 'familySituation',
        communityRegime: 'communityRegime',
    }),
    connect(null, mapDispatchToProps),
])(MatrimonialRegimeInner);

export default MatrimonialRegime;
