import { flowRight, isBoolean } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { scroller, Element } from 'react-scroll';
import { formValues, reduxForm } from 'redux-form';
import { setModal } from 'actions';
import { POST } from 'utils/httpMethods';
import Title from 'components/form/Title';
import { YesNoField, TextAreaField } from 'components/form/field';
import { VerticalLayout } from 'components/form/layout';
import Spinner from 'components/Spinner';
import FormButtons from 'components/FormButtons';
import ViewerBoard from './ViewerBoard';
import withRouter from '../../../withRouter';

import './Validation.scss';

const onApprovalChange = () => scroller.scrollTo('subscription-approval-next', {
    smooth: true,
    containerId: 'simulationFormInner',
    offset: -90,
});

const Form = ({
    params: { id },
    contract,
    isApproved,
    handleSubmit,
}) => (
    <form onSubmit={handleSubmit} id="main-form">
        <VerticalLayout>
            <div className="investorProfile-form" id="simulationFormInner">
                <div className="container-fluid">
                    <Title title="Consulter les documents relatifs à cette souscription" />
                    <ViewerBoard contract={contract} ripId={id} />
                    <hr />
                    <YesNoField
                        title="Validez-vous ce contrat ?"
                        name="data.isApproved"
                        onChange={onApprovalChange}
                    />
                    <Element name="subscription-approval-next">
                        {isApproved && (
                            <div className="alert alert-success">
                                <h3>Vous allez valider ce contrat !</h3>
                                <p>Cliquez sur &quot;Envoyer&quot; pour notifier le conseiller</p>
                            </div>
                        )}
                        {false === isApproved && (
                            <Fragment>
                                <TextAreaField
                                    title="Pourquoi refusez-vous ?"
                                    name="data.caComments"
                                    required
                                />
                                <div className="alert alert-warning">
                                    <h3>Vous allez refuser ce contrat !</h3>
                                    <p>Cliquez sur &quot;Envoyer&quot; pour notifier le conseiller</p>
                                </div>
                            </Fragment>
                        )}
                    </Element>
                </div>
            </div>
            {isBoolean(isApproved) && <FormButtons nextButtonContent="Envoyer" />}
        </VerticalLayout>
    </form>
);

Form.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isApproved: PropTypes.bool,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
    }).isRequired,
    contract: PropTypes.shape({}),
};

export default flowRight([
    withRouter,
    reduxForm({
        form: 'contract-approval',
        onSubmit: (body, dispatch, { contract: { id } }) => {
            // show a modal to avoid multiple submissions
            dispatch(setModal(() => <Spinner>Envoi de l&apos;email en cours...</Spinner>, false));

            // send the request to the backend
            return POST(`/api/contracts/${id}/approve`, { body });
        },
        onSubmitSuccess: (body, dispatch, { navigate }) => {
            // remove the modal
            dispatch(setModal(null));
            // inform of the success
            alert('Votre message a bien été envoyé !\n\n En cliquant sur OK vous serez redirigé vers la liste des rips');
            // and redirect
            navigate('/');
        },
        onSubmitFail: (errors, dispatch) => {
            // remove the modal
            dispatch(setModal(null));
            // inform of the failure
            alert('Erreur lors de l\'envoi du mail');
        },
    }),
    formValues({ isApproved: 'data.isApproved' }),
])(Form);
