import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Grid, AmountItem, TextItem, PercentageItem } from 'common/components/summary';
import Title from 'components/Title';

const Operation = ({
    simulationSummary: {
        instalmentCosts: { investmentWithTVA },
        resaleOperation: {
            builtCapital: resaleBuiltCapital,
        },
        indexedOperation: {
            builtCapital: indexedBuiltCapital,
        },
    },
    simulation: { needFunding, fundingRate },
}) => (
    <Fragment>
        <Title className="margin-top"><b>Opération</b></Title>
        <Grid size={2}>
            <AmountItem
                title="Montant de l'investissement (TTC)"
                value={investmentWithTVA}
            />
            {needFunding ? (
                <PercentageItem
                    title="Taux du financement"
                    value={fundingRate}
                />
            ) : (
                <TextItem
                    title="Taux du financement"
                    value="Paiement cash"
                />
            )}
            <AmountItem
                title="Capital reconstitué avec réindexation"
                value={indexedBuiltCapital}
            />
            <AmountItem
                title="Capital constitué sans réindexation"
                value={resaleBuiltCapital}
            />
        </Grid>
    </Fragment>
);

Operation.propTypes = {
    simulation: PropTypes.shape({
        needFunding: PropTypes.bool,
        fundingRate: PropTypes.number,
    }).isRequired,
    simulationSummary: PropTypes.shape({
        instalmentCosts: PropTypes.shape({
            investmentWithTVA: PropTypes.number,
        }),
        resaleOperation: PropTypes.shape({
            builtCapital: PropTypes.number,
        }),
        indexedOperation: PropTypes.shape({
            builtCapital: PropTypes.number,
        }),
    }).isRequired,
};

export default Operation;
