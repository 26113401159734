import decline from './decline';
import yesNoConductFinancialTransactionsWithAdvisor from './yesNoConductFinancialTransactionsWithAdvisor';
import yesNoConductFinancialTransactionsWithoutAdvisor from './yesNoConductFinancialTransactionsWithoutAdvisor';
import yesNoDelegatingPortfolio from './yesNoDelegatingPortfolio';
import doneInvestmentTypes from './doneInvestmentTypes';
import doneReactionOnLoss from './doneReactionOnLoss';
import downwardPrediction from './downwardPrediction';
import investmentStress from './investmentStress';
import sufferTradingLosses from './sufferTradingLosses';
import yesNoTradingLossKnowledge from './yesNoTradingLossKnowledge';
import financialKnowledge from './financialKnowledge';
import investmentInFinancialSupport from './investmentInFinancialSupport';
import investmentKnowledge from './investmentKnowledge';
import legalKnowledge from './legalKnowledge';
import supportLoss from './supportLoss';
import investmentPossession from './investmentPossession';
import possibilityOfSouscription from './possibilityOfSouscription';

const knowledgeAndExperience = {
    decline,
    doneInvestmentTypes,
    doneReactionOnLoss,
    downwardPrediction,
    financialKnowledge,
    investmentInFinancialSupport,
    investmentKnowledge,
    investmentPossession,
    investmentStress,
    legalKnowledge,
    possibilityOfSouscription,
    sufferTradingLosses,
    supportLoss,
    yesNoConductFinancialTransactionsWithAdvisor,
    yesNoConductFinancialTransactionsWithoutAdvisor,
    yesNoDelegatingPortfolio,
    yesNoTradingLossKnowledge,

};

export default knowledgeAndExperience;
