import React from 'react';

const boxShoyoCreationError = (rip, targetPartnerBoxShoyo) => {
    let errorMessageBox;
    const { statusCode } = targetPartnerBoxShoyo ? rip.statusBoxShoyo.partner : rip.statusBoxShoyo.client;

    switch (statusCode) {
        case 400:
            errorMessageBox = (
                <div >
                    <span style={{ fontWeight: 'bold', textAlign: 'center' }}>Merci de vérifier:</span>
                    <div style={{ textAlign: 'center' }}>
                        <ul style={{ display: 'inline-block', textAlign: 'left' }} >
                            <li>Que l’adresse email n’est pas déjà utilisée sur eDoc</li>
                            <li>Les caractères utilisés dans les noms et prénoms du RIP</li>
                            <li>Que le format et la taille du fichier du RIP signé sont corrects</li>
                        </ul>

                    </div>
                </div>
            );
            break;

        case 401:
            errorMessageBox = (
                <div>
                    Vous n’êtes pas autorisé à créer un eDoc
                </div>
            );
            break;

        case 403:
            errorMessageBox = (
                <div>
                    Accès refusé
                </div>
            );
            break;

        case 406:
            errorMessageBox = (
                <div>
                    Votre compte eDoc n’est pas correctement renseigné
                </div>
            );
            break;

        case 500:
            errorMessageBox = (
                <div>
                    Une erreur inconnue est survenue lors de la création de l’eDoc
                </div>
            );
            break;
        default:
            break;
    }

    return errorMessageBox;
};

export default boxShoyoCreationError;
