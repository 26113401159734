import React, { Component, Fragment } from 'react';
import { map, sumBy } from 'lodash/fp';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { formatAmount, formatDate } from 'common/utils/format';
import Title from 'components/Title';
import getSettings from 'common/utils/settings';
import FieldInfo from 'components/form/FieldInfo';

class HypothesisBis extends Component {
    renderPartsValuesAtTheEnd() {
        const partsValuesAtTheEnd = _.get(this.props, 'results.partsValuesAtTheEnd', []);

        return (
            <tr>
                <td key="PartsValuesAtTheEnd-title">Valeur des parts au terme</td>
                {partsValuesAtTheEnd.map(({ value }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={index.toString()}>{formatAmount(value)}</td>
                ))}
                <td key="PartsValuesAtTheEnd-total">{formatAmount(sumBy('value', partsValuesAtTheEnd))}</td>
            </tr>
        );
    }

    renderRewardsAfterSell() {
        const partsValuesAtTheEnd = _.get(this.props, 'results.partsValuesAtTheEnd', []);

        return (
            <tr>
                <td key="rewardsAfterSell-title">Gain au terme en cas de revente</td>
                {partsValuesAtTheEnd.map(({ sellValue }) => (
                    <td key={sellValue.id}>{formatAmount(sellValue)}</td>
                ))}
                <td key="rewardsAfterSell-total">{formatAmount(sumBy('sellValue', partsValuesAtTheEnd))}</td>
            </tr>
        );
    }

    renderBareOwnershipRow() {
        const partsValuesAtTheEnd = _.get(this.props, 'results.partsValuesAtTheEnd', []);

        return (
            <tr>
                <td key="BareOwnershipRow-title">Loyer annuel estimé en cas de conservation des parts</td>
                {partsValuesAtTheEnd.map(({ yearlyIncome }) => (
                    <td key={yearlyIncome.id}>{formatAmount(yearlyIncome)}</td>
                ))}
                <td key="BareOwnershipRow-total">{formatAmount(sumBy('yearlyIncome', partsValuesAtTheEnd))}</td>
            </tr>
        );
    }

    renderEndDate() {
        const endDate = _.get(this.props, 'results.endDate', []);

        return (
            <tr>
                <td key="EndDate-title">
                    Fin du démembrement
                    <FieldInfo id="end-date-explanation">
                        Premier jour du mois suivant la souscription + durée du démembrement + délai de jouissance de la
                        SCPI
                    </FieldInfo>
                </td>
                {endDate.map(
                    (value, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <td key={index.toString()}>{value ? formatDate(value) : '-'}</td>
                    ),
                    endDate,
                )}
            </tr>
        );
    }

    render() {
        const { products } = this.props;

        return (
            <Fragment>
                <Title>
                    <b>Au terme du démembrement</b>
                </Title>
                <table className="table table-striped table-bordered lifeInsurance-hypothesis-table table-hover">
                    <thead>
                        <tr>
                            <th />
                            {map(
                                ({ type }) => (
                                    <th key={type}>{getSettings(['simulation', 'dismemberedScpi', type, 'name'])}</th>
                                ),
                                products,
                            )}
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderPartsValuesAtTheEnd()}
                        {this.renderRewardsAfterSell()}
                        {this.renderBareOwnershipRow()}
                        {this.renderEndDate()}
                    </tbody>
                </table>
            </Fragment>
        );
    }
}

HypothesisBis.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default HypothesisBis;
