import React from 'react';
import PropTypes from 'prop-types';
import { PercentageFloatField } from 'components/form/field';

const DismembermentShare = ({ product }) => (
    <table className="settings-table">
        <thead>
            <tr>
                <th colSpan="3">Clés de démembrement</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Durée du démembrement</td>
                <td>Usufruit (%)</td>
                <td>Nue-propriété (%)</td>
            </tr>
            {[5, 7, 10, 15, 20].map((years) => (
                <tr key={years.toString()}>
                    <td>{years} ans</td>
                    <td><PercentageFloatField name={`simulation.dismemberedScpi.${product}.share.${years}.usufruct`} /></td>
                    <td><PercentageFloatField name={`simulation.dismemberedScpi.${product}.share.${years}.bareOwnership`} /></td>
                </tr>
            ))}
        </tbody>
    </table>
);

DismembermentShare.propTypes = {
    product: PropTypes.string.isRequired,
};

export default DismembermentShare;
