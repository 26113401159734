import { mapValues, get, flow, pickBy, has } from 'lodash/fp';
import React from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { formValues } from 'redux-form';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import TypedBlockCollectionItem from 'components/form/TypedBlockCollectionItem';
import TypedCollection from 'components/form/TypedCollection';
import { formatAmount, formatPercentage } from 'common/utils/format';
import { useSettingsMemo } from 'components/sessions/useSettings';
import SCPI from './SCPI';

const generateTypes = (duration, scpis) => flow([
    pickBy((scpi) => has(['share', duration], scpi) && !scpi.deleted),
    mapValues(({ name, ...scpi }) => {
        const share = get(['share', duration, 'bareOwnership'], scpi);

        return {
            component: withProps({ scpi })(SCPI),
            title: name,
            itemTitle: `${name} - ${formatAmount(scpi.value * share, 2)} (${formatPercentage(share)})`,
            unique: true,
            defaultValues: {
                parts: scpi.minimumParts,
                revaluation: '0.005',
            },
        };
    }),
])(scpis);

const SCPICollection = ({ duration }, required) => {
    const types = useSettingsMemo(
        ({ simulation: { dismemberedScpi: scpis } }) => generateTypes(duration, scpis),
        [duration],
    );

    return (
        <FormGroup>
            <Title>
                <b>Produits {required ? '*' : ''}</b>
            </Title>
            <TypedCollection
                name="data.products"
                types={types}
                textOnly
                itemComponent={TypedBlockCollectionItem}
            />
        </FormGroup>
    );
};

SCPICollection.propTypes = {
    duration: PropTypes.number.isRequired,
};

export default formValues({ duration: 'data.duration' })(SCPICollection);
