import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { SelectField } from '../../form/field';
import SalaryOption from './SalaryOption';

const allowedIncomes = ['salary', 'BNC', 'BIC', 'BA', 'pension', 'other', 'exemptedIncome', 'childrenIncome'];

const SelectSalaryInner = ({ incomes, ...props }) => (
    <SelectField {...props}>
        <option />
        {incomes && incomes.length
            ? incomes.map(({ id, type, ...salary }, index) =>
                (allowedIncomes.includes(type) ? (
                    <SalaryOption key={id || index.toString()} value={id || index} type={type} salary={salary} />
                ) : null))
            : null}
    </SelectField>
);

SelectSalaryInner.propTypes = {
    incomes: PropTypes.arrayOf(PropTypes.shape({})),
};

SelectSalaryInner.defaultProps = {
    incomes: [],
};

const SelectSalary = formValues({
    incomes: 'incomes',
})(SelectSalaryInner);

export default SelectSalary;
