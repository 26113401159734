import { flowRight, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapProps, withContext, lifecycle } from 'recompose';
import { setFormValidationDisplay } from 'actions';

const withFormValidationConsumer = flowRight([
    // first connect with the redux store
    connect(({ formValidation: { showErrors } }) => ({
        mustShowErrors: showErrors,
    }), { showErrors: setFormValidationDisplay }),
    // then setup the context for every fields below
    withContext({
        mustShowErrors: PropTypes.bool.isRequired,
    }, ({ mustShowErrors }) => ({ mustShowErrors })),
    lifecycle({
        componentDidMount() {
            // consume the display mode
            this.props.showErrors(false);
        },
    }),
    // no need add properties so we clear those a bit
    mapProps((props) => omit(['mustShowErrors', 'showErrors'], props)),
]);

export default withFormValidationConsumer;
