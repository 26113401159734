import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formValues } from 'redux-form';
import { DisabledField } from 'components/form/field';

const SSOUser = ({ firstName, lastName, email }) => (
    <Fragment>
        <DisabledField title="Prénom" name="firstName" value={firstName} />
        <DisabledField title="Nom" name="lastName" value={lastName} />
        <DisabledField title="Email" name="email" value={email} />
    </Fragment>
);

SSOUser.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
};

export default formValues({
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
})(SSOUser);
